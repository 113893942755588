import { Box, Divider, Stack, SxProps, Typography } from '@mui/material';

import { mergeSx, withProps } from '@cast/design-system';
import { BestPracticeCheckDetails } from '@cast/types';

import { Detail } from 'components/drawer';
import { CheckDetails } from 'components/security-insights';
import { SeverityChip, severityToTextMap } from 'features/security-insights';

const StyledDetail = withProps(Detail, {
  titleProps: { variant: 'L14B', color: 'grey.900' },
});

type Props = {
  check?: BestPracticeCheckDetails;
  isLoading?: boolean;
  sx?: SxProps;
};

export const CheckOverview = ({ check, isLoading, sx }: Props) => {
  const checkType = check?.manual ? 'Manual' : 'Automated';
  const severityLevel = check?.severityLevel;
  return (
    <Box
      sx={mergeSx(
        {
          '& > .Detail-root + .Detail-root': {
            marginTop: '12px',
          },
        },
        sx
      )}
    >
      <StyledDetail
        title="CVSS v3.1 Base Score"
        detail={
          <Stack direction="row" gap={8}>
            <SeverityChip className={severityLevel}>
              {check?.severityScore || '0'}
            </SeverityChip>
            <SeverityChip className={severityLevel}>
              {severityLevel && severityLevel in severityToTextMap
                ? severityToTextMap[severityLevel]
                : severityLevel}
            </SeverityChip>
          </Stack>
        }
        isLoading={isLoading}
      />
      <StyledDetail
        title="Vector"
        detail={check?.cvss3vector}
        isLoading={isLoading}
      />
      <StyledDetail
        title="Standard"
        detail={
          check?.labels?.length ? (
            <Stack typography="P14R" gap={8} justifyContent="flex-end">
              {check?.labels?.map((label) => (
                <span key={label}>{label}</span>
              ))}
            </Stack>
          ) : (
            '-'
          )
        }
        isLoading={isLoading}
      />
      <StyledDetail
        title="Check type"
        detail={
          <Stack direction="row" typography="P14R" gap={8} alignItems="center">
            {checkType}
            <Typography
              variant="P12B"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              bgcolor="grey.500"
              color="white"
              width={18}
              height={18}
            >
              {checkType[0]}
            </Typography>
          </Stack>
        }
        isLoading={isLoading}
      />
      {!!check?.mdCheckDetails && (
        <>
          <Divider sx={{ my: 32 }} />
          <CheckDetails sx={{ pb: 50 }} markdown={check.mdCheckDetails} />
        </>
      )}
    </Box>
  );
};
