import { useEffect } from 'react';

import dayjs from 'dayjs';

import { useScheduledJob } from '@cast/utils';

import { OnboardingState } from '../types';

export const useClusterConnectionTimeout = (
  state: OnboardingState | undefined,
  setOnboardingState: (state: OnboardingState) => void,
  timeout = 60 * 3 * 1000
) => {
  const { start, clear } = useScheduledJob();
  useEffect(() => {
    if (state === 'connecting') {
      start({
        when: dayjs().add(timeout, 'milliseconds'),
        job: () => setOnboardingState('error'),
      });
    }
    return clear;
  }, [clear, setOnboardingState, start, state, timeout]);
};
