import { useContext } from 'react';

import { Box, BoxProps } from '@mui/material';

import { mergeSx } from '@cast/design-system';

import { PortalsContext } from './PortalsProvider';
import { Portal } from './types';

type Props = { portal: `${Portal}` } & BoxProps;

export const PortalSlot = ({ portal, ...boxProps }: Props) => {
  const portalsContext = useContext(PortalsContext);
  const portalProps = portalsContext?.portalsProps[portal];
  return (
    <Box
      position="relative"
      {...boxProps}
      {...(portalProps || {})}
      ref={(ref: HTMLElement | null) => {
        portalsContext?.addPortal(portal as Portal, ref);
      }}
      sx={mergeSx(boxProps.sx, portalProps?.sx)}
    />
  );
};
