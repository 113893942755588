import { ChipProps, mergeSx } from '@cast/design-system';

import { BetaLabelChip } from 'components/chips';

type BetaLabelProps = ChipProps;

export const BetaLabel = ({ sx }: BetaLabelProps) => (
  <BetaLabelChip
    size="micro"
    sx={mergeSx(
      {
        mr: 'auto',
        alignSelf: 'flex-start',
        transform: 'translateX(-8px)',
      },
      sx
    )}
  />
);
