import { AxiosInstance } from 'axios';

import { RbacServiceAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const RbacApiAdapter = (client: AxiosInstance) => {
  const generatedApi = RbacServiceAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  return {
    resolveAccess: generatedApi.rbacServiceAPIAccessResolve,
    createGroup: generatedApi.rbacServiceAPICreateGroup,
    createRoleBinding: generatedApi.rbacServiceAPICreateRoleBindings,
    deleteGroup: generatedApi.rbacServiceAPIDeleteGroup,
    deleteRoleBinding: generatedApi.rbacServiceAPIDeleteRoleBinding,
    getGroup: generatedApi.rbacServiceAPIGetGroup,
    getResources: generatedApi.rbacServiceAPIGetOrganizationResources,
    getRole: generatedApi.rbacServiceAPIGetRole,
    getRoleBinding: generatedApi.rbacServiceAPIGetRoleBinding,
    listGroups: generatedApi.rbacServiceAPIListGroups,
    listRoleBindings: generatedApi.rbacServiceAPIListRoleBindings,
    listRoles: generatedApi.rbacServiceAPIListRoles,
    updateGroup: generatedApi.rbacServiceAPIUpdateGroup,
    updateGroups: generatedApi.rbacServiceAPIUpdateGroups,
    updateRoleBinding: generatedApi.rbacServiceAPIUpdateRoleBinding,
  };
};
