import { SvgIcon, SvgIconProps } from '@mui/material';
import clsx from 'clsx';

type ExpanderIconProps = SvgIconProps<'svg', { showExpand?: boolean }>;

const ExpanderIcon = ({ showExpand, ...props }: ExpanderIconProps) => {
  return (
    <SvgIcon
      className="DsCode-ExpanderIcon"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      {...props}
    >
      <rect width="13" height="13" rx="2" fill="#417AE9" />
      {showExpand ? (
        <rect x="3" y="6" height="1" width="7" fill="white" />
      ) : (
        <>
          <rect x="3" y="6" height="1" width="7" fill="white" />
          <rect x="6" y="3" height="7" width="1" fill="white" />
        </>
      )}
    </SvgIcon>
  );
};

export type ExpandTogglerProps = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

export const ExpandToggler = ({
  isCollapsed,
  setIsCollapsed,
}: ExpandTogglerProps) => {
  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
        setIsCollapsed(!isCollapsed);
      }}
      className={clsx(
        'DsCode-ExpandToggler',
        isCollapsed
          ? 'DsCode-ExpandToggler-collapsed'
          : 'DsCode-ExpandToggler-expanded'
      )}
    >
      <ExpanderIcon showExpand={!isCollapsed} fontSize="inherit" />
    </span>
  );
};
