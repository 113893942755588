import { Divider as MuiDivider, styled } from '@mui/material';

import { shouldForwardProp } from '../../utils/shouldForwardProp';

export const RowDivider = styled(MuiDivider, {
  name: 'DsDataPopperRowDivider',
  slot: 'Root',
  target: 'DsDataPopperRowDivider-Root',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root],
})``;
