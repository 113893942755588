import { ThemeOptions } from '@mui/material';

export const textAreaTheme: ThemeOptions['components'] = {
  DsTextArea: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiInputBase-root': {
          padding: 0,
          width: 'max-content',
          height: 'max-content',
          '& .MuiInputBase-input': {
            border: 'none',
            padding: '8px',
            borderRadius: '4px',
            boxSizing: 'border-box',
            outline: 'none',
            fontFamily: theme.typography.P16R.fontFamily,

            '&.DS-disableResize': {
              resize: 'none',
            },
          },
        },

        '.MuiInputBase-sizeSmall .DS-TextArea-adornment-root': {
          fontSize: '16px',
        },

        '.DS-TextArea-adornment-root': {
          color: theme.palette.grey[400],
          position: 'absolute',
          top: 6,
          right: 6,
          fontSize: '20px',
        },
        '&.DS-Input-readOnly': {
          '& .MuiInputBase-input': {
            backgroundColor: theme.palette.grey[50],
          },
        },
      }),
      small: {
        lineHeight: '18px',
        '& .MuiInputBase-input': {
          paddingRight: '32px',
          fontSize: '12px',
        },
      },
      medium: {
        lineHeight: '22px',
        '& .MuiInputBase-input': {
          paddingLeft: '10px',
          paddingRight: '38px',
          fontSize: '14px',
        },
      },
    },
  },
};
