import { AxiosInstance } from 'axios';

import { SSOAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const SSOApiAdapter = (client: AxiosInstance) => {
  const generatedApi = SSOAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    createSSOConection: generatedApi.sSOAPICreateSSOConnection,
    deleteSSOConnection: generatedApi.sSOAPIDeleteSSOConnection,
    getSSOConnection: generatedApi.sSOAPIGetSSOConnection,
    listSSOConnections: generatedApi.sSOAPIListSSOConnections,
    updateSSOConnection: generatedApi.sSOAPIUpdateSSOConnection,
  };
};
