import { useRef, useState } from 'react';

import { Dialog, DialogProps, Box } from '@mui/material';

import { analyticsEvents } from 'core/analytics';

type Props = DialogProps & {
  video?: string;
  testId?: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
};

export const VideoDialog = ({ video, testId, ...props }: Props) => {
  const playerRef = useRef<HTMLVideoElement | null>(null);
  const [videoControls, setVideoControls] = useState<boolean>(false);

  return (
    <Dialog
      sx={{ maxWidth: '1300px', bgcolor: 'none', margin: '0 auto' }}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          margin: 0,
          background: 'none',
        },
      }}
      {...props}
      fullWidth
    >
      <Box width="100%" data-testid={`${testId}-dialog`}>
        <video
          ref={playerRef}
          onPlay={() => analyticsEvents.playedVideo()}
          onClick={() => {
            if (!videoControls) {
              setVideoControls(true);
            }
          }}
          controls
          autoPlay
          style={{
            width: '100%',
            borderRadius: '10px',
            cursor: 'pointer',
          }}
        >
          <source src={video} type="video/mp4" />
        </video>
      </Box>
    </Dialog>
  );
};
