import { styled } from '@mui/material';

import { TendencyIndicatorBase } from './_components';

export const TendencyIndicator = styled(TendencyIndicatorBase)(({ theme }) => ({
  width: 'max-content',
  paddingLeft: '7px',
  paddingRight: '7px',
  borderRadius: '20px',
  '&.Tendency-up': {
    backgroundColor: theme.palette.red[400],
  },
  '&.Tendency-down': {
    backgroundColor: theme.palette.green[400],
  },
  '&.Tendency-stable': {
    backgroundColor: theme.palette.grey[200],
    '& .TendencyIndicator-icon': { display: 'none' },
    '& .TendencyIndicator-label': { marginLeft: 0 },
  },
  '&& .TendencyIndicator-icon, & .TendencyIndicator-label': {
    color: 'white',
  },
}));
