import { Stack, Typography } from '@mui/material';

import { AgentCodeSnippet } from 'components/code';
import { useOffboardingScriptQuery } from 'hooks/queries/cluster';

type Props = {
  clusterId: string;
};

export const Phase2OffboardingStep = ({ clusterId }: Props) => {
  const { isLoading, script, error, refetch } =
    useOffboardingScriptQuery(clusterId);

  return (
    <Stack width={464} gap={8}>
      <Stack gap={4}>
        <Typography variant="h6">
          Clean up the cloud resources that CAST AI created
        </Typography>

        <Typography variant="P12R" color="textSecondary">
          Copy the script below and run it in your cloud shell or terminal.
        </Typography>
      </Stack>

      <AgentCodeSnippet
        code={script}
        isLoading={isLoading}
        error={error}
        refetch={refetch}
        errorMessage="Failed to load offboarding script"
        testId="phase2-offboarding-script"
      />
    </Stack>
  );
};
