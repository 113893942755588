import { useEffect, useState } from 'react';

/**
 * @link https://usehooks-ts.com/react-hook/use-debounce
 */
export const useDebounce = <T>(value: T, delay = 200): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};
