import { CSSObject } from '@mui/material';

import { Icons, mergeSx } from '@cast/design-system';
import { WebhookStatus } from '@cast/types';

import { StatusChip, StatusChipProps } from 'components/chips';

const colors: Record<string, CSSObject> = {
  [WebhookStatus.CONNECTED]: {
    color: 'green.600',
    backgroundColor: 'green.50',
  },
  [WebhookStatus.FAILED]: {
    color: 'red.500',
    backgroundColor: 'red.50',
  },
};

type Props = StatusChipProps & {
  status?: WebhookStatus | 'UNKNOWN' | string;
  noText?: boolean;
};

const renderIcon = (status: Props['status']) => {
  switch (status) {
    case WebhookStatus.CONNECTED: {
      return <Icons.CheckCircle />;
    }
    case WebhookStatus.FAILED: {
      return <Icons.XCircle />;
    }
    case 'UNKNOWN':
      return null;
  }
};

const renderText = (status: Props['status']) => {
  switch (status) {
    case WebhookStatus.CONNECTED: {
      return <span className="StatusChip-text">Can connect</span>;
    }
    case WebhookStatus.FAILED: {
      return <span className="StatusChip-text">Cannot connect</span>;
    }
    case 'UNKNOWN':
      return null;
  }
};

export const WebhookStatusChip = ({
  status = 'UNKNOWN',
  noText,
  sx,
  ...props
}: Props) => {
  return (
    <StatusChip
      iconChip={noText}
      size="large"
      sx={mergeSx(colors[status], sx)}
      startIcon={!noText ? renderIcon(status) : undefined}
      {...props}
    >
      {!noText ? renderText(status) : renderIcon(status)}
    </StatusChip>
  );
};
