import {
  MutableRefObject,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react';

import { useTriggerRepaint } from '@cast/utils';

import { useExpandableRows } from './_hooks/useExpandableRows';
import { useFlexibleColumns } from './_hooks/useFlexibleColumns';
import { useReorderableRows } from './_hooks/useReorderableRows';
import { useSorting } from './_hooks/useSorting';
import { useTreeRows } from './_hooks/useTreeRows';
import { parseColumnProps } from './_utils';
import { initializeColumns } from './initializeColumns';
import { TablePersistence } from '../../persistence/context';
import { NormalizedTableProps, TableApi, TableState } from '../../types';

export const useTableComponent = (
  normalizedProps: NormalizedTableProps,
  rootRect?: DOMRect
): MutableRefObject<TableApi> => {
  const { children, apiRef } = normalizedProps;
  const forceRepaint = useTriggerRepaint();

  const tableApiRef = useRef() as MutableRefObject<TableApi>;
  const persistenceCtx = useContext(TablePersistence);
  const isStateRestored = useRef(false);

  useImperativeHandle(apiRef, () => tableApiRef.current as never, [
    tableApiRef,
  ]);

  if (!tableApiRef.current) {
    tableApiRef.current = {
      state: {} as never,
      setState: (
        newState: TableState | ((newState: TableState) => TableState),
        repaint = true
      ) => {
        const tableApi = tableApiRef.current;
        const oldState = tableApi?.state;
        if (typeof newState === 'function') {
          tableApiRef.current!.state = newState(oldState!);
        } else {
          tableApiRef.current!.state = newState;
        }
        persistenceCtx.accept(tableApiRef.current);
        if (repaint) {
          forceRepaint();
        }
      },
      tableInfo: {
        bodyHeight: 0,
        setBodyHeight: (
          height: number | ((height: number) => number),
          repaint?: boolean
        ) => {
          const tableApi = tableApiRef.current;
          const oldHeight = tableApi?.tableInfo?.bodyHeight ?? 0;
          if (typeof height === 'function') {
            tableApi!.tableInfo!.bodyHeight = height(oldHeight);
          } else {
            tableApi!.tableInfo!.bodyHeight = height;
          }
          if (repaint) {
            forceRepaint();
          }
        },
      },
      log: normalizedProps.log,
      // convert to full TableApi here, because plugin hooks decorates by adding methods/values to TableApi
    } as any as TableApi;
  }

  const columnsProps = parseColumnProps(children);
  useFlexibleColumns(tableApiRef, normalizedProps);
  initializeColumns(tableApiRef, columnsProps, rootRect?.width);

  useExpandableRows(tableApiRef, normalizedProps);
  useTreeRows(tableApiRef, normalizedProps);
  useSorting(tableApiRef, normalizedProps);
  useReorderableRows(tableApiRef, normalizedProps);

  if (!isStateRestored.current) {
    tableApiRef.current.state = persistenceCtx.retrieve(tableApiRef.current);
    tableApiRef.current.restoreDefaults = () => {
      tableApiRef.current.setColumnSettings(() => {
        return {
          resizedColumns: {},
          columnsOrder: [],
          hiddenColumns: {},
          lockedColumns: {},
        };
      });
    };
    isStateRestored.current = true;
  }

  return tableApiRef as MutableRefObject<TableApi>;
};
