import { MEMORY_UNIT_SUFFIXES } from '@cast/constants';
import { MemoryUnits } from '@cast/types';

export const bytesToGiBiBytes = (bytes: number) => bytes / Math.pow(1024, 3);

export type MemoryUnit = (typeof MEMORY_UNIT_SUFFIXES)[number];

export const convertUnit = (
  value: number,
  fromUnit: MemoryUnit,
  toUnit: MemoryUnit
): number => {
  if (fromUnit === toUnit) {
    return value;
  }

  const units: Record<MemoryUnit, number> = {
    Bytes: 1,
    KiB: 1024,
    MiB: 1024 ** 2,
    GiB: 1024 ** 3,
    TiB: 1024 ** 4,
    PiB: 1024 ** 5,
    EiB: 1024 ** 6,
    ZiB: 1024 ** 7,
    YiB: 1024 ** 8,
  };

  const fromUnitFactor = units[fromUnit];
  const toUnitFactor = units[toUnit];

  return (value * fromUnitFactor) / toUnitFactor;
};

export const bytesToUnit = (
  bytes: number,
  decimals = 0
): [number, MemoryUnits] => {
  if (bytes === 0) {
    return [0, 'Bytes'];
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return [
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    MEMORY_UNIT_SUFFIXES[i],
  ];
};

export const normalizeMemoryUnit = ({
  amount,
  unit,
}: {
  amount: number;
  unit: MemoryUnit;
}) => {
  let index = MEMORY_UNIT_SUFFIXES.indexOf(unit);
  let toUnit = unit;
  let val = amount;

  if (amount < 1) {
    // Current value is already smaller than 1, try smaller units until we get whole number
    index--;
    if (index < 0) {
      return { amount, unit };
    }
    while (index >= 0) {
      toUnit = MEMORY_UNIT_SUFFIXES[index];
      val = convertUnit(amount, unit, toUnit);
      if (val >= 1) {
        return { amount: val, unit: toUnit };
      }
      index--;
    }
    return { amount: val, unit: toUnit };
  }
  // Current value is larger than 1, try larger units until we reach number smaller than 1, then return previous value
  index++;
  while (index < MEMORY_UNIT_SUFFIXES.length) {
    const prevUnit = toUnit;
    const prevVal = val;
    toUnit = MEMORY_UNIT_SUFFIXES[index];
    val = convertUnit(amount, unit, toUnit);
    if (val < 1) {
      return { amount: prevVal, unit: prevUnit };
    }
    index++;
  }
  return { amount: val, unit: toUnit };
};
