import { ThemeOptions } from '@mui/material';

import { cssVars } from '../../../utils/cssVars';

const { getters, setValues } = cssVars({
  color: undefined,
  spacing: undefined,
  size: undefined,
  fontSize: undefined,
});
export const indicatorTheme: ThemeOptions['components'] = {
  DsIndicator: {
    defaultProps: {
      color: 'blue',
      size: 'small',
      count: 0,
    },
    styleOverrides: {
      small: setValues({
        size: '16px',
        fontSize: '10px',
        spacing: '6px',
      }),
      medium: setValues({
        size: '20px',
        fontSize: '12px',
        spacing: '8px',
      }),
      large: setValues({
        size: '24px',
        fontSize: '12px',
        spacing: '8px',
      }),
      dot: setValues({
        size: '8px',
        fontSize: '0px',
      }),
      blue: ({ theme }) =>
        setValues({
          color: theme.palette.blue[600],
        }),
      ocean: ({ theme }) =>
        setValues({
          color: theme.palette.aqua[600],
        }),
      indigo: ({ theme }) =>
        setValues({
          color: theme.palette.indigo[300],
        }),
      grey: ({ theme }) =>
        setValues({
          color: theme.palette.grey[600],
        }),

      root: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '40px',
        minWidth: getters.size(),
        height: getters.size(),
        backgroundColor: getters.color(),
        color: 'white',
        padding: `0 ${getters.spacing()}`,
      },
      label: ({ theme }) => ({
        ...theme.typography.P14M,
        fontSize: getters.fontSize(),
      }),
    },
  },
};
