import { ThemeOptions } from '@mui/material';

export const linkTheme: ThemeOptions['components'] = {
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.blue[500],
      }),
    },
  },
};
