import { useEffect, useMemo } from 'react';

import { useField, useForm } from 'react-final-form';

import { RffSelect } from 'core/forms/rff';
import { useGetWebhookCategoriesQuery } from 'hooks/queries/notifications';

export const OperationSelect = () => {
  const { categories } = useGetWebhookCategoriesQuery();

  const categoryField = useField('category');
  const form = useForm();

  const options = useMemo(() => {
    const subcategories = categories?.find(
      (c) => c.value === categoryField.input.value
    )?.subcategories;
    return Object.entries(subcategories || {})
      .filter(([key]) => key !== 'best-practices') // SEC doesn't support it at the moment
      .map(([key, value]) => ({
        title: value,
        value: key,
      }));
  }, [categories, categoryField.input.value]);

  useEffect(() => {
    // If there is only one option, select it and don't show the select at all
    if (options?.length === 1) {
      form.change('subcategory', options[0].value);
    }
  }, [form, options]);

  if (
    !categoryField.input.value || // If "All" category is selected
    !options?.length ||
    options.length === 1 // If only one option is available
  ) {
    return null;
  }

  return (
    <RffSelect
      testId="webhook-subcategory-select"
      applySortingWhileSearching={false}
      label="Operation"
      name="subcategory"
      options={options}
      optionLabel="title"
      optionValue="value"
      renderValue="title"
    />
  );
};
