import { PropsWithChildren, ReactNode } from 'react';

import { Box, Stack, styled, Typography, alpha } from '@mui/material';

import { withProps, Skeleton, Icons } from '@cast/design-system';
import { Notification, NotificationSeverity } from '@cast/types';

import { TimeAgo } from 'components/time';

const IconWrapper = styled(Box)({
  minWidth: 24,
  height: 24,
  borderRadius: '5px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 14,
  '& svg': {
    fontSize: 'inherit',
    color: 'inherit',
    width: '1em',
    height: '1em',
  },
});

const IconsBySeverity = {
  [NotificationSeverity.CRITICAL]: (
    <IconWrapper bgcolor="red.200" color="red.900">
      <Icons.XCircle />
    </IconWrapper>
  ),
  [NotificationSeverity.ERROR]: (
    <IconWrapper bgcolor="red.50" color="red.600">
      <Icons.XCircle />
    </IconWrapper>
  ),
  [NotificationSeverity.WARNING]: (
    <IconWrapper bgcolor="yellow.50" color="yellow.800">
      <Icons.Question />
    </IconWrapper>
  ),
  [NotificationSeverity.INFO]: (
    <IconWrapper bgcolor="yellow.50" color="yellow.800">
      <Icons.Question />
    </IconWrapper>
  ),
  [NotificationSeverity.SUCCESS]: (
    <IconWrapper bgcolor="green.50" color="green.500">
      <Icons.CheckCircle />
    </IconWrapper>
  ),
};

const SmallLight = withProps(Typography, {
  variant: 'P10R',
  color: 'grey.400',
  component: 'div',
} as any);

type Props = {
  notification?: Notification;
  isLoading?: boolean;
  onClick?: () => void;
};

type NotificationContainerProps = PropsWithChildren<
  Pick<Props, 'onClick'> & { icon?: ReactNode; testId?: string }
>;

const NotificationContainer = ({
  onClick,
  children,
  icon,
  testId,
}: NotificationContainerProps) => {
  return (
    <Stack
      direction="row"
      gap="12px"
      px="16px"
      sx={{
        cursor: onClick ? 'pointer' : undefined,
        '&:not(:first-of-type)': {
          borderTop: '1px solid',
          borderColor: 'grey.100',
        },
        '&:hover': {
          backgroundColor: (theme) => alpha(theme.palette.blue[50]!, 0.5),
        },
      }}
      pt="12px"
      pb="16px"
      onClick={onClick}
      data-testid={testId || 'notification-row'}
    >
      {icon}
      <Stack width="100%">{children}</Stack>
    </Stack>
  );
};

export const NotificationRow = ({
  notification,
  isLoading,
  onClick,
}: Props) => {
  if (isLoading) {
    return (
      <NotificationContainer
        icon={<Skeleton height={24} sx={{ minWidth: 24 }} />}
        testId="notification-row-skeleton"
      >
        <Skeleton height={20} sx={{ mt: '3px' }} />
        <Skeleton height={16} width={78} sx={{ mt: '12px' }} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pt="8px"
        >
          <Skeleton width={68} height={14} />
          <Skeleton width={52} height={14} />
        </Stack>
      </NotificationContainer>
    );
  }
  if (!notification) {
    return null;
  }
  const isSeen = !!notification.ackAt;
  const showMessage =
    !!notification.severity &&
    (
      [
        NotificationSeverity.ERROR,
        NotificationSeverity.CRITICAL,
      ] as NotificationSeverity[]
    ).includes(notification.severity);

  return (
    <NotificationContainer
      onClick={onClick}
      icon={
        IconsBySeverity[
          (notification.severity || '') as keyof typeof IconsBySeverity
        ]
      }
    >
      <Typography variant={isSeen ? 'P14R' : 'P14B'} pb="4px">
        {notification.name}
      </Typography>
      {showMessage && (
        <Typography variant="P12R" color="red.500" pt="4px" pb="2px">
          {notification.message}
        </Typography>
      )}
      <Stack
        direction="row"
        justifyContent={
          !notification.clusterMetadata?.project
            ? 'space-between'
            : 'flex-start'
        }
        alignItems="center"
      >
        <Typography variant="P12B" color="grey.500" pt="4px">
          {notification.clusterMetadata?.name || ''}
        </Typography>
        {!notification.clusterMetadata?.project && (
          <SmallLight>
            <TimeAgo time={notification.timestamp} noRounding />
          </SmallLight>
        )}
      </Stack>
      {!!notification.clusterMetadata?.project && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SmallLight>{notification.clusterMetadata?.project}</SmallLight>
          <SmallLight>
            <TimeAgo time={notification.timestamp} noRounding />
          </SmallLight>
        </Stack>
      )}
    </NotificationContainer>
  );
};
