import {
  ListItem,
  ListItemProps,
  ListItemText,
  Select,
  SelectProps,
} from '@cast/design-system';
import { OrganizationRole } from '@cast/types';

import {
  roleDescriptionMap,
  roleNameMap,
} from 'features/organization/constants';

const options: Record<
  OrganizationRole,
  { title: string; description: string }
> = {
  [OrganizationRole.owner]: {
    title: roleNameMap[OrganizationRole.owner],
    description: roleDescriptionMap[OrganizationRole.owner],
  },
  [OrganizationRole.member]: {
    title: roleNameMap[OrganizationRole.member],
    description: roleDescriptionMap[OrganizationRole.member],
  },
  [OrganizationRole.analyst]: {
    title: roleNameMap[OrganizationRole.analyst],
    description: roleDescriptionMap[OrganizationRole.analyst],
  },
  [OrganizationRole.viewer]: {
    title: roleNameMap[OrganizationRole.viewer],
    description: roleDescriptionMap[OrganizationRole.viewer],
  },
};

const values = Object.keys(options) as unknown as OrganizationRole[];

export const RoleSelect = (
  props: Partial<
    Pick<
      SelectProps<OrganizationRole, OrganizationRole, 'single'>,
      'onChange' | 'value' | 'sx' | 'testId' | 'disabled'
    >
  >
) => {
  return (
    <Select
      options={values}
      optionLabel={(option: OrganizationRole) => options[option].title}
      popoverProps={{
        anchorOrigin: { horizontal: 'right' },
        transformOrigin: { horizontal: 'right' },
      }}
      size="medium"
      renderOption={(option: OrganizationRole, props: ListItemProps) => (
        <ListItem component="li" {...props} sx={{ padding: '10px 12px' }}>
          <ListItemText
            primary={options[option].title}
            primaryTypographyProps={{ variant: 'P12M' }}
            secondary={options[option].description}
            secondaryTypographyProps={{ variant: 'P12R', color: 'grey.500' }}
          />
        </ListItem>
      )}
      {...props}
    />
  );
};
