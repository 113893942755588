import { ThemeOptions } from '@mui/material';
import range from 'lodash/range';

export enum DsShadow {
  NONE = 0,
  FOCUS_GLOW = 1,
  ACTIVE_GLOW = 2,
  HOVER_COMPONENT = 3,
  DROPDOWN = 4,
  SHADOW = 5,
  HOVER = 6,
  DRAWER = 7,
  TOOLTIP = 8,
}

const shadows = range(0, 25).map(() => 'none') as ThemeOptions['shadows'];

if (shadows) {
  shadows[DsShadow.FOCUS_GLOW] = '0px 0px 4px #7F61F0';
  shadows[DsShadow.ACTIVE_GLOW] = '0px 0px 2px #82B4FF';
  shadows[DsShadow.HOVER_COMPONENT] = '0px 1px 2px rgba(0, 0, 0, 0.25)';
  shadows[DsShadow.DROPDOWN] = '0px 2px 8px 2px rgba(93, 110, 117, 0.25)';
  shadows[DsShadow.SHADOW] = '2px 2px 8px 2px rgba(109, 127, 136, 0.06)';
  shadows[DsShadow.HOVER] = '0px 0px 15px 2px rgba(109, 127, 136, 0.15)';
  shadows[DsShadow.DRAWER] = '-4px 0px 24px 8px rgba(131, 146, 153, 0.1)';
  shadows[DsShadow.TOOLTIP] = '0px 2px 12px 2px rgba(93, 110, 117, 0.3)';
}

export const shadowsTheme = shadows;
