import { useMemo } from 'react';

import { useElementHeight } from '@cast/utils';

import { TreeUiConfig, TreeUiUtils } from '../../../../types';

type Props = {
  rowRoot: HTMLElement;
  subRow: HTMLElement;
  childWrapper: HTMLElement;
  config: TreeUiConfig;
  utils: TreeUiUtils;
  level: number;
};

const getSubRowExpander = (subRow: HTMLElement): HTMLElement | null => {
  const expanderEl = subRow.lastChild as HTMLElement;
  if (expanderEl?.attributes.getNamedItem('data-expander-wrapper')) {
    return expanderEl;
  }
  return null;
};

const getFirstRowMiddle = (subRow: HTMLElement) => {
  const expanderEl = getSubRowExpander(subRow);
  if (expanderEl) {
    // Has expander - ignore it's height
    return (subRow.clientHeight - expanderEl.clientHeight) / 2;
  }
  // Custom row rendered with renderRow
  return subRow.clientHeight / 2;
};

export const VerticalLine = ({
  rowRoot,
  subRow,
  childWrapper,
  config,
  utils,
  level,
}: Props) => {
  const rootHeight = useElementHeight(rowRoot);
  const childWrapperHeight = useElementHeight(childWrapper);

  const [lineTop, lineBottom] = useMemo(() => {
    const isChildRowsContainer = childWrapper.attributes.getNamedItem(
      'data-child-rows-container'
    );
    const childRows = childWrapper.children;
    if (!isChildRowsContainer || !childRows.length) {
      return [];
    }
    const lastRow = childRows.item(childRows.length - 1);

    if (!lastRow?.children.length) {
      return [];
    }
    let lastRowTreeRow: HTMLElement | null = null;
    let lastRowChildRows: HTMLElement | null = null;
    let lastRowExpanderWrapper: HTMLElement | null = null;
    for (let i = 0; i < lastRow?.children.length; i++) {
      const el = lastRow.children.item(i) as HTMLElement;

      if (
        !lastRowTreeRow &&
        el?.attributes.getNamedItem('data-tree-row-wrapper')
      ) {
        lastRowTreeRow = el;
        lastRowExpanderWrapper = getSubRowExpander(lastRowTreeRow);
      } else if (
        lastRowTreeRow &&
        !lastRowChildRows &&
        !!el?.attributes.getNamedItem('data-child-rows-container')
      ) {
        lastRowChildRows = el;
      }
    }
    const lineOffsetFromIconMiddle =
      config.iconSize / 2 + config.distanceFromLineToIcon;
    const firstRowMiddle = getFirstRowMiddle(subRow);
    const lineTop = firstRowMiddle + lineOffsetFromIconMiddle;
    if (!lastRowTreeRow) {
      return [];
    }
    if (!lastRowChildRows) {
      // vertical line ends on row without expander icon (without children)
      return [
        lineTop,
        (lastRowTreeRow.clientHeight +
          (lastRowExpanderWrapper?.clientHeight || 0)) /
          2,
      ];
    }

    // vertical line ends on row with expander icon (with children)
    return [
      lineTop,
      lastRowChildRows.clientHeight +
        firstRowMiddle +
        (lastRowExpanderWrapper?.clientHeight || 0),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootHeight, childWrapperHeight]);

  if (lineBottom) {
    return (
      <div
        className="DS-Table-TreeMarker DS-Table-TreeMarker-vertical"
        style={{
          left: utils.getVerticalLineIndentation(level),
          top: lineTop,
          bottom: lineBottom,
        }}
      />
    );
  }
  return null;
};
