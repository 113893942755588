export const getCookie = (name: string): string | undefined => {
  const cookies = document.cookie.split('; ');
  const testCookieName = `${name}=`;
  for (const cookie of cookies) {
    if (cookie.startsWith(testCookieName)) {
      return cookie.substring(testCookieName.length);
    }
  }
};

export const deleteCookie = ({
  name,
  domain = '.cast.ai',
  path = '/',
}: {
  name: string;
  domain?: string;
  path?: string;
}): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=${path};`;
};
