import {
  RoleAccess,
  RbacGroupRef,
  RbacPermissions,
  UserResourceBinding,
  GroupResourceBinding,
  RbacRoleBinding,
} from '@cast/types';

type Access = {
  id: string;
  access: RoleAccess;
  accessVia: AccessVia;
  hasAccessTo: RbacPermissions['endpoints'];
};

type AccessWithBinding = Access & { accessViaBinding: AccessViaBinding };

export type ResourceAccessByResourceId = Record<string, ResourceAccess>;

export type ControlData = {
  organizations: ResourceAccessByResourceId;
  clusters: ResourceAccessByResourceId;
};

export type ControlDataBySubjectId = Record<string, ControlData>;

export type AccessControlContextState = {
  isLoading: boolean;
  accessControlData: ControlDataBySubjectId;
  refetch: () => void;
  error: boolean;
};

export type ResourceAccess = Access | AccessWithBinding;

export type ScopeAccess = Record<string, ResourceAccess>;

export type AccessControlData = Record<
  string,
  {
    organizations: ScopeAccess;
    clusters: ScopeAccess;
  }
>;

export type SubjectResourceBinding = UserResourceBinding | GroupResourceBinding;

export const isUserResourceBinding = (
  binding: SubjectResourceBinding
): binding is UserResourceBinding => {
  return (binding as UserResourceBinding).userId !== undefined;
};

export type AccessViaBinding = {
  bindingId: string;
  definition: RbacRoleBinding['definition'];
  status: 'active' | 'processing' | 'error';
  statusChangedAt?: string;
  errorMessage?: string;
};

export type AccessVia = 'direct' | 'inherited' | RbacGroupRef[];
