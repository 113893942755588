import { createContext } from 'react';

import { FieldArrayRenderProps } from 'react-final-form-arrays';

export type RffInputsArrayState = {
  add: () => void;
  remove: (index: number) => void;
};

export const RffInputsArrayContext = createContext<RffInputsArrayState>(
  undefined as never
);

export type RffInputsArrayRowState<Value = any> = {
  field: string;
  index: number;
  isLast: boolean;
  isOnly: boolean;
  remove: () => void;
  fields: FieldArrayRenderProps<Value, any>['fields'];
};

export const RffInputsArrayRowContext = createContext<RffInputsArrayRowState>(
  undefined as never
);
