import { ThemeOptions } from '@mui/material';

export const dialogTheme: ThemeOptions['components'] = {
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiBackdrop-root': {
          backgroundColor: 'black',
          // Opacity is set as style attribute by mui
          opacity: '0.85 !important',
        },
      },
      paper: {
        padding: 0,
        '&.DS-DialogSize': {
          '&Small': {
            width: 440,
            maxWidth: 440,
          },
          '&Medium': {
            width: 560,
            maxWidth: 560,
          },
          '&Large': {
            width: 940,
            maxWidth: 940,
          },
          '&Xlarge': {
            width: 1024,
            maxWidth: 1024,
          },
        },
      },
    },
  },
  DsDialogActions: {
    styleOverrides: {
      root: {
        '& > :not(:first-of-type)': {
          margin: 0,
        },
      },
    },
  },
};
