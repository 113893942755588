import { ReactElement } from 'react';

import { styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import {
  ChipProps,
  Icons,
  withProps,
  Spinner,
  SpinnerProps,
} from '@cast/design-system';
import { ClusterDisplayStatus } from '@cast/types';

import { StatusChipBase } from '../StatusChip';

const Loader = styled(
  withProps(Spinner, {
    thickness: 4,
    disableShrink: true,
    noBackground: true,
  })
)<SpinnerProps>({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'inherit !important',
});

// prettier-ignore
const clusterLabelMap: Record<ClusterDisplayStatus, string> = {
  [ClusterDisplayStatus.WAITING_CONNECTION]: 'Connecting',
  [ClusterDisplayStatus.CONNECTING]: 'Connecting',
  [ClusterDisplayStatus.READ_ONLY]: 'Read only',
  [ClusterDisplayStatus.READY]: 'Connected',
  [ClusterDisplayStatus.PAUSED]: 'Paused',
  [ClusterDisplayStatus.PAUSING]: 'Pausing',
  [ClusterDisplayStatus.RESUMING]: 'Resuming',
  [ClusterDisplayStatus.DELETED]: 'Deleted',
  [ClusterDisplayStatus.DELETING]: 'Deleting',
  [ClusterDisplayStatus.WARNING]: 'Warning',
  [ClusterDisplayStatus.FAILED]: 'Failed',
  [ClusterDisplayStatus.ONLINE]: 'Online',
  [ClusterDisplayStatus.DISCONNECTED]: 'Disconnected',
  [ClusterDisplayStatus.DISCONNECTING]: 'Disconnecting',
  [ClusterDisplayStatus.NOT_RESPONDING]: 'Not Responding',
};

// prettier-ignore
const clusterIconMap: Partial<Record<ClusterDisplayStatus, ReactElement>> = {
  [ClusterDisplayStatus.READ_ONLY]: <Icons.Eye />,
  [ClusterDisplayStatus.READY]: <Icons.CheckCircle />,
  [ClusterDisplayStatus.PAUSED]: <Icons.Pause />,
  [ClusterDisplayStatus.WARNING]: <Icons.Warning />,
  [ClusterDisplayStatus.FAILED]: <Icons.Warning />,
  [ClusterDisplayStatus.ONLINE]: <Icons.CheckCircle />,
  [ClusterDisplayStatus.DISCONNECTED]: <Icons.XCircle />,
  [ClusterDisplayStatus.NOT_RESPONDING]: <Icons.Warning />,
};

const inProgressStatus = [
  ClusterDisplayStatus.WAITING_CONNECTION,
  ClusterDisplayStatus.CONNECTING,
  ClusterDisplayStatus.PAUSING,
  ClusterDisplayStatus.RESUMING,
  ClusterDisplayStatus.DELETING,
  ClusterDisplayStatus.DISCONNECTING,
];
type OwnerState = {
  status: ClusterDisplayStatus;
};

export const StyledStatusChipBase = styled(StatusChipBase, {
  target: 'StatusChip-root',
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<
  ChipProps & {
    hasCaption: boolean;
    ownerState?: OwnerState;
  }
>(({ theme, ownerState }) => {
  return [
    ownerState &&
      [
        ClusterDisplayStatus.WAITING_CONNECTION,
        ClusterDisplayStatus.CONNECTING,
        ClusterDisplayStatus.DISCONNECTING,
        ClusterDisplayStatus.CONNECTING,
        ClusterDisplayStatus.DELETED,
        ClusterDisplayStatus.DELETING,
      ].includes(ownerState.status) && {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[600],
      },
    ownerState &&
      [
        ClusterDisplayStatus.ONLINE,
        ClusterDisplayStatus.READ_ONLY,
        ClusterDisplayStatus.READY,
      ].includes(ownerState.status) && {
        backgroundColor: theme.palette.green[50],
        color: theme.palette.green[600],
      },
    ownerState &&
      [
        ClusterDisplayStatus.NOT_RESPONDING,
        ClusterDisplayStatus.PAUSED,
        ClusterDisplayStatus.WARNING,
      ].includes(ownerState.status) && {
        backgroundColor: theme.palette.yellow[50],
        color: theme.palette.yellow[900],
      },
    ownerState &&
      [ClusterDisplayStatus.DISCONNECTED, ClusterDisplayStatus.FAILED].includes(
        ownerState.status
      ) && {
        backgroundColor: theme.palette.red[50],
        color: theme.palette.red[500],
      },
    ownerState &&
      [ClusterDisplayStatus.PAUSING, ClusterDisplayStatus.RESUMING].includes(
        ownerState.status
      ) && {
        backgroundColor: theme.palette.blue[50],
        color: theme.palette.blue[500],
      },
  ];
});

export type ClusterStatusChipProps = {
  className?: string;
  status: ClusterDisplayStatus;
  managed?: boolean;
  size?: ChipProps['size'];
  iconChip?: ChipProps['iconChip'];
  disableCaption?: boolean;
  sx?: SxProps;
};

export const ClusterStatusChip = ({
  className,
  status,
  managed,
  size = 'large',
  iconChip = false,
  disableCaption = false,
  sx,
}: ClusterStatusChipProps) => {
  let caption =
    !managed && status !== ClusterDisplayStatus.READ_ONLY
      ? clusterLabelMap[ClusterDisplayStatus.READ_ONLY]
      : undefined;

  if (
    status === ClusterDisplayStatus.CONNECTING ||
    status === ClusterDisplayStatus.WAITING_CONNECTION
  ) {
    caption = 'ENABLING CAST AI...';
  }

  let icon = null;

  if (inProgressStatus.includes(status)) {
    icon = <Loader />;
  } else if (!managed) {
    icon = <Icons.Eye />;
  } else {
    icon = clusterIconMap[status];
  }

  return (
    <StyledStatusChipBase
      className={className}
      hasCaption={!disableCaption && !!caption}
      ownerState={{ status }}
      iconChip={iconChip}
      size={size}
      startIcon={icon}
      sx={sx}
      testId="cluster-status-chip"
    >
      {!iconChip && (
        <>
          {clusterLabelMap[status] || 'Unknown'}
          {!disableCaption && caption && (
            <Typography
              className="StatusChip-Caption"
              display="flex"
              fontSize="8px"
              fontWeight={400}
              lineHeight="10px"
              textTransform="uppercase"
              letterSpacing="0.5px"
            >
              {caption}
            </Typography>
          )}
        </>
      )}
    </StyledStatusChipBase>
  );
};
