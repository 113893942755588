import { useMemo } from 'react';

import { useSecurityInsightsAgents } from 'hooks/queries/security-insights';

import { useActiveClusters } from '../../_hooks';

export const useHasNoAgentsEnabled = () => {
  const { clusters } = useActiveClusters();
  const clusterIds = useMemo(
    () => clusters?.map((cluster) => cluster.id) || [],
    [clusters]
  );
  const { agentsData, isLoading, error, refetch } = useSecurityInsightsAgents({
    clusterIds,
  });
  const hasNoAgentsEnabled = useMemo(
    () =>
      Object.values(agentsData?.agentStatuses || {}).every((agent) => !agent),
    [agentsData?.agentStatuses]
  );

  return { hasNoAgentsEnabled, isLoading, error, refetch };
};
