import { useMemo } from 'react';

import { SxProps } from '@mui/system';

import { Avatar, AvatarProps, stringToHash } from '@cast/design-system';

export type ProfileAvatarProps = {
  name?: string;
  email?: string;
  color?: 'blue' | 'green' | 'indigo' | 'aqua' | 'yellow' | 'red';
  size: AvatarProps['size'];
  sx?: SxProps;
  disabled?: boolean;
};

const avatarColors: Array<ProfileAvatarProps['color']> = [
  'blue',
  'green',
  'indigo',
  'aqua',
  'yellow',
  'red',
];

export const ProfileAvatar = ({
  name,
  email,
  color,
  ...props
}: ProfileAvatarProps) => {
  const text = email || name || '';

  const _color = useMemo(() => {
    if (color) {
      return color;
    }
    return avatarColors[stringToHash(text) % avatarColors.length];
  }, [color, text]);

  return <Avatar variant="circular" text={text} color={_color} {...props} />;
};
