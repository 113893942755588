import { useEffect, useRef } from 'react';

import { SxProps } from '@mui/material';

import { Autocomplete, Skeleton, mergeSx } from '@cast/design-system';

import { useSearchCriterion } from 'components/search';
import {
  useLabelsAutocompleteProps,
  LabelsAutocompleteProps,
} from 'hooks/useLabelsAutocompleteProps';

export type LabelsSelectProps = LabelsAutocompleteProps & {
  isLoading: boolean;
  sx?: SxProps;
  initialValue?: string[];
};

export const LabelsSelect = ({
  isLoading,
  labels,
  error,
  refetch,
  sx,
  criterion = 'labels',
  initialValue,
}: LabelsSelectProps) => {
  const labelsAutocompleteProps = useLabelsAutocompleteProps({
    labels,
    error,
    refetch,
    criterion,
  });

  const { setValue } = useSearchCriterion(criterion);
  const wasInitialized = useRef(false);
  useEffect(() => {
    if (!initialValue || wasInitialized.current) {
      return;
    }
    setValue?.(initialValue);
    wasInitialized.current = true;
  }, [initialValue, setValue]);

  if (isLoading) {
    return <Skeleton height={32} sx={mergeSx({ width: '100%' }, sx)} />;
  }

  return (
    <Autocomplete
      testId="label-select"
      {...labelsAutocompleteProps}
      sx={mergeSx({ width: '100%' }, sx)}
    />
  );
};
