import { Area } from 'recharts';

export class BaseArea extends Area {
  static defaultProps = {
    ...Area.defaultProps,
    // Customized props
    type: 'linear',
    connectNulls: true,
    tooltipType: 'none',
    pointerEvents: 'none',
    activeDot: false,
  };
}
