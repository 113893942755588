import { useSearchContext } from './useSearchContext';
import { Criterion } from '../types';

type CriterionController<V> = {
  value: V;
  setValue: (value: V | V[]) => void;
  clear: () => void;
  criterion?: Criterion<V>;
};

type CriterionOrString<V = any> = V extends string ? string : Criterion<V>;

export function useSearchCriterion<V = any>(
  criterion?: CriterionOrString<V>
): V extends string ? CriterionController<any> : CriterionController<V> {
  const context = useSearchContext();
  if (!criterion || !context) {
    return {
      value: undefined,
      setValue: () => {},
      clear: () => {},
      criterion,
    } as any;
  }
  const criterionKey =
    typeof criterion === 'string' ? criterion : criterion.key;
  return {
    value: context.values[criterionKey],
    setValue: (value: any) => {
      if (Array.isArray(value) && !value.length) {
        context.clearValue(criterionKey);
      } else {
        context.setValue(criterionKey, value);
      }
    },
    clear: () => context.clearValue(criterionKey),
    criterion: context?.criteria?.find((c) => c.key === criterionKey),
  } as any;
}
