import { LinearProgress, LinearProgressProps } from '@mui/material';
import clsx from 'clsx';

export type ProgressBarProps = LinearProgressProps & {
  size: 'small' | 'medium' | 'large' | 'xlarge';
  disabled?: boolean;
};

export const ProgressBar = (props: ProgressBarProps) => {
  return (
    <LinearProgress
      {...props}
      className={clsx(
        props.className,
        `DSuiProgressBar-size-${props.size}`,
        props.disabled && 'DSuiProgressBar-disabled'
      )}
    />
  );
};
