import {
  demoAdvancedEvictorConfig,
  demoAutoscalerFeatures,
  demoClusterFilterInstanceTypes,
  demoClusterGenerateRebalancingPlan,
  demoClusterNodeSelectionList,
  demoClusterPolicies,
  demoClusterRebalancingPlan,
  demoClusterRebalancingPlans,
  demoClusterRebalancingWorkloads,
  demoNodeConstraints,
  demoPodMutation,
  demoPodMutationsList,
} from '@cast/fixtures';
import { PodMutatorStatus } from '@cast/types';

import { AutoscalerApiAdapter } from '../api-client/autoscaler-api-adapter';
import {
  isDemoClusterRequest,
  rejectDemoClusterRequest,
  resolveDemoClusterRequest,
} from '../utils';

export const AutoscalerApiProxy = (
  origin: ReturnType<typeof AutoscalerApiAdapter>
): ReturnType<typeof AutoscalerApiAdapter> => {
  return {
    ...origin,
    getPolicies: async (...args: Parameters<typeof origin.getPolicies>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterPolicies();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getPolicies(...args);
    },
    getNodeConstraints: async (
      ...args: Parameters<typeof origin.getNodeConstraints>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoNodeConstraints();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getNodeConstraints(...args);
    },
    getSelectionTemplates: async (
      ...args: Parameters<typeof origin.getSelectionTemplates>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterNodeSelectionList();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getSelectionTemplates(...args);
    },
    upsertPolicies: (...args: Parameters<typeof origin.upsertPolicies>) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.upsertPolicies(...args);
    },
    createSelectionTemplate: (
      ...args: Parameters<typeof origin.createSelectionTemplate>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.createSelectionTemplate(...args);
    },
    updateSelectionTemplate: (
      ...args: Parameters<typeof origin.updateSelectionTemplate>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.updateSelectionTemplate(...args);
    },
    deleteSelectionTemplate: (
      ...args: Parameters<typeof origin.deleteSelectionTemplate>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.deleteSelectionTemplate(...args);
    },
    getInstanceTypes: async (
      ...args: Parameters<typeof origin.getInstanceTypes>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterFilterInstanceTypes();
        return resolveDemoClusterRequest(fixture);
      }
      return origin.getInstanceTypes(...args);
    },
    getAgentInstallScript: async (
      ...args: Parameters<typeof origin.getAgentInstallScript>
    ) => {
      return origin.getAgentInstallScript(...args);
    },
    listRebalancingPlans: async (
      ...args: Parameters<typeof origin.listRebalancingPlans>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRebalancingPlans();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.listRebalancingPlans(...args);
    },
    getAutoscalerFeatures: async (
      ...args: Parameters<typeof origin.getAutoscalerFeatures>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAutoscalerFeatures();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAutoscalerFeatures(...args);
    },
    getRebalancingPlan: async (
      ...args: Parameters<typeof origin.getRebalancingPlan>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRebalancingPlan(
          args[0].rebalancingPlanId
        );
        return resolveDemoClusterRequest(fixture!);
      }

      return origin.getRebalancingPlan(...args);
    },
    getWorkloads: async (...args: Parameters<typeof origin.getWorkloads>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterRebalancingWorkloads();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getWorkloads(...args);
    },
    generateRebalancingPlan: async (
      ...args: Parameters<typeof origin.generateRebalancingPlan>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterGenerateRebalancingPlan(
          args[0].body.rebalancingNodes?.map((n: any) => n.nodeId!) || []
        );
        return resolveDemoClusterRequest(fixture);
      }

      return origin.generateRebalancingPlan(...args);
    },
    executeRebalancingPlan: (
      ...args: Parameters<typeof origin.executeRebalancingPlan>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.executeRebalancingPlan(...args);
    },
    updateAdvancedEvictorConfig: (
      ...args: Parameters<typeof origin.updateAdvancedEvictorConfig>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.updateAdvancedEvictorConfig(...args);
    },
    getAdvancedEvictorConfig: async (
      ...args: Parameters<typeof origin.getAdvancedEvictorConfig>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoAdvancedEvictorConfig();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getAdvancedEvictorConfig(...args);
    },
    getPodMutations: async (
      ...args: Parameters<typeof origin.getPodMutations>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoPodMutationsList();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getPodMutations(...args);
    },
    getPodMutation: async (
      ...args: Parameters<typeof origin.getPodMutation>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoPodMutation(args[0].id);
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getPodMutation(...args);
    },
    createPodMutation: (
      ...args: Parameters<typeof origin.createPodMutation>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.createPodMutation(...args);
    },
    updatePodMutation: (
      ...args: Parameters<typeof origin.updatePodMutation>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.updatePodMutation(...args);
    },
    deletePodMutation: (
      ...args: Parameters<typeof origin.deletePodMutation>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.deletePodMutation(...args);
    },
    getMutatorStatus: async (
      ...args: Parameters<typeof origin.getMutatorStatus>
    ) => {
      if (isDemoClusterRequest(args)) {
        return resolveDemoClusterRequest({ status: PodMutatorStatus.RUNNING });
      }

      return origin.getMutatorStatus(...args);
    },
  };
};
