import { forwardRef, useEffect, useState } from 'react';

import { Box, BoxProps, Stack, styled, useTheme } from '@mui/material';

import { LogoFullSvg, LogoSmallSvg } from 'assets/svg';
import { IS_PROD_EU } from 'common/constants';
import { SwipeableViews } from 'components/boxes';
import { LabelChip } from 'components/chips';
import { Link } from 'components/router';
import { isE2E } from 'utils/isE2E';

import { ClusterLevelSidebar } from './_components/ClusterLevelSidebar';
import { ExtraFeaturesSidebar } from './_components/ExtraFeaturesSidebar';
import { OrganizationLevelSidebar } from './_components/OrganizationLevelSidebar';
import { OrganizationManagementSidebar } from './_components/OrganizationManagementSidebar';
import { SidebarSizeSwitcher } from './_components/SidebarSizeSwitcher';
import { useSidebarCluster } from './_hooks/useSidebarCluster';
import { useSidebarOrganization } from './_hooks/useSidebarOrganization';
import { useMainLayout } from '../hooks';

const minimizedWidth = '64px';

const maximizedWidth = '232px';

const Container = styled(Stack)(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  alignSelf: 'stretch',
  background: theme.palette.grey[100],

  '&:before': {
    content: '""',
    position: 'absolute',
    width: '40px',
    height: '100%',
    left: '-40px',
    top: '0px',
    background:
      'linear-gradient(270deg, rgba(8, 41, 57, 0.05) 0%, rgba(8, 41, 57, 0) 72.92%)',
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    width: '40px',
    height: '100%',
    right: '-40px',
    top: '0px',
    background:
      'linear-gradient(90deg, rgba(8, 41, 57, 0.05) 0%, rgba(8, 41, 57, 0) 72.92%)',
  },
  '& .sidebar-size-switcher': {
    opacity: 0,
  },
  '&:hover .sidebar-size-switcher': {
    opacity: 1,
  },
  '&:has(:focus-visible) .sidebar-size-switcher': {
    opacity: 1,
  },
}));

type SidebarProps = BoxProps;

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(
  ({ ...boxProps }: SidebarProps, ref) => {
    const theme = useTheme();
    const { sidebarState } = useMainLayout();
    const cluster = useSidebarCluster();
    const organization = useSidebarOrganization();
    const [activeSidebar, setActiveSidebar] = useState<
      'main' | 'cluster' | 'organization-management'
    >('main');

    useEffect(() => {
      if (cluster && activeSidebar !== 'cluster') {
        setActiveSidebar('cluster');
        return;
      }

      if (organization && activeSidebar !== 'organization-management') {
        setActiveSidebar('organization-management');
        return;
      }

      if (!cluster && !organization) {
        setActiveSidebar('main');
      }
    }, [activeSidebar, cluster, organization]);

    if (!sidebarState) {
      return null;
    }

    const isMinimized = sidebarState === 'minimized';

    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        position="sticky"
        top={0}
        height="100vh"
        zIndex={1}
        sx={{ backgroundColor: 'grey.100' }}
        data-testid="sidebar"
        {...boxProps}
        ref={ref}
      >
        <Container width={isMinimized ? minimizedWidth : maximizedWidth}>
          <Box
            display="grid"
            gridTemplateRows="max-content max-content auto"
            overflow={cluster ? 'unset' : 'hidden'}
          >
            <Box position="absolute" top="38px" right="-10px" zIndex="1">
              <SidebarSizeSwitcher
                className="sidebar-size-switcher"
                sx={{
                  '&:hover svg': {
                    color: 'grey.900',
                  },
                }}
              />
            </Box>

            <Stack
              px={isMinimized ? '0px' : '8px'}
              ml={isMinimized ? '0px' : '13px'}
              height="60px"
              py="14px"
              alignItems={isMinimized ? 'center' : 'flex-start'}
              justifyContent="center"
            >
              <Link
                to="/dashboard"
                style={{ textDecoration: 'none' }}
                data-testid="app-logo"
              >
                {isMinimized ? (
                  <LogoSmallSvg
                    aria-label="logo"
                    color={theme.palette.grey[800]}
                  />
                ) : (
                  <Stack direction="row" gap={4}>
                    <LogoFullSvg
                      aria-label="logo"
                      color={theme.palette.grey[800]}
                    />
                    {IS_PROD_EU && (
                      <LabelChip
                        size="micro"
                        sx={{
                          backgroundColor: 'grey.800',
                          color: 'blue.50',
                          mt: 11,
                        }}
                      >
                        EU
                      </LabelChip>
                    )}
                  </Stack>
                )}
              </Link>
            </Stack>
          </Box>

          <SwipeableViews
            contentBefore={
              activeSidebar !== 'organization-management' && (
                <OrganizationLevelSidebar />
              )
            }
            slides={{
              main: <ExtraFeaturesSidebar />,
              cluster: <ClusterLevelSidebar />,
              'organization-management': <OrganizationManagementSidebar />,
            }}
            disableAnimations={isE2E()}
            activeSlide={activeSidebar}
          />
        </Container>
      </Box>
    );
  }
);

Sidebar.displayName = 'Sidebar';
