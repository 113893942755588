import { DotProps } from 'recharts';

type Props = DotProps & {
  index?: number;
  highlightTimestamp?: (payload: any) => boolean;
  payload?: { timestamp?: string };
};

export const HiddenDot = ({ cx = 0, cy = 0, ...props }: Props) => {
  return (
    <g data-testid={`chart-dot-${props?.index}`}>
      <circle cx={cx} cy={cy} r={1.5} fill="none" />
    </g>
  );
};
