import { forwardRef, useEffect, useState } from 'react';

import { Drawer, DrawerProps, useDrawerInstance } from '@cast/design-system';

import { DrawerContent, DrawerHeader } from 'components/drawer';
import { DestroyDrawerFormGuard } from 'components/drawer/ds';
import { useCreateWebhookMutation } from 'hooks/mutations/notifications';

import { DrawerTitle } from './DrawerTitle';
import { WebhookForm, makeApiModel } from './form';

type Props = DrawerProps;

export const AddWebhookDrawer = forwardRef<HTMLDivElement, Props>(
  (drawerProps, ref) => {
    const [formIsDirty, setFormIsDirty] = useState(false);
    const { close, shouldRenderGuard, setOnCloseGuard } = useDrawerInstance();
    const { mutateAsync } = useCreateWebhookMutation(close);

    useEffect(() => {
      if (formIsDirty) {
        setOnCloseGuard(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formIsDirty]);

    return (
      <Drawer
        ref={ref}
        {...drawerProps}
        size="medium"
        testId="add-webhook-drawer"
      >
        <DrawerHeader noDivider onClose={() => close()} sticky={false}>
          <DrawerTitle mt={24}>ADD WEBHOOK</DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <WebhookForm
            mode="create"
            onSubmit={async (model) => {
              return await mutateAsync(makeApiModel(model));
            }}
            onCancel={() => close()}
            onDirty={setFormIsDirty}
          />
        </DrawerContent>

        {shouldRenderGuard && (
          <DestroyDrawerFormGuard
            onContinue={() => {
              setOnCloseGuard(false);
              setTimeout(() => {
                if (formIsDirty) {
                  setOnCloseGuard(true);
                }
              }, 0);
            }}
            onLeave={close}
          />
        )}
      </Drawer>
    );
  }
);

AddWebhookDrawer.displayName = 'AddWebhookDrawer';
