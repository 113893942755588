import { CircularProgress, CircularProgressProps } from '@mui/material';
import clsx from 'clsx';

export type SpinnerProps = CircularProgressProps & {
  noBackground?: boolean;
  testId?: string;
};

export const Spinner = ({
  className,
  noBackground,
  size,
  testId,
  ...rest
}: SpinnerProps) => {
  return (
    <CircularProgress
      disableShrink
      thickness={4}
      className={clsx(
        'DS-Spinner-root',
        !noBackground && 'DS-Spinner-withBackground',
        className
      )}
      size="1em"
      sx={{
        fontSize: size,
      }}
      data-testid={testId}
      {...rest}
    />
  );
};
