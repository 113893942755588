import { forwardRef } from 'react';

import IconBase, { IconProps } from '../IconBase';

const renderFill = (color?: string, borderColor?: string) => (
  <>
    <rect x="5" y="5" width="22" height="22" rx="2" fill={color} />
    {borderColor && (
      <rect
        x="5"
        y="5"
        width="22"
        height="22"
        rx="2"
        strokeWidth="2"
        fill="none"
        stroke={borderColor}
      />
    )}
    <rect
      x="11.5"
      y="11.5"
      width="9"
      height="9"
      rx="2"
      strokeWidth="2"
      fill="none"
      stroke="white"
    />
  </>
);

const renderRegular = (color?: string, borderColor?: string) => (
  <>
    <rect
      x="5"
      y="5"
      width="22"
      height="22"
      rx="2"
      fill="none"
      strokeWidth="2"
      stroke="white"
    />
    {borderColor && (
      <rect
        x="5"
        y="5"
        width="22"
        height="22"
        rx="2"
        fill="none"
        strokeWidth="2"
        stroke={borderColor}
      />
    )}
    <rect
      x="11.5"
      y="11.5"
      width="9"
      height="9"
      rx="2"
      strokeWidth="2"
      fill="none"
      stroke={color}
    />
  </>
);

type NodeIconProps = {
  borderColor?: string;
} & IconProps;
export const NodeIcon = forwardRef<SVGSVGElement, NodeIconProps>(
  ({ borderColor, ...props }, ref) => {
    return (
      <IconBase ref={ref} {...props}>
        {props.weight === 'fill'
          ? renderFill(props.color, borderColor)
          : renderRegular(props.color, borderColor)}
      </IconBase>
    );
  }
);

NodeIcon.displayName = 'NodeIcon';
