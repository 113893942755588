import { TypographyProps } from '@mui/material';

import { MoneyPerPeriod } from 'components/money';
import { useAvailableSavingsQuery } from 'hooks/queries/available-savings';
import { useCluster } from 'hooks/useCluster';

type Props = {
  savings: number;
  suffix: string;
  primaryTextProps?: TypographyProps;
  suffixTextProps?: TypographyProps;
  testId?: string;
};

export const MoneySavings = ({
  savings,
  suffix,
  primaryTextProps,
  suffixTextProps,
  testId,
}: Props) => {
  const { cluster } = useCluster();
  const { isLoading, error } = useAvailableSavingsQuery(cluster.id);

  return (
    <MoneyPerPeriod
      primaryTextProps={primaryTextProps}
      suffix={suffix}
      moneyProps={{ amount: isLoading || !!error ? 0 : savings }}
      suffixTextProps={suffixTextProps}
      testId={testId}
    />
  );
};
