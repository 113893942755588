import { useQuery } from '@tanstack/react-query';

import { apiClient, QueryKeys } from 'core/react-query';
import { useOrganizations } from 'hooks/useOrganizations';

export const useRbacRolesQuery = (enabled = true) => {
  const { currentOrganization } = useOrganizations();
  const organizationId = currentOrganization?.id;

  return useQuery({
    queryKey: [QueryKeys.RBAC_ROLES, organizationId],
    queryFn: async () => {
      const { data } = await apiClient.rbac.listRoles({
        organizationId: organizationId!,
        pageLimit: '9999',
      });
      return data.roles ?? [];
    },
    enabled: enabled && !!organizationId,
  });
};
