import { Popover, PopoverProps, styled } from '@mui/material';

import { Card, DsShadow } from '@cast/design-system';

const HeaderPopperCard = styled(Card, {
  name: 'DsCard',
  slot: 'Root',
  overridesResolver: (_, styles) => {
    return [styles.popper];
  },
})(({ theme }) => ({
  borderRadius: '4px 4px 0 0',
  boxShadow: theme.shadows[DsShadow.DROPDOWN],
  padding: 0,
}));

HeaderPopperCard.displayName = 'HeaderPopperCard';

export const HeaderPopover = ({
  children,
  testId,
  ...props
}: PopoverProps & { testId?: string }) => {
  return (
    <Popover
      {...props}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: -10,
        horizontal: 'right',
      }}
      disableScrollLock
    >
      <HeaderPopperCard testId={testId}>{children}</HeaderPopperCard>
    </Popover>
  );
};
