import { useCallback, useEffect, useState } from 'react';

import { REPLACE_STATE_EVENT } from './constants';

/**
 * Custom event listener hook for `window.history.replaceState`
 * since `replaceState` doesn't trigger any listeners.
 * @param callback Memoized callback function
 */
export const useReplaceStateListener = (callback: (url: string) => void) => {
  useEffect(() => {
    const handler = (event: Event) => {
      const customEvent = event as CustomEvent<{ url: string }>;
      callback(customEvent.detail.url);
    };
    window.addEventListener(REPLACE_STATE_EVENT, handler);
    return () => {
      window.removeEventListener(REPLACE_STATE_EVENT, handler);
    };
  }, [callback]);
};

export type UsePathOptions = {
  enabled?: boolean;
};

export const usePath = (
  getPath: () => string,
  options: UsePathOptions = {}
) => {
  const { enabled = true } = options;
  const [path, setPath] = useState(() => (enabled ? getPath() : ''));
  const handler = useCallback(() => {
    setPath(enabled ? getPath() : '');
  }, [enabled, getPath]);
  useReplaceStateListener(handler);
  useEffect(() => {
    handler();
  }, [handler]);
  return path;
};
