import { createContext, PropsWithChildren } from 'react';

import { CodePreviewState } from '../types';

export const PreviewStateContext = createContext<CodePreviewState>({});

export const PreviewStateProvider = ({
  children,
  formattedCode,
}: PropsWithChildren<CodePreviewState>) => {
  return (
    <PreviewStateContext.Provider value={{ formattedCode }}>
      {children}
    </PreviewStateContext.Provider>
  );
};
