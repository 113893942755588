import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
type TableBodyHeightSyncValues = {
  registeredTableHeights: number[];
  registerTableHeight: (height: number) => void;
  minHeight: number | undefined;
};

const TableBodyHeightSyncContext = createContext<TableBodyHeightSyncValues>(
  {} as never
);

export const TableBodyHeightSyncProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [registeredTableHeights, setRegisteredTableHeights] = useState<
    number[]
  >([]);

  let minHeight: number | undefined;
  if (registeredTableHeights.length) {
    minHeight = Math.max(...registeredTableHeights);
  }

  const registerTableHeight = useCallback((height: number) => {
    setRegisteredTableHeights((current) => [...new Set([...current, height])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableBodyHeightSyncContext.Provider
      value={{
        minHeight,
        registeredTableHeights,
        registerTableHeight,
      }}
    >
      {children}
    </TableBodyHeightSyncContext.Provider>
  );
};

export const useTableBodyHeightSync = () => {
  return useContext(TableBodyHeightSyncContext);
};
