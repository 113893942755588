import { ChipProps, mergeSx } from '@cast/design-system';

import { LabelChip } from 'components/chips';

export const EarlyAccessLabel = (props: ChipProps) => (
  <LabelChip
    size="micro"
    {...props}
    sx={mergeSx(
      {
        backgroundColor: 'green.100',
        color: 'green.500',
        mr: 'auto',
        alignSelf: 'flex-start',
        transform: 'translateX(-8px)',

        '& > *': {
          cursor: 'inherit!important',
        },
      },
      props?.sx
    )}
  >
    Early access
  </LabelChip>
);
