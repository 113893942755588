import {
  capitalize,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import clsx from 'clsx';

export type DialogSize = 'small' | 'medium' | 'large' | 'xlarge';

export type DialogProps = MuiDialogProps & {
  size?: DialogSize;
  disableCloseOnBackdropClick?: boolean;
  testId?: string;
};

const Dialog = ({
  size = 'medium',
  PaperProps = {},
  children,
  disableCloseOnBackdropClick,
  testId,
  ...rest
}: DialogProps) => {
  return (
    <MuiDialog
      PaperProps={{
        ...PaperProps,
        className: clsx(
          'DS-Dialog-root',
          `DS-DialogSize${capitalize(size)}`,
          PaperProps?.className
        ),
      }}
      {...rest}
      onClose={(event, reason) => {
        if (disableCloseOnBackdropClick && reason === 'backdropClick') {
          return;
        }
        rest.onClose?.(event, reason);
      }}
      data-testid={testId}
    >
      {children}
    </MuiDialog>
  );
};

export default Dialog;
