import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { AvailableSavingsHistoryEntry } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type UseAvailableSavingsHistoryQuery = {
  isLoading: boolean;
  data?: AvailableSavingsHistoryEntry[];
  error: unknown;
  refetch?: () => void;
};

export const useAvailableSavingsHistoryQuery = (
  id: string,
  from: string,
  to: string
): UseAvailableSavingsHistoryQuery => {
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: [QueryKeys.ESTIMATED_SAVINGS_HISTORY, id, from, to],
    queryFn: async () => {
      const { data } = await apiClient.costReport.getCostHistory({
        fromDate: from,
        toDate: to,
        clusterId: id,
      });
      return (data.items as AvailableSavingsHistoryEntry[]).map((metric) => {
        return {
          ...metric,
          createdAt: dayjs(metric.createdAt).subtract(1, 'h').toISOString(),
        };
      });
    },
    enabled: Boolean(id && from && to),
  });

  return {
    isLoading,
    data,
    error,
    refetch,
  };
};
