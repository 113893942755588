import { PropsWithChildren, ReactNode } from 'react';

import { Box, Popover } from '@mui/material';
import { SxProps } from '@mui/system';

import { useDatePickerContext } from './useDatePickerContext';
import { mergeSx } from '../../../utils';

type DatePickerPopperProps = {
  aside?: ReactNode;
  popoverSx?: SxProps;
  testId?: string;
  footer?: ReactNode;
};

export const DatePickerPopper = ({
  children,
  aside,
  popoverSx,
  footer,
  testId = 'date-picker',
}: PropsWithChildren<DatePickerPopperProps>) => {
  const ctx = useDatePickerContext();

  const isOpen = Boolean(ctx.popoverAnchor);

  return (
    <Popover
      sx={mergeSx({ top: 4 }, popoverSx)}
      open={isOpen}
      anchorEl={ctx.popoverAnchor}
      onClose={ctx.handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      data-testid={`${testId}-popover`}
      disableScrollLock
    >
      <Box
        display="flex"
        width="fit-content"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
        })}
      >
        {aside}

        <div>{children}</div>
      </Box>
      {footer}
    </Popover>
  );
};
