import { useDrawer } from '@cast/design-system';
import { SecurityInsightsContainerImage } from '@cast/types';

import { CreateImageExceptionDrawer } from './CreateImageExceptionDrawer';

export const useCreateImageExceptionDrawer = () => {
  const { open } = useDrawer();

  const openCreateImageExceptionDrawer = (
    images: SecurityInsightsContainerImage[],
    onCreated?: () => void
  ) => {
    open(CreateImageExceptionDrawer, {
      images,
      onCreated,
    });
  };

  return { openCreateImageExceptionDrawer };
};
