import { Typography } from '@mui/material';

import { Column, Icons, TableHeader, Tooltip } from '@cast/design-system';
import { NodeResourceOffering } from '@cast/types';

import { tableCacheKeys } from 'common/tableCacheKeys';
import { ResourceOfferingChip } from 'components/chips';
import {
  NoData,
  NoResults,
  SearchableTable,
  SearchableTableRecordsCount,
} from 'components/search';

import { WorkloadRow } from '../../../../../types';
import { spotPreferencesWorkloadsTableKey } from '../../../constants';

export const WorkloadsTable = () => {
  return (
    <SearchableTable
      cacheKey={tableCacheKeys.SAVINGS.SPOT_INSTANCES_DRAWER}
      fillParentHeight
      urlKey={spotPreferencesWorkloadsTableKey}
      components={{
        noData: <NoData icon={<Icons.MagnifyingGlass />} entity="workload" />,
        noResults: (
          <NoResults icon={<Icons.MagnifyingGlass />} entity="workload" />
        ),
      }}
      sizing="l"
      testId="workloads-table"
      outerHeader={
        <TableHeader
          recordsCount={<SearchableTableRecordsCount title="workload" />}
          px={32}
        />
      }
    >
      <Column<WorkloadRow>
        id="workloadName"
        header="WORKLOAD"
        minWidth={300}
        sortable
        resizable
        cellWrapperSx={{ paddingLeft: 32 }}
        renderCell={({ workloadName, spotFriendly }) => {
          return !spotFriendly ? (
            <Tooltip
              arrow
              placement="top-start"
              title="Additional actions needed for workload to become spot-friendly"
            >
              <Typography variant="inherit" color="red.400" noWrap>
                {workloadName}
              </Typography>
            </Tooltip>
          ) : (
            workloadName
          );
        }}
      />
      <Column<WorkloadRow>
        id="workloadType"
        header="TYPE"
        minWidth={104}
        sortable
      />
      <Column<WorkloadRow>
        id="workloadNamespace"
        header="NAMESPACE"
        minWidth={140}
        sortable
      />
      <Column<WorkloadRow>
        id="pods"
        header="PODS"
        minWidth={110}
        infoTooltip="Average hourly pod count"
        renderCell="replicas"
        sortable
        accessor="replicas"
      />
      <Column<WorkloadRow>
        id="currentNodeType"
        header="CURRENT"
        minWidth={130}
        sortable
        accessor="currentNodeType"
        renderCell={(row) => (
          <ResourceOfferingChip
            resourceOffering={
              row.currentNodeType === 'spot'
                ? NodeResourceOffering.SPOT
                : NodeResourceOffering.ON_DEMAND
            }
          />
        )}
      />
      <Column<WorkloadRow>
        id="recommendedNodeType"
        header="SUGGESTED"
        minWidth={130}
        renderCell={(row) => (
          <ResourceOfferingChip
            resourceOffering={
              row.recommendedNodeType === 'spot'
                ? NodeResourceOffering.SPOT
                : NodeResourceOffering.ON_DEMAND
            }
          />
        )}
        sortable
        accessor="recommendedNodeType"
      />
    </SearchableTable>
  );
};
