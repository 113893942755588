import {
  createContext,
  forwardRef,
  PropsWithChildren,
  useContext,
} from 'react';

import { SvgIcon } from '@mui/material';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';
import clsx from 'clsx';
import { Check } from 'phosphor-react';

export type CheckboxProps = Omit<MuiCheckboxProps, 'color'> & {
  error?: boolean;
  color?: 'primary' | 'secondary' | 'tertiary';
  testId?: string;
};

export const CheckboxContext = createContext<CheckboxProps | undefined>(
  undefined
);

const CheckboxBorder = ({
  children,
  fontSize,
}: PropsWithChildren<{ fontSize?: 'small' | 'medium' }>) => {
  return (
    <div
      className={clsx('MuiCheckbox-body', `MuiCheckbox-fontSize-${fontSize}`)}
    >
      {children}
    </div>
  );
};

const Checkmark = () => {
  return (
    <Check className="MuiCheckbox-checkmark" color="white" weight="bold" />
  );
};

const Indeterminate = () => {
  return (
    <SvgIcon className="MuiCheckbox-checkmark" viewBox="0 0 8 2">
      <rect width="8" height="2" rx="1" fill="white" />
    </SvgIcon>
  );
};

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ color = 'primary', error, testId, ...props }: CheckboxProps, ref) => {
    const checkBoxCtx = useContext(CheckboxContext);

    return (
      <MuiCheckbox
        data-testid={testId}
        {...checkBoxCtx}
        {...props}
        color={error || checkBoxCtx?.error ? 'error' : color}
        icon={<CheckboxBorder />}
        checkedIcon={
          <CheckboxBorder>
            <Checkmark />
          </CheckboxBorder>
        }
        indeterminateIcon={
          <CheckboxBorder>
            <Indeterminate />
          </CheckboxBorder>
        }
        ref={ref}
      />
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
