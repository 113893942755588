import { createContext, PropsWithChildren } from 'react';

import { DatePickerProviderProps } from './types';

export const DatePickerContext = createContext<DatePickerProviderProps>(
  {} as never
);

const DatePickerProvider = ({
  children,
  ...props
}: PropsWithChildren<DatePickerProviderProps>) => {
  return (
    <DatePickerContext.Provider value={props}>
      {children}
    </DatePickerContext.Provider>
  );
};

export default DatePickerProvider;
