import Big from 'big.js';
import sumBy from 'lodash/sumBy';

import {
  SavingsProgressResponseEntry,
  SavingsProgressResponseSummary,
} from '@cast/types';

export const getSummary = (
  items: SavingsProgressResponseEntry[]
): SavingsProgressResponseSummary => {
  const totalSavings = sumBy(items, (item) =>
    Big(item.downscalingSavings || 0)
      .add(item.spotSavings || 0)
      .toNumber()
  );
  return {
    totalSavings: totalSavings.toFixed(6),
    totalCost: (totalSavings * 4).toString(),
  };
};
