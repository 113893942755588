import { AxiosError, AxiosResponse } from 'axios';

import { DEMO_CLUSTER_ID, isDemoMode } from '@cast/utils';

export const isDemoClusterRequest = (args: unknown[]) => {
  return (
    args.includes(DEMO_CLUSTER_ID) ||
    window.location.pathname.includes(DEMO_CLUSTER_ID) ||
    isDemoMode()
  );
};

export const resolveDemoClusterRequest = <T>(data: T) => {
  return Promise.resolve({
    data,
  } as AxiosResponse<T>);
};

export const rejectDemoClusterRequest = () =>
  Promise.reject(
    new AxiosError(
      `Demo organization doesn't support this action`,
      '400',
      undefined,
      undefined,
      {
        data: {
          message: `Demo organization doesn't support this action`,
        },
      } as AxiosResponse
    )
  );

export const rejectDemoOrgRequest = () =>
  Promise.reject(
    new AxiosError(
      `Demo org doesn't support this action`,
      '400',
      undefined,
      undefined,
      {
        data: {
          message: `Demo org doesn't support this action`,
        },
      } as AxiosResponse
    )
  );

export const getOrgId = (): string =>
  new URLSearchParams(window.location.search).get('org')!;
