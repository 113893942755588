export enum ExperimentName {
  DASHBOARD = 'dashboard',
  SIGNUP_FORM = 'signup-form',
  POST_SIGNUP_REDIRECT = 'post-signup-redirect',
}

export const Experiments: Record<ExperimentName, string> = {
  [ExperimentName.DASHBOARD]: 'EqfLjyq3TKSIv7kCf_uEUg',
  [ExperimentName.SIGNUP_FORM]: 'y-RTMRDSRH-CAUWSZiwtWg',
  [ExperimentName.POST_SIGNUP_REDIRECT]: 'okhJLlR8T4eAgxQBjkYLvA',
};
