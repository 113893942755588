/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * NodeType defines the role of the VM when joining the Kubernetes cluster. Default value is not allowed.
 * @export
 * @enum {string}
 */

export const ExternalclusterV1NodeType = {
    NODE_TYPE_INVALID: 'NODE_TYPE_INVALID',
    master: 'master',
    NODE_TYPE_MASTER: 'NODE_TYPE_MASTER',
    worker: 'worker',
    NODE_TYPE_WORKER: 'NODE_TYPE_WORKER'
} as const;

export type ExternalclusterV1NodeType = typeof ExternalclusterV1NodeType[keyof typeof ExternalclusterV1NodeType];



