import { ReactNode } from 'react';

import isEqual from 'lodash/isEqual';
import some from 'lodash/some';

import {
  DropdownProps,
  isSingleSelection,
  AutocompleteOverrides,
} from './types';

const makeGetters = <OptionItem = any, OptionValue = any>(
  dropdownProps: DropdownProps<OptionItem>
) => {
  const {
    optionLabel,
    optionDisabled,
    isOptionEqualToValue,
    groupBy,
    optionKey,
    optionValue,
  } = dropdownProps;

  let getOptionLabel: (option: OptionItem) => string;
  if (typeof optionLabel === 'string') {
    getOptionLabel = (option: OptionItem) =>
      (option[optionLabel] as unknown as string) || '';
  } else if (typeof optionLabel === 'function') {
    getOptionLabel = (option: OptionItem) => optionLabel(option) || '';
  } else {
    getOptionLabel = (option: OptionItem) => option as unknown as string;
  }

  let getOptionIcon: ((option: OptionItem) => ReactNode) | undefined;
  if ('optionIcon' in dropdownProps) {
    const { optionIcon } = dropdownProps;
    if (typeof optionIcon === 'function') {
      getOptionIcon = optionIcon;
    } else if (typeof optionIcon === 'string') {
      getOptionIcon = (option: OptionItem) =>
        (option as any)[optionIcon] as ReactNode;
    }
  }

  let groupByFn: (option: OptionItem) => string;
  if (typeof groupBy === 'string') {
    groupByFn = (option: OptionItem) => option[groupBy] as unknown as string;
  } else {
    groupByFn = groupBy as (option: OptionItem) => string;
  }

  let getOptionKey: (option: OptionItem) => string;
  if (typeof optionKey === 'function') {
    getOptionKey = optionKey;
  } else if (typeof optionKey === 'string') {
    getOptionKey = (option: OptionItem) =>
      (option[optionKey] as unknown as string) || '';
  } else {
    getOptionKey = getOptionLabel;
  }

  let getOptionSelectedValue: (option: OptionItem) => ReactNode;
  if (isSingleSelection(dropdownProps)) {
    const { renderValue } = dropdownProps;
    if (typeof renderValue === 'function') {
      getOptionSelectedValue = renderValue;
    } else if (typeof renderValue === 'string') {
      getOptionSelectedValue = (option: OptionItem) =>
        (option[renderValue] as unknown as string) || '';
    } else {
      getOptionSelectedValue = getOptionLabel;
    }
  } else {
    getOptionSelectedValue = getOptionLabel;
  }

  let getOptionValue: (option: OptionItem) => OptionValue;
  if (typeof optionValue === 'function') {
    getOptionValue = optionValue;
  } else if (typeof optionValue === 'string') {
    getOptionValue = (option: OptionItem) =>
      option?.[optionValue] as unknown as OptionValue;
  } else {
    getOptionValue = (option: OptionItem) => option as unknown as OptionValue;
  }

  return {
    getOptionIcon,
    getOptionLabel,
    groupBy: groupByFn,
    getOptionKey,
    getOptionSelectedValue,
    getOptionValue,
    getOptionDisabled: optionDisabled || ((_option: OptionItem) => false),
    isOptionEqualToValue:
      isOptionEqualToValue ??
      (optionValue
        ? (option, value) => {
            const opt =
              typeof option === 'object' ? getOptionValue(option) : option;
            const val =
              typeof value === 'object'
                ? getOptionValue(value as any as OptionItem)
                : value;
            return opt === val;
          }
        : (option, value) => isEqual(option, value)),
  };
};

type NormalizedProps<OptionItem, OptionValue = any> = {
  getters: ReturnType<typeof makeGetters<OptionItem, any>>;
  autocompleteProps?: AutocompleteOverrides<OptionItem, OptionValue>;
};
export const normalizeProps = <OptionItem = any, OptionValue = any>(
  props: DropdownProps<OptionItem, OptionValue>
): NormalizedProps<OptionItem, OptionValue> => {
  const { options, multiple, open, onChange, onOpen, onClose } = props;
  const getters = makeGetters(props);
  const autocompleteProps = {
    options: options || [],
    multiple,
    open,
    onChange: multiple
      ? (option: OptionItem[]) => {
          onChange?.(
            option,
            Array.isArray(options)
              ? options.map((option) => getters.getOptionValue(option))
              : []
          );
        }
      : (option: OptionItem) =>
          onChange?.(option, getters.getOptionValue(option)),
    value: props.value,
    applySortingWhileSearching: props.applySortingWhileSearching ?? true,
    searchFilter: props.searchFilter,
    resetInputOnClose: true,
    onOpen,
    onClose,
  };
  return { getters, autocompleteProps: autocompleteProps as any };
};

export const makeIsOptionSelected = <OptionItem, OptionValue = any>(
  multiple: boolean,
  value: OptionValue | OptionValue[],
  isOptionEqualToValue?:
    | ((option: OptionItem, value: OptionValue) => boolean)
    | ((option: OptionItem, value: OptionValue[]) => boolean)
): ((option: OptionItem) => boolean) => {
  if (multiple) {
    if (isOptionEqualToValue) {
      return (option: OptionItem) =>
        (value as OptionItem[]).some((v) =>
          isOptionEqualToValue?.(option, v as any)
        );
    }
    return (option: OptionItem) => some(value as OptionItem[], option as any);
  }
  if (isOptionEqualToValue) {
    return (option: OptionItem) => isOptionEqualToValue(option, value as any);
  }
  return (option: OptionItem) => value === option;
};
