import { MouseEvent } from 'react';

import { styled } from '@mui/material';
import { SxProps } from '@mui/system';
import copy from 'copy-to-clipboard';

import { mergeSx, Tooltip, Icons, TooltipProps } from '@cast/design-system';

import { notify } from 'core/snackbars/SnackbarUtilsConfigurator';

type CopyToClipboardProps = {
  text: string;
  tooltip?: string;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  iconSize?: string | number;
  color?: string;
  strokeWidth?: number;
  sx?: SxProps;
  showNotification?: boolean;
  onCopy?: (text: string) => void;
};

const StyledIcon = styled(Icons.Copy)({
  fill: 'currentColor',
  cursor: 'pointer',
});

export const CopyToClipboard = ({
  color = 'grey.500',
  text,
  iconSize = 24,
  tooltip = '',
  tooltipProps = {},
  strokeWidth = 2.5,
  sx,
  showNotification = true,
  onCopy,
}: CopyToClipboardProps) => {
  const handleCopy = (e: MouseEvent) => {
    e.stopPropagation();
    copy(text);
    onCopy?.(text);
    if (showNotification) {
      notify.success('Copied to clipboard', { key: 'copy-to-clipboard' });
    }
  };

  const button = (
    <StyledIcon
      onClick={handleCopy}
      sx={mergeSx(
        {
          color,
          width: '1em',
          height: '1em',
          minWidth: '1em',
          minHeight: '1em',
          fontSize: iconSize,
        },
        sx
      )}
      strokeWidth={strokeWidth}
    />
  );

  if (tooltip) {
    return (
      <Tooltip {...tooltipProps} title={tooltip}>
        {button}
      </Tooltip>
    );
  }

  return <>{button}</>;
};
