export default {
  50: '#FCFCFE',
  100: '#F4F6F9',
  200: '#E1E7EA',
  300: '#BAC3C8',
  400: '#839299',
  500: '#5D6E75',
  600: '#40545D',
  700: '#243B45',
  800: '#112934',
  900: '#051922',
};
