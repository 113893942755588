import { BestPracticeCheck } from '@cast/types';

import {
  ClientSideSearchCriterion,
  makeSearchBoxCriterion,
} from 'components/search';
import { CheckResourceState } from 'features/security-insights';

import { useStandardCriteria } from '../../_hooks/useStandardCriteria';

const baseCriteria: ClientSideSearchCriterion<BestPracticeCheck>[] = [
  makeSearchBoxCriterion<BestPracticeCheck>({
    key: 'Check:',
    prop: 'name',
  }),
  {
    key: 'severity',
  },
  {
    key: 'type',
    getValue: (o) => (o.manual ? 'manual' : 'automatic'),
    matcher: 'exact',
  },
  { key: 'clusterIds' },
  { key: 'namespaces' },
  { key: 'labels' },
  {
    key: 'resource',
    getValue: (o) => {
      return o.failed
        ? CheckResourceState.AFFECTED
        : CheckResourceState.UNAFFECTED;
    },
    initialValue: CheckResourceState.AFFECTED,
    matcher: 'exact',
  },
];

export const useComplianceCheckCriteria = ():
  | ClientSideSearchCriterion<BestPracticeCheck>[]
  | undefined => {
  const standardCriteria = useStandardCriteria();
  return [...baseCriteria, standardCriteria];
};
