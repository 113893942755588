import { ReactNode, useState, MouseEvent } from 'react';

import { capitalize, styled } from '@mui/material';
import clsx from 'clsx';
import { Eye, EyeSlash } from 'phosphor-react';

import { GetPasswordStrengthWithHint, PasswordStrength } from './types';
import Input, { InputProps } from '../input/Input';

type StrengthProps = {
  strength?: PasswordStrength;
  passwordHint?: ReactNode;
};

export type PasswordInputProps = StrengthProps &
  Omit<InputProps, 'value'> & {
    value?: string;
    showPassword?: boolean;
    getPasswordStrengthWithHint?: GetPasswordStrengthWithHint;
  };

const StyledInput = styled(Input, {
  name: 'DsPasswordInput',
  slot: 'Root',
  target: 'DsPasswordInput-Root',
  overridesResolver: (_, theme) => {
    return [theme.root];
  },
})({});
StyledInput.displayName = 'StyledInput';

const Strength = ({ strength, passwordHint }: StrengthProps) => {
  if (!strength && !passwordHint) {
    return null;
  }
  return (
    <div
      className={clsx(
        'DS-Password-strength-root',
        `DS-Password-strength-${strength}`
      )}
    >
      {strength && (
        <div className="DS-Password-strength-indicatorRow">
          <span className="DS-Password-strength-title">
            {capitalize(strength)}
          </span>
          <div className="DS-Password-strength-indicatorContainer">
            <div className="DS-Password-strength-indicator" />
          </div>
        </div>
      )}
      {passwordHint && (
        <span className="DS-Password-passwordHint" data-testid="password-hint">
          {passwordHint}
        </span>
      )}
    </div>
  );
};

const PasswordInput = ({
  strength,
  passwordHint,
  showPassword = false,
  getPasswordStrengthWithHint,
  error,
  ...props
}: PasswordInputProps) => {
  const [showInput, setShowInput] = useState(showPassword);
  const [value, setValue] = useState(props.value);

  const toggleShowInput = (e: MouseEvent) => {
    e.stopPropagation();
    setShowInput(!showInput);
  };

  const [retrievedStrength, retrievedHint] = getPasswordStrengthWithHint?.(
    value
  ) || [undefined, undefined];

  const strengthEl = (
    <Strength
      strength={strength || retrievedStrength}
      passwordHint={error || passwordHint || retrievedHint}
    />
  );

  return (
    <StyledInput
      hint={!error ? strengthEl : undefined}
      error={error ? strengthEl : undefined}
      inputProps={{
        type: showInput ? 'text' : 'password',
        'data-testid': 'password',
      }}
      endAdornment={
        showInput ? (
          <Eye onClick={toggleShowInput} data-testid="hide-password" />
        ) : (
          <EyeSlash onClick={toggleShowInput} data-testid="show-password" />
        )
      }
      onChange={(event) => {
        setValue(event.target.value);
      }}
      {...props}
    />
  );
};

export default PasswordInput;
