import { useElementHeight } from '@cast/utils';

import { NormalizedTableProps } from '../../../types';

export const useMaxHeight = (
  tableProps: NormalizedTableProps,
  rootRef?: HTMLElement,
  peripheralsHeight = 0,
  disableHeightCalc = false
) => {
  const calcParentHeight = tableProps.fillParentHeight && !!rootRef;
  const parentHeight = useElementHeight(
    rootRef?.parentElement,
    calcParentHeight
  );
  if (disableHeightCalc) {
    return 0;
  }
  if (tableProps.maxHeight) {
    if (rootRef) {
      return parseInt(getComputedStyle(rootRef).maxHeight);
    }
    return 1;
  }
  const calcVh = !!tableProps.maxHeightVh;
  if (calcParentHeight) {
    return Math.round(parentHeight);
  }

  if (calcVh) {
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    return Math.round((tableProps.maxHeightVh! / 100) * (vh || 1));
  }
  if (tableProps.maxHeightPx) {
    return tableProps.maxHeightPx;
  }
  if (tableProps.maxRows) {
    return tableProps.maxRows * tableProps.rowHeight + peripheralsHeight;
  }
  return 1;
};
