import * as Sentry from '@sentry/react';

import { ApiClientFactory } from '@cast/api-client';
import { client } from '@cast/core';

export const apiClient = ApiClientFactory(client);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config.url.includes('agent.yaml')) {
      Sentry.captureException(error);
      return Promise.reject(error);
    }

    if (
      error.config.url === 'auth/session' ||
      error.config.url.includes('/chat/completions')
    ) {
      Sentry.captureException(error);
      return Promise.reject(error);
    }

    if (error?.response?.status === 401) {
      // FIXME: Doesn't make sense to logout if session is already expired, check regression and remove
      return apiClient.auth
        .logout({ returnTo: window.location.origin })
        .finally(() => window.location.reload());
    }

    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export { client };
