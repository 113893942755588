import { MutableRefObject, ReactNode } from 'react';

import clsx from 'clsx';

import { Resizer } from './Resizer';
import { Tooltip } from '../../../tooltip';
import { ColumnModel, TableApi, TableComponents } from '../../types';

type Props = {
  columnModel: ColumnModel;
  api: MutableRefObject<TableApi>;
  Sorter: Required<TableComponents>['Sorter'];
  SortIcon: Required<TableComponents>['SortIcon'];
  InfoIcon: Required<TableComponents>['InfoIcon'];
};

export const HeaderCell = ({
  columnModel,
  api,
  SortIcon,
  InfoIcon,
  Sorter,
}: Props) => {
  const { HeaderCellComponent, isFirstGroupColumn, isLastGroupColumn } =
    columnModel;

  const defaultClasses = clsx(
    `DS-Table-CellWrapper DS-Table-HeaderCellWrapper DS-Table-CellWrapper-${columnModel.id}`,
    isFirstGroupColumn && 'DS-Table-HeaderCellWrapper-firstGroupColumn',
    isLastGroupColumn && 'DS-Table-HeaderCellWrapper-lastGroupColumn'
  );

  if (HeaderCellComponent) {
    return <HeaderCellComponent className={defaultClasses} />;
  }

  let headerContent: ReactNode;
  if (columnModel.renderHeader) {
    headerContent = columnModel.renderHeader(api.current);
  } else if (columnModel.header !== undefined) {
    headerContent = (
      <div className="DS-Table-HeaderTitle">{columnModel.header}</div>
    );
  }

  return (
    <>
      <div
        className={clsx(
          defaultClasses,
          columnModel.infoTooltip && 'DS-Table-HeaderCell-hasInfoTooltip',
          columnModel.sortable && 'DS-Table-HeaderCell-sortable',
          columnModel.locked && 'DS-Table-lockedCell',
          columnModel.floating && 'DS-Table-floatingCell'
        )}
      >
        <div className="DS-Table-Cell DS-Table-HeaderCell">
          {headerContent}
          {columnModel.infoTooltip && (
            <Tooltip
              arrow
              placement="top"
              {...(columnModel.infoTooltipProps || {})}
              title={
                columnModel.infoTooltipProps?.title ?? columnModel.infoTooltip
              }
            >
              <InfoIcon className="DS-Table-InfoIcon Ds-Table-HeaderIcon-root" />
            </Tooltip>
          )}
          <Sorter
            api={api.current}
            columnModel={columnModel}
            SortIcon={SortIcon}
          />
        </div>
      </div>
      {columnModel.resizable && <Resizer column={columnModel} />}
    </>
  );
};
