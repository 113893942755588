export function cssVars<T extends Record<string, string | number | undefined>>(
  vars?: T,
  prefix?: string
): {
  defs: Record<string, string | number | undefined>;
  getters: { [K in keyof T]: (defaultValue?: string | number) => T[K] };
  setValues: (
    values: Partial<Record<keyof T, string | number | undefined>>
  ) => Record<string, string | number>;
} {
  const defs: Record<string, string | number> = {};
  const getters: Record<
    string,
    (defaultVal?: string | number) => string | number
  > = {};

  if (vars) {
    for (const variable in vars) {
      const val = vars[variable];
      if (typeof val !== 'undefined') {
        defs[`--${prefix ? `${prefix}-` : ''}${variable}`] = val as
          | string
          | number;
      }
      getters[variable] = (defaultVal?: string | number) => {
        const value = defaultVal ?? vars[variable] ?? '';
        return value
          ? `var(--${prefix ? `${prefix}-` : ''}${variable}, ${value})`
          : `var(--${prefix ? `${prefix}-` : ''}${variable})`;
      };
    }
  }
  return {
    defs,
    getters: getters as any,
    setValues: ((values: Partial<T>) => {
      const result: Record<string, string | number> = {};
      for (const variable in values) {
        const val = values[variable];
        result[`--${prefix ? `${prefix}-` : ''}${variable}`] = val as
          | string
          | number;
      }
      return result;
    }) as any,
  };
}
