/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CastaiUsersV1beta1Auth0IngestLog } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1ClaimInvitationResponse } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1CreateInvitationsRequest } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1CreateInvitationsResponse } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1CurrentUserProfileResponse } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1GetHubspotTokenResponse } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1GroupRef } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1ListInvitationsResponse } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1ListOrganizationUsersResponse } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1ListOrganizationsResponse } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1Membership } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1NewMembership } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1Organization } from '../models';
// @ts-ignore
import { CastaiUsersV1beta1User } from '../models';
// @ts-ignore
import { UsersAPIUpdateOrganizationUserRequest } from '../models';
/**
 * UsersAPIApi - axios parameter creator
 * @export
 */
export const UsersAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Makes the user a member of the organization.
         * @param {string} organizationId Organization id which should take the user in.
         * @param {CastaiUsersV1beta1NewMembership} membership Membership to add to an organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIAddUserToOrganization: async (organizationId: string, membership: CastaiUsersV1beta1NewMembership, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('usersAPIAddUserToOrganization', 'organizationId', organizationId)
            // verify required parameter 'membership' is not null or undefined
            assertParamExists('usersAPIAddUserToOrganization', 'membership', membership)
            const localVarPath = `/v1/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(membership, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Auth0IngestLogsWebhook accepts logs from Auth0 from their log streaming.
         * @param {Array<CastaiUsersV1beta1Auth0IngestLog>} logs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIAuth0IngestLogsWebhook: async (logs: Array<CastaiUsersV1beta1Auth0IngestLog>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logs' is not null or undefined
            assertParamExists('usersAPIAuth0IngestLogsWebhook', 'logs', logs)
            const localVarPath = `/v1/auth0-ingest-logs-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Consume invitation and add current user to organization
         * @param {string} invitationId Invitation ID.
         * @param {object} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIClaimInvitation: async (invitationId: string, request: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('usersAPIClaimInvitation', 'invitationId', invitationId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('usersAPIClaimInvitation', 'request', request)
            const localVarPath = `/v1/invitations/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create invitations to join organization by email
         * @param {CastaiUsersV1beta1CreateInvitationsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPICreateInvitations: async (body: CastaiUsersV1beta1CreateInvitationsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('usersAPICreateInvitations', 'body', body)
            const localVarPath = `/v1/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an organization.
         * @param {CastaiUsersV1beta1Organization} organization Organization to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPICreateOrganization: async (organization: CastaiUsersV1beta1Organization, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organization' is not null or undefined
            assertParamExists('usersAPICreateOrganization', 'organization', organization)
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPICurrentUserProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an invitation by id.
         * @param {string} id Invitation ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIDeleteInvitation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersAPIDeleteInvitation', 'id', id)
            const localVarPath = `/v1/invitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an organization by id.
         * @param {string} id Delete an organization by id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIDeleteOrganization: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersAPIDeleteOrganization', 'id', id)
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit an organization by id.
         * @param {string} id Get organization by id.
         * @param {CastaiUsersV1beta1Organization} organization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIEditOrganization: async (id: string, organization: CastaiUsersV1beta1Organization, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersAPIEditOrganization', 'id', id)
            // verify required parameter 'organization' is not null or undefined
            assertParamExists('usersAPIEditOrganization', 'organization', organization)
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organization, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetHubspotToken returns token for Hubspot chat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIGetHubspotToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me/hubspot-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an organization by id.
         * @param {string} id Get organization by id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIGetOrganization: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersAPIGetOrganization', 'id', id)
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all pending invitations in the organization.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIListInvitations: async (pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all users which are members of the organization.
         * @param {string} organizationId Get organization users by organization id.
         * @param {boolean} [includeGroups] IncludeGroups is the flag to include group membership in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIListOrganizationUsers: async (organizationId: string, includeGroups?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('usersAPIListOrganizationUsers', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeGroups !== undefined) {
                localVarQueryParameter['includeGroups'] = includeGroups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List user organizations. If all arguments are empty, lists organizations for caller user. Organizations are sorted based on the date user joined the org, so first organization will always be the default one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIListOrganizations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists groups that a user is assigned to in the organization.
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIListUserGroups: async (organizationId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('usersAPIListUserGroups', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersAPIListUserGroups', 'userId', userId)
            const localVarPath = `/v1/organizations/{organizationId}/users/{userId}/groups`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes users from organization members.
         * @param {string} organizationId Organization id which should no longer contain the users.
         * @param {Array<string>} users Users is the list of user ids to remove.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIRemoveOrganizationUsers: async (organizationId: string, users: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('usersAPIRemoveOrganizationUsers', 'organizationId', organizationId)
            // verify required parameter 'users' is not null or undefined
            assertParamExists('usersAPIRemoveOrganizationUsers', 'users', users)
            const localVarPath = `/v1/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (users) {
                localVarQueryParameter['users'] = users;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes the user from organization members.
         * @param {string} organizationId Organization id which should no longer contain the user.
         * @param {string} userId User id to remove.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIRemoveUserFromOrganization: async (organizationId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('usersAPIRemoveUserFromOrganization', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersAPIRemoveUserFromOrganization', 'userId', userId)
            const localVarPath = `/v1/organizations/{organizationId}/users/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update profile for current user.
         * @param {CastaiUsersV1beta1User} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIUpdateCurrentUserProfile: async (item: CastaiUsersV1beta1User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('usersAPIUpdateCurrentUserProfile', 'item', item)
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(item, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates user in the organization.
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UsersAPIUpdateOrganizationUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIUpdateOrganizationUser: async (organizationId: string, userId: string, body: UsersAPIUpdateOrganizationUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('usersAPIUpdateOrganizationUser', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersAPIUpdateOrganizationUser', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('usersAPIUpdateOrganizationUser', 'body', body)
            const localVarPath = `/v1/organizations/{organizationId}/users/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersAPIApi - functional programming interface
 * @export
 */
export const UsersAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Makes the user a member of the organization.
         * @param {string} organizationId Organization id which should take the user in.
         * @param {CastaiUsersV1beta1NewMembership} membership Membership to add to an organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIAddUserToOrganization(organizationId: string, membership: CastaiUsersV1beta1NewMembership, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIAddUserToOrganization(organizationId, membership, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIAddUserToOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Auth0IngestLogsWebhook accepts logs from Auth0 from their log streaming.
         * @param {Array<CastaiUsersV1beta1Auth0IngestLog>} logs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIAuth0IngestLogsWebhook(logs: Array<CastaiUsersV1beta1Auth0IngestLog>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIAuth0IngestLogsWebhook(logs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIAuth0IngestLogsWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Consume invitation and add current user to organization
         * @param {string} invitationId Invitation ID.
         * @param {object} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIClaimInvitation(invitationId: string, request: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1ClaimInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIClaimInvitation(invitationId, request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIClaimInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create invitations to join organization by email
         * @param {CastaiUsersV1beta1CreateInvitationsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPICreateInvitations(body: CastaiUsersV1beta1CreateInvitationsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1CreateInvitationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPICreateInvitations(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPICreateInvitations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates an organization.
         * @param {CastaiUsersV1beta1Organization} organization Organization to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPICreateOrganization(organization: CastaiUsersV1beta1Organization, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPICreateOrganization(organization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPICreateOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get profile for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPICurrentUserProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1CurrentUserProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPICurrentUserProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPICurrentUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an invitation by id.
         * @param {string} id Invitation ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIDeleteInvitation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIDeleteInvitation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIDeleteInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an organization by id.
         * @param {string} id Delete an organization by id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIDeleteOrganization(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIDeleteOrganization(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIDeleteOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Edit an organization by id.
         * @param {string} id Get organization by id.
         * @param {CastaiUsersV1beta1Organization} organization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIEditOrganization(id: string, organization: CastaiUsersV1beta1Organization, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIEditOrganization(id, organization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIEditOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetHubspotToken returns token for Hubspot chat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIGetHubspotToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1GetHubspotTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIGetHubspotToken(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIGetHubspotToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get an organization by id.
         * @param {string} id Get organization by id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIGetOrganization(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIGetOrganization(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIGetOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all pending invitations in the organization.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIListInvitations(pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1ListInvitationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIListInvitations(pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIListInvitations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all users which are members of the organization.
         * @param {string} organizationId Get organization users by organization id.
         * @param {boolean} [includeGroups] IncludeGroups is the flag to include group membership in the response.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIListOrganizationUsers(organizationId: string, includeGroups?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1ListOrganizationUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIListOrganizationUsers(organizationId, includeGroups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIListOrganizationUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List user organizations. If all arguments are empty, lists organizations for caller user. Organizations are sorted based on the date user joined the org, so first organization will always be the default one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIListOrganizations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1ListOrganizationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIListOrganizations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIListOrganizations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Lists groups that a user is assigned to in the organization.
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIListUserGroups(organizationId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CastaiUsersV1beta1GroupRef>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIListUserGroups(organizationId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIListUserGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Removes users from organization members.
         * @param {string} organizationId Organization id which should no longer contain the users.
         * @param {Array<string>} users Users is the list of user ids to remove.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIRemoveOrganizationUsers(organizationId: string, users: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIRemoveOrganizationUsers(organizationId, users, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIRemoveOrganizationUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Removes the user from organization members.
         * @param {string} organizationId Organization id which should no longer contain the user.
         * @param {string} userId User id to remove.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIRemoveUserFromOrganization(organizationId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIRemoveUserFromOrganization(organizationId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIRemoveUserFromOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update profile for current user.
         * @param {CastaiUsersV1beta1User} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIUpdateCurrentUserProfile(item: CastaiUsersV1beta1User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIUpdateCurrentUserProfile(item, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIUpdateCurrentUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates user in the organization.
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UsersAPIUpdateOrganizationUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAPIUpdateOrganizationUser(organizationId: string, userId: string, body: UsersAPIUpdateOrganizationUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsersV1beta1Membership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAPIUpdateOrganizationUser(organizationId, userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersAPIApi.usersAPIUpdateOrganizationUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersAPIApi - factory interface
 * @export
 */
export const UsersAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Makes the user a member of the organization.
         * @param {UsersAPIApiUsersAPIAddUserToOrganizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIAddUserToOrganization(requestParameters: UsersAPIApiUsersAPIAddUserToOrganizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersAPIAddUserToOrganization(requestParameters.organizationId, requestParameters.membership, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Auth0IngestLogsWebhook accepts logs from Auth0 from their log streaming.
         * @param {UsersAPIApiUsersAPIAuth0IngestLogsWebhookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIAuth0IngestLogsWebhook(requestParameters: UsersAPIApiUsersAPIAuth0IngestLogsWebhookRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersAPIAuth0IngestLogsWebhook(requestParameters.logs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Consume invitation and add current user to organization
         * @param {UsersAPIApiUsersAPIClaimInvitationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIClaimInvitation(requestParameters: UsersAPIApiUsersAPIClaimInvitationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1ClaimInvitationResponse> {
            return localVarFp.usersAPIClaimInvitation(requestParameters.invitationId, requestParameters.request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create invitations to join organization by email
         * @param {UsersAPIApiUsersAPICreateInvitationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPICreateInvitations(requestParameters: UsersAPIApiUsersAPICreateInvitationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1CreateInvitationsResponse> {
            return localVarFp.usersAPICreateInvitations(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an organization.
         * @param {UsersAPIApiUsersAPICreateOrganizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPICreateOrganization(requestParameters: UsersAPIApiUsersAPICreateOrganizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1Organization> {
            return localVarFp.usersAPICreateOrganization(requestParameters.organization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPICurrentUserProfile(options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1CurrentUserProfileResponse> {
            return localVarFp.usersAPICurrentUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an invitation by id.
         * @param {UsersAPIApiUsersAPIDeleteInvitationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIDeleteInvitation(requestParameters: UsersAPIApiUsersAPIDeleteInvitationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersAPIDeleteInvitation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an organization by id.
         * @param {UsersAPIApiUsersAPIDeleteOrganizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIDeleteOrganization(requestParameters: UsersAPIApiUsersAPIDeleteOrganizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersAPIDeleteOrganization(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit an organization by id.
         * @param {UsersAPIApiUsersAPIEditOrganizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIEditOrganization(requestParameters: UsersAPIApiUsersAPIEditOrganizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1Organization> {
            return localVarFp.usersAPIEditOrganization(requestParameters.id, requestParameters.organization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetHubspotToken returns token for Hubspot chat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIGetHubspotToken(options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1GetHubspotTokenResponse> {
            return localVarFp.usersAPIGetHubspotToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an organization by id.
         * @param {UsersAPIApiUsersAPIGetOrganizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIGetOrganization(requestParameters: UsersAPIApiUsersAPIGetOrganizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1Organization> {
            return localVarFp.usersAPIGetOrganization(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all pending invitations in the organization.
         * @param {UsersAPIApiUsersAPIListInvitationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIListInvitations(requestParameters: UsersAPIApiUsersAPIListInvitationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1ListInvitationsResponse> {
            return localVarFp.usersAPIListInvitations(requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all users which are members of the organization.
         * @param {UsersAPIApiUsersAPIListOrganizationUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIListOrganizationUsers(requestParameters: UsersAPIApiUsersAPIListOrganizationUsersRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1ListOrganizationUsersResponse> {
            return localVarFp.usersAPIListOrganizationUsers(requestParameters.organizationId, requestParameters.includeGroups, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List user organizations. If all arguments are empty, lists organizations for caller user. Organizations are sorted based on the date user joined the org, so first organization will always be the default one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIListOrganizations(options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1ListOrganizationsResponse> {
            return localVarFp.usersAPIListOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists groups that a user is assigned to in the organization.
         * @param {UsersAPIApiUsersAPIListUserGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIListUserGroups(requestParameters: UsersAPIApiUsersAPIListUserGroupsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CastaiUsersV1beta1GroupRef>> {
            return localVarFp.usersAPIListUserGroups(requestParameters.organizationId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes users from organization members.
         * @param {UsersAPIApiUsersAPIRemoveOrganizationUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIRemoveOrganizationUsers(requestParameters: UsersAPIApiUsersAPIRemoveOrganizationUsersRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersAPIRemoveOrganizationUsers(requestParameters.organizationId, requestParameters.users, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes the user from organization members.
         * @param {UsersAPIApiUsersAPIRemoveUserFromOrganizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIRemoveUserFromOrganization(requestParameters: UsersAPIApiUsersAPIRemoveUserFromOrganizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.usersAPIRemoveUserFromOrganization(requestParameters.organizationId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update profile for current user.
         * @param {UsersAPIApiUsersAPIUpdateCurrentUserProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIUpdateCurrentUserProfile(requestParameters: UsersAPIApiUsersAPIUpdateCurrentUserProfileRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1User> {
            return localVarFp.usersAPIUpdateCurrentUserProfile(requestParameters.item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates user in the organization.
         * @param {UsersAPIApiUsersAPIUpdateOrganizationUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAPIUpdateOrganizationUser(requestParameters: UsersAPIApiUsersAPIUpdateOrganizationUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsersV1beta1Membership> {
            return localVarFp.usersAPIUpdateOrganizationUser(requestParameters.organizationId, requestParameters.userId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersAPIAddUserToOrganization operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIAddUserToOrganizationRequest
 */
export interface UsersAPIApiUsersAPIAddUserToOrganizationRequest {
    /**
     * Organization id which should take the user in.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIAddUserToOrganization
     */
    readonly organizationId: string

    /**
     * Membership to add to an organization.
     * @type {CastaiUsersV1beta1NewMembership}
     * @memberof UsersAPIApiUsersAPIAddUserToOrganization
     */
    readonly membership: CastaiUsersV1beta1NewMembership
}

/**
 * Request parameters for usersAPIAuth0IngestLogsWebhook operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIAuth0IngestLogsWebhookRequest
 */
export interface UsersAPIApiUsersAPIAuth0IngestLogsWebhookRequest {
    /**
     * 
     * @type {Array<CastaiUsersV1beta1Auth0IngestLog>}
     * @memberof UsersAPIApiUsersAPIAuth0IngestLogsWebhook
     */
    readonly logs: Array<CastaiUsersV1beta1Auth0IngestLog>
}

/**
 * Request parameters for usersAPIClaimInvitation operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIClaimInvitationRequest
 */
export interface UsersAPIApiUsersAPIClaimInvitationRequest {
    /**
     * Invitation ID.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIClaimInvitation
     */
    readonly invitationId: string

    /**
     * 
     * @type {object}
     * @memberof UsersAPIApiUsersAPIClaimInvitation
     */
    readonly request: object
}

/**
 * Request parameters for usersAPICreateInvitations operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPICreateInvitationsRequest
 */
export interface UsersAPIApiUsersAPICreateInvitationsRequest {
    /**
     * 
     * @type {CastaiUsersV1beta1CreateInvitationsRequest}
     * @memberof UsersAPIApiUsersAPICreateInvitations
     */
    readonly body: CastaiUsersV1beta1CreateInvitationsRequest
}

/**
 * Request parameters for usersAPICreateOrganization operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPICreateOrganizationRequest
 */
export interface UsersAPIApiUsersAPICreateOrganizationRequest {
    /**
     * Organization to create.
     * @type {CastaiUsersV1beta1Organization}
     * @memberof UsersAPIApiUsersAPICreateOrganization
     */
    readonly organization: CastaiUsersV1beta1Organization
}

/**
 * Request parameters for usersAPIDeleteInvitation operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIDeleteInvitationRequest
 */
export interface UsersAPIApiUsersAPIDeleteInvitationRequest {
    /**
     * Invitation ID.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIDeleteInvitation
     */
    readonly id: string
}

/**
 * Request parameters for usersAPIDeleteOrganization operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIDeleteOrganizationRequest
 */
export interface UsersAPIApiUsersAPIDeleteOrganizationRequest {
    /**
     * Delete an organization by id.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIDeleteOrganization
     */
    readonly id: string
}

/**
 * Request parameters for usersAPIEditOrganization operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIEditOrganizationRequest
 */
export interface UsersAPIApiUsersAPIEditOrganizationRequest {
    /**
     * Get organization by id.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIEditOrganization
     */
    readonly id: string

    /**
     * 
     * @type {CastaiUsersV1beta1Organization}
     * @memberof UsersAPIApiUsersAPIEditOrganization
     */
    readonly organization: CastaiUsersV1beta1Organization
}

/**
 * Request parameters for usersAPIGetOrganization operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIGetOrganizationRequest
 */
export interface UsersAPIApiUsersAPIGetOrganizationRequest {
    /**
     * Get organization by id.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIGetOrganization
     */
    readonly id: string
}

/**
 * Request parameters for usersAPIListInvitations operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIListInvitationsRequest
 */
export interface UsersAPIApiUsersAPIListInvitationsRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAPIApiUsersAPIListInvitations
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIListInvitations
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for usersAPIListOrganizationUsers operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIListOrganizationUsersRequest
 */
export interface UsersAPIApiUsersAPIListOrganizationUsersRequest {
    /**
     * Get organization users by organization id.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIListOrganizationUsers
     */
    readonly organizationId: string

    /**
     * IncludeGroups is the flag to include group membership in the response.
     * @type {boolean}
     * @memberof UsersAPIApiUsersAPIListOrganizationUsers
     */
    readonly includeGroups?: boolean
}

/**
 * Request parameters for usersAPIListUserGroups operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIListUserGroupsRequest
 */
export interface UsersAPIApiUsersAPIListUserGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAPIApiUsersAPIListUserGroups
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof UsersAPIApiUsersAPIListUserGroups
     */
    readonly userId: string
}

/**
 * Request parameters for usersAPIRemoveOrganizationUsers operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIRemoveOrganizationUsersRequest
 */
export interface UsersAPIApiUsersAPIRemoveOrganizationUsersRequest {
    /**
     * Organization id which should no longer contain the users.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIRemoveOrganizationUsers
     */
    readonly organizationId: string

    /**
     * Users is the list of user ids to remove.
     * @type {Array<string>}
     * @memberof UsersAPIApiUsersAPIRemoveOrganizationUsers
     */
    readonly users: Array<string>
}

/**
 * Request parameters for usersAPIRemoveUserFromOrganization operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIRemoveUserFromOrganizationRequest
 */
export interface UsersAPIApiUsersAPIRemoveUserFromOrganizationRequest {
    /**
     * Organization id which should no longer contain the user.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIRemoveUserFromOrganization
     */
    readonly organizationId: string

    /**
     * User id to remove.
     * @type {string}
     * @memberof UsersAPIApiUsersAPIRemoveUserFromOrganization
     */
    readonly userId: string
}

/**
 * Request parameters for usersAPIUpdateCurrentUserProfile operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIUpdateCurrentUserProfileRequest
 */
export interface UsersAPIApiUsersAPIUpdateCurrentUserProfileRequest {
    /**
     * 
     * @type {CastaiUsersV1beta1User}
     * @memberof UsersAPIApiUsersAPIUpdateCurrentUserProfile
     */
    readonly item: CastaiUsersV1beta1User
}

/**
 * Request parameters for usersAPIUpdateOrganizationUser operation in UsersAPIApi.
 * @export
 * @interface UsersAPIApiUsersAPIUpdateOrganizationUserRequest
 */
export interface UsersAPIApiUsersAPIUpdateOrganizationUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersAPIApiUsersAPIUpdateOrganizationUser
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof UsersAPIApiUsersAPIUpdateOrganizationUser
     */
    readonly userId: string

    /**
     * 
     * @type {UsersAPIUpdateOrganizationUserRequest}
     * @memberof UsersAPIApiUsersAPIUpdateOrganizationUser
     */
    readonly body: UsersAPIUpdateOrganizationUserRequest
}

/**
 * UsersAPIApi - object-oriented interface
 * @export
 * @class UsersAPIApi
 * @extends {BaseAPI}
 */
export class UsersAPIApi extends BaseAPI {
    /**
     * 
     * @summary Makes the user a member of the organization.
     * @param {UsersAPIApiUsersAPIAddUserToOrganizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIAddUserToOrganization(requestParameters: UsersAPIApiUsersAPIAddUserToOrganizationRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIAddUserToOrganization(requestParameters.organizationId, requestParameters.membership, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Auth0IngestLogsWebhook accepts logs from Auth0 from their log streaming.
     * @param {UsersAPIApiUsersAPIAuth0IngestLogsWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIAuth0IngestLogsWebhook(requestParameters: UsersAPIApiUsersAPIAuth0IngestLogsWebhookRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIAuth0IngestLogsWebhook(requestParameters.logs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Consume invitation and add current user to organization
     * @param {UsersAPIApiUsersAPIClaimInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIClaimInvitation(requestParameters: UsersAPIApiUsersAPIClaimInvitationRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIClaimInvitation(requestParameters.invitationId, requestParameters.request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create invitations to join organization by email
     * @param {UsersAPIApiUsersAPICreateInvitationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPICreateInvitations(requestParameters: UsersAPIApiUsersAPICreateInvitationsRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPICreateInvitations(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an organization.
     * @param {UsersAPIApiUsersAPICreateOrganizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPICreateOrganization(requestParameters: UsersAPIApiUsersAPICreateOrganizationRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPICreateOrganization(requestParameters.organization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile for current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPICurrentUserProfile(options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPICurrentUserProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an invitation by id.
     * @param {UsersAPIApiUsersAPIDeleteInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIDeleteInvitation(requestParameters: UsersAPIApiUsersAPIDeleteInvitationRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIDeleteInvitation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an organization by id.
     * @param {UsersAPIApiUsersAPIDeleteOrganizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIDeleteOrganization(requestParameters: UsersAPIApiUsersAPIDeleteOrganizationRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIDeleteOrganization(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit an organization by id.
     * @param {UsersAPIApiUsersAPIEditOrganizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIEditOrganization(requestParameters: UsersAPIApiUsersAPIEditOrganizationRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIEditOrganization(requestParameters.id, requestParameters.organization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetHubspotToken returns token for Hubspot chat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIGetHubspotToken(options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIGetHubspotToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an organization by id.
     * @param {UsersAPIApiUsersAPIGetOrganizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIGetOrganization(requestParameters: UsersAPIApiUsersAPIGetOrganizationRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIGetOrganization(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all pending invitations in the organization.
     * @param {UsersAPIApiUsersAPIListInvitationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIListInvitations(requestParameters: UsersAPIApiUsersAPIListInvitationsRequest = {}, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIListInvitations(requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all users which are members of the organization.
     * @param {UsersAPIApiUsersAPIListOrganizationUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIListOrganizationUsers(requestParameters: UsersAPIApiUsersAPIListOrganizationUsersRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIListOrganizationUsers(requestParameters.organizationId, requestParameters.includeGroups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List user organizations. If all arguments are empty, lists organizations for caller user. Organizations are sorted based on the date user joined the org, so first organization will always be the default one.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIListOrganizations(options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIListOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists groups that a user is assigned to in the organization.
     * @param {UsersAPIApiUsersAPIListUserGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIListUserGroups(requestParameters: UsersAPIApiUsersAPIListUserGroupsRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIListUserGroups(requestParameters.organizationId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes users from organization members.
     * @param {UsersAPIApiUsersAPIRemoveOrganizationUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIRemoveOrganizationUsers(requestParameters: UsersAPIApiUsersAPIRemoveOrganizationUsersRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIRemoveOrganizationUsers(requestParameters.organizationId, requestParameters.users, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes the user from organization members.
     * @param {UsersAPIApiUsersAPIRemoveUserFromOrganizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIRemoveUserFromOrganization(requestParameters: UsersAPIApiUsersAPIRemoveUserFromOrganizationRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIRemoveUserFromOrganization(requestParameters.organizationId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update profile for current user.
     * @param {UsersAPIApiUsersAPIUpdateCurrentUserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIUpdateCurrentUserProfile(requestParameters: UsersAPIApiUsersAPIUpdateCurrentUserProfileRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIUpdateCurrentUserProfile(requestParameters.item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates user in the organization.
     * @param {UsersAPIApiUsersAPIUpdateOrganizationUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPIApi
     */
    public usersAPIUpdateOrganizationUser(requestParameters: UsersAPIApiUsersAPIUpdateOrganizationUserRequest, options?: RawAxiosRequestConfig) {
        return UsersAPIApiFp(this.configuration).usersAPIUpdateOrganizationUser(requestParameters.organizationId, requestParameters.userId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

