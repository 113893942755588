import { CodeProps } from 'react-markdown/lib/ast-to-react';

import {
  PreviewMarkdown as DsPreviewMarkdown,
  PreviewMarkdownCodeElement,
  PreviewMarkdownProps,
} from '@cast/design-system';

import { notify } from 'core/snackbars';

const PreviewMarkdownCode = (props: CodeProps) => (
  <PreviewMarkdownCodeElement
    {...props}
    onCopy={() => notify.success('Copied to clipboard')}
  />
);

export const PreviewMarkdown = (props: PreviewMarkdownProps) => {
  return (
    <DsPreviewMarkdown
      {...props}
      components={{
        code: PreviewMarkdownCode,
        ...props.components,
      }}
    />
  );
};
