import { ReactElement, ReactNode } from 'react';

import { SxProps, capitalize } from '@mui/material';
import MUIAlert, { AlertProps as MUIAlertProps } from '@mui/material/Alert';
import MUIAlertTitle from '@mui/material/AlertTitle';
import clsx from 'clsx';

import { ButtonContext, ButtonProps } from '../buttons';
import {
  CheckCircle,
  IconContext,
  Laptop,
  ShieldWarning,
  Warning,
  WarningCircle,
  X,
  XCircle,
} from '../icons';

type Color =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'advisory'
  | 'exceptional';

export type AlertProps = Omit<MUIAlertProps, 'color' | 'icon'> & {
  size?: 'small' | 'large';
  icon?: ReactNode | Color;
  noIcon?: boolean;
  color?: Color;
  testId?: string;
  titleSx?: SxProps;
  colorToIconMap?: Partial<
    Record<NonNullable<AlertProps['color']>, ReactElement>
  >;
};

const buttonColors: Partial<
  Record<NonNullable<AlertProps['color']>, ButtonProps['variant']>
> = {
  advisory: 'primary',
  info: 'primary',
  success: 'secondary',
  warning: 'danger',
  error: 'danger',
  exceptional: 'primary',
};

const alertIconMap = {
  advisory: <ShieldWarning />,
  info: <WarningCircle />,
  success: <CheckCircle />,
  warning: <Warning />,
  error: <XCircle />,
  exceptional: <Laptop />,
} as Record<NonNullable<AlertProps['color']>, React.ReactElement>;

export const Alert = ({
  title,
  size = 'large',
  children,
  className,
  noIcon,
  icon,
  color,
  variant = 'filled',
  testId,
  colorToIconMap = alertIconMap,
  titleSx,
  ...props
}: AlertProps) => {
  if (colorToIconMap !== alertIconMap) {
    colorToIconMap = { ...alertIconMap, ...colorToIconMap };
  }
  let iconEl: ReactNode;
  if (!noIcon) {
    if (icon) {
      iconEl =
        typeof icon === 'string'
          ? colorToIconMap[icon as keyof typeof colorToIconMap]
          : icon;
    } else if (color) {
      iconEl = colorToIconMap[color];
    }
  }
  return (
    <IconContext.Provider value={{ size: '1em', weight: 'fill' }}>
      <ButtonContext.Provider
        value={{
          size: size === 'small' ? 'small' : 'medium',
          variant: color && buttonColors[color],
        }}
      >
        <MUIAlert
          data-testid={testId}
          className={clsx(
            className,
            title && 'DSuiAlert-hasTitle',
            children && 'DSuiAlert-hasChildren',
            `DSuiAlert-${size}`,
            noIcon && 'DS-Alert-noIcon',
            color && `DS-AlertColor${capitalize(color)}`,
            props.severity && `DS-AlertSeverity${capitalize(props.severity)}`,
            iconEl && 'DS-AlertWithIcon'
          )}
          icon={iconEl}
          action={
            props.onClose && (
              <X
                className="DSuiAlert-close"
                size={size === 'small' ? '16px' : '20px'}
                weight="bold"
                onClick={props.onClose}
                opacity={0.4}
              />
            )
          }
          variant={variant}
          {...props}
        >
          {title && <MUIAlertTitle sx={titleSx}>{title}</MUIAlertTitle>}
          {children && <div className="DSuiAlert-content">{children}</div>}
        </MUIAlert>
      </ButtonContext.Provider>
    </IconContext.Provider>
  );
};
