import { createContext } from 'react';

import { SwipeableViewsState } from './types';

export const SwipeableViewsContext = createContext<
  SwipeableViewsState | undefined
>(undefined);

export const SwipeableViewContext = createContext<
  | {
      key: string;
      isActive: boolean;
    }
  | undefined
>(undefined);
