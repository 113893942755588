import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

export const useBestPracticeCheckExceptionsQuery = (ruleId: string) => {
  const {
    isLoading,
    data: exceptions,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.SECURITY_BEST_PRACTICES_CHECK_EXCEPTIONS, ruleId],
    queryFn: async () => {
      const { data } = await apiClient.securityInsights.getCheckExceptions({
        ruleId,
      });
      return data;
    },
  });

  return { isLoading, exceptions, error, refetch };
};
