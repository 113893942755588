import { Theme } from '@mui/material';

import { Size, TreeUiConfig } from './types';

type Measurements = {
  headerFont: keyof Theme['typography'];
  bodyFont: keyof Theme['typography'];
  footerFont: keyof Theme['typography'];
  headerHeight: number;
  rowHeight: number;
  footerHeight: number;
  horizontalCellPadding: number;
  headerIconSize: number;
  groupHeaderHeight: number;
};

export const tableMeasurements: Record<Size, Measurements> = {
  xl: {
    headerFont: 'L12B',
    bodyFont: 'P14R',
    footerFont: 'L12B',
    headerHeight: 52,
    rowHeight: 48,
    footerHeight: 52,
    horizontalCellPadding: 20,
    headerIconSize: 16,
    groupHeaderHeight: 16,
  },
  l: {
    headerFont: 'L12B',
    bodyFont: 'P12R',
    footerFont: 'L12B',
    headerHeight: 44,
    rowHeight: 40,
    footerHeight: 44,
    horizontalCellPadding: 16,
    headerIconSize: 16,
    groupHeaderHeight: 16,
  },
  m: {
    headerFont: 'L10B',
    bodyFont: 'P12R',
    footerFont: 'L10B',
    headerHeight: 40,
    rowHeight: 32,
    footerHeight: 40,
    horizontalCellPadding: 12,
    headerIconSize: 12,
    groupHeaderHeight: 16,
  },
  s: {
    headerFont: 'L10B',
    bodyFont: 'P12R',
    footerFont: 'L10B',
    headerHeight: 36,
    rowHeight: 28,
    footerHeight: 36,
    horizontalCellPadding: 8,
    headerIconSize: 12,
    groupHeaderHeight: 16,
  },
};

export const defaultTreeUiConfig: TreeUiConfig = {
  iconSize: 13,
  leftExpanderPadding: 16,
  distanceFromLineToIcon: 5,
  firstCellRightPadding: 20,
};
