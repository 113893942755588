import { useRef, useState } from 'react';

import { Box } from '@mui/material';

import { useTableContext } from '../../hooks';
import { ColumnModel } from '../../types';

type Props = {
  column: ColumnModel;
};

export const Resizer = ({ column }: Props) => {
  const {
    tableProps: { ResizerIcon },
    api: {
      current: { resizeColumn },
    },
    overlay,
  } = useTableContext();

  const resizerRootRef = useRef<HTMLDivElement>();
  const [isResizing, setIsResizing] = useState(false);

  return (
    <Box
      ref={resizerRootRef}
      position={column.locked ? 'sticky' : 'absolute'}
      left={column.endOffset}
      top="50%"
      right={0}
      width={0}
      display="flex"
      alignItems="stretch"
      justifyContent="center"
      zIndex={column.locked ? 2 : 0}
      className="DS-Table-Resizer"
    >
      <Box
        position="absolute"
        top={0}
        bottom={0}
        alignItems="center"
        sx={{ cursor: 'pointer', display: isResizing ? 'none' : 'flex' }}
        onMouseDown={(event) => {
          const icon = event.target as HTMLElement;
          const { left, width } = icon.parentElement!.getBoundingClientRect();
          if (event.button > 0) {
            // allow only left clicks
            return;
          }
          setIsResizing(true);
          overlay.resizerLine?.startResizing({
            column,
            startingPoint: left + width / 2,
            onResizingEnded: (newSize) => {
              setIsResizing(false);
              resizeColumn(column.id, newSize);
            },
          });
        }}
      >
        <ResizerIcon />
      </Box>
    </Box>
  );
};
