import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

import { mergeSx, Tooltip, TooltipProps } from '@cast/design-system';

import { useWindowResize } from 'hooks/useWindowResize';

type Props = PropsWithChildren<
  {
    TooltipProps?: Partial<TooltipProps>;
    TypographyProps?: TypographyProps<'p', { component?: 'p' }>;
  } & BoxProps
>;

export const EllipsisWithTooltip = ({
  children,
  TooltipProps = { arrow: true, placement: 'top' },
  TypographyProps = {},
  sx,
  ...boxProps
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const { width } = useWindowResize();
  useEffect(() => {
    if (ref.current) {
      const textElement = ref.current.firstElementChild as HTMLElement;
      const isEllipsis = textElement.offsetWidth < textElement.scrollWidth;
      if (isTooltipVisible !== isEllipsis) {
        setIsTooltipVisible(isEllipsis);
      }
    }
    // eslint-disable-next-line
  }, [!!ref.current, width]);

  return (
    <Box
      sx={mergeSx(
        {
          display: 'flex',
          maxWidth: '100%',
          minWidth: 0,
          typography: 'inherit',
        },
        sx
      )}
      {...boxProps}
      ref={ref}
    >
      {isTooltipVisible ? (
        <Tooltip title={children ?? ''} {...TooltipProps}>
          <Typography
            noWrap
            variant="inherit"
            {...TypographyProps}
            component="p"
          >
            {children}
          </Typography>
        </Tooltip>
      ) : (
        <Typography noWrap variant="inherit" {...TypographyProps} component="p">
          {children}
        </Typography>
      )}
    </Box>
  );
};
