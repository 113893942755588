import { forwardRef } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { DrawerProps } from '@cast/design-system';

import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { Search, SearchProvider } from 'components/search';
import { WorkloadRow } from 'features/cluster/available-savings/types';

import { WorkloadsTable } from './_components/WorkloadsTable';

type Props = DrawerProps & {
  data?: WorkloadRow[];
};

export const WorkloadsDrawer = forwardRef<HTMLDivElement, Props>(
  ({ data, ...drawerProps }: Props, ref) => {
    return (
      <Drawer
        ref={ref}
        {...drawerProps}
        size="xlarge"
        testId="workloads-drawer"
      >
        <DrawerHeader noDivider>
          <Stack mt={32}>
            <Typography variant="L12R" color="grey.400">
              Spot instances
            </Typography>
            <Typography variant="h5">Workloads</Typography>
          </Stack>
        </DrawerHeader>
        <DrawerContent
          sx={{ height: '100%' }}
          contentWrapperProps={{ height: '100%' }}
        >
          <SearchProvider data={data}>
            <Stack pt={24} gap={24} height="100%">
              <Search />
              <Box mx={-32} flexGrow={1}>
                <WorkloadsTable />
              </Box>
            </Stack>
          </SearchProvider>
        </DrawerContent>
      </Drawer>
    );
  }
);

WorkloadsDrawer.displayName = 'WorkloadsDrawer';
