import { useMemo } from 'react';

import { makeCriterionFilter } from '../client-side';
import { ClientSideSearchCriterion } from '../types';

export const useSearchByCriteria = (
  criteria: ClientSideSearchCriterion[],
  values: Record<string, any>,
  data?: any[]
) => {
  const filters = useMemo(() => {
    const filters: Array<(opt: any) => boolean> = [];
    for (const key in values) {
      const criterion = criteria.find((c) => c.key === key);
      if (criterion) {
        filters.push(makeCriterionFilter(criterion, values[key]));
      }
    }
    return filters;
  }, [values, criteria]);

  return useMemo(() => {
    if (!filters.length || !data?.length) {
      return data || [];
    }
    return data.filter((option) => filters.every((f) => f(option)));
  }, [filters, data]);
};
