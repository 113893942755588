import { ReactNode } from 'react';

import { AreaProps, LineProps, BarProps } from 'recharts';

export enum ChartType {
  AREA = 'area',
  LINE = 'line',
  BAR = 'bar',
}

export type ChartTypeToggleOptions = Array<{
  value: ChartType;
  icon: ReactNode;
}>;

export type LineChartConfig = {
  name: string;
  color?: string;
};

export type ChartConfig = {
  type: ChartType.LINE | ChartType.AREA | ChartType.BAR;
  enabled: boolean;
  config: LineProps | AreaProps | BarProps;
  stripedBar?: boolean;
  hasEstimatedData?: boolean;
  stackId?: string;
  xAxisId?: number | string;
  yAxisId?: number | string;
};
