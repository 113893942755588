import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

export const useIntegrationsQuery = () => {
  const {
    data: integrations,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.SECURITY_INTEGRATIONS],
    queryFn: async () => {
      const { data } = await apiClient.securityInsights.getIntegrations();
      return data.integrations;
    },
  });
  return { integrations, isLoading, error, refetch };
};
