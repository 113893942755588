import {
  ChangeEvent,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { styled, SxProps, useTheme } from '@mui/material';
import clsx from 'clsx';

import { getThemeColor } from '../../../utils/theme';

const innerPadding = '2px';
const thumbSize = 'calc(1em - 4px)';
const trackWidth = '1.5625em';
const activeWidth = '1.3em';
const duration = '.1s';

type SwitchSize = 'small' | 'medium' | 'large' | 'xLarge';
type SwitchColor = 'primary' | 'secondary' | 'tertiary';

const defaultSize: SwitchSize = 'medium';
const switchSizes: Record<SwitchSize, number> = {
  small: 16,
  medium: 20,
  large: 24,
  xLarge: 32,
};

const defaultColor: SwitchColor = 'primary';
const switchColors: Record<
  SwitchColor,
  { normal: string; hover: string; disabled: string }
> = {
  primary: {
    normal: 'blue.500',
    hover: 'blue.600',
    disabled: 'blue.100',
  },
  secondary: {
    normal: 'green.500',
    hover: 'green.600',
    disabled: 'green.100',
  },
  tertiary: {
    normal: 'grey.700',
    hover: 'grey.900',
    disabled: 'grey.200',
  },
};

const Root = styled('span', { target: 'DS-Switch-Root' })({
  width: trackWidth,
  height: '1em',
  display: 'inline-block',
  borderRadius: '1em',
  position: 'relative',
  backgroundColor: 'blue',
  flexShrink: 0,
  flexGrow: 0,
});

export type SwitchProps = {
  size?: SwitchSize;
  color?: SwitchColor;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  sx?: SxProps;
  name?: string;
  testId?: string;
} & Omit<HTMLAttributes<HTMLSpanElement>, 'onChange'>;

export const Switch = forwardRef<HTMLSpanElement, SwitchProps>(
  (
    {
      size = defaultSize,
      color = defaultColor,
      name,
      checked,
      onChange,
      disabled,
      sx,
      testId,
      ...rest
    },
    ref
  ) => {
    const thumbRef = useRef<HTMLSpanElement | null>(null);
    const theme = useTheme();
    const isUncontrolled = checked === undefined;
    const wasUncontrolled = useRef(checked === undefined);
    if (isUncontrolled !== wasUncontrolled.current) {
      console.error(
        'Cannot change Switch from controlled to uncontrolled or vice-versa'
      );
    }
    const [innerChecked, setInnerChecked] = useState(!!checked);

    const isChecked = isUncontrolled ? innerChecked : checked;
    const _colors = switchColors[color];
    const colors = useMemo(() => {
      if (isChecked) {
        if (disabled) {
          const disabledColor = getThemeColor(theme, _colors.disabled);
          return {
            normal: disabledColor,
            hover: disabledColor,
            active: disabledColor,
          };
        } else {
          const hoverColor = getThemeColor(theme, _colors.hover);
          return {
            normal: getThemeColor(theme, _colors.normal),
            hover: hoverColor,
            active: hoverColor,
          };
        }
      } else {
        if (disabled) {
          return { normal: theme.palette.grey[200] };
        } else {
          return {
            normal: theme.palette.grey[300],
            active: theme.palette.grey[400],
          };
        }
      }
    }, [_colors, theme, disabled, isChecked]);

    useEffect(() => {
      const el = thumbRef.current;
      const transitionStart = ({ propertyName }: TransitionEvent) => {
        const classList = thumbRef.current?.classList;
        if (propertyName === 'left' && classList) {
          classList.remove('was-checked');
          classList.remove('was-unchecked');
        }
      };
      el?.addEventListener('transitionstart', transitionStart);
      return () => {
        el?.removeEventListener('transitionstart', transitionStart);
      };
    }, [thumbRef]);

    return (
      <Root
        sx={sx}
        data-testid={testId}
        {...rest}
        className={clsx(
          isChecked && 'DS-Switch-checked',
          disabled && 'DS-Switch-disabled',
          rest.className
        )}
        css={{
          fontSize: switchSizes[size],
          backgroundColor: colors.normal,
          '&:hover': {
            backgroundColor: colors.hover,
          },
          '&:active': {
            backgroundColor: colors.active,
          },
        }}
        ref={ref}
      >
        <input
          className="DS-Switch-input"
          name={name}
          checked={checked}
          onChange={(event) => {
            onChange?.(event, !isChecked);
            thumbRef.current?.classList.add(
              `was-${isChecked ? 'unchecked' : 'checked'}`
            );
            setInnerChecked(!isChecked);
          }}
          type="checkbox"
          css={{
            position: 'absolute',
            inset: 0,
            margin: 0,
            borderRadius: 'inherit',
            opacity: 0,
            cursor: disabled ? 'initial' : 'pointer',
            // Make it work in Safari
            width: '100%',
            height: '100%',
          }}
          disabled={disabled}
        />
        <span
          className="DS-Switch-thumb"
          css={{
            pointerEvents: 'none',
            display: 'inline-block',
            position: 'absolute',
            width: thumbSize,
            height: thumbSize,
            left: innerPadding,
            top: innerPadding,
            borderRadius: '50%',
            backgroundColor:
              disabled && !checked ? theme.palette.grey[100] : 'white',
            transition: `width ${duration}, border-radius ${duration}, left ${duration} ease ${duration}, transform ${duration}`,
            '.DS-Switch-input:checked + &': {
              left: `calc(100% - (${thumbSize} + ${innerPadding}))`,
              right: innerPadding,
            },
            '.DS-Switch-Root:not(.DS-Switch-disabled):active input:checked + &, .DS-Switch-Root &.was-unchecked':
              {
                width: activeWidth,
                borderRadius: '60% 40% 40% 60% / 50%',
                transform: `translateX(calc((${activeWidth} - ${thumbSize}) * -1))`,
              },
            '.DS-Switch-Root:not(.DS-Switch-disabled):active :not(input:checked) + &, .DS-Switch-Root &.was-checked':
              {
                width: activeWidth,
                borderRadius: '40% 60% 60% 40% / 50%',
              },
          }}
          ref={thumbRef}
        />
      </Root>
    );
  }
);

Switch.displayName = 'Switch';
