import {
  Cluster,
  AgentStatus,
  ClusterResponse,
  ClusterStatus,
  ClusterDisplayStatus,
} from '@cast/types';

export const getClustersCacheUpdaterFn = (cluster: Cluster) => {
  return (data: Cluster[] | undefined) => {
    if (!data) {
      return [];
    }

    return data.map((_cluster) => {
      if (_cluster.id === cluster.id) {
        return {
          ..._cluster,
          status: cluster.status,
          displayStatus: cluster.displayStatus,
        };
      }
      return _cluster;
    });
  };
};

const transformStatus = (cluster: ClusterResponse) => {
  if (cluster.status === ClusterStatus.READY && !cluster.credentialsId) {
    return ClusterStatus.READ_ONLY;
  }
  return cluster.status;
};

const computeDisplayStatus = (cluster: ClusterResponse) => {
  const { agentStatus, status, credentialsId } = cluster;
  const isAgentConnected = agentStatus === AgentStatus.ONLINE;
  const shouldOverrideStatus =
    status &&
    agentStatus !== AgentStatus.DISCONNECTING &&
    [
      ClusterStatus.PAUSED,
      ClusterStatus.PAUSING,
      ClusterStatus.RESUMING,
      ClusterStatus.DELETING,
      ClusterStatus.FAILED,
    ].includes(status as ClusterStatus);
  const showClusterStatus = isAgentConnected || shouldOverrideStatus;

  if (showClusterStatus && !credentialsId) {
    return ClusterDisplayStatus.READ_ONLY;
  }

  return (showClusterStatus
    ? status
    : agentStatus) as unknown as ClusterDisplayStatus;
};

export const enhanceCluster = (cluster: ClusterResponse): Cluster => {
  return {
    ...cluster,
    status: transformStatus(cluster),
    displayStatus: computeDisplayStatus(cluster),
  };
};
