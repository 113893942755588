import { forwardRef, useImperativeHandle, useRef } from 'react';

import { ResizerLine } from './ResizerLine';
import { useTableContext } from '../../hooks';
import { OverlayState, ResizerLineApi } from '../../types';

export const Overlay = forwardRef<OverlayState, any>((_, ref) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const resizerLineRef = useRef<ResizerLineApi | null>(null);
  const {
    api: {
      current: {
        columnsInfo: { lockedColumnsWidth },
      },
    },
  } = useTableContext();

  useImperativeHandle<OverlayState, OverlayState>(
    ref,
    () => ({ root: rootRef.current, resizerLine: resizerLineRef.current }),
    [rootRef, resizerLineRef]
  );

  return (
    <div className="DS-Table-Overlay" ref={rootRef}>
      {lockedColumnsWidth > 0 && (
        <div className="DS-Table-LockedColumnsDivider" />
      )}
      <ResizerLine ref={resizerLineRef} />
    </div>
  );
});

Overlay.displayName = 'Overlay';
