import { PropsWithChildren, ReactNode } from 'react';

import {
  Box,
  Stack,
  styled,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material';

import {
  DataPopper,
  DataPopperRow,
  DataPopperRowProps,
} from '@cast/design-system';
import { getCurrencyPrecision } from '@cast/utils';

import { Money } from 'components/money';

type Props = PropsWithChildren<{
  primary?: string;
  secondary?: string;
  title?: string;
  total?: number | ReactNode;
  projectedCost?: number;
  projected?: boolean;
  forecast?: boolean;
  disableHeader?: boolean;
  headerProps?: DataPopperRowProps;
  secondaryProps?: TypographyProps;
  sx?: SxProps;
}>;

export const CostReportChartTooltip = styled(
  ({
    primary,
    secondary,
    secondaryProps,
    title,
    total,
    projectedCost,
    projected,
    forecast,
    disableHeader = false,
    headerProps = {},
    children,
    ...props
  }: Props) => {
    const precision =
      typeof total === 'number' ? getCurrencyPrecision(total) : 0;
    const projectedPrecision = getCurrencyPrecision(projectedCost);
    return (
      <DataPopper
        size="small"
        data-testid="cost-report-chart-tooltip"
        {...props}
      >
        {!disableHeader && (
          <DataPopperRow
            left={
              <Typography variant="L10M" color="grey.400">
                {primary}
              </Typography>
            }
            right={
              <>
                {forecast ? (
                  <Typography variant="L10M" color="grey.400">
                    Forecast
                  </Typography>
                ) : (
                  <Typography
                    variant="L10M"
                    color="grey.400"
                    {...secondaryProps}
                  >
                    {secondary}
                  </Typography>
                )}

                {projected && !forecast && (
                  <Typography variant="L10M" color="grey.400">
                    Projected
                  </Typography>
                )}
              </>
            }
            {...headerProps}
          />
        )}

        {title && (
          <DataPopperRow
            dividerBottom
            left={<Typography variant="L10M">{title}</Typography>}
            right={
              <Stack direction="row" flexWrap="nowrap" gap={16}>
                {typeof total === 'number' ? (
                  <Typography variant="P10B" align="right" minWidth="50px">
                    <Money
                      amount={total}
                      precision={precision}
                      fixed={precision}
                    />
                  </Typography>
                ) : total !== null ? (
                  total
                ) : (
                  '--'
                )}

                {projected && typeof projectedCost !== 'undefined' && (
                  <Typography
                    variant="P10B"
                    align="right"
                    minWidth="50px"
                    sx={{ opacity: 0.5 }}
                  >
                    <Money
                      amount={projectedCost}
                      precision={projectedPrecision}
                      fixed={projectedPrecision}
                    />
                  </Typography>
                )}
              </Stack>
            }
          />
        )}

        <Box flexGrow={1} sx={{ opacity: forecast ? 0.3 : 1 }}>
          {children}
        </Box>
      </DataPopper>
    );
  }
)(({ projected }) => {
  return {
    minWidth: projected ? 240 : 200,
  };
});
