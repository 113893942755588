import { forwardRef } from 'react';

import { SnackbarContent, CustomContentProps } from 'notistack';

import { Snackbar } from '@cast/design-system';

type NotificationSnackbarProps = CustomContentProps;

export const NotificationSnackbar = forwardRef<
  HTMLDivElement,
  NotificationSnackbarProps
>((props, ref) => {
  const { id, message, variant, iconVariant, action } = props;

  return (
    <SnackbarContent ref={ref} role="alert">
      <Snackbar
        variant={variant}
        icon={iconVariant[variant]}
        action={typeof action === 'function' ? action(id) : action}
        testId="notification-snackbar"
      >
        {message}
      </Snackbar>
    </SnackbarContent>
  );
});

NotificationSnackbar.displayName = 'NotificationSnackbar';
