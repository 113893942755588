import { ThemeOptions } from '@mui/material';

import { responsiveStyles } from '../../theme/breakpoints';
import { DsShadow } from '../../theme/shadows';

export const drawerTheme: ThemeOptions['components'] = {
  DsDrawerOverlay: {
    styleOverrides: {
      root: {
        position: 'absolute',
        inset: 0,
        zIndex: '2',
        paddingTop: '30vh',
      },
      opaqueOverlay: {
        position: 'absolute',
        inset: 0,
        backgroundColor: 'white',
        opacity: 0.9,
      },
      contentWrapper: {
        position: 'relative',
      },
    },
  },
  MuiDrawer: {
    defaultProps: {
      hideBackdrop: false,
    },
    styleOverrides: {
      root: {
        '&.DS-Drawer-no-backdrop': {
          left: 'unset',
        },

        '& .MuiBackdrop-root': {
          backgroundColor: 'unset',
        },
      },
      paper: ({ theme }) => ({
        boxShadow: theme.shadows[DsShadow.DRAWER],
        '&.DS-Drawer-size': {
          '&XXsmall': {
            width: 300,
            ...responsiveStyles({
              lg: { width: 300 },
            }),
          },
          '&Xsmall': {
            width: 384,
            ...responsiveStyles({
              lg: { width: 384 },
            }),
          },
          '&Small': {
            width: 480,
            ...responsiveStyles({
              lg: { width: 480 },
            }),
          },
          '&Medium': {
            width: 704,
            ...responsiveStyles({
              lg: { width: 704 },
            }),
          },
          '&Large': {
            width: 864,
            ...responsiveStyles({
              lg: { width: 864 },
            }),
          },
          '&Xlarge': {
            width: 960,
            ...responsiveStyles({
              lg: { width: 960 },
            }),
          },
          '&Xxlarge': {
            width: 1024,
            ...responsiveStyles({
              lg: { width: 1024 },
            }),
          },
        },
        '&.DS-Drawer-color': {
          '&Primary': {
            '--backgroundColor': 'white',
            backgroundColor: 'white',
          },
          '&Secondary': {
            backgroundColor: theme.palette.grey[900],
            '--backgroundColor': theme.palette.grey[900],
            color: 'white',

            '&.DS-Drawer-sizeXsmall, &.DS-Drawer-sizeSmall': {
              backgroundColor: theme.palette.grey[800],
            },

            '& .DS-CloseDrawerButton-root': {
              color: 'white',
              opacity: 0.4,
              '&:hover': { backgroundColor: 'unset' },
            },
          },
        },
      }),
    },
  },
};
