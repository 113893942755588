import { ReactNode } from 'react';

import { OptimizeContext } from './_providers';

type Props = {
  id: string;
  children: ReactNode;
};

export const Variant = ({ id, children }: Props) => {
  return (
    <OptimizeContext.Consumer>
      {(value) => (value === id ? children : null)}
    </OptimizeContext.Consumer>
  );
};
