import * as yup from 'yup';

export const AWSARNCredentialsSchema = yup.object({
  eks: yup
    .object()
    .shape({
      assumeRoleArn: yup
        .string()
        .required('Please set a Role ARN for the cloud credential.'),
    })
    .required(),
});
