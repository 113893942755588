import { ElementType, forwardRef, ReactElement, useContext } from 'react';

import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import clsx from 'clsx';

import { InternalTabsContext } from './InternalTabsContext';

export type TabProps<
  C extends ElementType = 'button',
  Value = any
> = MuiTabProps<C, { testId?: string; value?: Value }>;

export const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ className, children, label, testId, ...rest }: TabProps<any>, ref) => {
    const { onTabMouseEnter, onTabMouseLeave } =
      useContext(InternalTabsContext);
    return (
      <MuiTab
        className={clsx('DsTab-Root', className)}
        onMouseEnter={() => onTabMouseEnter(rest.value)}
        onMouseLeave={() => onTabMouseLeave(rest.value)}
        label={<div className="DsTab-Label">{label}</div>}
        ref={ref}
        {...rest}
        data-tabvalue={rest.value}
        data-testid={testId || String(rest.value)}
      >
        {children}
      </MuiTab>
    );
  }
) as (<C extends ElementType>(props: TabProps<C>) => ReactElement) & {
  displayName: string;
};

Tab.displayName = 'Tab';
