import { MEMORY_UNIT_SUFFIXES } from '@cast/constants';

export enum CpuType {
  ON_DEMAND = 'onDemand',
  FALLBACK = 'fallback',
  SPOT = 'spot',
  NORMALIZED = 'normalized',
}

export enum ComputeResourceType {
  CPU = 'cpu',
  MEMORY = 'memory',
}

export enum ComputeResourceTypeExtended {
  CPU = 'cpu',
  MEMORY = 'memory',
  GPU = 'gpu',
}

export type MemoryUnits = (typeof MEMORY_UNIT_SUFFIXES)[number];

export type Memory = {
  bytes: number;
  value: number;
  unit: MemoryUnits;
};
