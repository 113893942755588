import { forwardRef, HTMLAttributes, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

import { FontSettings, useTextWidth } from '@cast/utils';

type Props = HTMLAttributes<HTMLInputElement> & {
  fontSettings?: FontSettings;
  value?: string;
  onWidthChanged?: (width: number) => void;
  setProps?: string | string[];
  styleFactory?: (width: number, text: string) => SxProps;
};

const AutoSizingInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      fontSettings,
      onWidthChanged,
      styleFactory = (width) => ({ width }),
      ...props
    }: Props,
    ref
  ) => {
    const [innerRef, setInnerRef] = useState<HTMLInputElement | undefined>();
    const [innerVal, setInnerVal] = useState<string>('');
    const isControlled = props.value !== undefined;
    const width = useTextWidth(
      // eslint-disable-next-line react/prop-types
      !isControlled ? innerVal : props.value || props.placeholder,
      innerRef,
      fontSettings
    );
    useEffect(() => {
      if (width !== undefined) {
        onWidthChanged?.(width);
      }
    }, [width, onWidthChanged]);
    return (
      <Box
        component="input"
        {...props}
        onChange={(event: any) => {
          setInnerVal(event.target.value);
          props.onChange?.(event);
        }}
        ref={(r: HTMLInputElement) => {
          if (typeof ref === 'function') {
            ref(r);
          } else if (ref) {
            ref.current = r;
          }
          setInnerRef(r || undefined);
        }}
        sx={styleFactory?.(width || 0, props.value || '')}
      />
    );
  }
);
AutoSizingInput.displayName = 'AutoSizingInput';

export default AutoSizingInput;
