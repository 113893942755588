import { useContext, useState } from 'react';

import { SxProps } from '@mui/material';

import { SettingsDrawerContext } from '../context';

const animationDuration = 200;

export type UseRowLockingWithAnimationArgs = {
  id: string;
  row: HTMLElement | null;
};
export const useRowLockingWithAnimation = ({
  id,
  row,
}: UseRowLockingWithAnimationArgs) => {
  const [translateY, setTranslateY] = useState<number>(0);
  const sx: SxProps = {
    transition: `transform ${animationDuration}ms`,
    transform: !!translateY ? `translateY(${translateY}px)` : undefined,
  };
  const { switchColumnLocking, lockedGroupRef, unlockedGroupRef } = useContext(
    SettingsDrawerContext
  );
  return {
    switchLocking: (locked: boolean) => {
      if (!row) {
        return;
      }
      if (locked) {
        if (!lockedGroupRef.current) {
          return;
        }
        const rowTop = row.getBoundingClientRect().top;
        const destinationBottom =
          lockedGroupRef.current.getBoundingClientRect().bottom;
        setTranslateY(-(rowTop - destinationBottom));
      } else {
        if (!unlockedGroupRef.current) {
          return;
        }
        const { top: rowTop, height: rowHeight } = row.getBoundingClientRect();
        const destinationTop =
          unlockedGroupRef.current.getBoundingClientRect().top;
        setTranslateY(destinationTop - rowTop - rowHeight);
      }
      setTimeout(() => {
        setTranslateY(0);
        switchColumnLocking({
          id,
          locked,
        });
      }, animationDuration);
    },
    lockingSx: sx,
  };
};
