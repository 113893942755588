import { SvgIcon } from '@mui/material';

export const ResizerIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 20 16"
      sx={{ fontSize: 19, cursor: 'pointer', fill: 'none', color: 'grey.400' }}
    >
      <path
        d="M6.20128 4.6308C6.25026 4.62134 6.30091 4.62586 6.34743 4.64384C6.39261 4.663 6.43115 4.69504 6.45825 4.73597C6.4855 4.77712 6.49996 4.82542 6.4998 4.87477V4.87497L6.4998 11.125L6.49981 11.1252C6.49996 11.1745 6.4855 11.2228 6.45825 11.264C6.43111 11.305 6.3925 11.337 6.34725 11.3562C6.31621 11.3684 6.28317 11.3748 6.2498 11.375C6.18313 11.3746 6.11912 11.3488 6.07082 11.3029L2.94655 8.17864C2.94652 8.17861 2.94649 8.17858 2.94646 8.17855C2.89943 8.13101 2.87305 8.06684 2.87305 7.99997C2.87305 7.93311 2.89942 7.86895 2.94643 7.82142C2.94647 7.82138 2.94651 7.82134 2.94655 7.82129L6.0706 4.69725C6.10706 4.66328 6.15234 4.64025 6.20128 4.6308Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.125"
      />
      <line
        x1="10.5"
        y1="0.5"
        x2="10.5"
        y2="15.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M13.4375 4.87497V11.125M13.4375 4.87497H13.4375H13.4375ZM13.4375 4.87497V11.125M13.4375 11.125C13.4373 11.1867 13.4554 11.247 13.4894 11.2985C13.5235 11.3499 13.572 11.3901 13.6289 11.414L13.4375 11.125ZM13.5 4.87497L13.5 4.87477C13.4998 4.82542 13.5143 4.77712 13.5416 4.73597C13.5687 4.69504 13.6072 4.663 13.6524 4.64384C13.6989 4.62586 13.7495 4.62134 13.7985 4.6308C13.8475 4.64025 13.8927 4.66328 13.9292 4.69725L17.0533 7.82129C17.0533 7.82133 17.0533 7.82137 17.0534 7.82141C17.1004 7.86894 17.1268 7.93311 17.1268 7.99997C17.1268 8.06684 17.1004 8.13101 17.0533 8.17855C17.0533 8.17858 17.0533 8.17861 17.0533 8.17864L13.929 11.3029C13.8807 11.3488 13.8167 11.3746 13.75 11.375C13.7166 11.3748 13.6836 11.3684 13.6526 11.3562C13.6073 11.337 13.5687 11.305 13.5416 11.264C13.5143 11.2228 13.4998 11.1745 13.5 11.1252V11.125L13.5 4.87497Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.125"
      />
    </SvgIcon>
  );
};
