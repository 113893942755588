import { Box } from '@mui/material';

import { LogoLoader } from './LogoLoader';

export const SuspenseLoader = () => {
  return (
    <Box
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="transparent"
      sx={{ inset: 0 }}
      aria-label="loading-spinner"
    >
      <LogoLoader />
    </Box>
  );
};
