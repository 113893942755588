import { forwardRef, ReactNode } from 'react';

import { Field, FieldProps } from 'react-final-form';

import {
  ChipInput,
  ChipInputForwardRef,
  ChipInputProps,
} from '@cast/design-system';

import { useControlsGroupState } from './controls-group';
import { useRffFormState } from '../hooks';
import { showErrorOnChange } from '../utils';

const coerceToArray = (value: string | string[]) =>
  Array.isArray(value) ? value : [value];

export type RffChipInputProps = Omit<
  ChipInputProps,
  'name' | 'value' | 'onChange'
> & {
  name: string;
  renderError?: (error: string | string[]) => ReactNode;
  fieldProps?: Partial<FieldProps<string[], any>>;
};

export const RffChipInput = forwardRef<ChipInputForwardRef, RffChipInputProps>(
  ({ fieldProps = {}, renderError, ...chipInputProps }, ref) => {
    const { mode } = useRffFormState();
    const { disabled, readOnly, name } = useControlsGroupState({
      disabled: chipInputProps.disabled,
      readOnly: mode === 'view' || chipInputProps.readOnly,
      name: chipInputProps.name,
    });

    return (
      <Field
        {...fieldProps}
        name={name!}
        render={(props) => {
          const {
            input: { onBlur, onFocus, onChange, value },
            meta,
          } = props;
          const showError = showErrorOnChange(meta);

          const activeChips = value?.map((v: string) => ({
            title: v,
            onDestroy: () => {
              onChange(value.filter((chip: string) => chip !== v));
            },
          }));

          return (
            <ChipInput
              ref={ref}
              separators={['Enter']}
              onSeparation={(val) => {
                onChange([...new Set([...value, ...coerceToArray(val)])]);
              }}
              onBlur={onBlur}
              onFocus={onFocus}
              error={
                showError ? renderError?.(meta.error) || meta.error : undefined
              }
              startIcon={false}
              chips={activeChips}
              sx={{
                '.DsChipInput-Chip': {
                  backgroundColor: 'grey.600',
                },
              }}
              onClear={() => {
                onChange([]);
              }}
              multiline
              {...chipInputProps}
              readOnly={readOnly}
              disabled={disabled}
            />
          );
        }}
      />
    );
  }
);

RffChipInput.displayName = 'RffChipInput';
