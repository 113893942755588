import { ChartTypeToggle, chartTypeToggleOptions } from 'components/chart';
import { ChartType, ChartTypeToggleOptions } from 'types/charts';

import { useCostReportContext } from '../hooks/useCostReportContext';
import { CostReportChartType } from '../types/costOverTime';

export const CostReportChartToggle = () => {
  const { changeType, chartType } = useCostReportContext();

  const excludeOptions = [ChartType.LINE];

  const chartTypeOptions: ChartTypeToggleOptions =
    chartTypeToggleOptions.filter(
      ({ value }) =>
        Object.values(CostReportChartType).includes(
          value as unknown as CostReportChartType
        ) && excludeOptions.indexOf(value) === -1
    );

  return (
    <ChartTypeToggle
      active={chartType}
      onChange={changeType}
      options={chartTypeOptions}
    />
  );
};
