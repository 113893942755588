import { bindToggle } from 'material-ui-popup-state/core';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';

import { List, ListItem, ListItemText } from '@cast/design-system';

import { EXTERNAL_LINKS } from 'common/links';
import { Link as RouterLink } from 'components/router';

import { HeaderButton, HeaderPopover } from './_components';

export const ApiMenu = () => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'api-menu',
  });

  return (
    <>
      <HeaderButton
        {...bindToggle(popupState)}
        active={popupState.isOpen}
        testId="api-menu-btn"
      >
        API
      </HeaderButton>
      <HeaderPopover {...bindPopover(popupState)} testId="api-menu">
        <List size="medium" disablePadding sx={{ minWidth: '190px' }}>
          <ListItem
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component="a"
            target="_blank"
            rel="noreferer"
            href={EXTERNAL_LINKS.castai_swagger}
            onClick={() => popupState.close()}
          >
            <ListItemText>API documentation</ListItemText>
          </ListItem>
          <ListItem
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={RouterLink}
            to="/user/api-access"
            onClick={() => popupState.close()}
          >
            <ListItemText>API access keys</ListItemText>
          </ListItem>
        </List>
      </HeaderPopover>
    </>
  );
};
