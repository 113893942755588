import { PropsWithChildren, ReactNode } from 'react';

import { Box, SxProps } from '@mui/material';

import { DatePickerTrigger } from './DatePickerTrigger';
import { mergeSx } from '../../../../utils';
import { InputProps } from '../../../controls';
import { CalendarBlank } from '../../../icons';
import { useDatePickerContext } from '../useDatePickerContext';

export type DatePickerInputProps = {
  inputProps?: Pick<InputProps, 'size' | 'sx' | 'startAdornment' | 'label'>;
  testId?: string;
  popperContent: ReactNode;
  sx?: SxProps;
};

export const DatePickerInput = ({
  inputProps,
  testId = 'date-picker',
  popperContent,
  sx,
}: PropsWithChildren<DatePickerInputProps>) => {
  const ctx = useDatePickerContext();

  const isOpen = Boolean(ctx.popoverAnchor);

  return (
    <Box sx={sx}>
      <DatePickerTrigger
        onClick={ctx.handleOpen}
        isOpen={isOpen}
        inputProps={{
          size: 'large',
          readOnly: true,
          focused: isOpen,
          value: ctx.inputValue(),
          startAdornment: <CalendarBlank size={20} />,
          ...inputProps,
          sx: mergeSx(
            {
              '& *:hover': { cursor: 'pointer !important' },
              '& .DS-Input-readOnly': {
                backgroundColor: 'white !important',
              },
            },
            inputProps?.sx
          ),
        }}
        testId={testId}
      />
      {popperContent}
    </Box>
  );
};
