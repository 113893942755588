import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';

import { Params } from 'react-router-dom';

import { DateRange } from '@cast/types';
import { useCompoundReducer } from '@cast/utils';

import {
  RangePickerProvider,
  useRangePickerContext,
  RangePickerQueryParamKeys,
  DateRangeFilter,
} from 'components/date/RangePicker';
import { RangePickerProviderProps } from 'components/date/RangePicker/providers/types';
import { useReportCapabilitiesQuery } from 'hooks/queries/cost';
import { useCluster } from 'hooks/useCluster';
import { useParams } from 'hooks/useParams';
import { readStorage, writeStorage } from 'utils/storage';

import { CostReportChartType } from '../types/costOverTime';
import { adjustEndDate } from '../utils/costOverTime';

const STORE_KEY_BASE = 'cost-report.cluster';

type ReducerState = {
  chartType: CostReportChartType;
  currentDateRange: DateRange;
  currentDateRangeFilter?: DateRangeFilter;
};

export type CostReportProviderProps = PropsWithChildren<
  RangePickerProviderProps & {
    isDetails?: boolean;
  }
>;

const createInitialState = (
  currentDateRange: DateRange,
  currentDateRangeFilter?: DateRangeFilter
): ReducerState => {
  return {
    chartType: readStorage(
      `${STORE_KEY_BASE}.chart-type`,
      CostReportChartType.BAR
    ),
    currentDateRange,
    currentDateRangeFilter,
  };
};

type CostReportActions = {
  changeType: (chartType: CostReportChartType) => void;
  changeDateRange: (value: DateRange) => void;
  changeDateRangeFilter: (value: DateRangeFilter | undefined) => void;
};

type CostOverTimeContextValue = ReducerState &
  CostReportActions & {
    routeProps: Params;
    isDefaultFilter: boolean;
    isCapabilitiesLoading: boolean;
    isExporterInstalled: boolean;
    showGpuData: boolean;
    networkDataAvailable: boolean;
    refetchCapabilities: () => void;
    capabilitiesError: unknown;
  };

export const CostReportContext = createContext<CostOverTimeContextValue>(
  {} as never
);

const CostReportInnerProvider = ({
  children,
  routeProps,
}: CostReportProviderProps) => {
  const rangePickerCtx = useRangePickerContext();
  const { cluster } = useCluster();

  const { state, dispatcher } = useCompoundReducer(
    {
      changeType: (state, chartType: CostReportChartType) => {
        writeStorage(`${STORE_KEY_BASE}.chart-type`, chartType);
        return {
          ...state,
          chartType,
        };
      },
      changeDateRange: (state, dateRange: DateRange) => {
        return {
          ...state,
          currentDateRange: dateRange,
        };
      },
      changeDateRangeFilter: (state, filter: DateRangeFilter | undefined) => {
        return {
          ...state,
          currentDateRangeFilter: filter,
        };
      },
    },
    createInitialState(
      rangePickerCtx.currentDateRange,
      rangePickerCtx.currentDateRangeFilter
    )
  );

  useEffect(() => {
    dispatcher.changeDateRange(rangePickerCtx.currentDateRange);
    dispatcher.changeDateRangeFilter(rangePickerCtx.currentDateRangeFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangePickerCtx.currentDateRange, rangePickerCtx.currentDateRangeFilter]);

  const [currentFrom, currentTo] = useMemo(
    () => adjustEndDate(rangePickerCtx.currentDateRange),
    [rangePickerCtx.currentDateRange]
  );

  const {
    data: reportCapabilities,
    isLoading,
    refetch,
    error,
  } = useReportCapabilitiesQuery({
    clusterId: cluster.id,
    startTime: currentFrom.toISOString(),
    endTime: currentTo.toISOString(),
    enabled: !!cluster.id,
  });

  return (
    <CostReportContext.Provider
      value={{
        ...state,
        ...dispatcher,
        isExporterInstalled: reportCapabilities?.gpuMetricsAvailable ?? false,
        showGpuData: reportCapabilities?.gpuDataAvailable ?? false,
        networkDataAvailable: reportCapabilities?.networkDataAvailable ?? false,
        isCapabilitiesLoading: isLoading,
        refetchCapabilities: refetch,
        capabilitiesError: error,
        isDefaultFilter: rangePickerCtx.isDefaultFilter,
        routeProps,
      }}
    >
      {children}
    </CostReportContext.Provider>
  );
};

const queryParamKeys: RangePickerQueryParamKeys = {
  fromDate: 'cost_report_from',
  toDate: 'cost_report_to',
  dateRangePreset: 'cost_report_date_preset',
};

export const CostReportProvider = ({
  children,
  ...props
}: CostReportProviderProps) => {
  const params = useParams();
  return (
    <RangePickerProvider
      queryParamKeys={queryParamKeys}
      routeProps={params}
      {...props}
    >
      <CostReportInnerProvider routeProps={params} {...props}>
        {children}
      </CostReportInnerProvider>
    </RangePickerProvider>
  );
};
