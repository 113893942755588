import { useEffect } from 'react';

import { analyticsEvents } from 'core/analytics';
import { useNavigate } from 'hooks/useNavigate';
import { deleteCookie, getCookie } from 'utils/cookie';

const INTERESTED_IN_COOKIE_KEY = '_cast_interested_in';

type CookieValues = 'security';

const redirectMap: Record<CookieValues, string> = {
  security: '/organization/security/dashboard',
};

export const useNavigateToPageOfInterest = (isLoading: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const interestedIdCookie = getCookie(INTERESTED_IN_COOKIE_KEY);
    const redirectPath = redirectMap[interestedIdCookie as CookieValues];
    if (!redirectPath) {
      return;
    }

    deleteCookie({
      name: INTERESTED_IN_COOKIE_KEY,
      domain:
        document.location.hostname === 'localhost' ? 'localhost' : '.cast.ai',
    });
    analyticsEvents.showedInterestIn(interestedIdCookie!);
    navigate(redirectPath, { replace: true });
  }, [isLoading, navigate]);
};
