import { PropsWithChildren } from 'react';

import {
  Stack,
  styled,
  Typography,
  Divider as MuiDivider,
} from '@mui/material';
import * as yup from 'yup';

import { Button } from '@cast/design-system';
import { Webhook } from '@cast/types';
import { makeValidateSync } from '@cast/utils';

import { EXTERNAL_LINKS } from 'common/links';
import { ExternalLink } from 'components/router';
import { FormMode, RffForm, RffInput, WhenMode } from 'core/forms/rff';

import { AuthenticationKeysRow } from './AuthenticationKeysRow';
import { CategorySelect } from './CategorySelect';
import { SeverityCheckboxes } from './NotificationSeverity';
import { OperationSelect } from './OperationSelect';
import { RequestTemplateRow } from './RequestTemplateRow';
import { FormModel } from './types';
import { makeFormModel } from './utils';
import 'core/extensions/yup';

type GroupTitleProps = PropsWithChildren<{ optional?: boolean }>;

const GroupTitle = ({ optional, children }: GroupTitleProps) => {
  return (
    <Typography variant="P14B" color="grey.900" display="inline-flex" pb={4}>
      {children}
      {optional && (
        <Typography
          fontStyle="italic"
          color="grey.400"
          fontWeight={400}
          variant="inherit"
          pl="4px"
        >
          Optional
        </Typography>
      )}
    </Typography>
  );
};

const Divider = styled(MuiDivider)({ marginBottom: '16px' });

type GroupProps = PropsWithChildren<{ title: string }>;

const Group = ({ title, children }: GroupProps) => {
  return (
    <Stack pt="24px">
      <GroupTitle>{title}</GroupTitle>
      <Divider />
      {children}
    </Stack>
  );
};

const validator = makeValidateSync(
  yup.object().shape({
    name: yup.string().required('Name is required'),
    callbackUrl: yup.string().required('Callback URL is required'),
    selectedSeverities: yup
      .array()
      .of(yup.string())
      .min(1, 'Please select at least one severity'),
    requestTemplate: yup.string().required('Request template is required'),
    subcategory: yup.string().when('category', {
      is: (category: string) => !!category,
      then: (schema) => schema.required('Operation is required'),
    }),
  })
);

type Props = {
  webhook?: Webhook;
  mode: FormMode;
  onSubmit?: (formModel: FormModel) => void;
  onDirty?: (dirty: boolean) => void;
  onCancel?: () => void;
};

export const WebhookForm = ({
  mode,
  webhook,
  onSubmit,
  onDirty,
  onCancel,
}: Props) => {
  return (
    <RffForm
      mode={mode}
      initialValues={makeFormModel(webhook)}
      initialValuesEqual={(a, b) => a?.id === b?.id}
      onSubmit={onSubmit}
      onDirty={onDirty}
      validate={validator}
      array
      testId="webhook-form"
    >
      <Stack>
        <Group title="Details">
          <Stack gap="16px">
            <RffInput
              name="name"
              label="Name"
              placeholder="Enter name"
              testId="webhook-name-input"
            />
            <RffInput
              name="callbackUrl"
              label="Callback URL"
              placeholder="Enter URL"
              testId="webhook-callback-url-input"
            />
            <CategorySelect />
            <OperationSelect />
          </Stack>
        </Group>
        <Group title="Notification severity">
          <SeverityCheckboxes />
        </Group>
        <Group title="Template">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="P12M" mb={8}>
              Request template
            </Typography>
            <ExternalLink
              link={EXTERNAL_LINKS.docs_webhook_examples}
              sx={{ typography: 'P12M' }}
            >
              Documentation
            </ExternalLink>
          </Stack>
          <RequestTemplateRow />
        </Group>
        <Group title="Authorization keys">
          <Stack pb="24px">
            <AuthenticationKeysRow />
            <WhenMode mode={['create', 'edit']}>
              <Stack
                direction="row"
                gap="16px"
                justifyContent="flex-end"
                pt="24px"
              >
                <Button variant="tertiary" size="medium" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  size="medium"
                  type="submit"
                  testId="webhook-save-button"
                >
                  Save
                </Button>
              </Stack>
            </WhenMode>
          </Stack>
        </Group>
      </Stack>
    </RffForm>
  );
};
