import { ReactNode, useState, MouseEvent } from 'react';

import { Box, SxProps } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import pluralize from 'pluralize';
import { TZDate } from 'react-day-picker';

import { MultiDayInfinitePicker } from './MultiDayInfinitePicker';
import { DatePickerFooter } from '../../components/DatePickerFooter';
import {
  DatePickerInput,
  DatePickerInputProps,
} from '../../components/DatePickerInput';
import { DatePickerPopper } from '../../components/DatePickerPopper';
import { defaultDateFormat } from '../../constants';
import DatePickerProvider from '../../DatePickerProvider';
import {
  InfiniteDayPickerRef,
  MarkedDate,
  MultiDayCondition,
} from '../../types';
import { convertDate, utcNow, utcToDate } from '../../utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export type MultiDayPickerProps = {
  initialDays?: string[];
  onChange: (days: string[] | undefined, timezone: string) => void;
  format?: string;
  endMonth?: MultiDayCondition;
  startMonth?: MultiDayCondition;
  minDate?: MultiDayCondition;
  maxDate?: MultiDayCondition;
  triggerInputProps?: DatePickerInputProps['inputProps'];
  allowTimeInput?: boolean;
  hideTimezonePicker?: boolean;
  initialTimeZone?: string;
  markedDates?: MarkedDate[];
  hint?:
    | ReactNode
    | ((args: {
        dates: string[];
        timezone: string;
        pickerRef: InfiniteDayPickerRef;
      }) => ReactNode);
  initialMonth?: string;
  inputValue?: (dates: string[] | undefined, timezone: string) => string;
  sx?: SxProps;
};

export const MultiDayPicker = ({
  initialDays,
  onChange,
  format: _format = defaultDateFormat,
  endMonth,
  startMonth,
  triggerInputProps,
  maxDate,
  minDate,
  hideTimezonePicker = false,
  initialTimeZone = dayjs.tz.guess(),
  markedDates = [],
  hint,
  inputValue,
  sx,
}: MultiDayPickerProps) => {
  const [pickerRef, setPickerRef] = useState<InfiniteDayPickerRef>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [selectedDays, setSelectedDays] = useState<TZDate[] | undefined>(() =>
    initialDays?.map((day) => utcToDate(day, initialTimeZone))
  );
  const [initialMonth] = useState<TZDate>(() =>
    utcToDate(initialDays?.[0] ? initialDays[0] : utcNow(), initialTimeZone)
  );

  const [timezone, setTimezone] = useState<string>(initialTimeZone);

  const handleOpen = (event: MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClose = () => {
    setTimezone(initialTimeZone);
    setSelectedDays(initialDays?.map((day) => utcToDate(day, initialTimeZone)));
    handleClose();
  };

  const handleTimezoneChange = (newTimezone: string) => {
    const convertedSelections = selectedDays?.map(
      (day) => convertDate(day, timezone, newTimezone)!
    );

    setSelectedDays(convertedSelections);

    if (convertedSelections?.length) {
      pickerRef?.scrollToMonth(convertedSelections[0]);
    }

    setTimezone(newTimezone);
  };

  const handleApply = () => {
    onChange(
      selectedDays?.map((day) => day?.toUTCString()),
      timezone
    );
    handleClose();
  };

  const formattedInputValue = (selected?: TZDate[]) => {
    if (selected) {
      return `${selected.length} ${pluralize('day', selected.length)}`;
    }
    return '';
  };

  const inputValueFormatter = inputValue
    ? inputValue.bind(
        null,
        selectedDays?.map((day) => day?.toUTCString()),
        timezone
      )
    : formattedInputValue.bind(null, selectedDays);

  return (
    <DatePickerProvider
      mode="multiple"
      format={_format}
      inputValue={inputValueFormatter}
      selected={selectedDays}
      setSelected={setSelectedDays}
      popoverAnchor={anchorEl}
      setPopoverAnchor={setAnchorEl}
      handleOpen={handleOpen}
      handleClose={onClose}
      handleApply={handleApply}
      startMonth={startMonth}
      endMonth={endMonth}
      maxDate={maxDate}
      minDate={minDate}
      initialMonth={initialMonth}
      pickerRef={pickerRef}
      setPickerRef={setPickerRef}
      hideTimezonePicker={hideTimezonePicker}
      timezone={timezone}
      setTimezone={handleTimezoneChange}
      markedDates={markedDates}
      allowTimeInput={false}
    >
      <DatePickerInput
        sx={sx}
        inputProps={triggerInputProps}
        popperContent={
          <DatePickerPopper
            footer={
              <DatePickerFooter
                isMini
                hint={
                  typeof hint === 'function'
                    ? hint({
                        dates:
                          selectedDays?.map((day) => day?.toUTCString()) ?? [],
                        timezone,
                        pickerRef,
                      })
                    : hint
                }
              />
            }
          >
            <Box pt={16}>
              <MultiDayInfinitePicker />
            </Box>
          </DatePickerPopper>
        }
      />
    </DatePickerProvider>
  );
};
