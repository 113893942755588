import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

import { Skeleton } from '../../feedback/skeleton';

export const RowSkeleton = (props: SxProps) => {
  return (
    <Box
      sx={{
        p: 11,
        px: 32,
        ...props,
      }}
    >
      <Skeleton sx={{ height: '100%' }} />
    </Box>
  );
};
