import { createContext, PropsWithChildren, useContext } from 'react';

import { Box, BoxProps } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const InViewContext = createContext<boolean | undefined>(undefined);

export const LazyLoadedBox = ({
  children,
  ...props
}: PropsWithChildren<BoxProps>) => {
  const { inView, ref } = useInView({ triggerOnce: true });
  return (
    <Box ref={ref} {...props}>
      <InViewContext.Provider value={inView}>{children}</InViewContext.Provider>
    </Box>
  );
};

export const useInViewport = () => {
  const isVisible = useContext(InViewContext);
  if (typeof isVisible === 'boolean') {
    return isVisible;
  }
  return true;
};
