import clsx from 'clsx';

import { useDrawerInstance } from './hooks';
import { mergeSx } from '../../utils';
import { IconButton, IconButtonProps } from '../buttons';
import { CaretDoubleRight, X } from '../icons';

type Props = IconButtonProps & {
  icon?: 'arrows' | 'x';
  positioned?: boolean;
  showGuard?: boolean;
};

export const CloseDrawerButton = ({
  icon,
  positioned = true,
  showGuard,
  sx,
  ...props
}: Props) => {
  const drawerInstance = useDrawerInstance();
  icon = icon ?? (!!drawerInstance.parentId ? 'x' : 'arrows');
  const Icon = icon === 'arrows' ? CaretDoubleRight : X;
  return (
    <IconButton
      variant="ghost"
      size="small"
      sx={mergeSx(
        positioned && [
          { position: 'absolute' },
          icon === 'arrows' && { top: 26, left: 16 },
          icon === 'x' && { top: 16, right: 16 },
        ],
        sx
      )}
      onClick={() => {
        if (showGuard) {
          drawerInstance.setShouldRenderGuard(true);
        } else {
          drawerInstance.close();
        }
      }}
      testId="close-drawer"
      {...props}
      className={clsx(props.className, 'DS-CloseDrawerButton-root')}
    >
      <Icon />
    </IconButton>
  );
};
