import {
  FormControlLabel as MuiFormControlLabel,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { Input, Radio, RadioGroup } from '@cast/design-system';

import { useAvailableSavingsPauseSchedule } from '../../../_providers/PauseScheduleProvider';
import { PauseScheduleMode } from '../types';

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  '& .MuiFormControlLabel-label': {
    ...theme.typography.P12R,
    color: 'black',
    marginLeft: 8,
  },
}));

export const SetSchedulingMode = () => {
  const {
    changeClusterScheduleMode,
    clusterScheduleMode,
    hoursRunning,
    setHoursRunning,
  } = useAvailableSavingsPauseSchedule();

  const isCustom = clusterScheduleMode === PauseScheduleMode.CUSTOM;

  return (
    <RadioGroup
      value={clusterScheduleMode}
      onChange={(_, value) => changeClusterScheduleMode(+value)}
      sx={{ gap: 12 }}
    >
      <Typography variant="P12M">Cluster runs:</Typography>
      <FormControlLabel
        control={
          <Radio value={PauseScheduleMode.BUSINESS_HOURS} color="tertiary" />
        }
        label="15 h / day x 5 days a week"
      />
      <FormControlLabel
        control={
          <Radio
            value={PauseScheduleMode.CUSTOM}
            color="tertiary"
            testId="hours-per-week-radio"
          />
        }
        label={
          <Stack direction="row" alignItems="center" gap={8}>
            <Input
              disabled={!isCustom}
              autoFocus={isCustom}
              value={hoursRunning}
              onChange={(_, value) => setHoursRunning(+value)}
              endAdornment="h"
              type="number"
              size="small"
              sx={{ width: 58 }}
              testId="hours-per-week-input"
            />
            <Typography variant="inherit">a week</Typography>
          </Stack>
        }
      />
    </RadioGroup>
  );
};
