import { OrganizationRole } from '@cast/types';

const organization1 = {
  id: '1',
  name: 'Acme corp',
  createdAt: '2021-06-08T06:31:42.179261Z',
  role: OrganizationRole.owner,
};

const organization2 = {
  id: '2',
  name: 'Acme corp 2',
  createdAt: '2021-05-27T12:52:06.906788Z',
  role: OrganizationRole.member,
};

const organization3 = {
  id: '3',
  name: '',
  createdAt: '2021-05-27T12:52:06.906788Z',
  role: OrganizationRole.viewer,
};

const rawListResponse = {
  organizations: [organization1, organization2],
};

const rawJoinResponse = {
  organizationId: '441749be-7266-4e59-908b-16e1856dfe0b',
  membership: {
    user: {
      id: '25052ef4-9ec4-4791-8fd2-878b8030df6a',
      username: 'google-oauth2|116762997471583572268',
      name: '',
      email: 'info@ovi.lt',
    },
    role: 'owner',
  },
};

const organizationMembersApiResponse = {
  users: [
    {
      role: OrganizationRole.owner,
      user: {
        email: 'hello@world.com',
        lastActivity: '2020-12-02T00:00:00Z',
      },
    },
  ],
};

const organizationMembers = [
  {
    email: 'hello@world.com',
    lastActivity: '2020-12-02T00:00:00Z',
    role: OrganizationRole.owner,
  },
];

export const organizationFixtures = {
  listResponse: rawListResponse,
  detailsResponse: organization1,
  entities: {
    [organization1.id]: organization1,
    [organization2.id]: organization2,
  },
  rawJoinResponse,
  organization1,
  organization2,
  organization3,
  organizationMembers,
  organizationMembersApiResponse,
};
