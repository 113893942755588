export enum Cloud {
  gcp = 'gcp',
  aws = 'aws',
  azure = 'azure',
}

export enum K8sProvider {
  EKS = 'eks',
  AKS = 'aks',
  GKE = 'gke',
  ROSA = 'openshift',
  KOPS = 'kops',
}
