import { ReactNode } from 'react';

import { Field, FieldProps } from 'react-final-form';

import { Input, InputProps } from '@cast/design-system';

import { useControlsGroupState } from './controls-group';
import { useRffFormState } from '../hooks';
import { showErrorOnChange } from '../utils';

export type RffInputProps = Omit<InputProps, 'name' | 'size'> & {
  name: string;
  size?: 'small' | 'medium' | 'large';
  renderError?: (error: string | string[]) => ReactNode;
  fieldProps?: Partial<FieldProps<string, any>>;
};

export const RffInput = ({
  fieldProps = {},
  renderError,
  ...inputProps
}: RffInputProps) => {
  const { mode } = useRffFormState();
  const { disabled, readOnly, name } = useControlsGroupState({
    disabled: inputProps.disabled,
    readOnly: mode === 'view' || inputProps.readOnly,
    name: inputProps.name,
  });
  return (
    <Field
      {...fieldProps}
      name={name || inputProps.name}
      render={(props) => {
        const {
          input: { onBlur, onFocus, onChange, value },
          meta,
        } = props;
        const showError = showErrorOnChange(meta);
        return (
          <Input
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(_, value) => onChange(value)}
            value={value}
            error={
              showError ? renderError?.(meta.error) || meta.error : undefined
            }
            success={meta.touched && meta.valid}
            {...(inputProps.withSpinButtons && {
              onIncrement: (_, value) =>
                onChange(
                  Math.min(
                    value,
                    inputProps?.max ??
                      inputProps.inputProps?.max ??
                      Number.MAX_SAFE_INTEGER
                  )
                ),
              onDecrement: (_, value) =>
                onChange(
                  Math.max(
                    value,
                    inputProps?.min ??
                      inputProps.inputProps?.min ??
                      Number.MIN_SAFE_INTEGER
                  )
                ),
            })}
            {...inputProps}
            disabled={disabled}
            readOnly={readOnly}
          />
        );
      }}
    />
  );
};
