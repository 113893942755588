import { useState } from 'react';

import {
  FormControlLabel as MuiFormControlLabel,
  RadioGroup,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { Radio, TextArea } from '@cast/design-system';

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  '& .MuiFormControlLabel-label': {
    ...theme.typography.P12R,
    color: 'black',
    marginLeft: 8,
  },
}));

type Props = {
  reasons: string[];
  setReason: (value: string) => void;
};

export const ExceptionReason = ({ reasons, setReason }: Props) => {
  const [reasonSelection, setReasonSelection] = useState(reasons[0]);
  const [otherReason, setOtherReason] = useState('');
  const allReasons = [...reasons, 'Other'];

  return (
    <Stack mt={16}>
      <Typography variant="P14B" color="grey.900" mb={16}>
        Reason for exception:
      </Typography>
      <RadioGroup
        value={reasonSelection}
        onChange={(_, value) => {
          setReasonSelection(value);
          setReason(value === 'Other' ? otherReason : value);
        }}
        sx={{ gap: 12 }}
        data-testid="reason-radio-group"
      >
        {allReasons.map((reason) => (
          <FormControlLabel
            key={reason}
            control={<Radio value={reason} size="small" />}
            label={reason}
          />
        ))}
      </RadioGroup>
      {reasonSelection === 'Other' && (
        <TextArea
          placeholder="Enter reason here"
          value={otherReason}
          onChange={(e) => {
            setReason(e.target.value);
            setOtherReason(e.target.value);
          }}
          minRows={3}
          disableResize={false}
          responsive
          sx={{ mt: 16 }}
          testId="reason-textarea"
        />
      )}
    </Stack>
  );
};
