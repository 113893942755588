import { PropsWithChildren } from 'react';

import { Box, BoxProps, styled } from '@mui/material';

type Props = PropsWithChildren<{ topDivider?: boolean } & BoxProps>;

export const DrawerFooter = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'topDivider',
  target: 'Drawer-Footer',
})<Props>(({ theme, topDivider }) => ({
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  background: 'white',
  ...(topDivider && { borderTop: `1px solid ${theme.palette.grey[200]}` }),
}));
