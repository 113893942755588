import { breadcrumbsTheme } from './breadcrumbs/breadcrumbsTheme';
import { stepperTheme } from './stepper/stepperTheme';
import { tabsTheme } from './tabs/tabsTheme';

export * from './stepper';
export * from './tabs';
export * from './breadcrumbs';

export const navigationTheme = {
  ...stepperTheme,
  ...breadcrumbsTheme,
  ...tabsTheme,
};
