import { useRef, useState } from 'react';

import { DialogContent, Stack, Typography } from '@mui/material';
import { useModal } from 'mui-modal-provider';

import { Button, Dialog, DialogProps, Icons } from '@cast/design-system';
import { Cluster } from '@cast/types';
import { useDelayedEvent } from '@cast/utils';

import { SwipeableViews } from 'components/boxes';
import { HexIconSuccess } from 'components/hex-icons';
import { useDeleteClusterMutation } from 'hooks/mutations';
import { ShowModalOutput } from 'types/modal';

import { Phase2OffboardingStep } from './_components/Phase2OffboardingStep';
import { ClusterRemovalDialogContent } from '../_components/ClusterRemovalDialogContent';

type Props = DialogProps & {
  cluster: Cluster;
  onClose: () => void;
};

const DeleteClusterDialog = ({ cluster, onClose, ...props }: Props) => {
  const { isPending, mutate } = useDeleteClusterMutation(cluster);
  const [slide, setSlide] = useState<'confirm' | 'success'>('confirm');
  const isManaged = Boolean(cluster?.credentialsId);

  const autoClose = useDelayedEvent({
    milliseconds: 2000,
    onEnded: onClose,
  });

  const handleDelete = () => {
    mutate(undefined, {
      onSuccess: () => {
        setSlide('success');
        autoClose.activate();
      },
    });
  };

  return (
    <Dialog {...props} onClose={onClose} testId="delete-cluster-dialog">
      <SwipeableViews
        activeSlide={slide}
        slides={{
          confirm: (
            <ClusterRemovalDialogContent
              title={`Remove ${cluster.name} from CAST AI?`}
              onClose={onClose}
              actions={
                <Stack direction="row" justifyContent="flex-end" gap={16}>
                  <Button
                    variant="tertiary"
                    onClick={onClose}
                    testId="cancel-cluster-delete"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleDelete}
                    loading={isPending}
                    testId="confirm-cluster-delete"
                  >
                    Remove cluster
                  </Button>
                </Stack>
              }
            >
              <Typography
                variant="P14R"
                color="textSecondary"
                mb={isManaged ? 20 : 0}
              >
                The cluster will stay operational, but will be removed from the
                console.
              </Typography>
              {isManaged && <Phase2OffboardingStep clusterId={cluster.id} />}
            </ClusterRemovalDialogContent>
          ),
          success: (
            <DialogContent sx={{ p: 24 }}>
              <Stack
                direction="row"
                alignItems="flex-start"
                gap={8}
                data-testid="cluster-removed-message"
              >
                <HexIconSuccess
                  display="flex"
                  alignSelf="flex-start"
                  size={40}
                  animationType="pulsateSlow"
                  icon={<Icons.CheckCircle size={16} weight="fill" />}
                  sx={{ mt: -4 }}
                />

                <Typography variant="h4">{cluster.name} removed</Typography>
              </Stack>
            </DialogContent>
          ),
        }}
      />
    </Dialog>
  );
};

export const useDeleteClusterDialog = () => {
  const { showModal } = useModal({ disableAutoDestroy: true });
  const modalInstance = useRef<ShowModalOutput | undefined>();

  return {
    openDeleteClusterDialog: (cluster: Cluster) => {
      modalInstance.current = showModal(
        DeleteClusterDialog,
        {
          cluster,
          onClose: () => modalInstance.current?.destroy(),
        },
        { rootId: 'root' }
      );
    },
  };
};
