import { MutableRefObject, useEffect, useRef } from 'react';

import {
  NormalizedTableProps,
  RowKey,
  SetTableState,
  TableApi,
} from '../../../types';

const expandRow = (setState: SetTableState, rowKey: RowKey) => {
  setState((state) => ({
    ...state,
    expandedRows: [...state.expandedRows, rowKey],
  }));
};

const collapseRow = (setState: SetTableState, rowKey: RowKey) => {
  setState((state) => ({
    ...state,
    expandedRows: state.expandedRows.filter((key) => key !== rowKey),
  }));
};
export const useExpandableRows = (
  tableApi: MutableRefObject<TableApi>,
  { rowKey: getRowKey }: NormalizedTableProps
) => {
  const initialized = useRef(false);
  if (!initialized.current) {
    tableApi.current.state.expandedRows = [];
    initialized.current = true;
  }

  useEffect(() => {
    const setState = tableApi.current.setState;
    tableApi.current.expandRow = (row: any) => {
      const rowKey = getRowKey(row);
      if (tableApi.current.state.expandedRows.includes(rowKey)) {
        return false;
      }
      expandRow(setState, rowKey);
      return true;
    };
    tableApi.current.collapseRow = (row: any) => {
      const rowKey = getRowKey(row);
      if (!tableApi.current.state.expandedRows.includes(rowKey)) {
        return false;
      }
      collapseRow(tableApi.current.setState, rowKey);
      return true;
    };
    tableApi.current.toggleIsRowExpanded = (row: any) => {
      const rowKey = getRowKey(row);
      const isExpanded = tableApi.current.state.expandedRows.includes(rowKey);
      if (isExpanded) {
        collapseRow(tableApi.current.setState, rowKey);
      } else {
        expandRow(setState, rowKey);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableApi]);
};
