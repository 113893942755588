import { useMemo, useReducer } from 'react';

type HandlerFn<STATE> = (state: STATE, ...args: any[]) => STATE;
type ExtractParamsWithoutState<
  STATE,
  HANDLERFN extends HandlerFn<STATE>
> = HANDLERFN extends (state: STATE, ...args: infer ARGS) => STATE
  ? ARGS
  : never;

type Dispatcher<STATE, HANDLER extends Record<string, HandlerFn<STATE>>> = {
  [K in keyof HANDLER]: HANDLER[K] extends HandlerFn<STATE>
    ? (...args: ExtractParamsWithoutState<STATE, HANDLER[K]>) => STATE
    : never;
};

type UseCompoundReducer<
  STATE,
  HANDLER extends Record<string, HandlerFn<STATE>>
> = {
  state: STATE;
  dispatcher: Dispatcher<STATE, HANDLER>;
};

export function useCompoundReducer<
  STATE,
  HANDLER extends Record<string, HandlerFn<STATE>>,
  INITIAL
>(
  handler: HANDLER,
  initialState: INITIAL,
  initializer: (state: INITIAL) => STATE
): UseCompoundReducer<STATE, HANDLER>;
export function useCompoundReducer<
  STATE,
  HANDLER extends Record<string, HandlerFn<STATE>>
>(handler: HANDLER, initialState: STATE): UseCompoundReducer<STATE, HANDLER>;
export function useCompoundReducer<
  STATE,
  HANDLER extends Record<string, HandlerFn<STATE>>,
  INITIAL
>(
  handler: HANDLER,
  initialState: INITIAL,
  initializer?: (state: INITIAL) => STATE
): UseCompoundReducer<STATE, HANDLER> {
  const [state, dispatch] = useReducer(
    (state: STATE, action: any) => {
      const type = action.type;
      const args = action.args;
      return handler[type].apply(null, [state, ...args]);
    },
    initialState,
    initializer as any
  );

  return {
    state,
    dispatcher: useMemo(
      () =>
        Object.keys(handler).reduce((acc, key) => {
          acc[key] = (
            ...args: ExtractParamsWithoutState<
              STATE,
              (typeof handler)[typeof key]
            >
          ) => {
            dispatch({ type: key, args });
          };
          return acc;
        }, {} as any),
      [handler]
    ),
  };
}
