import { AxiosInstance } from 'axios';

import { ExternalClusterAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const ClusterApiAdapter = (client: AxiosInstance) => {
  const generatedApi = ExternalClusterAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  return {
    getCredentialsScript: generatedApi.externalClusterAPIGetCredentialsScript,
    getOffboardingScript: generatedApi.externalClusterAPIGetCleanupScript,
    getClusters: generatedApi.externalClusterAPIListClusters,
    getCluster: generatedApi.externalClusterAPIGetCluster,
    updateCluster: generatedApi.externalClusterAPIUpdateCluster,
    deleteCluster: generatedApi.externalClusterAPIDeleteCluster,
    disconnectCluster: generatedApi.externalClusterAPIDisconnectCluster,
    reconcileCluster: generatedApi.externalClusterAPIReconcileCluster,
    events: generatedApi.externalClusterAPIHandleCloudEvent,
    getNode: generatedApi.externalClusterAPIGetNode,
    getNodes: generatedApi.externalClusterAPIListNodes,
    deleteNode: generatedApi.externalClusterAPIDeleteNode,
  };
};
