import { useState } from 'react';

import {
  clearQueryParamSilently,
  getQueryParam,
  setQueryParamSilently,
} from '@cast/utils';

export const useFreeText = (urlKey?: string) => {
  const freeTextQueryKey = urlKey ? `${urlKey}_freeText` : undefined;
  const [freeText, setFreeText] = useState(() => {
    if (!freeTextQueryKey) {
      return '';
    }
    const qp = getQueryParam(freeTextQueryKey);
    if (qp) {
      try {
        return decodeURIComponent(qp);
      } catch (e) {
        console.error('Cannot decode free text');
        return '';
      }
    }
    return '';
  });

  return {
    freeText,
    setFreeText: (text?: string) => {
      if (!text) {
        setFreeText('');
        if (freeTextQueryKey) {
          clearQueryParamSilently(freeTextQueryKey);
        }
      } else {
        setFreeText(text);
        if (freeTextQueryKey) {
          setQueryParamSilently(freeTextQueryKey, encodeURIComponent(text));
        }
      }
    },
  };
};
