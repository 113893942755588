import { capitalize, styled } from '@mui/material';

import { JsonContainer } from './JsonContainer';
import { PreviewJsonProps } from './types';
import { shouldForwardProp } from '../../../utils/shouldForwardProp';
import { CodeErrorBoundary } from '../_components';
import { PreviewConfigProvider } from '../_providers';

const Root = styled('div', {
  name: 'DsPreviewJson',
  slot: 'Root',
  target: 'DsPreviewJson-root',
  shouldForwardProp,
  overridesResolver: ({ ownerState }, styles) => [
    styles.root,
    styles[`color${capitalize(ownerState.color)}`],
  ],
})<{ ownerState: PreviewJsonProps }>({});

const defaultValues = {
  color: 'primary' as const,
  size: 'large' as const,
  indentBy: 4,
  interactive: true,
  errorMessage: 'Cannot display JSON',
};

export const PreviewJson = (props: PreviewJsonProps) => {
  props = {
    ...defaultValues,
    ...props,
  };

  return (
    <PreviewConfigProvider {...props}>
      <Root ownerState={props} sx={props.rootSx} data-testid="json-preview">
        <CodeErrorBoundary>
          <JsonContainer {...props} />
        </CodeErrorBoundary>
      </Root>
    </PreviewConfigProvider>
  );
};
