import blue from './blue';
import green from './green';

export { default as aqua } from './aqua';
export { default as blue } from './blue';
export { default as grey } from './grey';
export { default as green } from './green';
export { default as indigo } from './indigo';
export { default as red } from './red';
export { default as yellow } from './yellow';

export const primary = blue;
export const secondary = green;
