import { ReactNode, useContext } from 'react';

import {
  capitalize,
  Step as MuiStep,
  StepLabel,
  StepperContext,
  StepperContextType,
  StepProps as MuiStepProps,
} from '@mui/material';
import clsx from 'clsx';

import { InternalContext } from './InternalContext';
import { Spinner } from '../../feedback';
import { Check, X } from '../../icons';

type StepColor = 'primary' | 'secondary' | 'light' | 'dark' | 'error';

export type StepProps = Omit<MuiStepProps, 'content'> & {
  color?: StepColor;
  content?: ReactNode;
  label?: ReactNode;
  error?: boolean;
  loading?: boolean;
};

const wrapContent = (content: number | ReactNode) => (
  <span className="DS-Step-content">{content}</span>
);

export const Step = ({
  color = 'primary',
  className,
  content: parentContent,
  label,
  index,
  error,
  loading,
  ...rest
}: StepProps) => {
  const stepperContext = useContext(StepperContext) as StepperContextType;
  const { size } = useContext(InternalContext);
  const content = wrapContent(parentContent || (index || 0) + 1);
  const isActive = stepperContext.activeStep === index;

  let chld: ReactNode;
  if (label) {
    chld = <StepLabel icon={content}>{label}</StepLabel>;
  } else {
    chld = content;
  }
  let icon: ReactNode = (
    <Check
      className="DS-Step-statusIcon DS-Step-completedIcon"
      type="fill"
      size={size === 'medium' ? 20 : 16}
    />
  );
  if (error) {
    icon = (
      <X
        className="DS-Step-statusIcon DS-Step-errorIcon"
        size={size === 'medium' ? 20 : 16}
      />
    );
  }
  if (loading) {
    icon = (
      <Spinner
        className="DS-Step-statusIcon DS-Step-loadingIcon"
        size={size === 'medium' ? '16px' : '12px'}
        sx={{ color: 'white' }}
        noBackground
      />
    );
  }

  return (
    <MuiStep
      className={clsx(
        'DS-Step-root',
        `DS-Step-color${capitalize(color)}`,
        isActive && 'Mui-active',
        error && 'DS-Step-error',
        loading && 'DS-Step-loading',
        rest.disabled && 'Mui-disabled',
        className
      )}
      tabIndex={rest.disabled ? undefined : 1}
      index={index}
      {...rest}
    >
      {icon}
      {chld}
    </MuiStep>
  );
};
