import { forwardRef } from 'react';

import { CaretDown, CaretUp, Lock, LockOpen } from '../../../../../../icons';
import { IconProps } from '../../../../../../icons/IconBase';

export const ExpandIcon = ({
  isExpanded,
  ...props
}: IconProps & { isExpanded: boolean }) => {
  const Icon = isExpanded ? CaretUp : CaretDown;
  return <Icon {...props} />;
};

export const LockIcon = forwardRef<
  SVGSVGElement,
  IconProps & { isLocked?: boolean; isDisabled?: boolean }
>(({ isLocked, isDisabled, onClick, ...props }, ref) => {
  const Icon = isLocked ? Lock : LockOpen;
  return (
    <Icon
      weight={isLocked ? 'fill' : undefined}
      css={({ palette }) => ({
        color: isDisabled ? palette.grey[300] : palette.grey[500],
        '&:hover': {
          color: !isDisabled ? palette.grey[900] : undefined,
        },
        cursor: isDisabled ? undefined : 'pointer',
      })}
      onClick={isDisabled ? undefined : onClick}
      {...props}
      ref={ref}
    />
  );
});

LockIcon.displayName = 'LockIcon';
