export enum Primitive {
  STRING = 1,
  NUMBER,
  BOOLEAN,
  NUMERIC_STRING,
  LINK,
  DATE,
  NULL,
  UNDEFINED,
}

export type PrimitiveVal = string | number | boolean | null | undefined;
