import { forwardRef } from 'react';

import { keyframes } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    transform: scale(2.25);
  }
`;

type Props = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  init?: boolean;
  isActive?: boolean;
  onClick?: (active: boolean) => void;
  testId?: string;
};

export const Beacon = forwardRef<HTMLDivElement, Props>(
  (
    { onClick, isActive, top, bottom, left, right, init, testId, ...other },
    ref
  ) => {
    return (
      <Box
        {...other}
        className={clsx({ active: isActive })}
        ref={ref}
        sx={{
          ...(!init ? { position: 'absolute', top, bottom, left, right } : {}),
          backgroundColor: '#69BBD5',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '16px',
          width: '16px',

          '&:not(:hover):not(.active)::before': {
            position: 'absolute',
            animation: `${pulse} 1s ease-in-out infinite`,
            content: '""',
            height: '16px',
            width: '16px',
            borderRadius: '50%',
            backgroundColor: '#69BBD5',
            display: 'inline-flex',
            opacity: 0.35,
          },
          ...(init
            ? {
                cursor: 'pointer',
                '& > *': { opacity: 0 },
                '&:hover, &.active': {
                  width: '42px',
                  borderRadius: '16px',
                  transition: 'width 400ms',
                  transitionDelay: '40ms',

                  '& > *': {
                    transition: 'opacity 40ms',
                    transitionDelay: '40ms',
                    opacity: 1,
                  },
                },
              }
            : {}),
        }}
        component="div"
        onClick={() => onClick?.(true)}
        data-testid={testId}
      >
        {init && (
          <Typography variant="L10M" color="white" component="span">
            TOUR
          </Typography>
        )}
      </Box>
    );
  }
);
Beacon.displayName = 'Beacon';
