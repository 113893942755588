import { PropsWithChildren, useRef, useState } from 'react';

import { Box, styled, SxProps } from '@mui/material';
import * as Sentry from '@sentry/react';

import { mergeSx } from '@cast/design-system';

import { IS_DEV } from 'common/constants';
import { Portal, PortalSlot } from 'core/portals';
import { usePersistentState } from 'hooks/usePersistentState';

import { ContentContainer } from './ContentContainer';
import { Header } from './header';
import { useMainLayout } from './hooks';
import { MainLayoutProvider } from './MainLayoutProvider';
import { Sidebar } from './sidebar';
import { SidebarState } from './types';

export type MainLayoutProps = PropsWithChildren<{
  sidebar?: SidebarState;
  sx?: SxProps;
  containerSx?: SxProps;
  noMenu?: boolean;
}>;

const PageLayout = styled('div', {
  target: 'PageLayout-root',
  shouldForwardProp: (propName: PropertyKey) => propName !== 'sidebar',
})<MainLayoutProps>(({ theme: { breakpoints, palette }, sidebar }) => [
  {
    '--backgroundColor': palette.grey[100],
    display: 'grid',
    margin: '0 auto',
    minHeight: '100vh',
    backgroundColor: palette.grey[100],
    [breakpoints.up('lg')]: {
      gridTemplateColumns:
        sidebar === false
          ? '[main] auto'
          : `[gap-start] auto [sidebar] ${
              sidebar === 'maximized' ? 232 : 64
            }px [main] ${
              sidebar === 'maximized' ? 1592 : 1760
            }px [gap-end] auto`,
    },
    [breakpoints.down('lg')]: {
      gridTemplateColumns:
        sidebar === false
          ? '[main] auto'
          : `[sidebar] ${sidebar === 'maximized' ? 232 : 64}px [main] auto`,
    },
    gridTemplateRows:
      '[toolbar] max-content [sticky-header] max-content [gap] 40px [content] 1fr [gap] 40px [footer] max-content',
  },
]);

export const MainLayout = ({
  sidebar = 'maximized',
  noMenu,
  sx,
  containerSx,
  children,
}: MainLayoutProps) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const { isInsideMainLayout } = useMainLayout();
  const sentryMessagePushed = useRef<boolean>();
  const [styleOverrides, setStyleOverrides] = useState<SxProps | undefined>();
  const [sidebarState, setSidebarState] = usePersistentState<SidebarState>(
    'sidebarState',
    sidebar,
    'session'
  );
  const [fullWidthChildrenCount, setIsFullWidthChildrenCount] = useState(0);
  const isFullWidthContent = fullWidthChildrenCount > 0;

  if (isInsideMainLayout) {
    const message = 'MainLayout rendered inside MainLayout';
    if (IS_DEV) {
      throw new Error(message);
    }
    if (!sentryMessagePushed.current) {
      Sentry.captureMessage(message);
      sentryMessagePushed.current = true;
    }
    return <>{children}</>;
  }

  return (
    <MainLayoutProvider
      sidebarState={sidebarState}
      setSidebarState={setSidebarState}
      isFullWidthContent={isFullWidthContent}
      setIsFullWidthContent={(isFullWidth) => {
        setIsFullWidthChildrenCount((val) => val + (isFullWidth ? 1 : -1));
      }}
      setStyleOverrides={setStyleOverrides}
      sidebarRef={sidebarRef}
    >
      <PageLayout sx={mergeSx(sx, styleOverrides)} sidebar={sidebarState}>
        {sidebar && (
          <Sidebar
            ref={sidebarRef}
            sx={{
              gridColumn: {
                xs: 'sidebar',
                lg: '1 / span 2',
              },
              gridRow: '1 / -1',
            }}
          />
        )}

        <Header
          sx={{
            gridColumn: 'main',
            gridRow: 'toolbar',
          }}
          noSidebar={!sidebar}
          noMenu={noMenu}
        />

        <PortalSlot
          sx={{
            gridColumn: 'main / -1',
            gridRow: 'sticky-header',
          }}
          portal={Portal.PAGE_HEADER}
        />

        {isFullWidthContent ? (
          <Box
            sx={mergeSx(
              {
                gridColumn: {
                  xs: 'main',
                  lg: 'main / -1',
                },
                gridRow: 'content',
              },
              containerSx
            )}
            component="main"
          >
            {children}
          </Box>
        ) : (
          <ContentContainer
            sx={mergeSx(
              {
                gridColumn: 'main',
                gridRow: 'content',
              },
              containerSx
            )}
            component="main"
          >
            {children}
          </ContentContainer>
        )}

        <PortalSlot
          sx={{
            gridColumn: {
              xs: 'main',
              lg: 'main / -1',
            },
            gridRow: 'footer',
            bottom: 0,
            position: 'sticky',
          }}
          portal={Portal.PAGE_FOOTER}
        />
      </PageLayout>
    </MainLayoutProvider>
  );
};
