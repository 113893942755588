import { forwardRef, useEffect } from 'react';

import { Typography } from '@mui/material';

import { DrawerProps } from '@cast/design-system';
import { BestPracticeCheck } from '@cast/types';

import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { orgSecurityEvents } from 'core/analytics';

import { ExceptionsForm } from './_components/ExceptionsForm';

type Props = {
  check: BestPracticeCheck;
} & DrawerProps;

export const ExceptionsManagementDrawer = forwardRef<HTMLDivElement, Props>(
  ({ check, ...drawerProps }, ref) => {
    useEffect(() => {
      if (check.ruleId) {
        orgSecurityEvents.complianceCheckExceptionDrawerOpened(check.ruleId);
      }
    }, [check.ruleId]);

    return (
      <Drawer
        ref={ref}
        size="xxlarge"
        {...drawerProps}
        testId="exception-management-drawer"
      >
        <DrawerHeader noDivider sticky={false} sx={{ mb: 24 }}>
          <Typography variant="L12R" color="grey.400" mt={15}>
            Except resources from the check
          </Typography>
          <Typography variant="h5" color="grey.900">
            {check.name}
          </Typography>
        </DrawerHeader>
        <DrawerContent sx={{ overflowY: 'hidden' }}>
          <ExceptionsForm
            ruleId={check.ruleId || ''}
            closeDrawer={() => drawerProps.onClose?.({}, 'escapeKeyDown')}
          />
        </DrawerContent>
      </Drawer>
    );
  }
);

ExceptionsManagementDrawer.displayName = 'ExceptionsManagementDrawer';
