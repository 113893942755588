import { useMemo } from 'react';

import { getGroupedColumn } from '../../../../../_utils';
import { useTableContext } from '../../../../../hooks';
import { SettingsRowModel } from '../types';

export const useRowBaseModel = (setting: SettingsRowModel) => {
  const {
    api: {
      current: {
        columnsInfo: { groupedColumnsProps },
      },
    },
  } = useTableContext();

  return useMemo(() => {
    const { id, visible } = setting;
    const isInvisible = visible === false;
    const { column, group } = getGroupedColumn(id, groupedColumnsProps);
    if (!column && !group) {
      throw new Error(
        `Cannot display settings row: no column with "${id}" found`
      );
    }
    const title = !column
      ? group!.title
      : column.settingsTitle || column.header;
    const entity = group && !column ? group : column!;
    return {
      title,
      visibilitySettingTooltip: entity.visibilitySettingTooltip,
      frozen: entity.frozen,
      lockSettingDisabled:
        entity.lockSettingDisabled || entity.frozen || isInvisible,
      lockSettingTooltip: isInvisible
        ? 'Enable column to adjust the preference'
        : entity.lockSettingTooltip,
      reorderSettingDisabled:
        entity.reorderSettingDisabled || entity.frozen || isInvisible,
      reorderSettingTooltip: isInvisible
        ? 'Enable column to adjust the preference'
        : entity.reorderSettingTooltip,
      group: setting.locked ? 'lockedColumns' : 'unlockedColumns',
    } as const;
  }, [setting, groupedColumnsProps]);
};
