import { useQuery } from '@tanstack/react-query';

import { apiClient, QueryKeys } from 'core/react-query';

type UseReportCapabilitiesQueryProps = {
  clusterId: string;
  startTime: string;
  endTime: string;
  enabled?: boolean;
  refetchInterval?: number | false;
};

export const useReportCapabilitiesQuery = ({
  clusterId,
  startTime,
  endTime,
  refetchInterval,
  enabled = true,
}: UseReportCapabilitiesQueryProps) => {
  return useQuery({
    queryKey: [QueryKeys.REPORTING_CAPABILITIES, startTime, endTime, clusterId],
    queryFn: async () => {
      const { data } = await apiClient.costReport.getReportCapabilities({
        clusterId,
        startTime,
        endTime,
      });

      return data;
    },
    enabled,
    refetchInterval,
  });
};
