import { Typography } from '@mui/material';
import round from 'lodash/round';

import { DataPopper, DataPopperRow } from '@cast/design-system';

type Props = {
  title: string;
  configurationSavings: number;
  workloadRightsizing: number;
};

export const SavingsTooltip = ({
  title,
  configurationSavings,
  workloadRightsizing,
}: Props) => {
  const totalSavings = configurationSavings + workloadRightsizing;

  return (
    <DataPopper sx={{ width: 210 }}>
      <DataPopperRow
        size="small"
        left={
          <Typography variant="L10M" color="grey.700" noWrap>
            {title}
          </Typography>
        }
        right={
          <Typography variant="P10M">
            {' '}
            {round(totalSavings, 2) + '%'}
          </Typography>
        }
        dividerBottom
      />
      <DataPopperRow
        size="small"
        indicator="green.500"
        left={
          <Typography variant="P10R" noWrap>
            Configuration savings
          </Typography>
        }
        right={
          <Typography variant="P10R">
            {round(configurationSavings, 2) + '%'}
          </Typography>
        }
      />
      <DataPopperRow
        size="small"
        indicator="aqua.800"
        left={
          <Typography variant="P10R" noWrap>
            Workload rightsizing
          </Typography>
        }
        right={
          <Typography variant="P10R">
            {round(workloadRightsizing, 2) + '%'}
          </Typography>
        }
      />
    </DataPopper>
  );
};
