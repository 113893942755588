import { forwardRef, useContext, useMemo, useRef, useState } from 'react';

import { Box, Popover, PopoverProps, SxProps } from '@mui/material';
import range from 'lodash/range';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { Field, FieldRenderProps, useField } from 'react-final-form';

import {
  ChipInput,
  ChipInputForwardRef,
  Icons,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  mergeSx,
  useDropdownWithAutocomplete,
  DropdownPopper,
  DropdownRoot,
  DropdownContext,
  Skeleton,
} from '@cast/design-system';
import { BestPracticeCheckAffectedResource } from '@cast/types';

import { OptionalTag } from './OptionalTag';

type Props = {
  index: number;
  sx?: SxProps;
  resources: BestPracticeCheckAffectedResource[];
  isLoading: boolean;
};

const PrefixPopover = forwardRef<
  HTMLDivElement,
  PopoverProps & { dataCount: number; isLoading: boolean }
>(({ dataCount, children, isLoading, ...rest }, ref) => {
  const { popupState } = useContext(DropdownContext);

  const getContent = () => {
    if (isLoading) {
      return (
        <List sx={{ width: popupState.anchorEl?.offsetWidth }}>
          {range(0, 5).map((i) => (
            <ListItem key={i}>
              <Skeleton />
            </ListItem>
          ))}
        </List>
      );
    }
    if (dataCount === 0) {
      return (
        <List sx={{ width: popupState.anchorEl?.offsetWidth }}>
          <ListItem>
            <ListItemText>No items found</ListItemText>
          </ListItem>
        </List>
      );
    }
    return children;
  };

  return (
    <Popover {...rest} ref={ref}>
      {getContent()}
    </Popover>
  );
});

PrefixPopover.displayName = 'PrefixPopover';

const PrefixInputInner = ({
  index,
  sx,
  resources,
  input,
  isLoading,
}: Props & FieldRenderProps<string[]>) => {
  const {
    input: { value: selectedClusterIds },
  } = useField(`groups[${index}].clusters`);

  const chipInputRef = useRef<ChipInputForwardRef | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const [inputValue, setInputValue] = useState('');
  const chips = useMemo(() => input.value || [], [input.value]);
  const options = useMemo(
    () =>
      resources.filter(
        (resource) =>
          resource.name &&
          !chips.includes(resource.name) &&
          resource.name.startsWith(inputValue)
      ),
    [chips, inputValue, resources]
  );

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'dropdown',
    disableAutoFocus: true,
  });
  const autocomplete = useDropdownWithAutocomplete({
    dropdownProps: {
      options,
      optionValue: (o) => o?.name,
      optionKey: (o) => `${o.name}+${o.kind}+${o.namespace}+${o.clusterId}`,
      optionLabel: (o) => o.name ?? '',
    },
    autocompleteOverrides: {
      onChange: (option: any) => {
        input.onChange([...chips, option.name]);
        chipInputRef.current?.clearInput();
        setInputValue('');
      },
      open: popupState.isOpen && !!options.length,
      hideSearch: true,
    },
  });
  return (
    <DropdownContext.Provider
      value={{
        popupState,
        autocomplete,
        dropdownProps: { sx: { pb: 3 } },
      }}
    >
      <DropdownRoot>
        <ChipInput
          testId={`prefix-input-${index}`}
          ref={chipInputRef}
          label={
            <Box display="flex">
              Prefix
              <Tooltip
                title="To except single resource, enter full name of the resource"
                placement="top"
                arrow
              >
                <Box mx={4}>
                  <Icons.Info />
                </Box>
              </Tooltip>
              <OptionalTag />
            </Box>
          }
          sx={mergeSx(sx, {
            '.DsChipInput-Chip': {
              backgroundColor: 'grey.600',
            },
          })}
          separators={['Enter', ',']}
          onSeparation={(value) => {
            if (Array.isArray(value)) {
              input.onChange([...chips, ...value.filter(Boolean)]);
              setInputValue('');
            } else if (value) {
              input.onChange([...chips, value]);
              setInputValue('');
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          inputProps={{
            onChange: (e: any) => setInputValue(e.target.value),
          }}
          placeholder="Enter text, comma separated"
          chips={chips.map((value) => ({
            title: value,
            onDestroy: (e) => {
              input.onChange([...chips.filter((v) => v !== value)]);
              // Prevent dropdown from opening
              e.stopPropagation();
            },
          }))}
          onClear={() => {
            input.onChange([]);
            setInputValue('');
          }}
          onBlur={(e) => {
            if (
              !e.target.value ||
              popoverRef.current?.contains(e.relatedTarget)
            ) {
              return;
            }
            // Preventing misunderstanding when user forgets to press Enter
            input.onChange([...chips, e.target.value]);
            chipInputRef.current?.clearInput();
            popupState.close();
          }}
          disabled={!selectedClusterIds?.length}
          multiline
          startIcon={false}
        />
      </DropdownRoot>
      <DropdownPopper
        PopoverComponent={(props) => (
          <PrefixPopover
            {...props}
            dataCount={options.length}
            isLoading={isLoading}
            ref={popoverRef}
          />
        )}
      />
    </DropdownContext.Provider>
  );
};

export const PrefixInput = ({ index, ...rest }: Props) => {
  return (
    <Field
      name={`groups[${index}].names`}
      render={(props) => (
        <PrefixInputInner {...rest} {...props} index={index} />
      )}
    />
  );
};
