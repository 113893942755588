import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';

type Props = Omit<BoxProps, 'onScroll'> & {
  setIsScrolledContent: (isScrolled: boolean) => void;
  setIsScrolledToBottom: (isScrolledToBottom: boolean) => void;
};

export const DrawerScroller = ({
  children,
  setIsScrolledContent,
  setIsScrolledToBottom,
  className,
  ...props
}: Props) => {
  return (
    <Box
      overflow="auto"
      className={clsx('Drawer-scroller', className)}
      {...props}
      onScroll={({
        currentTarget: { scrollTop, scrollHeight, clientHeight },
      }) => {
        const isScrolled = scrollTop > 0;
        const isScrolledToBottom = scrollTop === scrollHeight - clientHeight;
        setIsScrolledContent(isScrolled);
        setIsScrolledToBottom(isScrolledToBottom);
      }}
    >
      {children}
    </Box>
  );
};
