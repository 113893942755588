import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UpdateWebhook } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useUpdateWebhookMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, webhook }: { id: string; webhook: UpdateWebhook }) => {
      return apiClient.notifications.updateWebhook({ id, config: webhook });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.WEBHOOKS] });
      notify.success('Webhook updated successfully');
      onSuccess?.();
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
