import { ReactNode } from 'react';

import { SxProps } from '@mui/system';

import {
  Autocomplete,
  DropdownProps,
  Select,
  SelectProps,
} from '@cast/design-system';

import { Criterion, useSearchCriterion } from 'components/search';

type OptionItem<T = string> = {
  title: string;
  value: T | T[];
};

type Props<T = string> = {
  criterion?: Criterion | string;
  value?: T | undefined;
  startAdornment?: ReactNode;
  options: Array<OptionItem | string>;
  sx?: SxProps;
  inputSx?: SxProps;
  renderOption?: DropdownProps<T>['renderOption'];
  analyticsHandler?: (value: T | T[]) => void;
  testId?: string;
  SelectProps?: SelectProps<OptionItem<T>, T>;
  multiple?: boolean;
  autocomplete?: boolean;
};

export const FilterSelect = <T extends string>({
  criterion: _criterion,
  options,
  startAdornment,
  sx,
  inputSx,
  renderOption,
  analyticsHandler,
  testId,
  SelectProps,
  multiple,
  autocomplete,
}: Props<T>) => {
  const Dropdown = autocomplete ? Autocomplete : Select;
  const criterion = useSearchCriterion(_criterion);
  multiple = SelectProps?.multiple ?? multiple;
  const normalizedOptions = options.map((opt) =>
    typeof opt === 'string' ? { title: opt, value: opt } : opt
  );

  const opts = multiple
    ? normalizedOptions
    : [{ title: 'All', value: 'All' }, ...normalizedOptions];

  const val = (() => {
    if (multiple) {
      if (!criterion?.value?.includes) {
        return [];
      }
      return opts.filter(({ value }) => criterion.value.includes(value));
    }
    return (
      (criterion?.value && opts.find((opt) => opt.value === criterion.value)) ||
      opts[0]
    );
  })();

  return (
    <Dropdown<OptionItem<T>, T>
      sx={sx}
      inputProps={{ sx: inputSx }}
      value={val}
      onChange={(val: any) => {
        const value = Array.isArray(val)
          ? val.map(({ value }) => value)
          : val.value;
        if (Array.isArray(value)) {
          criterion.setValue?.(value);
          analyticsHandler?.(value);
        } else {
          if (value === 'All') {
            criterion?.clear?.();
          } else {
            criterion?.setValue?.(value);
          }
          analyticsHandler?.(value);
        }
      }}
      isOptionEqualToValue={(opt, val: any) => {
        return opt.value === val.value;
      }}
      startAdornment={startAdornment}
      optionLabel="title"
      options={opts}
      renderOption={renderOption as any}
      testId={testId}
      {...(SelectProps as any)}
      multiple={multiple}
      checkable={multiple}
    />
  );
};
