import { useCallback } from 'react';

import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  SecurityInsightsContainerImage,
  SecurityInsightsImageDetailsResponse,
} from '@cast/types';

import { QueryKeyFactory, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { useChangeImageExceptionsMutation } from 'hooks/mutations/security-insights';

import { getImageName, getImageTagId } from '../../../utils';

type Props = {
  images: SecurityInsightsContainerImage[];
  onCreated?: () => void;
};

export const useCreateException = ({ images, onCreated }: Props) => {
  const queryClient = useQueryClient();
  const { mutate } = useChangeImageExceptionsMutation(() => {
    notify.success(
      <Typography variant="P14B" color="green.200">
        {images.length === 1
          ? 'Repository was excepted!'
          : `${images.length} repositories were excepted!`}
      </Typography>
    );
    images.forEach((image) => {
      queryClient.setQueryData(
        QueryKeyFactory.securityImageDetails({ tagId: getImageTagId(image) }),
        (
          oldImage: SecurityInsightsImageDetailsResponse
        ): SecurityInsightsImageDetailsResponse => {
          return {
            ...oldImage,
            exceptedAt: new Date().toISOString(),
          };
        }
      );
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.securityImageDetails({
          tagId: getImageTagId(image),
        }),
      });
    });
    queryClient.resetQueries({
      queryKey: [QueryKeys.SECURITY_CONTAINER_IMAGES],
    });
  });

  const createException = useCallback(
    (reason: string) => {
      mutate(
        {
          added:
            images.map((image) => ({
              tagName: getImageName(image),
            })) || [],
          deleted: [],
          reason: reason || '',
        },
        { onSuccess: onCreated }
      );
    },
    [images, mutate, onCreated]
  );

  return createException;
};
