import Box, { BoxProps } from '@mui/material/Box';

type Props = {
  countryCode?: string;
} & BoxProps;

export const CountryIcon = ({
  countryCode,
  width = 21,
  height = 15,
  ...boxProps
}: Props) => {
  return countryCode ? (
    <Box
      component="img"
      width={width}
      height={height}
      src={`https://flagcdn.com/${countryCode.toLowerCase()}.svg`}
      loading="lazy"
      {...boxProps}
    />
  ) : (
    <Box
      width={21}
      height={15}
      border={({ palette }) => `1px solid ${palette.grey[200]}`}
      bgcolor="grey.50"
      borderRadius="2px"
      {...boxProps}
    />
  );
};
