import { useInfiniteQuery } from '@tanstack/react-query';

import { SecurityInsightsCheckResourcesRequest } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

export type UseBestPracticeCheckResourcesQueryArgs = {
  filters: SecurityInsightsCheckResourcesRequest;
  enabled?: boolean;
};

export const useBestPracticeCheckResourcesQuery = ({
  filters,
  enabled = true,
}: UseBestPracticeCheckResourcesQueryArgs) => {
  const {
    isLoading,
    data,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [
      QueryKeys.SECURITY_BEST_PRACTICE_CHECK_RESOURCES,
      filters.ruleId,
      filters.body,
      filters.body.page?.limit,
    ],
    queryFn: async ({ pageParam }) => {
      const { data } =
        await apiClient.securityInsights.getBestPracticeCheckResources({
          ruleId: filters.ruleId,
          body: {
            ...filters.body,
            page: { cursor: pageParam, limit: filters.body.page?.limit },
          },
        });
      return data;
    },
    initialPageParam: '',
    getNextPageParam: (lastPage) =>
      lastPage.nextCursor && lastPage.count?.toString() !== lastPage.nextCursor
        ? lastPage.nextCursor
        : undefined,
    enabled,
  });

  return {
    isLoading,
    data: {
      resources: data?.pages.flatMap((p) => p.resources),
      totalCount: data?.pages?.[0].count,
    },
    error,
    refetch,
    fetchNextPage,
    hasNextPage: !!hasNextPage && !isFetching,
    isFetching,
  };
};
