import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import get from 'lodash/get';

const fromThemeOrDefault = (theme: any, prop?: string | number | null) =>
  prop && get(theme, prop, prop);

export const getThemeColor = (theme: Theme, path?: string | number) =>
  fromThemeOrDefault(theme.palette, path);

export const useThemeValue = (prefix: keyof Theme, path?: string) => {
  const theme = useTheme();
  if (!path) {
    return path;
  }
  return fromThemeOrDefault(theme[prefix], path);
};

export const useThemeColor = (color?: string) => {
  return useThemeValue('palette', color);
};

export const useThemeColors = (...colors: (string | undefined | null)[]) => {
  const theme = useTheme();
  return colors.map((color) => fromThemeOrDefault(theme.palette, color));
};
