import { TypographyOptions } from '@mui/material/styles/createTypography';

const headingFont = 'Bai Jamjuree, sans-serif';
const bodyFont = 'Poppins, sans-serif';
const codeFont = 'Space Mono, monospace';

const fontWeightHeading = 700;
const fontWeightBold = 600;
const fontWeightMedium = 500;
const fontWeightRegular = 400;

export const fonts = { body: bodyFont, heading: headingFont, code: codeFont };

export const typographyVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',

  A1: 'strong',
  A2: 'strong',

  P20B: 'p',
  P18B: 'p',
  P16B: 'p',
  P14B: 'p',
  P12B: 'p',
  P10B: 'p',

  P20M: 'p',
  P18M: 'p',
  P16M: 'p',
  P14M: 'p',
  P12M: 'p',
  P10M: 'p',

  P20R: 'p',
  P18R: 'p',
  P16R: 'p',
  P14R: 'p',
  P12R: 'p',
  P10R: 'p',

  L14B: 'p',
  L12B: 'p',
  L10B: 'p',

  L14M: 'p',
  L12M: 'p',
  L10M: 'p',

  L14R: 'p',
  L12R: 'p',
  L10R: 'p',

  C16: 'pre',
  C14: 'pre',
  C12: 'pre',
};

export const typographyTheme: TypographyOptions = {
  fontSize: 12,
  fontFamily: bodyFont,
  fontWeightBold,
  fontWeightMedium,
  fontWeightRegular,
  h1: {
    fontFamily: headingFont,
    fontWeight: fontWeightHeading,
    fontSize: '40px',
    lineHeight: '52px',
  },
  h2: {
    fontFamily: headingFont,
    fontWeight: fontWeightHeading,
    fontSize: '36px',
    lineHeight: '44px',
  },
  h3: {
    fontFamily: headingFont,
    fontWeight: fontWeightHeading,
    fontSize: '28px',
    lineHeight: '36px',
  },
  h4: {
    fontFamily: headingFont,
    fontWeight: fontWeightHeading,
    fontSize: '24px',
    lineHeight: '32px',
  },
  h5: {
    fontFamily: headingFont,
    fontWeight: fontWeightHeading,
    fontSize: '20px',
    lineHeight: '28px',
  },
  h6: {
    fontFamily: headingFont,
    fontWeight: fontWeightHeading,
    fontSize: '16px',
    lineHeight: '24px',
  },

  // Additional heading variants
  A1: {
    fontFamily: headingFont,
    fontWeight: fontWeightHeading,
    fontSize: '14px',
    lineHeight: '22px',
  },
  A2: {
    fontFamily: headingFont,
    fontWeight: fontWeightHeading,
    fontSize: '12px',
    lineHeight: '18px',
  },

  // Body Bold
  P20B: {
    fontFamily: bodyFont,
    fontWeight: fontWeightBold,
    fontSize: '20px',
    lineHeight: '28px',
  },
  P18B: {
    fontFamily: bodyFont,
    fontWeight: fontWeightBold,
    fontSize: '18px',
    lineHeight: '26px',
  },
  P16B: {
    fontFamily: bodyFont,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
  },
  P14B: {
    fontFamily: bodyFont,
    fontWeight: fontWeightBold,
    fontSize: '14px',
    lineHeight: '22px',
  },
  P12B: {
    fontFamily: bodyFont,
    fontWeight: fontWeightBold,
    fontSize: '12px',
    lineHeight: '18px',
  },
  P10B: {
    fontFamily: bodyFont,
    fontWeight: fontWeightBold,
    fontSize: '10px',
    lineHeight: '16px',
  },

  // Body Medium
  P20M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '20px',
    lineHeight: '28px',
  },
  P18M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '18px',
    lineHeight: '26px',
  },
  P16M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '16px',
    lineHeight: '24px',
  },
  P14M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '14px',
    lineHeight: '22px',
  },
  P12M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '12px',
    lineHeight: '18px',
  },
  P10M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '10px',
    lineHeight: '16px',
  },

  // Body regular
  P20R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '20px',
    lineHeight: '28px',
  },
  P18R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '18px',
    lineHeight: '26px',
  },
  P16R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '16px',
    lineHeight: '24px',
  },
  P14R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '14px',
    lineHeight: '22px',
  },
  P12R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '12px',
    lineHeight: '18px',
  },
  P10R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '10px',
    lineHeight: '16px',
  },

  // Labels bold
  L14B: {
    fontFamily: bodyFont,
    fontWeight: fontWeightBold,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  L12B: {
    fontFamily: bodyFont,
    fontWeight: fontWeightBold,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  L10B: {
    fontFamily: bodyFont,
    fontWeight: fontWeightBold,
    fontSize: '10px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },

  // Labels medium
  L14M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  L12M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  L10M: {
    fontFamily: bodyFont,
    fontWeight: fontWeightMedium,
    fontSize: '10px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },
  // Labels regular
  L14R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '14px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  L12R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  L10R: {
    fontFamily: bodyFont,
    fontWeight: fontWeightRegular,
    fontSize: '10px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },

  // Code snippets
  C16: {
    fontFamily: codeFont,
    fontWeight: fontWeightRegular,
    fontSize: '16px',
    lineHeight: '24px',
  },
  C14: {
    fontFamily: codeFont,
    fontWeight: fontWeightRegular,
    fontSize: '14px',
    lineHeight: '20px',
  },
  C12: {
    fontFamily: codeFont,
    fontWeight: fontWeightRegular,
    fontSize: '12px',
    lineHeight: '18px',
  },
};
