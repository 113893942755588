import { createContext, MutableRefObject } from 'react';

import { useSettingsDrawerState } from './_hooks/useSettingsDrawerState';

export const SettingsDrawerContext = createContext<
  ReturnType<typeof useSettingsDrawerState> & {
    lockedGroupRef: MutableRefObject<HTMLElement | null>;
    unlockedGroupRef: MutableRefObject<HTMLElement | null>;
  }
>({} as never);
