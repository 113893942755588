import partition from 'lodash/partition';

const compareFn = (a: any, b: any) => {
  const isNumberA = !isNaN(a);
  const isNumberB = !isNaN(b);

  if (isNumberA && isNumberB) {
    return parseInt(a) - parseInt(b);
  }

  if (isNumberA || isNumberB) {
    return isNumberA ? -1 : 1;
  }

  return a.localeCompare(b);
};

export const dropdownOptionsSort = (arr: any[], selectedValues?: any[]) => {
  if (!selectedValues) {
    return [...arr].sort(compareFn);
  }
  const [selected, other] = partition(arr, (val) =>
    selectedValues.includes(val)
  );
  return [...selected, ...other.sort(compareFn)];
};
