import { useMemo } from 'react';

const Loader = Symbol('Loader');

export const useLoadingRows = (length = 5, isEnabled?: boolean) => {
  return useMemo(() => {
    if (!isEnabled) {
      return [];
    }
    return [...Array(length)].map(
      () =>
        ({
          id: Math.random(),
          [Loader]: true,
        } as any)
    );
  }, [length, isEnabled]);
};

export const isLoadingRow = (row: any) => row[Loader];
