import { Stack, Typography } from '@mui/material';

import { Column, Icons } from '@cast/design-system';
import { BestPracticeCheckAffectedResource } from '@cast/types';

import { tableCacheKeys } from 'common/tableCacheKeys';
import { NoData, NoResults, SearchableTable } from 'components/search';
import { nonNamespacedLabel } from 'features/organization/security-insights/constants';

export type ResourceTableItem = BestPracticeCheckAffectedResource & {
  providerNamespaceId?: string;
};

export const ResourcesTable = () => {
  return (
    <SearchableTable
      cacheKey={tableCacheKeys.SECURITY.COMPLIANCE_EXCEPTIONS}
      testId="resources-table"
      sizing="m"
      maxRows={10}
      components={{
        noData: <NoData icon={<Icons.MagnifyingGlass />} entity="resource" />,
        noResults: (
          <NoResults icon={<Icons.MagnifyingGlass />} entity="resource" />
        ),
      }}
      rowKey={(resource) => JSON.stringify(resource)}
    >
      <Column
        id="name"
        header="Resource name"
        minWidth={179}
        resizable
        sortable
        cellSx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      />
      <Column id="kind" header="Kind" minWidth={100} sortable />
      <Column
        id="namespace"
        header="Namespace"
        minWidth={150}
        sortable
        renderCell={({ namespace }: ResourceTableItem) =>
          namespace ? namespace : nonNamespacedLabel
        }
      />
      <Column
        id="clusterName"
        header="Cluster"
        minWidth={160}
        sortable
        renderCell={({
          clusterName,
          providerNamespaceId,
        }: ResourceTableItem) => (
          <Stack>
            <Typography variant="P10R">{clusterName}</Typography>
            {providerNamespaceId && (
              <Typography variant="P10R" color="textSecondary" mt={-4}>
                {providerNamespaceId}
              </Typography>
            )}
          </Stack>
        )}
      />
    </SearchableTable>
  );
};
