import { ThemeOptions } from '@mui/material';

import { cssVars } from '../../utils/cssVars';

export const listSubheaderVars = cssVars(
  {
    minHeight: undefined,
    padding: undefined,
  },
  'ds-list'
);

export const listItemVars = cssVars(
  {
    minHeight: undefined,
    padding: undefined,
    adornmentGap: undefined,
    avatarGap: undefined,
    radioGap: undefined,
    color: undefined,
    colorSelected: undefined,
    colorDisabled: undefined,
    background: undefined,
    backgroundHover: undefined,
  },
  'ds-list-item'
);

export const listSearchInput = cssVars(
  {
    adornmentGap: undefined,
    inputGap: undefined,
  },
  'ds-list-search'
);

export const listTheme: ThemeOptions['components'] = {
  DsList: {
    styleOverrides: {
      root: {
        cursor: 'text',
        padding: 0,
        '& .DsListItem-root': {
          margin: '1px 0',
        },
        '&:not(.Ds-List-Nested) .DsListItem-root:first-of-type': {
          marginTop: '0',
        },
        '& .DsListItem-root:last-of-type': {
          marginBottom: '0',
        },
      },
    },
  },
  DsListSubheader: {
    styleOverrides: {
      root: {
        padding: listSubheaderVars.getters.padding(),
        minHeight: listSubheaderVars.getters.minHeight(),
      },
      large: ({ disableGutters }) =>
        listSubheaderVars.setValues({
          minHeight: '40px',
          padding: disableGutters ? '0px' : '17px 12px 11px',
        }),
      medium: ({ disableGutters }) =>
        listSubheaderVars.setValues({
          minHeight: '32px',
          padding: disableGutters ? '0px' : '13px 10px 7px',
        }),
      small: ({ disableGutters }) =>
        listSubheaderVars.setValues({
          minHeight: '28px',
          padding: disableGutters ? '0px' : '11px 8px 5px',
        }),
    },
  },
  DsListItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...listItemVars.setValues({
          color: theme.palette.grey[900],
          background: theme.palette.common.white,
          backgroundHover: theme.palette.grey[100],
        }),

        minHeight: listItemVars.getters.minHeight(),
        padding: listItemVars.getters.padding(),
        color: listItemVars.getters.color(),

        '&:hover': {
          background: 'initial',
        },
        '& .DsListItem-content': {
          flex: '1 1 auto',
        },
        '& .MuiListItemIcon-alignItemsFlexStart': {
          marginTop: 0,
        },

        '& .DsListItem-adornment-start': {
          display: 'inherit',
          marginRight: listItemVars.getters.adornmentGap(),
          color: theme.palette.grey[400],

          '&.DsListItem-adornment-avatar': {
            marginRight: listItemVars.getters.avatarGap(),
          },
          '&.DsListItem-adornment-radio': {
            marginRight: listItemVars.getters.radioGap(),
          },
        },
        '& .DsListItem-adornment-end': {
          display: 'inherit',
          marginLeft: listItemVars.getters.adornmentGap(),
          color: theme.palette.grey[400],

          '&.DsListItem-adornment-avatar': {
            marginLeft: listItemVars.getters.avatarGap(),
          },
          '&.DsListItem-adornment-radio': {
            marginLeft: listItemVars.getters.radioGap(),
          },
        },
      }),
      large: ({ disableGutters, ownerState }) =>
        listItemVars.setValues({
          minHeight: '40px',
          padding: disableGutters
            ? '0px'
            : ownerState.multiline
            ? '12px 16px'
            : `0 ${ownerState.nested ? '24px' : '12px'}`,
          adornmentGap: '8px',
          avatarGap: '12px',
          radioGap: '12px',
        }),
      medium: ({ disableGutters, ownerState }) =>
        listItemVars.setValues({
          minHeight: '32px',
          padding: disableGutters
            ? '0px'
            : ownerState.multiline
            ? '10px 12px'
            : `0 ${ownerState.nested ? '22px' : '10px'}`,
          adornmentGap: '8px',
          avatarGap: '12px',
          radioGap: '12px',
        }),
      small: ({ disableGutters, ownerState }) =>
        listItemVars.setValues({
          minHeight: '28px',
          padding: disableGutters
            ? '0px'
            : ownerState.multiline
            ? '5px 12px'
            : `0 ${ownerState.nested ? '20px' : '8px'}`,
          adornmentGap: '4px',
          avatarGap: '8px',
          radioGap: '8px',
        }),
      hover: {
        '&:hover': {
          background: listItemVars.getters.backgroundHover(),
          cursor: 'pointer',
          userSelect: 'none',
        },
      },
      selected: ({ theme }) => ({
        ...listItemVars.setValues({
          background: theme.palette.blue[50],
          backgroundHover: theme.palette.blue[100],
          color: theme.palette.blue[700],
        }),

        '& .DsListItem-adornment-start,& .DsListItem-adornment-end': {
          color: 'inherit',
        },
      }),

      disabled: ({ theme }) => ({
        ...listItemVars.setValues({
          color: theme.palette.grey[300],
        }),

        '&.Mui-disabled': {
          opacity: 1,

          '&:hover': {
            cursor: 'not-allowed',
            userSelect: 'text',
          },
        },
      }),
    },
  },
  DsListItemText: {
    styleOverrides: {
      root: {
        margin: 0,

        '.Mui-disabled & .MuiListItemText-secondary': {
          color: 'inherit',
        },
      },
    },
  },
  DsListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 'unset',
        color: 'inherit',

        '.Mui-selected &': {
          color: 'inherit',
        },
      },
    },
  },
  DsListItemAvatar: {
    styleOverrides: {
      root: {
        minWidth: 'unset',
        margin: 0,
      },
    },
  },
  DsListSearchInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.grey[400],
        borderBottom: `1px solid ${theme.palette.grey[200]}`,

        '& .DS-Input-startOutsideAdornment': {
          paddingLeft: listSearchInput.getters.adornmentGap(),
        },

        '& .DS-Input-endOutsideAdornment': {
          paddingRight: listSearchInput.getters.adornmentGap(),

          '& .clearInput': {
            cursor: 'pointer',

            '&:hover': {
              color: theme.palette.grey[500],
            },
          },
        },

        '&& .MuiFilledInput-root.MuiInputBase-formControl': {
          padding: '0 8px',
          borderRadius: 0,
          border: 'none',
        },
        '&&& .Mui-focused': {
          boxShadow: 'none',
        },

        '&:hover, &:focus-within': {
          borderBottomColor: theme.palette.grey[300],
        },

        '&:focus-within': {
          '& .DS-Input-startOutsideAdornment': {
            color: theme.palette.blue[500],
          },
        },

        '& .DS-Input-outsideAdornment': {
          background: 'white',

          '.DsInput-disabled': {
            color: theme.palette.grey[300],

            '& .clearInput': {
              cursor: 'initial',
            },
          },
        },
      }),
      large: listSearchInput.setValues({
        adornmentGap: '14px',
      }),
      medium: listSearchInput.setValues({
        adornmentGap: '12px',
      }),
      small: listSearchInput.setValues({
        adornmentGap: '10px',
      }),
    },
  },
};
