export const WEEKDAYS = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN',
] as const;

export const WEEKDAYS_DISPLAY = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
} as const;

export const MEMORY_UNIT_SUFFIXES = [
  'Bytes',
  'KiB',
  'MiB',
  'GiB',
  'TiB',
  'PiB',
  'EiB',
  'ZiB',
  'YiB',
] as const;

export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_DAY = 86_400;

export const AVERAGE_HOURS_IN_MONTH = 730; // All CSP providers use this number in their calculations
export const HOURS_IN_WEEK = 168;
export const AVERAGE_DAYS_IN_MONTH = 30;

export const POLLING_DELAY_MS = 20_000;
