import { ReactNode } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { Tooltip } from '@cast/design-system';
import { K8sProvider } from '@cast/types';

import { LogoFullSvg, LogoSmallSvg } from 'assets/svg';

import { K8sProviderIcon } from './K8sProviderIcon';

type Props = {
  provider?: `${K8sProvider}`;
  castEnabled?: boolean;
  iconSplitter?: ReactNode;
  size?: number;
  spacing?: number;
  variant?: 'small' | 'large';
};

export const K8sProviderIcons = ({
  provider,
  castEnabled = false,
  size = 26,
  iconSplitter,
  variant = 'small',
}: Props) => {
  const { palette } = useTheme();
  const message = `${castEnabled ? 'CAST AI managed ' : ''}${
    provider?.toUpperCase() ?? ''
  } cluster`;

  return (
    <Tooltip title={message}>
      <Box
        display="inline-flex"
        alignItems="center"
        gap={variant === 'small' ? 4 : 6}
      >
        {provider && <K8sProviderIcon provider={provider} size={size} />}

        {castEnabled && (
          <>
            {provider &&
              (iconSplitter || (
                <Typography
                  fontWeight="fontWeightMedium"
                  variant={variant === 'small' ? 'P12R' : 'P16R'}
                  color="textPrimary"
                >
                  +
                </Typography>
              ))}

            {variant === 'small' ? (
              <LogoSmallSvg height={size} color={palette.grey[800]} />
            ) : (
              <LogoFullSvg height={size} color={palette.grey[800]} />
            )}
          </>
        )}
      </Box>
    </Tooltip>
  );
};
