import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useReconcileClusterMutation = (clusterId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => apiClient.clusters.reconcileCluster({ clusterId }),
    onSuccess: () => {
      notify.success('Reconciliation in progress');
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.EXTERNAL_CLUSTER],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.EXTERNAL_CLUSTERS],
      });
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
