import { useState } from 'react';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';

import {
  Column,
  PreviewSnippet,
  Tooltip,
  Icons,
  useDrawer,
  useDrawerInstance,
} from '@cast/design-system';
import { Webhook, WebhookStatus } from '@cast/types';

import { FailedToLoad } from 'components/messages';
import { SearchableTable, useSearchContext } from 'components/search';
import { EllipsisWithTooltip } from 'components/tooltip';
import { notify } from 'core/snackbars';

import { WebhookFormSubdrawer } from './form';
import { WebhookStatusChip } from '../_components';

const TooltipProps = {
  arrow: true,
  PopperProps: {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#082939',
        maxWidth: 420,
      },
    },
  },
};

type WebhookChipProps = { webhook: Webhook };
const WebhookChipWithTooltip = ({ webhook }: WebhookChipProps) => {
  if (webhook.status === WebhookStatus.FAILED) {
    return (
      <Tooltip
        {...TooltipProps}
        title={
          <>
            <Typography variant="P12R">
              Cannot connect due to recurring issue:
            </Typography>
            <PreviewSnippet
              code={webhook.error!}
              errorHighlight
              onCopy={() => notify.success('Copied to clipboard')}
              p="16px"
            />
          </>
        }
        sx={{ '.MuiTooltip-tooltip': { borderRadius: '4px' } }}
      >
        <div>
          <WebhookStatusChip status={webhook.status} noText size="small" />
        </div>
      </Tooltip>
    );
  }
  return <WebhookStatusChip status={webhook.status} noText size="small" />;
};

export const WebhooksList = () => {
  const ctx = useSearchContext();
  const { open } = useDrawer();
  const instance = useDrawerInstance();
  const [activeWebhook, setActiveWebhook] = useState<Webhook>();

  return (
    <SearchableTable
      fillParentHeight
      isFetching={ctx?.isLoading && !!ctx?.data?.length}
      rowKey={(row: Webhook) => row.id}
      onRowClick={(_, { row }) => {
        setActiveWebhook(row);
        open(
          WebhookFormSubdrawer,
          {
            webhook: row,
            onClose: () => {
              setActiveWebhook(undefined);
            },
          },
          { parentId: instance.id }
        );
      }}
      components={{
        failed: (
          <FailedToLoad
            title="Failed to load webhooks"
            refresh={ctx?.refetch}
            sx={{ px: 32, pt: 108 }}
          />
        ),
      }}
      testId="webhooks-table"
    >
      <Column
        id="name"
        header="NAME"
        minWidth={280}
        renderHeader={() => <Typography variant="L10B">Name</Typography>}
        renderCell={(webhook: Webhook) => {
          return (
            <EllipsisWithTooltip
              TypographyProps={{
                variant: 'P12M',
                color: 'blue.500',
                noWrap: true,
              }}
              TooltipProps={TooltipProps}
            >
              {webhook.name}
            </EllipsisWithTooltip>
          );
        }}
        cellWrapperSx={{ pl: 25 }}
        sortable
      />
      <Column
        id="status"
        header="STATUS"
        minWidth={100}
        renderHeader={() => <Typography variant="L10B">Status</Typography>}
        renderCell={(webhook: Webhook) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              pr={8}
            >
              <WebhookChipWithTooltip webhook={webhook} />
              <Icons.CaretLeft
                className={clsx(
                  activeWebhook?.id === webhook.id && 'webhook-active'
                )}
                style={{
                  transition: 'transform 600ms',
                  transform: 'rotate(180deg)',
                  ...(activeWebhook?.id === webhook.id
                    ? { transform: 'rotate(0deg)' }
                    : {}),
                }}
              />
            </Stack>
          );
        }}
        cellWrapperSx={{ pr: 15 }}
      />
    </SearchableTable>
  );
};
