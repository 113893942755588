import { forwardRef, PropsWithChildren } from 'react';

import { Link as RouterLink, LinkProps } from 'react-router-dom';

import { useLocationWithOrgId } from 'hooks/useLocationWithOrgId';

export const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>(
  (
    // eslint-disable-next-line react/prop-types
    { children, to, ...props }: PropsWithChildren<LinkProps>,
    ref
  ) => {
    if (typeof to === 'function') {
      throw new Error('Passing function is not supported');
    }

    const location = useLocationWithOrgId(to);
    const _to = location || to;

    return (
      <RouterLink {...props} to={_to} ref={ref}>
        {children}
      </RouterLink>
    );
  }
);

Link.displayName = 'Link';
