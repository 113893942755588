import { useMemo } from 'react';

import { Box, Stack } from '@mui/material';

import { Button, ButtonProps, Tooltip, Skeleton } from '@cast/design-system';
import {
  SecurityInsightsIntegrationType,
  SecurityInsightsTicket,
} from '@cast/types';

import { ReactComponent as JiraIcon } from 'assets/icons/jira.svg';
import { ExternalLink } from 'components/router';
import { useIntegrationsQuery } from 'hooks/queries/security-insights';

import {
  useCreateJiraTicketDrawer,
  CreateJiraTicketDrawerProps,
} from '../../_hooks/useCreateJiraTicketDrawer';
import { getOrgSecurityRoutePath } from '../../navigation';

export type JiraButtonProps = {
  ticket?: SecurityInsightsTicket;
  testId?: string;
  buttonProps?: Omit<ButtonProps, 'testId' | 'loading'>;
  isLoading?: boolean;
} & CreateJiraTicketDrawerProps;

export const JiraButton = ({
  ticket,
  itemType,
  itemName,
  itemId,
  additionalData,
  buttonProps,
  isLoading: isLoadingProp,
  testId = 'jira-button',
}: JiraButtonProps) => {
  const { integrations, isLoading } = useIntegrationsQuery();
  const jiraIntegration = useMemo(
    () =>
      integrations?.find(
        (integration) =>
          integration.type === SecurityInsightsIntegrationType.JIRA
      ),
    [integrations]
  );

  const { openCreateJiraTicketDrawer } = useCreateJiraTicketDrawer();

  if (isLoading || isLoadingProp) {
    return (
      <Skeleton height={32} width={139} data-testid={`${testId}-skeleton`} />
    );
  }

  if (!jiraIntegration) {
    return (
      <Tooltip
        title={
          <Stack direction="row">
            Integrate Jira in
            <ExternalLink
              link={getOrgSecurityRoutePath('settings')}
              sx={{ ml: 4 }}
            >
              Settings page
            </ExternalLink>
          </Stack>
        }
      >
        <div>
          <Button
            variant="ghost"
            size="small"
            startIcon={<JiraIcon />}
            disabled
            testId={testId}
            {...buttonProps}
          >
            Integrate Jira
          </Button>
        </div>
      </Tooltip>
    );
  }

  if (ticket) {
    return (
      <Tooltip title={`View ${ticket.name}`}>
        <Button
          component="a"
          href={ticket.url}
          target="_blank"
          variant="ghost"
          size="small"
          startIcon={<JiraIcon />}
          testId={testId}
          {...buttonProps}
        >
          <Box
            sx={{
              maxWidth: '20ch',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            View {ticket.name}
          </Box>
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      variant="ghost"
      size="small"
      startIcon={<JiraIcon />}
      onClick={() => {
        openCreateJiraTicketDrawer({
          itemType,
          itemName,
          itemId,
          additionalData,
        });
      }}
      testId={testId}
      {...buttonProps}
    >
      Create ticket
    </Button>
  );
};
