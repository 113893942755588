import { Icons } from '@cast/design-system';

import { ChartType, ChartTypeToggleOptions } from 'types/charts';

export const chartTypeToggleOptions: ChartTypeToggleOptions = [
  { value: ChartType.LINE, icon: <Icons.ChartLine /> },
  { value: ChartType.AREA, icon: <Icons.ChartLine /> },
  { value: ChartType.BAR, icon: <Icons.ChartBar /> },
];

export const DEFAULT_BAR_SIZE = 28;
