import { forwardRef } from 'react';

import { Box, Typography } from '@mui/material';

import { Drawer, DrawerProps } from '@cast/design-system';

import {
  CloseDrawerButton,
  DestroyDrawerFormGuard,
  DrawerContent,
} from 'components/drawer/ds';

import { TicketForm } from './_components/TicketForm';
import { ticketTypeToStringMap } from './constants';
import { CreateJiraTicketDrawerProps } from './types';

type Props = CreateJiraTicketDrawerProps & Omit<DrawerProps, 'testId'>;

export const CreateJiraTicketDrawer = forwardRef<HTMLDivElement, Props>(
  ({ itemType, itemName, itemId, additionalData, ...drawerProps }, ref) => {
    return (
      <Drawer
        ref={ref}
        size="small"
        {...drawerProps}
        testId="create-jira-ticket-drawer"
      >
        <CloseDrawerButton />
        <DrawerContent sx={{ pt: 62, mb: 24 }}>
          <Typography variant="h5">Create Jira ticket</Typography>
        </DrawerContent>
        <Box
          padding="16px 32px"
          bgcolor="grey.50"
          borderTop="grey.200"
          borderBottom="grey.200"
          mb={24}
        >
          <Typography variant="P14B">
            Selected{' '}
            {ticketTypeToStringMap[itemType]?.toLocaleLowerCase() || 'item'}:
          </Typography>
          <Typography variant="P12M">{itemName}</Typography>
        </Box>

        <DrawerContent>
          <TicketForm
            itemType={itemType}
            itemName={itemName}
            itemId={itemId}
            additionalData={additionalData}
          />
        </DrawerContent>
        <DestroyDrawerFormGuard />
      </Drawer>
    );
  }
);

CreateJiraTicketDrawer.displayName = 'CreateJiraTicketDrawer';
