import { useCallback } from 'react';

import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  SecurityInsightsContainerImage,
  SecurityInsightsImageDetailsResponse,
} from '@cast/types';

import { QueryKeyFactory, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { useChangeImageExceptionsMutation } from 'hooks/mutations/security-insights';

import { getImageName, getImageTagId } from '../../../utils';

type Props = {
  images: SecurityInsightsContainerImage[];
  onCancelled?: () => void;
};

export const useCancelException = ({ images, onCancelled }: Props) => {
  const queryClient = useQueryClient();
  const { mutate } = useChangeImageExceptionsMutation(() => {
    notify.success(
      <>
        {images.length === 1 ? (
          <>
            <Typography variant="P14B" color="blue.300">
              Exception was cancelled!
            </Typography>
            Repository was returned to Active repositories list
          </>
        ) : (
          <>
            <Typography variant="P14B" color="blue.300">
              Exceptions were cancelled!
            </Typography>
            {`${images.length} repositories were returned to Active repositories list`}
          </>
        )}
      </>
    );
    images.forEach((image) => {
      queryClient.setQueryData(
        QueryKeyFactory.securityImageDetails({ tagId: getImageTagId(image) }),
        (
          oldImage: SecurityInsightsImageDetailsResponse
        ): SecurityInsightsImageDetailsResponse => {
          return {
            ...oldImage,
            exceptedAt: undefined,
            exceptedReason: '',
          };
        }
      );
      queryClient.invalidateQueries({
        queryKey: QueryKeyFactory.securityImageDetails({
          tagId: getImageTagId(image),
        }),
      });
    });
    queryClient.resetQueries({
      queryKey: [QueryKeys.SECURITY_CONTAINER_IMAGES],
    });
  });

  const cancelException = useCallback(() => {
    mutate(
      {
        added: [],
        deleted:
          images.map((image) => ({
            tagName: getImageName(image),
          })) || [],
      },
      { onSuccess: onCancelled }
    );
  }, [images, mutate, onCancelled]);

  return cancelException;
};
