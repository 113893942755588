import { ReactNode, useContext } from 'react';

import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';

import { Skeleton } from '@cast/design-system';

import { useIsLoading } from 'core/loading';

import { DrawerContext } from './DrawerContext';
import { CopyToClipboard } from '../clickable-actions';

const TextSkeleton = () => <Skeleton width={150} height={22} variant="text" />;

type DetailProps = Omit<BoxProps, 'title'> & {
  title: ReactNode;
  detail: ReactNode;
  type?: 'column' | 'row';
  className?: string;
  titleProps?: TypographyProps;
  detailProps?: TypographyProps;
  skeleton?: ReactNode;
  suffix?: string;
  showCopy?: boolean;
  isLoading?: boolean;
  testId?: string;
};

export const Detail = ({
  title,
  detail = '-',
  type = 'row',
  className,
  titleProps = {},
  detailProps = {},
  sx,
  skeleton,
  suffix,
  showCopy,
  isLoading,
  testId,
}: DetailProps) => {
  const coreIsLoading = useIsLoading();
  // TODO: delete after https://castai.atlassian.net/browse/UI-2180
  const drawerIsLoading = useContext(DrawerContext)?.isLoading;
  isLoading =
    isLoading !== undefined ? isLoading : coreIsLoading || drawerIsLoading;

  const props: BoxProps =
    type === 'row'
      ? { justifyContent: 'space-between', alignItems: 'center' }
      : { flexDirection: 'column', alignItems: 'flex-start', minWidth: 0 };
  return (
    <Box
      display="flex"
      {...props}
      className={clsx('Detail-root', className)}
      sx={sx}
      data-testid={testId}
    >
      <Typography variant="L12R" color="textSecondary" {...titleProps}>
        {title}
      </Typography>

      {isLoading ? (
        skeleton || <TextSkeleton />
      ) : typeof detail === 'string' ? (
        <Typography variant="P14R" {...detailProps}>
          {detail}{' '}
          {suffix && (
            <Typography variant="P14R" color="grey.500" component="span">
              {suffix}{' '}
            </Typography>
          )}
          {showCopy && !!detail && detail !== '-' && (
            <CopyToClipboard
              text={detail}
              iconSize={16}
              sx={{ transform: 'translateY(3px)' }}
            />
          )}
        </Typography>
      ) : (
        detail
      )}
    </Box>
  );
};
