import { TreeUiConfig, TreeUiUtils } from './types';

export const getGroupedColumn = <
  C extends { id: string },
  G extends { id: string; columns?: Array<C> }
>(
  id: string,
  columns: (C | G)[]
): {
  column?: Exclude<C | G, { columns: Array<C> }>;
  group?: Extract<C | G, { columns: Array<C> }>;
} => {
  for (const column of columns) {
    if (column.id === id) {
      if ((column as G).columns) {
        return { group: column as any };
      }
      return { column: column as any };
    }
    const columns = (column as G).columns;
    if (columns) {
      for (const childColumn of columns) {
        if (childColumn.id === id) {
          return { column: childColumn as any, group: column as any };
        }
      }
    }
  }
  return { group: undefined, column: undefined };
};

export const makeTreeUiUtils = ({
  iconSize,
  leftExpanderPadding,
  distanceFromLineToIcon,
  firstCellRightPadding,
}: TreeUiConfig): TreeUiUtils => {
  const expanderColumnWidth = iconSize + leftExpanderPadding;

  const getExpanderIconIndentation = (level: number) =>
    (level + 1) * expanderColumnWidth - iconSize;
  const getVerticalLineIndentation = (level: number) =>
    getExpanderIconIndentation(level) + iconSize / 2;
  const getHorizontalLineIndentation = getVerticalLineIndentation;
  const getHorizontalLineToExpanderWidth = (level: number) =>
    getHorizontalLineIndentation(level) -
    getHorizontalLineIndentation(level - 1) -
    iconSize / 2 -
    distanceFromLineToIcon;
  const getHorizontalLineToCellWidth = (level: number) =>
    getHorizontalLineIndentation(level) -
    getHorizontalLineIndentation(level - 1);
  const getRowIndentation = (level: number) =>
    level * expanderColumnWidth + firstCellRightPadding;

  return {
    getExpanderIconIndentation,
    getVerticalLineIndentation,
    getHorizontalLineIndentation,
    getHorizontalLineToExpanderWidth,
    getHorizontalLineToCellWidth,
    getRowIndentation,
  };
};
