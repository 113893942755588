import { KeyVal } from './types';

export const apiObjectToKeyVal = (obj?: Record<string, any>) => {
  const entries = Object.entries(obj || {}).map(([key, value]) => ({
    key,
    value,
  })) as KeyVal[];
  if (!entries.length) {
    return [{ key: '', value: '' }];
  }
  return entries;
};

export const reduceKeyVal = (keyVals?: KeyVal[]) => {
  const result = keyVals?.reduce(
    (acc: Record<string, string>, { key, value }) => {
      if (key) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );
  if (Object.keys(result || {}).length) {
    return result;
  }
};
