import { ReactNode, useState } from 'react';

import { Divider, Stack, SxProps, Typography } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { TZDate } from 'react-day-picker';

import { PeriodComparisonPickerBody } from './PeriodComparisonPickerBody';
import { PeriodComparisonPickerFooter } from './PeriodComparisonPickerFooter';
import { PeriodPresetsList } from './PeriodPresetsList';
import { CalendarBlank } from '../../../icons';
import {
  DatePickerInput,
  DatePickerInputProps,
} from '../../components/DatePickerInput';
import { DatePickerPopper } from '../../components/DatePickerPopper';
import { defaultDateFormat, defaultDateTimeFormat } from '../../constants';
import DatePickerProvider from '../../DatePickerProvider';
import {
  DatePeriod,
  DatePeriodInTimestamp,
  InfiniteDayPickerRef,
  MarkedDate,
  PeriodCondition,
  PeriodPreset,
} from '../../types';
import {
  utcToDate,
  convertRange,
  rangeToUtc,
  dayjsToTZDate,
  periodToString,
} from '../../utils';

dayjs.extend(utc);
dayjs.extend(timezone);

type PeriodProps = {
  minDate?: PeriodCondition;
  maxDate?: PeriodCondition;
  endMonth?: PeriodCondition;
  startMonth?: PeriodCondition;
};

export type PeriodComparisonPickerProps = {
  initialStartDates?: { periodA?: string; periodB?: string };
  onChange?: (
    dates: { periodA: DatePeriodInTimestamp; periodB: DatePeriodInTimestamp },
    timezone: string,
    preset: number
  ) => void;
  initialPreset?: number;
  format?: string;
  triggerInputProps?: DatePickerInputProps['inputProps'];
  periodPresets?: PeriodPreset[];
  hideRangeInputs?: boolean;
  allowTimeInput?: boolean;
  hidePresets?: boolean;
  maxNumberOfDays?: number;
  hideTimezonePicker?: boolean;
  initialTimeZone?: string;
  markedDates?: MarkedDate[];
  hint?:
    | ReactNode
    | ((args: {
        periodA: DatePeriodInTimestamp;
        periodB: DatePeriodInTimestamp;
        timezone: string;
        pickerARef: InfiniteDayPickerRef;
        pickerBRef: InfiniteDayPickerRef;
      }) => ReactNode);
  periodAProps?: PeriodProps;
  periodBProps?: PeriodProps;
  inputValue?: (
    periodA: DatePeriodInTimestamp,
    periodB: DatePeriodInTimestamp,
    timezone: string
  ) => string;
  sx?: SxProps;
};

const buildInitialRange = (
  initialStartDate: string | undefined,
  activePeriod: number | undefined,
  timezone: string
) => {
  if (!activePeriod || !initialStartDate) {
    return {
      from: initialStartDate
        ? utcToDate(initialStartDate, timezone)
        : undefined,
      to: undefined,
    };
  }

  return {
    from: utcToDate(initialStartDate, timezone),
    to: dayjsToTZDate(
      dayjs
        .utc(initialStartDate)
        .add(activePeriod, 'minutes')
        .subtract(1, 'ms'),
      timezone
    ),
  };
};

export const PeriodComparisonPicker = ({
  initialStartDates,
  initialPreset,
  onChange,
  periodPresets = [],
  allowTimeInput = false,
  format: _format = allowTimeInput ? defaultDateTimeFormat : defaultDateFormat,
  triggerInputProps,
  hint,
  hidePresets = false,
  maxNumberOfDays,
  hideRangeInputs = false,
  hideTimezonePicker = false,
  markedDates = [],
  initialTimeZone = dayjs.tz.guess(),
  periodAProps,
  periodBProps,
  inputValue,
  sx,
}: PeriodComparisonPickerProps) => {
  const [pickerARef, setPickerARef] = useState<InfiniteDayPickerRef>(null);
  const [pickerBRef, setPickerBRef] = useState<InfiniteDayPickerRef>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const [selectedA, setSelectedA] = useState<DatePeriod>(() =>
    buildInitialRange(
      initialStartDates?.periodA,
      initialPreset,
      initialTimeZone
    )
  );
  const [selectedB, setSelectedB] = useState<DatePeriod>(() =>
    buildInitialRange(
      initialStartDates?.periodB,
      initialPreset,
      initialTimeZone
    )
  );
  const [timezone, setTimezone] = useState<string>(initialTimeZone);

  const [selectedActivePeriod, setSelectedActivePeriod] = useState<
    number | undefined
  >(initialPreset);

  const handleOpen = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClose = () => {
    setTimezone(initialTimeZone);
    setSelectedA(
      buildInitialRange(
        initialStartDates?.periodA,
        initialPreset,
        initialTimeZone
      )
    );
    setSelectedB(
      buildInitialRange(
        initialStartDates?.periodB,
        initialPreset,
        initialTimeZone
      )
    );
    setSelectedActivePeriod(initialPreset);
    handleClose();
  };

  const handleTimezoneChange = (newTimezone: string) => {
    const convertedPeriodA = convertRange(selectedA, timezone, newTimezone);
    setSelectedA(convertedPeriodA);
    if (!!convertedPeriodA.from || !!convertedPeriodA.to) {
      pickerARef?.scrollToMonth(
        (convertedPeriodA.from || convertedPeriodA.to) as TZDate
      );
    }

    const convertedPeriodB = convertRange(selectedB, timezone, newTimezone);
    setSelectedB(convertedPeriodB);
    if (!!convertedPeriodB.from || !!convertedPeriodB.to) {
      pickerBRef?.scrollToMonth(
        (convertedPeriodB.from || convertedPeriodB.to) as TZDate
      );
    }

    setTimezone(newTimezone);
  };

  const handleApply = () => {
    if (selectedA?.from && selectedB?.from && selectedActivePeriod) {
      onChange?.(
        {
          periodA: rangeToUtc(selectedA),
          periodB: rangeToUtc(selectedB),
        },
        timezone,
        selectedActivePeriod
      );

      handleClose();
    }
  };

  const formattedInputValue = (
    selectedA?: DatePeriod,
    selectedB?: DatePeriod
  ) => {
    if (selectedA?.from && selectedB?.from) {
      return `${dayjs.tz(selectedA.from, timezone).format(_format)} vs ${dayjs
        .tz(selectedB.from, timezone)
        .format(_format)}`;
    }
    return '';
  };

  const inputValueFormatter = inputValue
    ? inputValue.bind(
        null,
        rangeToUtc(selectedA),
        rangeToUtc(selectedB),
        timezone
      )
    : formattedInputValue.bind(null, selectedA, selectedB);

  const clearRanges = () => {
    setSelectedA({ from: undefined, to: undefined });
    setSelectedB({ from: undefined, to: undefined });
  };

  return (
    <DatePickerProvider
      mode="range"
      format={_format}
      inputValue={inputValueFormatter}
      popoverAnchor={anchorEl}
      setPopoverAnchor={setAnchorEl}
      handleOpen={handleOpen}
      handleClose={onClose}
      handleApply={handleApply}
      periodPresets={periodPresets}
      activePeriod={selectedActivePeriod}
      setActivePeriod={setSelectedActivePeriod}
      allowTimeInput={allowTimeInput}
      maxNumberOfDays={maxNumberOfDays}
      hideTimezonePicker={hideTimezonePicker}
      timezone={timezone}
      setTimezone={handleTimezoneChange}
      markedDates={markedDates}
      hidePresets={hidePresets}
      clearRanges={clearRanges}
    >
      <DatePickerInput
        sx={sx}
        inputProps={{
          startAdornment: selectedActivePeriod ? (
            <Stack direction="row" alignItems="center" gap="12px" height="100%">
              <Typography
                variant="P14R"
                color="grey.400"
                noWrap
                data-testid="active-period"
              >
                {periodToString(selectedActivePeriod)}
              </Typography>
              <Divider orientation="vertical" />
              <CalendarBlank size="20px" />
            </Stack>
          ) : (
            <CalendarBlank size="20px" />
          ),
          ...triggerInputProps,
        }}
        popperContent={
          <DatePickerPopper
            aside={
              hidePresets ? null : (
                <Stack
                  minWidth={178}
                  padding="8px 16px"
                  borderRight={(theme) =>
                    `1px solid ${theme.palette.grey[200]}`
                  }
                >
                  <PeriodPresetsList />
                </Stack>
              )
            }
            footer={
              <PeriodComparisonPickerFooter
                isMini={hidePresets}
                hint={
                  typeof hint === 'function'
                    ? hint({
                        periodA: rangeToUtc(selectedA),
                        periodB: rangeToUtc(selectedB),
                        timezone,
                        pickerARef,
                        pickerBRef,
                      })
                    : hint
                }
              />
            }
          >
            <Stack direction="row" mt={16}>
              <PeriodComparisonPickerBody
                label="Period A"
                variant="main"
                hideRangeInputs={hideRangeInputs}
                initialRange={initialStartDates?.periodA}
                periodProps={{
                  selected: selectedA,
                  setSelected: setSelectedA,
                  pickerRef: pickerARef,
                  setPickerRef: setPickerARef,
                  ...periodAProps,
                }}
                otherRange={selectedB}
              />
              <Divider
                sx={{ borderColor: 'grey.100', mt: -16 }}
                orientation="vertical"
                flexItem
              />
              <PeriodComparisonPickerBody
                label="Period B"
                variant="secondary"
                hideRangeInputs={hideRangeInputs}
                initialRange={initialStartDates?.periodB}
                periodProps={{
                  selected: selectedB,
                  setSelected: setSelectedB,
                  pickerRef: pickerBRef,
                  setPickerRef: setPickerBRef,
                  ...periodBProps,
                }}
                otherRange={selectedA}
              />
            </Stack>
          </DatePickerPopper>
        }
      />
    </DatePickerProvider>
  );
};
