import dayjs, { OpUnitType } from 'dayjs';
import isNull from 'lodash/isNull';
import mapValues from 'lodash/mapValues';
import round from 'lodash/round';

import { DateRange, PriceType } from '@cast/types';
import { getPriceMultiplier } from '@cast/utils';

import {
  CostReportChartDataByPeriod,
  CostReportDataByPeriod,
  ResourceOfferingMultiplier,
} from '../types/costOverTime';

export const adjustEndDate = ([start, end]: DateRange): DateRange => {
  if (!dayjs(end).isSame(start, 'day')) {
    end = dayjs(end).startOf('d').add(1, 'd');
  } else {
    end = dayjs(start).startOf('d').add(1, 'd');
  }
  return [dayjs(start).startOf('d'), end];
};

export const unitsInCurrentRange = (
  [start, end]: DateRange,
  unit: OpUnitType
) => {
  return dayjs(end).diff(start, unit);
};

export const hasFallbackDataPoint = ({
  fallbackCpuCount,
}: CostReportChartDataByPeriod) => fallbackCpuCount !== 0;

const hoursTillNow = () => {
  const hours = Math.max(1, dayjs().diff(dayjs().startOf('d'), 'h'));
  return Math.max(1, hours);
};

const isCurrentDayData = (data: CostReportChartDataByPeriod) => {
  return dayjs(data.timestamp).isToday();
};

export const getDataHoursMultiplier = (
  data: CostReportChartDataByPeriod,
  priceType: PriceType = PriceType.DAILY
) => {
  if (priceType === PriceType.DAILY && isCurrentDayData(data)) {
    return hoursTillNow();
  }

  return getPriceMultiplier(priceType);
};

export const hasCpuAndNotForecastedDataPoint = ({
  onDemandCpuCount,
  fallbackCpuCount,
  spotCpuCount,
  forecast,
}: CostReportDataByPeriod) =>
  (onDemandCpuCount || fallbackCpuCount || spotCpuCount) && !forecast;

export const hasGpuAndNotForecastedDataPoint = ({
  onDemandGpuCount,
  fallbackGpuCount,
  spotGpuCount,
  forecast,
}: CostReportDataByPeriod) =>
  (onDemandGpuCount || fallbackGpuCount || spotGpuCount) && !forecast;

export const isPastDataPoint = ({ timestamp }: CostReportDataByPeriod) => {
  return !dayjs(timestamp).isToday();
};

export const getUptimeMultiplier = ({
  onDemandUptime,
  fallbackUptime,
  spotUptime,
}: CostReportChartDataByPeriod): ResourceOfferingMultiplier => {
  const onDemand = (onDemandUptime ?? 0) / 60;
  const fallback = (fallbackUptime ?? 0) / 60;
  const spot = (spotUptime ?? 0) / 60;

  // storage costs already have uptime accounted for...
  const storage = 1;

  return {
    onDemand,
    fallback,
    spot,
    storage,
  };
};

const roundPodCount = (count?: number): number => {
  return count ? round(count) : 0;
};

export const preparePodCount = (
  onDemand?: number,
  fallback?: number,
  spot?: number
) => {
  if (isNull(onDemand) || isNull(fallback) || isNull(spot)) {
    return {
      total: null,
      onDemand: null,
      fallback: null,
      spot: null,
    };
  }

  const onDemandCount = roundPodCount(onDemand);
  const fallbackCount = roundPodCount(fallback);
  const spotCount = roundPodCount(spot);
  const totalCount = onDemandCount + fallbackCount + spotCount;

  return {
    total: totalCount,
    onDemand: onDemandCount,
    fallback: fallbackCount,
    spot: spotCount,
  };
};

export const convertChartDatapointToCostReportData = (
  data: CostReportChartDataByPeriod[]
): CostReportDataByPeriod[] => {
  return data.map((d) => {
    return mapValues(d, (value) => {
      return value !== null ? value : 0;
    }) as CostReportDataByPeriod;
  });
};
