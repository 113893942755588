import { useEffect } from 'react';

import { captureMessage } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { HubspotContact, HubspotObject } from '@cast/types';

import { HUBSPOT_COOKIE_KEY } from 'common/constants';
import { analyticsEvents } from 'core/analytics';
import { apiClient } from 'core/api-client';
import { useAuth } from 'core/auth';
import { useHubspotContact } from 'core/hubspot';
import { parseApiError } from 'utils/api';
import { getCookie } from 'utils/cookie';
import { gtmPushEvent } from 'utils/gtmPushEvent';

type Props = {
  pageName: string;
  enabled: boolean;
};

export const useCreateFullHubspotContactMutation = ({
  pageName,
  enabled,
}: Props) => {
  const { user } = useAuth();
  const { contactState, setContactState } = useHubspotContact();

  const { mutate } = useMutation({
    mutationFn: (contact: HubspotContact) => {
      const hubspotCookie = getCookie(HUBSPOT_COOKIE_KEY);

      return apiClient.hubspot.createFullHubspotContact({
        submittedAt: new Date().getTime(),
        fields: [
          {
            objectTypeId: HubspotObject.CONTACT,
            name: 'email',
            value: user!.email,
          },
          {
            objectTypeId: HubspotObject.CONTACT,
            name: 'firstname',
            value: contact.firstName,
          },
          {
            objectTypeId: HubspotObject.CONTACT,
            name: 'lastname',
            value: contact.lastName,
          },
          {
            objectTypeId: HubspotObject.CONTACT,
            name: 'company',
            value: contact.company,
          },
          {
            objectTypeId: HubspotObject.CONTACT,
            name: 'phone',
            value: contact.phone,
          },
        ],
        context: {
          hutk: hubspotCookie!,
          pageUri: window.document.URL,
          pageName,
        },
      });
    },
    onSuccess: () => {
      setContactState('success');
      const { email, name, id } = user!;
      gtmPushEvent('signup_completed', {
        'user-id': id,
        email,
        name,
      });
      analyticsEvents.signupCompleted();
    },
    onError: (error, payload) => {
      setContactState(payload);
      const parsedError = parseApiError(error);

      analyticsEvents.errorWhenSignup([parsedError]);

      captureMessage(
        `Hubspot: Unable to submit FULL signup form. Payload: ${JSON.stringify(
          payload
        )}; Error: ${parsedError}`
      );
    },
  });

  useEffect(() => {
    if (enabled && typeof contactState !== 'string') {
      mutate(contactState);
    }

    // excluding contactFallback since we only need to fire this event once per login
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, mutate]);

  return { mutate };
};
