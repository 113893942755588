import { useMemo } from 'react';

import { Box, styled, Typography } from '@mui/material';
import map from 'lodash/map';
import {
  bindHover,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import Popover from 'material-ui-popup-state/HoverPopover';

import { Icons, List, ListItem, ListItemText } from '@cast/design-system';

import { SavingsPoliciesCount } from 'components/cluster';
import { usePoliciesQuery } from 'hooks/queries/autoscaler';
import { useThemeColors } from 'hooks/theme';
import { useParams } from 'hooks/useParams';
import { filterAvailablePolicies, getPoliciesList } from 'utils/cluster';

const CaretIcon = styled(Icons.CaretUp)(({ theme }) => ({
  transform: 'rotate(0)',
  color: theme.palette.grey[700],
  transition: theme.transitions.create(['transform']),
}));

export const SavingsPolicies = () => {
  const { clusterId } = useParams<{ clusterId: string }>();
  const { policies } = usePoliciesQuery(clusterId);
  const availablePolicies = useMemo(
    () => filterAvailablePolicies(policies),
    [policies]
  );

  const policiesList = getPoliciesList(availablePolicies);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'savings-policies-popover',
  });

  const [colorEnabled, colorDisabled] = useThemeColors('green.500', 'red.400');

  return (
    <>
      <Box
        alignItems="center"
        display="inline-flex"
        gap={8}
        {...bindHover(popupState)}
      >
        <Typography variant="h4">
          <SavingsPoliciesCount
            clusterId={clusterId}
            skeletonProps={{ width: 42, height: 32 }}
          />
        </Typography>
        <CaretIcon
          sx={popupState.isOpen ? { transform: 'rotate(180deg)' } : undefined}
          size={16}
        />
      </Box>

      <Popover
        PaperProps={{
          sx: {
            boxShadow: 'dropdown',
          },
        }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableScrollLock
      >
        <List
          sx={{
            py: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            '& .DsListItem-root': {
              px: 8,
            },
            '& .DsListItem-adornment-start': {
              mr: 4,
            },
          }}
        >
          {map(policiesList, ({ label, enabled }) => (
            <ListItem
              key={label}
              startAdornment={
                enabled ? (
                  <Icons.CheckCircle
                    color={colorEnabled}
                    weight="fill"
                    size={16}
                  />
                ) : (
                  <Icons.XCircle
                    color={colorDisabled}
                    weight="fill"
                    size={16}
                  />
                )
              }
            >
              <ListItemText>{label}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};
