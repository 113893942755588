import { useQuery, useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import { AvailableSavingsResponse } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

export const useAvailableSavingsQuery = (
  clusterId?: string,
  isEnabled = true
) => {
  const client = useQueryClient();
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: [QueryKeys.ESTIMATED_SAVINGS, clusterId],
    queryFn: async () => {
      const { data } = await apiClient.costReport.getEstimatedSavings(
        { clusterId: clusterId! },
        { timeout: 60_000 }
      );
      return data as AvailableSavingsResponse;
    },
    enabled: !!clusterId && isEnabled,
    refetchInterval: ({ queryKey }) => {
      const data = client.getQueryData(queryKey);
      if (!data) {
        return 5000;
      }
      return 0;
    },
  });

  return {
    isLoading: (!isLoading && isEmpty(data?.recommendations)) || isLoading,
    data: data || undefined,
    error,
    hasLoaded: !isEmpty(data?.recommendations),
    refetch,
  };
};
