import { ComponentProps } from 'react';

import { FailedToLoad } from 'components/messages';

type Props = ComponentProps<typeof FailedToLoad>;

export const SlimFailedToLoad = (props: Props) => {
  return (
    <FailedToLoad
      compact
      iconSize={44}
      iconWrapperProps={{
        sx: { gridColumn: '1 / 2', gridRow: '1 / 4', mr: 18 },
      }}
      titleProps={{ sx: { textAlign: 'left' } }}
      bodyProps={{ sx: { mt: 0 } }}
      actionsProps={{ mt: 0 }}
      sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr' }}
      {...props}
    />
  );
};
