import { PropsWithChildren, ReactNode } from 'react';

import { styled } from '@mui/material';
import { IconContext, X } from 'phosphor-react';

import { ButtonContext, IconButton } from '../buttons';

export type SnackbarProps = PropsWithChildren<{
  variant:
    | 'advisory'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'exceptional'
    | 'disabled';
  icon?: ReactNode;
  action?: ReactNode;
  onClose?: () => void;
  testId?: string;
}>;

type OwnerState = {
  variant?: SnackbarProps['variant'];
  icon: boolean;
};

const SnackbarRoot = styled('div', {
  name: 'DsSnackbar',
  slot: 'Root',
  target: 'DsSnackbar-root',
  overridesResolver: ({ ownerState }, styles) => {
    return [styles.root, styles[ownerState.variant]];
  },
})<{ ownerState: OwnerState }>({});

const SnackbarIcon = styled('div', {
  name: 'DsSnackbar',
  slot: 'Icon',
  target: 'DsSnackbar-icon',
  overridesResolver: (_, styles) => {
    return [styles.icon];
  },
})({});

export const Snackbar = ({
  children,
  icon,
  action,
  onClose,
  variant,
  testId,
}: SnackbarProps) => {
  return (
    <ButtonContext.Provider value={{ size: 'small' }}>
      <SnackbarRoot
        ownerState={{ variant, icon: !!icon }}
        data-testid={testId}
        data-variant={variant}
      >
        {icon && (
          <SnackbarIcon>
            <IconContext.Provider value={{ size: '20px', weight: 'fill' }}>
              {icon}
            </IconContext.Provider>
          </SnackbarIcon>
        )}

        {children}

        {action ? (
          <span className="DsSnackbar-action">{action}</span>
        ) : (
          <>
            {onClose && (
              <IconButton
                className="DsSnackbar-close"
                size="small"
                onClick={onClose}
              >
                <X size="1em" weight="bold" />
              </IconButton>
            )}
          </>
        )}
      </SnackbarRoot>
    </ButtonContext.Provider>
  );
};
