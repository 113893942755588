import { AxiosInstance } from 'axios';

import { AuthAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const AuthApiFactory = (client: AxiosInstance) => {
  const generatedApi = AuthAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    logout: generatedApi.logout,
    session: generatedApi.getSession,
  };
};
