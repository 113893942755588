import { styled } from '@mui/material';
import { SxProps } from '@mui/system';

import { Tooltip, TooltipProps, Icons, mergeSx } from '@cast/design-system';

const StyledInfoCircleIcon = styled(Icons.Info)({});

export type InfoTooltipProps = Omit<TooltipProps, 'children'> & {
  iconSize?: string;
  strokeWidth?: string;
  iconSx?: SxProps;
  noDefaultIconStyling?: boolean; // TODO: remove this prop when all places are fixed
};

export const InfoTooltip = ({
  iconSize = 'min(12px, 1em)',
  strokeWidth = '1.5',
  iconSx,
  noDefaultIconStyling = true,
  ...props
}: InfoTooltipProps) => {
  return (
    <Tooltip arrow placement="top" {...props}>
      <StyledInfoCircleIcon
        sx={mergeSx(
          !noDefaultIconStyling && {
            transform: 'translateY(0.13em)',
            marginLeft: '3px',
          },
          iconSx
        )}
        size={iconSize}
        strokeWidth={strokeWidth}
      />
    </Tooltip>
  );
};
