export default {
  50: '#FEF6E1',
  100: '#FCE8B5',
  200: '#FAD983',
  300: '#F8CA51',
  400: '#F7BE2C',
  500: '#F5B307',
  600: '#F4AC06',
  700: '#F0A104',
  800: '#F19617',
  900: '#EE8B02',
};
