import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import { clearQueryParamSilently } from '@cast/utils';

import { ANALYTICS_ENABLED } from 'common/constants';
import { user } from 'core/auth';
import { useOrganizations } from 'hooks/useOrganizations';

import { excludeEmail } from '../utils';

export const useInitAnalytics = (enabled: boolean) => {
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState<boolean>(false);
  const { currentOrganization } = useOrganizations();

  const ANALYTICS_QUERY_PARAMS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'ref',
    'gclid',
  ];

  useEffect(() => {
    if (
      ANALYTICS_ENABLED &&
      enabled &&
      currentOrganization?.id &&
      currentOrganization?.name
    ) {
      const url = new URL(window.location.href);
      const email = user().email;
      if (!excludeEmail(email)) {
        try {
          mixpanel.identify(email);
          mixpanel.register({
            email,
          });
          mixpanel.people.set({
            $email: email,
            orgName: currentOrganization?.name,
            orgID: currentOrganization?.id,
          });

          if (url.searchParams.has('ref')) {
            mixpanel.people.append({
              ref: url.searchParams.get('ref'),
            });
          }
        } catch (error) {
          console.error('Failed to identify user in mixpanel', error);
          Sentry.captureException(error);
        } finally {
          // dont block application if analytics fails to init
          setIsAnalyticsEnabled(true);
        }
      } else {
        setIsAnalyticsEnabled(true);
      }

      clearQueryParamSilently(ANALYTICS_QUERY_PARAMS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization?.name, currentOrganization?.id, enabled]);

  return { isAnalyticsEnabled };
};
