import { Cluster } from '@cast/types';

import { isAKSCluster, isEKSCluster, isGKECluster } from 'types/cluster';

import {
  ComputeSpendChartData,
  CostReportChartDataByPeriod,
  ResourceOfferingMultiplier,
} from '../../cost-report/types/costOverTime';

const transformFirstDataPoint = (
  datapoint: CostReportChartDataByPeriod,
  multiplier: ResourceOfferingMultiplier
): ComputeSpendChartData => {
  let onDemand: number | null = 0;
  let fallback: number | null = 0;
  let spot: number | null = 0;
  let storage: number | null = 0;

  if (datapoint.onDemandCost !== null) {
    onDemand = datapoint.onDemandCost * multiplier.onDemand;
  }

  if (datapoint.fallbackCost !== null) {
    fallback = datapoint.fallbackCost * multiplier.fallback;
  }

  if (datapoint.spotCost !== null) {
    spot = datapoint.spotCost * multiplier.spot;
  }

  if (datapoint.totalStorageCost !== null) {
    storage = datapoint.totalStorageCost * multiplier.storage;
  }

  return {
    timestamp: datapoint.timestamp,
    storage,
    onDemand,
    onDemandCpuCount: datapoint.onDemandCpuCount,
    onDemandRamGib: datapoint.onDemandRamGib,
    fallback,
    fallbackCpuCount: datapoint.fallbackCpuCount,
    fallbackRamGib: datapoint.fallbackRamGib,
    spot,
    spotCpuCount: datapoint.spotCpuCount,
    spotRamGib: datapoint.spotRamGib,
    anomalies: [],
  };
};

// prettier-ignore
const transformDataPoint = (
  previous: ComputeSpendChartData,
  datapoint: CostReportChartDataByPeriod,
  multiplier: ResourceOfferingMultiplier
): ComputeSpendChartData => {
  let onDemand: number | null = null;
  let onDemandCpuCount: number | null = null;
  let onDemandRamGib: number | null = null;
  let fallback: number | null = null;
  let fallbackCpuCount: number | null = null;
  let fallbackRamGib: number | null = null;
  let spot: number | null = null;
  let spotCpuCount: number | null = null;
  let spotRamGib: number | null = null;
  let storage: number | null = null;

  if (datapoint.onDemandCost !== null) {
    onDemand = (previous.onDemand ?? 0) + datapoint.onDemandCost * multiplier.onDemand;
  }

  if (datapoint.onDemandCpuCount !== null) {
    onDemandCpuCount = (previous.onDemandCpuCount ?? 0) + datapoint.onDemandCpuCount;
  }

  if (datapoint.onDemandRamGib !== null) {
    onDemandRamGib = (previous.onDemandRamGib ?? 0) + datapoint.onDemandRamGib;
  }

  if (datapoint.fallbackCost !== null) {
    fallback = !datapoint.forecast
      ? (previous.fallback ?? 0) + datapoint.fallbackCost * multiplier.fallback
      : 0;
  }

  if (
    datapoint.fallbackCpuCount !== null
  ) {
    fallbackCpuCount = !datapoint.forecast
      ? (previous.fallbackCpuCount ?? 0) + datapoint.fallbackCpuCount
      : 0;
  }

  if (datapoint.fallbackRamCost !== null) {
    fallbackRamGib = !datapoint.forecast
      ? (previous.fallbackRamGib ?? 0) +
        datapoint.fallbackRamCost * multiplier.fallback
      : 0;
  }

  if (datapoint.spotCost !== null) {
    spot = (previous.spot ?? 0) + datapoint.spotCost * multiplier.spot;

    if (datapoint.forecast) {
      spot += previous.fallback ?? 0;
    }
  }

  if (datapoint.spotCpuCount !== null) {
    spotCpuCount = (previous.spotCpuCount ?? 0)+ datapoint.spotCpuCount;
  }

  if (datapoint.spotRamCost !== null) {
    spotRamGib = (previous.spotRamGib ?? 0) + datapoint.spotRamCost * multiplier.spot;

    if (datapoint.forecast) {
      spotRamGib += previous.spotRamGib ?? 0;
    }
  }

  if (datapoint.totalStorageCost !== null) {
    storage = (previous.storage ?? 0) + datapoint.totalStorageCost * multiplier.storage;
  }

  return {
    timestamp: datapoint.timestamp,
    forecast: datapoint.forecast,
    storage,
    onDemand,
    onDemandCpuCount,
    onDemandRamGib,
    fallback,
    fallbackCpuCount,
    fallbackRamGib,
    spot,
    spotCpuCount,
    spotRamGib,
    anomalies: []
  };
};

export const makeCumulativeReducerFn =
  (multiplier: ResourceOfferingMultiplier) =>
  (
    acc: ComputeSpendChartData[],
    datapoint: CostReportChartDataByPeriod,
    index: number
  ) => {
    if (index === 0) {
      acc.push(transformFirstDataPoint(datapoint, multiplier));
    } else {
      const previous = acc[index - 1];

      if (datapoint.forecast) {
        datapoint.spotCost =
          (datapoint.spotCost ?? 0) + (datapoint.fallbackCost ?? 0) / 2;
        datapoint.spotCpuCount =
          (datapoint.spotCpuCount ?? 0) + (datapoint.fallbackCpuCount ?? 0);
        datapoint.fallbackCost = 0;
        datapoint.fallbackCpuCount = 0;
      }

      acc.push(transformDataPoint(previous, datapoint, multiplier));
    }

    return acc;
  };

export const displayGpuForCluster = (cluster: Cluster) => {
  const isGKE = isGKECluster(cluster);
  const isEKS = isEKSCluster(cluster);
  const isAKS = isAKSCluster(cluster);
  return isGKE || isEKS || isAKS;
};

export const getItemKey = (item: {
  workloadName?: string;
  namespace?: string;
  workloadType?: string;
}): string => {
  return item.workloadName! + item.namespace! + item.workloadType!;
};
