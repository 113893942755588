import { useContext, useMemo } from 'react';

import { Divider, Stack, styled } from '@mui/material';
import Big from 'big.js';

import { Paper, withProps } from '@cast/design-system';

import { FailedToLoad } from 'components/messages';

import { GaugeCard } from './GaugeCard';
import { DashboardContext } from '../../../DashboardContext';

const StyledDivider = withProps(Divider, {
  orientation: 'vertical',
  sx: { borderColor: 'grey.100' },
});

const CardContainer = styled(Paper)({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  padding: '16px 16px 14px 16px',
});

export const TotalResources = () => {
  const { clusters, failedToLoadSummary, refetchSummary } =
    useContext(DashboardContext);

  const {
    cpuProvisionedCores,
    cpuAllocatableCores,
    cpuRequestedCores,
    cpuUsedCores,
    memoryProvisionedGib,
    memoryAllocatableGib,
    memoryRequestedGib,
    memoryUsedGib,
    gpuAllocatable,
    gpuRequested,
    gpuUsed,
    gpuProvisioned,
  } = useMemo(() => {
    let cpuProvisionedCores = Big(0);
    let cpuAllocatableCores = Big(0);
    let cpuRequestedCores = Big(0);
    let cpuUsedCores = Big(0);
    let memoryProvisionedGib = Big(0);
    let memoryAllocatableGib = Big(0);
    let memoryRequestedGib = Big(0);
    let memoryUsedGib = Big(0);
    let gpuProvisioned = Big(0);
    let gpuAllocatable = Big(0);
    let gpuRequested = Big(0);
    let gpuUsed = Big(0);

    if (clusters) {
      for (const { metrics } of clusters) {
        // prettier-ignore
        if (metrics) {
            cpuProvisionedCores = cpuProvisionedCores.add(metrics.cpuProvisionedTotal);
            cpuAllocatableCores = cpuAllocatableCores.add(metrics.cpuAllocatableTotal);
            cpuRequestedCores = cpuRequestedCores.add(metrics.cpuRequestedTotal);
            cpuUsedCores = cpuUsedCores.add(metrics.cpuUsed);
            memoryProvisionedGib = memoryProvisionedGib.add(metrics.ramProvisionedTotal);
            memoryAllocatableGib = memoryAllocatableGib.add(metrics.ramAllocatableTotal);
            memoryRequestedGib = memoryRequestedGib.add(metrics.ramRequestedTotal);
            memoryUsedGib = memoryUsedGib.add(metrics.ramUsed);
            gpuProvisioned = gpuProvisioned.add(metrics.gpuProvisionedTotal);
            gpuAllocatable = gpuAllocatable.add(metrics.gpuAllocatableTotal);
            gpuRequested = gpuRequested.add(metrics.gpuRequestedTotal);
            gpuUsed = gpuUsed.add(metrics.gpuUsedTotal);
          }
      }
    }

    return {
      cpuProvisionedCores: cpuProvisionedCores.toNumber(),
      cpuAllocatableCores: cpuAllocatableCores.toNumber(),
      cpuRequestedCores: cpuRequestedCores.toNumber(),
      cpuUsedCores: cpuUsedCores.toNumber(),
      memoryProvisionedGib: memoryProvisionedGib.toNumber(),
      memoryAllocatableGib: memoryAllocatableGib.toNumber(),
      memoryRequestedGib: memoryRequestedGib.toNumber(),
      memoryUsedGib: memoryUsedGib.toNumber(),
      gpuProvisioned: gpuProvisioned.toNumber(),
      gpuAllocatable: gpuAllocatable.toNumber(),
      gpuRequested: gpuRequested.toNumber(),
      gpuUsed: gpuUsed.toNumber(),
    };
  }, [clusters]);

  const hasGpuUsage = gpuAllocatable > 0 || gpuRequested > 0 || gpuUsed > 0;

  if (failedToLoadSummary) {
    return (
      <CardContainer>
        <FailedToLoad
          title="Failed to load resource metrics"
          refresh={refetchSummary}
          compact
        />
      </CardContainer>
    );
  }

  const CpuGauge = () => (
    <GaugeCard
      resource="CPU"
      provisioned={cpuProvisionedCores}
      total={cpuAllocatableCores}
      requested={cpuRequestedCores}
      used={cpuUsedCores}
      unit="CPU"
      testId="cpu-gauge"
    />
  );

  const MemoryGauge = () => (
    <GaugeCard
      resource="memory"
      provisioned={memoryProvisionedGib}
      total={memoryAllocatableGib}
      requested={memoryRequestedGib}
      used={memoryUsedGib}
      unit="GiB"
      testId="memory-gauge"
    />
  );

  if (!hasGpuUsage) {
    return (
      <Stack direction="row" gap={24} width="100%">
        <CardContainer>
          <CpuGauge />
        </CardContainer>
        <CardContainer>
          <MemoryGauge />
        </CardContainer>
      </Stack>
    );
  }

  return (
    <CardContainer>
      <CpuGauge />
      <StyledDivider />
      <MemoryGauge />
      <StyledDivider />
      <GaugeCard
        resource="GPU"
        provisioned={gpuProvisioned}
        total={gpuAllocatable}
        requested={gpuRequested}
        used={gpuUsed}
        unit="GPU"
        testId="gpu-gauge"
      />
    </CardContainer>
  );
};
