import { PropsWithChildren } from 'react';

import { Box, Typography } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { usePrevious } from '@cast/utils';

import { usePreviewConfig } from '../_providers';

type FallbackProps = { resetError: () => void };

const Fallback = ({ resetError }: FallbackProps) => {
  const { renderFallback, data, errorMessage } = usePreviewConfig();
  const prev = usePrevious(data);

  if (prev && prev !== data) {
    resetError();
  }

  if (renderFallback) {
    return renderFallback(data, resetError);
  }

  return (
    <Box className="DsCode-error" bgcolor="inherit" color="red.400" p="8px">
      <Typography component="span" color="red.400">
        ERROR:
      </Typography>
      <Typography component="span" pl="4px" color="white">
        {errorMessage ?? 'Cannot display code preview'}
      </Typography>
    </Box>
  );
};

export const CodeErrorBoundary = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <Fallback resetError={resetErrorBoundary} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};
