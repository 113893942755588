import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';

import { mergeSx } from '@cast/design-system';

export type MarkerProps = {
  color: string;
  background?: string;
  empty?: boolean;
} & Omit<BoxProps, 'color'>;

export const Marker = ({
  empty,
  color,
  background,
  width = 4,
  borderRadius = 2,
  sx,
  ...boxProps
}: MarkerProps) => {
  return (
    <Box
      width={width}
      borderRadius={`${borderRadius}px`}
      border={empty ? color : undefined}
      boxSizing="border-box"
      sx={mergeSx(
        {
          background,
          backgroundColor: empty ? undefined : color,
        },
        sx
      )}
      {...boxProps}
      className={clsx(boxProps.className, 'Marker-root')}
    />
  );
};
