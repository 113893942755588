import { useEffect, useMemo, useRef } from 'react';

import { Box, Stack } from '@mui/material';

import { Avatar, AvatarProps, stringToHash } from '@cast/design-system';

import { LogoSmallSvg } from 'assets/svg';

const avatarColors = ['indigo', 'aqua', 'yellow', 'grey'] as const;

type Color = (typeof avatarColors)[number];

export type Props = Omit<AvatarProps, 'color' | 'text'> & {
  name: string;
  color?: Color;
};

export const OrganizationAvatar = (props: Props) => {
  const avatarRef = useRef<HTMLButtonElement | null>(null);
  const avatarHeight = useRef<number | null>(null);

  const color = useMemo(() => {
    return (
      props.color ||
      avatarColors[stringToHash(props.name) % avatarColors.length]
    );
  }, [props.color, props.name]);

  useEffect(() => {
    if (avatarRef.current) {
      avatarHeight.current = Math.round(
        avatarRef.current.getBoundingClientRect().height / 2
      );
    }
  }, []);

  if (!props.name?.length) {
    return (
      <Box position="relative">
        <Avatar
          {...props}
          variant="square"
          text=""
          ref={avatarRef}
          className="empty-avatar"
          sx={{ backgroundColor: 'grey.300' }}
        />
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          {!!avatarHeight.current && (
            <LogoSmallSvg color="white" height={avatarHeight.current} />
          )}
        </Stack>
      </Box>
    );
  }

  return (
    <Avatar
      variant="square"
      text={props.name}
      color={color}
      size={props.size}
    />
  );
};
