import { styled, SxProps } from '@mui/material';

import { focusProps, Icons } from '@cast/design-system';

import { useMainLayout } from '../../hooks';
import { SidebarState } from '../../types';

const Container = styled('button')(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  background: 'white',
  '& svg': {
    color: theme.palette.grey[500],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.grey[300],
    },
  },
  '&:focus-visible': {
    opacity: 1,
    ...focusProps(theme),
  },
}));

export type SidebarSwitcherProps = {
  sx?: SxProps;
  className?: string;
};

export const SidebarSizeSwitcher = ({
  sx,
  className,
}: SidebarSwitcherProps) => {
  const { sidebarState, setSidebarState } = useMainLayout();
  const isMinimized = sidebarState && sidebarState === 'minimized';

  const handleSidebarSize = () => {
    setSidebarState((current: SidebarState) => {
      if (!current) {
        return false;
      }

      if (current === 'minimized') {
        return 'maximized';
      }

      return 'minimized';
    });
  };

  const a11yLabel = `${isMinimized ? 'Expand' : 'Collapse'} Sidebar`;

  return (
    <Container
      onClick={handleSidebarSize}
      sx={sx}
      className={className}
      aria-label={a11yLabel}
    >
      {isMinimized ? (
        <Icons.CaretDoubleRight size="16px" />
      ) : (
        <Icons.CaretDoubleLeft size="16px" />
      )}
    </Container>
  );
};
