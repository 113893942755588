import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Dayjs } from 'dayjs';
import debounce from 'lodash/debounce';
import { TZDate } from 'react-day-picker';

import { useDatePickerContext } from './useDatePickerContext';
import Input, { InputProps } from '../../controls/input/Input';
import { dateToInputString, inputStringToDayjs } from '../utils';

type DateTimeInputProps = InputProps & {
  dateToSyncWith?: TZDate;
  dateValidator: (value: Dayjs) => string | undefined;
  onDateChange: (date?: Dayjs) => void;
};

export const DateTimeInput = ({
  dateToSyncWith,
  dateValidator,
  onDateChange,
  ...rest
}: DateTimeInputProps) => {
  const ctx = useDatePickerContext();

  const [value, setValue] = useState(
    dateToSyncWith
      ? dateToInputString(dateToSyncWith, ctx.format, ctx.timezone)
      : ''
  );
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    // when date picker value is changed outside of input sync it with input value
    if (dateToSyncWith) {
      const dateString = dateToInputString(
        dateToSyncWith,
        ctx.format,
        ctx.timezone
      );

      if (value !== dateString) {
        setValue(dateString);
        setError(undefined);
      }
    } else {
      setValue('');
      setError(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateToSyncWith]);

  const validateInput = useCallback(
    (dateString: string) => {
      if (!dateString) {
        onDateChange();
        setError(undefined);
        return;
      }

      const parsed = inputStringToDayjs(dateString, ctx.format, ctx.timezone);

      if (!parsed.isValid()) {
        setError('Invalid date');
      } else {
        const error = dateValidator(parsed);

        if (error) {
          setError(error);
        } else {
          onDateChange(parsed);
          setError(undefined);
        }
      }
    },
    [ctx.format, ctx.timezone, dateValidator, onDateChange]
  );

  const debouncedValidation = useMemo(() => {
    return debounce(validateInput, 700);
  }, [validateInput]);

  const handleChange = (
    _: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => {
    setValue(value);
    debouncedValidation(value);
  };

  return (
    <Input
      size="medium"
      placeholder={`yyyy/mm/dd${ctx.allowTimeInput ? ' hh:mm' : ''}`}
      testId="date-picker-input"
      onChange={handleChange}
      value={value}
      error={error}
      {...rest}
    />
  );
};
