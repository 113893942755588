import { Notification } from '@cast/types';

import { Criterion } from 'components/search';
import { makeSearchBoxCriteria } from 'components/search/utils';

export const notificationsSearchBoxCriteria =
  makeSearchBoxCriteria<Notification>(
    { key: 'name', title: 'Name:' },
    { key: 'id', title: 'Notification ID:' },
    { key: 'clusterName', title: 'Cluster:' },
    { key: 'project', title: 'Project:' },
    { key: 'operation', title: 'Operation:' }
  );

export const defaultOption = { key: 'unresolved', title: 'Unresolved' };

export const resolvedCriterion: Criterion = {
  key: 'resolved',
  initialValue: defaultOption.key,
};

export const severityCriterion: Criterion = { key: 'severity' };

export const notificationsCriteria = [
  ...notificationsSearchBoxCriteria,
  resolvedCriterion,
  severityCriterion,
];
