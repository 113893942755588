import { useContext } from 'react';

import { SxProps } from '@mui/system';

import { Icons, Button, mergeSx } from '@cast/design-system';

import {
  RffInputsArrayContext,
  RffInputsArrayRowContext,
} from './RffInputsArrayContext';

type Props = {
  testId?: string;
  text?: string;
  disabled?: boolean;
  sx?: SxProps;
};

export const AddRowButton = ({
  testId = 'add-row-btn',
  text = 'Add',
  disabled = false,
  sx,
}: Props) => {
  const arrayContext = useContext(RffInputsArrayContext);
  const arrayRowContext = useContext(RffInputsArrayRowContext);
  if (!arrayRowContext.isLast) {
    return null;
  }

  return (
    <Button
      className="InputsStack-AddRowButton"
      variant="text"
      startIcon={<Icons.Plus />}
      disabled={disabled}
      onClick={arrayContext.add}
      sx={mergeSx(
        {
          mt: 12,
        },
        sx
      )}
      testId={testId}
    >
      {text}
    </Button>
  );
};
