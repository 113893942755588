import { styled } from '@mui/material';

import { Tooltip } from '@cast/design-system';

type Props = {
  type: 'manual' | 'automatic';
};

export const CheckType = styled(({ type, ...props }: Props) => {
  return (
    <Tooltip
      title={type === 'manual' ? 'Manual' : 'Automated'}
      placement="top"
      arrow
    >
      <div {...props}>{type[0]}</div>
    </Tooltip>
  );
})(({ theme, type }) => ({
  height: 13,
  width: 13,
  paddingTop: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  color: 'white',
  borderRadius: '50%',
  fontSize: 10,
  backgroundColor: theme.palette.grey[type === 'manual' ? 300 : 500],
}));
