import { SvgIcon, SvgIconProps } from '@mui/material';
import clsx from 'clsx';

import { useThemeColors } from '../../../../hooks/theme';
import { mergeSx } from '../../../../utils';
import { SortDirection } from '../../types';

type Props = SvgIconProps<'svg', { sortDirection?: SortDirection }>;

export const SortIcon = ({ sortDirection, sx, ...rest }: Props) => {
  const [activeColor, inactiveColor] = useThemeColors('grey.900', 'grey.200');
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      fill="none"
      className={clsx(
        'Ds-Table-SortIcon-root',
        'Ds-Table-HeaderIcon-root',
        sortDirection === 'asc' && 'Ds-Table-SortIcon-asc',
        sortDirection === 'desc' && 'Ds-Table-SortIcon-desc'
      )}
      sx={mergeSx(
        {
          cursor: 'pointer',
          fontSize: 16,
          color: 'grey.400',
          '&.Ds-Table-SortIcon-asc .Ds-Table-SortIcon-ArrowUp': {
            stroke: activeColor,
          },
          '&.Ds-Table-SortIcon-asc .Ds-Table-SortIcon-ArrowDown': {
            stroke: inactiveColor,
          },
          '&.Ds-Table-SortIcon-desc .Ds-Table-SortIcon-ArrowDown': {
            stroke: activeColor,
          },
          '&.Ds-Table-SortIcon-desc .Ds-Table-SortIcon-ArrowUp': {
            stroke: inactiveColor,
          },
        },
        sx
      )}
      {...rest}
    >
      <path
        className="Ds-Table-SortIcon-ArrowDown"
        d="M7 11L5 13L3 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="Ds-Table-SortIcon-ArrowDown"
        d="M5 3V13"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="Ds-Table-SortIcon-ArrowUp"
        d="M9 5L11 3L13 5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="Ds-Table-SortIcon-ArrowUp"
        d="M11 13V3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
