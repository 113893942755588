import { useQuery } from '@tanstack/react-query';

import { RbacResolveAccessRequestParams } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';
import { useOrganizations } from 'hooks/useOrganizations';

type RbacResolveAccessQueryArgs = RbacResolveAccessRequestParams & {
  enabled?: boolean;
};

export const useRbacResolveAccessQuery = ({
  enabled = true,
  ...params
}: RbacResolveAccessQueryArgs) => {
  const { currentOrganization } = useOrganizations();
  const organizationId = currentOrganization?.id;

  return useQuery({
    queryKey: [QueryKeys.RBAC_RESOLVE_ACCESS, organizationId, params],
    queryFn: async () => {
      const { data } = await apiClient.rbac.resolveAccess({
        organizationId: organizationId!,
        body: params,
      });
      return data ?? [];
    },
    enabled:
      enabled &&
      !!organizationId &&
      Boolean(params.groups?.length || params.users?.length),
  });
};
