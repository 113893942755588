import { alpha, Divider, DividerProps } from '@mui/material';
import clsx from 'clsx';

import { getThemeColor } from '../../hooks/theme';
import { mergeSx } from '../../utils';

export type ShadowDividerProps = Pick<
  DividerProps,
  'orientation' | 'color' | 'sx' | 'className'
> & {
  direction?: 'top' | 'bottom' | 'left' | 'right';
  opacity?: number;
  endOpacity?: number;
  endColor?: string;
  size?: number;
};

function getDegree(direction: ShadowDividerProps['direction']): string {
  switch (direction) {
    case 'top':
      return '0deg';
    case 'bottom':
      return '180deg';
    case 'left':
      return '-90deg';
    case 'right':
      return '90deg';
    default:
      return '';
  }
}

export const ShadowDivider = ({
  opacity = 0.6,
  endOpacity = 0,
  color = 'grey.200',
  endColor = color,
  orientation = 'vertical',
  direction = orientation === 'vertical' ? 'right' : 'bottom',
  size = 20,
  className,
  sx,
  ...props
}: ShadowDividerProps) => {
  const width = orientation === 'vertical' ? `${size}px` : '100%';
  const height = orientation === 'vertical' ? '100%' : `${size}px`;
  const deg = getDegree(direction);

  return (
    <Divider
      orientation={orientation}
      {...props}
      sx={mergeSx(
        (theme) => ({
          width,
          height,
          pointerEvents: 'none',
          border: 'none',
          background: `linear-gradient(${deg}, ${alpha(
            getThemeColor(theme, color),
            opacity
          )} 0%, ${alpha(
            getThemeColor(theme, endColor),
            endOpacity
          )} ${size}px)`,
        }),
        sx
      )}
      className={clsx('DsShadowDivider-Root', className)}
    />
  );
};
