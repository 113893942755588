import { mergeSx } from '@cast/design-system';

import { LabelChip, LabelChipProps } from '../LabelChip';

export const BetaLabelChip = (labelProps: LabelChipProps) => {
  return (
    <LabelChip
      size="small"
      {...labelProps}
      sx={mergeSx(
        {
          backgroundColor: 'indigo.200',
          color: 'white',
          alignSelf: 'center',
          '& > *': {
            cursor: 'inherit!important',
          },
        },
        labelProps?.sx
      )}
    >
      Beta
    </LabelChip>
  );
};
