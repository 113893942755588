import { multiSorterTheme } from './components/multi-sorter/multiSorterTheme';
import { paginatorTheme } from './components/paginator/paginatorTheme';
import { tableTheme as _tableTheme } from './tableTheme';

export { Column } from './Column';
export { ColumnGroup } from './ColumnGroup';
export { Table } from './Table';
export { BodyRow } from './components/BodyRow';
export * from './types';
export * from './utils';
export * from './data-table';
export * from './components';
export * from './constants';
export * from './hooks';

export const tableTheme = {
  ...multiSorterTheme,
  ..._tableTheme,
  ...paginatorTheme,
};
