import { useEffect } from 'react';

import orderBy from 'lodash/orderBy';
import { useField, useForm } from 'react-final-form';

import { countryCodeMap } from '@cast/constants';
import { ListItem, ListItemIcon, ListItemText } from '@cast/design-system';

import { IS_PROD } from 'common/constants';
import { RffAutocomplete } from 'core/forms/rff';
import { useCountryCodeQuery } from 'hooks/queries';

import { searchFilter } from './_utils';
import { CountryIcon } from '../_components';

type CountryCodeAutocompleteProps = {
  name: string;
};

const autocompleteOptions = orderBy(
  countryCodeMap,
  ['phone', (o) => o.orderWeight || 0],
  ['asc', 'desc']
);

export const CountryCodeAutocomplete = ({
  name,
}: CountryCodeAutocompleteProps) => {
  const field = useField(name);
  const { change } = useForm();

  const { country } = useCountryCodeQuery(IS_PROD);
  useEffect(() => {
    if (!country || field.meta.dirty) {
      return;
    }
    const loadedCountry = countryCodeMap.find((c) => c.code === country);
    if (loadedCountry) {
      change(name, loadedCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <RffAutocomplete
      name={name}
      size="medium"
      sx={{
        '& .MuiInputBase-root ': {
          cursor: 'pointer',
        },
      }}
      options={autocompleteOptions}
      optionLabel="phone"
      searchFilter={searchFilter}
      optionKey={(option) => option.code}
      renderOption={(option, reactProps) => {
        return (
          <ListItem
            {...reactProps}
            startAdornment={
              <ListItemIcon>
                <CountryIcon countryCode={option.code} />
              </ListItemIcon>
            }
          >
            <ListItemText>{option.phone}</ListItemText>
          </ListItem>
        );
      }}
      optionIcon={(item) => {
        return (
          <CountryIcon
            key={item.label}
            countryCode={item.code}
            sx={{
              borderRadius: '2px',
            }}
          />
        );
      }}
      inputProps={{
        placeholder: '+00',
      }}
      virtualization
      virtualizationProps={{
        maxHeight: 142,
      }}
    />
  );
};
