import { ReactElement, ReactNode } from 'react';

import { Link } from '@mui/material';

import { Primitive, PrimitiveVal } from '../../_types';
import { getPrimitiveType } from '../../utils';

type Props = {
  data: PrimitiveVal;
};

const render: Record<Primitive, (content?: ReactNode) => ReactElement> = {
  [Primitive.STRING]: (content) => (
    <span className="DsPreviewYaml-String">{content}</span>
  ),
  [Primitive.NUMBER]: (content) => (
    <span className="DsPreviewYaml-Number">{content}</span>
  ),
  [Primitive.BOOLEAN]: (content) => (
    <span className="DsPreviewYaml-Boolean">{String(content)}</span>
  ),
  [Primitive.NUMERIC_STRING]: (content) => (
    <span className="DsPreviewYaml-NumericString">{content}</span>
  ),
  [Primitive.LINK]: (content) => (
    <Link
      className="DsPreviewYaml-Link"
      target="_blank"
      rel="noreferrer"
      href={content as string}
    >
      {content}
    </Link>
  ),
  [Primitive.DATE]: (content: ReactNode) => (
    <span className="DsPreviewYaml-Date">{content}</span>
  ),
  [Primitive.NULL]: () => <span className="DsPreviewYaml-Null">null</span>,
  [Primitive.UNDEFINED]: () => (
    <span className="DsPreviewYaml-Undefined">undefined</span>
  ),
};

export const PrimitiveValue = ({ data }: Props) => {
  const type = getPrimitiveType(data);
  if (!type) {
    throw new Error('Trying to render non primitive value as primitive');
  }
  return (
    <span className="DsPreviewYaml-PrimitiveValueContainer">
      {render[type](data)}
    </span>
  );
};
