import {
  cloneElement,
  ComponentType,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
  UIEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { styled } from '@mui/material';
import clsx from 'clsx';
import { IconContext } from 'phosphor-react';

import { useElementWidth, useMergedRef } from '@cast/utils';

import InputChip, { InputChipProps } from './Chip';
import { chipGetters } from './chipInputTheme';
import { mergeSx } from '../../../utils';
import { MagnifyingGlass, XCircle } from '../../icons';
import AutoSizingInput from '../AutoSizingInput';
import { Input, InputProps } from '../input';

const sizeToIconSize = {
  small: '16px',
  medium: '20px',
  large: '20px',
};

type CustomInputProps = HTMLAttributes<HTMLInputElement> & {
  title?: string;
  value?: string;
};

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ title, ...props }: CustomInputProps, ref) => {
    const [inputRef, setInputRef] = useMergedRef(ref, false);
    return (
      <div className="DsChipInput-InputWrapper">
        {title && (
          <span
            className="DsChipInput-ChipTitle"
            onClick={() => inputRef?.focus()}
          >
            {title}
          </span>
        )}
        <AutoSizingInput
          {...props}
          ref={setInputRef}
          styleFactory={(width) => ({
            width: `${width ? width + 16 : 16}px !important`,
          })}
        />
      </div>
    );
  }
);
CustomInput.displayName = 'CustomInput';

export type ChipInputProps<T = ReactElement | InputChipProps | string> = Omit<
  Omit<InputProps, 'ref'>,
  'onEnter' | 'inputRef'
> & {
  chips?: Array<T>;
  startIcon?: ReactNode | boolean;
  activeTitle?: string;
  onClear?: (e: UIEvent) => void;
  showClearButton?: boolean;
  separators?: string[];
  onSeparation?: (value: string | string[]) => void;
  ChipComponent?: ComponentType<InputChipProps>;
};

const StyledInput = styled(Input, {
  name: 'DsChipInput',
  slot: 'Root',
  overridesResolver: ({ size }, styles) => {
    return [styles.root, styles[size]];
  },
})({});
StyledInput.displayName = 'StyledInput';

export type ChipInputForwardRef = {
  wrapperRef?: HTMLDivElement | null;
  inputRef?: HTMLInputElement | null;
  clearInput: () => void;
};

const ChipInput = forwardRef<ChipInputForwardRef, ChipInputProps>(
  (
    {
      chips,
      startIcon = true,
      activeTitle,
      inputProps = {},
      size = 'medium',
      onClear,
      showClearButton,
      separators = ['Enter'],
      onSeparation,
      ChipComponent = InputChip,
      ...props
    }: ChipInputProps,
    ref
  ) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [value, setValue] = useState<string>('');

    useImperativeHandle(ref, () => ({
      wrapperRef: wrapperRef.current,
      inputRef: inputRef?.current,
      clearInput: () => {
        setValue('');
      },
    }));

    const showStartIcon = !!startIcon;
    if (showStartIcon && typeof startIcon === 'boolean') {
      startIcon = <MagnifyingGlass className="Ds-MagnifyingGlass" />;
    }
    const [startIconWrapper, setStartIconWrapper] =
      useState<HTMLDivElement | null>(null);
    const startIconWidth = useElementWidth(startIconWrapper);

    const [endIconWrapper, setEndIconWrapper] = useState<HTMLDivElement | null>(
      null
    );
    const endIconWidth = useElementWidth(endIconWrapper);

    const valueToChips = useCallback(
      (value: string) => {
        const values = value.split(new RegExp(separators.join('|'), 'gi'));
        onSeparation?.(values.map((v) => v.trim()));
        setValue('');
      },
      [onSeparation, separators, setValue]
    );

    useEffect(() => {
      if (separators?.some((s) => value.includes(s))) {
        valueToChips(value);
      }
    }, [separators, value, valueToChips]);

    const triggerClear = useCallback(
      (event: UIEvent) => {
        onClear?.(event);
        setValue('');
        inputRef?.current?.focus();
      },
      [inputRef, onClear]
    );

    return (
      <StyledInput
        ref={wrapperRef}
        inputRef={inputRef}
        className={clsx(
          'DsChipInput-root',
          !!startIconWidth && 'DsChipInput-withStartIcon'
        )}
        InputProps={{ inputComponent: CustomInput }}
        inputProps={{ ...inputProps, title: activeTitle }}
        size={size}
        {...props}
        sx={mergeSx(
          !!startIconWidth && {
            '& .MuiInputBase-root': {
              paddingLeft: `calc(${startIconWidth}px + ${chipGetters.px()} + ${chipGetters.startIconPr()})`,
            },
          },
          !!endIconWidth && {
            '& .MuiInputBase-root': {
              paddingRight: `calc(${endIconWidth}px + ${chipGetters.px()} + ${chipGetters.startIconPr()})`,
            },
          },
          props.sx
        )}
        value={value}
        onChange={(_, value) => setValue(value)}
        onKeyDownCapture={(e) => {
          const target = e.target as HTMLInputElement;
          if (separators?.includes(e.key)) {
            valueToChips(target.value);
          }
        }}
        startAdornment={
          <>
            {showStartIcon && (
              <div className="DsChipInput-StartIcon" ref={setStartIconWrapper}>
                <IconContext.Provider value={{ size: sizeToIconSize[size] }}>
                  {startIcon}
                </IconContext.Provider>
              </div>
            )}
            {chips?.map((chip, index) => {
              if (isValidElement(chip)) {
                return cloneElement(chip, {
                  className: 'DsChipInput-Chip',
                  size,
                } as InputChipProps);
              }
              const { sx, className, ...chipProps } = (
                typeof chip === 'string' ? { title: chip } : chip
              ) as InputChipProps;

              return (
                <ChipComponent
                  key={index}
                  className={clsx('DsChipInput-Chip', className)}
                  sx={mergeSx(
                    { backgroundColor: 'indigo.300', color: 'white' },
                    sx
                  )}
                  size={size}
                  disabled={props.disabled}
                  multiline
                  {...chipProps}
                  onDestroy={
                    !props.disabled && !props.readOnly
                      ? chipProps.onDestroy
                      : undefined
                  }
                />
              );
            })}
          </>
        }
        endAdornment={
          (showClearButton !== undefined
            ? showClearButton
            : onClear &&
              !!(activeTitle?.length || chips?.length || !!props.value)) &&
          !props.disabled &&
          !props.readOnly && (
            <div
              ref={setEndIconWrapper}
              className="DsChipInput-EndIcon"
              data-testid="clear-all"
            >
              <XCircle
                aria-label="clear all"
                size={sizeToIconSize[size]}
                onClick={triggerClear}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </div>
          )
        }
      />
    );
  }
);
ChipInput.displayName = 'ChipInput';

export default ChipInput;
