import { HTMLAttributes } from 'react';

import { styled, SxProps } from '@mui/material';

import { Chip } from '../../chip';
import { Criterion } from '../types';

const Root = styled('div', {
  name: 'DsSearch',
  slot: 'SearchInCriteria',
  target: 'DsSearch-CriteriaSearch',
  overridesResolver: (_, styles) => {
    return [styles.criteriaSearch];
  },
})<HTMLAttributes<HTMLDivElement>>``;

const Label = styled('div', {
  name: 'DsSearch',
  slot: 'SearchInCriteriaLabel',
  target: 'DsSearch-CriteriaSearchLabel',
  overridesResolver: (_, styles) => {
    return [styles.criteriaSearchLabel];
  },
})<HTMLAttributes<HTMLDivElement>>``;

const List = styled('div', {
  name: 'DsSearch',
  slot: 'CriteriaList',
  target: 'DsSearch-CriteriaSearchList',
  overridesResolver: (_, styles) => {
    return [styles.criteriaSearchList];
  },
})<HTMLAttributes<HTMLDivElement>>``;

export type SearchInCriteriaProps = {
  criteria?: Criterion[];
  onSelect?: (criteria: Criterion) => void;
  criteriaSx?: SxProps;
};

export const CriteriaSearch = ({
  criteria,
  onSelect,
  criteriaSx,
}: SearchInCriteriaProps) => {
  if (!criteria?.length) {
    return null;
  }

  return (
    <Root sx={criteriaSx} data-testid="search-available-criterias">
      <Label>Search in criteria</Label>
      <List>
        {criteria.map((criterion) => (
          <Chip
            testId="search-criteria-chip"
            key={criterion.key}
            onClick={() => {
              onSelect?.(criterion);
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {criterion.title || criterion.key}
          </Chip>
        ))}
      </List>
    </Root>
  );
};
