export type PauseScheduleDataPoint = {
  day: string;
  runningHours: number;
  pausedHours: number;
};

export enum PauseScheduleMode {
  BUSINESS_HOURS,
  CUSTOM,
}
