import { DataKey } from './_types';

export const valueResolver = (
  dataKey: DataKey<any> | undefined,
  entry: any
): number | null => {
  const value =
    (typeof dataKey === 'string' || typeof dataKey === 'number'
      ? entry[dataKey as keyof typeof entry]
      : dataKey?.(entry)) || null;

  if (value === null) return value;

  return typeof value !== 'number' ? parseFloat(value) : value;
};
