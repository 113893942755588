import { forwardRef } from 'react';

import { useThemeColor } from '../../../hooks/theme';
import IconBase, { IconProps } from '../IconBase';

export const Chatbot = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const color = useThemeColor(props.color || 'grey.900');
  return (
    <IconBase ref={ref} {...props} viewBox="0 0 40 40">
      <path
        d="M14.1185 15.132C15.5747 13.3717 17.7757 12.2501 20.2388 12.2501C22.6145 12.2501 24.7466 13.2936 26.2015 14.9473L31.9855 11.6079L21.0083 5.27017C20.3844 4.90994 19.6157 4.90994 18.9917 5.27017L8.01447 11.6079L14.1185 15.132Z"
        fill={color}
      />
      <path
        d="M12.5285 18.2889L6.25 14.664V27.3395C6.25 28.0599 6.63435 28.7256 7.25827 29.0859L18.2355 35.4236L18.2355 27.8735C14.8207 26.9857 12.2996 23.8819 12.2996 20.1892C12.2996 19.5342 12.3789 18.8978 12.5285 18.2889Z"
        fill={color}
      />
      <path
        d="M21.7645 27.9819L21.7645 35.4236L32.7417 29.0859C33.3657 28.7256 33.75 28.0599 33.75 27.3395V14.664L27.8862 18.0495C28.0763 18.7302 28.1779 19.4479 28.1779 20.1892C28.1779 24.052 25.4192 27.2705 21.7645 27.9819Z"
        fill={color}
      />
      <path
        d="M24.903 20.064C24.903 20.6929 24.7791 21.3157 24.5385 21.8968C24.2978 22.4778 23.945 23.0058 23.5003 23.4505C23.0555 23.8953 22.5276 24.248 21.9465 24.4887C21.3654 24.7294 20.7427 24.8533 20.1137 24.8533C19.4848 24.8533 18.862 24.7294 18.2809 24.4887C17.6999 24.248 17.1719 23.8953 16.7272 23.4505C16.2825 23.0058 15.9297 22.4778 15.689 21.8968C15.4483 21.3157 15.3244 20.6929 15.3244 20.064H17.7276C17.7276 20.3774 17.7893 20.6876 17.9092 20.9771C18.0292 21.2666 18.2049 21.5297 18.4265 21.7512C18.6481 21.9728 18.9111 22.1486 19.2006 22.2685C19.4901 22.3884 19.8004 22.4501 20.1137 22.4501C20.4271 22.4501 20.7374 22.3884 21.0269 22.2685C21.3164 22.1486 21.5794 21.9728 21.801 21.7512C22.0225 21.5297 22.1983 21.2666 22.3182 20.9771C22.4381 20.6876 22.4998 20.3774 22.4998 20.064H24.903Z"
        fill={color}
      />
    </IconBase>
  );
});

Chatbot.displayName = 'Chatbot';
