import { Stack } from '@mui/material';

import { AutoscalerVisualSvg } from 'assets/svg';

import {
  AgentSetupBlock,
  AgentSetupBlockProps,
} from './_components/AgentSetupBlock';

export const AgentAutoscalerSetup = (props: Partial<AgentSetupBlockProps>) => {
  return (
    <AgentSetupBlock
      heading="Kubernetes automation"
      description="Everything you need to keep your cloud costs and savings in check."
      icon={
        <Stack direction="row">
          <AutoscalerVisualSvg />
        </Stack>
      }
      checkboxTestId="agent-autoscaler-checkbox"
      {...props}
    />
  );
};
