import {
  Params,
  useParams as useParamsFromReactRouter,
} from 'react-router-dom';

// Issue: React Router v6 converts all generic props into optional ones
// https://github.com/remix-run/react-router/issues/8200#issuecomment-1100887996
export const useParams = <T>() =>
  useParamsFromReactRouter() as T extends string
    ? Readonly<{ [K in T]: string }>
    : T extends string[]
    ? Readonly<Record<T[number], string>>
    : T extends Record<string, string>
    ? Readonly<T>
    : Params;
