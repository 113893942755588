import { ForwardedRef } from 'react';

export const setRef = <T>(outerRef: ForwardedRef<T>, el: T) => {
  if (outerRef === null) {
    return;
  }
  if (typeof outerRef === 'object') {
    outerRef.current = el;
  } else {
    outerRef(el);
  }
};
