import { useContext } from 'react';

import { RffControlsGroupContext } from '../context';
import { RffControlsGroupState } from '../types';

type UseControlsGroupState = RffControlsGroupState & { name?: string };

export const useControlsGroupState = (
  state: UseControlsGroupState = {}
): UseControlsGroupState => {
  const ctx = useContext(RffControlsGroupContext);
  if (!ctx) {
    return state;
  }
  const namePrefix = ctx.namePrefix || state.namePrefix;
  return {
    disabled: ctx.disabled || state.disabled,
    readOnly: ctx.readOnly || state.readOnly,
    namePrefix,
    name:
      !!state.name && !!namePrefix ? `${namePrefix}.${state.name}` : state.name,
  };
};
