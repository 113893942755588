import { DateRangeFilter } from 'components/date';

export const defaultFilterPresets: DateRangeFilter[] = [
  DateRangeFilter.ThisMonth,
  DateRangeFilter.Last7Days,
  DateRangeFilter.LastWeek,
  DateRangeFilter.Last2Weeks,
  DateRangeFilter.Last30Days,
  DateRangeFilter.LastMonth,
];

export const shortRangeFilterPresets: DateRangeFilter[] = [
  DateRangeFilter.TODAY,
];

export const workloadsTableKey = 'workloads-cost-report';
export const namespacesTableKey = 'namespaces-cost-report';
