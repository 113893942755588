import { useMatch } from 'react-router-dom';

import { useClusterQuery } from 'hooks/queries/cluster';

export const useSidebarCluster = () => {
  const match = useMatch('/external-clusters/:clusterId/*');
  const clusterId = match?.params?.clusterId;
  const { cluster } = useClusterQuery(clusterId);
  return cluster;
};
