import { useEffect, UIEvent } from 'react';

import { useSearchContext } from '../hooks';

export const useInitialStateReset = <T extends UIEvent = UIEvent>(
  reset?: (event?: T) => void
) => {
  const ctx = useSearchContext();
  useEffect(() => {
    if (ctx && ctx.initialResetTrigger !== undefined) {
      reset?.(
        typeof ctx.initialResetTrigger !== 'number'
          ? (ctx.initialResetTrigger as T)
          : undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx?.initialResetTrigger]);
};
