import {
  ComponentPropsWithRef,
  createContext,
  ElementType,
  forwardRef,
} from 'react';

import { styled } from '@mui/material';
import MUIList, { ListProps as MUIListProps } from '@mui/material/List';

import { shouldForwardProp } from '../../utils/shouldForwardProp';

export type ListProps<C extends ElementType = 'ul'> = MUIListProps<
  C,
  {
    size?: 'small' | 'medium' | 'large';
    component?: C;
    disableHover?: boolean;
    disableSelect?: boolean;
    testId?: string;
  }
>;

export const ListContext = createContext<ListProps | undefined>(undefined);

export const List = styled(
  // eslint-disable-next-line react/display-name
  forwardRef(
    <C extends ElementType = 'ul'>(
      props: ListProps<C>,
      ref: ComponentPropsWithRef<C>['ref']
    ) => {
      const { size, disableSelect, disableHover, testId, ...rest } = props;

      return (
        <ListContext.Provider value={{ size, disableSelect, disableHover }}>
          <MUIList ref={ref} data-testid={testId} {...rest} />
        </ListContext.Provider>
      );
    }
  ),
  {
    name: 'DsList',
    slot: 'Root',
    target: 'DsList-root',
    shouldForwardProp,
    overridesResolver: ({ size }, theme) => {
      return [theme.root, theme[size]];
    },
  }
)({});

List.displayName = 'List';
