import * as Sentry from '@sentry/react';

export type Storage = 'local' | 'session';

const getStorage = (storage: Storage) =>
  window[storage === 'local' ? 'localStorage' : 'sessionStorage'];

export const writeStorage = <T>(
  key: string,
  value: T,
  storage: Storage = 'local'
) => {
  try {
    getStorage(storage).setItem(key, JSON.stringify(value));
  } catch (err) {
    Sentry.captureException(err);
    console.error(err);
  }
};

export const readStorage = <T>(
  key: string,
  initialState: T,
  storage: Storage = 'local'
) => {
  try {
    const persistedStateJson = getStorage(storage).getItem(key);
    if (persistedStateJson) {
      return JSON.parse(persistedStateJson);
    } else {
      if (initialState) {
        writeStorage(key, initialState);
      }
      return initialState;
    }
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    return initialState;
  }
};

export const clearStorageEntries = (
  clear: RegExp | ((key: string) => boolean),
  storage: Storage = 'local'
) => {
  const _storage = getStorage(storage);
  clear =
    typeof clear === 'function'
      ? clear
      : (key: string) => {
          return (clear as RegExp).test(key);
        };
  const keysToDelete: string[] = [];
  for (let i = 0; i < _storage.length; i++) {
    const key = _storage.key(i);
    if (key && clear(key)) {
      keysToDelete.push(key);
    }
  }
  for (const key in keysToDelete) {
    _storage.removeItem(key);
  }
};
