import { forwardRef } from 'react';

import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import { mergeSx } from '../../utils';

export type TooltipProps = MuiTooltipProps & {
  size?: 'small' | 'medium';
  tooltipSx?: SxProps;
  condition?: boolean;
  testId?: string;
  wrapIf?: boolean;
};

export const Tooltip = forwardRef<any, TooltipProps>(
  (
    {
      children,
      color = 'primary',
      size = 'medium',
      PopperProps = {},
      sx,
      tooltipSx,
      title,
      wrapIf = true,
      testId,
      ...props
    }: TooltipProps,
    ref
  ) => {
    if (testId) {
      (PopperProps as any)['data-testid'] = testId;
    }
    if (!wrapIf) {
      return children;
    }
    return (
      <MuiTooltip
        arrow
        {...props}
        PopperProps={{
          ...PopperProps,
          sx: mergeSx(
            sx,
            PopperProps?.sx,
            tooltipSx && { '& .MuiTooltip-tooltip': tooltipSx }
          ),
          className: clsx(
            PopperProps?.className,
            `DS-Tooltip-color-${color}`,
            `DS-Tooltip-size-${size}`
          ),
        }}
        title={title}
        ref={ref}
      >
        {children}
      </MuiTooltip>
    );
  }
);

Tooltip.displayName = 'Tooltip';
