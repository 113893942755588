import { demoClusterPricingNodes } from '@cast/fixtures';

import { PricingApiAdapter } from '../api-client/pricing-api-adapter';
import { isDemoClusterRequest, resolveDemoClusterRequest } from '../utils';

export const PricingApiProxy = (
  origin: ReturnType<typeof PricingApiAdapter>
) => {
  return {
    ...origin,
    getClusterNodesPricing: async (
      ...args: Parameters<typeof origin.getClusterNodesPricing>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterPricingNodes();
        return resolveDemoClusterRequest(fixture);
      }

      return origin.getClusterNodesPricing(...args);
    },
    getNodePricing: async (
      ...args: Parameters<typeof origin.getNodePricing>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoClusterPricingNodes();
        const node = fixture.nodes!.find((n) => n.id === args[0].nodeId);
        return resolveDemoClusterRequest({ node });
      }

      return origin.getNodePricing(...args);
    },
  };
};
