import { CSSProperties, memo, ReactNode } from 'react';

import clsx from 'clsx';
import isEqual from 'lodash/isEqual';

import { BodyCell } from '../_components/Body/_components/BodyCell';
import { isLoadingRow } from '../_components/Body/_hooks/useLoadingRows';
import { useTableContext } from '../hooks/useTableContext';
import { BodyRowProps, RowState } from '../types';

export const BodyRow = memo(
  ({ row, index, onClick, draggableState }: BodyRowProps) => {
    const ctx = useTableContext();
    const api = ctx.api.current;
    const isLoading = isLoadingRow(row);
    const columnModels = api.columnsInfo.columnModels;
    const rowKey = ctx.tableProps.rowKey(row);
    const getExpandedPanel = ctx.tableProps.getExpandedPanel;
    const isExpanded = getExpandedPanel
      ? api.state.expandedRows.includes(rowKey)
      : false;
    const onRowClick = onClick ?? ctx.tableProps.onRowClick;

    const rowState: RowState = {
      row,
      index,
      tableApi: api,
      isExpanded,
      rowKey,
      level: 0,
      draggableState,
    };

    let renderedRow = ctx.tableProps.renderRow?.(row, rowState);
    if (
      isLoading &&
      renderedRow === undefined &&
      ctx.tableProps.loadingRowSkeleton
    ) {
      renderedRow = ctx.tableProps.loadingRowSkeleton;
    }
    const hasRenderedRow = renderedRow !== undefined;
    const bodyCells: ReactNode[] = [];
    if (!hasRenderedRow) {
      let i = 0;
      while (i < columnModels.length) {
        const columnModel = columnModels[i];
        const columnSpan = columnModel.getColumnSpan?.(row, rowState);
        if (columnSpan! > 1) {
          const endColumn = columnModels[i + columnSpan! - 1];
          if (!endColumn) {
            throw new Error(`Returned invalid column span ${columnSpan}`);
          }
          const startOffset = columnModel.startOffset;
          const endOffset = endColumn.endOffset;
          const computedWidth = endOffset - startOffset;
          bodyCells.push(
            <BodyCell
              key={columnModel.id}
              columnModel={{
                ...columnModel,
                startOffset,
                endOffset,
                computedWidth,
              }}
              rowState={rowState}
              style={{
                width: computedWidth,
                minWidth: computedWidth,
                maxWidth: computedWidth,
              }}
            />
          );
          i = i + columnSpan!;
        } else {
          bodyCells.push(
            <BodyCell
              key={columnModel.id}
              columnModel={columnModel}
              rowState={rowState}
            />
          );
          i++;
        }
      }
    }

    const hasExpandedPanel = getExpandedPanel && isExpanded;
    const rowAttributes = ctx.tableProps.getRowAttributes?.(row, rowState);

    let styles: CSSProperties = rowAttributes?.styles || {};
    if (draggableState?.provided?.draggableProps?.style) {
      styles = {
        ...draggableState?.provided?.draggableProps?.style,
        ...styles,
      };
    }

    return (
      <div
        className={clsx(
          'DS-Table-RowWrapper DS-Table-BodyRowWrapper',
          `DS-Table-BodyRowWrapper-${index}`,
          isExpanded && 'DS-Table-rowExpanded',
          index === 0 && 'DS-Table-firstRow',
          index + 1 === api.state.sortedData?.length && 'DS-Table-lastRow',
          rowAttributes?.className,
          ctx.tableProps.getRowClass?.(row, rowState),
          isLoading && 'DS-Table-loadingRow',
          draggableState?.draggableSnapshot?.isClone &&
            'DS-Table-DraggableClone'
        )}
        onClick={(event) => {
          if (!isLoading && onRowClick) {
            onRowClick(event, rowState);
          }
        }}
        data-testid={ctx.tableProps.getRowTestId?.(row, rowState)}
        data-sysid={ctx.tableProps.sysId}
        ref={
          draggableState
            ? (ref) => {
                if (ref) {
                  draggableState.provided.innerRef(ref);
                }
              }
            : undefined
        }
        {...(draggableState?.provided?.draggableProps || {})}
        style={styles}
      >
        {hasRenderedRow ? (
          renderedRow
        ) : (
          <>
            <div
              className={clsx(
                'DS-Table-Row DS-Table-BodyRow',
                onRowClick &&
                  !ctx.tableProps.isLoading &&
                  'DS-Table-ClickableRow'
              )}
              data-sysid={ctx.tableProps.sysId}
            >
              {bodyCells}
            </div>
            <div
              className={clsx(
                'DS-Table-ExpandedPanelWrapper',
                hasExpandedPanel && 'open'
              )}
            >
              {hasExpandedPanel ? getExpandedPanel(row, rowState) : null}
            </div>
          </>
        )}
      </div>
    );
  },
  isEqual
);

BodyRow.displayName = 'BodyRow';
