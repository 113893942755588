import { AxiosInstance } from 'axios';

import { BillingAPIApiFactory } from '@cast/openapi';
import { CheckoutPageConfig } from '@cast/types';

import { AXIOS_CONFIGURATION } from './constants';

export const BillingApiFactory = (client: AxiosInstance) => {
  const generatedApi = BillingAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    getSubscription: generatedApi.subscription,

    getPortalHostedPage: (forwardUrl?: string) =>
      generatedApi.billingSso(
        { forwardUrl },
        {
          headers: {
            Accept: 'application/json',
          },
        }
      ),

    getCheckoutHostedPage: (config?: CheckoutPageConfig) =>
      generatedApi.subscriptionCheckout(config, {
        headers: {
          Accept: 'application/json',
        },
      }),

    getBillingReport: generatedApi.billingAPIGetUsageReport,
  };
};
