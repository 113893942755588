import { forwardRef } from 'react';

import { Box, Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';

import { TendencyIcon, TendencyIconProps } from '../../TendencyIcon';

type TendencyIndicatorProps = TendencyIconProps & {
  value: string;
  valueProps?: TypographyProps;
};

export const TendencyIndicatorBase = forwardRef<
  HTMLDivElement,
  TendencyIndicatorProps
>((props: TendencyIndicatorProps, ref) => {
  const { value, className, valueProps, ...iconProps } = props;

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      component="span"
      className={clsx(`Tendency-${props.tendency}`, className)}
      ref={ref}
    >
      <TendencyIcon className="TendencyIndicator-icon" {...iconProps} />
      <Typography
        className="TendencyIndicator-label"
        component="span"
        ml={`${(iconProps.size ?? 14) / 3}px`}
        color="grey.900"
        variant="P14R"
        {...valueProps}
      >
        {value}
      </Typography>
    </Box>
  );
});

TendencyIndicatorBase.displayName = 'TendencyIndicatorBase';
