import { forwardRef, ReactElement, ReactNode, useEffect } from 'react';

import { styled } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import clsx from 'clsx';

import { useMergedRef } from '@cast/utils';

import { Input, InputProps } from '../input';

export type TextAreaProps = {
  icon?: ReactNode | ReactElement;
  readOnly?: boolean;
  disableResize?: boolean;
  responsive?: boolean;
  minRows?: number;
  maxRows?: number;
  size?: 'small' | 'medium';
} & Omit<InputProps, 'multiline' | 'size' | 'icon'>;

const StyledInput = styled(Input, {
  name: 'DsTextArea',
  slot: 'Root',
  overridesResolver: ({ size }, styles) => {
    return [styles.root, styles[size]];
  },
})({});
StyledInput.displayName = 'StyledInput';

const TextAreaInput = forwardRef<any, any>(
  // Prevent warning in console
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ ownerState, ...props }, ref) => {
    return <TextareaAutosize {...props} ref={ref} />;
  }
);
TextAreaInput.displayName = 'TextAreaInput';

const TextArea = forwardRef<HTMLDivElement, TextAreaProps>(
  (
    {
      className,
      label,
      labelTooltipProps,
      icon,
      success,
      readOnly,
      hint,
      error,
      disableResize = true,
      responsive = false,
      minRows = 2,
      maxRows = 2,
      size = 'medium',
      sx,
      InputProps,
      ...props
    }: TextAreaProps,
    ref
  ) => {
    const [textFieldEl, setRef] = useMergedRef(ref);

    useEffect(() => {
      if (textFieldEl) {
        const { width } = textFieldEl.getBoundingClientRect();
        const textAreas = textFieldEl.getElementsByTagName('textarea');
        for (const textArea of textAreas) {
          textArea.style.width = `${width}px`;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!textFieldEl]);

    useEffect(() => {
      if (responsive && !!textFieldEl) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            const width = entry.contentRect.width;
            const textAreas = textFieldEl.getElementsByTagName('textarea');
            for (const textArea of textAreas) {
              textArea.style.width = `${width}px`;
            }
          }
        });

        if (textFieldEl) {
          resizeObserver.observe(textFieldEl);
        }

        return () => {
          if (textFieldEl) {
            resizeObserver.unobserve(textFieldEl);
          }
        };
      }
    }, [responsive, textFieldEl]);

    return (
      <StyledInput
        ref={setRef}
        className={clsx(
          className,
          'DS-TextArea-root',
          `DS-Input-size-${size}`,
          readOnly && 'DS-Input-readOnly'
        )}
        size={size}
        label={label}
        labelTooltipProps={labelTooltipProps}
        // Required for placeholder to be visible if input is empty and has no adornments
        hint={error || hint}
        error={!!error}
        multiline
        inputProps={{ className: clsx(disableResize && 'DS-disableResize') }}
        InputProps={{
          readOnly,
          className: clsx(
            success && 'DS-success',
            readOnly && `DS-Input-readOnly`,
            `DS-TextArea-size-${size}`
          ),
          minRows,
          maxRows,
          endAdornment: icon ? (
            <div className="DS-TextArea-adornment-root">{icon}</div>
          ) : undefined,
          components: { Input: TextAreaInput },
          sx,
          ...InputProps,
        }}
        {...props}
      />
    );
  }
);
TextArea.displayName = 'TextArea';

export default TextArea;
