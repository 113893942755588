import { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { Card, ShadowDivider } from '@cast/design-system';
import { getCurrencyPrecision } from '@cast/utils';

import { Money } from 'components/money';
import { BarSkeletons } from 'components/skeletons';
import { InfoTooltip } from 'components/tooltip';
import { TourStep } from 'core/tour';
import { isStorageReportsSupported } from 'features/cluster/utils';
import { CostReportChart } from 'features/cost-report/components/chart/CostReportChart';
import { LargeMetricsBox } from 'features/cost-report/components/cost-over-time/LargeMetricsBox';
import { useCluster } from 'hooks/useCluster';
import { ComputeSpendType, computeSpendColorsMap } from 'types/cost-report';
import { generateEmptyTimeSeries } from 'utils/data/generateEmptyTimeSeries';

import { ComputeSpendControls, ComputeSpendTooltip } from './_components';
import {
  useComputeSpendChart,
  useComputeSpendControls,
  useCumulativeChartData,
  useDailyCostChartData,
} from './_hooks';
import { ReportSection } from '../../../cost-report/components/ReportSection';
import { useCostOverTime } from '../../../cost-report/hooks/useCostOverTime';
import { useCostReportContext } from '../../../cost-report/hooks/useCostReportContext';
import { ComputeSpendMode } from '../../../cost-report/types/costOverTime';
import { costOverTimeTourId, tourSteps } from '../tourSteps';

const chartDataMap: Record<
  ComputeSpendMode,
  typeof useDailyCostChartData | typeof useCumulativeChartData
> = {
  [ComputeSpendMode.DAILY_COST]: useDailyCostChartData,
  [ComputeSpendMode.CUMULATIVE]: useCumulativeChartData,
};

type ComputeSpendSectionProps = {
  showStorage?: boolean;
};

export const ComputeSpendSection = ({
  showStorage: _showStorage = true,
}: ComputeSpendSectionProps) => {
  const { cluster } = useCluster();
  const isStorageSupported = isStorageReportsSupported(cluster);
  const showStorage = isStorageSupported && _showStorage;

  const config = useComputeSpendChart(showStorage);
  const { chartMode } = useComputeSpendControls();
  const { isDefaultFilter, chartType, currentDateRange } =
    useCostReportContext();
  const { isCollecting, aggregatedMetrics, dynamicCopiesMap } =
    useCostOverTime();
  const { data } = chartDataMap[chartMode]();

  const emptyData = useMemo(
    () => generateEmptyTimeSeries({ range: currentDateRange }),
    [currentDateRange]
  );

  const LOADING_MONEY = '$--.--';

  const computeCost = aggregatedMetrics.computeCost;

  const mainCost = isDefaultFilter
    ? computeCost.todayProjected
    : computeCost.rangeSoFar;

  const onDemandCost = isDefaultFilter
    ? computeCost.onDemandProjected
    : computeCost.onDemandRangeSoFar;

  const fallbackCost = isDefaultFilter
    ? computeCost.fallbackProjected
    : computeCost.fallbackRangeSoFar;

  const spotCost = isDefaultFilter
    ? computeCost.spotProjected
    : computeCost.spotRangeSoFar;

  const storageCost = isDefaultFilter
    ? computeCost.storageProjected
    : computeCost.storageRangeSoFar;

  const mainCostPrecision = getCurrencyPrecision(mainCost);
  const onDemandCostPrecision = getCurrencyPrecision(onDemandCost);
  const fallbackCostPrecision = getCurrencyPrecision(fallbackCost);
  const spotCostPrecision = getCurrencyPrecision(spotCost);
  const storageCostPrecision = getCurrencyPrecision(storageCost);

  return (
    <ReportSection title="Compute spend" testId="compute-spend-section">
      <Box mt="12px">
        <TourStep
          step={tourSteps.computeSpendChart}
          type={costOverTimeTourId}
          text="Check the actual cluster compute spend in the selected period and cost distribution by resource offering over time."
          highlight="shadow"
          placement="top"
        >
          <Card sx={{ p: 0 }} className="tour-highlight">
            <Box
              display="flex"
              justifyContent="space-between"
              py="12px"
              px="16px"
            >
              <Box flex={1}>
                <LargeMetricsBox
                  testId="total-compute-spend"
                  title={
                    <Stack direction="row" alignItems="center" gap="4px">
                      {isDefaultFilter
                        ? dynamicCopiesMap.computeSpend.mainTitle
                        : 'Total compute spend'}
                      {isDefaultFilter && (
                        <InfoTooltip
                          title={dynamicCopiesMap.computeSpend.mainTooltip}
                          placement="top"
                          iconSize="14px"
                        />
                      )}
                    </Stack>
                  }
                  primaryValue={
                    isCollecting ? (
                      LOADING_MONEY
                    ) : (
                      <Money
                        amount={mainCost}
                        precision={mainCostPrecision}
                        fixed={mainCostPrecision}
                      />
                    )
                  }
                  primaryValueProps={{
                    variant: 'h5',
                    lineHeight: '28px',
                  }}
                />
              </Box>
              <Stack flex={1} direction="row" justifyContent="space-between">
                <Box width="100%">
                  <LargeMetricsBox
                    testId="on-demand-compute-spend"
                    markerColor={
                      computeSpendColorsMap[ComputeSpendType.ON_DEMAND]
                    }
                    title="On-demand spend"
                    primaryValue={
                      isCollecting ? (
                        LOADING_MONEY
                      ) : (
                        <Money
                          amount={onDemandCost}
                          precision={onDemandCostPrecision}
                          fixed={onDemandCostPrecision}
                          dashesIfUndefined
                        />
                      )
                    }
                  />
                </Box>

                <Box width="100%">
                  <LargeMetricsBox
                    testId="fallback-compute-spend"
                    markerColor={
                      computeSpendColorsMap[ComputeSpendType.FALLBACK]
                    }
                    title={
                      <Stack direction="row" alignItems="center" gap="4px">
                        fallback spend
                        <InfoTooltip
                          title="Temporary on-demand node while spot is not available"
                          placement="bottom"
                        />
                      </Stack>
                    }
                    primaryValue={
                      isCollecting ? (
                        LOADING_MONEY
                      ) : (
                        <Money
                          amount={fallbackCost}
                          precision={fallbackCostPrecision}
                          fixed={fallbackCostPrecision}
                          dashesIfUndefined
                        />
                      )
                    }
                  />
                </Box>
                <Box width="100%">
                  <LargeMetricsBox
                    testId="spot-compute-spend"
                    markerColor={computeSpendColorsMap[ComputeSpendType.SPOT]}
                    title="spot spend"
                    primaryValue={
                      isCollecting ? (
                        LOADING_MONEY
                      ) : (
                        <Money
                          amount={spotCost}
                          precision={spotCostPrecision}
                          fixed={spotCostPrecision}
                          dashesIfUndefined
                        />
                      )
                    }
                  />
                </Box>
                {showStorage && (
                  <Box width="100%">
                    <LargeMetricsBox
                      testId="storage-compute-spend"
                      markerColor={
                        computeSpendColorsMap[ComputeSpendType.STORAGE]
                      }
                      title="storage spend"
                      primaryValue={
                        isCollecting ? (
                          LOADING_MONEY
                        ) : (
                          <Money
                            amount={storageCost}
                            precision={storageCostPrecision}
                            fixed={storageCostPrecision}
                            dashesIfUndefined
                          />
                        )
                      }
                    />
                  </Box>
                )}
              </Stack>
            </Box>
            <Box borderTop="grey.100">
              <ComputeSpendControls showStorage={showStorage} />
              <ShadowDivider
                opacity={0.2}
                color="grey.200"
                direction="bottom"
                orientation="horizontal"
              />
            </Box>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="L10B" pl="19px">
                {chartMode === ComputeSpendMode.DAILY_COST
                  ? 'Daily cost'
                  : 'Cumulative cost'}
              </Typography>
            </Stack>
            {isCollecting ? (
              <BarSkeletons height={240} />
            ) : (
              <CostReportChart
                chartType={chartType}
                TooltipComponent={(props) => (
                  <ComputeSpendTooltip {...props} showStorage={showStorage} />
                )}
                data={data?.length > 0 ? data : emptyData}
                height={240}
                config={config}
                testId="compute-spend-chart"
              />
            )}
          </Card>
        </TourStep>
      </Box>
    </ReportSection>
  );
};
