import { useContext } from 'react';

import { Stack, Typography } from '@mui/material';

import { Button, Icons } from '@cast/design-system';

import { TourContext } from '../providers';

type Props = {
  callback?: () => void;
};

export const TourFooter = ({ callback }: Props) => {
  const { setIsActive, steps, nextStep, isLastStep, finishTour, stepIndex } =
    useContext(TourContext);

  if (!steps?.length || typeof stepIndex === 'undefined') {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Button
        variant="text"
        onClick={(event: MouseEvent) => {
          event.stopPropagation();
          setIsActive(false);
        }}
        sx={{
          color: 'aqua.300',
          opacity: 0.4,
          px: 0,
          typography: 'P12M',

          '&:hover': {
            opacity: 1,
            color: 'aqua.100',
          },

          '&:active': {
            color: 'aqua.600',
          },
        }}
        testId="skip-btn"
      >
        Skip tour
      </Button>
      <Stack direction="row" alignItems="center" gap="4px">
        <Typography variant="P12R" color="white">
          <span data-testid="step-index">{stepIndex + 1}</span>/
          <span data-testid="steps-count">{steps.length}</span>
        </Typography>

        {isLastStep ? (
          <Button
            variant="text"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              finishTour();
              callback?.();
            }}
            sx={{
              color: 'aqua.300',
              typography: 'P12M',

              '& .MuiButton-endIcon': {
                marginLeft: 0,
              },

              '&:hover': {
                color: 'aqua.100',
              },
              '&:active': {
                color: 'aqua.600',
              },
            }}
            testId="done-btn"
          >
            Done
          </Button>
        ) : (
          <Button
            variant="text"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              nextStep();
              callback?.();
            }}
            endIcon={<Icons.ArrowRight />}
            sx={{
              color: 'aqua.300',
              typography: 'P12M',

              '& .MuiButton-endIcon': {
                marginLeft: 0,
              },
              '&:hover': {
                color: 'aqua.100',
              },
              '&:active': {
                color: 'aqua.600',
              },
            }}
            testId="next-btn"
          >
            Next
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
