import { ThemeOptions } from '@mui/material';

import { DsShadow } from '../../../theme/shadows';

export const chartTooltipTheme: ThemeOptions['components'] = {
  DsChartTooltip: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 300,
        height: 'auto',
        background: 'white',
        borderRadius: '4px',
        border: 'none',
        boxShadow: theme.shadows[DsShadow.TOOLTIP],
      }),
      header: ({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.grey[400],
        padding: '8px 20px',
      }),
      footer: ({ theme }) => ({
        padding: '8px 20px',
        border: `1px solid ${theme.palette.grey[100]}`,
      }),
      large: {
        '& .DsChartTooltip-Header': {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
        },
      },
      medium: () => ({
        '& .DsChartTooltip-Header': {
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '18px',
        },
      }),
      small: () => ({
        '& .DsChartTooltip-Header': {
          fontSize: '10px',
          fontWeight: 500,
          lineHeight: '12px',
        },
      }),
      disabled: () => ({
        position: 'relative',

        '&:after': {
          content: "''",
          position: 'absolute',
          display: 'block',
          width: '100%',
          height: '100%',
          background: 'rgba(255,255,255,0.6)',
          top: 0,
          left: 0,
          borderRadius: '4px',
        },
      }),
    },
  },
};
