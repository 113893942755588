import { AxiosInstance } from 'axios';

import { UsageAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const ReportsApiAdapter = (client: AxiosInstance) => {
  const generatedApi = UsageAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    getCpuUsageReport: generatedApi.usageAPIGetUsageReport,
    getCpuUsageSummary: generatedApi.usageAPIGetUsageSummary,
  };
};
