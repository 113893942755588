import { DrawerReducerState } from './types';

export const findParentDrawerIds = (
  id: string,
  drawers: Array<DrawerReducerState['drawers']['string']>,
  nested: string[] = []
) => {
  const child = drawers.find((d) => d.id === id);

  if (child && child.parentId) {
    nested.push(child.parentId);
    findParentDrawerIds(child.parentId, drawers, nested);
  }
  return nested;
};

export const findChildrenDrawerIds = (
  id: string,
  drawers: Array<DrawerReducerState['drawers']['string']>,
  nested: string[] = []
) => {
  const parent = drawers.find((d) => d.parentId === id);

  if (parent) {
    nested.push(parent.id);
    findChildrenDrawerIds(parent.id, drawers, nested);
  }
  return nested;
};
