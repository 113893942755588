import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import Big from 'big.js';

import {
  AvailableSavingsNode,
  AvailableSavingsRecommendations,
} from '@cast/types';
import { groupUniqueNodesWithCount } from '@cast/utils';

import { useAbility } from 'core/ability';
import { clusterEvents } from 'core/analytics';
import { useCurrentPrice } from 'features/cluster/available-savings/_hooks/useCurrentPrice';
import { useCluster } from 'hooks/useCluster';
import { usePersistentState } from 'hooks/usePersistentState';
import { isEKSCluster, isGKECluster } from 'types/cluster';

import { useAvailableSavingsCurrentConfiguration } from '../_hooks/useAvailableSavingsCurrentConfiguration';
import { useAvailableSavingsRecommendations } from '../_hooks/useAvailableSavingsRecommendations';
import { nonNegativeGuard } from '../utils';

type ArmSupportState = {
  isNegativeSavings: boolean;
  enabled: boolean;
  toggle: () => void;
  setArmCpuPercentage: (v: number) => void;
  armCpuPercentage: number;
  monthlyPrice: number;
  savingsPercentage: number;
  optimalNodes: AvailableSavingsNode[];
  sectionSavings: number;
  availableSavingsPercentage: number;
};

const Context = createContext<ArmSupportState>(undefined as never);

const getRecommendationsData = (
  armCpuPercentage: number,
  details?: AvailableSavingsRecommendations
) => {
  const priceBefore = nonNegativeGuard(details?.armSavingsMonthly?.priceBefore);
  const priceAfter = nonNegativeGuard(details?.armSavingsMonthly?.priceAfter);
  const priceAfterWithoutArm = nonNegativeGuard(details?.monthly?.priceAfter);
  const invertedArmCpuPercentage = 100 - armCpuPercentage;
  const priceDiffRatio = Big(priceAfterWithoutArm).sub(priceAfter).div(100);

  const monthlyPriceWithAppliedArmCpuPercentage = Big(priceAfter)
    .add(priceDiffRatio.mul(invertedArmCpuPercentage))
    .toNumber();

  const isNegativeSavings =
    priceBefore < monthlyPriceWithAppliedArmCpuPercentage;
  const savings = !isNegativeSavings
    ? priceBefore - monthlyPriceWithAppliedArmCpuPercentage
    : 0;
  const savingsPercentage = nonNegativeGuard((savings / priceBefore) * 100);

  return {
    priceDiffRatio,
    monthlyPrice: monthlyPriceWithAppliedArmCpuPercentage,
    isNegativeSavings,
    savingsPercentage,
  };
};

export const ArmSupportProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const { cluster } = useCluster();
  const canViewArmAvailableSavings = useAbility().can(
    'view',
    'FeatureClusterCostMonitoringArmSavings'
  );
  const recommendations = useAvailableSavingsRecommendations();
  const currentCtx = useAvailableSavingsCurrentConfiguration();

  const [enabled, setEnabled] = usePersistentState<boolean>(
    `available-savings.arm-support.enabled-${cluster.id}`,
    false
  );
  const [armCpuPercentage, setArmCpuPercentage] = usePersistentState<number>(
    `available-savings.arm-support.cpuPercentage-${cluster.id}`,
    100
  );

  const [
    isNegativeSavings,
    sectionSavings,
    monthlyPrice,
    savingsPercentage,
    optimalNodes,
  ] = useMemo(() => {
    const basePrice = recommendations?.monthly?.priceAfter;
    const armPrice = recommendations?.armSavingsMonthly?.priceAfter;

    let sectionSavings = 0;
    if (basePrice && armPrice) {
      sectionSavings = Math.max(
        Big(basePrice).sub(armPrice).mul(armCpuPercentage).div(100).toNumber(),
        0
      );
    }

    const { isNegativeSavings, monthlyPrice, savingsPercentage } =
      getRecommendationsData(armCpuPercentage, recommendations);

    let optimalNodes: AvailableSavingsNode[] = currentCtx.nodes;
    if (!isNegativeSavings) {
      optimalNodes = groupUniqueNodesWithCount(
        recommendations?.details?.armSavingsConfiguration?.nodes || []
      );
    }

    return [
      isNegativeSavings,
      sectionSavings,
      monthlyPrice,
      savingsPercentage,
      optimalNodes,
    ];
  }, [recommendations, currentCtx.nodes, armCpuPercentage]);

  const toggle = () => {
    setEnabled(!enabled);
    clusterEvents.toggledRecommendedArm(cluster, !enabled);
  };

  const { currentMonthlyPrice } = useCurrentPrice();
  const availableSavingsPercentage = nonNegativeGuard(
    (sectionSavings / currentMonthlyPrice) * 100
  );

  return (
    <Context.Provider
      value={{
        enabled:
          !canViewArmAvailableSavings ||
          (!isEKSCluster(cluster) && !isGKECluster(cluster))
            ? false
            : enabled,
        toggle,
        isNegativeSavings,
        setArmCpuPercentage: (percentage: number) => {
          if (percentage > 100) {
            percentage = 100;
          } else if (percentage < 0) {
            percentage = 0;
          }
          setArmCpuPercentage(percentage);
        },
        armCpuPercentage,
        sectionSavings,
        monthlyPrice,
        savingsPercentage,
        optimalNodes,
        availableSavingsPercentage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAvailableSavingsArmSupport = () => {
  return useContext(Context);
};
