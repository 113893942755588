import { MutableRefObject } from 'react';

import { getColumnWidths } from './_utils';
import { processColumn } from './processColumn';
import { processColumnGroup } from './processColumnGroup';
import {
  ColumnProps,
  GroupedColumnProps,
  isColumnsGroup,
  TableApi,
} from '../../../types';
import { applyColumnSettings } from '../../../utils/applyColumnSettings';

export const initializeColumns = (
  tableApi: MutableRefObject<TableApi>,
  groupedColumnsProps: GroupedColumnProps[],
  containerWidth?: number
) => {
  const columnsProps: ColumnProps[] = [];
  for (const props of groupedColumnsProps) {
    if (isColumnsGroup(props)) {
      columnsProps.push(...props.columns);
    } else {
      columnsProps.push(props);
    }
  }

  const { columnWidths, tableWidth } = getColumnWidths(
    columnsProps,
    containerWidth,
    tableApi.current.state.columnSettings
  );

  tableApi.current.columnsInfo = {
    columnsProps,
    groupedColumnsProps,
    groupModels: [],
    columnModels: [],
    tableWidth,
    columnStyles: {},
    columnWidths,
    lockedColumnsWidth: 0,
    scrollableColumnsWidth: 0,
    hasFooter: false,
  };

  const propsWithSettingsApplied = applyColumnSettings({
    groupedColumnsProps,
    ...tableApi.current.state.columnSettings,
  });

  for (const column of propsWithSettingsApplied) {
    if (isColumnsGroup(column)) {
      processColumnGroup({
        groupProps: column,
        info: tableApi.current.columnsInfo,
      });
    } else {
      processColumn({
        columnProps: column,
        info: tableApi.current.columnsInfo,
      });
    }
  }
};
