import { useEffect, useState } from 'react';

export const usePeripheralsHeight = (root: HTMLElement | null) => {
  const [peripheralsHeight, setPeripheralsHeight] = useState(0);

  useEffect(() => {
    if (!root) {
      return;
    }
    const elements = root.querySelectorAll('[data-peripheral]');

    const getElementsHeight = () => {
      let height = 0;
      elements.forEach((el) => {
        const elHeight = el.getBoundingClientRect().height;
        height += elHeight;
      });
      return height;
    };

    if (elements.length) {
      setPeripheralsHeight(getElementsHeight());
    }

    const observer = new ResizeObserver(() =>
      setPeripheralsHeight(getElementsHeight())
    );
    elements.forEach((el) => observer.observe(el));
    return () => observer.disconnect();
  }, [root]);

  return peripheralsHeight;
};
