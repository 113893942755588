import { Outlet } from 'react-router-dom';

import { useAbility, Subjects } from 'core/ability';

import { Navigate } from './Navigate';

type Props = {
  subject: Subjects;
};

export const ProtectedRoute = ({ subject }: Props) => {
  const rule = useAbility().relevantRuleFor('view', subject);

  if (rule?.inverted) {
    console.warn(rule.reason);
    const disabledByFeatureFlag = rule.reason?.includes('is not enabled');

    return (
      <Navigate
        to={disabledByFeatureFlag ? '/' : '/forbidden'}
        state={{ message: rule.reason }}
        replace
      />
    );
  }

  return <Outlet />;
};
