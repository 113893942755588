import { styled } from '@mui/material';

import { Paper, PaperProps } from '../paper';

export type CardProps = PaperProps;

export const Card = styled(Paper)<CardProps>(({ theme }) => ({
  [`.${theme.constants.sidebarMinimizedClass} &`]: {
    '@media (max-width: 1366px)': {
      padding: '0px 12px',
    },
  },

  [`.${theme.constants.sidebarMaximizedClass} &`]: {
    '@media (max-width: 1680px)': {
      padding: '0px 20px',
    },
    '@media (max-width: 1440px)': {
      padding: '0px 16px',
    },
  },

  padding: '0px 24px',
  width: '100%',
})) as typeof Paper;
