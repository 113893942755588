import { ThemeOptions } from '@mui/material/styles/createTheme';

import { DsShadow } from '../../../theme/shadows';

export const paperTheme: ThemeOptions['components'] = {
  DsPaper: {
    styleOverrides: {
      root: ({ theme, ...props }) => ({
        boxShadow: theme.shadows[DsShadow.SHADOW],
        '--backgroundColor': 'white',
        backgroundColor: 'white',
        borderRadius: 4,
        '&.Ds-disabled': {
          boxShadow: theme.shadows[DsShadow.NONE],
          backgroundColor: theme.palette.grey[50],
        },
        '&:hover': props.hoverable
          ? { boxShadow: theme.shadows[DsShadow.HOVER] }
          : undefined,
      }),
    },
  },
};
