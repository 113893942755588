import { forwardRef } from 'react';

import IconBase, {
  RenderFunction,
  IconWeight,
  IconProps,
  PaintFunction,
  renderPathForWeight,
} from '../IconBase';

const pathsByWeight = new Map<IconWeight, PaintFunction>();

pathsByWeight.set('light', (color: string) => (
  <>
    <path
      d="M11 9L16.5 3L22 9"
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 23L16.5 29L11 23"
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
));

pathsByWeight.set('regular', (color: string) => (
  <>
    <path
      d="M11 9L16.5 3L22 9"
      stroke={color}
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 23L16.5 29L11 23"
      stroke={color}
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
));

const renderPath: RenderFunction = (weight: IconWeight, color: string) =>
  renderPathForWeight(weight, color, pathsByWeight);

export const NumberSwitch = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <IconBase ref={ref} {...props} renderPath={renderPath} />
);

NumberSwitch.displayName = 'NumberSwitch';
