import { stringify } from 'query-string';

import { getQueryParam } from '@cast/utils';

import { AUTH_PROVIDER_QUERY_KEY } from 'common/constants';
import { getScreenHintParam } from 'utils/auth/getScreenHint';

export const getRedirectToScreenUrl = (returnTo: string) => {
  const authProvider = getQueryParam(AUTH_PROVIDER_QUERY_KEY);
  let queryParams: Record<string, string> = {
    returnTo: returnTo || window.location.href,
    screenHint: getScreenHintParam(),
  };

  if (authProvider && ['google-oauth2', 'github'].includes(authProvider)) {
    queryParams = {
      'auth-provider': authProvider,
    };
  }

  return `${window.location.origin}/api/v1/auth?${stringify(queryParams, {
    skipEmptyString: true,
  })}`;
};

export const redirectToScreen = (returnTo: string) => {
  window.location.replace(getRedirectToScreenUrl(returnTo));
};
