import { PropsWithChildren, useRef } from 'react';

import isEqual from 'lodash/isEqual';

import { SerializableObject } from '@cast/types';

import { TablePersistence } from './context';
import {
  PersistenceDriver,
  SerializableGroupedColumn,
  SerializableTableState,
  TableStateConverter,
  TableStateMigrator,
} from './types';
import { getSerializableColumns } from './utils';
import { persistenceDrivers } from '../data-table/persistence/drivers';
import { makeStateConverter } from '../data-table/persistence/stateConverters';
import { TableApi, TableState } from '../types';

export type PersistenceProviderProps = {
  id?: string;
  persistenceDriver?: PersistenceDriver;
  stateConverter?: TableStateConverter;
  stateMigrator?: TableStateMigrator;
};

export const PersistenceProvider = ({
  id,
  persistenceDriver = persistenceDrivers.local,
  stateConverter = makeStateConverter({
    columnResizings: true,
    columnVisibility: true,
    columnsOrder: true,
    columnLocking: true,
  }),
  stateMigrator,
  children,
}: PropsWithChildren<PersistenceProviderProps>) => {
  const stateWasAccepted = useRef(false);
  const stateWasRetrieved = useRef(false);

  if (!id || !persistenceDriver || !stateConverter) {
    return <>{children}</>;
  }

  const layoutColumnsKey = `${id}-layout-columns`;

  const accept = (api: TableApi) => {
    if (!stateWasAccepted.current) {
      persistenceDriver.accept(
        layoutColumnsKey,
        getSerializableColumns(
          api.columnsInfo.groupedColumnsProps
        ) as unknown as SerializableObject
      );
      stateWasAccepted.current = true;
    }
    persistenceDriver.accept(
      id,
      stateConverter.toSerializable(api.state) as SerializableObject
    );
  };

  const migrateStateIfNeeded = (
    api: TableApi,
    savedState: SerializableTableState
  ): TableState | undefined => {
    if (stateWasRetrieved.current || !stateMigrator) {
      return;
    }
    const lastSavedLayout =
      persistenceDriver.retrieve<SerializableGroupedColumn[]>(layoutColumnsKey);
    if (!lastSavedLayout) {
      return;
    }
    const currentLayout = getSerializableColumns(
      api.columnsInfo.groupedColumnsProps
    );
    if (isEqual(lastSavedLayout, currentLayout)) {
      return;
    }
    return stateMigrator({
      lastSavedLayout,
      currentLayout,
      savedState,
      currentTableApi: api,
    });
  };

  return (
    <TablePersistence.Provider
      value={{
        accept,
        retrieve: (api) => {
          const retrievedState =
            persistenceDriver.retrieve<SerializableTableState>(id);
          stateWasRetrieved.current = true;
          if (!retrievedState) {
            return api.state;
          }
          const migratedState = migrateStateIfNeeded(api, retrievedState);
          if (migratedState) {
            return migratedState;
          }
          const { state, requiresReset } = stateConverter.toTable(
            retrievedState,
            api
          );
          if (requiresReset) {
            accept({ ...api, state });
          }
          return state;
        },
      }}
    >
      {children}
    </TablePersistence.Provider>
  );
};
