/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * - resource_affected: Resource is affected. It fails this check.  - resource_excepted: Resource is excepted. It failed this check, but there is a matching exception.  - resource_unaffected: Resource is unaffected. It passes this check.
 * @export
 * @enum {string}
 */

export const InsightsV1CheckClusterResourceStatus = {
    affected: 'resource_affected',
    excepted: 'resource_excepted',
    unaffected: 'resource_unaffected'
} as const;

export type InsightsV1CheckClusterResourceStatus = typeof InsightsV1CheckClusterResourceStatus[keyof typeof InsightsV1CheckClusterResourceStatus];



