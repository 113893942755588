import { forwardRef } from 'react';

import { styled, useTheme } from '@mui/material';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';

import { Spinner } from '../feedback';
import { IconContext } from '../icons';

export type IconButtonProps = ButtonBaseProps<
  'button',
  {
    loading?: boolean;
    variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'danger';
    size?: 'large' | 'medium' | 'small';
    testId?: string;
  }
>;

const IconButton = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ testId, ...props }: IconButtonProps, ref) => {
      const theme = useTheme();
      const _props = {
        ...(theme.components?.DsIconButton?.defaultProps ?? {}),
        ...props,
      };
      const { children, loading, disabled, ...rest } = _props;

      return (
        <IconContext.Provider
          value={{
            size: '1em',
            weight: props.size === 'small' ? 'regular' : 'bold',
          }}
        >
          <ButtonBase
            ref={ref}
            data-testid={testId}
            {...rest}
            disabled={disabled || loading}
          >
            {loading ? (
              <span className="DsIconButton-Spinner">
                <Spinner size={props.size === 'small' ? '16px' : '20px'} />
              </span>
            ) : (
              children
            )}
          </ButtonBase>
        </IconContext.Provider>
      );
    }
  ),
  {
    name: 'DsIconButton',
    slot: 'Root',
    target: 'DsIconButton-root',
    overridesResolver: ({ variant, size, disabled, loading }, theme) => {
      return [
        theme.root,
        theme[variant],
        theme[size],
        disabled || loading ? theme.disabled : {},
        loading ? theme.loading : {},
      ];
    },
  }
)<IconButtonProps>``;

IconButton.displayName = 'IconButton';

export default IconButton;
