import dayjs from 'dayjs';
import isSafeInteger from 'lodash/isSafeInteger';

import { ConvertableToDateTime } from '@cast/types';

export const getDateType = (
  timestamp: ConvertableToDateTime
): 'date' | 'unix' | 'iso' => {
  if (typeof timestamp === 'number') {
    return 'unix';
  }

  if (timestamp instanceof Date) {
    return 'date';
  }

  const _timestamp = +timestamp;
  if (isSafeInteger(_timestamp)) {
    return 'unix';
  }

  return 'iso';
};

export const anyTimestampToUnix = (timestamp: ConvertableToDateTime) => {
  const type = getDateType(timestamp);

  switch (type) {
    case 'date':
      return dayjs(timestamp).unix();
    case 'unix':
      return +timestamp;
    case 'iso':
      return dayjs(timestamp).unix();
  }
};

export const convertToDateType = (
  timestamp: ConvertableToDateTime,
  type: 'date' | 'unix' | 'iso'
) => {
  const _unix = anyTimestampToUnix(timestamp);

  switch (type) {
    case 'date':
      return dayjs.unix(_unix).toDate();
    case 'unix':
      return _unix;
    case 'iso':
      return dayjs.unix(_unix).toISOString();
  }
};
