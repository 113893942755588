import { withProps } from '@cast/design-system';

import { MetricsBox } from 'components/boxes';

export const LargeMetricsBox = withProps(MetricsBox, {
  sx: {
    height: '41px',
  },
  valuesContainerProps: {
    height: '100%',
    justifyContent: 'space-between',
  },
  markerProps: {
    width: '2px',
    minHeight: '41px',
    marginRight: '12px',
    color: 'default',
  },
  titleProps: {
    variant: 'L10R',
    lineHeight: '12px',
    noWrap: true,
  },
  primaryValueProps: {
    variant: 'h6',
    lineHeight: '24px',
    fontWeight: 700,
    noWrap: true,
  },
});
