import { forwardRef, isValidElement, useContext } from 'react';

import { styled, Typography } from '@mui/material';
import MuiListItemText, {
  ListItemTextProps as MuiListItemTextProps,
} from '@mui/material/ListItemText';

import { ListContext } from './List';

export type ListItemTextProps = MuiListItemTextProps;

const ListItemTextRoot = styled(MuiListItemText, {
  name: 'DsListItemText',
  slot: 'Root',
  target: 'DsListItemText-root',
  overridesResolver: (_, theme) => {
    return [theme.root];
  },
})<ListItemTextProps>({});

export const ListItemText = forwardRef<HTMLDivElement, ListItemTextProps>(
  (props, ref) => {
    const listContext = useContext(ListContext);

    const {
      children,
      disableTypography = false,
      primary: primaryProp,
      primaryTypographyProps,
      secondary: secondaryProp,
      secondaryTypographyProps,
      ...other
    } = props;

    const size = listContext?.size ?? 'medium';
    const primary = primaryProp != null ? primaryProp : children;
    const secondary = secondaryProp || null;

    let computedPrimaryTypographyProps: typeof primaryTypographyProps = {};
    if (
      primary &&
      primary &&
      isValidElement(primary) &&
      primary.type !== Typography &&
      !disableTypography
    ) {
      if (size === 'large') {
        computedPrimaryTypographyProps = {
          variant: secondary ? 'P14M' : 'P14R',
        };
      } else if (size === 'medium') {
        computedPrimaryTypographyProps = {
          variant: secondary ? 'P12M' : 'P14R',
        };
      } else {
        computedPrimaryTypographyProps = {
          variant: secondary ? 'P12M' : 'P12R',
        };
      }

      if (!('color' in computedPrimaryTypographyProps)) {
        computedPrimaryTypographyProps.color = 'inherit';
      }
    }

    let computedSecondaryTypographyProps: typeof secondaryTypographyProps = {};
    if (
      secondary &&
      isValidElement(secondary) &&
      secondary.type !== Typography &&
      !disableTypography
    ) {
      switch (size) {
        case 'large':
          computedSecondaryTypographyProps = {
            variant: 'P12R',
          };
          break;
        case 'medium':
          computedSecondaryTypographyProps = {
            variant: 'P10R',
          };
          break;
        case 'small':
          computedSecondaryTypographyProps = {
            variant: 'P10R',
            lineHeight: '14px',
          };
          break;
      }

      if (!('color' in computedSecondaryTypographyProps)) {
        computedSecondaryTypographyProps.color = 'grey.500';
      }
    }

    return (
      <ListItemTextRoot
        ref={ref}
        {...other}
        primary={primary}
        primaryTypographyProps={{
          ...computedPrimaryTypographyProps,
          ...primaryTypographyProps,
        }}
        secondary={secondary}
        secondaryTypographyProps={{
          ...computedSecondaryTypographyProps,
          ...secondaryTypographyProps,
        }}
      />
    );
  }
);

ListItemText.displayName = 'ListItemText';
