import { ThemeOptions } from '@mui/material';

import { cssVars } from '../../../utils/cssVars';
import { inputGetters } from '../input';

export const {
  defs: chipDefs,
  getters: chipGetters,
  setValues: setChipValues,
} = cssVars(
  {
    iconPt: '5px',
    py: '3px',
    px: '10px',
    inputHeight: '24px',
    startIconPr: '8px',
  },
  'ds-chip'
);

export const chipInputTheme: ThemeOptions['components'] = {
  DsChipInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiInputBase-root': {
          flexWrap: 'wrap',
          height: 'unset',
          minHeight: chipGetters.inputHeight(),
          gap: '4px',
          paddingTop: chipGetters.py(),
          paddingBottom: chipGetters.py(),
        },
        '& .DsChipInput-StartIcon': {
          position: 'absolute',
          top: chipGetters.iconPt(),
          left: chipGetters.px(),
        },
        '& .DsChipInput-EndIcon': {
          position: 'absolute',
          top: chipGetters.iconPt(),
          right: chipGetters.px(),
          '& svg': {
            cursor: 'pointer',
          },
          '& circle': {
            fill: 'currentColor',
          },
          '& line': {
            stroke: 'white',
          },
        },
        '& .DsChipInput-ChipTitle': {
          marginRight: '8px',
          fontSize: inputGetters.fontSize(),
          lineHeight: inputGetters.lineHeight(),
        },
        '& .DS-Input-startAdornment-root': {
          display: 'contents',
        },
        '& .DsChipInput-InputWrapper': {
          display: 'flex',
          alignItems: 'center',
          height: chipGetters.inputHeight(),
          maxWidth: '100%',
        },
        '&:focus-within': {
          '& .Ds-MagnifyingGlass': {
            color: theme.palette.blue[500],
          },
        },
        '& .DS-Input-adornment-root': {
          height: chipGetters.inputHeight(),
        },
      }),
      small: setChipValues({
        iconPt: '6px',
        py: '3px',
        px: '8px',
        inputHeight: '20px',
        startIconPr: '4px',
      }),
      medium: chipDefs,
      large: setChipValues({
        iconPt: '9px',
        py: '5px',
        px: '12px',
        inputHeight: '28px',
        startIconPr: '8px',
      }),
    },
  },
};
