import { PrimitiveVal } from '../../_types';

export enum TokenType {
  INDENTATION = 'INDENTATION',
  ARRAY_MARK = 'ARRAY_MARK',
  OBJECT_KEY = 'OBJECT_KEY',
  PRIMITIVE_VALUE = 'PRIMITIVE_VALUE',
}

export interface IndentationToken {
  type: TokenType.INDENTATION;
  value: number;
}

export interface ArrayMarkToken {
  type: TokenType.ARRAY_MARK;
  expandable?: boolean;
  collapsePath?: string;
  index?: number;
}

export interface ObjectKeyToken {
  type: TokenType.OBJECT_KEY;
  expandable?: boolean;
  value: string;
  collapsePath?: string;
}

export interface PrimitiveValueToken {
  type: TokenType.PRIMITIVE_VALUE;
  value: PrimitiveVal;
  indexInParent?: number;
}

export type Token =
  | IndentationToken
  | ArrayMarkToken
  | ObjectKeyToken
  | PrimitiveValueToken;

export interface YamlNode {
  tokens: Token[];
  index: number;
  path: string;
  level: number;
  isExpanded: boolean;
  isVisible: boolean;
  toggle: () => void;
  childNodes: YamlNode[];
  allNodes: YamlNode[];
  nodeAsString: (indentation?: number) => string;
  yamlAsString: (indentation?: number) => string;
}
