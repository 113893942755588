import { Fragment, ReactElement, ReactNode } from 'react';

import { Stack, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import pluralize from 'pluralize';

import { Chip, mergeSx } from '@cast/design-system';

import { Search, SearchProps } from './Search';
import { SearchProvider } from './SearchProvider';
import { SearchState } from './types';
import { HexIconNotification } from '../hex-icons';
import { StatusMessage } from '../messages';

const ResultChip = styled(Chip)(({ theme }) => {
  return [
    {
      padding: '4px 8px',
      height: 'unset',
      minHeight: 28,
      wordBreak: 'break-all' as const,
      ...theme.typography.P12M,
      color: 'white',
      borderRadius: '4px',
      backgroundColor: theme.palette.grey[600],
    },
  ];
});

export type ChipSearchProps<T = string> = {
  title?: ReactNode;
  renderChip?: (entry: T) => ReactNode;
  entity: string;
  testId?: string;
  data?: T[];
  components?: {
    noData?: ReactNode;
    noDataIcon?: ReactElement;
  };
  sx?: SxProps;
  chipSx?: SxProps;
  searchProps?: SearchProps;
};

export const ChipSearch = ({
  entity,
  title = pluralize(entity, 2),
  testId = 'data-search-box',
  data,
  renderChip,
  components: {
    noDataIcon,
    noData = (
      <StatusMessage
        icon={
          <HexIconNotification
            icon={noDataIcon}
            size={64}
            animationType="pulsate"
          />
        }
        title={`No ${pluralize(entity, 2)}`}
      />
    ),
  } = {},
  sx,
  chipSx,
  searchProps,
}: ChipSearchProps) => {
  return (
    <SearchProvider data={data}>
      {(ctx: SearchState) =>
        !!noData && !ctx.isLoading && !ctx.data?.length ? (
          <>{noData}</>
        ) : (
          <Stack
            gap={12}
            onClick={(e) => e.stopPropagation()}
            data-testid={testId}
            sx={sx}
          >
            {typeof title === 'string' ? (
              <Typography variant="h5" textTransform="capitalize">
                {title}
              </Typography>
            ) : (
              title
            )}
            <Search
              {...searchProps}
              rootSx={mergeSx(
                {
                  '&& .MuiFilledInput-root.MuiInputBase-formControl': {
                    color: 'white',
                    backgroundColor: 'unset',
                    border: 'grey.600',
                    '&:focus-within': {
                      '& .Ds-MagnifyingGlass': {
                        color: 'inherit',
                      },
                    },
                  },
                },
                searchProps?.rootSx
              )}
            />
            {ctx.filteredData?.length ? (
              <Typography variant="A1" color="white" data-testid="entity-count">
                {ctx.filteredData.length}{' '}
                {pluralize(entity, ctx.filteredData.length)} found
              </Typography>
            ) : (
              <Typography variant="P14R" color="white">
                No results found for{' '}
                <Typography variant="P14B" component="span">
                  “{ctx.freeText}”
                </Typography>
              </Typography>
            )}

            <Stack flexDirection="row" flexWrap="wrap" gap={12}>
              {ctx.filteredData?.map((record: any) => {
                const recordStr = String(record);
                if (renderChip) {
                  return (
                    <Fragment key={recordStr}>{renderChip(record)}</Fragment>
                  );
                }
                return (
                  <ResultChip key={recordStr} sx={chipSx}>
                    {recordStr}
                  </ResultChip>
                );
              })}
            </Stack>
          </Stack>
        )
      }
    </SearchProvider>
  );
};
