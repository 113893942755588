import { ImageScanStatus, SecurityInsightsContainerImage } from '@cast/types';

import {
  ClientSideSearchCriterion,
  Criterion,
  makeSearchBoxCriteria,
} from 'components/search';

import { getImageName } from '../utils';

export const vulnerabilityManagementTableUrlKey =
  'org-security-vulnerability-management';

export const scanStatusCriterion: ClientSideSearchCriterion = {
  key: 'scanStatus',
  prop: 'scanStatus',
};

export const vulnerabilityManagementCriteria: Criterion[] = [
  ...makeSearchBoxCriteria<SecurityInsightsContainerImage>(
    {
      key: 'Image name:',
      getValue: getImageName,
    },
    { title: 'Package:', key: 'packageNames' },
    {
      key: 'cves',
      title: 'CVE:',
    }
  ),
  { key: 'clusterIds' },
  { key: 'namespaces' },
  { key: 'status' },
  { key: 'labels' },
  scanStatusCriterion,
];

export const scanStatusToDisplayName: Record<ImageScanStatus, string> = {
  [ImageScanStatus.Pending]: 'Scanning',
  [ImageScanStatus.Failed]: 'Scan failed',
  [ImageScanStatus.Completed]: 'Scanned',
  [ImageScanStatus.Unknown]: 'Unknown',
};
