import { useContext } from 'react';

import { Icons } from '@cast/design-system';

import { useRffFormState } from './hooks';
import { RffInputsArrayRowContext } from './inputs-array';

type Props = {
  testId?: string;
} & Icons.IconProps;

export const RemoveInputs = ({
  testId = 'remove-inputs-btn',
  ...iconProps
}: Props) => {
  const { isOnly, remove } = useContext(RffInputsArrayRowContext);
  const { mode } = useRffFormState();
  const isHidden = isOnly || mode === 'view';
  return (
    <Icons.X
      size={12}
      onClick={
        isHidden
          ? undefined
          : (event) => {
              event.stopPropagation();
              remove();
            }
      }
      data-testid={testId}
      {...iconProps}
      css={{
        width: 'auto',
        paddingLeft: '16px',
        display: isHidden ? 'none' : 'inherit',
        cursor: 'pointer',
        ...iconProps.style,
      }}
    />
  );
};
