import { ReactNode, useEffect, useRef } from 'react';

import clsx from 'clsx';

import { ExpandIcon } from '../../../../components';
import { useTableContext } from '../../../../hooks/useTableContext';
import { RowState } from '../../../../types';
import { BodyCell } from '../BodyCell';

type SubRowProps = {
  row: any;
  index: number;
  level: number;
  hasChildren: boolean;
  isTreeExpanded: boolean;
};

export const SubRow = ({
  row,
  index,
  level,
  hasChildren,
  isTreeExpanded,
}: SubRowProps) => {
  const ctx = useTableContext();
  const api = ctx.api.current;
  const config = ctx.tableProps.treeUiConfig!;
  const utils = ctx.tableProps.treeUiUtils!;
  const columnModels = api.columnsInfo.columnModels;
  const rowKey = ctx.tableProps.rowKey(row);
  const getExpandedPanel = ctx.tableProps.getExpandedPanel;
  const isExpanded = getExpandedPanel
    ? api.state.expandedRows.includes(rowKey)
    : false;
  const onRowClick = ctx.tableProps.onRowClick;

  const rowState: RowState = {
    row,
    index,
    tableApi: api,
    isExpanded,
    rowKey,
    level,
  };

  const firstIsExpandedChange = useRef(true);
  useEffect(() => {
    firstIsExpandedChange.current = false;
  }, [isExpanded]);

  const renderedRow = ctx.tableProps.renderRow?.(row, rowState);
  const hasRenderedRow = typeof renderedRow !== 'undefined';
  const bodyCells: ReactNode[] = [];
  const indentation = utils.getRowIndentation(level);
  if (!hasRenderedRow) {
    let i = 0;
    while (i < columnModels.length) {
      const columnModel = columnModels[i];
      const columnSpan = columnModel.getColumnSpan?.(row, rowState);
      if (columnSpan! > 1) {
        const endColumn = columnModels[i + columnSpan! - 1];
        if (!endColumn) {
          throw new Error(`Returned invalid column span ${columnSpan}`);
        }
        // Override sizing of first column
        const startOffset =
          columnModel.startOffset + (i === 0 ? indentation : 0);
        const endOffset = endColumn.endOffset;
        const computedWidth = endOffset - startOffset;
        bodyCells.push(
          <BodyCell
            key={columnModel.id}
            columnModel={{
              ...columnModel,
              startOffset,
              endOffset,
              computedWidth: endOffset - startOffset,
            }}
            rowState={rowState}
            style={{
              paddingLeft: config.firstCellRightPadding,
              marginLeft: indentation,
              width: computedWidth,
              minWidth: computedWidth,
              maxWidth: computedWidth,
            }}
          />
        );
        i = i + columnSpan!;
        continue;
      } else if (i === 0) {
        // Override sizing of first column
        const computedWidth = columnModel.computedWidth - indentation;
        bodyCells.push(
          <BodyCell
            key={columnModel.id}
            columnModel={{
              ...columnModel,
              startOffset: columnModel.startOffset + indentation,
              computedWidth,
            }}
            rowState={rowState}
            style={{
              marginLeft: indentation,
              width: computedWidth,
              minWidth: computedWidth,
              maxWidth: computedWidth,
            }}
          />
        );
      } else {
        bodyCells.push(
          <BodyCell
            key={columnModel.id}
            columnModel={columnModel}
            rowState={rowState}
          />
        );
      }
      i++;
    }
  }
  return (
    <div
      data-tree-row-wrapper
      className={clsx(
        'DS-Table-RowWrapper DS-Table-BodyRowWrapper DS-Table-SubRow',
        isExpanded && 'DS-Table-rowExpanded',
        index === 0 && 'DS-Table-firstRow',
        ctx.tableProps.getRowClass?.(row, rowState)
      )}
      data-testid={ctx.tableProps.getRowTestId?.(row, rowState)}
      data-sysid={ctx.tableProps.sysId}
      onClick={onRowClick ? (event) => onRowClick(event, rowState) : undefined}
    >
      {hasRenderedRow ? (
        renderedRow
      ) : (
        <>
          <div
            className="DS-Table-Row DS-Table-BodyRow DS-Table-TreeRow"
            data-testid={ctx.tableProps.getRowTestId?.(row, rowState)}
            data-sysid={ctx.tableProps.sysId}
          >
            {!hasChildren && level > 0 && (
              <div
                className="DS-Table-TreeMarker DS-Table-TreeMarker-horizontal"
                style={{
                  left: utils.getVerticalLineIndentation(level - 1),
                  width: utils.getHorizontalLineToCellWidth(level),
                }}
              />
            )}
            {hasChildren && (
              <>
                {level > 0 && (
                  <div
                    className="DS-Table-TreeMarker DS-Table-TreeMarker-horizontal"
                    style={{
                      left: utils.getHorizontalLineIndentation(level - 1),
                      width: utils.getHorizontalLineToExpanderWidth(level),
                    }}
                  />
                )}
                <ExpandIcon
                  style={{
                    fontSize: config.iconSize,
                    left: utils.getExpanderIconIndentation(level),
                  }}
                  onClick={(event) => {
                    ctx.tableProps.onTreeIconClick?.(event, rowState);

                    if (!event.defaultPrevented) {
                      api.toggleIsTreeRowExpanded(row);
                    }
                  }}
                  isExpanded={isTreeExpanded}
                />
              </>
            )}
            {bodyCells}
          </div>
          <div data-expander-wrapper className="DS-Table-ExpandedPanelWrapper">
            {getExpandedPanel && isExpanded
              ? getExpandedPanel(row, rowState)
              : null}
          </div>
        </>
      )}
    </div>
  );
};
