import { useEffect, useState } from 'react';

import throttle from 'lodash/throttle';

type Dimensions = {
  width: number;
  height: number;
};

export const useWindowResize = (throttleTime = 200) => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const throttledHandleResize = throttle(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, throttleTime);

    window.addEventListener('resize', throttledHandleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  });

  return dimensions;
};
