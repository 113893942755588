import { BoxProps } from '@mui/material';

import { Icons } from '@cast/design-system';

import { HexIconContainerProps } from './_components';
import { HexIcon, HexIconProps } from './HexIcon';

type Props = BoxProps &
  Pick<HexIconContainerProps, 'size' | 'animationType' | 'icon'> &
  Pick<HexIconProps, 'iconColor' | 'iconSize'>;

export const HexIconCheck = ({
  icon = <Icons.CheckSquare weight="fill" />,
  ...rest
}: Props) => {
  return (
    <HexIcon icon={icon} iconColor="blue.500" hexColor="blue.300" {...rest} />
  );
};

export const HexIconError = ({
  icon = <Icons.XCircle weight="fill" />,
  ...rest
}: Props) => {
  return (
    <HexIcon icon={icon} iconColor="red.500" hexColor="red.200" {...rest} />
  );
};

export const HexIconNotification = ({
  icon = <Icons.BellSimpleRinging weight="fill" />,
  ...rest
}: Props) => {
  return (
    <HexIcon icon={icon} iconColor="grey.400" hexColor="grey.300" {...rest} />
  );
};

export const HexIconSuccess = ({
  icon = <Icons.CheckCircle weight="fill" />,
  ...rest
}: Props) => {
  return (
    <HexIcon icon={icon} iconColor="green.500" hexColor="green.300" {...rest} />
  );
};

export const HexIconWarning = ({
  icon = <Icons.Warning weight="fill" />,
  ...rest
}: Props) => {
  return (
    <HexIcon
      icon={icon}
      iconColor="yellow.500"
      hexColor="yellow.300"
      {...rest}
    />
  );
};
