import { useContext } from 'react';

import {
  PreviewConfigContext,
  PreviewConfigProps,
} from './PreviewConfigProvider';

export const usePreviewConfig = <
  T extends PreviewConfigProps = PreviewConfigProps
>(): T => useContext(PreviewConfigContext) as T;
