import { ThemeOptions } from '@mui/material';

import { grey, red } from '../../../theme/colors';

export const formHelperTextTheme: ThemeOptions['components'] = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        position: 'relative',
        fontSize: '12px',
        lineHeight: '18px',
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        color: grey[500],
        '&.DS-disabled': {
          color: grey[300],
        },
        '&.DS-error': {
          color: red[500],
        },
      },
      sizeSmall: {
        fontSize: '10px',
        lineHeight: '16px',
      },
      text: {
        marginTop: '4px',
      },
      textAbsolute: {
        marginTop: 0,
        top: 4,
        position: 'absolute',
      },
    },
  },
};
