import { AxiosInstance } from 'axios';

import { FeaturesAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const FeatureFlagsApiFactory = (client: AxiosInstance) => {
  const generatedApi = FeaturesAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    getFlags: generatedApi.featuresAPIList,
    resolveFlags: generatedApi.featuresAPIResolve,
  };
};
