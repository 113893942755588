export enum NotificationVariant {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export type Notification = {
  id: string;
  variant: NotificationVariant;
  message: string;
};
