import { useContext } from 'react';

import { NormalizedCostMode } from '../../../../../cost-report/types/costOverTime';
import { NormalizedCostContext } from '../NormalizedCostProvider';

export const useNormalizedCostsControls = () => {
  const [state, dispatch] = useContext(NormalizedCostContext);

  const changeActiveTab = (activeTab: NormalizedCostMode) => {
    dispatch({ type: 'changeActiveTab', activeTab });
  };

  return {
    ...state,
    changeActiveTab,
  } as const;
};
