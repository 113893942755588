import { GroupedColumnOrdering } from '../types';

export const toColumnsOrdering = <
  T extends { id: string; columns?: { id: string }[] }
>(
  props: T[]
): GroupedColumnOrdering[] =>
  props.map((row) =>
    row.columns
      ? {
          id: row.id,
          columns: row.columns.map(({ id }) => id),
        }
      : { id: row.id }
  );
