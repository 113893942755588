import { PropsWithChildren, ReactNode } from 'react';

import { OptimizeContext } from './_providers';
import { useExperiment } from './hooks';
import { ExperimentProps } from './types';

export const Experiment = ({
  children,
  loader,
  ...props
}: PropsWithChildren<ExperimentProps & { loader?: ReactNode }>) => {
  const variant = useExperiment(props);
  return (
    <OptimizeContext.Provider value={variant}>
      {variant === null ? loader : children}
    </OptimizeContext.Provider>
  );
};
