import { PropsWithChildren, useState } from 'react';

import clsx from 'clsx';
import pluralize from 'pluralize';

import { ValueTitle } from './JsonBlock/ValueTitle';
import { LineBreak } from './LineBreak';
import { Bracket, Comma } from './tokens';
import { usePreviewConfig } from '../../_providers';

type Props = PropsWithChildren<{
  type: 'array' | 'object';
  openingBracketIndentation?: number;
  closingBracketIndentation?: number;
  hasNext?: boolean;
  title?: string;
  contentLength?: number;
  className?: string;
}>;

export const JsonBlockContainer = ({
  children,
  type,
  openingBracketIndentation = 0,
  closingBracketIndentation = 0,
  hasNext,
  title,
  contentLength,
  className,
}: Props) => {
  const [bracketHasHover, setBracketHasHover] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { interactive } = usePreviewConfig();

  const closingTag = (
    <>
      {isCollapsed && (
        <span className="DsPreviewJson-ChildCount">
          {contentLength} {pluralize('item', contentLength)}
        </span>
      )}
      <Bracket
        contentType={type}
        type="close"
        indent={isCollapsed || !contentLength ? 0 : closingBracketIndentation}
        hasHover={bracketHasHover}
        onHasHoverChanged={setBracketHasHover}
      >
        {hasNext && <Comma />}
      </Bracket>
      {Boolean(contentLength) && <LineBreak />}
    </>
  );
  return (
    <div
      className={clsx(
        'DsPreviewJson-BlockContainer-root',
        interactive && 'DsPreviewJson-BlockContainer-interactive',
        className
      )}
    >
      <div
        className="DsPreviewJson-BlockContainer-TitleRow"
        onClick={() => interactive && setIsCollapsed(!isCollapsed)}
        onMouseOver={() => setBracketHasHover(true)}
        onMouseOut={() => setBracketHasHover(false)}
      >
        {title && (
          <ValueTitle
            title={title}
            level={closingBracketIndentation}
            expandTogglerProps={
              interactive ? { isCollapsed, setIsCollapsed } : undefined
            }
          />
        )}
        <Bracket
          contentType={type}
          type="open"
          indent={openingBracketIndentation}
          hasHover={bracketHasHover}
          onHasHoverChanged={setBracketHasHover}
          expandTogglerProps={
            !title && interactive ? { isCollapsed, setIsCollapsed } : undefined
          }
        />
        {(isCollapsed || !contentLength) && closingTag}
      </div>

      <div
        className={clsx(
          'DsPreviewJson-BlockContainer-Container',
          isCollapsed || !contentLength
            ? 'DsPreviewJson-BlockContainer-Container-hidden'
            : 'DsPreviewJson-BlockContainer-Container-visible'
        )}
      >
        <LineBreak />
        <div
          className={clsx(
            'DsPreviewJson-BlockContainer-Wrapper',
            type === 'object'
              ? 'DsPreviewJson-ObjectDisplay-root'
              : 'DsPreviewJson-ArrayDisplay-root'
          )}
        >
          {children}
        </div>
        <LineBreak />
      </div>
      {!(isCollapsed || !contentLength) && closingTag}
    </div>
  );
};
