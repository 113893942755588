import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useInput } from '@mui/base';
import { AutocompleteGroupedOption } from '@mui/material';
import { PopoverActions } from '@mui/material/Popover/Popover';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import pullAt from 'lodash/pullAt';
import sortBy from 'lodash/sortBy';
import { PopupState } from 'material-ui-popup-state/hooks';

import {
  AutocompleteOverrides,
  DropdownProps,
  UseDropdownWithAutocomplete,
  isMultipleSelection,
  isSingleSelection,
} from './types';
import { makeIsOptionSelected, normalizeProps } from './utils';

export type DropdownState<OptionItem = any, OptionValue = any> = {
  dropdownProps: DropdownProps<OptionItem, OptionValue>;
  popupState: PopupState;
  autocomplete: ReturnType<typeof useDropdownWithAutocomplete<OptionItem, any>>;
};

export const useDropdownWithAutocomplete = <
  OptionItem extends unknown,
  OptionValue extends unknown
>({
  dropdownProps,
  autocompleteOverrides = {},
}: UseDropdownWithAutocomplete<OptionItem, OptionValue>) => {
  const popoverActionsRef = useRef<PopoverActions | null>(null);
  const {
    getters: {
      getOptionLabel,
      getOptionIcon,
      groupBy,
      getOptionKey,
      getOptionSelectedValue,
      getOptionValue,
      getOptionDisabled,
      isOptionEqualToValue,
    },
    autocompleteProps: normalizedAutocompleteProps,
  } = normalizeProps<OptionItem, OptionValue>(dropdownProps);

  const autocompleteProps = useMemo(() => {
    return {
      ...normalizedAutocompleteProps,
      ...autocompleteOverrides,
    } as AutocompleteOverrides<OptionItem, OptionValue>; // Explicit casting is necessary due to the fact that the overridden type is Partial<...>, which converts existing props into optional ones
  }, [normalizedAutocompleteProps, autocompleteOverrides]);

  const emitInputChange = useCallback(
    (value: string, reason: 'change' | 'reset') => {
      dropdownProps.onInputChange?.(value, reason);
    },
    [dropdownProps]
  );

  /**
   * States
   */

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState('');
  const input = useInput({
    value: inputValue,
    inputRef,
    onChange: (event) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      emitInputChange(newValue, 'change');
    },
  });

  const [_initialValue, setInitialValue] = useState<
    OptionValue | OptionValue[] | null
  >(autocompleteProps.multiple ? [] : null);

  const [value, setValue] = useState<OptionValue | OptionValue[] | null>(
    _initialValue
  );

  const showMultipleValueText = Boolean(
    autocompleteProps.multiple &&
      !!(value as OptionItem[]).length &&
      !autocompleteProps.open
  );

  const _options = useMemo(() => {
    let options = autocompleteProps.options || [];

    // Apply search
    options =
      inputValue.length > 0
        ? options.filter(
            !!autocompleteProps.searchFilter
              ? (item) => autocompleteProps.searchFilter?.(item, inputValue)
              : (item) =>
                  getOptionLabel(item)
                    .toLocaleLowerCase()
                    .startsWith(inputValue.toLocaleLowerCase())
          )
        : options;

    // Apply sorting
    if (autocompleteProps.applySortingWhileSearching) {
      options = sortBy(options, (item) => {
        return getOptionLabel(item);
      });
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputValue,
    autocompleteProps.options,
    autocompleteProps.searchFilter,
    autocompleteProps.applySortingWhileSearching,
    getOptionLabel,
    inputValue,
  ]);

  const options = useMemo(() => {
    if (!dropdownProps.showSelectedOptionsFirst || !dropdownProps.value) {
      return _options;
    }
    const isOptionSelected = makeIsOptionSelected(
      !!dropdownProps.multiple,
      dropdownProps.value,
      dropdownProps.isOptionEqualToValue
    );
    return sortBy(_options, (item) => !isOptionSelected(item));
  }, [
    dropdownProps.showSelectedOptionsFirst,
    dropdownProps.value,
    dropdownProps.multiple,
    dropdownProps.isOptionEqualToValue,
    _options,
  ]);

  const groupedOptions = useMemo(() => {
    if (!groupBy) {
      return [];
    }

    const groups: { [key: string]: AutocompleteGroupedOption<OptionItem> } = {};

    options.forEach((item, index) => {
      const groupKey = groupBy(item);
      const group = groups[groupKey];
      if (group) {
        groups[groupKey] = {
          ...groups[groupKey],
          options: [...groups[groupKey].options, item],
        };
      } else {
        groups[groupKey] = {
          key: index,
          index,
          group: groupKey,
          options: [item],
        };
      }
    });

    return Object.values(groups);
  }, [groupBy, options]);

  const selectedOption = useMemo(() => {
    if (isSingleSelection(dropdownProps)) {
      return dropdownProps.options?.find((option) =>
        isOptionEqualToValue(getOptionValue(option), dropdownProps.value)
      );
    }
  }, [isOptionEqualToValue, getOptionValue, dropdownProps]);

  /**
   * Getters
   */
  const isOptionSelected = useMemo(() => {
    return makeIsOptionSelected(
      !!autocompleteProps.multiple,
      value,
      isOptionEqualToValue
    );
  }, [autocompleteProps.multiple, isOptionEqualToValue, value]);

  const getOptionProps = useCallback(
    ({ option }: { option: OptionItem; index: number }) => ({
      onClick: (e: MouseEvent) => {
        if (autocompleteProps.multiple) {
          e.preventDefault(); // Disable 'close on select' to allow the selection of other items
          e.stopPropagation();

          const isOptionDisabled = getOptionDisabled(option);
          if (isOptionDisabled) {
            return;
          }

          const selectedOptionIndex = (value as OptionItem[]).findIndex(
            (selectedOptionValue) => {
              return isEqual(selectedOptionValue, getOptionValue(option));
            }
          );
          if (selectedOptionIndex === -1) {
            setValue((prevValue) => [
              ...(prevValue as OptionItem[]),
              getOptionValue(option),
            ]);
          } else {
            const newValue = cloneDeep(value);
            pullAt(newValue as OptionItem[], selectedOptionIndex);
            setValue(newValue);
          }
        } else {
          const isOptionDisabled = getOptionDisabled(option);
          if (isOptionDisabled) {
            return;
          }

          setValue(getOptionValue(option));
          if (isSingleSelection(autocompleteProps)) {
            autocompleteProps.onChange?.(option);
          }
        }
      },
      key: getOptionKey(option),
      value: getOptionValue(option),
      label: getOptionLabel(option),
    }),
    [
      autocompleteProps,
      getOptionDisabled,
      getOptionKey,
      getOptionLabel,
      getOptionValue,
      value,
    ]
  );

  /**
   * Actions
   */

  const saveValue = useCallback(() => {
    setInitialValue(value);
  }, [value]);

  const resetValue = useCallback(() => {
    setValue(_initialValue);
  }, [_initialValue]);

  const clearValue = useCallback(() => {
    setInputValue('');
    setValue(autocompleteProps.multiple ? [] : null);
  }, [autocompleteProps.multiple]);

  const selectAll = useCallback(() => {
    setValue(
      cloneDeep(autocompleteProps.options)
        .filter((option) => getOptionLabel(option).startsWith(inputValue))
        .map((item) => getOptionValue(item))
    );
  }, [autocompleteProps.options, getOptionLabel, getOptionValue, inputValue]);

  const select = useCallback(
    (groupOptions: OptionItem[], checked: boolean) => {
      if (checked) {
        setValue([
          ...cloneDeep(value as OptionValue[]),
          ...groupOptions.map(getOptionValue),
        ]);
        return;
      }
      // Remove options from the value
      const newValue = cloneDeep(value as OptionValue[]).filter(
        (item) =>
          !groupOptions.some((groupOption) =>
            isOptionEqualToValue(groupOption, item)
          )
      );

      setValue(newValue);
    },
    [getOptionValue, isOptionEqualToValue, value]
  );

  /**
   * Effects
   */

  useEffect(() => {
    setInputValue(autocompleteProps.inputValue || '');
  }, [autocompleteProps.inputValue]);

  useEffect(() => {
    if (autocompleteProps.resetInputOnClose && !autocompleteProps.open) {
      setInputValue('');
      emitInputChange('', 'reset');
    }
  }, [
    autocompleteProps.open,
    autocompleteProps.resetInputOnClose,
    emitInputChange,
  ]);

  useEffect(() => {
    if (autocompleteProps.open) {
      setInitialValue(_initialValue);
    } else {
      setValue(_initialValue);
    }
  }, [_initialValue, autocompleteProps.open]);

  // Detect external values changes
  useEffect(() => {
    if (autocompleteProps.value) {
      setInitialValue(autocompleteProps.value);
    } else {
      if (autocompleteProps.multiple) {
        setInitialValue([]);
      } else {
        setInitialValue(null);
      }
    }
  }, [autocompleteProps.multiple, autocompleteProps.value]);

  return {
    getters: {
      // Normalized getters
      getOptionLabel,
      getOptionKey,
      getOptionSelectedValue,
      getOptionValue,
      getOptionDisabled,
      getOptionIcon,
      isOptionEqualToValue,

      // Internal getters - derived from normalized getters and/or the states of this hook
      getInputProps: input.getInputProps,
      getOptionProps,
      isOptionSelected,
    },
    inputRef,
    popoverActionsRef,
    actions: {
      saveValue,
      resetValue,
      clearValue,
      selectAll,
      select,
    },
    states: {
      options,
      groupedOptions,
      value,
      selectedOption: isSingleSelection(dropdownProps)
        ? selectedOption
        : undefined,
      showMultipleValueText: isMultipleSelection(dropdownProps)
        ? showMultipleValueText
        : undefined,
      searchInputValue: inputValue,
      allowSearch:
        autocompleteProps.options.length >= 5 && !autocompleteProps.hideSearch,
      popupOpen: autocompleteProps.open,
      searchInputProps: autocompleteProps.searchInputProps,
    },
  };
};
