import { CSSObject } from '@emotion/serialize';
import { Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

type StripedBgProps = {
  color: string;
  bgColor?: string;
  spacing?: number;
  rotation?: number;
};

export const createStripedBg = ({
  color,
  bgColor = 'transparent',
  spacing = 3,
  rotation = -45,
}: StripedBgProps) =>
  `repeating-linear-gradient(${rotation}deg, ${color}, ${color} 1px, ${bgColor} 1px, ${bgColor} ${spacing}px)`;

export const mergeSx = (
  ...sxProps: (SxProps<any> | undefined | false | null)[]
): SxProps<any> | undefined => {
  let sxs: SxProps = [];
  sxProps.forEach((sx) => {
    if (sx) {
      if (Array.isArray(sx)) {
        sxs = (sxs as Array<any>).concat(sx);
      } else {
        (sxs as Array<any>).push(sx);
      }
    }
  });
  if (sxs.length === 0) {
    return;
  }
  if (sxs.length === 1) {
    return sxs[0] as any;
  }
  return sxs;
};

export function createSx<T extends Record<string, SxProps<Theme>>>(
  t: T
): Record<keyof T, SxProps<any>> {
  return t;
}

type StylesDict = Record<string, CSSObject>;

export function createStyles<R extends StylesDict>(
  factory: (theme: Theme) => R
): () => Record<keyof R, any>;

export function createStyles<R extends StylesDict, P = any>(
  factory: (theme: Theme, p: P) => R
): (p: P) => Record<keyof R, any>;

export function createStyles<P, R>(factory: (theme: Theme, p: P) => R) {
  return (props?: P) => {
    const theme = useTheme();
    return factory(theme, props as any);
  };
}
