import { Stack, Typography } from '@mui/material';

import { Spinner } from '@cast/design-system';

type ConnectingStateProps = {
  title: string;
  subtitle: string;
};

export const ConnectingState = ({ title, subtitle }: ConnectingStateProps) => {
  return (
    <Stack direction="row" gap="12px">
      <Spinner color="secondary" size={32} testId="connecting-spinner" />
      <Stack gap="4px">
        <Typography variant="h6">{title}</Typography>
        <Typography variant="P12R">{subtitle}</Typography>
      </Stack>
    </Stack>
  );
};
