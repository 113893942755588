import mapValues from 'lodash/mapValues';

import { DrawerReducerAction, DrawerReducerState } from './types';
import { findParentDrawerIds } from './utils';

export const drawerInitialState: DrawerReducerState = {
  drawers: {},
  order: [],
};

export function drawerReducer(
  state: DrawerReducerState,
  action: DrawerReducerAction
) {
  switch (action.type) {
    case 'open': {
      const parentId = action.payload.parentId;

      if (!parentId && state.order.length) {
        // close all other drawers
        return {
          drawers: {
            [action.payload.id]: {
              id: action.payload.id,
              component: action.payload.component,
              props: {
                ...action.payload.props,
                open: false,
                hideBackdrop: true,
              },
              parentId,
              subDrawerOpen: false,
            },
          },
          order: [action.payload.id],
        };
      }

      return {
        ...state,
        drawers: {
          ...state.drawers,
          [action.payload.id]: {
            id: action.payload.id,
            component: action.payload.component,
            props: { ...action.payload.props, open: false, hideBackdrop: true },
            parentId,
            subDrawerOpen: false,
          },
          ...(parentId
            ? {
                [parentId]: {
                  ...state.drawers[parentId],
                  subDrawerOpen: true,
                },
              }
            : {}),
        },
        order: [...state.order, action.payload.id],
      };
    }
    case 'update': {
      const _state = { ...state };
      if (action.payload.translateX) {
        const nestedDrawerIds = findParentDrawerIds(
          action.payload.id,
          Object.values(_state.drawers),
          []
        );

        _state.drawers = mapValues(_state.drawers, (drawer) => {
          if (nestedDrawerIds.includes(drawer.id)) {
            const x = action.payload.translateX ?? 0;
            if (x > 0) {
              return {
                ...drawer,
                translateX: (drawer.initialTranslateX ?? 0) + (x ?? 0),
              };
            } else {
              return {
                ...drawer,
                translateX: (drawer.translateX ?? 0) + (x ?? 0),
              };
            }
          } else {
            return drawer;
          }
        });
      }

      return {
        ..._state,
        drawers: {
          ..._state.drawers,
          [action.payload.id]: {
            ..._state.drawers[action.payload.id],
            ...action.payload,
            props: {
              ...(_state.drawers[action.payload.id]?.props
                ? _state.drawers[action.payload.id].props
                : {}),
              ...action.payload.props,
            },
          },
        },
      };
    }
    case 'destroy': {
      const _state = { ...state };
      const parentId = _state.drawers[action.payload.id]?.parentId;

      delete _state.drawers[action.payload.id];

      return {
        ..._state,
        drawers: {
          ..._state.drawers,
          ...(parentId
            ? {
                [parentId]: {
                  ...state.drawers[parentId],
                  subDrawerOpen: Object.values(_state.drawers).some(
                    (d) => d.parentId === parentId
                  ),
                },
              }
            : {}),
        },
        order: _state.order.filter((id) => id !== action.payload.id),
      };
    }
  }
}
