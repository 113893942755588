export const toggleChatBubbleVisibility = (visible: boolean) => {
  window.document.body.classList.toggle('chat-bubble-hidden', !visible);
};

export const markUnreadMessages = (status: boolean) => {
  window.document.body.classList.toggle('support-messages-unread', status);
};

export const loadWidget = (name: string, email: string) => {
  window.pylon = {
    chat_settings: {
      app_id: '40ee6fac-2b7b-4a4f-81f1-a85e28dfa3da',
      email,
      name,
    },
  };
  window.Pylon?.('onChangeUnreadMessagesCount', (unreadCount) => {
    markUnreadMessages(unreadCount > 0);
    if (unreadCount > 0) {
      toggleChatBubbleVisibility(true);
    }
  });
  toggleChatBubbleVisibility(false);
};

export const openChat = () => {
  toggleChatBubbleVisibility(true);
  window.Pylon?.('show');
};
