import { ElementType, PropsWithChildren } from 'react';

import { styled, Typography, TypographyProps } from '@mui/material';

import { shouldForwardProp } from '../../utils/shouldForwardProp';

const CellRoot = styled(Typography, {
  name: 'DsDataPopperCell',
  slot: 'Root',
  target: 'DsDataPopperCell-Root',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root],
})<TypographyProps<'span', { component?: ElementType }>>``;

export const Cell = (props: PropsWithChildren<TypographyProps>) => {
  return (
    <CellRoot component="div" variant="inherit" {...props}>
      {props.children}
    </CellRoot>
  );
};
