import { useRef, useState } from 'react';

import { styled } from '@mui/material';
import clsx from 'clsx';

import { useElementRect } from '@cast/utils';

import { Body } from './_components/Body';
import { DraggableContainer } from './_components/DraggableContainer';
import { GroupHeadersRow } from './_components/GroupHeadersRow';
import { Overlay } from './_components/Overlay';
import { usePeripheralsHeight } from './_hooks/usePeripheralsHeight';
import { useTableComponent } from './_hooks/useTableComponent';
import { getHorizontalScrollerWidth } from './_hooks/useTableComponent/initializeColumns/_utils';
import { useTableProps } from './_hooks/useTableProps';
import { TableContext } from './context';
import { NormalizedTableProps, OverlayState, TableProps } from './types';
import { mergeSx } from '../../utils';
import { shouldForwardProp } from '../../utils/shouldForwardProp';

const TableRoot = styled('div', {
  name: 'DsTable',
  slot: 'Root',
  skipVariantsResolver: true,
  target: 'DS-Table-root',
  overridesResolver: (_, styles) => [styles.root],
  shouldForwardProp,
})<{ ownerState: NormalizedTableProps }>({});

export const Table = <T extends any = any>({
  sx,
  outerHeader,
  ..._props
}: TableProps<T>) => {
  const normalizedProps = useTableProps(_props);
  const { data, HeaderRowComponent, FooterRowComponent, treeTable } =
    normalizedProps;

  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
  const overlayRef = useRef<OverlayState>({ root: null, resizerLine: null });
  const [isFloatingColumnSticky, setIsFloatingColumnSticky] = useState(false);

  const peripheralsHeight = usePeripheralsHeight(rootRef);

  const rootRect = useElementRect(
    rootRef,
    true,
    ({ width, height }) => ({ width, height }),
    (a, b) => a?.width === b?.width && a?.height === b?.height
  );

  const api = useTableComponent(normalizedProps, rootRect as any);
  const { hasFooter, columnStyles, groupModels } = api.current.columnsInfo;
  const showFooterRow = hasFooter || _props.FooterRowComponent;
  const showFooter = !!_props.footer;

  return (
    <TableRoot
      className={clsx(
        'DS-Table-root',
        hasFooter && 'DS-Table-hasFooter',
        treeTable && 'DS-Table-treeTable',
        isFloatingColumnSticky && 'DS-Table-floatingColumnSticky'
      )}
      data-testid={normalizedProps.testId}
      sx={mergeSx(
        sx,
        columnStyles,
        !!_props.maxHeight && { maxHeight: _props.maxHeight }
      )}
      ref={setRootRef}
      ownerState={normalizedProps}
      style={
        {
          '--tableWidth': `${api.current.columnsInfo.tableWidth}px`,
          '--lockedColumnsWidth': `${api.current.columnsInfo.lockedColumnsWidth}px`,
          '--scrollableColumnsWidth': `${api.current.columnsInfo.scrollableColumnsWidth}px`,
          '--hScrollerWidth': getHorizontalScrollerWidth(
            api.current.columnsInfo,
            rootRect?.width
          ),
        } as any
      }
    >
      <TableContext.Provider
        value={{
          rootRect,
          rootRef: rootRef || undefined,
          data,
          api,
          tableProps: normalizedProps,
          peripheralsHeight,
          overlay: overlayRef.current,
          isFloatingColumnSticky,
          setIsFloatingColumnSticky,
        }}
      >
        <div
          className={clsx(
            'DS-Table-OuterHeaderWrapper',
            !!groupModels?.length &&
              'DS-Table-OuterHeaderWrapper-withGroupHeaders'
          )}
          data-peripheral={true}
        >
          {outerHeader}
        </div>
        <div className="DS-Table-Wrapper">
          <div className="DS-Table-HorizontalScroller">
            <div
              className="DS-Table-TopPeripheralsWrapper"
              data-peripheral={true}
              data-group-headers={true}
            >
              <div className="DS-Table-TopPeripherals">
                <GroupHeadersRow />
              </div>
            </div>
            <div className="DS-Table-OverlayContainer">
              <div
                className="DS-Table-TopPeripheralsWrapper"
                data-peripheral={true}
                data-headers={true}
              >
                <div className="DS-Table-TopPeripherals">
                  <HeaderRowComponent />
                </div>
              </div>
              {rootRect && <Body />}
              {(showFooter || showFooterRow) && (
                <div
                  className="DS-Table-BottomPeripheralsWrapper"
                  data-peripheral={true}
                >
                  {showFooterRow && <FooterRowComponent />}
                  {showFooter && <>{_props.footer}</>}
                </div>
              )}
              <Overlay ref={overlayRef} />
            </div>
          </div>
        </div>
        <DraggableContainer />
      </TableContext.Provider>
    </TableRoot>
  );
};
