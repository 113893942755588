/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CastaiInventoryV1beta1GetPricingForClusterNodeResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetPricingForClusterNodesResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetPricingForOrganizationNodesResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1SyncGCPBillingAPIDataResponse } from '../models';
/**
 * PricingAPIApi - axios parameter creator
 * @export
 */
export const PricingAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get pricing for single cluster node
         * @param {string} clusterId The ID of the cluster.
         * @param {string} nodeId The node id
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForClusterNode: async (clusterId: string, nodeId: string, pricingAsOf?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('pricingAPIGetPricingForClusterNode', 'clusterId', clusterId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('pricingAPIGetPricingForClusterNode', 'nodeId', nodeId)
            const localVarPath = `/v1/pricing/clusters/{clusterId}/nodes/{nodeId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pricingAsOf !== undefined) {
                localVarQueryParameter['pricingAsOf'] = (pricingAsOf as any instanceof Date) ?
                    (pricingAsOf as any).toISOString() :
                    pricingAsOf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pricing for cluster nodes by node ids or all nodes if ids are not provided
         * @param {string} clusterId The ID of the cluster.
         * @param {Array<string>} [nodeIds] The node ids
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForClusterNodes: async (clusterId: string, nodeIds?: Array<string>, pricingAsOf?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('pricingAPIGetPricingForClusterNodes', 'clusterId', clusterId)
            const localVarPath = `/v1/pricing/clusters/{clusterId}/nodes`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeIds) {
                localVarQueryParameter['nodeIds'] = nodeIds;
            }

            if (pricingAsOf !== undefined) {
                localVarQueryParameter['pricingAsOf'] = (pricingAsOf as any instanceof Date) ?
                    (pricingAsOf as any).toISOString() :
                    pricingAsOf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the pricing of organization nodes by ids or all nodes if ids are not provided
         * @param {Array<string>} [nodeIds] The node ids
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForOrganizationNodes: async (nodeIds?: Array<string>, pricingAsOf?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pricing/nodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeIds) {
                localVarQueryParameter['nodeIds'] = nodeIds;
            }

            if (pricingAsOf !== undefined) {
                localVarQueryParameter['pricingAsOf'] = (pricingAsOf as any instanceof Date) ?
                    (pricingAsOf as any).toISOString() :
                    pricingAsOf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync GCP billing pricing API data
         * @param {string} [clusterId] GCP cluster ID.
         * @param {string} [billingAccountId] GCP billing account ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPISyncGCPBillingAPIData: async (clusterId?: string, billingAccountId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/pricing/sync/gcp/billing-api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (billingAccountId !== undefined) {
                localVarQueryParameter['billingAccountId'] = billingAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PricingAPIApi - functional programming interface
 * @export
 */
export const PricingAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PricingAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get pricing for single cluster node
         * @param {string} clusterId The ID of the cluster.
         * @param {string} nodeId The node id
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIGetPricingForClusterNode(clusterId: string, nodeId: string, pricingAsOf?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetPricingForClusterNodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIGetPricingForClusterNode(clusterId, nodeId, pricingAsOf, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIGetPricingForClusterNode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get pricing for cluster nodes by node ids or all nodes if ids are not provided
         * @param {string} clusterId The ID of the cluster.
         * @param {Array<string>} [nodeIds] The node ids
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIGetPricingForClusterNodes(clusterId: string, nodeIds?: Array<string>, pricingAsOf?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetPricingForClusterNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIGetPricingForClusterNodes(clusterId, nodeIds, pricingAsOf, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIGetPricingForClusterNodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the pricing of organization nodes by ids or all nodes if ids are not provided
         * @param {Array<string>} [nodeIds] The node ids
         * @param {string} [pricingAsOf] The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPIGetPricingForOrganizationNodes(nodeIds?: Array<string>, pricingAsOf?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetPricingForOrganizationNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPIGetPricingForOrganizationNodes(nodeIds, pricingAsOf, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPIGetPricingForOrganizationNodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync GCP billing pricing API data
         * @param {string} [clusterId] GCP cluster ID.
         * @param {string} [billingAccountId] GCP billing account ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingAPISyncGCPBillingAPIData(clusterId?: string, billingAccountId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1SyncGCPBillingAPIDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingAPISyncGCPBillingAPIData(clusterId, billingAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PricingAPIApi.pricingAPISyncGCPBillingAPIData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PricingAPIApi - factory interface
 * @export
 */
export const PricingAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PricingAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Get pricing for single cluster node
         * @param {PricingAPIApiPricingAPIGetPricingForClusterNodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForClusterNode(requestParameters: PricingAPIApiPricingAPIGetPricingForClusterNodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetPricingForClusterNodeResponse> {
            return localVarFp.pricingAPIGetPricingForClusterNode(requestParameters.clusterId, requestParameters.nodeId, requestParameters.pricingAsOf, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pricing for cluster nodes by node ids or all nodes if ids are not provided
         * @param {PricingAPIApiPricingAPIGetPricingForClusterNodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForClusterNodes(requestParameters: PricingAPIApiPricingAPIGetPricingForClusterNodesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetPricingForClusterNodesResponse> {
            return localVarFp.pricingAPIGetPricingForClusterNodes(requestParameters.clusterId, requestParameters.nodeIds, requestParameters.pricingAsOf, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the pricing of organization nodes by ids or all nodes if ids are not provided
         * @param {PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPIGetPricingForOrganizationNodes(requestParameters: PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetPricingForOrganizationNodesResponse> {
            return localVarFp.pricingAPIGetPricingForOrganizationNodes(requestParameters.nodeIds, requestParameters.pricingAsOf, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync GCP billing pricing API data
         * @param {PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingAPISyncGCPBillingAPIData(requestParameters: PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1SyncGCPBillingAPIDataResponse> {
            return localVarFp.pricingAPISyncGCPBillingAPIData(requestParameters.clusterId, requestParameters.billingAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for pricingAPIGetPricingForClusterNode operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIGetPricingForClusterNodeRequest
 */
export interface PricingAPIApiPricingAPIGetPricingForClusterNodeRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNode
     */
    readonly clusterId: string

    /**
     * The node id
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNode
     */
    readonly nodeId: string

    /**
     * The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNode
     */
    readonly pricingAsOf?: string
}

/**
 * Request parameters for pricingAPIGetPricingForClusterNodes operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIGetPricingForClusterNodesRequest
 */
export interface PricingAPIApiPricingAPIGetPricingForClusterNodesRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNodes
     */
    readonly clusterId: string

    /**
     * The node ids
     * @type {Array<string>}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNodes
     */
    readonly nodeIds?: Array<string>

    /**
     * The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForClusterNodes
     */
    readonly pricingAsOf?: string
}

/**
 * Request parameters for pricingAPIGetPricingForOrganizationNodes operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest
 */
export interface PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest {
    /**
     * The node ids
     * @type {Array<string>}
     * @memberof PricingAPIApiPricingAPIGetPricingForOrganizationNodes
     */
    readonly nodeIds?: Array<string>

    /**
     * The specific timestamp for which pricing information is requested. If not provided, the current prices are returned.
     * @type {string}
     * @memberof PricingAPIApiPricingAPIGetPricingForOrganizationNodes
     */
    readonly pricingAsOf?: string
}

/**
 * Request parameters for pricingAPISyncGCPBillingAPIData operation in PricingAPIApi.
 * @export
 * @interface PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest
 */
export interface PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest {
    /**
     * GCP cluster ID.
     * @type {string}
     * @memberof PricingAPIApiPricingAPISyncGCPBillingAPIData
     */
    readonly clusterId?: string

    /**
     * GCP billing account ID.
     * @type {string}
     * @memberof PricingAPIApiPricingAPISyncGCPBillingAPIData
     */
    readonly billingAccountId?: string
}

/**
 * PricingAPIApi - object-oriented interface
 * @export
 * @class PricingAPIApi
 * @extends {BaseAPI}
 */
export class PricingAPIApi extends BaseAPI {
    /**
     * 
     * @summary Get pricing for single cluster node
     * @param {PricingAPIApiPricingAPIGetPricingForClusterNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIGetPricingForClusterNode(requestParameters: PricingAPIApiPricingAPIGetPricingForClusterNodeRequest, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIGetPricingForClusterNode(requestParameters.clusterId, requestParameters.nodeId, requestParameters.pricingAsOf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pricing for cluster nodes by node ids or all nodes if ids are not provided
     * @param {PricingAPIApiPricingAPIGetPricingForClusterNodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIGetPricingForClusterNodes(requestParameters: PricingAPIApiPricingAPIGetPricingForClusterNodesRequest, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIGetPricingForClusterNodes(requestParameters.clusterId, requestParameters.nodeIds, requestParameters.pricingAsOf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the pricing of organization nodes by ids or all nodes if ids are not provided
     * @param {PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPIGetPricingForOrganizationNodes(requestParameters: PricingAPIApiPricingAPIGetPricingForOrganizationNodesRequest = {}, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPIGetPricingForOrganizationNodes(requestParameters.nodeIds, requestParameters.pricingAsOf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync GCP billing pricing API data
     * @param {PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricingAPIApi
     */
    public pricingAPISyncGCPBillingAPIData(requestParameters: PricingAPIApiPricingAPISyncGCPBillingAPIDataRequest = {}, options?: RawAxiosRequestConfig) {
        return PricingAPIApiFp(this.configuration).pricingAPISyncGCPBillingAPIData(requestParameters.clusterId, requestParameters.billingAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}

