import { ThemeOptions } from '@mui/material';

export const previewJsonTheme: ThemeOptions['components'] = {
  DsPreviewJson: {
    styleOverrides: {
      root: () => ({
        position: 'relative',
      }),
      scroller: ({ ownerState }) => ({
        height: ownerState.height,
        width: '100%',
      }),
      syntaxHighlight: ({
        theme,
        ownerState: { errorMarker, horizontalPadding = 44 },
      }) => ({
        ...(errorMarker
          ? {
              [errorMarker as string]: {
                content: '""',
                borderRadius: '10px',
                display: 'block',
                position: 'absolute',
                top: 3,
                bottom: 3,
                left: -15,
                width: 3,
                backgroundColor: theme.palette.red[400],
              },
            }
          : {}),
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: 'inherit',
        position: 'relative',
        '& *': {
          color: 'white',
        },
        '& .DsPreviewJson-PrimitiveValueContainer': {
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        },
        '& .DsPreviewJson-Key, & .DsPreviewJson-Comma, & .DsPreviewJson-Colon, & .DsPreviewJson-Null, & .DsPreviewJson-Bracket-Bracket, & .DsPreviewJson-Boolean, & .DsPreviewJson-Number':
          {
            color: theme.palette.green[300],
          },
        '& .DsPreviewJson-String': { whiteSpace: 'pre-wrap' },
        '& .DsPreviewJson-Key': {
          whiteSpace: 'nowrap',
        },
        '& .DsPreviewJson-Undefined': {
          color: theme.palette.grey[300],
        },
        '& .DsPreviewJson-Colon, & .DsPreviewJson-Indentation': {
          whiteSpace: 'pre',
        },
        '& .DsPreviewJson-Link': {
          color: theme.palette.blue[300],
          cursor: 'pointer',
          '&:hover': { color: theme.palette.blue[300] },
        },
        '& .DsCode-ExpandToggler': {
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '1em',
          width: '1em',
          position: 'absolute',
          userSelect: 'none',
          left: -18,
          top: '50%',
          transform: 'translateY(-50%)',
        },
        '& .DsPreviewJson-ChildCount': {
          fontSize: '0.8em',
        },
        '& .DsPreviewJson-ObjectDisplay': {
          '&-KeyContainer': {
            position: 'relative',
            whiteSpace: 'nowrap',
          },
          '&-KeyIndentation': {
            whiteSpace: 'pre',
          },
          '&-KeyWrapper': {
            position: 'relative',
          },
        },
        '& .DsPreviewJson-Bracket': {
          '&-root': {
            whiteSpace: 'pre-wrap',
          },
          '&-hover .DsPreviewJson-Bracket-Bracket': {
            fontWeight: 900,
          },
          '&-Wrapper': {
            position: 'relative',
          },
        },

        '& .DsPreviewJson-BlockContainer': {
          '&-interactive': {
            '& .DsPreviewJson-BlockContainer-TitleRow': {
              cursor: 'pointer',
            },
          },
          '&-TitleRow': {
            width: `calc(100% + ${horizontalPadding * 2}px)`,
            paddingLeft: horizontalPadding,
            marginLeft: -horizontalPadding,
            marginRight: -horizontalPadding,
          },
          '&-root': {
            width: '100%',
            position: 'relative',
          },
          '&-Wrapper': {
            width: '100%',
          },
          '&-Container': {
            display: 'contents',
            '&-hidden': {
              display: 'none',
            },
          },
        },
        '& .DsPreviewJson-LineBreak': {
          flexBasis: '100%',
          height: 0,
        },
      }),
      colorPrimary: ({ theme }) => ({
        backgroundColor: theme.palette.grey[800],
      }),
      colorSecondary: ({ theme }) => ({
        backgroundColor: theme.palette.grey[900],
      }),
      sizeMedium: ({ theme }) => theme.typography.C12,
      sizeLarge: ({ theme }) => theme.typography.C14,
    },
  },
};
