import {
  capitalize,
  Stepper as MuiStepper,
  StepperProps as MuiStepperProps,
} from '@mui/material';
import clsx from 'clsx';

import { InternalContext } from './InternalContext';
import { Step } from './Step';
import { StepperSize } from './types';

export type StepperProps = MuiStepperProps<
  'div',
  {
    size?: StepperSize;
    steps?: number;
    fullHeight?: boolean;
  } & {
    testId?: string;
  }
>;

export const Stepper = ({
  size = 'medium',
  className,
  children,
  fullHeight,
  steps,
  testId,
  ...rest
}: StepperProps) => {
  if (!children && !steps) {
    return null;
  }
  return (
    <InternalContext.Provider value={{ size }}>
      <MuiStepper
        className={clsx(
          'DS-Stepper-root',
          `DS-Stepper-size${capitalize(size)}`,
          fullHeight && 'DS-Stepper-fullHeight',
          className
        )}
        data-testid={testId}
        {...rest}
      >
        {children ||
          [...Array(steps)].map((_, index) => (
            <Step key={index} content={(index + 1).toString()} />
          ))}
      </MuiStepper>
    </InternalContext.Provider>
  );
};
