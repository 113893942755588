import { useRef } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import { useModal } from 'mui-modal-provider';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  PreviewSnippet,
} from '@cast/design-system';
import { Cluster } from '@cast/types';

import { CloseDialogButton } from 'components/dialogs';
import { K8sProviderIcon } from 'components/icons';
import { notify } from 'core/snackbars';
import { ShowModalOutput } from 'types/modal';

type Props = DialogProps & {
  cluster: Cluster;
  onClose: () => void;
};

const DisconnectPhase1ClusterDialog = ({
  cluster,
  onClose,
  ...props
}: Props) => {
  return (
    <Dialog {...props} onClose={onClose} testId="phase1-disconnect-dialog">
      <DialogTitle sx={{ p: '16px 32px' }}>
        <Stack gap={24}>
          <Stack direction="row">
            <Stack direction="row" gap={4}>
              <K8sProviderIcon provider={cluster.providerType} size={24} />
              <Typography variant="A1">{cluster.name}</Typography>
            </Stack>

            <CloseDialogButton onClose={onClose} />
          </Stack>

          <Stack gap={8}>
            <Typography variant="h4">Disconnect your cluster</Typography>
            <Typography variant="P14R" color="grey.500">
              To disconnect your cluster remove CAST AI agent.
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>

      <Divider sx={{ borderColor: 'grey.100' }} />

      <DialogContent sx={{ p: '16px 32px 0px' }}>
        <Stack gap={16}>
          <Stack gap={8}>
            <Stack gap={6}>
              <Typography variant="A1">Agent removal script</Typography>
              <Typography variant="P12R" color="grey.500">
                Ensure that kubectl is installed and the cluster is accessible.
                Copy the provided script and run it on a local terminal or cloud
                shell.
              </Typography>
            </Stack>
          </Stack>

          <PreviewSnippet
            data-testid="phase1-offboarding-script"
            code="kubectl delete namespace castai-agent"
            onCopy={() => notify.success('Copied to clipboard!')}
            p="8px 16px"
          />

          <Alert variant="filled" color="info">
            <Typography variant="P12R">
              The cluster will be disconnected immediately, however it might
              take{' '}
              <Typography variant="P12B" component="span">
                up to 5 min
              </Typography>{' '}
              for status to change in the console.
            </Typography>
          </Alert>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: '16px 32px' }}>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="tertiary"
            onClick={onClose}
            testId="close-dialog-action"
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const useDisconnectPhase1ClusterDialog = () => {
  const { showModal } = useModal();
  const modalInstance = useRef<ShowModalOutput | undefined>();

  return {
    openDisconnectPhase1ClusterDialog: (cluster: Cluster) => {
      modalInstance.current = showModal(
        DisconnectPhase1ClusterDialog,
        {
          cluster,
          onClose: () => modalInstance.current?.destroy(),
        },
        { rootId: 'root' }
      );
    },
  };
};
