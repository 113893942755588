import { useThemeColors } from 'hooks/theme';

export const useGetSelectChipSx = () => {
  const [chipColor] = useThemeColors('grey.600');
  return {
    '.DS-Dropdown-multiValueCount': {
      backgroundColor: `${chipColor} !important`,
    },
  };
};
