import { CountryCodeMap } from '@cast/constants';

export const searchFilter = (item: CountryCodeMap, value: string) => {
  const phonePrefixFn = (v: string) => v.replace(/[^0-9\s]/g, '').toLowerCase();
  const valuePhonePrefix = phonePrefixFn(value);
  const optionPhonePrefix = phonePrefixFn(item.phone);
  const condition1 =
    valuePhonePrefix.length > 0
      ? optionPhonePrefix.startsWith(valuePhonePrefix)
      : false;
  const condition2 = item.label.toLowerCase().startsWith(value.toLowerCase());

  return condition1 || condition2;
};
