import { Fragment, ReactNode, useState } from 'react';

import { styled } from '@mui/material';
import Popover from '@mui/material/Popover';

import { MultiSorterOwnerState, MultiSorterProps } from './types';
import { SortDescending, FunnelSimple, SortAscending } from '../../../icons';
import { List, ListItem, ListItemText } from '../../../lists';
import { ColumnProps, SortDirection, SortingState } from '../../types';

type SortBy = {
  name: ReactNode;
  accessor: Required<ColumnProps>['accessor'];
};

const MultiSorterPopover = styled(Popover, {
  name: 'DsMultiSorter',
  slot: 'Popover',
  target: 'DsMultiSorter-popover',
  overridesResolver: (_, styles) => [styles.popover],
})<{ ownerState: MultiSorterOwnerState }>({});

const MultiSorterIconWrapper = styled('div', {
  name: 'DsMultiSorter',
  slot: 'IconWrapper',
  target: 'DsMultiSorter-IconWrapper',
  overridesResolver: (_, styles) => [styles.iconWrapper],
})<{ ownerState: MultiSorterOwnerState }>({});

type MultiSorterIconProps = {
  sortingState?: SortingState;
};

const MultiSorterIcon = styled(
  ({ sortingState, ...props }: MultiSorterIconProps) => {
    if (sortingState?.sortBy.direction === 'asc') {
      return <SortAscending {...props} />;
    }

    if (sortingState?.sortBy.direction === 'desc') {
      return <SortDescending {...props} />;
    }

    return <FunnelSimple {...props} />;
  },
  {
    name: 'DsMultiSorter',
    slot: 'Icon',
    target: 'DsMultiSorter-Icon',
    overridesResolver: (_, styles) => [styles.icon],
  }
)<MultiSorterIconProps & { ownerState: MultiSorterOwnerState }>({});

export const makeMultiSorter = (sortBy: SortBy[]) => {
  const MultiSorter = (props: MultiSorterProps) => {
    const {
      api,
      columnModel: { id, sortable },
    } = props;
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const isOpen = !!anchorEl;

    if (!sortable) {
      return null;
    }
    const sortingState =
      api.state.sortingState?.columnId === id
        ? api.state.sortingState
        : undefined;
    const currentAccessor = sortingState?.sortBy.accessor;
    const currentSortDirection = sortingState?.sortBy.direction;

    const ownerState = { ...props, isOpen, sortingActive: !!sortingState };

    return (
      <>
        <MultiSorterPopover
          ownerState={ownerState}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <List size="small" disablePadding>
            {sortBy.map(({ name, accessor }, index) => {
              const thisAccessorActive = accessor === currentAccessor;
              const handleClick = (direction: SortDirection) => {
                const newState =
                  thisAccessorActive && currentSortDirection === direction
                    ? undefined
                    : {
                        columnId: id,
                        sortBy: { accessor, direction },
                      };
                api.sortColumn(newState);
                setAnchorEl(null);
              };
              return (
                <Fragment key={index}>
                  <ListItem
                    selected={
                      thisAccessorActive && currentSortDirection === 'desc'
                    }
                    onClick={() => handleClick('desc')}
                  >
                    <ListItemText>{name} high to low</ListItemText>
                  </ListItem>
                  <ListItem
                    selected={
                      thisAccessorActive && currentSortDirection === 'asc'
                    }
                    onClick={() => handleClick('asc')}
                  >
                    <ListItemText>{name} low to high</ListItemText>
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        </MultiSorterPopover>
        <MultiSorterIconWrapper
          onClick={(e) => setAnchorEl(e.target)}
          ownerState={ownerState}
        >
          <MultiSorterIcon
            ownerState={ownerState}
            sortingState={sortingState}
          />
        </MultiSorterIconWrapper>
      </>
    );
  };
  return MultiSorter;
};
