import { ReactNode, useContext } from 'react';

import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import { Skeleton } from '@cast/design-system';

import { DrawerContext } from './DrawerContext';

const TextSkeleton = () => <Skeleton width={150} height={16} />;

type DetailProps = {
  title: string | ReactNode;
  detail: string | ReactNode;
  type?: 'column' | 'row';
  className?: string;
  titleProps?: TypographyProps;
  detailProps?: TypographyProps;
  sx?: SxProps;
  skeleton?: ReactNode;
};

export const Section = ({
  title,
  detail,
  type = 'row',
  className,
  titleProps = {},
  detailProps = {},
  sx,
  skeleton,
}: DetailProps) => {
  const { isLoading } = useContext(DrawerContext);

  const props: BoxProps =
    type === 'row'
      ? { justifyContent: 'space-between', alignItems: 'center' }
      : { flexDirection: 'column', alignItems: 'flex-start', minWidth: 0 };
  return (
    <Box
      display="flex"
      {...props}
      className={clsx('Detail-root', className)}
      sx={sx}
    >
      <Typography
        variant="L14R"
        color="grey.500"
        className="Detail-title"
        {...titleProps}
      >
        {title}
      </Typography>

      {isLoading ? (
        skeleton || <TextSkeleton />
      ) : typeof detail === 'string' ? (
        <Typography variant="P14R" color="grey.900" {...detailProps}>
          {detail}
        </Typography>
      ) : (
        detail
      )}
    </Box>
  );
};
