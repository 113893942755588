import {
  formatDistanceToNowStrict,
  formatDistanceToNow,
  parseISO,
} from 'date-fns';
import dayjs from 'dayjs';

import { DATE_TIME_PRECISE } from '@cast/constants';

export const getTimeAgo = (
  time: string,
  suffix?: boolean,
  strict?: boolean
) => {
  try {
    const date = parseISO(time);

    if (strict) {
      return formatDistanceToNowStrict(date, { addSuffix: Boolean(suffix) });
    }

    return formatDistanceToNow(date, {
      addSuffix: Boolean(suffix),
    });
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const toDateTimePrecise = (time: string) => {
  try {
    return dayjs(time).format(DATE_TIME_PRECISE);
  } catch (e) {
    console.error(e);
  }
};
