import { ComponentType, ReactNode, useMemo, useState } from 'react';

import { useTheme } from '@mui/material';
import sortBy from 'lodash/sortBy';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  Sector,
  Tooltip,
  TooltipProps,
} from 'recharts';

import { getThemeColor } from 'utils/theme';

import { PieLabel } from '../_components';
import { useIsAnimationActive } from '../hooks';

type Props = {
  title?: string;
  children?: ReactNode;
  width?: number;
  height?: number;
  size?: number;
  data: {
    color: string;
    value: number;
    [key: string]: unknown;
  }[];
  TooltipComponent?: ComponentType<{ payload?: any }>;
  tooltipProps?: Partial<TooltipProps<any, any>>;
  disableOnHover?: boolean;
  disableAnimation?: boolean;
  disableDataSorting?: boolean;
  startAngle?: number;
  endAngle?: number;
};

type ActiveShapeProps = {
  outerRadius: number;
  percentage: number;
  cx: number;
  cy: number;
  middleRadius: number;
  midAngle: number;
  payload: { payload: never };
  calcSize: (value: number) => number;
};
const ActiveShape = ({ calcSize, ...props }: ActiveShapeProps) => {
  return (
    <Sector
      {...props}
      innerRadius={calcSize(75)}
      outerRadius={calcSize(125)}
      css={{
        filter: `drop-shadow(0px 0px ${calcSize(3)}px rgba(0, 0, 0, 0.32))`,
      }}
    />
  );
};

const DEFAULT_SIZE = 260;

const makeCalcSize = (currentSize: number) => (value: number) =>
  (currentSize / DEFAULT_SIZE) * value;

export const DonutChart = ({
  data: _data,
  title,
  children,
  size = DEFAULT_SIZE,
  TooltipComponent,
  tooltipProps = {},
  disableOnHover = false,
  disableAnimation = false,
  disableDataSorting = false,
  startAngle = -90,
  endAngle,
}: Props) => {
  const theme = useTheme();
  const calcSize = useMemo(() => makeCalcSize(size), [size]);
  const [activeShapeIndex, setActiveShapeIndex] = useState<number>(-1);

  const data = useMemo(
    () => (disableDataSorting ? _data : sortBy(_data, 'value')),
    [_data, disableDataSorting]
  );
  const isAnimationActive = useIsAnimationActive(data);
  return (
    <PieChart
      width={size}
      height={size}
      onMouseLeave={() => {
        setActiveShapeIndex(-1);
      }}
      css={{ '& g:focus, & path:focus': { outline: 'none' } }}
    >
      <Pie
        isAnimationActive={!disableAnimation && isAnimationActive}
        startAngle={startAngle}
        endAngle={endAngle}
        dataKey="value"
        data={data}
        innerRadius={calcSize(70)}
        onMouseEnter={
          disableOnHover ? undefined : (_, index) => setActiveShapeIndex(index)
        }
        paddingAngle={calcSize(4)}
        minAngle={calcSize(2)}
        cornerRadius={calcSize(4)}
        outerRadius={calcSize(120)}
        activeIndex={activeShapeIndex}
        activeShape={(props) => <ActiveShape {...props} calcSize={calcSize} />}
      >
        {!!title && (
          <Label
            width={30}
            position="center"
            content={<PieLabel title={title} />}
          />
        )}
        {data.map((entry, index) => (
          <Cell
            stroke="100px"
            key={index}
            fill={getThemeColor(theme, entry.color)}
          />
        ))}
      </Pie>
      {TooltipComponent && (
        <Tooltip
          isAnimationActive={false}
          allowEscapeViewBox={{ x: false, y: false }}
          wrapperStyle={{ zIndex: theme.zIndex.tooltip! + 1 }}
          {...tooltipProps}
          content={(props) => {
            return (
              <TooltipComponent
                // eslint-disable-next-line
                payload={props!.payload as any}
              />
            );
          }}
        />
      )}

      {children}
    </PieChart>
  );
};
