import { PropsWithChildren } from 'react';

import capitalize from 'lodash/capitalize';

import { useSessionQuery, useUserProfileQuery } from 'hooks/queries';

import { AuthContext } from './context';
import { _CASTAI_USER } from './user';

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { isLoading, userSession } = useSessionQuery();
  const { userProfile } = useUserProfileQuery(userSession?.userId);
  const isAuthenticated = Boolean(!isLoading && userSession && !!userProfile);
  const user =
    userProfile && userSession
      ? {
          id: userSession.userId,
          guid: userProfile.id!,
          firstName: userSession.nickname, // TODO: change to real name when 'old' users are fixed
          email: userSession.email,
          firstLogin: !!userProfile.firstLogin,
          name: userProfile.name,
        }
      : undefined;
  _CASTAI_USER.id = user?.id as string;
  _CASTAI_USER.guid = user?.guid as string;
  _CASTAI_USER.firstName = user?.firstName ? capitalize(user?.firstName) : '';
  _CASTAI_USER.name = user?.name as string;
  _CASTAI_USER.email = user?.email as string;
  _CASTAI_USER.firstLogin = user?.firstLogin as boolean;
  return (
    <AuthContext.Provider value={{ isAuthenticated, user }}>
      {children}
    </AuthContext.Provider>
  );
};
