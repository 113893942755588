import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

export type Accessor<T = any> = string | number | ((entity: T) => any);
export type Direction = 'asc' | 'desc';
export type SortingType = 'number' | 'string';
export type SortBy<T = any> = {
  accessor: Accessor<T>;
  direction: Direction;
  sortingType?: SortingType;
};

const accessorsByType = {
  string: (accessor: string | number) => (entry: any) => {
    const a = get(entry, accessor);
    if (a == null) {
      return '';
    }
    return String(a);
  },
  number: (accessor: string | number) => (entry: any) => {
    const a = Number(get(entry, accessor));
    if (isNaN(a)) {
      return 0;
    }
    return a;
  },
};

const getAccessor = (
  accessor: Accessor,
  sortingType?: SortingType
): Accessor => {
  if (sortingType && typeof accessor !== 'function') {
    return accessorsByType[sortingType](accessor);
  }
  return accessor;
};

export const sort = <T>(data: T[], sorting: SortBy<T> | SortBy<T>[]) => {
  const accessors: Accessor<T>[] = [];
  const directions: Direction[] = [];
  if (Array.isArray(sorting)) {
    for (const { accessor, direction, sortingType } of sorting) {
      accessors.push(getAccessor(accessor, sortingType));
      directions.push(direction);
    }
  } else {
    accessors.push(getAccessor(sorting.accessor, sorting.sortingType));
    directions.push(sorting.direction);
  }
  return orderBy(data, accessors, directions);
};
