import { NodeResourceOffering } from '@cast/types';

export const CPU_OVER_PROVISIONING_RANGE = [40, 60];
export const MEMORY_OVER_PROVISIONING_RANGE = [30, 50];

export type InstanceFixture = {
  name: string;
  price: string;
  resources: {
    cpuAllocatableMilli: number;
    memAllocatableMib: number;
    cpuCapacityMilli: number;
    memCapacityMib: number;
  };
  resourceOffering: NodeResourceOffering;
  generation: 1 | 2;
  arch: 'amd64' | 'arm64';
};

export const inventory: Record<
  NodeResourceOffering,
  Record<string, InstanceFixture>
> = {
  [NodeResourceOffering.SPOT]: {
    'c5.2xlarge': {
      name: 'c5.2xlarge',
      price: '0.0994',
      resources: {
        cpuAllocatableMilli: 7910,
        memAllocatableMib: 15048,
        cpuCapacityMilli: 8000, // 8 core
        memCapacityMib: 16384, // 16 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 1,
      arch: 'amd64',
    },
    'c5.4xlarge': {
      name: 'c5.4xlarge',
      price: '0.1523',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 1,
      arch: 'amd64',
    },
    'm6g.2xlarge': {
      name: 'm6g.2xlarge',
      price: '0.3041',
      resources: {
        cpuAllocatableMilli: 7910,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 8000, // 8 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 2,
      arch: 'arm64',
    },
    'c5a.8xlarge': {
      name: 'c5a.8xlarge',
      price: '0.3041',
      resources: {
        cpuAllocatableMilli: 31850,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 2,
      arch: 'amd64',
    },
    'c6i.4xlarge': {
      name: 'c6i.4xlarge',
      price: '0.1596',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 2,
      arch: 'amd64',
    },
    'c6g.4xlarge': {
      name: 'c6g.4xlarge',
      price: '0.1596',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 2,
      arch: 'arm64',
    },
    'm5.4xlarge': {
      name: 'm5.4xlarge',
      price: '0.2123',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 1,
      arch: 'amd64',
    },
    'c6g.8xlarge': {
      name: 'c6g.8xlarge',
      price: '0.5145',
      resources: {
        cpuAllocatableMilli: 31850,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 2,
      arch: 'arm64',
    },
    'm5d.8xlarge': {
      name: 'm5d.8xlarge',
      price: '0.2123',
      resources: {
        cpuAllocatableMilli: 31640,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 2,
      arch: 'amd64',
    },
    'r5.4xlarge': {
      name: 'r5.4xlarge',
      price: '0.1952',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 1,
      arch: 'amd64',
    },
    'm7g.8xlarge': {
      name: 'm7g.8xlarge',
      price: '0.5657',
      resources: {
        cpuAllocatableMilli: 31640,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.SPOT,
      generation: 2,
      arch: 'arm64',
    },
  },
  [NodeResourceOffering.ON_DEMAND]: {
    'c5.2xlarge': {
      name: 'c5.2xlarge',
      price: '0.444',
      resources: {
        cpuAllocatableMilli: 7910,
        memAllocatableMib: 15048,
        cpuCapacityMilli: 8000, // 8 core
        memCapacityMib: 16384, // 16 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 1,
      arch: 'amd64',
    },
    'c5.4xlarge': {
      name: 'c5.4xlarge',
      price: '0.888',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 1,
      arch: 'amd64',
    },
    'c5a.8xlarge': {
      name: 'c5a.8xlarge',
      price: '1.7761',
      resources: {
        cpuAllocatableMilli: 31850,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 2,
      arch: 'amd64',
    },
    'c6i.4xlarge': {
      name: 'c6i.4xlarge',
      price: '0.8956',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 2,
      arch: 'amd64',
    },
    'm5.4xlarge': {
      name: 'm5.4xlarge',
      price: '0.8956',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 1,
      arch: 'amd64',
    },
    'm5d.8xlarge': {
      name: 'm5d.8xlarge',
      price: '1.7913',
      resources: {
        cpuAllocatableMilli: 31640,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 2,
      arch: 'amd64',
    },
    'r5.4xlarge': {
      name: 'r5.4xlarge',
      price: '0.9032',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 1,
      arch: 'amd64',
    },
    'm6g.2xlarge': {
      name: 'm6g.2xlarge',
      price: '0.308',
      resources: {
        cpuAllocatableMilli: 7910,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 8000, // 8 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 2,
      arch: 'arm64',
    },
    'c6g.4xlarge': {
      name: 'c6g.4xlarge',
      price: '0.544',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 2,
      arch: 'arm64',
    },
    'c6g.8xlarge': {
      name: 'c6g.8xlarge',
      price: '1.088',
      resources: {
        cpuAllocatableMilli: 31850,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 2,
      arch: 'arm64',
    },
    'm7g.8xlarge': {
      name: 'm7g.8xlarge',
      price: '1.3056',
      resources: {
        cpuAllocatableMilli: 31640,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.ON_DEMAND,
      generation: 2,
      arch: 'arm64',
    },
  },
  [NodeResourceOffering.FALLBACK]: {
    'c5.2xlarge': {
      name: 'c5.2xlarge',
      price: '0.444',
      resources: {
        cpuAllocatableMilli: 7910,
        memAllocatableMib: 15048,
        cpuCapacityMilli: 8000, // 8 core
        memCapacityMib: 16384, // 16 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 1,
      arch: 'amd64',
    },
    'c5.4xlarge': {
      name: 'c5.4xlarge',
      price: '0.888',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 1,
      arch: 'amd64',
    },
    'c5a.8xlarge': {
      name: 'c5a.8xlarge',
      price: '1.7761',
      resources: {
        cpuAllocatableMilli: 31850,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 2,
      arch: 'amd64',
    },
    'c6i.4xlarge': {
      name: 'c6i.4xlarge',
      price: '0.8956',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 2,
      arch: 'amd64',
    },

    'm5.4xlarge': {
      name: 'm5.4xlarge',
      price: '0.8956',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 1,
      arch: 'amd64',
    },
    'm5d.8xlarge': {
      name: 'm5d.8xlarge',
      price: '1.7913',
      resources: {
        cpuAllocatableMilli: 31640,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 2,
      arch: 'amd64',
    },
    'r5.4xlarge': {
      name: 'r5.4xlarge',
      price: '0.9032',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 1,
      arch: 'amd64',
    },
    'm6g.2xlarge': {
      name: 'm6g.2xlarge',
      price: '0.308',
      resources: {
        cpuAllocatableMilli: 7910,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 8000, // 8 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 2,
      arch: 'arm64',
    },
    'c6g.4xlarge': {
      name: 'c6g.4xlarge',
      price: '0.544',
      resources: {
        cpuAllocatableMilli: 15820,
        memAllocatableMib: 30097,
        cpuCapacityMilli: 16000, // 16 core
        memCapacityMib: 32768, // 32 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 2,
      arch: 'arm64',
    },
    'c6g.8xlarge': {
      name: 'c6g.8xlarge',
      price: '1.088',
      resources: {
        cpuAllocatableMilli: 31850,
        memAllocatableMib: 60194,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 65536, // 64 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 2,
      arch: 'arm64',
    },
    'm7g.8xlarge': {
      name: 'm7g.8xlarge',
      price: '1.3056',
      resources: {
        cpuAllocatableMilli: 31640,
        memAllocatableMib: 126740,
        cpuCapacityMilli: 32000, // 32 core
        memCapacityMib: 131072, // 128 gib
      },
      resourceOffering: NodeResourceOffering.FALLBACK,
      generation: 2,
      arch: 'arm64',
    },
  },
};
