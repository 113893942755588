import { Stack } from '@mui/material';

import {
  InputsStack,
  RemoveInputs,
  RffInput,
  RffPasswordInput,
} from 'core/forms/rff';

export const AuthenticationKeysRow = () => {
  return (
    <InputsStack
      name="authKeys"
      sx={{ '& .RffInputsArray-rows': { gap: '16px' } }}
      addRowButtonTestId="webhook-add-header-button"
      renderInputsRow={({ index }) => {
        return (
          <Stack
            direction="row"
            flexWrap="nowrap"
            flex="1"
            gap="16px"
            alignItems="center"
          >
            <RffInput
              name={`authKeys[${index}].key`}
              label="Header"
              placeholder="Authorization"
              sx={{ width: '50%' }}
              testId={`webhook-header-name-${index}`}
            />
            <RffPasswordInput
              name={`authKeys[${index}].value`}
              label="Key"
              placeholder="Enter key"
              sx={{ width: '50%' }}
              testId={`webhook-header-key-${index}`}
              endOutsideAdornment={<RemoveInputs />}
            />
          </Stack>
        );
      }}
    />
  );
};
