import { styled } from '@mui/material';

import {
  ListItem,
  ListItemText,
  ListProps,
  List,
  ListItemProps,
  ListItemTextProps,
  withProps,
  focusProps,
} from '@cast/design-system';

export const DrawerActionList = styled(
  withProps(List, { size: 'large' })
)<ListProps>({});

export const DrawerActionListItem = styled(ListItem)<ListItemProps>(
  ({ theme }) => ({
    minHeight: '48px',
    padding: '0 32px',
    borderWidth: '1px 0px',
    borderStyle: 'solid',
    borderColor: 'transparent',

    '&.Mui-selected': {
      color: theme.palette.blue[600],
      borderColor: theme.palette.blue[100],
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.grey[100],
    },
    '&:hover': {
      borderColor: theme.palette.grey[200],
    },
    '&:focus-visible': {
      ...focusProps(theme),
    },
  })
);

export const DrawerActionListItemText = styled(
  withProps(ListItemText, {
    primaryTypographyProps: { variant: 'P14M' },
    secondaryTypographyProps: { variant: 'P14R' },
  })
)<ListItemTextProps>({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
