import {
  SerializableColumnGroup,
  SerializableGroupedColumn,
  serializableSettings,
} from './types';
import { GroupedColumnProps, isColumnsGroup } from '../types';

const applySettings = (p: GroupedColumnProps) => {
  return serializableSettings.reduce((acc, curr) => {
    if (curr in p) {
      acc[curr] = p[curr];
    }
    return acc;
  }, {} as Partial<SerializableGroupedColumn>);
};

export const getSerializableColumns = (
  props: GroupedColumnProps[]
): SerializableGroupedColumn[] => {
  return props.map((p) => {
    const result = { id: p.id, ...applySettings(p) };
    if (isColumnsGroup(p)) {
      (result as SerializableColumnGroup).columns = p.columns.map((col) => ({
        id: col.id,
        ...applySettings(col),
      }));
    }
    return result;
  });
};
