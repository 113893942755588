import { bindToggle } from 'material-ui-popup-state/core';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';

import { List, ListItem, ListItemText } from '@cast/design-system';

import { EXTERNAL_LINKS } from 'common/links';
import { openChat } from 'utils/supportChat';

import { HeaderButton, HeaderPopover } from './_components';

export const HelpMenu = () => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'help-menu',
  });
  const popoverState = bindPopover(popupState);

  return (
    <>
      <HeaderButton
        {...bindToggle(popupState)}
        active={popupState.isOpen}
        testId="help-menu-btn"
      >
        Help
      </HeaderButton>
      <HeaderPopover {...popoverState} testId="help-menu">
        <List size="medium" disablePadding sx={{ minWidth: '190px' }}>
          <ListItem
            onClick={() => {
              openChat();
              popoverState.onClose();
            }}
          >
            <ListItemText>Get support</ListItemText>
          </ListItem>

          <ListItem
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component="a"
            target="_blank"
            rel="noreferer"
            href={EXTERNAL_LINKS.castai_slack_community}
          >
            <ListItemText>Community</ListItemText>
          </ListItem>
        </List>
      </HeaderPopover>
    </>
  );
};
