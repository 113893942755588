import { Typography } from '@mui/material';

import { PreviewJson } from '@cast/design-system';

type Props = { details?: string };

export const NotificationDetails = ({ details }: Props) => {
  if (!details?.length) {
    return null;
  }
  return (
    <PreviewJson
      rootSx={{ mt: 24 }}
      data={details}
      renderFallback={(data) => (
        <Typography
          px="35px"
          variant="P14R"
          color="white"
          py="16px"
          component="p"
        >
          {data}
        </Typography>
      )}
    />
  );
};
