import { createTheme } from '@mui/material/styles';

import { breakpoints } from './breakpoints';
import {
  aqua,
  blue,
  green,
  grey,
  indigo,
  primary,
  red,
  secondary,
  yellow,
} from './colors';
import { globalStyles } from './globalStyles';
import { shadowsTheme } from './shadows';
import { zIndex } from './zIndex';
import { componentsThemes } from '../components/componentsThemes';
import {
  fonts,
  typographyTheme,
  typographyVariantMapping,
} from '../components/typography/typographyTheme';
import { getThemeColor } from '../utils/theme';

const boxShadowKeys = [
  'focusGlow',
  'activeGlow',
  'hoverComponent',
  'dropdown',
  'shadow',
  'hover',
  'drawer',
  'tooltip',
];

const boxShadowResolver = ({ boxShadow, theme }: any) => {
  const index = boxShadowKeys.indexOf(boxShadow);
  if (index > -1) {
    return { boxShadow: theme.shadows[index + 1] };
  }
  return { boxShadow };
};

const strokeResolver = ({ stroke, theme }: any) => {
  return {
    stroke: getThemeColor(theme, stroke),
  };
};

const fillResolver = ({ fill, theme }: any) => {
  return {
    fill: getThemeColor(theme, fill),
  };
};

const makeBorderResolver =
  (
    what: 'border' | 'borderTop' | 'borderRight' | 'borderBottom' | 'borderLeft'
  ) =>
  (props: any) => {
    const val = props[what];
    const border =
      props.theme.borders?.[val] ?? getThemeColor(props.theme, val);
    if (border !== val) {
      return {
        [what]: `1px solid ${border}`,
      };
    }
    if (typeof border === 'number') {
      return { [what]: `${border} solid` };
    }
    return { [what]: border };
  };

const lineHeightResolver = (lineHeight: any, _: any) => {
  if (typeof lineHeight === 'number') {
    return `${lineHeight}px`;
  }
  return lineHeight;
};

export const theme = createTheme({
  constants: {
    shellClass: 'DS-Shell',
    sidebarMinimizedClass: 'DS-Layout-Sidebar-minimized',
    sidebarMaximizedClass: 'DS-Layout-Sidebar-maximized',
    fonts,
  },
  breakpoints: {
    values: breakpoints,
  },
  zIndex,
  components: {
    ...globalStyles,
    ...componentsThemes,
    MuiTypography: {
      defaultProps: {
        variantMapping: typographyVariantMapping,
      },
    },
  },
  typography: typographyTheme,
  shadows: shadowsTheme,
  palette: {
    blue,
    grey,
    green,
    aqua,
    indigo,
    red,
    yellow,
    primary: {
      main: primary[600],
    },
    secondary: {
      main: secondary[600],
    },
    tertiary: {
      main: grey[200],
    },
    exception: {
      main: 'white',
    },
    buttonText: {
      main: '#fff0', // Transparent
    },
    ghost: {
      main: '#fff0', // Transparent
    },
    danger: {
      main: red[500],
    },
    info: {
      main: blue[500],
      light: blue[500],
      dark: blue[500],
    },
    error: {
      main: red[500],
      light: red[500],
      dark: red[500],
    },
    exceptional: {
      main: indigo[400],
      light: indigo[400],
      dark: indigo[400],
    },
    advisory: {
      light: grey[900],
      dark: red[500],
      main: red[200],
    },
    text: {
      primary: grey[900],
      secondary: grey[500],
      disabled: grey[300],
    },
  },
  spacing: 1,
  unstable_sxConfig: {
    boxShadow: {
      style: boxShadowResolver,
    },
    stroke: {
      style: strokeResolver,
    },
    fill: {
      style: fillResolver,
    },
    border: {
      style: makeBorderResolver('border'),
    },
    borderTop: {
      style: makeBorderResolver('borderTop'),
    },
    borderRight: {
      style: makeBorderResolver('borderRight'),
    },
    borderBottom: {
      style: makeBorderResolver('borderBottom'),
    },
    borderLeft: {
      style: makeBorderResolver('borderLeft'),
    },
    lineHeight: {
      transform: lineHeightResolver,
    },
  },
});

export * from './utils';
export * from './shadows';
