import { forwardRef } from 'react';

import { styled, StyledComponentProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';

import { ChipProps } from '@cast/design-system';
import { NodeResourceOffering } from '@cast/types';

import { LabelChip } from 'components/chips';

type IndicatorSize = 'small' | 'medium';

export type InstanceTagProps = ChipProps & {
  resourceOffering: NodeResourceOffering;
  size?: IndicatorSize;
};

const ResourceOfferingTagInner = forwardRef<HTMLDivElement, InstanceTagProps>(
  (
    // eslint-disable-next-line react/prop-types
    {
      resourceOffering,
      size = 'medium',
      className,
      ...props
    }: InstanceTagProps,
    ref
  ) => {
    return (
      <LabelChip
        ref={ref}
        className={clsx(className, resourceOffering, size)}
        {...props}
      >
        {resourceOffering}
      </LabelChip>
    );
  }
);
ResourceOfferingTagInner.displayName = 'ResourceOfferingTagInner';

export const resourceOfferingTagStyle = (theme: Theme) =>
  ({
    display: 'inline-flex',
    alignItems: 'center',
    width: 'max-content',
    borderRadius: '20px',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: 0,
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    letterSpacing: '0.5px',
    paddingLeft: '7px',
    paddingRight: '7px',
    '&.small': {
      height: 18,
    },
    '&.medium': {
      height: 22,
    },
    '&.spot': {
      backgroundColor: theme.palette.indigo[800],
      color: 'white',
    },
    '&.on-demand': {
      backgroundColor: theme.palette.blue[300],
      color: 'white',
    },
    '&.fallback': {
      backgroundColor: theme.palette.yellow[400],
      color: 'white',
    },
  } as StyledComponentProps);

export const ResourceOfferingTag = styled(ResourceOfferingTagInner)(
  ({ theme }) => ({
    ...resourceOfferingTagStyle(theme),
  })
);
ResourceOfferingTag.displayName = 'ResourceOfferingTag';
