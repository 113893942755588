import dayjs from 'dayjs';

import { DateRangeFilter, Filters } from './types';

const defaultTimezone = dayjs.tz.guess();

export const allFilters: Filters = {
  [DateRangeFilter.ThisMonth]: {
    label: 'This month',
    date: (timezone = defaultTimezone) => ({
      from: dayjs().tz(timezone).startOf('month').toISOString(),
      to: dayjs().tz(timezone).endOf('month').toISOString(),
    }),
  },
  [DateRangeFilter.Last15Mins]: {
    label: 'Last 15 minutes',
    date: (timezone) => ({
      from: dayjs().tz(timezone).subtract(15, 'minutes').toISOString(),
      to: dayjs().tz(timezone).toISOString(),
    }),
  },
  [DateRangeFilter.Last30Mins]: {
    label: 'Last 30 minutes',
    date: (timezone) => ({
      from: dayjs().tz(timezone).subtract(30, 'minutes').toISOString(),
      to: dayjs().tz(timezone).toISOString(),
    }),
  },
  [DateRangeFilter.LastHour]: {
    label: 'Last hour',
    date: (timezone) => ({
      from: dayjs().tz(timezone).subtract(1, 'hour').toISOString(),
      to: dayjs().tz(timezone).toISOString(),
    }),
  },
  [DateRangeFilter.Last24Hours]: {
    label: 'Last 24 hours',
    date: (timezone) => ({
      from: dayjs().tz(timezone).subtract(24, 'hours').toISOString(),
      to: dayjs().tz(timezone).toISOString(),
    }),
  },
  [DateRangeFilter.TODAY]: {
    label: 'Today',
    date: (timezone) => ({
      from: dayjs().tz(timezone).startOf('day').toISOString(),
      to: dayjs().tz(timezone).endOf('day').toISOString(),
    }),
  },
  [DateRangeFilter.Last7Days]: {
    label: 'Last 7 days',
    date: (timezone, fromNow) => ({
      from: fromNow
        ? dayjs().tz(timezone).subtract(7, 'days').toISOString()
        : dayjs().tz(timezone).startOf('day').subtract(7, 'days').toISOString(),
      to: fromNow
        ? dayjs().tz(timezone).toISOString()
        : dayjs().tz(timezone).endOf('day').toISOString(),
    }),
  },
  [DateRangeFilter.Last30Days]: {
    label: 'Last 30 days',
    date: (timezone, fromNow) => ({
      from: fromNow
        ? dayjs().tz(timezone).subtract(30, 'days').toISOString()
        : dayjs()
            .tz(timezone)
            .startOf('day')
            .subtract(30, 'days')
            .toISOString(),
      to: fromNow
        ? dayjs().tz(timezone).toISOString()
        : dayjs().tz(timezone).endOf('day').toISOString(),
    }),
  },
  [DateRangeFilter.Last1Year]: {
    label: 'Last 1 year',
    date: (timezone) => {
      const from = dayjs().tz(timezone).startOf('year').subtract(1, 'year');

      return {
        from: from.toISOString(),
        to: from.endOf('year').toISOString(),
      };
    },
  },
  [DateRangeFilter.LastMonth]: {
    label: 'Last month',
    date: (timezone) => {
      const from = dayjs().tz(timezone).startOf('month').subtract(1, 'month');
      return {
        from: from.toISOString(),
        to: from.endOf('month').toISOString(),
      };
    },
  },
  [DateRangeFilter.LastWeek]: {
    label: 'Last week',
    date: (timezone) => {
      const from = dayjs().tz(timezone).startOf('week').subtract(1, 'week');
      return {
        from: from.toISOString(),
        to: from.endOf('week').toISOString(),
      };
    },
  },
  [DateRangeFilter.Last2Weeks]: {
    label: 'Last 2 weeks',
    date: (timezone) => {
      return {
        from: dayjs()
          .tz(timezone)
          .startOf('week')
          .subtract(2, 'weeks')
          .toISOString(),
        to: dayjs()
          .tz(timezone)
          .startOf('week')
          .subtract(1, 'day')
          .endOf('week')
          .toISOString(),
      };
    },
  },
};
