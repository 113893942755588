import { ReactNode, isValidElement, cloneElement } from 'react';

import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

type Match = {
  index: number;
  url: string;
  text: string;
  lastIndex: number;
};

const defaultComponentDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number
): ReactNode => {
  return (
    <a href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  );
};

const identity = <T extends any = any>(str: T) => str;

const defaultMatchDecorator = (text: string): Match[] | null => {
  return linkify.match(text);
};

type Props = {
  children?: ReactNode;
  componentDecorator?: (a: string, b: string, num: number) => ReactNode;
  hrefDecorator?: (string: string) => string;
  matchDecorator?: (string: string) => Array<Match> | null;
  textDecorator?: (string: string) => string;
};

const parseString = (
  string: string,
  {
    componentDecorator = defaultComponentDecorator,
    hrefDecorator = identity,
    matchDecorator = defaultMatchDecorator,
    textDecorator = identity,
  }: Props
) => {
  if (string === '') {
    return string;
  }

  const matches = matchDecorator(string);
  if (!matches) {
    return string;
  }

  const elements = [];
  let lastIndex = 0;
  matches.forEach((match, i) => {
    // Push preceding text if there is any
    if (match.index > lastIndex) {
      elements.push(string.substring(lastIndex, match.index));
    }

    const decoratedHref = hrefDecorator(match.url);
    const decoratedText = textDecorator(match.text);
    const decoratedComponent = componentDecorator(
      decoratedHref,
      decoratedText,
      i
    );
    elements.push(decoratedComponent);

    lastIndex = match.lastIndex;
  });

  // Push remaining text if there is any
  if (string.length > lastIndex) {
    elements.push(string.substring(lastIndex));
  }

  return elements.length === 1 ? elements[0] : elements;
};

const parse = (children: ReactNode, props: Props, key = 0): any => {
  if (typeof children === 'string') {
    return parseString(children, props);
  } else if (
    isValidElement(children) &&
    children.type !== 'a' &&
    children.type !== 'button'
  ) {
    return cloneElement(
      children,
      { key },
      parse(children.props.children, props)
    );
  } else if (Array.isArray(children)) {
    return children.map((child, i) => parse(child, props, i));
  }

  return children;
};

const Linkify = (props: Props) => {
  if (!props.children) {
    return null;
  }
  return <>{parse(props.children, props)}</>;
};

export default Linkify;
