import { createContext, PropsWithChildren, useMemo } from 'react';

import dayjs from 'dayjs';

import { AvailableSavingsHistoryEntry, PriceType } from '@cast/types';
import { getPriceMultiplier } from '@cast/utils';

import { FailedToLoad } from 'components/messages';
import { useAvailableSavingsHistoryQuery } from 'hooks/queries/available-savings';
import { useCluster } from 'hooks/useCluster';
import { usePersistentState } from 'hooks/usePersistentState';
import { AvailableSavingsHistoryChartDataPoint } from 'types/available-savings';
import { TimeSeries } from 'types/metrics';

import { AvailableSavingsHistoryCostRate, Instance, Period } from '../types';
import { mapResponseToDataByHour } from '../utils';

type AvailableSavingsHistoryContextValue = {
  isLoading: boolean;
  data: AvailableSavingsHistoryEntry[] | undefined;
  dataPoints: TimeSeries<AvailableSavingsHistoryChartDataPoint> | undefined;
  historyPeriod: Period;
  instanceType: Instance;
  setInstanceType: (instanceType: Instance) => void;
  setHistoryPeriod: (period: Period) => void;
  costRate: AvailableSavingsHistoryCostRate;
  setCostRate: (clusterCost: AvailableSavingsHistoryCostRate) => void;
  priceMultiplier: number;
};

export const AvailableSavingsHistoryContext =
  createContext<AvailableSavingsHistoryContextValue>({} as never);

export const AvailableSavingsHistoryProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const { cluster } = useCluster();
  const id = cluster.id;
  const [historyPeriod, setHistoryPeriod] = usePersistentState<Period>(
    `available-savings.history.period-${id}`,
    Period.DAY
  );
  const [instanceType, setInstanceType] = usePersistentState<Instance>(
    `available-savings.history.instance-${id}`,
    Instance.ALL
  );

  const dateTo = useMemo(() => dayjs().endOf('hour').add(1, 'ms').utc(), []);

  const dateFrom = useMemo(() => {
    switch (historyPeriod) {
      case Period.DAY:
        return dateTo.subtract(24, 'hours');
      case Period.WEEK:
        return dateTo.subtract(7, 'days');
      case Period.MONTH:
        return dateTo.subtract(30, 'days');
      default:
        return dateTo.subtract(7, 'days');
    }
  }, [historyPeriod, dateTo]);

  const { isLoading, data, error, refetch } = useAvailableSavingsHistoryQuery(
    id,
    dateFrom.toISOString(),
    dateTo.toISOString()
  );

  const [costRate, setCostRate] =
    usePersistentState<AvailableSavingsHistoryCostRate>(
      `available-savings.history.cost-rate-${id}`,
      PriceType.MONTHLY
    );

  const priceMultiplier = getPriceMultiplier(costRate);

  const dataPoints = useMemo(() => {
    switch (historyPeriod) {
      case Period.DAY:
        return mapResponseToDataByHour(data, priceMultiplier).reverse();
      case Period.WEEK:
        return mapResponseToDataByHour(data, priceMultiplier, 24 * 7).reverse();
      case Period.MONTH:
        throw new Error('Monthly period not supported');
    }
  }, [data, historyPeriod, priceMultiplier]);

  return (
    <AvailableSavingsHistoryContext.Provider
      value={{
        isLoading,
        data,
        dataPoints,
        historyPeriod,
        setHistoryPeriod,
        costRate,
        setCostRate,
        priceMultiplier,
        instanceType,
        setInstanceType,
      }}
    >
      {error ? <FailedToLoad refresh={refetch} /> : children}
    </AvailableSavingsHistoryContext.Provider>
  );
};
