import { styled, useTheme } from '@mui/material';

import { getThemeColor } from '../../../hooks/theme';

export enum DotsLoaderSize {
  'small' = 6,
  'medium' = 8,
  'large' = 12,
}

export type DotsLoaderProps = {
  size?: DotsLoaderSize;
  color?: string;
};

const Root = styled('div', {
  name: 'DsDotsLoader',
  slot: 'Root',
  target: 'DsDotsLoader-Root',
  overridesResolver: (_, styles) => [styles.root],
})<{ ownerState: DotsLoaderProps }>(({ ownerState }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 4,

  '@keyframes loader': {
    to: {
      opacity: 0.1,
    },
  },

  '.DsDotsLoader-Dot': {
    background: ownerState.color,
    width: ownerState.size,
    height: ownerState.size,
    animation: 'loader 0.6s infinite alternate',

    '&:nth-child(1)': {
      animationDelay: '0.0s',
    },

    '&:nth-child(2)': {
      animationDelay: '0.2s',
    },

    '&:nth-child(3)': {
      animationDelay: '0.4s',
    },
  },
}));

const Dot = styled('div', {
  name: 'DsDotsLoader',
  slot: 'Dot',
  target: 'DsDotsLoader-Dot',
  overridesResolver: (_, styles) => [styles.dot],
})({
  display: 'block',
  borderRadius: '50%',
});

export const DotsLoader = ({
  size = DotsLoaderSize.small,
  color = 'grey.500',
}: DotsLoaderProps) => {
  const theme = useTheme();
  const ownerState: DotsLoaderProps = {
    size,
    color: getThemeColor(theme, color),
  };
  return (
    <Root ownerState={ownerState}>
      <Dot />
      <Dot />
      <Dot />
    </Root>
  );
};
