import { Theme } from '@mui/material/styles';
import get from 'lodash/get';

export const fromThemeOrDefault = (theme: any, prop?: string | number | null) =>
  prop && get(theme, prop, prop);

export const getThemeColor = (theme: Theme, path?: string | number) =>
  fromThemeOrDefault(theme.palette, path);

export const shadowDividerProps = (orientation: 'horizontal' | 'vertical') => ({
  position: 'absolute',
  background: `linear-gradient(${
    orientation === 'vertical' ? '90deg' : '180deg'
  }, #C4C4C4 -17.86%, rgba(196, 196, 196, 0) 76.19%)`,
  opacity: 0.18,
  ...(orientation === 'vertical'
    ? { width: 18, height: '100%' }
    : { width: '100%', height: 18 }),
});
