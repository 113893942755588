import { useMemo } from 'react';

import { AvailableSavingsConfigurationAfterSummary } from '@cast/types';
import { bytesToUnit, groupUniqueNodesWithCount } from '@cast/utils';

import { ConfigurationSummary } from 'types/available-savings';

import { useAvailableSavingsCurrentConfiguration } from './useAvailableSavingsCurrentConfiguration';
import { useAvailableSavingsRecommendations } from './useAvailableSavingsRecommendations';
import { useAvailableSavingsArmSupport } from '../_providers/ArmSupportProvider';
import { useAvailableSavingsSpotAnalysis } from '../_providers/SpotAnalysisProvider';
import { ModifiedAvailableSavingsNode } from '../types';
import { getConfigurationSummary } from '../utils';

export const useAvailableSavingsOptimalConfiguration = () => {
  const recommendations = useAvailableSavingsRecommendations();
  const currentCtx = useAvailableSavingsCurrentConfiguration();
  const spotCtx = useAvailableSavingsSpotAnalysis();
  const armCtx = useAvailableSavingsArmSupport();

  const [nodes, summary] = useMemo(() => {
    let nodes: ModifiedAvailableSavingsNode[] = currentCtx.nodes;
    let afterSummary: AvailableSavingsConfigurationAfterSummary | undefined;
    if (armCtx?.enabled) {
      if (!armCtx.isNegativeSavings) {
        nodes = groupUniqueNodesWithCount(
          recommendations?.details?.armSavingsConfiguration?.nodes || []
        );
      }
      afterSummary = recommendations?.details?.armSavingsConfiguration?.summary;
    } else {
      if (!spotCtx.isNegativeSavings) {
        nodes = groupUniqueNodesWithCount(
          recommendations?.details?.configurationAfter?.nodes || []
        );
      }
      afterSummary = recommendations?.details?.configurationAfter?.summary;
    }

    const { distribution } = getConfigurationSummary(nodes);

    const [memory, memoryUnit] = bytesToUnit(afterSummary?.ramBytes || 0, 2);
    const summary: ConfigurationSummary = {
      distribution,
      avgCpuPrice: Number(afterSummary?.avgCpuPrice || 0),
      avgRamPrice: Number(afterSummary?.avgRamPrice || 0),
      total: {
        instances: afterSummary?.nodes || 0,
        cpu: afterSummary?.cpuCores || 0,
        gpu: afterSummary?.gpu || 0,
        memory: {
          value: memory,
          unit: memoryUnit,
          bytes: afterSummary?.ramBytes || 0,
        },
      },
    };

    return [nodes, summary];
  }, [
    currentCtx.nodes,
    armCtx?.enabled,
    armCtx?.isNegativeSavings,
    recommendations?.details?.armSavingsConfiguration?.nodes,
    recommendations?.details?.armSavingsConfiguration?.summary,
    recommendations?.details?.configurationAfter?.nodes,
    recommendations?.details?.configurationAfter?.summary,
    spotCtx.isNegativeSavings,
  ]);

  return {
    nodes,
    summary,
  };
};
