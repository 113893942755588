import Big from 'big.js';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import sumBy from 'lodash/sumBy';

import {
  AvailableSavingsHistoryEntry,
  AvailableSavingsResponse,
  AvailableSavingsReplicatedWorkload,
} from '@cast/types';
import { bytesToUnit } from '@cast/utils';

import {
  ConfigurationSummary,
  AvailableSavingsHistoryChartDataPoint,
} from 'types/available-savings';
import { TimeSeries } from 'types/metrics';

import {
  ModifiedAvailableSavingsNode,
  PartitionedWorkloads,
  WorkloadRow,
} from './types';

export const nonNegativeGuard = (value: string | number | undefined) => {
  if (!value) {
    return 0;
  }

  let result: number;

  if (typeof value === 'string') {
    result = Big(value).toNumber();
  } else {
    result = value;
  }

  return result > 0 ? result : 0;
};

export const mapResponseToDataByHour = (
  entries: AvailableSavingsHistoryEntry[] = [],
  priceMultiplier = 1,
  dataPoints = 24
): TimeSeries<AvailableSavingsHistoryChartDataPoint> => {
  const now = dayjs();

  const grouped: Record<string, AvailableSavingsHistoryEntry[]> = groupBy(
    entries,
    (entry) => dayjs(entry.createdAt).format('DD-HH')
  );

  return range(dataPoints).map((_, i) => {
    const createdAt = now.subtract(i, 'hour');
    const entryKey = createdAt.format('DD-HH');
    const group = grouped[entryKey];

    if (group?.length) {
      const entry = group[0];

      const clusterCost = entry.current!.costPerHour! * priceMultiplier || 0;
      const clusterCostWithCastAi =
        (entry.optimizedSpotInstances?.costPerHour || 0) *
        (priceMultiplier || 0);

      return {
        timestamp: createdAt.endOf('hour').add(1, 'ms').toISOString(),
        dateFrom: createdAt.toISOString(),
        dateTo: createdAt.add(1, 'hour').toISOString(),
        clusterCost,
        clusterCostWithCastAi: Math.min(clusterCost, clusterCostWithCastAi), // don't display negative savings
        totalCost: clusterCost + clusterCostWithCastAi,

        cpuSpot: entry.current!.spotCpu || 0,
        cpuOnDemand: entry.current!.totalCpu! - entry.current!.spotCpu! || 0,
        cpuSpotOptimized: entry.optimizedSpotInstances?.spotCpu || 0,
        cpuOnDemandOptimized:
          (entry.optimizedSpotInstances?.totalCpu || 0) -
          (entry.optimizedSpotInstances?.spotCpu || 0),
        totalCpu: entry.current!.totalCpu || 0,
        totalCpuOptimized: entry.optimizedSpotInstances?.totalCpu || 0,

        memorySpot: entry.current!.spotRamGib || 0,
        memoryOnDemand:
          entry.current!.totalRamGib! - entry.current!.spotRamGib! || 0,
        memorySpotOptimized: entry.optimizedSpotInstances?.spotRamGib || 0,
        memoryOnDemandOptimized:
          (entry.optimizedSpotInstances?.totalRamGib || 0) -
          (entry.optimizedSpotInstances?.spotRamGib || 0),

        totalMemory: entry.current!.totalRamGib || 0,
        totalMemoryOptimized: entry.optimizedSpotInstances?.totalRamGib || 0,

        nodesSpot: entry.current!.spotNodeCount || 0,
        nodesOnDemand:
          entry.current!.totalNodeCount! - entry.current!.spotNodeCount! || 0,
        nodesSpotOptimized: entry.optimizedSpotInstances?.spotNodeCount || 0,
        nodesOnDemandOptimized:
          (entry.optimizedSpotInstances?.totalNodeCount || 0) -
          (entry.optimizedSpotInstances?.spotNodeCount || 0),
        totalNodes: entry.current!.totalNodeCount || 0,
        totalNodesOptimized: entry.optimizedSpotInstances?.totalNodeCount || 0,
      };
    }

    return {
      timestamp: createdAt.endOf('hour').add(1, 'ms').toISOString(),
      dateFrom: createdAt.toISOString(),
      dateTo: createdAt.add(1, 'hour').toISOString(),
      clusterCost: null,
      clusterCostWithCastAi: null,
      totalCost: null,

      cpuSpot: null,
      cpuOnDemand: null,
      cpuSpotOptimized: null,
      cpuOnDemandOptimized: null,
      totalCpu: null,
      totalCpuOptimized: null,

      memorySpot: null,
      memoryOnDemand: null,
      memorySpotOptimized: null,
      memoryOnDemandOptimized: null,
      totalMemory: null,
      totalMemoryOptimized: null,

      nodesSpot: null,
      nodesOnDemand: null,
      nodesSpotOptimized: null,
      nodesOnDemandOptimized: null,
      totalNodes: null,
      totalNodesOptimized: null,
    };
  });
};

export const getConfigurationSummary = (
  nodes: ModifiedAvailableSavingsNode[]
): ConfigurationSummary => {
  const instances = sumBy(nodes, 'quantity');
  const cpu = sumBy(nodes, (node) => node.cpuCores * node.quantity!);
  const gpu = sumBy(nodes, (node) => node.gpu * node.quantity!);
  const memoryBytes = sumBy(nodes, (node) => node.ramBytes * node.quantity!);
  const [memory, memoryUnit] = bytesToUnit(memoryBytes, 2);

  return {
    total: {
      instances,
      cpu,
      gpu,
      memory: {
        bytes: memoryBytes,
        value: memory,
        unit: memoryUnit,
      },
    },
    distribution: nodes.reduce(
      (acc, node) => {
        if (node.spot) {
          return {
            ...acc,
            cpuSpot: acc.cpuSpot + node.cpuCores * node.quantity!,
            memorySpot: acc.memorySpot + node.ramBytes * node.quantity!,
            instancesSpot: acc.instancesSpot + node.quantity!,
            spotNodes: acc.spotNodes + 1,
            gpuSpot: acc.gpuSpot + node.gpu * node.quantity!,
          };
        }

        if (node.fallback) {
          return {
            ...acc,
            cpuFallback: acc.cpuFallback + node.cpuCores * node.quantity!,
            memoryFallback: acc.memoryFallback + node.ramBytes * node.quantity!,
            instancesFallback: acc.instancesFallback + node.quantity!,
            onDemandNodes: acc.onDemandNodes + 1,
            gpuFallback: acc.gpuFallback + node.gpu * node.quantity!,
          };
        }

        return {
          ...acc,
          cpu: acc.cpu + node?.cpuCores * node.quantity!,
          memory: acc.memory + node.ramBytes * node.quantity!,
          instances: acc.instances + node.quantity!,
          onDemandNodes: acc.onDemandNodes + 1,
          gpu: acc.gpu + node.gpu * node.quantity!,
        };
      },
      {
        cpu: 0,
        memory: 0,
        instances: 0,
        gpu: 0,

        cpuSpot: 0,
        memorySpot: 0,
        instancesSpot: 0,
        gpuSpot: 0,

        cpuFallback: 0,
        memoryFallback: 0,
        instancesFallback: 0,
        gpuFallback: 0,

        spotNodes: 0,
        onDemandNodes: 0,
      }
    ),
  };
};

export const getCurrentPrice = (savings?: AvailableSavingsResponse) => {
  return {
    currentMonthlyPrice: nonNegativeGuard(
      savings?.currentConfiguration?.totalPrice?.monthly
    ),
    currentHourlyPrice: nonNegativeGuard(
      savings?.currentConfiguration?.totalPrice?.hourly
    ),
  };
};

export const workloadTransformer = (
  workload: AvailableSavingsReplicatedWorkload,
  spotFriendly?: boolean
): WorkloadRow => {
  const {
    currentNodeType,
    ownerType,
    recommendedNodeType,
    replicas,
    workloadName,
    workloadType,
    workloadNamespace,
  } = workload;
  let orderKey: number;

  if (currentNodeType !== recommendedNodeType) {
    orderKey = recommendedNodeType === 'spot' ? 0 : 1;
  } else {
    orderKey = currentNodeType === 'spot' ? 2 : 3;
  }

  return {
    workloadName,
    workloadType,
    workloadNamespace,
    ownerType,
    currentNodeType,
    recommendedNodeType,
    replicas: replicas?.length ?? 0,
    orderKey,
    spotFriendly: spotFriendly || recommendedNodeType === 'spot',
  } as const;
};

export const partitionWorkloads = ({
  currentConfiguration,
  recommendations,
}: AvailableSavingsResponse): PartitionedWorkloads => {
  const currentReplicatedWorkloads = currentConfiguration?.workloads ?? [];
  const spotReplicatedWorkloads =
    recommendations?.SpotInstances?.details?.replicatedWorkloads || [];
  const spotOnlyReplicatedWorkloads =
    recommendations?.SpotOnly?.details?.replicatedWorkloads || [];

  const workloads: WorkloadRow[] = [];
  const spotWorkloads: WorkloadRow[] = [];
  const spotOnlyWorkloads: WorkloadRow[] = [];

  for (const rWorkload of currentReplicatedWorkloads) {
    if (rWorkload.ownerType === 'DaemonSet') {
      continue;
    }

    const workload = workloadTransformer(rWorkload);

    workloads.push(workload);
  }

  spotReplicatedWorkloads.forEach((rSpotWorkload) => {
    const spotWorkload = workloadTransformer(rSpotWorkload);
    if (spotWorkload.ownerType === 'DaemonSet') {
      return;
    }
    spotWorkloads.push(spotWorkload);
  });

  spotOnlyReplicatedWorkloads.forEach((rSpotOnlyWorkload) => {
    const spotFriendly = rSpotOnlyWorkload.recommendedNodeType === 'spot';
    const spotOnlyWorkload = workloadTransformer(
      rSpotOnlyWorkload,
      spotFriendly
    );
    if (spotOnlyWorkload.ownerType === 'DaemonSet') {
      return;
    }
    spotOnlyWorkloads.push(spotOnlyWorkload);
  });

  return {
    workloads: orderBy(workloads, ['orderKey', 'replicas'], ['asc', 'desc']),
    spotWorkloads: orderBy(
      spotWorkloads,
      ['orderKey', 'replicas'],
      ['asc', 'desc']
    ),
    spotOnlyWorkloads: orderBy(
      spotOnlyWorkloads,
      ['orderKey', 'replicas'],
      ['asc', 'desc']
    ),
  };
};
