import { ThemeOptions } from '@mui/material';

export const chartLegendTheme: ThemeOptions['components'] = {
  DsChartLegend: {
    styleOverrides: {
      root: () => ({
        borderRadius: '2px',
        verticalAlign: 'middle',
        boxSizing: 'border-box',
      }),

      // Variant
      filled: ({ ownerState }) => ({
        background: ownerState.color,
        border: `1px solid ${ownerState.color}`,
      }),
      outlined: ({ ownerState }) => ({
        background: 'none',
        border: `1px solid ${ownerState.color}`,
      }),

      // Size
      large: {
        width: '12px',
        height: '12px',
      },
      medium: {
        width: '12px',
        height: '12px',
      },
      small: {
        width: '8px',
        height: '8px',
      },
    },
  },
};
