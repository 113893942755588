import { useLocation } from 'react-router-dom';

import { DEMO_CLUSTER_ID, isDemoMode } from '@cast/utils';

import { IS_DEV } from 'common/constants';
import { Navigate } from 'components/router';
import { user } from 'core/auth';
import { useExperiment } from 'core/experiments';

export const LandingRedirect = () => {
  const location = useLocation();
  const variant = useExperiment({ name: 'post-signup-redirect' });

  if (user().firstLogin && isDemoMode() && !IS_DEV) {
    return (
      <Navigate
        to={{
          ...location,
          pathname:
            variant === '0'
              ? '/dashboard'
              : `/external-clusters/${DEMO_CLUSTER_ID}/available-savings`,
        }}
      />
    );
  }

  return (
    <Navigate
      to={{
        ...location,
        pathname: '/dashboard',
      }}
    />
  );
};
