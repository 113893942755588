import { AxiosInstance } from 'axios';

import { CommitmentsAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const CommitmentsApiAdapter = (client: AxiosInstance) => {
  const generatedApi = CommitmentsAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  return {
    importAzureCommitments: generatedApi.commitmentsAPIImportAzureReservations,
    getImportGCPCommitmentsScript:
      generatedApi.commitmentsAPIGetGCPCommitmentsImportScript,
    getCommitments: generatedApi.commitmentsAPIGetCommitments,
    replaceAssignments: generatedApi.commitmentsAPIReplaceCommitmentAssignments,
    updateCommitment: generatedApi.commitmentsAPIUpdateCommitment,
    deleteCommitment: generatedApi.commitmentsAPIDeleteCommitment,
    getAssignments: generatedApi.commitmentsAPIGetCommitmentsAssignments,
  };
};
