import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import merge from 'lodash/merge';

import {
  DailyCostReportResponse,
  Cluster,
  RecursivePartial,
  PoliciesConfig,
  NodeConfigurationTemplate,
  NodeResponse,
  Workload,
  AvailableSavingsResponse,
  AvailableSavingsHistoryResponse,
  CostReportApiResponse,
  WorkloadCostReportResponse,
  BestPracticesCheckResponse,
  BestPracticeCheckDetails,
  RebalancingPlan,
  NodeConstraintResponse,
  CostReportAllocationGroupsResponse,
  CostReportAllocationGroupTotalCostsResponse,
  CostReportAllocationGroupWorkloadsResponse,
  WorkloadEfficiencyListResponseRaw,
  BestPracticesFiltersResponse,
  CostReportClustersSummary,
  ClusterUnscheduledPodsResponse,
  CostReportClusterSummaryResponse,
  CostReportClusterResourceUsageResponse,
  SecurityInsightsContainerImagesResponse,
  SecurityInsightsImageDigestsResponse,
  SecurityInsightsImageDetailsResponse,
  SecurityInsightsImageVulnerabilitiesResponse,
  SecurityInsightsImagePackageVulnerabilityDetailsResponse,
  SecurityInsightsImagePackagesResponse,
  DataTransferCostResponse,
  SecurityInsightsImageResourcesResponse,
  AdvancedEvictorConfigResponse,
  AllocationGroupsDataTransferWorkloadsResponse,
  AllocationGroupsDataTransferSummaryResponse,
  SavingsProgress,
  SavingsProgressResponse,
  OptimizationWorkloadsListResponse,
  OptimizationWorkloadDetailsResponse,
  WorkloadTrafficDestinationsHistory,
  OrganizationClustersReportResponse,
  SecurityInsightsAttackPathsResponse,
  SecurityInsightsAttackPathDetailsResponse,
  SecurityInsightsResourceDetailsResponse,
  AnomalyDetailsResponse,
  AnomaliesResponse,
  SecurityInsightsIntegrationsResponse,
  SecurityInsightsCheckResourcesResponse,
  SecurityInsightsImageAlternativesResponse,
  SecurityInsightsBestPracticesOverviewResponse,
  SecurityInsightsImageSecurityOverviewResponse,
  SecurityInsightsAttackPathsOverviewResponse,
  AnomaliesOverviewResponse,
  AllocationGroupEfficiencySummaryResponse,
  SecurityInsightsClusterSecuritySettingsResponse,
  SecurityInsightsWorkloadsResponse,
  RuntimeRulesResponse,
  RuntimeRuleResponse,
  AllocationGroupWorkloadsEfficiencyResponse,
  AnomalyEventsResponse,
  WorkloadGpuUtilizationSummary,
  WorkloadsWastedGpuCostImpactResponse,
  ClusterAnomaliesResponse,
  NamespaceCostReportTotalCostsResponse,
  NamespaceCostReportSummaryResponse,
  NamespacesDataTransferSummaryResponse,
  NamespacesDataTransferTotalCostsResponse,
  CostReportAllocationGroupTimedSummariesResponse,
} from '@cast/types';

import {
  replaceClusterIdTokens,
  replaceClusterNameTokens,
  replaceOrgIdTokens,
  replaceRebalancingPlanIdTokens,
  replaceTimestampTokens,
} from './utils';

dayjs.extend(utc);
dayjs.extend(isBetween);

export const clusterDetailsModifier = (
  base: string,
  overrides: RecursivePartial<Cluster>,
  tokens: {
    name: string;
    orgId: string;
    clusterId?: string;
  }
): Cluster => {
  const parsedTimestamps = replaceTimestampTokens(base);
  const parsedName = replaceClusterNameTokens(parsedTimestamps, tokens.name);
  const parsedId = replaceClusterIdTokens(parsedName, tokens.clusterId);
  const parsedOrgId = replaceOrgIdTokens(parsedId, tokens.orgId);
  return merge(JSON.parse(parsedOrgId), overrides);
};

export const organizationClustersReportModifier = (
  base: string,
  overrides: RecursivePartial<OrganizationClustersReportResponse>,
  tokens?: {
    clusterId?: string;
  }
): OrganizationClustersReportResponse => {
  const parsedId = replaceClusterIdTokens(base, tokens?.clusterId);
  const parsedTimestamps = replaceTimestampTokens(parsedId);
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const organizationClustersSummaryModifier = (
  base: string,
  overrides: RecursivePartial<OrganizationClustersReportResponse>,
  tokens?: {
    clusterId?: string;
  }
): DailyCostReportResponse => {
  const parsedId = replaceClusterIdTokens(base, tokens?.clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const dailyCostsModifier = (
  base: string,
  overrides: RecursivePartial<DailyCostReportResponse>,
  tokens?: {
    clusterId?: string;
  }
): DailyCostReportResponse => {
  const parsedTimestamps = replaceTimestampTokens(base);
  const parsedId = replaceClusterIdTokens(parsedTimestamps, tokens?.clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const costReportModifier = (
  base: string,
  overrides: RecursivePartial<CostReportApiResponse>,
  clusterId?: string,
  startDate?: dayjs.Dayjs
): CostReportApiResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('h').add(1, 'ms'),
    startDate
  );
  const parsedId = replaceClusterIdTokens(parsedTimestamps, clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const clustersSummaryModifier = (
  base: string,
  overrides: RecursivePartial<CostReportClustersSummary>,
  clusterId?: string
): CostReportClustersSummary => {
  const parsedId = replaceClusterIdTokens(base, clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const clusterSummaryModifier = (
  base: string,
  overrides: RecursivePartial<CostReportClusterSummaryResponse>
): CostReportClusterSummaryResponse => {
  const parsedId = replaceClusterIdTokens(base);
  return merge(JSON.parse(parsedId), overrides);
};

export const resourceUsageModifier = (
  base: string,
  overrides: RecursivePartial<CostReportClusterResourceUsageResponse>,
  endDate?: dayjs.Dayjs,
  clusterId?: string
): CostReportClusterResourceUsageResponse => {
  const parsedId = replaceClusterIdTokens(base, clusterId);
  const parsedTimestamps = replaceTimestampTokens(
    parsedId,
    'iso',
    undefined,
    endDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const unscheduledPodsModifier = (
  base: string,
  overrides: RecursivePartial<ClusterUnscheduledPodsResponse>,
  clusterId?: string
): ClusterUnscheduledPodsResponse => {
  const parsedId = replaceClusterIdTokens(base, clusterId);
  const parsedTimestamps = replaceTimestampTokens(parsedId, 'iso');
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const savingsProgressModifier = (
  base: string,
  overrides: RecursivePartial<SavingsProgress>,
  clusterId?: string,
  startDate?: dayjs.Dayjs
): SavingsProgressResponse => {
  const parsedId = replaceClusterIdTokens(base, clusterId);
  const parsedTimestamps = replaceTimestampTokens(
    parsedId,
    'iso',
    (date) => date.endOf('d').add(1, 'ms'),
    startDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const allocationGroupsModifier = (
  base: string,
  overrides: RecursivePartial<CostReportAllocationGroupsResponse>,
  clusterId?: string
): CostReportAllocationGroupsResponse => {
  const parsedId = replaceClusterIdTokens(base, clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const allocationGroupComputeCostTotalCostsModifier = (
  base: string,
  startDate?: string,
  endDate?: string
): CostReportAllocationGroupTotalCostsResponse => {
  const from = dayjs(startDate);
  const to = dayjs(endDate);

  const parsedTimestamps = replaceTimestampTokens(base, 'iso', undefined, to);

  const result = JSON.parse(
    parsedTimestamps
  ) as CostReportAllocationGroupTotalCostsResponse;
  if (!endDate) {
    return result;
  }

  let _to = to;
  const now = dayjs().endOf('day').add(1, 'ms');

  if (now.isBefore(to)) {
    _to = now;
  }

  const filteredDatapoints = result.items?.map((group) => {
    return {
      ...group,
      items: group.items?.filter((datapoint) => {
        const timestamp = dayjs(datapoint.timestamp);
        return timestamp.isBetween(from, _to.endOf('day').add(1, 'ms'));
      }),
    };
  });

  return {
    ...result,
    items: filteredDatapoints,
  };
};

export const allocationGroupComputeCostTimedSummariesModifier = (
  base: string,
  startDate?: string,
  endDate?: string
): CostReportAllocationGroupTimedSummariesResponse => {
  const from = dayjs(startDate);
  const to = dayjs(endDate);

  const parsedTimestamps = replaceTimestampTokens(base, 'iso', undefined, to);

  const result = JSON.parse(
    parsedTimestamps
  ) as CostReportAllocationGroupTimedSummariesResponse;
  if (!endDate) {
    return result;
  }

  let _to = to;
  const now = dayjs().endOf('day').add(1, 'ms');

  if (now.isBefore(to)) {
    _to = now;
  }

  const filteredDatapoints = result.items?.map((group) => {
    return {
      ...group,
      items: group.items?.filter((datapoint) => {
        const timestamp = dayjs(datapoint.timestamp);
        return timestamp.isBetween(from, _to.endOf('day').add(1, 'ms'));
      }),
    };
  });

  return {
    ...result,
    items: filteredDatapoints,
  };
};

export const allocationGroupComputeCostWorkloadsModifier = (
  base: string,
  overrides: RecursivePartial<CostReportAllocationGroupWorkloadsResponse>,
  clusterId?: string
): CostReportAllocationGroupWorkloadsResponse => {
  const parsedId = replaceClusterIdTokens(base, clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const allocationGroupNetworkCostSummariesModifier = (
  base: string,
  overrides: RecursivePartial<AllocationGroupsDataTransferSummaryResponse>,
  startDate?: dayjs.Dayjs
): AllocationGroupsDataTransferSummaryResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => date.endOf('d').add(1, 'ms'),
    startDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const allocationGroupNetworkCostWorkloadsModifier = (
  base: string,
  overrides: RecursivePartial<AllocationGroupsDataTransferWorkloadsResponse>,
  startDate?: dayjs.Dayjs
): AllocationGroupsDataTransferWorkloadsResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => date.endOf('d').add(1, 'ms'),
    startDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const workloadComputeCostModifier = (
  base: string,
  overrides: RecursivePartial<WorkloadCostReportResponse>,
  clusterId?: string,
  startDate?: dayjs.Dayjs
): WorkloadCostReportResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    startDate
  );
  const parsedId = replaceClusterIdTokens(parsedTimestamps, clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const workloadNetworkCostModifier = (
  base: string,
  overrides: RecursivePartial<DataTransferCostResponse>,
  clusterId?: string,
  startDate?: dayjs.Dayjs
): DataTransferCostResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    startDate
  );
  const parsedId = replaceClusterIdTokens(parsedTimestamps, clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const workloadTrafficDestinationsHistoryModifier = (
  base: string,
  overrides: RecursivePartial<WorkloadTrafficDestinationsHistory>,
  startDate?: dayjs.Dayjs
): WorkloadTrafficDestinationsHistory => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    startDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const workloadEfficiencyModifier = (
  base: string,
  overrides: RecursivePartial<WorkloadEfficiencyListResponseRaw>,
  clusterId?: string,
  startDate?: dayjs.Dayjs
): WorkloadEfficiencyListResponseRaw => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    startDate
  );
  const parsedId = replaceClusterIdTokens(parsedTimestamps, clusterId);
  return merge(JSON.parse(parsedId), overrides);
};

export const workloadGpuUtilizationModifier = (
  base: string,
  startDate?: string,
  endDate?: string,
  clusterId?: string
): WorkloadGpuUtilizationSummary => {
  const from = dayjs(startDate);
  const to = dayjs(endDate);

  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    from
  );
  const result = JSON.parse(
    replaceClusterIdTokens(parsedTimestamps, clusterId)
  ) as WorkloadGpuUtilizationSummary;

  if (!endDate) {
    return result;
  }

  let _to = to;
  const now = dayjs().endOf('day').add(1, 'ms');

  if (now.isBefore(to)) {
    _to = now;
  }

  return {
    ...result,
    intervals: result.intervals?.filter((interval) => {
      const timestamp = dayjs(interval.timestamp);
      return timestamp.isBetween(from, _to);
    }),
  };
};

export const workloadsGpuUtilizationModifier = (
  base: string,
  clusterId?: string
) => {
  const parsedId = replaceClusterIdTokens(base, clusterId);
  return JSON.parse(parsedId);
};

export const workloadGpuWasteCostImpactModifier = (
  base: string,
  startDate: string,
  endDate?: string,
  clusterId?: string
): WorkloadsWastedGpuCostImpactResponse => {
  const from = dayjs(startDate);
  const to = dayjs(endDate);

  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    from
  );
  const result = JSON.parse(
    replaceClusterIdTokens(parsedTimestamps, clusterId)
  ) as WorkloadsWastedGpuCostImpactResponse;

  if (!endDate) {
    return result;
  }

  let _to = to;
  const now = dayjs().endOf('day').add(1, 'ms');

  if (now.isBefore(to)) {
    _to = now;
  }

  return {
    ...result,
    workloads: result.workloads?.map((w) => ({
      ...w,
      costImpactHistory: w.costImpactHistory?.filter((interval) => {
        const timestamp = dayjs(interval.timestamp);
        return timestamp.isBetween(from, _to);
      }),
    })),
  };
};

export const allocationGroupsEfficiencyModifier = (
  base: string,
  overrides: RecursivePartial<AllocationGroupEfficiencySummaryResponse>,
  startDate?: dayjs.Dayjs
): AllocationGroupEfficiencySummaryResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    startDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const allocationGroupWorkloadsEfficiencyModifier = (
  base: string,
  overrides: RecursivePartial<AllocationGroupWorkloadsEfficiencyResponse>,
  clusterId?: string
): AllocationGroupWorkloadsEfficiencyResponse => {
  const parsedId = replaceClusterIdTokens(base, clusterId);

  return merge(JSON.parse(parsedId), overrides);
};

export const policiesModifier = (
  base: string,
  overrides: RecursivePartial<PoliciesConfig>
): PoliciesConfig => {
  return merge(JSON.parse(base), overrides);
};

export const nodeConfigModifier = (
  base: string,
  overrides: RecursivePartial<NodeConfigurationTemplate>
): NodeConfigurationTemplate => {
  return merge(JSON.parse(base), overrides);
};

export const nodeModifier = (
  base: string,
  overrides: RecursivePartial<NodeResponse>
): NodeResponse => {
  const parsedTimestamps = replaceTimestampTokens(base);
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const rebalancingPlanModifier = (
  base: string,
  overrides: RecursivePartial<RebalancingPlan>,
  tokens?: {
    planId?: string;
    clusterId?: string;
  }
): RebalancingPlan => {
  const parsedTimestamps = replaceTimestampTokens(base);
  const parsedClusterId = replaceClusterIdTokens(
    parsedTimestamps,
    tokens?.clusterId
  );
  const parsedPlanId = tokens?.planId
    ? replaceRebalancingPlanIdTokens(parsedClusterId, tokens.planId)
    : parsedClusterId;
  return merge(JSON.parse(parsedPlanId), overrides);
};

export const rebalancingWorkloadModifier = (
  base: string,
  overrides: RecursivePartial<Workload>
): Workload => {
  return merge(JSON.parse(base), overrides);
};

export const estimatedSavingsModifier = (
  base: string,
  overrides: RecursivePartial<AvailableSavingsResponse>
): AvailableSavingsResponse => {
  const parsedTimestamps = replaceTimestampTokens(base);
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const estimatedSavingsHistoryModifier = (
  base: string,
  overrides: RecursivePartial<AvailableSavingsHistoryResponse>
): AvailableSavingsHistoryResponse => {
  const parsedTimestamps = replaceTimestampTokens(base, 'iso', (date) =>
    date.endOf('h').add(1, 'ms')
  );
  const parsedClusterId = replaceClusterIdTokens(parsedTimestamps);
  return merge(JSON.parse(parsedClusterId), overrides);
};

export const securityInsightsBestPracticesModifier = (
  base: string,
  overrides: RecursivePartial<BestPracticesCheckResponse>
): BestPracticesCheckResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsBestPracticesFiltersModifier = (
  base: string,
  overrides: RecursivePartial<BestPracticesFiltersResponse>
): BestPracticesFiltersResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsBestPracticesDetailsModifier = (
  base: string,
  overrides: RecursivePartial<BestPracticeCheckDetails>
): BestPracticeCheckDetails => {
  const parsedIds = replaceClusterIdTokens(base);
  return merge(JSON.parse(parsedIds), overrides);
};

export const securityInsightsBestPracticesResourcesModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsCheckResourcesResponse>
): SecurityInsightsCheckResourcesResponse => {
  const parsedIds = replaceClusterIdTokens(base);
  return merge(JSON.parse(parsedIds), overrides);
};

export const securityInsightsImagesModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsContainerImagesResponse>
): SecurityInsightsContainerImagesResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsImageDetailsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsImageDetailsResponse>,
  startDate?: dayjs.Dayjs
): SecurityInsightsImageDetailsResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    undefined,
    undefined,
    startDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const securityInsightsBaseImageAlternativesModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsImageAlternativesResponse>
): SecurityInsightsImageAlternativesResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsImageDigestsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsImageDigestsResponse>,
  startDate?: dayjs.Dayjs
): SecurityInsightsImageDigestsResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    undefined,
    undefined,
    startDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const securityInsightsImageVulnerabilitiesModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsImageVulnerabilitiesResponse>,
  startDate?: dayjs.Dayjs
): SecurityInsightsImageVulnerabilitiesResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    undefined,
    undefined,
    startDate
  );
  return merge(JSON.parse(parsedTimestamps), overrides);
};

export const securityInsightsImageVulnerabilityDetailsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsImagePackageVulnerabilityDetailsResponse>
): SecurityInsightsImagePackageVulnerabilityDetailsResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsImagePackagesModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsImagePackagesResponse>
): SecurityInsightsImagePackagesResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsImageResourcesModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsImageResourcesResponse>,
  startDate?: dayjs.Dayjs,
  clusterId?: string,
  clusterName?: string
): SecurityInsightsImageResourcesResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    undefined,
    undefined,
    startDate
  );
  const parsedClusterId = replaceClusterIdTokens(parsedTimestamps, clusterId);
  const parsedClusterName = replaceClusterNameTokens(
    parsedClusterId,
    clusterName
  );
  return merge(JSON.parse(parsedClusterName), overrides);
};

export const securityInsightsAttackPathsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsAttackPathsResponse>
): SecurityInsightsAttackPathsResponse => {
  const parsedClusterId = replaceClusterIdTokens(base);
  return merge(JSON.parse(parsedClusterId), overrides);
};

export const securityInsightsAttackPathDetailsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsAttackPathDetailsResponse>,
  clusterId?: string
): SecurityInsightsAttackPathDetailsResponse => {
  const parsedClusterId = replaceClusterIdTokens(base, clusterId);
  return merge(JSON.parse(parsedClusterId), overrides);
};

export const securityInsightsResourceDetailsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsResourceDetailsResponse>,
  clusterId?: string,
  clusterName?: string
): SecurityInsightsResourceDetailsResponse => {
  const parsedIds = replaceClusterIdTokens(base, clusterId);
  const parsedClusterName = replaceClusterNameTokens(parsedIds, clusterName);
  return merge(JSON.parse(parsedClusterName), overrides);
};

export const securityInsightsIntegrationsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsIntegrationsResponse>
): SecurityInsightsIntegrationsResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsBestPracticeOverviewModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsBestPracticesOverviewResponse>
): SecurityInsightsBestPracticesOverviewResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsImageSecurityOverviewModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsImageSecurityOverviewResponse>
): SecurityInsightsImageSecurityOverviewResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsAttackPathsOverviewModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsAttackPathsOverviewResponse>
): SecurityInsightsAttackPathsOverviewResponse => {
  return merge(JSON.parse(base), overrides);
};

export const securityInsightsClustersSettingsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsClusterSecuritySettingsResponse>
): SecurityInsightsClusterSecuritySettingsResponse => {
  const parsedClusterId = replaceClusterIdTokens(base);
  const parsedClusterName = replaceClusterNameTokens(parsedClusterId);
  const parsedTimestamp = replaceTimestampTokens(parsedClusterName);
  return merge(JSON.parse(parsedTimestamp), overrides);
};

export const securityInsightsWorkloadsModifier = (
  base: string,
  overrides: RecursivePartial<SecurityInsightsWorkloadsResponse>,
  clusterId?: string,
  clusterName?: string
): SecurityInsightsWorkloadsResponse => {
  const parsedClusterId = replaceClusterIdTokens(base, clusterId);
  const parsedClusterName = replaceClusterNameTokens(
    parsedClusterId,
    clusterName
  );
  return merge(JSON.parse(parsedClusterName), overrides);
};

export const nodeConstraintsModifier = (
  base: string,
  overrides: RecursivePartial<NodeConstraintResponse>
): NodeConstraintResponse => {
  const parsedClusterId = replaceClusterIdTokens(base);
  return merge(JSON.parse(parsedClusterId), overrides);
};

export const advancedEvictorConfigModifier = (
  base: string,
  overrides: RecursivePartial<AdvancedEvictorConfigResponse>
): AdvancedEvictorConfigResponse => {
  return merge(JSON.parse(base), overrides);
};

export const woopWorkloadsModifier = (
  base: string,
  overrides?: RecursivePartial<OptimizationWorkloadsListResponse>
) => {
  const result = JSON.parse(
    replaceTimestampTokens(replaceClusterIdTokens(base), 'iso', (date) =>
      date.endOf('h').add(1, 'ms')
    )
  );
  if (!overrides) {
    return result;
  }
  return merge(result, overrides);
};

export const woopWorkloadModifier = (
  base: string,
  overrides?: RecursivePartial<OptimizationWorkloadDetailsResponse>
) => {
  const result = JSON.parse(
    replaceTimestampTokens(replaceClusterIdTokens(base), 'iso', (date) =>
      date.endOf('h').add(1, 'ms')
    )
  );
  if (!overrides) {
    return result;
  }
  return merge(result, overrides);
};

export const runtimeSecurityAnomaliesModifier = (
  base: string,
  overrides: RecursivePartial<AnomaliesResponse>
): AnomaliesResponse => {
  return merge(JSON.parse(base), overrides);
};

export const runtimeSecurityAnomalyDetailsModifier = (
  base: string,
  overrides: RecursivePartial<AnomalyDetailsResponse>
): AnomalyDetailsResponse => {
  return merge(JSON.parse(base), overrides);
};

export const runtimeSecurityAnomalyEventsModifier = (
  base: string,
  overrides: RecursivePartial<AnomalyEventsResponse>
): AnomalyEventsResponse => {
  return merge(JSON.parse(base), overrides);
};

export const runtimeSecurityAnomaliesOverviewModifier = (
  base: string,
  overrides: RecursivePartial<AnomaliesOverviewResponse>
): AnomaliesOverviewResponse => {
  return merge(JSON.parse(base), overrides);
};

export const runtimeSecurityRulesModifier = (
  base: string,
  overrides: RecursivePartial<RuntimeRulesResponse>
): RuntimeRulesResponse => {
  return merge(JSON.parse(base), overrides);
};

export const runtimeSecurityRuleDetailsModifier = (
  base: string,
  overrides: RecursivePartial<RuntimeRuleResponse>
): RuntimeRuleResponse => {
  return merge(JSON.parse(base), overrides);
};

export const costReportWorkloadCostsModifier = (base: string) => {
  return JSON.parse(
    replaceTimestampTokens(replaceClusterIdTokens(base), 'iso', (date) =>
      date.endOf('h').add(1, 'ms')
    )
  );
};

export const costReportWorkloadSummariesModifier = (base: string) => {
  return JSON.parse(replaceClusterIdTokens(base));
};

export const dataTransferCostModifier = async (
  base: string,
  overrides: RecursivePartial<DataTransferCostResponse>,
  start: string,
  end: string
): Promise<DataTransferCostResponse> => {
  const today = dayjs().startOf('day');
  const to = dayjs(end).isAfter(today) ? today.add(1, 'day') : end;
  const fixture = workloadNetworkCostModifier(base, {}, undefined, dayjs(end));
  return merge(
    {
      ...fixture,
      items: fixture.items?.map((item) => ({
        ...item,
        ingressMetrics: item.ingressMetrics?.filter((metrics) =>
          dayjs(metrics.timestamp).isBetween(start, to, null, '[]')
        ),
        egressMetrics: item.egressMetrics?.filter((metrics) =>
          dayjs(metrics.timestamp).isBetween(start, to, null, '[]')
        ),
      })),
    },
    overrides
  );
};

export const commitmentsModifier = (base: string, clusterId?: string) => {
  return JSON.parse(replaceClusterIdTokens(base, clusterId));
};

export const clusterAnomaliesModifier = (
  base: string,
  startDate?: dayjs.Dayjs
): ClusterAnomaliesResponse => {
  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    startDate
  );
  return JSON.parse(parsedTimestamps);
};

export const namespacesCostsSummaryModifier = (
  base: string,
  clusterId?: string
): NamespaceCostReportSummaryResponse => {
  return JSON.parse(replaceClusterIdTokens(base, clusterId));
};

export const namespacesTotalCostsModifier = (
  base: string,
  startDate?: string,
  endDate?: string,
  clusterId?: string
): NamespaceCostReportTotalCostsResponse => {
  const from = dayjs(startDate);
  const to = dayjs(endDate);

  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    to
  );
  const result = JSON.parse(
    replaceClusterIdTokens(parsedTimestamps, clusterId)
  ) as NamespaceCostReportTotalCostsResponse;

  if (!endDate) {
    return result;
  }

  let _to = to;
  const now = dayjs().endOf('day').add(1, 'ms');

  if (now.isBefore(to)) {
    _to = now;
  }

  const filteredDatapoints = result.items?.map((namespace) => {
    return {
      ...namespace,
      costMetrics: namespace?.costMetrics?.filter((datapoint) => {
        const timestamp = dayjs(datapoint.timestamp);
        return timestamp.isBetween(from, _to);
      }),
    };
  });

  return {
    ...result,
    items: filteredDatapoints,
  };
};

export const namespacesNetworkCostsSummaryModifier = (
  base: string,
  clusterId?: string
): NamespacesDataTransferSummaryResponse => {
  return JSON.parse(replaceClusterIdTokens(base, clusterId));
};

export const namespacesNetworkTotalCostsModifier = (
  base: string,
  startDate?: string,
  endDate?: string,
  clusterId?: string
): NamespacesDataTransferTotalCostsResponse => {
  const from = dayjs(startDate);
  const to = dayjs(endDate);

  const parsedTimestamps = replaceTimestampTokens(
    base,
    'iso',
    (date) => dayjs(date).endOf('d').utc().add(1, 'ms'),
    to
  );
  const result = JSON.parse(
    replaceClusterIdTokens(parsedTimestamps, clusterId)
  ) as NamespacesDataTransferTotalCostsResponse;

  if (!endDate) {
    return result;
  }

  let _to = to;
  const now = dayjs().endOf('day').add(2, 'ms');

  if (now.isBefore(to)) {
    _to = now;
  }

  const filteredDatapoints = result.items?.map((namespace) => {
    return {
      ...namespace,
      items: namespace.items?.filter((datapoint) => {
        const timestamp = dayjs(datapoint.timestamp);
        return timestamp.isBetween(from, _to);
      }),
    };
  });

  return {
    ...result,
    items: filteredDatapoints,
  };
};
