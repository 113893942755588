import { styled } from '@mui/material';

import { PaginatorIconButton } from './PaginatorIconButton';
import { PaginatorProps } from './types';
import { shouldForwardProp } from '../../../../utils/shouldForwardProp';
import { CaretLeft, CaretRight } from '../../../icons';

const Root = styled('div', {
  name: 'DsPaginator',
  slot: 'Root',
  target: 'DsPaginator-Root',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root],
})<{ ownerState: PaginatorProps }>({});

const Text = styled('span', {
  name: 'DsPaginator',
  slot: 'Text',
  target: 'DsPaginator-Text',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.text],
})<{ ownerState: PaginatorProps }>({});

const IconBack = styled(CaretLeft, {
  name: 'DsPaginator',
  slot: 'Icon',
  target: 'DsPaginator-Icon DsPaginatorIconBack',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.icon, styles.iconBack],
})<{ ownerState: PaginatorProps }>({});

const IconForward = styled(CaretRight, {
  name: 'DsPaginator',
  slot: 'Icon',
  target: 'DsPaginator-Icon DsPaginatorIconForward',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.icon, styles.iconForward],
})<{ ownerState: PaginatorProps }>({});

export const Paginator = (props: PaginatorProps) => {
  const {
    onCurrentPageChange,
    size,
    sx,
    infinite,
    canGoForward: canGoForwardProp,
  } = props;
  let { recordsCount, pageSize, currentPage } = props;

  if (recordsCount < 0) {
    recordsCount = 0;
  }
  if (pageSize < 1) {
    pageSize = 1;
  }
  if (currentPage < 1) {
    currentPage = 1;
  }
  const maxPage = Math.ceil(recordsCount / pageSize);
  if (currentPage > maxPage) {
    currentPage = maxPage;
  }
  const from = (currentPage - 1) * pageSize;
  const to = Math.min(from + pageSize, recordsCount);

  const canGoBack = currentPage > 1;
  const canGoForward =
    canGoForwardProp != null ? canGoForwardProp : currentPage < maxPage;
  return (
    <Root ownerState={props} sx={sx}>
      <Text ownerState={props}>
        {Math.max(1, from)}-{to}
        {!infinite && ` of ${recordsCount}`}
      </Text>
      <PaginatorIconButton
        className="DsPaginator-IconButtonBack"
        onClick={() => onCurrentPageChange?.(currentPage - 1, 'back')}
        disabled={!canGoBack}
        size={size}
        data-testid="back button"
      >
        <IconBack ownerState={props} />
      </PaginatorIconButton>
      <PaginatorIconButton
        className="DsPaginator-IconButtonForward"
        onClick={() => onCurrentPageChange?.(currentPage + 1, 'forward')}
        disabled={!canGoForward}
        size={size}
        data-testid="forward button"
      >
        <IconForward ownerState={props} />
      </PaginatorIconButton>
    </Root>
  );
};
