import { useRef, useState } from 'react';

import { Stack, Box, Divider, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import * as yup from 'yup';

import {
  Button,
  ChipInput,
  DialogTitle,
  DialogActions,
  DialogContent,
  Icons,
  InputChipProps,
  ChipInputForwardRef,
} from '@cast/design-system';
import { OrganizationRole } from '@cast/types';

import { InvitationQuery } from 'types/organization';

import { RoleSelect } from '../../_components';

const schema = yup.string().email();

type Props = {
  onSubmit: (inviteList: InvitationQuery['members']) => void;
  onClose: () => void;
  errorMessage?: string;
};

export const InviteOrganizationForm = ({
  onSubmit,
  onClose,
  errorMessage,
}: Props) => {
  const chipInputRef = useRef<ChipInputForwardRef | null>(null);
  const [activeChips, setActiveChips] = useState<InputChipProps[]>([]);
  const [role, setRole] = useState<OrganizationRole>(OrganizationRole.owner);

  const [validationError, setValidationError] = useState<string | null>(null);

  const clearAll = () => {
    setActiveChips([]);
  };

  const handleDialogClose = () => {
    clearAll();
    onClose();
  };

  const addChip = (value: string) => {
    if (!value.length) {
      return;
    }
    try {
      schema.validateSync(value);
      setValidationError(null);
      setActiveChips((chips) => {
        const newChip: InputChipProps = {
          onDestroy: () => {
            setActiveChips((chips) =>
              chips.filter((chip) => !isEqual(chip, newChip))
            );
          },
          title: value,
        };
        return [...chips, newChip];
      });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setValidationError(error.errors[0]);
      }
    }
  };

  const handleSendInvite = () => {
    try {
      let invitations =
        uniqBy(
          activeChips.map((chip) => ({ userEmail: chip.title, role })),
          'userEmail'
        ) || [];

      const inputValue = chipInputRef.current?.inputRef?.value;
      if (inputValue) {
        schema.validateSync(inputValue);
        invitations = [...invitations, { userEmail: inputValue, role }];
        chipInputRef.current?.clearInput();
      }

      if (!invitations?.length) {
        return;
      }

      onSubmit(invitations);
      clearAll();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setValidationError(error.errors[0]);
      }
    }
  };

  const handleRoleChange = (value: OrganizationRole) => {
    setRole(value as OrganizationRole);
  };

  return (
    <>
      <DialogTitle sx={{ pb: 0 }} variant="h4">
        Invite new members
      </DialogTitle>

      <DialogContent sx={{ pb: 20, overflow: 'visible' }}>
        <Typography variant="P14R" color="grey.500" mt={4}>
          Give team members access to clusters, assign roles. You can always
          invite more members later.
        </Typography>
        <Stack pt={24} gap={12} direction="row" flexWrap="nowrap">
          <Box width="70%">
            <ChipInput
              ref={chipInputRef}
              separators={['Enter', ',']}
              onSeparation={(value) => {
                if (Array.isArray(value)) {
                  value.forEach((v) => addChip(v));
                } else {
                  addChip(value);
                }
              }}
              placeholder="Email, comma separated"
              error={errorMessage || validationError}
              startIcon={<Icons.EnvelopeSimple />}
              chips={activeChips}
              sx={{
                '.DsChipInput-Chip': {
                  backgroundColor: 'grey.600',
                },
              }}
              onClear={clearAll}
              multiline
              testId="org-member-emails-input"
            />
          </Box>
          <Box width="30%">
            <RoleSelect
              value={role}
              onChange={handleRoleChange}
              sx={{
                '&.MuiInputBase-root': {
                  position: 'absolute',
                  right: 0,
                  border: 0,
                  height: '48px',
                  marginTop: '1px',
                  marginRight: '1px',
                },
                '& .MuiSelect-select': {
                  padding: '12px',
                },
              }}
            />
          </Box>
        </Stack>
      </DialogContent>

      <Divider sx={{ borderColor: 'grey.100' }} />

      <DialogActions>
        <Button variant="tertiary" size="medium" onClick={handleDialogClose}>
          Cancel
        </Button>

        <Button
          testId="send-invite-button"
          size="medium"
          onClick={handleSendInvite}
        >
          Send invite
        </Button>
      </DialogActions>
    </>
  );
};
