import { PropsWithChildren, useContext, useEffect, useState } from 'react';

import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

import { DsShadow } from '@cast/design-system';

import { DrawerContext } from './DrawerContext';
import { SubDrawerContext } from './SubDrawerContext';

type Props = PropsWithChildren<{
  id: string;
  isActive?: boolean;
  sx?: SxProps<Theme>;
}>;

export const SubDrawer = ({ children, isActive, id }: Props) => {
  const { activeSubDrawer, setActiveSubDrawer } = useContext(DrawerContext);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const expandedWidth = ref?.scrollWidth;

  useEffect(() => {
    if (isActive !== undefined) {
      if (isActive) {
        setActiveSubDrawer(id);
      } else {
        setActiveSubDrawer(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const _isActive = isActive || activeSubDrawer === id;

  return (
    <SubDrawerContext.Provider value={{ id, isActive: _isActive }}>
      <Box
        ref={setRef}
        className="SubDrawer-root"
        width={_isActive ? expandedWidth : 0}
        sx={{
          position: 'relative',
          zIndex: 1,
          transition: 'width 500ms',
          boxShadow: (theme) =>
            _isActive ? theme.shadows[DsShadow.DRAWER] : undefined,
        }}
      >
        {children}
      </Box>
    </SubDrawerContext.Provider>
  );
};
