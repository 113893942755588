import { useCallback, useState } from 'react';

import { Dialog, DialogProps } from '@cast/design-system';
import { Cluster, ClusterDisplayStatus } from '@cast/types';

import { SwipeableViews } from 'components/boxes';

import { ReconnectStep } from './_components/ReconnectStep';
import { SetupStep } from './_components/SetupStep';

type Props = DialogProps & {
  cluster: Cluster;
  onClose: () => void;
  phase: 1 | 2;
};

export const ReconnectClusterDialog = ({
  cluster,
  onClose,
  phase,
  ...props
}: Props) => {
  const [activeSlide, setActiveSlide] = useState<'setup' | 'reconnect'>(
    'setup'
  );

  const onBackClick = () => {
    setActiveSlide('setup');
  };

  const onReconnectStart = useCallback(() => {
    setActiveSlide('reconnect');
  }, []);

  return (
    <Dialog {...props} onClose={onClose} testId="cluster-reconnect-dialog">
      <SwipeableViews
        animateHeight
        slides={{
          setup: (
            <SetupStep
              cluster={cluster}
              phase={phase}
              onClose={onClose}
              onReconnectStart={onReconnectStart}
            />
          ),
          reconnect: (
            <ReconnectStep
              onClose={onClose}
              cluster={cluster}
              connecting={
                ![
                  ClusterDisplayStatus.READ_ONLY,
                  ClusterDisplayStatus.READY,
                ].includes(cluster.displayStatus)
              }
              onBackClick={onBackClick}
            />
          ),
        }}
        activeSlide={activeSlide}
      />
    </Dialog>
  );
};
