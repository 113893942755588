import { useInfiniteQuery } from '@tanstack/react-query';
import flatMap from 'lodash/flatMap';

import { SortingState } from '@cast/design-system';

import { QueryKeys, apiClient } from 'core/react-query';

type UseGetWebhooksQueryArgs = {
  sortingState?: SortingState;
  enabled?: boolean;
};

export const useGetWebhooksQuery = ({
  sortingState,
  enabled = true,
}: UseGetWebhooksQueryArgs = {}) => {
  const {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [QueryKeys.WEBHOOKS, sortingState],
    queryFn: async ({ pageParam }) => {
      const { data } = await apiClient.notifications.listWebhooks({
        pageLimit: '25',
        pageCursor: pageParam,
        sortField: sortingState?.columnId,
        sortOrder: sortingState?.sortBy.direction || 'desc',
      });
      return data || null;
    },
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor || undefined;
    },
    enabled,
  });
  return {
    webhooks: data?.pages
      ? flatMap(data.pages, (page) => page.items)
      : undefined,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
  };
};
