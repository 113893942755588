import { PropsWithChildren } from 'react';

import clsx from 'clsx';

import { ExpandToggler, ExpandTogglerProps } from '../../../_components';
import { Indentation } from '../Indentation';

type Props = PropsWithChildren<{
  onHasHoverChanged?: (hasHover: boolean) => void;
  hasHover?: boolean;
  contentType: 'array' | 'object';
  type: 'open' | 'close';
  indent?: number;
  hasNext?: boolean;
  expandTogglerProps?: ExpandTogglerProps;
}>;

export const Bracket = ({
  onHasHoverChanged,
  hasHover,
  contentType,
  type,
  indent,
  children,
  expandTogglerProps,
}: Props) => {
  return (
    <span
      className={clsx(
        'DsPreviewJson-Bracket-root',
        `DsPreviewJson-${contentType}${
          type === 'open' ? 'Opening' : 'Closing'
        }Bracket`,
        hasHover && 'DsPreviewJson-Bracket-hover'
      )}
      onMouseOver={() => onHasHoverChanged?.(true)}
      onMouseOut={() => onHasHoverChanged?.(false)}
    >
      <Indentation indent={indent}>
        <span className="DsPreviewJson-Bracket-Wrapper">
          {expandTogglerProps && <ExpandToggler {...expandTogglerProps} />}
          <span className="DsPreviewJson-Bracket-Bracket">
            {contentType === 'object'
              ? type === 'open'
                ? '{'
                : '}'
              : type === 'open'
              ? '['
              : ']'}
          </span>
        </span>
      </Indentation>
      {children}
    </span>
  );
};
