import {
  CastaiUsersV1beta1UserOrganization,
  CastaiUsersV1beta1Organization,
} from '@cast/openapi';
export type {
  CastaiUsersV1beta1Membership as UpdateOrganizationUser,
  CastaiUsersV1beta1CreateInvitationsRequest as NewInvitationsResponse,
  CastaiUsersV1beta1Membership as OrganizationUser,
} from '@cast/openapi';

export enum OrganizationRole {
  owner = 'owner',
  member = 'member',
  analyst = 'analyst',
  viewer = 'viewer',
}

export type Organization = Required<
  Omit<CastaiUsersV1beta1UserOrganization, 'role'> & {
    role?: OrganizationRole;
  }
>;

export type UsersOrganization = Required<CastaiUsersV1beta1Organization>;
