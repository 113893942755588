import { OrganizationRole } from '@cast/types';

import { Actions, Subjects } from './types';

type ActionConfig = {
  permissions?: boolean;
  flags?: string[];
} & ({ permissions: boolean } | { flags: string[] });

export const ABILITY_CONFIG_V1: Record<
  OrganizationRole,
  Record<Subjects, Partial<Record<Actions, ActionConfig>>>
> = {
  [OrganizationRole.owner]: {
    OrganizationNotifications: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
    },
    OrganizationWebHooks: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationSsoConnections: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationBilling: {
      view: {
        flags: ['ui__page-modules--billing'],
      },
    },
    OrganizationBillingReport: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--billing-report'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--billing-report'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--billing-report'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--billing-report'],
      },
    },
    OrganizationDiscounts: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationCommitments: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
    },
    OrganizationRebalanceSchedules: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
    },
    OrganizationCostMonitoring: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
    },
    OrganizationSecurityCompliance: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
    },
    OrganizationSecurityVulnerabilities: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
    },
    OrganizationSecurityAttackPaths: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
    },
    OrganizationSecurityRuntime: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
    },
    OrganizationSecurityWorkloads: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
    },
    OrganizationSecuritySettings: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
    },
    OrganizationSecurityIntegrations: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationSecurityNodeRotation: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
    },
    OrganizationAiOptimizerReports: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
    },
    OrganizationAiOptimizerProviders: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
    },
    OrganizationAiOptimizerSettings: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationAiOptimizerPlayGround: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
    },
    OrganizationAiOptimizerModelDeployments: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
    },
    OrganizationAiOptimizerSettingsPage: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
    },
    OrganizationDatabaseOptimization: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationManagement: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-management'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-management'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-management'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-management'],
      },
    },
    OrganizationAccessManagement: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    Clusters: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    ClusterCostMonitoring: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
    },
    ClusterWorkloadAutoscaler: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
    },
    ClusterNodes: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
    },
    ClusterAutoscaler: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
    },
    ClusterPodMutations: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
    },
    ClusterRebalancer: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
    },
    ClusterAuditLog: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
    },
    FeatureChargebee: {
      view: {
        flags: ['ui__external-features--chargebee'],
      },
    },
    FeatureGoogleOptimize: {
      view: {
        flags: ['ui__external-features--google-optimize'],
      },
    },
    FeatureGoogleTagManager: {
      view: {
        flags: ['ui__external-features--google-tag-manager'],
      },
    },
    FeatureHubspot: {
      view: {
        flags: ['ui__external-features--hubspot'],
      },
    },
    FeatureSentry: {
      view: {
        flags: ['ui__external-features--sentry'],
      },
    },
    FeatureOrganizationRBACV2: {
      view: {
        flags: ['wire-rbacv2-enabled'],
      },
    },
    FeatureOrganizationSecurityImageDeletion: {
      view: {
        flags: ['ui__standalone--security-image-deletion'],
      },
    },
    FeatureOrganizationSecurityIntegrations: {
      view: {
        flags: ['ui__standalone--security-integrations'],
      },
    },
    FeatureOrganizationSecurityPhase2: {
      view: {
        flags: ['ui__standalone--security-phase-2'],
      },
    },
    FeatureOrganizationSecurityPolicyEnforcement: {
      view: {
        flags: ['ui__standalone--security-policy-enforcement'],
      },
    },
    FeatureOrganizationSecurityVulnerabilitiesAndPackagesExceptions: {
      view: {
        flags: [
          'ui__standalone--security-vulnerabilities-and-package-exceptions',
        ],
      },
    },
    FeatureOrganizationSecurityBaseImageRecommendation: {
      view: {
        flags: ['ui__standalone--security-base-images-recommendation'],
      },
    },
    FeatureClusterCostMonitoringArmSavings: {
      view: {
        flags: ['ui__standalone--arm-available-savings'],
      },
    },
    FeatureClusterCostMonitoringBlurOptimizedNodes: {
      view: {
        flags: ['ui__standalone--available-savings-blur-optimized-nodes'],
      },
    },
    FeatureClusterCostMonitoringWorkloadRightsizing: {
      view: {
        flags: ['ui__standalone--available-savings-workload-rightsizing'],
      },
    },
    FeatureClusterCostMonitoringSavingsProgress: {
      view: {
        flags: ['ui__page-modules--cluster__savings-progress'],
      },
    },
    FeatureClusterCostMonitoringShortDateRange: {
      view: {
        flags: ['ui__standalone--cost-monitoring-short-date-range'],
      },
    },
    FeatureClusterAutoscalerSwitch: {
      view: {
        flags: ['ui__standalone--autoscaler-switch'],
      },
    },
    FeatureClusterAutoscalerKarpenterMigration: {
      view: {
        flags: ['ui__standalone--karpenter-migration'],
      },
    },
    FeatureClusterWorkloadAutoscalerHpa: {
      view: {
        flags: ['ui__page-modules--cluster__workload-optimization__hpa'],
      },
    },
    FeatureClusterRebalancing: {
      view: {
        flags: ['ui__standalone--clusters-rebalancing'],
      },
    },
    FeatureClusterNodesShowInstancePrices: {
      view: {
        flags: ['ui__standalone--show-instance-prices'],
      },
    },
    FeatureClusterCostComparison: {
      view: {
        flags: ['ui__page-modules--cluster__cost-report__comparison'],
      },
    },
  },
  [OrganizationRole.member]: {
    OrganizationNotifications: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
    },
    OrganizationWebHooks: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationSsoConnections: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationBilling: {
      view: {
        flags: ['ui__page-modules--billing'],
      },
    },
    OrganizationBillingReport: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--billing-report'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
    },
    OrganizationDiscounts: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationCommitments: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
    },
    OrganizationRebalanceSchedules: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
    },
    OrganizationCostMonitoring: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
    },
    OrganizationSecurityCompliance: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
    },
    OrganizationSecurityVulnerabilities: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
    },
    OrganizationSecurityAttackPaths: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
    },
    OrganizationSecurityRuntime: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
    },
    OrganizationSecurityWorkloads: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
    },
    OrganizationSecuritySettings: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
    },
    OrganizationSecurityIntegrations: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationSecurityNodeRotation: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
    },
    OrganizationAiOptimizerReports: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
    },
    OrganizationAiOptimizerProviders: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
    },
    OrganizationAiOptimizerSettings: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationAiOptimizerPlayGround: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
    },
    OrganizationAiOptimizerModelDeployments: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
    },
    OrganizationAiOptimizerSettingsPage: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
    },
    OrganizationDatabaseOptimization: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    OrganizationManagement: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-management'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
    },
    OrganizationAccessManagement: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    Clusters: {
      view: {
        permissions: true,
      },
      create: {
        permissions: true,
      },
      update: {
        permissions: true,
      },
      delete: {
        permissions: true,
      },
    },
    ClusterCostMonitoring: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
    },
    ClusterWorkloadAutoscaler: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
    },
    ClusterNodes: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
    },
    ClusterAutoscaler: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
    },
    ClusterPodMutations: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
    },
    ClusterRebalancer: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
    },
    ClusterAuditLog: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
    },
    FeatureChargebee: {
      view: {
        flags: ['ui__external-features--chargebee'],
      },
    },
    FeatureGoogleOptimize: {
      view: {
        flags: ['ui__external-features--google-optimize'],
      },
    },
    FeatureGoogleTagManager: {
      view: {
        flags: ['ui__external-features--google-tag-manager'],
      },
    },
    FeatureHubspot: {
      view: {
        flags: ['ui__external-features--hubspot'],
      },
    },
    FeatureSentry: {
      view: {
        flags: ['ui__external-features--sentry'],
      },
    },
    FeatureOrganizationRBACV2: {
      view: {
        flags: ['wire-rbacv2-enabled'],
      },
    },
    FeatureOrganizationSecurityImageDeletion: {
      view: {
        flags: ['ui__standalone--security-image-deletion'],
      },
    },
    FeatureOrganizationSecurityIntegrations: {
      view: {
        flags: ['ui__standalone--security-integrations'],
      },
    },
    FeatureOrganizationSecurityPhase2: {
      view: {
        flags: ['ui__standalone--security-phase-2'],
      },
    },
    FeatureOrganizationSecurityPolicyEnforcement: {
      view: {
        flags: ['ui__standalone--security-policy-enforcement'],
      },
    },
    FeatureOrganizationSecurityVulnerabilitiesAndPackagesExceptions: {
      view: {
        flags: [
          'ui__standalone--security-vulnerabilities-and-package-exceptions',
        ],
      },
    },
    FeatureOrganizationSecurityBaseImageRecommendation: {
      view: {
        flags: ['ui__standalone--security-base-images-recommendation'],
      },
    },
    FeatureClusterCostMonitoringArmSavings: {
      view: {
        flags: ['ui__standalone--arm-available-savings'],
      },
    },
    FeatureClusterCostMonitoringBlurOptimizedNodes: {
      view: {
        flags: ['ui__standalone--available-savings-blur-optimized-nodes'],
      },
    },
    FeatureClusterCostMonitoringWorkloadRightsizing: {
      view: {
        flags: ['ui__standalone--available-savings-workload-rightsizing'],
      },
    },
    FeatureClusterCostMonitoringSavingsProgress: {
      view: {
        flags: ['ui__page-modules--cluster__savings-progress'],
      },
    },
    FeatureClusterCostMonitoringShortDateRange: {
      view: {
        flags: ['ui__standalone--cost-monitoring-short-date-range'],
      },
    },
    FeatureClusterAutoscalerSwitch: {
      view: {
        flags: ['ui__standalone--autoscaler-switch'],
      },
    },
    FeatureClusterAutoscalerKarpenterMigration: {
      view: {
        flags: ['ui__standalone--karpenter-migration'],
      },
    },
    FeatureClusterWorkloadAutoscalerHpa: {
      view: {
        flags: ['ui__page-modules--cluster__workload-optimization__hpa'],
      },
    },
    FeatureClusterRebalancing: {
      view: {
        flags: ['ui__standalone--clusters-rebalancing'],
      },
    },
    FeatureClusterNodesShowInstancePrices: {
      view: {
        flags: ['ui__standalone--show-instance-prices'],
      },
    },
    FeatureClusterCostComparison: {
      view: {
        flags: ['ui__page-modules--cluster__cost-report__comparison'],
      },
    },
  },
  [OrganizationRole.analyst]: {
    OrganizationNotifications: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--notifications'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--notifications'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--notifications'],
      },
    },
    OrganizationWebHooks: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationSsoConnections: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationBilling: {
      view: {
        flags: ['ui__page-modules--billing'],
      },
    },
    OrganizationBillingReport: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--billing-report'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
    },
    OrganizationDiscounts: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationCommitments: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization__commitments'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization__commitments'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization__commitments'],
      },
    },
    OrganizationRebalanceSchedules: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
    },
    OrganizationCostMonitoring: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
    },
    OrganizationSecurityCompliance: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
    },
    OrganizationSecurityVulnerabilities: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
    },
    OrganizationSecurityAttackPaths: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
    },
    OrganizationSecurityRuntime: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
    },
    OrganizationSecurityWorkloads: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
    },
    OrganizationSecuritySettings: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__settings'],
      },
    },
    OrganizationSecurityIntegrations: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationSecurityNodeRotation: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
    },
    OrganizationAiOptimizerReports: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
    },
    OrganizationAiOptimizerProviders: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
    },
    OrganizationAiOptimizerSettings: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationAiOptimizerPlayGround: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
    },
    OrganizationAiOptimizerModelDeployments: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
    },
    OrganizationAiOptimizerSettingsPage: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
    },
    OrganizationDatabaseOptimization: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationManagement: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-management'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
    },
    OrganizationAccessManagement: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    Clusters: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    ClusterCostMonitoring: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      create: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      update: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      delete: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
    },
    ClusterWorkloadAutoscaler: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
    },
    ClusterNodes: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__node'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__node'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__node'],
      },
    },
    ClusterAutoscaler: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
    },
    ClusterPodMutations: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
    },
    ClusterRebalancer: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
    },
    ClusterAuditLog: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
    },
    FeatureChargebee: {
      view: {
        flags: ['ui__external-features--chargebee'],
      },
    },
    FeatureGoogleOptimize: {
      view: {
        flags: ['ui__external-features--google-optimize'],
      },
    },
    FeatureGoogleTagManager: {
      view: {
        flags: ['ui__external-features--google-tag-manager'],
      },
    },
    FeatureHubspot: {
      view: {
        flags: ['ui__external-features--hubspot'],
      },
    },
    FeatureSentry: {
      view: {
        flags: ['ui__external-features--sentry'],
      },
    },
    FeatureOrganizationRBACV2: {
      view: {
        flags: ['wire-rbacv2-enabled'],
      },
    },
    FeatureOrganizationSecurityImageDeletion: {
      view: {
        flags: ['ui__standalone--security-image-deletion'],
      },
    },
    FeatureOrganizationSecurityIntegrations: {
      view: {
        flags: ['ui__standalone--security-integrations'],
      },
    },
    FeatureOrganizationSecurityPhase2: {
      view: {
        flags: ['ui__standalone--security-phase-2'],
      },
    },
    FeatureOrganizationSecurityPolicyEnforcement: {
      view: {
        flags: ['ui__standalone--security-policy-enforcement'],
      },
    },
    FeatureOrganizationSecurityVulnerabilitiesAndPackagesExceptions: {
      view: {
        flags: [
          'ui__standalone--security-vulnerabilities-and-package-exceptions',
        ],
      },
    },
    FeatureOrganizationSecurityBaseImageRecommendation: {
      view: {
        flags: ['ui__standalone--security-base-images-recommendation'],
      },
    },
    FeatureClusterCostMonitoringArmSavings: {
      view: {
        flags: ['ui__standalone--arm-available-savings'],
      },
    },
    FeatureClusterCostMonitoringBlurOptimizedNodes: {
      view: {
        flags: ['ui__standalone--available-savings-blur-optimized-nodes'],
      },
    },
    FeatureClusterCostMonitoringWorkloadRightsizing: {
      view: {
        flags: ['ui__standalone--available-savings-workload-rightsizing'],
      },
    },
    FeatureClusterCostMonitoringSavingsProgress: {
      view: {
        flags: ['ui__page-modules--cluster__savings-progress'],
      },
    },
    FeatureClusterCostMonitoringShortDateRange: {
      view: {
        flags: ['ui__standalone--cost-monitoring-short-date-range'],
      },
    },
    FeatureClusterAutoscalerSwitch: {
      view: {
        flags: ['ui__standalone--autoscaler-switch'],
      },
    },
    FeatureClusterAutoscalerKarpenterMigration: {
      view: {
        flags: ['ui__standalone--karpenter-migration'],
      },
    },
    FeatureClusterWorkloadAutoscalerHpa: {
      view: {
        flags: ['ui__page-modules--cluster__workload-optimization__hpa'],
      },
    },
    FeatureClusterRebalancing: {
      view: {
        flags: ['ui__standalone--clusters-rebalancing'],
      },
    },
    FeatureClusterNodesShowInstancePrices: {
      view: {
        flags: ['ui__standalone--show-instance-prices'],
      },
    },
    FeatureClusterCostComparison: {
      view: {
        flags: ['ui__page-modules--cluster__cost-report__comparison'],
      },
    },
  },
  [OrganizationRole.viewer]: {
    OrganizationNotifications: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--notifications'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--notifications'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--notifications'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--notifications'],
      },
    },
    OrganizationWebHooks: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationSsoConnections: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationBilling: {
      view: {
        flags: ['ui__page-modules--billing'],
      },
    },
    OrganizationBillingReport: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--billing-report'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--billing-report'],
      },
    },
    OrganizationDiscounts: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationCommitments: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__commitments'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization__commitments'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization__commitments'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization__commitments'],
      },
    },
    OrganizationRebalanceSchedules: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization__scheduled-rebalancing'],
      },
    },
    OrganizationCostMonitoring: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization__cost-monitoring'],
      },
    },
    OrganizationSecurityCompliance: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__best-practices'],
      },
    },
    OrganizationSecurityVulnerabilities: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__image-security'],
      },
    },
    OrganizationSecurityAttackPaths: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__attack-path'],
      },
    },
    OrganizationSecurityRuntime: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__anomalies'],
      },
    },
    OrganizationSecurityWorkloads: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__workloads'],
      },
    },
    OrganizationSecuritySettings: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__settings'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__settings'],
      },
    },
    OrganizationSecurityIntegrations: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationSecurityNodeRotation: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-security__node-os-updates'],
      },
    },
    OrganizationAiOptimizerReports: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__cost-report'],
      },
    },
    OrganizationAiOptimizerProviders: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__providers'],
      },
    },
    OrganizationAiOptimizerSettings: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationAiOptimizerPlayGround: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__playground'],
      },
    },
    OrganizationAiOptimizerModelDeployments: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__model-deployments'],
      },
    },
    OrganizationAiOptimizerSettingsPage: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-llm__settings'],
      },
    },
    OrganizationDatabaseOptimization: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    OrganizationManagement: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--organization-management'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--organization-management'],
      },
    },
    OrganizationAccessManagement: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    Clusters: {
      view: {
        permissions: true,
      },
      create: {
        permissions: false,
      },
      update: {
        permissions: false,
      },
      delete: {
        permissions: false,
      },
    },
    ClusterCostMonitoring: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__cost-monitoring'],
      },
    },
    ClusterWorkloadAutoscaler: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__workload-optimization'],
      },
    },
    ClusterNodes: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__node'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__node'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__node'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__node'],
      },
    },
    ClusterAutoscaler: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__settings'],
      },
    },
    ClusterPodMutations: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
      },
    },
    ClusterRebalancer: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__rebalancer'],
      },
    },
    ClusterAuditLog: {
      view: {
        permissions: true,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      create: {
        permissions: false,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      update: {
        permissions: false,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
      delete: {
        permissions: false,
        flags: ['ui__page-modules--cluster__audit-log'],
      },
    },
    FeatureChargebee: {
      view: {
        flags: ['ui__external-features--chargebee'],
      },
    },
    FeatureGoogleOptimize: {
      view: {
        flags: ['ui__external-features--google-optimize'],
      },
    },
    FeatureGoogleTagManager: {
      view: {
        flags: ['ui__external-features--google-tag-manager'],
      },
    },
    FeatureHubspot: {
      view: {
        flags: ['ui__external-features--hubspot'],
      },
    },
    FeatureSentry: {
      view: {
        flags: ['ui__external-features--sentry'],
      },
    },
    FeatureOrganizationRBACV2: {
      view: {
        flags: ['wire-rbacv2-enabled'],
      },
    },
    FeatureOrganizationSecurityImageDeletion: {
      view: {
        flags: ['ui__standalone--security-image-deletion'],
      },
    },
    FeatureOrganizationSecurityIntegrations: {
      view: {
        flags: ['ui__standalone--security-integrations'],
      },
    },
    FeatureOrganizationSecurityPhase2: {
      view: {
        flags: ['ui__standalone--security-phase-2'],
      },
    },
    FeatureOrganizationSecurityPolicyEnforcement: {
      view: {
        flags: ['ui__standalone--security-policy-enforcement'],
      },
    },
    FeatureOrganizationSecurityVulnerabilitiesAndPackagesExceptions: {
      view: {
        flags: [
          'ui__standalone--security-vulnerabilities-and-package-exceptions',
        ],
      },
    },
    FeatureOrganizationSecurityBaseImageRecommendation: {
      view: {
        flags: ['ui__standalone--security-base-images-recommendation'],
      },
    },
    FeatureClusterCostMonitoringArmSavings: {
      view: {
        flags: ['ui__standalone--arm-available-savings'],
      },
    },
    FeatureClusterCostMonitoringBlurOptimizedNodes: {
      view: {
        flags: ['ui__standalone--available-savings-blur-optimized-nodes'],
      },
    },
    FeatureClusterCostMonitoringWorkloadRightsizing: {
      view: {
        flags: ['ui__standalone--available-savings-workload-rightsizing'],
      },
    },
    FeatureClusterCostMonitoringSavingsProgress: {
      view: {
        flags: ['ui__page-modules--cluster__savings-progress'],
      },
    },
    FeatureClusterCostMonitoringShortDateRange: {
      view: {
        flags: ['ui__standalone--cost-monitoring-short-date-range'],
      },
    },
    FeatureClusterAutoscalerSwitch: {
      view: {
        flags: ['ui__standalone--autoscaler-switch'],
      },
    },
    FeatureClusterAutoscalerKarpenterMigration: {
      view: {
        flags: ['ui__standalone--karpenter-migration'],
      },
    },
    FeatureClusterWorkloadAutoscalerHpa: {
      view: {
        flags: ['ui__page-modules--cluster__workload-optimization__hpa'],
      },
    },
    FeatureClusterRebalancing: {
      view: {
        flags: ['ui__standalone--clusters-rebalancing'],
      },
    },
    FeatureClusterNodesShowInstancePrices: {
      view: {
        flags: ['ui__standalone--show-instance-prices'],
      },
    },
    FeatureClusterCostComparison: {
      view: {
        flags: ['ui__page-modules--cluster__cost-report__comparison'],
      },
    },
  },
};
