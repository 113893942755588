import { useMemo } from 'react';

import { K8sProvider } from '@cast/types';
import { isDemoMode } from '@cast/utils';

import { useClusters } from './useClusters';

export const useOrgHasClustersWithProvider = (
  provider: `${K8sProvider}` | Array<`${K8sProvider}`>
) => {
  const { clusters } = useClusters();
  return useMemo(() => {
    if (isDemoMode()) {
      return false;
    }

    if (Array.isArray(provider)) {
      return clusters.some((cluster) =>
        provider.some((provider) => cluster.providerType === provider)
      );
    } else {
      return clusters.some((cluster) => cluster.providerType === provider);
    }
  }, [provider, clusters]);
};
