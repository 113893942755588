import { PropsWithChildren, useContext, useEffect, useRef } from 'react';

import { Box, PopperProps } from '@mui/material';

import { Beacon, TourFooter, TourTooltip } from '../_components';
import { TourContext } from '../providers/TourProvider';
import { TourStepProps } from '../types';

export const TourStep = ({
  children,
  step,
  type,
  highlight,
  scrollIntoViewOptions = {},
  boxProps = {},
  text,
  note,
  placement,
  callback,
  popperSkidding,
  popperDistance,
  beaconPosition,
}: PropsWithChildren<TourStepProps>) => {
  const ctx = useContext(TourContext);
  const isStepActive =
    !!ctx?.isActive && step === ctx?.activeStep && type === ctx?.type;

  const containerRef = useRef<HTMLDivElement>();

  const popperProps: Partial<PopperProps> =
    popperSkidding || popperDistance
      ? {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [popperSkidding || 0, popperDistance || 0],
              },
            },
          ],
        }
      : {};

  useEffect(() => {
    if (containerRef.current && isStepActive) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        ...scrollIntoViewOptions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStepActive, containerRef]);

  if (!ctx || !isStepActive) {
    return <>{children}</>;
  }

  if (highlight === 'bubble') {
    return (
      <Box
        data-step={step}
        position="relative"
        {...boxProps}
        ref={containerRef}
      >
        {children}
        <TourTooltip
          open
          text={text}
          note={note}
          placement={placement}
          footer={<TourFooter callback={callback} />}
          popperProps={popperProps}
          testId="tour-step"
        >
          <Beacon {...(beaconPosition ?? { top: 5 })} />
        </TourTooltip>
      </Box>
    );
  }

  return (
    <TourTooltip
      open
      text={text}
      note={note}
      placement={placement}
      footer={<TourFooter callback={callback} />}
      popperProps={popperProps}
      testId="tour-step"
    >
      <Box ref={containerRef} {...boxProps} className="tour-active">
        {children}
      </Box>
    </TourTooltip>
  );
};
