import { ReactNode } from 'react';

import { styled } from '@mui/material';
import { SxProps } from '@mui/system';

import { ProgressBar, ProgressBarProps } from './ProgressBar';
import { shouldForwardProp } from '../../../utils/shouldForwardProp';

export type ProgressBarWithLabelProps = ProgressBarProps & {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  total?: number;
  progressBarSx?: SxProps;
};

const AdornmentWrapper = styled('div', {
  name: 'DsProgressBarWithLabel',
  slot: 'AdornmentWrapper',
  target: 'DsProgressBarWithLabel-AdornmentWrapper',
  shouldForwardProp,
  overridesResolver: ({ className }, styles) => {
    return [
      className.endsWith('StartAdornmentWrapper')
        ? styles.startAdornmentWrapper
        : styles.endAdornmentWrapper,
    ];
  },
})<{ ownerState: ProgressBarWithLabelProps; className?: string }>({});

export const ProgressBarWithLabel = styled(
  ({
    className,
    startAdornment,
    endAdornment,
    ...props
  }: ProgressBarWithLabelProps) => {
    return (
      <div className={className}>
        {startAdornment != null ? (
          <AdornmentWrapper
            ownerState={props}
            className="DsProgressBarWithLabel-StartAdornmentWrapper"
          >
            {startAdornment}
          </AdornmentWrapper>
        ) : null}
        <ProgressBar variant="determinate" {...props} />
        {endAdornment != null ? (
          <AdornmentWrapper
            ownerState={props}
            className="DsProgressBarWithLabel-EndAdornmentWrapper"
          >
            {endAdornment}
          </AdornmentWrapper>
        ) : null}
      </div>
    );
  },
  {
    name: 'DsProgressBarWithLabel',
    slot: 'Root',
    target: 'ProgressBarWithLabel-root',
    overridesResolver: ({ startAdornment, endAdornment, size }, theme) => {
      return [
        theme.root,
        startAdornment && theme.startAdornment,
        endAdornment && theme.endAdornment,
        startAdornment && endAdornment && theme.bothAdornments,
        theme[size],
      ];
    },
  }
)<ProgressBarWithLabelProps>({});
