import { persistenceDrivers } from './drivers';
import { makeStateConverter } from './stateConverters';
import { PersistenceProviderProps } from '../../persistence/PersistenceProvider';
import { DataTableProps } from '../types';

export const usePersistence = ({
  cacheKey,
  persistence,
}: DataTableProps): PersistenceProviderProps => {
  if (persistence === undefined) {
    return { id: cacheKey };
  }
  if (typeof persistence === 'boolean') {
    return persistence ? { id: cacheKey } : {};
  }
  const { id, driver, converter, persist } = persistence;
  return {
    id: id || cacheKey,
    persistenceDriver: driver
      ? typeof driver === 'object'
        ? driver
        : persistenceDrivers[driver]
      : undefined,
    stateConverter:
      converter || (persist ? makeStateConverter(persist) : undefined),
  };
};
