import { useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { TZDate } from 'react-day-picker';

import { PeriodComparisonInfinitePicker } from './PeriodComparisonInfinitePicker';
import { PeriodComparisonPickerInput } from './PeriodComparisonPickerInput';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import {
  DatePeriod,
  InfiniteDayPickerRef,
  PeriodComparisonPickerProviderProps,
  PeriodCondition,
} from '../../types';
import {
  utcToDate,
  utcNow,
  rangeToUtc,
  buildDisabledMatcher,
} from '../../utils';

type PeriodDateRangeProps = {
  selected: DatePeriod;
  setSelected: (range: DatePeriod) => void;
  pickerRef: InfiniteDayPickerRef;
  setPickerRef: (ref: InfiniteDayPickerRef) => void;
  minDate?: PeriodCondition;
  maxDate?: PeriodCondition;
  endMonth?: PeriodCondition;
  startMonth?: PeriodCondition;
};

type PeriodComparisonPickerBodyProps = {
  label: string;
  variant: 'main' | 'secondary';
  hideRangeInputs: boolean;
  initialRange?: string;
  periodProps: PeriodDateRangeProps;
  otherRange: DatePeriod;
};

export const PeriodComparisonPickerBody = ({
  label,
  variant,
  hideRangeInputs,
  initialRange,
  otherRange,
  periodProps,
}: PeriodComparisonPickerBodyProps) => {
  const ctx = useDatePickerContext<PeriodComparisonPickerProviderProps>();

  const [initialMonth] = useState<TZDate>(
    utcToDate(initialRange ? initialRange : utcNow(), ctx.timezone)
  );

  const [startPlaceholder, setStartPlaceholder] = useState('');
  const [endPlaceholder, setEndPlaceholder] = useState('');

  const disabled = useMemo(() => {
    const rangeInUtc = rangeToUtc(periodProps.selected);
    const otherRangeInUtc = rangeToUtc(otherRange);

    const minDate =
      typeof periodProps.minDate === 'function'
        ? periodProps.minDate({
            range: rangeInUtc,
            otherRange: otherRangeInUtc,
            timezone: ctx.timezone,
          })
        : periodProps.minDate;

    const maxDate =
      typeof periodProps.maxDate === 'function'
        ? periodProps.maxDate({
            range: rangeInUtc,
            otherRange: otherRangeInUtc,
            timezone: ctx.timezone,
          })
        : periodProps.maxDate;

    return buildDisabledMatcher(minDate, maxDate, ctx.timezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodProps.selected, otherRange, ctx.timezone]);

  const startMonth = useMemo(() => {
    const startMonth =
      typeof periodProps.startMonth === 'function'
        ? periodProps.startMonth({
            range: rangeToUtc(periodProps.selected),
            otherRange: rangeToUtc(otherRange),
            timezone: ctx.timezone,
          })
        : periodProps.startMonth;

    if (startMonth) {
      return new TZDate(startMonth, ctx.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodProps.selected, otherRange, ctx.timezone]);

  const endMonth = useMemo(() => {
    const endMonth =
      typeof periodProps.endMonth === 'function'
        ? periodProps.endMonth({
            range: rangeToUtc(periodProps.selected),
            otherRange: rangeToUtc(otherRange),
            timezone: ctx.timezone,
          })
        : periodProps.endMonth;

    if (endMonth) {
      return new TZDate(endMonth, ctx.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodProps.selected, otherRange, ctx.timezone]);

  return (
    <Stack gap={8} data-testid={`${label}-body`}>
      {!hideRangeInputs && (
        <Box px={24}>
          <PeriodComparisonPickerInput
            label={label}
            selectedRange={periodProps.selected}
            setSelectedRange={periodProps.setSelected}
            endPlaceholder={endPlaceholder}
            startPlaceholder={startPlaceholder}
            minDate={periodProps.minDate}
            maxDate={periodProps.maxDate}
            otherRange={otherRange}
            pickerRef={periodProps.pickerRef}
          />
        </Box>
      )}

      <PeriodComparisonInfinitePicker
        label={label}
        selectedRange={periodProps.selected}
        setSelectedRange={periodProps.setSelected}
        setEndPlaceholder={setEndPlaceholder}
        setStartPlaceholder={setStartPlaceholder}
        infinitePickerProps={{
          variant: variant,
          initialMonth: initialMonth,
          startMonth: startMonth,
          endMonth: endMonth,
        }}
        otherRange={otherRange}
        disabledDates={disabled}
        setPickerRef={periodProps.setPickerRef}
      />
    </Stack>
  );
};
