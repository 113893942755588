const RADIAN = Math.PI / 180;

export const polarToCartesian = (
  cx: number,
  cy: number,
  radius: number,
  angle: number
): { x: number; y: number } => ({
  x: cx + Math.cos(-RADIAN * angle) * radius,
  y: cy + Math.sin(-RADIAN * angle) * radius,
});

export const linearMap = (
  value: number,
  sFrom: number,
  sTo: number,
  dFrom: number,
  dTo: number
) => {
  // Y = (X-A)/(B-A) * (D-C) + C
  return ((value - sFrom) / (sTo - sFrom)) * (dTo - dFrom) + dFrom;
};
