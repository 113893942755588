import { PropsWithChildren } from 'react';

import { Box, BoxProps, capitalize, styled } from '@mui/material';

import { shouldForwardProp } from '../../../utils/shouldForwardProp';
import { PreviewConfigProps, usePreviewConfig } from '../_providers';

const Root = styled(Box, {
  name: 'DsCodeActions',
  slot: 'Root',
  target: 'DsCodeActions-root',
  shouldForwardProp,
  overridesResolver: ({ ownerState }, styles) => [
    styles.root,
    styles[`size${capitalize(ownerState.size)}`],
  ],
})<{ ownerState: { size?: PreviewConfigProps['size'] } }>({});

export type CodeActionsProps = PropsWithChildren<BoxProps>;

export const CodeActions = (props: CodeActionsProps) => {
  const { size } = usePreviewConfig();
  return <Root {...props} ownerState={{ size }} />;
};
