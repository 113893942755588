import { Stack, styled } from '@mui/material';
import { SxProps } from '@mui/system';

import { AddRowButton } from './AddRowButton';
import { RffInputsArray, RffInputsArrayProps } from './RffInputsArray';
import { RffInputsArrayRowState } from './RffInputsArrayContext';
import { WhenMode } from '../WhenMode';

const StyledInputsArray = styled(RffInputsArray)({
  '& .RffInputsArray-rows': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& .RffInputsArray-row': {
      '&:not(:first-of-type)': {
        '& .MuiInputLabel-root': {
          display: 'none',
        },
      },
    },
  },
});

type Props<Value = any> = RffInputsArrayProps<Value> & {
  withAddRowButton?: boolean;
  addRowButtonTestId?: string;
  addRowButtonText?: string;
  addRowButtonDisabled?: boolean;
  addRowButtonSx?: SxProps;
  rowWrapperSx?: SxProps;
};

export const InputsStack = <Value = any,>({
  renderInputsRow,
  withAddRowButton = true,
  addRowButtonTestId,
  addRowButtonText,
  addRowButtonDisabled,
  addRowButtonSx,
  rowWrapperSx,
  ...rest
}: Props<Value>) => {
  return (
    <StyledInputsArray
      testId="inputs-stack"
      {...rest}
      renderInputsRow={(rowState: RffInputsArrayRowState) => {
        return (
          <Stack sx={rowWrapperSx}>
            {renderInputsRow(rowState)}
            {withAddRowButton && (
              <WhenMode mode={['edit', 'create']}>
                <AddRowButton
                  testId={addRowButtonTestId}
                  text={addRowButtonText}
                  disabled={addRowButtonDisabled}
                  sx={addRowButtonSx}
                />
              </WhenMode>
            )}
          </Stack>
        );
      }}
    />
  );
};
