import {
  GroupedColumnOrdering,
  GroupedColumnProps,
  isColumnsGroup,
  ParsedGroupProps,
} from '../types';

export type ApplyOrderingArgs = {
  groupedColumnsProps: GroupedColumnProps[];
  columnsOrder?: GroupedColumnOrdering[];
};
// Returns same column props, but with ordering applied
export const applyOrdering = ({
  groupedColumnsProps,
  columnsOrder,
}: ApplyOrderingArgs): GroupedColumnProps[] => {
  if (!columnsOrder?.length) {
    return groupedColumnsProps;
  }
  const result: GroupedColumnProps[] = [];
  for (const { id, columns } of columnsOrder) {
    if (columns?.length) {
      // Is group
      const group = groupedColumnsProps.find(
        (props) => props.id === id && isColumnsGroup(props)
      );
      if (group) {
        result.push(group);
      }
    } else {
      // Is column
      const column = groupedColumnsProps.find((props) => {
        return props.id === id && !isColumnsGroup(props);
      }) as ParsedGroupProps;
      if (column) {
        result.push(column);
      }
    }
  }
  if (groupedColumnsProps.length !== columnsOrder.length) {
    const missingColumns = groupedColumnsProps.filter(
      ({ id }) => !columnsOrder.some((order) => order.id === id)
    );
    return [...result, ...missingColumns];
  }

  return result;
};
