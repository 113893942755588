import { useMemo, useState } from 'react';

import { getElementFont, getTextWidth } from '../dom';

export type FontSettings = {
  fontWeight: string;
  fontSize: string;
  fontFamily: string;
};

/**
 * @see https://www.w3docs.com/snippets/javascript/how-to-calculate-text-width-with-javascript.html
 */
export const useTextWidth = (
  text?: string,
  el?: HTMLElement,
  fontSettings?: FontSettings
) => {
  const font = useMemo(() => {
    if (fontSettings) {
      return `${fontSettings.fontWeight} ${fontSettings.fontSize} ${fontSettings.fontFamily}`;
    }
    if (el) {
      return getElementFont(el);
    }
  }, [el, fontSettings]);
  const [canvasEl] = useState(() =>
    document ? document.createElement('canvas') : undefined
  );
  return useMemo(() => {
    if (el && canvasEl && font) {
      return getTextWidth(text || '', canvasEl, font);
    }
  }, [canvasEl, font, el, text]);
};
