import { globalTourSteps } from 'common/tour';

export const costOverTimeTourId = 'cost-over-time';

export const tourSteps = {
  metricCards: 'metric-cards',
  datePicker: 'date-picker',
  computeSpendChart: 'compute-spend-chart',
  normalizedCostChart: 'normalized-cost-chart',
  dailyComputeSpendDetails: 'daily-compute-spend-details',
};

export const costOverTimeTourStepsOrder = [
  tourSteps.metricCards,
  tourSteps.datePicker,
  tourSteps.computeSpendChart,
  tourSteps.normalizedCostChart,
  tourSteps.dailyComputeSpendDetails,
  globalTourSteps.connectMyCluster,
];
