import { ReactNode } from 'react';

import { Form } from 'react-final-form';

import { makeValidateSync } from '@cast/utils';

import { RffTextArea } from 'core/forms/rff';
import { AzureCredentials } from 'types/credentials';
import { AzureCredentialsSchema } from 'utils/validators';

type Props = {
  onSubmit: (value: { credentials: AzureCredentials }) => void;
  setSubmitHandler: (v: () => void) => void;
  error?: ReactNode;
};

const validate = makeValidateSync(AzureCredentialsSchema);

export const AzureCredentialsForm = ({
  onSubmit,
  setSubmitHandler,
  error,
}: Props) => {
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        setSubmitHandler(handleSubmit);
        return (
          <form onSubmit={handleSubmit} noValidate>
            <RffTextArea
              testId="azure-credentials-input"
              autoComplete="off"
              id="credentials-azure"
              name="credentials.json"
              inputProps={{ 'aria-label': 'credentials azure json' }}
              rows={5}
              required
              fullWidth
              placeholder="Credentials JSON"
              error={error}
            />
          </form>
        );
      }}
    />
  );
};
