import { HTMLAttributes, memo } from 'react';

import isEqual from 'lodash/isEqual';

import { ColumnModel, RowState } from '../../../types';

type Props = {
  columnModel: ColumnModel;
  rowState: RowState;
  style?: HTMLAttributes<HTMLDivElement>['style'];
};

export const BodyCell = memo(({ columnModel, rowState, style }: Props) => {
  return (
    <div
      className={`DS-Table-CellWrapper DS-Table-BodyCellWrapper DS-Table-CellWrapper-${
        columnModel.id
      } ${columnModel.locked ? 'DS-Table-lockedCell' : ''} ${
        columnModel.floating ? 'DS-Table-floatingCell' : ''
      } ${
        columnModel.stopClickPropagation ? 'DS-Table-stopClickPropagation' : ''
      }`}
      style={style}
      onClick={
        columnModel.stopClickPropagation
          ? (e) => e.stopPropagation()
          : undefined
      }
    >
      <div className="DS-Table-Cell DS-Table-BodyCell">
        {columnModel.renderCell(rowState.row, rowState)}
      </div>
    </div>
  );
}, isEqual);
BodyCell.displayName = 'BodyCell';
