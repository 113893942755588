import { REPLACE_STATE_EVENT } from './constants';

/**
 * `window.history.replaceState` doesn't trigger `popstate` event or any other event.
 *  So we need to dispatch a custom event to notify other components.
 * */
const dispatchReplaceStateEvent = (url: string) => {
  window.dispatchEvent(
    new CustomEvent(REPLACE_STATE_EVENT, {
      detail: { url },
    })
  );
};

export const isUrl = (str: string) => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const setQueryParamSilently = (key: string, value: string | number) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, String(value));
  window.history.replaceState(
    window.history.state,
    window.document.title,
    url.toString()
  );
  dispatchReplaceStateEvent(url.toString());
};

export const setSerializedQueryParamSilently = (key: string, value: any) => {
  try {
    const serializedValue = encodeURIComponent(JSON.stringify(value));
    setQueryParamSilently(key, serializedValue);
  } catch (err) {
    console.error('Error serializing value');
  }
};

export const clearQueryParamSilently = (keys: string | string[]) => {
  if (!Array.isArray(keys)) keys = [keys];
  const url = new URL(window.location.href);

  keys.forEach((key) => url.searchParams.delete(key));
  window.history.replaceState(
    window.history.state,
    window.document.title,
    url.toString()
  );
  dispatchReplaceStateEvent(url.toString());
};

export const getQueryParam = (key: string) => {
  return new URLSearchParams(window.location.search).get(key);
};

export const getDeserializedQueryParam = <T = any>(
  key: string
): T | undefined => {
  const value = new URLSearchParams(window.location.search).get(key);
  if (value) {
    try {
      return JSON.parse(decodeURIComponent(value));
    } catch (err) {
      console.error(`Failed to deserialize query param: ${key}`);
    }
  }
  return undefined;
};

export const replaceSearchParam = (
  url: string,
  param: string,
  newValue: string
) => {
  const regex = new RegExp(`(${param}=)([^&]*)`);
  return url.replace(regex, `$1${newValue}`);
};
