import { AxiosInstance } from 'axios';

import { PricingAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const PricingApiAdapter = (client: AxiosInstance) => {
  const generatedApi = PricingAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    getClusterNodesPricing: generatedApi.pricingAPIGetPricingForClusterNodes,
    getNodePricing: generatedApi.pricingAPIGetPricingForClusterNode,
  };
};
