import { Children, ComponentPropsWithoutRef, useState } from 'react';

import { Box, Stack, SxProps, Typography } from '@mui/material';

import { Icons, Scroller, mergeSx } from '@cast/design-system';

import { LinkBehaviour } from 'components/common';

type Props = {
  children: React.ReactNode;
  maxCount?: number;
  expanderText?: (
    isExpanded: boolean,
    remainingChildrenCount: number
  ) => string;
  LinkBehaviourProps?: ComponentPropsWithoutRef<typeof LinkBehaviour>;
  sx?: SxProps;
  scrollerSx?: SxProps;
  childrenContainerSx?: SxProps;
};

const defaultExpanderText = (
  isExpanded: boolean,
  remainingChildrenCount: number
) => {
  if (isExpanded) {
    return 'View less';
  }
  return `+${remainingChildrenCount} more`;
};

export const ExpandableList = ({
  children,
  maxCount = 3,
  expanderText = defaultExpanderText,
  LinkBehaviourProps,
  sx,
  scrollerSx,
  childrenContainerSx,
}: Props) => {
  const childrenArray = Children.toArray(children);
  const [isExpanded, setIsExpanded] = useState(false);
  const isExpandable = childrenArray.length > maxCount;

  return (
    <Box sx={sx}>
      <Scroller
        sx={mergeSx({ maxHeight: '360px', overflow: 'auto' }, scrollerSx)}
      >
        <Stack sx={childrenContainerSx}>
          {childrenArray.slice(0, isExpanded ? childrenArray.length : maxCount)}
        </Stack>
      </Scroller>
      {isExpandable && (
        <LinkBehaviour
          onClick={() => setIsExpanded((v) => !v)}
          {...LinkBehaviourProps}
        >
          <Stack direction="row" gap={4} alignItems="center">
            <Typography variant="P14M">
              {expanderText(isExpanded, childrenArray.length - maxCount)}
            </Typography>
            {isExpanded ? (
              // For some reason without `pointerEvents: 'none'`, click on the icon closes parent drawer
              <Icons.CaretUp style={{ pointerEvents: 'none' }} />
            ) : (
              <Icons.CaretDown style={{ pointerEvents: 'none' }} />
            )}
          </Stack>
        </LinkBehaviour>
      )}
    </Box>
  );
};
