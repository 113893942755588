import {
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';

import { Stack, StackProps, styled, Typography } from '@mui/material';
import { Virtuoso } from 'react-virtuoso';

import { Button } from '../../buttons';
import { Checkbox, useDropdownWithAutocomplete } from '../../controls';
import { ListItem, ListItemProps } from '../../lists';
import { VirtuosoScroller } from '../../scroller';
import { Criterion } from '../types';

type OwnerState = {
  allowMultiple?: boolean;
};

const Root = styled('div', {
  name: 'DsSearch',
  slot: 'Options',
  target: 'DsSearch-Options',
  overridesResolver: (_, styles) => {
    return [styles.options];
  },
})<HTMLAttributes<HTMLDivElement> & { ownerState: OwnerState }>``;

const Controls = styled(Stack, {
  name: 'DsSearch',
  slot: 'OptionsControls',
  target: 'DsSearch-OptionsControls',
  overridesResolver: (_, styles) => {
    return [styles.optionsControls];
  },
})<StackProps>``;

const StyledListItem = styled(ListItem, {
  name: 'DsSearch',
  slot: 'OptionsListItem',
  target: 'DsSearch-OptionsListItem',
  overridesResolver: (_, styles) => {
    return [styles.optionsListItem];
  },
})<ListItemProps & { ownerState: OwnerState }>``;

type Actions = {
  onClearAll: MouseEventHandler;
  onApply: MouseEventHandler;
};

export type OptionsListProps<OptionItem, OptionValue> = {
  options: OptionItem[];
  renderOption?: (
    option: OptionItem,
    index: number
  ) => string | number | ReactNode;
  getOptionProps: ReturnType<
    typeof useDropdownWithAutocomplete<OptionItem, OptionValue>
  >['getters']['getOptionProps'];
  activeCriterion?: Criterion; // SearchBoxCriterion
  selectedOptions: Record<string, boolean>;
  toggleOption: (key: string) => void;
  listHeight: number;
  showOptions: boolean;
} & Actions;

export const Options = <
  OptionItem extends unknown,
  OptionValue extends unknown
>({
  options,
  getOptionProps,
  activeCriterion,
  selectedOptions,
  toggleOption,
  listHeight: _listHeight,
  onApply,
  onClearAll,
  showOptions: _showOptions,
}: OptionsListProps<OptionItem, OptionValue>) => {
  const allowMultiple = activeCriterion?.allowMultiple;
  const renderOption = activeCriterion?.renderOption;
  const title = activeCriterion?.title || activeCriterion?.key;

  const controlsRef = useRef<HTMLDivElement | undefined>();
  const listHeight =
    _listHeight - (controlsRef.current?.getBoundingClientRect().height || 0);
  const [height, setHeight] = useState<number>(listHeight);
  const handleListHeight = useCallback((h: number) => {
    setHeight(h);
  }, []);

  const showOptions = _showOptions && !!options.length;

  return (
    <Root data-testid="search-options-list" ownerState={{ allowMultiple }}>
      <Virtuoso
        totalListHeightChanged={handleListHeight}
        data={options}
        style={{
          // We have to give 1px height, otherwise Virtuoso will not be rendered
          height: showOptions ? height || listHeight : 1,
          maxHeight: listHeight,
        }}
        components={{
          Scroller: VirtuosoScroller,
        }}
        itemContent={(index, option) => {
          const { onClick, ...optionProps } = getOptionProps({
            option,
            index,
          });
          const optionKey = optionProps.key;
          return (
            <StyledListItem
              key={
                optionKey +
                `${selectedOptions[optionKey] ? 'selected' : undefined}`
              }
              component="li"
              testId={`search-option-${index}`}
              onMouseDown={(e) => {
                // Prevent the dropdown from closing (input blur is discarded)
                e.preventDefault();
                e.stopPropagation();
              }}
              onClick={(e) => {
                // Prevent the dropdown from closing (input blur is discarded)
                e.stopPropagation();
                e.preventDefault();

                if (allowMultiple) {
                  toggleOption(optionKey);
                } else {
                  onClick(e);
                }
              }}
              ownerState={{ allowMultiple }}
            >
              {allowMultiple && (
                <Checkbox checked={selectedOptions[optionKey]} />
              )}
              {renderOption ? (
                renderOption(option, index)
              ) : (
                <Stack direction="row" flexWrap="wrap">
                  {!allowMultiple && (
                    <Typography
                      component="span"
                      pr="10px"
                      color="grey.900"
                      variant="P14M"
                      noWrap
                    >
                      {title}
                    </Typography>
                  )}
                  <Typography variant="P14R" color="grey.900" noWrap>
                    {option as string}
                  </Typography>
                </Stack>
              )}
            </StyledListItem>
          );
        }}
        overscan={listHeight}
      />
      {allowMultiple && (
        <Controls
          direction="row"
          justifyContent="flex-end"
          py="4px"
          px="12px"
          gap="12px"
          ref={controlsRef as any}
        >
          <Button
            size="small"
            variant="ghost"
            onClick={onClearAll}
            onMouseDown={(e: MouseEvent) => {
              // Prevent the dropdown from closing (input blur is discarded)
              e.stopPropagation();
              e.preventDefault();
            }}
            data-testid="search-clear-selection"
          >
            Clear all
          </Button>
          <Button
            size="small"
            variant="primary"
            onClick={onApply}
            onMouseDown={(e: MouseEvent) => {
              // Prevent the dropdown from closing (input blur is discarded)
              e.stopPropagation();
              e.preventDefault();
            }}
            data-testid="search-apply-selection"
          >
            Apply
          </Button>
        </Controls>
      )}
    </Root>
  );
};
