import {
  ComponentType,
  forwardRef,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { PopoverProps } from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';

import { DropdownInput } from './components/DropdownInput';
import { DropdownPopper } from './components/DropdownPopper';
import { DropdownRoot } from './components/DropdownRoot';
import { DropdownContext } from './DropdownContext';
import { DropdownProps, isSingleSelection } from './types';
import { useDropdownWithAutocomplete } from './useDropdownWithAutocomplete';
import { ListContext } from '../../lists/List';

export type SelectProps<
  OptionItem = any,
  OptionValue = any,
  SelectionType = unknown
> = DropdownProps<OptionItem, OptionValue, SelectionType> & {
  PopoverComponent?: ComponentType<PopoverProps>;
};

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  ({ PopoverComponent, ...dropdownProps }, ref) => {
    const popupState = usePopupState({
      variant: 'popover',
      popupId: 'dropdown',
    });
    const [inputValue, setInputValue] = useState('');
    const autocomplete = useDropdownWithAutocomplete({
      dropdownProps: {
        ...dropdownProps,
        options: dropdownProps.options || [],
      },
      autocompleteOverrides: {
        onChange: (option: any) => {
          if (isSingleSelection(dropdownProps)) {
            if (option) {
              const valueResult =
                autocomplete.getters.getOptionSelectedValue(option);
              // Check if not a ReactNode
              setInputValue(typeof valueResult === 'string' ? valueResult : '');
            } else {
              setInputValue('');
            }

            dropdownProps.onChange?.(
              option,
              autocomplete.getters.getOptionValue(option)
            );
          }
        },
        open: popupState.isOpen,
        hideSearch: true,
      },
    });

    useEffect(() => {
      if (isSingleSelection(dropdownProps)) {
        const valueResult = autocomplete.states.selectedOption
          ? autocomplete.getters.getOptionSelectedValue(
              autocomplete.states.selectedOption
            )
          : '';
        // Check if not a ReactNode
        setInputValue(typeof valueResult === 'string' ? valueResult : '');
      }
    }, [
      dropdownProps,
      autocomplete.getters,
      autocomplete.states.selectedOption,
    ]);

    return (
      <DropdownContext.Provider
        value={{
          popupState,
          dropdownProps,
          autocomplete,
        }}
      >
        <ListContext.Provider value={{ size: dropdownProps.size }}>
          <DropdownRoot>
            <DropdownInput
              inputProps={{
                value: inputValue,
              }}
              ref={ref}
            />
          </DropdownRoot>

          <DropdownPopper PopoverComponent={PopoverComponent} />
        </ListContext.Provider>
      </DropdownContext.Provider>
    );
  }
) as (<OptionItem = any, OptionValue = any, SelectionType = unknown>(
  props: SelectProps<OptionItem, OptionValue, SelectionType>
) => ReactElement) & {
  displayName: string;
};

Select.displayName = 'Select';
