import { PropsWithChildren, useMemo } from 'react';

import { Typography } from '@mui/material';

import { X } from '../../../icons';
import {
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  ListSubheader,
} from '../../../lists';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import { PeriodComparisonPickerProviderProps } from '../../types';
import { periodToString } from '../../utils';

const PresetListItem = ({
  children,
  selected,
  ...rest
}: PropsWithChildren<ListItemProps>) => {
  const ctx = useDatePickerContext<PeriodComparisonPickerProviderProps>();

  return (
    <ListItem
      sx={{
        borderRadius: '4px',
        padding: '7px 8px',
      }}
      component="li"
      endAdornment={
        selected ? (
          <X
            onClick={() => {
              ctx.setActivePeriod(undefined);
              ctx.clearRanges();
            }}
            size={16}
            data-testid="remove-preset-button"
          />
        ) : undefined
      }
      selected={selected}
      {...rest}
    >
      {children}
    </ListItem>
  );
};

export const PeriodPresetsList = () => {
  const ctx = useDatePickerContext<PeriodComparisonPickerProviderProps>();

  const isCustom = useMemo(() => {
    return (
      !!ctx.activePeriod &&
      !ctx.periodPresets.some((p) => p.periodInMins === ctx.activePeriod)
    );
  }, [ctx.activePeriod, ctx.periodPresets]);

  return (
    <List size="small" disablePadding testId="period-picker-presets">
      <ListSubheader
        sx={{ alignItems: 'center', padding: 8, color: 'grey.900' }}
      >
        <Typography variant="P12M">Date Range:</Typography>
      </ListSubheader>

      {ctx.periodPresets?.map((preset) => {
        const isSelected = preset.periodInMins === ctx.activePeriod;
        return (
          <PresetListItem
            key={preset.title}
            selected={isSelected}
            onClick={() => {
              if (isSelected) {
                ctx.setActivePeriod(undefined);
              } else {
                ctx.setActivePeriod(preset.periodInMins);
              }
              ctx.clearRanges();
            }}
            testId={'period-preset-' + preset.title}
          >
            <ListItemText
              primary={preset.title}
              primaryTypographyProps={{ variant: 'P12R' }}
            />
          </PresetListItem>
        );
      })}
      {ctx.activePeriod && isCustom && (
        <PresetListItem selected testId="custom-period-preset">
          <ListItemText
            primary={`Custom: ${periodToString(ctx.activePeriod)}`}
            primaryTypographyProps={{ variant: 'P12R' }}
          />
        </PresetListItem>
      )}
    </List>
  );
};
