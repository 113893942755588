import { useEffect, useMemo, useRef, useState } from 'react';

import { Stack, Typography, useTheme } from '@mui/material';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import { useMatch } from 'react-router-dom';

import { Button, DrawerProvider, Icons, useDrawer } from '@cast/design-system';
import { Notification, NotificationSeverity } from '@cast/types';
import { usePrevious } from '@cast/utils';

import { CloseableAlert } from 'components/alerts/CloseableAlert';
import { isAlertDismissed } from 'components/alerts/CloseableAlert/utils';
import { notificationsEvents } from 'core/analytics';
import { useNotificationDrawer } from 'features/notifications/hooks/useNotificationDrawer';
import { useGetNotificationsQuery } from 'hooks/queries/notifications';
import { useOrganizations } from 'hooks/useOrganizations';

const generateNotificationId = (id?: string) => `critical-notification-${id}`;

export const CriticalNotificationListInner = () => {
  const { palette } = useTheme();
  const { openNotificationDrawer } = useNotificationDrawer();
  const { drawers } = useDrawer();
  const [isExpanded, setExpanded] = useState(false);
  const [expansionTransitionEnded, setExpansionTransitionEnded] =
    useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const isNotificationDrawerOpen = useMemo(() => {
    return !!Object.values(drawers).find((drawer) => {
      return !!drawer.props?.notification;
    });
  }, [drawers]);

  const { currentOrganization } = useOrganizations();
  const {
    notificationsResponse: { items },
  } = useGetNotificationsQuery({
    filter: {
      onlyUnresolved: true,
      isExpired: false,
      severities: [NotificationSeverity.CRITICAL],
    },
  });
  const prevItems = usePrevious(items);
  const [visibleNotifications, setVisibleNotifications] = useState<
    Notification[]
  >([]);
  const notificationTotal = visibleNotifications.length;

  useEffect(() => {
    if (currentOrganization && notificationTotal > 0) {
      notificationsEvents.viewedCriticalNotifications(currentOrganization.name);
    }
  }, [currentOrganization, notificationTotal]);

  useEffect(() => {
    if (!isEqual(items, prevItems)) {
      setVisibleNotifications(
        items.filter(
          (item) => !isAlertDismissed(generateNotificationId(item.id))
        )
      );
    }
  }, [items, prevItems]);

  useEffect(() => {
    const controller = new AbortController();

    if (
      elementRef.current &&
      isExpanded &&
      expansionTransitionEnded &&
      !isNotificationDrawerOpen
    ) {
      const expandedHeight = elementRef.current.scrollHeight;
      const { top, left, right } = elementRef.current.getBoundingClientRect();

      window.addEventListener(
        'mousemove',
        throttle((e: MouseEvent) => {
          const isOutsideHorizontalBounds =
            e.clientX < left || e.clientX > right;
          const isOutsideVerticalBounds =
            e.clientY < top || e.clientY > top + expandedHeight;

          if (isOutsideHorizontalBounds || isOutsideVerticalBounds) {
            setExpanded(false);
            setExpansionTransitionEnded(false);
            controller.abort();
          }
        }, 350),
        {
          signal: controller.signal,
        }
      );
    }

    return () => {
      controller.abort();
    };
  }, [isExpanded, expansionTransitionEnded, isNotificationDrawerOpen]);

  const handleNotificationDismiss = (notification: Notification) => {
    notificationsEvents.dismissedCriticalNotification(
      currentOrganization!.name,
      notification.name!
    );

    setVisibleNotifications(
      visibleNotifications.filter((item) => item.id !== notification.id)
    );
  };

  const handleNotificationClick = (notification: Notification) => {
    notificationsEvents.clickedCriticalNotification(
      currentOrganization!.name,
      notification.name!
    );

    openNotificationDrawer({
      notification,
      openedFrom: 'critical notifications list',
    });
  };

  if (!notificationTotal) {
    return null;
  }

  return (
    <Stack
      mt={-10}
      height={95}
      gap={4}
      sx={{
        isolation: 'isolate',
        zIndex: 100,
        position: 'relative',
        overflow: 'visible',
      }}
      onTransitionEnd={() => {
        if (isExpanded) {
          setExpansionTransitionEnded(true);
        }
      }}
      ref={elementRef}
      data-testid="critical-notification-list"
    >
      {notificationTotal > 1 && (
        <Typography
          variant="P12B"
          color="grey.600"
          sx={{
            position: 'absolute',
            top: -20,
          }}
        >
          {notificationTotal} Critical alerts
        </Typography>
      )}
      {visibleNotifications.map((notification, index) => (
        <CloseableAlert
          closeable={generateNotificationId(notification.id)}
          key={notification.id}
          data-testid={`critical-notification-item-${notification.id}`}
          color="advisory"
          size="small"
          icon={<Icons.Warning color={palette.red[500]} size={20} />}
          onMouseEnter={() => {
            // Trigger expansion when the first item is hovered
            // if there are multiple notifications
            if (notificationTotal > 1 && index === 0 && !isExpanded) {
              setExpanded(true);
            }
          }}
          onClose={(e: React.SyntheticEvent) => {
            e.stopPropagation();
            handleNotificationDismiss(notification);
          }}
          onClick={
            index === 0 || isExpanded
              ? () => handleNotificationClick(notification)
              : undefined
          }
          sx={{
            cursor: index === 0 || isExpanded ? 'pointer' : 'initial',
            '& .MuiAlert-message': { width: '100%' },
            '& .DSuiAlert-content': { display: 'block' },
            width: '100%',
            alignSelf: 'center',
            transition: 'all 0.2s linear',
            position: 'relative',
            zIndex: notificationTotal - index,
            // Add a shadow to the last expanded notification
            boxShadow:
              isExpanded && index === notificationTotal - 1
                ? '0px 11px 14px 0px rgba(0, 0, 0, 0.10)'
                : undefined,

            ...(!isExpanded && {
              transform: `translateY(-${index * 61}px)`,
              width: `calc(100% - ${index * 16}px)`,
              // Only display the first three notifications in the stack
              opacity: index > 2 ? 0 : 1 - index * 0.2,
              pointerEvents: index > 2 ? 'none' : 'auto',
            }),
          }}
        >
          <Typography variant="P14B">{notification.name}</Typography>
          <Stack
            direction="row"
            gap={4}
            flexWrap={isExpanded ? 'wrap' : 'nowrap'}
          >
            <Typography
              variant="P12R"
              color="grey.900"
              sx={
                // Limit the collapsed height of the notification
                !isExpanded
                  ? {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }
                  : undefined
              }
            >
              {notification.message}
            </Typography>
            <Button variant="text" size="medium">
              Learn more
            </Button>
          </Stack>
        </CloseableAlert>
      ))}
    </Stack>
  );
};

export const CriticalNotificationList = () => {
  const isInNotificationsPage = !!useMatch('/notifications');

  if (isInNotificationsPage) {
    return null;
  }

  return (
    <DrawerProvider>
      <CriticalNotificationListInner />
    </DrawerProvider>
  );
};
