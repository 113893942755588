import { Typography, Divider } from '@mui/material';
import { SxProps } from '@mui/system';

import { HexIconNotification, HexIconCheck } from 'components/hex-icons';
import { StatusMessage } from 'components/messages';

type Props = {
  allResolved?: boolean;
  sx?: SxProps;
};

export const NoNotifications = ({ allResolved, sx }: Props) => {
  return (
    <>
      <Divider />
      <StatusMessage
        icon={
          allResolved ? (
            <HexIconCheck size={48} />
          ) : (
            <HexIconNotification size={48} />
          )
        }
        title={
          allResolved
            ? 'All notifications are resolved'
            : 'No notifications yet'
        }
        body={
          <Typography variant="P10R" textAlign="center">
            {allResolved
              ? 'New notifications you receive will show up here.'
              : 'All notifications you receive will show up here.'}
          </Typography>
        }
        sx={sx}
      />
    </>
  );
};
