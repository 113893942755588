import { AxiosInstance } from 'axios';

import { NodeConfigurationAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const NodeConfigTemplatesApiAdapter = (client: AxiosInstance) => {
  const generatedApi = NodeConfigurationAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  return {
    createNodeConfigTemplate:
      generatedApi.nodeConfigurationAPICreateConfiguration,
    deleteNodeConfigTemplate:
      generatedApi.nodeConfigurationAPIDeleteConfiguration,
    getNodeConfigTemplate: generatedApi.nodeConfigurationAPIGetConfiguration,
    listNodeConfigTemplates:
      generatedApi.nodeConfigurationAPIListConfigurations,
    updateNodeConfigTemplate:
      generatedApi.nodeConfigurationAPIUpdateConfiguration,
    setDefaultNodeConfigTemplate: generatedApi.nodeConfigurationAPISetDefault,
    getNodeConfigSuggestions:
      generatedApi.nodeConfigurationAPIGetSuggestedConfiguration,
    getMaxPodsFormulaPresets:
      generatedApi.nodeConfigurationAPIListMaxPodsPresets,
  };
};
