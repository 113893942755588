import { ThemeOptions } from '@mui/material';

export const fileDropTheme: ThemeOptions['components'] = {
  DsFileDrop: {
    styleOverrides: {
      root: ({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        background: 'none',
        border: `1px dashed ${theme.palette.grey[300]}`,
        borderRadius: '2px',
      }),
      placeholder: ({ theme, ownerState }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        ...theme.typography.P12R,
        color: ownerState.active
          ? theme.palette.blue[500]
          : theme.palette.grey[400],
      }),

      // Modifiers
      active: ({ theme }) => ({
        background: theme.palette.blue[50],
        borderColor: theme.palette.blue[200],
      }),
    },
  },
};
