import { useEffect, useState } from 'react';

import {
  DEMO_CLUSTER_ID,
  isDemoMode,
  removeDemoMode,
  setDemoMode,
} from '@cast/utils';

import { useClustersQuery } from 'hooks/queries/cluster';
import { useOrganizations } from 'hooks/useOrganizations';

type UseDetermineDemoModeArgs = {
  enabled: boolean;
};

export const useDetermineDemoMode = ({ enabled }: UseDetermineDemoModeArgs) => {
  const { currentOrganization: organization } = useOrganizations();
  const [isDemoDeterminationProcessDone, setDemoDeterminationStatus] =
    useState(false);

  const { clusters } = useClustersQuery({
    polling: false,
    enabled,
  });

  useEffect(() => {
    if (
      enabled &&
      !isDemoDeterminationProcessDone &&
      clusters &&
      organization
    ) {
      const orgHasOnlyDemoCluster =
        clusters.length === 1 && clusters[0].id === DEMO_CLUSTER_ID;

      if (isDemoMode()) {
        if (!orgHasOnlyDemoCluster) {
          removeDemoMode();
        }
      } else {
        if (orgHasOnlyDemoCluster) {
          setDemoMode(organization?.id);
        }
      }

      if (clusters.length > 0) {
        setDemoDeterminationStatus(true);
      }
    }
  }, [clusters, enabled, isDemoDeterminationProcessDone, organization]);

  return {
    isDemoDeterminationProcessDone,
  };
};
