import { Attributes, ComponentType, createElement } from 'react';

// eslint-disable-next-line
export const mergeProps = (defaultProps: any = {}, props: any = {}) => {
  const result = { ...defaultProps };
  Object.entries(props).forEach(([key, value]) => {
    if (key in result) {
      if (typeof result[key] === 'object' && typeof value === 'object') {
        result[key] = { ...result[key], ...value };
      } else if (Array.isArray(result[key]) && Array.isArray(value)) {
        result[key] = result[key].concat(value);
      } else {
        result[key] = value;
      }
    } else {
      result[key] = value;
    }
  });
  return result;
};

export function withProps<
  // eslint-disable-next-line
  P extends {}
>(
  component: ComponentType<P>,
  defaultProps: Attributes & Partial<P>,
  deepMergeProps?: boolean
): ComponentType<
  Attributes & Omit<P, keyof typeof defaultProps> & Partial<typeof defaultProps>
> {
  // eslint-disable-next-line
  return (props: any) =>
    createElement(
      component,
      deepMergeProps
        ? mergeProps(defaultProps, props)
        : { ...defaultProps, ...props }
    );
}
