import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { DsShadow } from './shadows';

export const focusProps = (theme: Theme): SystemStyleObject<Theme> => ({
  outline: `2px solid ${theme.palette.indigo[300]}`,
  boxShadow: theme.shadows[DsShadow.FOCUS_GLOW],
});

export const activeProps = (theme: Theme): SystemStyleObject<Theme> => ({
  border: `1px solid ${theme.palette.blue[500]}`,
  boxShadow: theme.shadows[DsShadow.ACTIVE_GLOW],
});

export const AbsolutePseudo: SystemStyleObject<Theme> = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};
