import { useCallback } from 'react';

import { Cluster } from '@cast/types';
import { isManagedByTerraform } from '@cast/utils';

import { useDeleteClusterDialog } from './dialogs';
import { useDeleteClusterMutation } from './mutations';

export const useDeleteCluster = (cluster: Cluster) => {
  const { openDeleteClusterDialog } = useDeleteClusterDialog();
  const { mutate } = useDeleteClusterMutation(cluster);
  const isTerraformManaged = isManagedByTerraform(cluster.managedBy);

  const deleteCluster = useCallback(() => {
    if (isTerraformManaged) {
      mutate();
    } else {
      openDeleteClusterDialog(cluster);
    }
  }, [isTerraformManaged, mutate, cluster, openDeleteClusterDialog]);

  return {
    deleteCluster,
  };
};
