import { useMemo } from 'react';

import orderBy from 'lodash/orderBy';

import { Cluster } from '@cast/types';

import {
  useClustersQuery,
  UseClustersQueryArgs,
} from 'hooks/queries/cluster/useClustersQuery';

type UseClustersHook = {
  isLoading: boolean;
  hasError: boolean;
  error?: unknown;
  clusters: Cluster[];
  refetchClusters: () => void;
};

export const useClusters = (args?: UseClustersQueryArgs): UseClustersHook => {
  const { isLoading, clusters, error, refetchClusters } =
    useClustersQuery(args);

  const _clusters = useMemo(() => {
    return orderBy(clusters, ['createdAt'], ['desc']);
  }, [clusters]);

  return {
    isLoading,
    error,
    hasError: Boolean(error),
    clusters: _clusters,
    refetchClusters,
  };
};
