export type Currency = string;

export enum PriceType {
  HOURLY = 'hourly',
  DAILY = 'daily',
  MONTHLY = 'monthly',
}

export enum ConfigType {
  CURRENT = 'current',
  OPTIMAL = 'optimal',
}
