/** A module. Its name is module:cast/nodes.
 * @module cast/nodes
 */

import type {
  ExternalclusterV1Node as _NodeResponse,
  CastaiAutoscalerV1beta1GetProblematicNodesResponseNode as NodeProblematic,
} from '@cast/openapi';
import { ExternalclusterV1NodeType as NodeRoles } from '@cast/openapi';

import type { NodePricing } from './pricing';
import {
  WorkloadMigrationStatusMigrationStatusEnum,
  NodeMigrationStatusMigrationStatusEnum,
} from './rebalance';

export enum NodeStatus {
  unknown = 'unknown',
  pending = 'pending',
  creating = 'creating',
  ready = 'ready',
  notReady = 'notReady',
  draining = 'draining',
  deleting = 'deleting',
  deleted = 'deleted',
  interrupted = 'interrupted',
}

export enum ComputedNodeStatus {
  CORDONED = 'cordoned',
}

export enum NodeResourceOffering {
  ON_DEMAND = 'on-demand',
  FALLBACK = 'fallback',
  SPOT = 'spot',
}

export type NodeIssue = 'failedToDrain';

export type Node = Omit<NodeResponse, 'state'> & {
  state: {
    phase?: NodeStatus | ComputedNodeStatus;
  };
  resourceOffering: NodeResourceOffering;
  unremovable: boolean;
  regionLabel?: string;
  castNodeId?: string;
  issues: NodeIssue[];
  pricingInfo?: NodePricing;
  nodeIssues?: NodeProblematic['problems'];
};

export type WorkloadBaseRunningInNode = {
  name: string;
  namespace: string;
  workloadType: string;
  nodeId: string;
  nodeName: string;
  replicasPerNode: number;
  resourcesPerNode: {
    requested: {
      milliCpu: number;
      memoryBytes: number;
    };
  };
  migrationStatus: WorkloadMigrationStatusMigrationStatusEnum;
};

export type NodeWithWorkloads = Node & {
  workloads: WorkloadBaseRunningInNode[];
  migrationStatus: NodeMigrationStatusMigrationStatusEnum;
};

export type NodeResponse = Omit<_NodeResponse, 'instancePrice'> & {
  /** @deprecated Use `pricingInfo.totalPrice` instead */
  instancePrice: _NodeResponse['instancePrice'];
};

export type { ExternalclusterV1Taint as NodeTaint } from '@cast/openapi';
export { NodeRoles, NodeProblematic };
