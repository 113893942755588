import { BarProps, CartesianGrid } from 'recharts';

import { ChartConfig } from 'types/charts';

import { DEFAULT_BAR_SIZE } from '../constants';

export function calculateBarSize(grid: CartesianGrid): number {
  const { left = 0, right = 0 } = grid?.props.xAxis?.padding as {
    left?: number | undefined;
    right?: number | undefined;
  };
  const sumOfPaddings = left + right;
  const chartWidth = grid?.props.width ? grid?.props.width - sumOfPaddings : 0;
  const dataPoints = grid?.props.xAxis?.ticks?.length;
  if (dataPoints) {
    return Math.min(chartWidth / dataPoints / 2, DEFAULT_BAR_SIZE);
  } else {
    return 0;
  }
}

export function isBarChartProps(
  props: ChartConfig['config']
): props is BarProps {
  return (props as BarProps).barSize !== undefined;
}
