import { ElementType } from 'react';

import { FormHelperTextProps, styled } from '@mui/material';
import clsx from 'clsx';

type Props = FormHelperTextProps<
  'p',
  {
    component?: ElementType;
    textWrapper?: ElementType;
    hintAbsolute?: boolean;
  }
>;

const HelperText = styled('div', {
  name: 'MuiFormHelperText',
  slot: 'HelperText',
  overridesResolver: ({ hintAbsolute }, styles) => {
    return [styles.text, hintAbsolute && styles.textAbsolute];
  },
  target: 'DS-FormHelperText-text',
  shouldForwardProp: (prop) => prop !== 'hintAbsolute',
})<{ hintAbsolute?: any }>({});

const DsFormHelperText = styled(
  ({
    component,
    children,
    hintAbsolute,
    error,
    disabled,
    className,
    ...props
  }: Props) => {
    const Root = component || 'div';
    return (
      <Root
        {...props}
        className={clsx(
          className,
          error && 'DS-error',
          disabled && 'DS-disabled'
        )}
        data-testid="helper-text"
      >
        <HelperText hintAbsolute={hintAbsolute}>{children}</HelperText>
      </Root>
    );
  },
  {
    name: 'MuiFormHelperText',
    slot: 'Root',
    skipVariantsResolver: true,
    overridesResolver: (_, styles) => {
      return [styles.root];
    },
    target: 'DS-FormHelperText-root',
  }
)({});

export default DsFormHelperText;
