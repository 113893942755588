import { Box, Stack, styled, Typography } from '@mui/material';

import { Tooltip } from '@cast/design-system';
import { ImageStatus } from '@cast/types';

type Props = { status: ImageStatus; variant?: 'minimal' | 'regular' };

type ImageUsageIndicatorBubbleProps = { status: ImageStatus };

const ImageUsageIndicatorBubble = styled(Box, {
  target: 'ImageUsageIndicatorBubble-root',
  shouldForwardProp: (prop) => prop !== 'status' && prop !== 'variant',
})<ImageUsageIndicatorBubbleProps>(({ status, theme }) => ({
  width: 8,
  height: 8,
  backgroundColor:
    status === ImageStatus.Running
      ? theme.palette.green[400]
      : theme.palette.grey[300],
  borderRadius: '4px',
}));

export const ImageUsageIndicator = ({ status, variant = 'regular' }: Props) => {
  if (variant === 'minimal') {
    return (
      <Tooltip
        title={status === ImageStatus.Running ? 'Running' : 'Not running'}
        placement="top"
        arrow
      >
        <div data-testid="image-usage-indicator-tooltip">
          <ImageUsageIndicatorBubble status={status} />
        </div>
      </Tooltip>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={4}>
      <ImageUsageIndicatorBubble status={status} />
      <Typography variant="P10M">
        {status === ImageStatus.Running ? 'Running' : 'Not running'}
      </Typography>
    </Stack>
  );
};
