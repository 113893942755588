import { useMemo } from 'react';

import { useAvailableSavingsQuery } from 'hooks/queries/available-savings';
import { useCluster } from 'hooks/useCluster';

import { partitionWorkloads } from '../utils';

export const useAvailableSavingsPartitionedWorkloads = () => {
  const { cluster } = useCluster();
  const { isLoading, data, error } = useAvailableSavingsQuery(cluster.id);
  const partitionedWorkloads = useMemo(
    () =>
      data
        ? partitionWorkloads(data)
        : { workloads: [], spotWorkloads: [], spotOnlyWorkloads: [] },
    [data]
  );
  return { isLoading, error, ...partitionedWorkloads };
};
