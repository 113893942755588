import { Fragment, ReactElement, useEffect, useRef, useState } from 'react';

import { Box, BoxProps } from '@mui/material';
import random from 'lodash/random';

import { Skeleton } from '@cast/design-system';
import { useTriggerRepaint } from '@cast/utils';

import { useWindowResize } from 'hooks/useWindowResize';

const barSpacing = 28;
const barWidth = 24;

const RandomBar = () => {
  const height = useRef(random(50, 99));
  return (
    <Skeleton
      variant="rectangular"
      sx={{
        height: `${height.current}%`,
        width: barWidth,
        borderRadius: '2px',
      }}
    />
  );
};

type Props = { height: number } & Omit<BoxProps, 'height'>;
export const BarSkeletons = ({ height, ...props }: Props) => {
  const repaint = useTriggerRepaint();
  const [container, setContainer] = useState<HTMLElement | undefined>();
  const width = container?.offsetWidth;
  const bars = useRef<ReactElement[]>([]);
  const requiredBars = Math.floor((width || 0) / (barSpacing + barWidth)) || 0;
  useWindowResize();
  useEffect(() => {
    if (bars.current.length < requiredBars) {
      bars.current.push(
        ...Array(requiredBars - bars.current.length).fill(<RandomBar />)
      );
    }
    repaint();
  }, [repaint, requiredBars]);

  return (
    <Box
      ref={(ref: HTMLElement) => setContainer(ref || undefined)}
      minWidth="100%"
      minHeight={height}
      width="100%"
      height={height}
      display="inline-flex"
      justifyContent="space-around"
      alignItems="flex-end"
      position="relative"
      bgcolor="common.white"
      py="18px"
      {...props}
    >
      {[...Array(requiredBars)].map((_, index) => {
        const bar = bars.current[index];
        if (!bar) {
          return null;
        }
        return <Fragment key={index}>{bar}</Fragment>;
      })}
    </Box>
  );
};
