import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import {
  SecurityInsightsCreateTicketRequest,
  SecurityInsightsTicketItemType,
} from '@cast/types';

import { apiClient, QueryKeyFactory, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export type CreateTicketAdditionalData = {
  imageId?: string;
  packageVulnId?: string;
};

export const useCreateTicketMutation = (
  onSuccess?: () => void
): UseMutationResult<
  unknown,
  unknown,
  {
    id: string;
    body: SecurityInsightsCreateTicketRequest;
    additionalData?: CreateTicketAdditionalData;
  },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, body }) => {
      return apiClient.securityInsights.createTicket({ id, body });
    },
    onSuccess: (_, variables) => {
      const id = variables.body.typeIds[0];
      switch (variables.body.type) {
        case SecurityInsightsTicketItemType.Vulnerability: {
          queryClient.invalidateQueries({
            queryKey: [
              QueryKeys.SECURITY_IMAGE_PACKAGE_VULNERABILITIES,
              variables.additionalData?.imageId,
              id,
            ],
          });
          break;
        }
        case SecurityInsightsTicketItemType.AttackPath: {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.SECURITY_ATTACK_PATH_DETAILS, id],
          });
          break;
        }
        case SecurityInsightsTicketItemType.Check: {
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey?.[0] === QueryKeys.SECURITY_BEST_PRACTICE_CHECK &&
              query.queryKey?.[1] === id,
          });
          break;
        }
        case SecurityInsightsTicketItemType.RuntimeAnomaly: {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.SECURITY_ANOMALY, id],
          });
          break;
        }
        case SecurityInsightsTicketItemType.Package: {
          queryClient.invalidateQueries({
            queryKey: [
              QueryKeys.SECURITY_IMAGE_PACKAGE_VULNERABILITIES,
              variables.additionalData?.imageId,
              variables.additionalData?.packageVulnId,
            ],
          });
          break;
        }
        case SecurityInsightsTicketItemType.Image: {
          queryClient.invalidateQueries({
            queryKey: QueryKeyFactory.securityImageDetails({
              tagId: id,
            }),
          });
          break;
        }
      }
      onSuccess?.();
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
