import * as yup from 'yup';

export const AWSCredentialsSchema = yup.object({
  credentials: yup
    .object()
    .shape({
      accessKeyId: yup
        .string()
        .required('Please set a Access key ID for the cloud credential.'),
      secretAccessKey: yup
        .string()
        .required('Please set a Secret access key for the cloud credential.'),
    })
    .required(),
});
