import { useContext } from 'react';

import clsx from 'clsx';
import { CaretDown, CaretUp } from 'phosphor-react';

import { DropdownContext } from '../DropdownContext';

const ArrowIcon = () => {
  const {
    popupState,
    dropdownProps: { disabled },
  } = useContext(DropdownContext);

  if (popupState.isOpen) {
    return <CaretUp className="DS-Dropdown-arrow DS-Dropdown-arrow-up" />;
  }
  return (
    <CaretDown
      className={clsx(
        'DS-Dropdown-arrow DS-Dropdown-arrow-down',
        disabled && 'Mui-disabled'
      )}
    />
  );
};

export const EndAdornment = () => {
  const {
    dropdownProps: { endAdornment },
    autocomplete: {
      states: { selectedOption },
    },
  } = useContext(DropdownContext);
  if (!endAdornment) {
    return <ArrowIcon />;
  }
  return (
    <div className="DS-Dropdown-endAdornmentWrapper">
      {typeof endAdornment === 'function'
        ? endAdornment(selectedOption)
        : endAdornment}
      <ArrowIcon />
    </div>
  );
};
