import { forwardRef, Key } from 'react';

import Typography, { TypographyProps } from '@mui/material/Typography';

import { Chip as DsChip, ChipProps as DsChipProps } from '../../chip';

export type InputChipProps = {
  title: string;
  value?: string;
  delimiter?: string;
  key?: Key;
  titleProps?: TypographyProps;
  valueProps?: TypographyProps;
} & Omit<DsChipProps, 'children'>;

const InputChip = forwardRef<HTMLDivElement, InputChipProps>(
  ({ title, value, delimiter = ':', titleProps, valueProps, ...rest }, ref) => {
    if (value) {
      return (
        <DsChip {...rest} ref={ref}>
          <Typography data-testid="chip-title" {...titleProps}>
            {title}
            {delimiter}{' '}
          </Typography>
          <Typography
            className="DsChip-value"
            data-testid="chip-value"
            {...valueProps}
          >
            {value.toString().replace('\n', ', ')}
          </Typography>
        </DsChip>
      );
    }
    return (
      <DsChip {...rest} ref={ref}>
        {title}
      </DsChip>
    );
  }
);

InputChip.displayName = 'InputChip';

export default InputChip;
