import { ChangeEvent, ReactNode, useMemo } from 'react';

import clsx from 'clsx';
import uniq from 'lodash/uniq';

import { Checkbox, CheckboxProps } from '../../../controls/checkbox';
import { Column } from '../../Column';
import { GetRowKey, RowState } from '../../types';
import { DataTableProps } from '../types';

type UseSelectableRows = {
  getRowClass: DataTableProps['getRowClass'];
  checkboxColumn: ReactNode;
};
export const useSelectableRows = ({
  onRowsSelected,
  selectedRows,
  isRowSelectable,
  rowKey,
  getRowClass,
  data,
  checkboxColumnProps: {
    disabled: _checkboxColumnDisabled,
    getTestId,
    renderHeaderCheckbox,
    renderCellCheckbox,
    ...checkboxColumnProps
  } = {},
}: DataTableProps): UseSelectableRows => {
  const selectable = !!onRowsSelected;

  const checkboxColumnDisabled = useMemo(() => {
    if (!isRowSelectable || !data?.length || !selectable) {
      return _checkboxColumnDisabled;
    }
    return !data.some((row) => isRowSelectable(row));
  }, [data, isRowSelectable, _checkboxColumnDisabled, selectable]);

  if (!selectable) {
    return { getRowClass, checkboxColumn: null };
  }

  const handleCheck = (rowKey: any, checked: boolean) => {
    if (onRowsSelected) {
      const keys = checked
        ? [...(selectedRows || []), rowKey]
        : selectedRows?.filter((id) => id !== rowKey);
      onRowsSelected(
        keys || [],
        `checkbox-${checked ? 'checked' : 'unchecked'}`
      );
    }
  };

  const totalDataLength = data?.length;
  let allChecked = false;
  let indeterminate = false;
  if (!!totalDataLength && selectedRows?.length === totalDataLength) {
    allChecked = true;
  } else if (!selectedRows?.length) {
    allChecked = false;
  } else if (
    selectedRows.length &&
    totalDataLength &&
    selectedRows.length < totalDataLength
  ) {
    indeterminate = true;
  }

  const handleCheckAll = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!data || !onRowsSelected) {
      return;
    }
    if (indeterminate || (allChecked && !checked)) {
      onRowsSelected([], 'select-all-unchecked');
    } else if (checked) {
      if (isRowSelectable) {
        onRowsSelected(
          uniq([
            ...(selectedRows || []),
            ...data
              .filter((record) => isRowSelectable(record))
              .map(rowKey as GetRowKey),
          ]),
          'select-all-checked'
        );
      } else {
        onRowsSelected(
          uniq([...(selectedRows || []), ...data.map(rowKey as GetRowKey)]),
          'select-all-checked'
        );
      }
    }
  };
  return {
    getRowClass: (row, rowState) => {
      const selectionDisabled = isRowSelectable
        ? !isRowSelectable(row, rowState)
        : false;
      const isChecked = allChecked || selectedRows?.includes(rowState.rowKey);
      return clsx(
        getRowClass?.(row, rowState),
        selectionDisabled && 'DS-Table-rowSelectionDisabled',
        isChecked && 'DS-Table-rowSelected'
      );
    },
    checkboxColumn: (
      <Column
        id="_checkBox"
        width={32}
        renderHeader={() => {
          const checkboxProps: CheckboxProps = {
            testId: 'check-all',
            checked: allChecked || indeterminate,
            indeterminate,
            onChange: handleCheckAll,
            onClick: (e) => e.stopPropagation(),
            size: 'small',
            disabled: checkboxColumnDisabled,
          };
          if (renderHeaderCheckbox) {
            return renderHeaderCheckbox(checkboxProps);
          }
          return <Checkbox {...checkboxProps} />;
        }}
        renderCell={(row: any, rowState: RowState) => {
          const checkboxProps: CheckboxProps = {
            value: rowState.rowKey,
            checked: allChecked || selectedRows?.includes(rowState.rowKey),
            onChange: (_, checked) => {
              handleCheck(rowState.rowKey, checked);
            },
            disabled:
              checkboxColumnDisabled ||
              (isRowSelectable ? !isRowSelectable(row, rowState) : false),
            onClick: (e) => e.stopPropagation(),
            size: 'small',
            testId: getTestId?.(row) ?? 'data-table-row-checkbox',
          };
          if (renderCellCheckbox) {
            return renderCellCheckbox(checkboxProps, row, rowState);
          } else {
            return <Checkbox {...checkboxProps} />;
          }
        }}
        cellWrapperSx={{ px: 8 }}
        frozen
        excludeFromSettings
        stopClickPropagation
        {...checkboxColumnProps}
      />
    ),
  };
};
