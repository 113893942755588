import {
  forwardRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';

import { Field, FieldProps } from 'react-final-form';

import { Select, SelectProps } from '@cast/design-system';

import { useControlsGroupState } from './controls-group';
import { useRffFormState } from '../hooks';
import { showErrorOnChange } from '../utils';

type Props<T> = SelectProps<T> & {
  name: string;
  label?: ReactNode;
  renderError?: (error: string | string[]) => ReactNode;
  fieldProps?: Partial<FieldProps<T, any>>;
  onMouseOver?: MouseEvent;
  onMouseLeave?: MouseEvent;
};

export const RffSelect = forwardRef<HTMLElement, Props<unknown>>(
  ({ renderError, fieldProps, label, ...autocompleteProps }, ref) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const { mode } = useRffFormState();
    const { disabled, readOnly } = useControlsGroupState({
      disabled: autocompleteProps.disabled,
      readOnly: mode === 'view' || autocompleteProps.inputProps?.readOnly,
    });

    return (
      <Field
        {...fieldProps}
        name={autocompleteProps.name}
        render={(props) => {
          const {
            input: { onBlur, onFocus, onChange, value },
            meta,
          } = props;
          const showError = showErrorOnChange(meta) && !popoverOpen;
          return (
            <Select
              {...(autocompleteProps as any)}
              value={autocompleteProps.value || value || null}
              onChange={(option, val) => {
                autocompleteProps.onChange?.(option, val);
                onChange(val);
                setPopoverOpen(false);
              }}
              onOpen={() => {
                setPopoverOpen(true);
                autocompleteProps.onOpen?.();
              }}
              onClose={() => {
                setPopoverOpen(false);
                autocompleteProps.onClose?.();
              }}
              inputProps={{
                readOnly,
                label,
                onBlur,
                onFocus,
                error: showError
                  ? renderError?.(meta.error) || meta.error
                  : undefined,
                success: meta.touched && meta.valid,
                // Tooltip passes these for it's direct child
                onMouseOver: autocompleteProps.onMouseOver,
                onMouseLeave: autocompleteProps.onMouseLeave,
                ...autocompleteProps.inputProps,
              }}
              disabled={disabled}
              ref={ref}
            />
          );
        }}
      />
    );
  }
) as (<T = any>(props: Props<T>) => ReactElement) & {
  displayName: string;
};

RffSelect.displayName = 'RffSelect';
