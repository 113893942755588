import { MutableRefObject, useEffect, useRef } from 'react';

import isString from 'lodash/isString';

import {
  NormalizedTableProps,
  RowKey,
  RowState,
  SetTableState,
  TableApi,
} from '../../../types';

const expandRow = (setState: SetTableState, rowKey: RowKey) => {
  setState((state) => ({
    ...state,
    expandedTreeRows: [...state.expandedTreeRows, rowKey],
  }));
};

const collapseRow = (setState: SetTableState, rowKey: RowKey) => {
  setState((state) => ({
    ...state,
    expandedTreeRows: state.expandedTreeRows.filter((key) => key !== rowKey),
  }));
};

const saveChildRow = (
  setState: SetTableState,
  rowKey: RowKey,
  rowData: any
) => {
  setState(
    (state) => ({
      ...state,
      childRows: {
        ...state.childRows,
        [rowKey]: rowData,
      },
    }),
    true
  );
};
export const useTreeRows = (
  tableApi: MutableRefObject<TableApi>,
  { rowKey: getRowKey }: NormalizedTableProps
) => {
  const initialized = useRef(false);
  if (!initialized.current) {
    tableApi.current.state.expandedTreeRows = [];
    tableApi.current.state.childRows = {};
    initialized.current = true;
  }

  useEffect(() => {
    const setState = tableApi.current.setState;
    tableApi.current.getTreeRows = async (row, childRows, rowState) => {
      const fetchChild = async (
        childRowsFn: <T>(
          row: T,
          rowState?: RowState
        ) => T[] | Promise<T> | undefined
      ) => {
        const dataSource = childRowsFn?.(row, rowState);
        if (!(dataSource instanceof Promise)) {
          return dataSource;
        }

        const data = await dataSource;
        if (Array.isArray(data)) {
          saveChildRow(setState, getRowKey(row), data);
          return data;
        } else {
          saveChildRow(setState, getRowKey(row), [data]);
          return [data];
        }
      };

      if (isString(childRows)) {
        return row[childRows];
      }

      if (tableApi.current.state.childRows?.[getRowKey(row)]) {
        return tableApi.current.state.childRows?.[getRowKey(row)];
      }

      if (typeof childRows === 'symbol' || typeof childRows === 'number') {
        return [];
      }

      if (childRows) {
        return fetchChild(childRows);
      }
    };
    tableApi.current.expandTreeRow = (row: any) => {
      const rowKey = getRowKey(row);
      if (tableApi.current.state.expandedTreeRows.includes(rowKey)) {
        return false;
      }
      expandRow(setState, rowKey);
      return true;
    };
    tableApi.current.collapseTreeRow = (row: any) => {
      const rowKey = getRowKey(row);
      if (!tableApi.current.state.expandedTreeRows.includes(rowKey)) {
        return false;
      }
      collapseRow(tableApi.current.setState, rowKey);
      return true;
    };
    tableApi.current.toggleIsTreeRowExpanded = (row: any) => {
      const rowKey = getRowKey(row);
      const isExpanded =
        tableApi.current.state.expandedTreeRows.includes(rowKey);
      if (isExpanded) {
        collapseRow(tableApi.current.setState, rowKey);
      } else {
        expandRow(setState, rowKey);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableApi]);
};
