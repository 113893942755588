import { alpha, ThemeOptions } from '@mui/material';

import { DsShadow } from '../../theme/shadows';
import { focusProps } from '../../theme/utils';
import { cssVars } from '../../utils/cssVars';

export const buttonVars = cssVars(
  {
    color: undefined,
    colorHover: undefined,
    colorActive: undefined,
    colorDisabled: undefined,

    background: undefined,
    backgroundHover: undefined,
    backgroundActive: undefined,
    backgroundDisabled: undefined,
    backgroundLoading: undefined,

    boxShadowHover: undefined,
    boxShadowActive: undefined,

    boxShadowDisabled: undefined,

    iconSize: undefined,
    iconGap: undefined,
  },
  'ds-button'
);

export const iconButtonVars = cssVars(
  {
    color: undefined,
    colorActive: undefined,
    colorDisabled: undefined,

    background: undefined,
    backgroundHover: undefined,
    backgroundActive: undefined,
    backgroundDisabled: undefined,

    boxShadowHover: undefined,
    boxShadowActive: undefined,

    boxShadowDisabled: undefined,

    iconSize: undefined,

    spinnerColor: undefined,
    spinnerBorderColor: undefined,
  },
  'ds-icon-button'
);

export const buttonsTheme: ThemeOptions['components'] = {
  DsButton: {
    defaultProps: {
      variant: 'primary',
      size: 'medium',
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme, fullWidth }) => ({
        borderRadius: '4px',
        width: fullWidth ? '100%' : 'max-content',
        background: buttonVars.getters.background(),
        color: buttonVars.getters.color(),

        '&:hover': {
          color: buttonVars.getters.colorHover(),
          background: buttonVars.getters.backgroundHover(),
          boxShadow: buttonVars.getters.boxShadowHover(),
        },

        '&:active': {
          color: buttonVars.getters.colorActive(),
          background: buttonVars.getters.backgroundActive(),
          boxShadow: buttonVars.getters.boxShadowActive(),
        },

        '&:focus-visible': {
          ...focusProps(theme),
        },
        whiteSpace: 'nowrap',
      }),
      startIcon: {
        '& .DsButton-StartIcon': {
          display: 'inherit',
          marginRight: buttonVars.getters.iconGap(),

          '& svg': {
            fontSize: buttonVars.getters.iconSize(),
          },
        },
      },
      endIcon: {
        '& .DsButton-EndIcon': {
          display: 'inherit',
          marginLeft: buttonVars.getters.iconGap(),

          '& svg': {
            fontSize: buttonVars.getters.iconSize(),
          },
        },
      },
      primary: ({ theme }) =>
        buttonVars.setValues({
          color: '#FFFFFF',
          colorHover: '#FFFFFF',
          colorActive: '#FFFFFF',
          colorDisabled: '#FFFFFF',
          background: theme.palette.blue[500],
          backgroundHover: theme.palette.blue[600],
          backgroundActive: theme.palette.blue[700],
          backgroundDisabled: theme.palette.blue[200],
          backgroundLoading: theme.palette.blue[50],
          boxShadowHover: undefined,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.blue[900]}`,
          boxShadowDisabled: undefined,
        }),
      secondary: ({ theme }) =>
        buttonVars.setValues({
          color: '#FFFFFF',
          colorHover: '#FFFFFF',
          colorActive: '#FFFFFF',
          colorDisabled: '#FFFFFF',
          background: theme.palette.green[500],
          backgroundHover: theme.palette.green[600],
          backgroundActive: theme.palette.green[700],
          backgroundDisabled: theme.palette.green[200],
          backgroundLoading: theme.palette.green[50],
          boxShadowHover: undefined,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.green[900]}`,
          boxShadowDisabled: undefined,
        }),
      tertiary: ({ theme }) =>
        buttonVars.setValues({
          color: theme.palette.grey[900],
          colorHover: theme.palette.grey[900],
          colorActive: theme.palette.grey[900],
          colorDisabled: theme.palette.grey[300],
          background: theme.palette.grey[200],
          backgroundHover: theme.palette.grey[300],
          backgroundActive: theme.palette.grey[300],
          backgroundDisabled: theme.palette.grey[100],
          backgroundLoading: theme.palette.grey[200],
          boxShadowHover: undefined,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.grey[400]}`,
          boxShadowDisabled: `inset 0 0 0 1px ${theme.palette.grey[200]}`,
        }),
      ghost: ({ theme }) =>
        buttonVars.setValues({
          color: theme.palette.grey[700],
          colorHover: theme.palette.grey[700],
          colorActive: theme.palette.grey[700],
          colorDisabled: theme.palette.grey[300],
          background: undefined,
          backgroundHover: theme.palette.grey[100],
          backgroundActive: theme.palette.grey[100],
          backgroundDisabled: undefined,
          backgroundLoading: theme.palette.grey[300],
          boxShadowHover: `inset 0 0 0 1px ${theme.palette.grey[200]}`,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.grey[300]}`,
          boxShadowDisabled: undefined,
        }),
      danger: ({ theme }) =>
        buttonVars.setValues({
          color: '#FFFFFF',
          colorHover: '#FFFFFF',
          colorActive: '#FFFFFF',
          colorDisabled: '#FFFFFF',
          background: theme.palette.red[400],
          backgroundHover: theme.palette.red[500],
          backgroundActive: theme.palette.red[600],
          backgroundDisabled: theme.palette.red[100],
          backgroundLoading: theme.palette.red[50],
          boxShadowHover: undefined,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.red[800]}`,
          boxShadowDisabled: undefined,
        }),
      text: ({ theme }) =>
        buttonVars.setValues({
          color: theme.palette.blue[500],
          colorHover: theme.palette.blue[600],
          colorActive: theme.palette.blue[700],
          colorDisabled: theme.palette.blue[200],
          background: undefined,
          backgroundHover: undefined,
          backgroundActive: undefined,
          backgroundDisabled: undefined,
          backgroundLoading: theme.palette.blue[50],
          boxShadowHover: undefined,
          boxShadowActive: undefined,
          boxShadowDisabled: undefined,
        }),
      large: ({ theme, variant }) => ({
        ...(variant === 'text'
          ? {
              ...buttonVars.setValues({
                iconGap: '4px',
                iconSize: '20px',
              }),
              ...theme.typography.P14M,
              padding: '0',
            }
          : {
              ...buttonVars.setValues({
                iconGap: '8px',
                iconSize: '20px',
              }),
              ...theme.typography.P14B,
              height: '40px',
              padding: '0 16px',
            }),
      }),
      medium: ({ theme, variant }) => ({
        ...(variant === 'text'
          ? {
              ...buttonVars.setValues({
                iconGap: '4px',
                iconSize: '16px',
              }),
              ...theme.typography.P12M,
              padding: '0',
            }
          : {
              ...buttonVars.setValues({
                iconGap: '8px',
                iconSize: '20px',
              }),
              ...theme.typography.P14B,
              height: '32px',
              padding: '0 12px',
            }),
      }),
      small: ({ theme, variant }) => ({
        ...(variant === 'text'
          ? {
              ...buttonVars.setValues({
                iconGap: '4px',
                iconSize: '12px',
              }),
              ...theme.typography.P10M,
              padding: '0',
            }
          : {
              ...buttonVars.setValues({
                iconGap: '4px',
                iconSize: '16px',
              }),
              ...theme.typography.P12B,
              height: '28px',
              padding: '0 10px',
            }),
      }),
      disabled: {
        '&.Mui-disabled': {
          background: buttonVars.getters.backgroundDisabled(),
          boxShadow: buttonVars.getters.boxShadowDisabled(),
          color: buttonVars.getters.colorDisabled(),
        },
      },
      loading: {
        '& .MuiLinearProgress-root': {
          height: '2px',
          position: 'absolute',
          left: '4px',
          right: '4px',
          bottom: '2px',
          borderRadius: '3px',
          backgroundColor: 'transparent',
          opacity: 0.6,

          '& .MuiLinearProgress-bar1Indeterminate': {
            visibility: 'hidden',
          },
          '& .MuiLinearProgress-bar2Indeterminate': {
            display: 'block',
            background: buttonVars.getters.backgroundLoading(),
          },
        },
      },
    },
  },
  DsIconButton: {
    defaultProps: {
      variant: 'primary',
      size: 'medium',
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '4px',
        background: iconButtonVars.getters.background(),
        color: iconButtonVars.getters.color(),

        '&:hover': {
          background: iconButtonVars.getters.backgroundHover(),
          boxShadow: iconButtonVars.getters.boxShadowHover(),
        },
        '&:active': {
          color: iconButtonVars.getters.colorActive(),
          background: iconButtonVars.getters.backgroundActive(),
          boxShadow: iconButtonVars.getters.boxShadowActive(),
        },
        '&:focus-visible': {
          ...focusProps(theme),
        },

        '& svg': {
          fontSize: iconButtonVars.getters.iconSize(),
        },
      }),
      primary: ({ theme }) =>
        iconButtonVars.setValues({
          color: '#FFFFFF',
          colorDisabled: '#FFFFFF',
          background: theme.palette.blue[500],
          backgroundHover: theme.palette.blue[600],
          backgroundActive: theme.palette.blue[700],
          backgroundDisabled: theme.palette.blue[200],
          boxShadowHover: undefined,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.blue[900]}`,
          boxShadowDisabled: undefined,
          spinnerColor: '#FFFFFF',
          spinnerBorderColor: 'transparent',
        }),
      secondary: ({ theme }) =>
        iconButtonVars.setValues({
          color: '#FFFFFF',
          colorDisabled: '#FFFFFF',
          background: theme.palette.green[500],
          backgroundHover: theme.palette.green[600],
          backgroundActive: theme.palette.green[700],
          backgroundDisabled: theme.palette.green[200],
          boxShadowHover: undefined,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.green[900]}`,
          boxShadowDisabled: undefined,
          spinnerColor: '#FFFFFF',
          spinnerBorderColor: 'transparent',
        }),
      tertiary: ({ theme }) =>
        iconButtonVars.setValues({
          color: theme.palette.grey[900],
          colorDisabled: theme.palette.grey[300],
          background: theme.palette.grey[200],
          backgroundHover: theme.palette.grey[300],
          backgroundActive: theme.palette.grey[300],
          backgroundDisabled: theme.palette.grey[100],
          boxShadowHover: undefined,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.grey[400]}`,
          boxShadowDisabled: `1px solid ${theme.palette.grey[200]}`,
          spinnerColor: theme.palette.grey[600],
          spinnerBorderColor: theme.palette.grey[200],
        }),
      ghost: ({ theme }) =>
        iconButtonVars.setValues({
          color: theme.palette.grey[700],
          colorDisabled: theme.palette.grey[300],
          background: undefined,
          backgroundHover: theme.palette.grey[100],
          backgroundActive: theme.palette.grey[100],
          backgroundDisabled: undefined,
          boxShadowHover: `inset 0 0 0 1px ${theme.palette.grey[200]}`,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.grey[300]}`,
          boxShadowDisabled: undefined,
          spinnerColor: theme.palette.grey[600],
          spinnerBorderColor: theme.palette.grey[200],
        }),
      danger: ({ theme }) =>
        iconButtonVars.setValues({
          color: '#FFFFFF',
          colorDisabled: '#FFFFFF',
          background: theme.palette.red[400],
          backgroundHover: theme.palette.red[500],
          backgroundActive: theme.palette.red[600],
          backgroundDisabled: theme.palette.red[100],
          boxShadowHover: undefined,
          boxShadowActive: `inset 0 0 0 1px ${theme.palette.red[800]}`,
          boxShadowDisabled: undefined,
          spinnerColor: '#FFFFFF',
          spinnerBorderColor: 'transparent',
        }),
      large: {
        ...iconButtonVars.setValues({ iconSize: '20px' }),
        width: '40px',
        height: '40px',
      },
      medium: {
        ...iconButtonVars.setValues({ iconSize: '20px' }),
        width: '32px',
        height: '32px',
      },
      small: {
        ...iconButtonVars.setValues({ iconSize: '16px' }),
        width: '28px',
        height: '28px',
      },
      disabled: {
        '&:disabled': {
          background: iconButtonVars.getters.backgroundDisabled(),
          boxShadow: iconButtonVars.getters.boxShadowDisabled(),
          color: iconButtonVars.getters.colorDisabled(),
        },
      },
      loading: {
        '& .DsIconButton-Spinner': {
          display: 'inherit',

          '& .MuiCircularProgress-colorPrimary': {
            color: iconButtonVars.getters.spinnerColor(),
          },
          '& .DS-Spinner-withBackground:before': {
            borderColor: iconButtonVars.getters.spinnerBorderColor(),
          },
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        gap: '2px',
        width: 'max-content',
        height: 'max-content',
        padding: 2,
        backgroundColor: theme.palette.grey[200],
      }),
    },
  },
  MuiToggleButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        border: 'none',
        ...theme.typography.P14B,
        textTransform: 'initial',

        '&&&': {
          margin: 0,
          borderRadius: '4px',
        },

        '&.Mui-focusVisible': {
          ':after': {
            content: '""',
            position: 'absolute',
            top: -2,
            right: -2,
            bottom: -2,
            left: -2,
            borderRadius: '4px',
            ...focusProps(theme),
          },
        },

        '&.Mui-selected': {
          backgroundColor: 'white',
          color: theme.palette.grey[600],
          '&:hover': {
            backgroundColor: 'white',
            boxShadow: theme.shadows[DsShadow.HOVER_COMPONENT],
          },
        },
        '&:not(Mui-selected)': {
          color: theme.palette.grey[400],
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[300], 0.3),
          },
        },

        '&.Mui-disabled': {
          border: 'none',
          '&.Mui-selected': {
            backgroundColor: theme.palette.grey[50],
            color: theme.palette.grey[200],
          },
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.grey[100],
        },

        '&.MuiToggleButton-size': {
          '&Large': {
            height: 36,
            gap: '6px',
          },
          '&Medium': {
            height: 28,
            gap: '6px',
          },
          '&Small': {
            height: 24,
            gap: '4px',
            fontSize: 12,
          },
        },
        '&.DsToggleButton-type': {
          '&Icon': {
            '&.MuiToggleButton-size': {
              '&Large': {
                width: 36,
              },
              '&Medium': {
                width: 28,
              },
              '&Small': {
                width: 24,
              },
            },
          },
          '&Mixed': {
            '&.MuiToggleButton-size': {
              '&Large': {
                paddingLeft: 14,
                paddingRight: 16,
              },
              '&Medium': {
                paddingLeft: 10,
                paddingRight: 12,
              },
              '&Small': {
                paddingLeft: 8,
                paddingRight: 10,
              },
            },
          },
          '&Text': {
            '&.MuiToggleButton-size': {
              '&Large': {
                paddingLeft: 16,
                paddingRight: 16,
              },
              '&Medium': {
                paddingLeft: 12,
                paddingRight: 12,
              },
              '&Small': {
                paddingLeft: 10,
                paddingRight: 10,
              },
            },
          },
        },
      }),
    },
  },
};
