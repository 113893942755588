export const DEFAULT_RANGE_FORMAT = 'y/MM/dd';
export const DATE_TIME_NO_SEC = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_NO_SEC_AMPM = 'YYYY-MM-DD hh:mm A';
export const DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_PRECISE = 'YYYY-MM-DD HH:mm:ss.SSS';
export const DATE = 'MMMM D, YYYY';
export const DATE_SIMPLE = 'YYYY-MM-DD';
export const DATE_SIMPLE_NO_DASH = 'YYYY MM DD';
export const DATE_LONG_MONTH = 'MMMM D, YYYY HH:mm';
export const TIME = 'HH:mm:ss';
export const DATE_LONG_MONTH_WITH_OFFSET = 'MMMM D, YYYY HH:mm [GMT]Z';
export const DATE_TIME_WITH_OFFSET = 'YYYY-MM-DD HH:mm:ss [GMT]Z';
