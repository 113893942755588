import replace from 'lodash/replace';
import { Location, Path, parsePath } from 'react-router-dom';

import { organizationQueryKey } from '@cast/constants';

export type GetLocationDescriptorSettings = {
  preserveAll?: boolean;
  preserveSearch?: boolean;
  preserveState?: boolean;
  preserveHash?: boolean;
  preserveKey?: boolean;
};

export const getLocationDescriptor = (
  path: Partial<Path> | string,
  location: Location,
  settings?: GetLocationDescriptorSettings
): Partial<Location> => {
  const parsedPath = typeof path === 'string' ? parsePath(path) : path;
  if (
    !settings ||
    (!settings.preserveAll &&
      !settings.preserveSearch &&
      !settings.preserveState &&
      !settings.preserveHash &&
      !settings.preserveKey)
  ) {
    return parsedPath;
  }
  const getSetting = (key: keyof GetLocationDescriptorSettings) =>
    (settings.preserveAll && settings[key] !== false) || settings[key];

  const preserveSearch = getSetting('preserveSearch');
  const preserveState = getSetting('preserveState');
  const preserveHash = getSetting('preserveHash');
  const preserveKey = getSetting('preserveKey');
  const newDestination: Partial<Location> = {
    pathname: parsedPath.pathname,
  };

  if (preserveSearch) {
    const existingSearch = new URLSearchParams(location.search);
    const newSearch = new URLSearchParams(parsedPath.search);
    existingSearch.forEach((value, key) => {
      newSearch.append(key, value);
    });
    const searchString = newSearch.toString();
    if (searchString?.length) {
      newDestination.search = searchString;
    }
  } else if (parsedPath.search) {
    newDestination.search = parsedPath.search;
  }

  if (preserveHash && !parsedPath.hash && location.hash) {
    newDestination.hash = location.hash;
  } else if (parsedPath.hash) {
    newDestination.hash = parsedPath.hash;
  }

  if (preserveState && location.state) {
    newDestination.state = location.state;
  }

  if (preserveKey && location.key) {
    newDestination.key = location.key;
  }

  return newDestination;
};

export const applyYamlPreview = (code: string, orgId?: string) => {
  const token = code.match(/Token\s[^"]+/);
  const yaml = code.match(/agent[^"]+/);

  if (!yaml || !token) {
    return code;
  }

  const urlParams = new URLSearchParams({
    ...(orgId ? { [organizationQueryKey]: orgId } : {}),
    yaml: yaml[0],
    token: token[0],
  });

  return replace(
    code,
    /https[^"]+/,
    (string) =>
      `<a href=/yaml-preview?${urlParams} target="_blank" rel="noopener noreferrer">${string}</a>`
  );
};
