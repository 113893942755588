import axios from 'axios';

import { AXIOS_BASE_PATH, AXIOS_CLIENT_TIMEOUT } from './constants';

export const client = axios.create({
  timeout: AXIOS_CLIENT_TIMEOUT,
  baseURL: AXIOS_BASE_PATH,
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  },
});
