import Big from 'big.js';

import { CostReportClusterSummaryNormalized } from 'types/cost-report';

export const toBigNumberTypes = <T extends Record<string, any>, R>(
  item: T,
  ignoreKeys: (keyof T)[] = []
): R => {
  const converted = {} as Record<string, any>;
  Object.keys(item).forEach((key) => {
    converted[key] = ignoreKeys.includes(key) ? item[key] : Big(item[key] || 0);
  });

  return converted as R;
};

export const enhanceClusterSummary = (
  summary: CostReportClusterSummaryNormalized | undefined
) => {
  if (!summary) {
    return undefined;
  }

  const {
    cpuAllocatableOnDemand,
    cpuAllocatableSpot,
    cpuAllocatableSpotFallback,
    cpuRequestedOnDemand,
    cpuRequestedSpot,
    cpuRequestedSpotFallback,
    cpuProvisionedOnDemand,
    cpuProvisionedSpot,
    cpuProvisionedSpotFallback,
    cpuUsed,
    ramAllocatableOnDemand,
    ramAllocatableSpot,
    ramAllocatableSpotFallback,
    ramRequestedOnDemand,
    ramRequestedSpot,
    ramRequestedSpotFallback,
    ramProvisionedOnDemand,
    ramProvisionedSpot,
    ramProvisionedSpotFallback,
    ramUsed,
    nodeCountOnDemand,
    nodeCountOnDemandCastai,
    nodeCountSpot,
    nodeCountSpotCastai,
    nodeCountSpotFallbackCastai,
    cpuCostHourlyOnDemand,
    cpuCostHourlySpot,
    cpuCostHourlySpotFallback,
    gpuProvisionedOnDemand,
    gpuProvisionedSpot,
    gpuProvisionedSpotFallback,
    gpuAllocatableSpot,
    gpuAllocatableSpotFallback,
    gpuAllocatableOnDemand,
    gpuRequestedSpot,
    gpuRequestedSpotFallback,
    gpuRequestedOnDemand,
    gpuUsedOnDemand,
    gpuUsedSpot,
    gpuUsedSpotFallback,
    gpuNotUsedOnDemand,
    gpuNotUsedSpot,
    gpuNotUsedSpotFallback,
    gpuIdleOnDemand,
    gpuIdleSpot,
    gpuIdleSpotFallback,
  } = summary;

  const cpuAllocatableTotal = cpuAllocatableOnDemand
    .add(cpuAllocatableSpot)
    .add(cpuAllocatableSpotFallback);
  const cpuRequestedTotal = cpuRequestedOnDemand
    .add(cpuRequestedSpot)
    .add(cpuRequestedSpotFallback);
  const cpuProvisionedTotal = cpuProvisionedOnDemand
    .add(cpuProvisionedSpot)
    .add(cpuProvisionedSpotFallback);

  const ramAllocatableTotal = ramAllocatableOnDemand
    .add(ramAllocatableSpot)
    .add(ramAllocatableSpotFallback);
  const ramRequestedTotal = ramRequestedOnDemand
    .add(ramRequestedSpot)
    .add(ramRequestedSpotFallback);
  const ramProvisionedTotal = ramProvisionedOnDemand
    .add(ramProvisionedSpot)
    .add(ramProvisionedSpotFallback);

  const gpuProvisionedTotal = Big(gpuProvisionedOnDemand || 0)
    .add(gpuProvisionedSpot || 0)
    .add(gpuProvisionedSpotFallback || 0);
  const gpuAllocatableTotal = Big(gpuAllocatableOnDemand || 0)
    .add(gpuAllocatableSpot || 0)
    .add(gpuAllocatableSpotFallback || 0);
  const gpuRequestedTotal = Big(gpuRequestedOnDemand || 0)
    .add(gpuRequestedSpot || 0)
    .add(gpuRequestedSpotFallback || 0);
  const gpuIdleTotal = Big(gpuIdleOnDemand || 0)
    .add(gpuIdleSpot || 0)
    .add(gpuIdleSpotFallback || 0);
  const gpuNotUsedTotal = Big(gpuNotUsedOnDemand || 0)
    .add(gpuNotUsedSpot || 0)
    .add(gpuNotUsedSpotFallback || 0);
  const gpuUsedTotal = Big(gpuUsedOnDemand || 0)
    .add(gpuUsedSpot || 0)
    .add(gpuUsedSpotFallback || 0);

  const nodesCountTotal = nodeCountOnDemand
    .add(nodeCountSpot)
    .add(nodeCountSpotFallbackCastai);
  const nodesCountManagedTotal = nodeCountOnDemandCastai
    .add(nodeCountSpotCastai)
    .add(nodeCountSpotFallbackCastai);

  const cpuCostHourlyTotal = cpuCostHourlyOnDemand
    .add(cpuCostHourlySpot)
    .add(cpuCostHourlySpotFallback);

  return {
    ...summary,
    cpuAllocatableTotal,
    cpuRequestedTotal,
    cpuProvisionedTotal,
    cpuUsed,
    ramAllocatableTotal,
    ramRequestedTotal,
    ramProvisionedTotal,
    gpuProvisionedTotal,
    gpuAllocatableTotal,
    gpuRequestedTotal,
    gpuIdleTotal,
    gpuUsedTotal,
    gpuNotUsedTotal,
    nodesCountTotal,
    nodesCountManagedTotal,
    ramUsed,
    cpuCostHourlyTotal,
  };
};
