import { ReactNode } from 'react';

import { Form } from 'react-final-form';

import { ClusterEditParams } from '@cast/types';
import { makeValidateSync } from '@cast/utils';

import { RffInput } from 'core/forms/rff';
import { AWSARNCredentialsSchema } from 'utils/validators';

type Props = {
  onSubmit: (value: ClusterEditParams) => void;
  setSubmitHandler: (v: () => void) => void;
  error?: ReactNode;
};

const validate = makeValidateSync(AWSARNCredentialsSchema);

export const AWSCredentialsForm = ({
  onSubmit,
  setSubmitHandler,
  error,
}: Props) => {
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        setSubmitHandler(handleSubmit);

        return (
          <form onSubmit={handleSubmit} noValidate>
            <RffInput
              testId="aws-credentials-input"
              id="aws-role-arn"
              size="medium"
              name="eks.assumeRoleArn"
              placeholder="Role ARN"
              required
              inputProps={{
                'aria-label': 'credentials aws role arn',
              }}
              error={error}
            />
          </form>
        );
      }}
    />
  );
};
