import { ReactNode } from 'react';

import { FooterCell } from './FooterCell';
import { useTableContext } from '../../hooks';

export const FooterRow = () => {
  const { api } = useTableContext();
  const columnModels = api.current.columnsInfo.columnModels;
  const footerCells: ReactNode[] = [];
  for (const columnModel of columnModels) {
    footerCells.push(
      <FooterCell key={columnModel.id} model={columnModel} api={api.current} />
    );
  }
  return (
    <div className="DS-Table-FooterRowWrapper">
      <div className="DS-Table-FooterRow">{footerCells}</div>
    </div>
  );
};
