import { useContext } from 'react';

import { styled } from '@mui/material';

import { DataPopperContext } from './context';
import {
  DataPopperProps,
  RowIndicatorProps,
  DataPopperRowProps,
} from './types';
import { shouldForwardProp } from '../../utils/shouldForwardProp';

const RowIndicatorRoot = styled('div', {
  name: 'DsDataPopperRowIndicator',
  slot: 'Root',
  target: 'DsDataPopperRowIndicator-Root',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root],
})<{
  ownerState: RowIndicatorProps & {
    rowProps?: DataPopperRowProps;
    popperProps: DataPopperProps;
  };
}>``;

export const RowIndicator = ({
  variant = 'filled',
  ...props
}: RowIndicatorProps) => {
  const { rowProps, popperProps } = useContext(DataPopperContext);
  return (
    <RowIndicatorRoot
      ownerState={{ variant, ...props, rowProps, popperProps }}
      sx={props.sx}
    />
  );
};
