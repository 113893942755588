import { ThemeOptions } from '@mui/material';

export const skeletonTheme: ThemeOptions['components'] = {
  MuiSkeleton: {
    defaultProps: {
      variant: 'rectangular',
      animation: 'wave',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.grey[200],
        borderRadius: '4px',

        '&.MuiSkeleton-circular': {
          borderRadius: '100%',
        },
        '.DSuiSkeletonTable-x-large &': {
          height: '48px',
        },
        '.DSuiSkeletonTable-large &': {
          height: '40px',
        },
        '.DSuiSkeletonTable-medium &': {
          height: '32px',
        },
        '.DSuiSkeletonTable-small &': {
          height: '28px',
        },
      }),
    },
  },
};
