import { FeatureFlagResolved } from '@cast/types';

import { useAccessControl } from 'core/access-control/AccessControlProvider';
import { useOrganizations } from 'hooks/useOrganizations';

import { ConsoleRules, UseRules } from '../types';
import { ruleBuilder as ruleBuilderV2 } from '../utils/rule-builder';

export const useRulesV2 = (
  flags: FeatureFlagResolved[],
  subjectId?: string
): UseRules => {
  const { currentOrganization } = useOrganizations();
  const { isLoading, subjectAccessData, error } = useAccessControl({
    subjectId,
    // false to not cause cluster fetching
    excludeOrphanedClusters: false,
  });

  const rules: ConsoleRules[] = [];

  if (subjectAccessData && subjectId && currentOrganization?.id) {
    rules.push(...ruleBuilderV2(subjectId, subjectAccessData, flags));
  }

  return {
    isLoading,
    rules,
    error,
  };
};
