import { createContext, PropsWithChildren, useRef } from 'react';

import { BoxProps } from '@mui/material';

import { Portal } from './types';

export const PortalsContext = createContext<
  | {
      addPortal: (portal: Portal, ref: HTMLElement | null) => void;
      setPortalProps: (portal: `${Portal}`, props: BoxProps) => void;
      clearPortalProps: (portal: `${Portal}`) => void;
      portals: Partial<Record<Portal, HTMLElement | null>>;
      portalsProps: Partial<Record<Portal, BoxProps>>;
    }
  | undefined
>(undefined);

export const PortalsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const portalsRef = useRef<Partial<Record<Portal, HTMLElement | null>>>({});
  const portalsProps = useRef<Partial<Record<Portal, BoxProps>>>({});
  return (
    <PortalsContext.Provider
      value={{
        addPortal: (portal, ref) => (portalsRef.current[portal] = ref),
        setPortalProps: (portal, props) => {
          portalsProps.current[portal] = props;
        },
        clearPortalProps: (portal) => {
          delete portalsProps.current[portal];
        },
        portals: portalsRef.current,
        portalsProps: portalsProps.current,
      }}
    >
      {children}
    </PortalsContext.Provider>
  );
};
