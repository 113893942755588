import {
  createContext,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';

import { SxProps } from '@mui/material';

import { CodePreviewState } from '../types';

export type PreviewConfigProps = {
  rootSx?: SxProps;
  children?: ((codePreviewState?: CodePreviewState) => ReactNode) | ReactNode;
  containerSx?: SxProps;
  interactive?: boolean;
  showLineNumbers?: boolean;
  data: any;
  errorMessage?: string;
  renderFallback?: (data: any, resetError?: () => void) => ReactElement;
  height?: number | string;
  color?: 'primary' | 'secondary';
  size?: 'large' | 'medium';
  horizontalPadding?: number;
};

export const PreviewConfigContext = createContext<PreviewConfigProps>(
  {} as never
);

type Props<T extends PreviewConfigProps> = PropsWithChildren<T>;

export const PreviewConfigProvider = <T extends PreviewConfigProps>({
  children,
  ...props
}: Props<T>) => {
  return (
    <PreviewConfigContext.Provider value={props}>
      {children}
    </PreviewConfigContext.Provider>
  );
};
