import { useMemo } from 'react';

import { useDebounce } from '@cast/utils';

import { filterByFreeText as defaultFilterByFreeText } from '../client-side';
import { FreeTextPredicate } from '../types';

export const useSearchByFreeText = (
  data?: any[],
  freeText?: string,
  enabled = true,
  freeTextPredicate?: FreeTextPredicate,
  filterByFreeText: (
    data: any[],
    freeText: string,
    freeTextPredicate?: FreeTextPredicate
  ) => any[] = defaultFilterByFreeText
) => {
  const debouncedFreeText = useDebounce(freeText);

  return useMemo(() => {
    if (!enabled) {
      return data;
    }
    if (!data?.length) {
      return [];
    }
    if (!freeText) {
      return data;
    }
    return filterByFreeText(data, freeText, freeTextPredicate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, debouncedFreeText, enabled, freeTextPredicate, filterByFreeText]);
};
