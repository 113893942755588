import { useMutation, useQueryClient } from '@tanstack/react-query';
import pluralize from 'pluralize';

import { QueryKeys, apiClient } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useAckNotificationsMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (ids: string[]) => {
      return apiClient.notifications.ackNotifications({ body: { ids } });
    },
    onSuccess: (_, ids) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.NOTIFICATIONS] });
      notify.success(
        `${pluralize('Notification', ids?.length || 1)} resolved successfully`
      );
      onSuccess?.();
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
