import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateWebhook } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useCreateWebhookMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (createWebhook: CreateWebhook) => {
      return apiClient.notifications.createWebhook({ config: createWebhook });
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKeys.WEBHOOKS] })
        .then(() => {
          notify.success('Webhook created successfully');
          onSuccess?.();
        });
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
