import { processColumn } from './processColumn';
import { ColumnsInfo, ParsedGroupProps } from '../../../types';

export const processColumnGroup = ({
  groupProps,
  info,
}: {
  groupProps: ParsedGroupProps;
  info: ColumnsInfo;
}) => {
  const modelsCountBeforeProcessing = info.columnModels.length;
  for (const columnProps of groupProps.columns) {
    if (columnProps.locked !== undefined) {
      throw new Error(
        "Column in group cannot be locked. Set 'locked' prop on entire group"
      );
    }
    processColumn({
      columnProps,
      info,
      groupProps,
    });
  }
  if (!info.groupModels) {
    info.groupModels = [];
  }
  const modelsCountAfterProcessing = info.columnModels.length;
  if (modelsCountBeforeProcessing < modelsCountAfterProcessing) {
    const groupColumns = info.columnModels.slice(modelsCountBeforeProcessing);
    const firstColumnOfGroup = groupColumns[0];
    const lastColumnOfGroup = groupColumns[groupColumns.length - 1];
    firstColumnOfGroup.isFirstGroupColumn = true;
    lastColumnOfGroup.isLastGroupColumn = true;
    info.groupModels.push({
      ...groupProps,
      startOffset: firstColumnOfGroup.startOffset,
      endOffset: lastColumnOfGroup.endOffset,
      columns: groupColumns,
      locked: !!groupProps.locked,
    });
  }
};
