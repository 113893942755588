import { forwardRef, useCallback } from 'react';

import {
  ComposedChartWithEstimate,
  ComposedChartWithEstimateRef,
} from 'components/chart';

import { getFutureDatapointIndex } from './utils';
import { ComposedChartWithCostReportProps } from '../../types/costOverTime';

export const CostReportAreaChart = forwardRef<
  ComposedChartWithEstimateRef,
  ComposedChartWithCostReportProps
>(({ composedProps, xAxisProps, yAxisProps, data, ...chartProps }, ref) => {
  const yTickFormatter = useCallback((t: string) => `$${t}`, []);

  if (!data.length) {
    return null;
  }

  return (
    <ComposedChartWithEstimate
      ref={ref}
      {...chartProps}
      estimateStartPoint={getFutureDatapointIndex(data)}
      data={data}
      composedProps={{
        ...composedProps,
        margin: { top: -1, left: 0, right: 0 },
      }}
      xAxisProps={xAxisProps}
      yAxisProps={{
        tickFormatter: yTickFormatter,
        ...yAxisProps,
      }}
    />
  );
});

CostReportAreaChart.displayName = 'CostReportAreaChart';
