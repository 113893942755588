import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

type Props = {
  text?: string;
};

export const DividerWithText = ({ text }: Props) => {
  return (
    <Divider
      textAlign="left"
      sx={{
        borderColor: 'green.400',
        '&::before': { width: '52px' },
        '&::before, &:after': { borderColor: 'inherit' },
      }}
    >
      {!!text && (
        <Typography
          variant="L10M"
          color="green.500"
          sx={(theme) => ({
            background: theme.palette.grey[100],
            padding: '0 4px',
          })}
        >
          {text}
        </Typography>
      )}
    </Divider>
  );
};
