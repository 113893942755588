import { useCallback, useState } from 'react';

import {
  clearQueryParamSilently,
  getDeserializedQueryParam,
  getQueryParam,
  setQueryParamSilently,
  setSerializedQueryParamSilently,
} from '../url';

type Type = 'string' | 'object' | 'number';

type IO<T extends string | object | number> = {
  store: (key: string, value: T) => void;
  retrieve: (key: string) => T | undefined;
};

const IoByType: Record<Type, IO<any>> = {
  string: {
    store: (key: string, value: string) =>
      setQueryParamSilently(key, encodeURIComponent(value)),
    retrieve: (key: string) => {
      const value = getQueryParam(key);
      if (value) {
        return decodeURIComponent(value);
      }
    },
  },
  object: {
    store: setSerializedQueryParamSilently,
    retrieve: getDeserializedQueryParam,
  },
  number: {
    store: setQueryParamSilently,
    retrieve: getQueryParam,
  },
};

export const useSearchParamState = <T = string>(
  searchParamKey?: string,
  defaultVal?: T | (() => T),
  type: Type = 'string'
) => {
  const { store, retrieve } = IoByType[type];

  const [state, setState] = useState<T | undefined>(
    searchParamKey
      ? () => {
          const retrievedValue = retrieve(searchParamKey);
          if (retrievedValue !== undefined) {
            return retrievedValue;
          }
          if (defaultVal) {
            if (typeof defaultVal === 'function') {
              return (defaultVal as any)() as T;
            }
            return defaultVal;
          }
        }
      : defaultVal
  );

  const handleStateUpdate = useCallback(
    (s: T | undefined) => {
      if (searchParamKey) {
        if (s) {
          store(searchParamKey, s as any);
        } else {
          clearQueryParamSilently(searchParamKey);
        }
      }
      setState(s);
    },
    [searchParamKey, store]
  );

  return {
    state,
    setState: handleStateUpdate,
  };
};
