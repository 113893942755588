import { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';

export const ActionIcon = ({
  children,
  className,
  disabled,
  ...props
}: PropsWithChildren<BoxProps & { disabled?: boolean }>) => {
  return (
    <Box
      {...props}
      className={clsx(
        'DS-Table-ActionIcon',
        disabled && 'DS-Table-actionDisabled',
        className
      )}
    >
      {children}
    </Box>
  );
};
