import { Typography, TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import { MoneyProps } from './Money';
import { MoneyWithTooltip } from './MoneyWithTooltip';

export type MoneyPerPeriodProps = {
  moneyProps: MoneyProps;
  primaryTextProps?: TypographyProps;
  suffixTextProps?: TypographyProps;
  suffix: string;
  compact?: boolean;
  sx?: SxProps<any>;
  testId?: string;
};

export const MoneyPerPeriod = ({
  suffix,
  compact = false,
  moneyProps,
  primaryTextProps = {},
  suffixTextProps = {},
  sx,
  testId,
}: MoneyPerPeriodProps) => {
  const hasMoney = moneyProps.amount != null;

  return (
    <Typography
      sx={sx}
      noWrap
      {...primaryTextProps}
      className={clsx('MoneyPerPeriod-root', primaryTextProps?.className)}
      data-testid={testId}
    >
      {hasMoney ? (
        <MoneyWithTooltip
          moneyProps={moneyProps}
          compact={compact}
          className="MoneyPerPeriod-money"
        />
      ) : (
        <span className="MoneyPerPeriod-money MoneyPerPeriod-emptyMoney">
          $--.--
        </span>
      )}{' '}
      <Typography
        component="span"
        color="textSecondary"
        fontSize="0.85em"
        {...suffixTextProps}
        sx={suffixTextProps?.sx}
        className="MoneyPerPeriod-suffix"
      >
        /{suffix}
      </Typography>
    </Typography>
  );
};
