import type {
  CastaiAutoscalerV1beta1RebalancingNode,
  CastaiAutoscalerV1beta1ExecutionConditions,
} from '@cast/openapi';
import {
  CastaiAutoscalerV1beta1GetClusterWorkloadsResponseWorkload,
  CastaiAutoscalerV1beta1GetClusterWorkloadsResponseWorkloadNode,
  CastaiAutoscalerV1beta1GetClusterWorkloadsResponse,
} from '@cast/openapi';

export type {
  CastaiAutoscalerV1beta1RebalancingPlanResponseConfigurationsConfigurationNode as RebalancingNode,
  CastaiAutoscalerV1beta1RebalancingPlanResponseConfigurationsConfiguration as RebalancingConfiguration,
  CastaiAutoscalerV1beta1RebalancingPlanResponseConfigurations as RebalancingConfigurations,
  CastaiAutoscalerV1beta1RebalancingPlanResponsePlanError as RebalancingPlanError,
  CastaiAutoscalerV1beta1GetClusterWorkloadsResponseWorkloadIssue as WorkloadIssue,
  CastaiAutoscalerV1beta1ListRebalancingPlansResponse as RebalancingPlansResponse,
  CastaiAutoscalerV1beta1RebalancingNode as RebalancingNodeItem,
  CastaiAutoscalerV1beta1RebalancingPlanResponseOperation as RebalancingPlanOperation,
  CastaiAutoscalerV1beta1RebalancingPlanResponse as RebalancingPlan,
  CastaiAutoscalerV1beta1GenerateRebalancingPlanResponse as RebalancingAccepted,
} from '@cast/openapi';

export {
  CastaiAutoscalerV1beta1RebalancingPlanResponseOperationType as RebalancingPlanOperationTypeEnum,
  CastaiAutoscalerV1beta1Status as RebalancingPlanStatusEnum,
  CastaiAutoscalerV1beta1Os as OSEnum,
} from '@cast/openapi';

export type GenerateRebalancingPlanParams = {
  evictGracefully?: boolean | null;
  aggressiveMode?: boolean | null;
  executionConditions?: CastaiAutoscalerV1beta1ExecutionConditions;
  keepDrainTimeoutNodes?: boolean | null;
  minNodes?: number;
  rebalancingNodes?: Array<CastaiAutoscalerV1beta1RebalancingNode>;
};

export enum RebalancingCreateNodeOperationParamsCspEnum {
  aws = 'aws',
  gcp = 'gcp',
  azure = 'azure',
  do = 'do',
}

export enum RebalancingNodeManagedByEnum {
  CASTAI = 'CASTAI',
  EKS = 'EKS',
  GKE = 'GKE',
  AKS = 'AKS',
  KOPS = 'KOPS',
  eks = 'eks',
  gke = 'gke',
  aks = 'aks',
  kops = 'kops',
}

export enum WorkloadCostImpactLevelEnum {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum WorkloadMigrationStatusMigrationStatusEnum {
  ready = 'ready',
  not_ready = 'not-ready',
}

export enum NodeMigrationStatusMigrationStatusEnum {
  ready = 'ready',
  not_ready = 'not-ready',
}

export type WorkloadNode = Omit<
  CastaiAutoscalerV1beta1GetClusterWorkloadsResponseWorkloadNode,
  'status'
> & {
  status?: { migrationStatus?: NodeMigrationStatusMigrationStatusEnum };
};

export type Workload = Omit<
  CastaiAutoscalerV1beta1GetClusterWorkloadsResponseWorkload,
  'status' | 'nodes' | 'costImpact'
> & {
  status?: { migrationStatus?: WorkloadMigrationStatusMigrationStatusEnum };
  nodes?: WorkloadNode[];
  costImpact?: CastaiAutoscalerV1beta1GetClusterWorkloadsResponseWorkload['costImpact'] & {
    level?: WorkloadCostImpactLevelEnum;
  };
};

export type ClusterWorkloads = Omit<
  CastaiAutoscalerV1beta1GetClusterWorkloadsResponse,
  'workloads'
> & {
  workloads?: Workload[];
};
