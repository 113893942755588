import { AxiosError } from 'axios';

export type OpenAiApiErrorDetails = Partial<{
  error: {
    code: string;
    message: string;
    param: string | null;
    type: string;
  };
}>;

export type ApiErrorDetails = Partial<{
  message: string;
  fieldViolations: Array<{
    field: string;
    description: string;
  }>;
}>;

export const isAxiosError = (error: unknown): error is AxiosError => {
  return error instanceof Object && (error as AxiosError).name === 'AxiosError';
};

export const hasErrorDetails = (error: unknown): error is ApiErrorDetails => {
  return (
    error instanceof Object &&
    ('message' in error || 'fieldViolations' in error)
  );
};

export const hasOpenAiErrorDetails = (
  error: unknown
): error is OpenAiApiErrorDetails => {
  if (error instanceof Object && 'error' in error) {
    return error.error instanceof Object && 'message' in error.error;
  }
  return false;
};
