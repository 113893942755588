export const removeHiddenChars = (str: string) => {
  // This regex pattern aims to match non-printable and hidden characters
  // \u0000-\u001f : Control characters
  // \u007f-\u009f : Control characters
  // \u2028-\u2029 : Line and paragraph separators
  // \u200e-\u200f : Left-to-right and right-to-left marks
  // \u202a-\u202e : Bidirectional text control
  // \ufeff : Byte order mark (BOM)
  return str.replace(
    // eslint-disable-next-line no-control-regex
    /[\u0000-\u001F\u007F-\u009F\-\u200F\u202A-\u202E\u2028-\u2029\ufeff]/g,
    ''
  );
};
