import { Stack, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';

import { Select, ListItem } from '@cast/design-system';
import { PriceType } from '@cast/types';

import { fullPriceTypeMap, priceTypeMap } from 'common/maps';

import { useCostOverTimeSection } from '../../hooks/useCostOverTimeSection';

type CostRateMenuItemProps = {
  type: PriceType;
};

const CostRateMenuItem = ({ type }: CostRateMenuItemProps) => {
  return (
    <Stack direction="row" gap="6px">
      <Typography variant="P12R">
        {capitalize(fullPriceTypeMap[type])}
      </Typography>
      <Typography variant="P12R" sx={{ opacity: 0.5 }}>
        /{priceTypeMap[type]}
      </Typography>
    </Stack>
  );
};

const options: PriceType[] = [
  PriceType.HOURLY,
  PriceType.DAILY,
  PriceType.MONTHLY,
];

export const CostRateSelect = () => {
  const { costRate, changeCostRate } = useCostOverTimeSection();

  return (
    <Select
      testId="change-cost-rate"
      value={costRate}
      onChange={(value) => changeCostRate(value)}
      startAdornment={
        <Typography variant="P12R" color="grey.400" noWrap>
          Cost rate:
        </Typography>
      }
      options={options}
      size="small"
      endAdornment={'/' + priceTypeMap[costRate]}
      renderOption={(option, listItemProps) => (
        <ListItem {...listItemProps} component="li">
          <CostRateMenuItem type={option} />
        </ListItem>
      )}
      applySortingWhileSearching={false}
    />
  );
};
