import { AxiosInstance } from 'axios';

import { ReferralEvent } from '@cast/types';

const referralsPortalUrl = 'https://refer.cast.ai';
const postReferralEventEndpoint = '/wp-json/console/v1/ping';

export const ReferralsApiFactory = (client: AxiosInstance) => ({
  postReferralEvent: (form: ReferralEvent) =>
    client.post(postReferralEventEndpoint, form, {
      baseURL: referralsPortalUrl,
      auth: {
        username: '9123ae790b2f5bc92e73a98485695b7e',
        password: 'a70a8c7df43cca6dc16e95680824f584',
      },
    }),
});
