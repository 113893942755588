import { ReactNode, useMemo } from 'react';

import { ValidationErrors } from 'final-form';

import { Cloud, ClusterEditParams } from '@cast/types';
import { makeValidateSync } from '@cast/utils';

import { RffJsonEditor, RffInput, RffTextArea } from 'core/forms/rff';
import { useCluster } from 'hooks/useCluster';
import { mapClusterToCloud } from 'utils/csp';
import {
  AWSARNCredentialsSchema,
  AzureCredentialsSchema,
  GCPCredentialsSchema,
} from 'utils/validators';

export type ManualCredentialsInputFormValue = {
  credentials?: { json?: string };
  eks?: { assumeRoleArn?: string };
};

type UseManualCredentialsInput = {
  validate?: (value: ManualCredentialsInputFormValue) => ValidationErrors;
  getValue: (value: ManualCredentialsInputFormValue) => ClusterEditParams;
  input: ReactNode;
  initialValue: ManualCredentialsInputFormValue;
};

export const useManualCredentialsInput = (): UseManualCredentialsInput => {
  const { cluster } = useCluster();
  const cloud = mapClusterToCloud(cluster);
  return useMemo(() => {
    const getValue = ({
      credentials,
      eks,
    }: ManualCredentialsInputFormValue): ClusterEditParams => {
      if (eks) {
        return {
          eks,
        };
      }

      return {
        credentials: credentials?.json ? credentials.json : '',
      };
    };

    if (cloud === Cloud.aws) {
      return {
        validate: makeValidateSync(AWSARNCredentialsSchema),
        input: (
          <RffInput
            id="aws-role-arn"
            size="medium"
            name="eks.assumeRoleArn"
            placeholder="Role ARN"
            required
            inputProps={{
              'aria-label': 'credentials aws role arn',
            }}
            testId="manual-input"
          />
        ),
        initialValue: { eks: { assumeRoleArn: '' } },
        getValue,
      };
    }
    if (cloud === Cloud.gcp) {
      return {
        validate: makeValidateSync(GCPCredentialsSchema),
        input: (
          <RffJsonEditor
            name="credentials.json"
            rows={5}
            placeholder="Service Account JSON"
          />
        ),
        initialValue: { credentials: { json: '' } },
        getValue,
      };
    }
    if (cloud === Cloud.azure) {
      return {
        validate: makeValidateSync(AzureCredentialsSchema),
        input: (
          <RffTextArea
            autoComplete="off"
            id="credentials-azure"
            name="credentials.json"
            inputProps={{ 'aria-label': 'credentials azure json' }}
            rows={5}
            required
            fullWidth
            placeholder="Credentials JSON"
          />
        ),
        initialValue: { credentials: { json: '' } },
        getValue,
      };
    }
    return {
      input: null,
      initialValue: {},
      getValue,
    };
  }, [cloud]);
};
