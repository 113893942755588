import { AxiosInstance } from 'axios';

import { WorkloadOptimizationAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const WorkloadOptimizationApiAdapter = (client: AxiosInstance) => {
  const generatedApi = WorkloadOptimizationAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  return {
    getAgentStatus: generatedApi.workloadOptimizationAPIGetAgentStatus,
    getInstallAgentCommand: generatedApi.workloadOptimizationAPIGetInstallCmd,
    getWorkloads: generatedApi.workloadOptimizationAPIListWorkloads,
    getWorkload: generatedApi.workloadOptimizationAPIGetWorkload,
    updateWorkload: generatedApi.workloadOptimizationAPIUpdateWorkloadV2,
    getEvents: generatedApi.workloadOptimizationAPIListWorkloadEvents,
    getWorkloadOptimizationPolicies:
      generatedApi.workloadOptimizationAPIListWorkloadScalingPolicies,
    createWorkloadScalingPolicy:
      generatedApi.workloadOptimizationAPICreateWorkloadScalingPolicy,
    deleteWorkloadScalingPolicy:
      generatedApi.workloadOptimizationAPIDeleteWorkloadScalingPolicy,
    assignWorkloadsToPolicy:
      generatedApi.workloadOptimizationAPIAssignScalingPolicyWorkloads,
    updateWorkloadScalingPolicy:
      generatedApi.workloadOptimizationAPIUpdateWorkloadScalingPolicy,
    getWorkloadsOptimizationSummary:
      generatedApi.workloadOptimizationAPIGetWorkloadsSummary,
  };
};
