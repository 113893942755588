import { useMemo } from 'react';

import { Box, Stack, Typography, useTheme } from '@mui/material';

import {
  Alert,
  Button,
  Column,
  ColumnGroup,
  Icons,
  Switch,
  TableHeader,
  Tooltip,
  useDrawerInstance,
} from '@cast/design-system';
import { BestPracticeCheckDetails } from '@cast/types';

import { tableCacheKeys } from 'common/tableCacheKeys';
import { CopyToClipboard } from 'components/clickable-actions';
import { LinkBehaviour } from 'components/common';
import { HexIconNotification } from 'components/hex-icons';
import { StatusMessage } from 'components/messages';
import { Link } from 'components/router';
import {
  ClearAllButton,
  Search,
  SearchableTable,
  SearchableTableRecordsCount,
  SearchProvider,
} from 'components/search';
import { useActiveClusters } from 'features/organization/_hooks';
import {
  useDeleteCheckPolicyEnforcementMutation,
  useEnforceCheckPolicyMutation,
} from 'hooks/mutations/security-insights';
import { useSecurityInsightsAgents } from 'hooks/queries/security-insights';
import { getThemeColor } from 'utils/theme';

import { policiesCriteria } from './constants';
import { PolicyEnforcementItemType } from './types';
import { ClusterSelect } from '../../../../../_components';
import { getOrgSecurityRoutePath } from '../../../../../navigation';

type Props = {
  check?: BestPracticeCheckDetails;
  onAffectedClick: (clusterId: string) => void;
  onExceptedClick: (clusterId: string) => void;
};

export const PolicyEnforcement = ({
  check,
  onAffectedClick,
  onExceptedClick,
}: Props) => {
  const theme = useTheme();
  const { close } = useDrawerInstance();
  const { clusters } = useActiveClusters();
  const clusterIds = useMemo(
    () => clusters?.map((cluster) => cluster.id) || [],
    [clusters]
  );
  const { agentsData, isLoading: isLoadingAgents } = useSecurityInsightsAgents({
    clusterIds,
  });

  const { mutate: enableEnforcement } = useEnforceCheckPolicyMutation();
  const { mutate: deleteEnforcement } =
    useDeleteCheckPolicyEnforcementMutation();

  const data = useMemo(() => {
    return clusters
      .map((cluster): PolicyEnforcementItemType => {
        return {
          clusterId: cluster.id,
          clusterName: cluster.name,
          policyEnforcementEnabled:
            !!agentsData?.statuses?.[cluster.id].policyEnforcement,
          providerNamespaceId: cluster.providerNamespaceId,
          enforcementId: check?.enforcedOn?.find(
            (o) => o.clusterId === cluster.id
          )?.enforcementId,
          affected: 0, // FIXME: temp fix
          excepted: 0, // FIXME: temp fix
        };
      })
      .filter((item) => item.policyEnforcementEnabled);
  }, [clusters, agentsData?.statuses, check?.enforcedOn]);

  if (!check?.enforceable) {
    return (
      <Stack>
        <StatusMessage
          icon={
            <HexIconNotification
              iconSize={72}
              icon={
                <Icons.Warning
                  weight="fill"
                  color={getThemeColor(theme, 'grey.500')}
                />
              }
            />
          }
          title="This policy cannot be enforced"
          body="Checks unrelated to workloads cannot be enforced."
          sx={{ mt: 24 }}
        />
      </Stack>
    );
  }

  return (
    <SearchProvider
      criteria={policiesCriteria}
      data={data}
      isLoading={isLoadingAgents}
    >
      <Stack
        flexGrow={1}
        minHeight={0}
        data-testid="policy-enforcement-tab-content"
      >
        {!data.length && (
          <Alert
            size="small"
            variant="filled"
            color="advisory"
            noIcon
            sx={{ mb: 24 }}
            testId="no-clusters-with-policy-enforcement-alert"
          >
            <div>
              Use the{' '}
              <LinkBehaviour
                component={Link}
                to={getOrgSecurityRoutePath('settings')}
                onClick={() => close()}
                sx={{ textDecoration: 'none' }}
              >
                settings page
              </LinkBehaviour>{' '}
              to manage policy enforcement for clusters, enabling or disabling
              this feature as necessary. Only clusters with this feature
              installed are listed below.
            </div>
          </Alert>
        )}
        <Stack direction="row" spacing={8} mb={8}>
          <Search />
          <ClusterSelect criterion="clusterId" sx={{ width: '50%' }} />
          <ClearAllButton />
        </Stack>
        <Box flexGrow={1} flexShrink={1} mx={-32} overflow="hidden">
          <SearchableTable
            cacheKey={tableCacheKeys.SECURITY.POLICY_ENFORCEMENT}
            fillParentHeight
            testId="policy-enforcement-table"
            components={{
              noData: (
                <StatusMessage
                  icon={
                    <HexIconNotification
                      iconSize={72}
                      icon={
                        <Icons.ToggleLeft
                          weight="fill"
                          color={getThemeColor(theme, 'grey.500')}
                        />
                      }
                    />
                  }
                  title="None of the clusters have this feature installed"
                  body="Please go to security settings to enable policy enforcement for the clusters"
                  actions={
                    <Button
                      variant="text"
                      endIcon={<Icons.CaretRight />}
                      component={Link}
                      to={getOrgSecurityRoutePath('settings')}
                      onClick={() => close()}
                    >
                      Go to settings
                    </Button>
                  }
                  sx={{ mt: 24 }}
                />
              ),
            }}
            outerHeader={
              <TableHeader
                recordsCount={<SearchableTableRecordsCount title="cluster" />}
                px={32}
              />
            }
          >
            <Column
              id="clusterId"
              header="id"
              width={76}
              cellSx={{ ml: 20 }}
              renderCell={({ clusterId }: PolicyEnforcementItemType) => (
                <CopyToClipboard text={clusterId} iconSize="20px" />
              )}
            />
            <Column
              id="clusterName"
              header="Name"
              minWidth={150}
              renderCell={({
                clusterName,
                clusterId,
                providerNamespaceId,
              }: PolicyEnforcementItemType) => (
                <Stack>
                  <LinkBehaviour
                    component={Link}
                    aria-label="cluster name"
                    variant="P12M"
                    noWrap
                    sx={{ textDecoration: 'none' }}
                    to={`/external-clusters/${clusterId}`}
                  >
                    {clusterName}
                  </LinkBehaviour>
                  {providerNamespaceId && (
                    <Typography variant="P10R" color="grey.400" noWrap>
                      {providerNamespaceId}
                    </Typography>
                  )}
                </Stack>
              )}
              sortable
              resizable
            />
            <Column
              id="policyEnforcement"
              header="Policy enforcement"
              minWidth={165}
              sortable
              accessor={({ enforcementId }: PolicyEnforcementItemType) =>
                !!enforcementId
              }
              renderCell={({
                clusterId,
                enforcementId,
                affected,
                excepted,
              }: PolicyEnforcementItemType) => {
                const isDisabled = !!affected || !!excepted;
                const switchComponent = (
                  <Switch
                    checked={!!enforcementId}
                    disabled={isDisabled}
                    onChange={(_, checked) =>
                      checked
                        ? enableEnforcement({
                            ruleId: check?.ruleId ?? '',
                            clusterIds: [clusterId],
                          })
                        : deleteEnforcement({
                            enforcementId: enforcementId ?? '',
                          })
                    }
                    size="small"
                  />
                );
                return (
                  <>
                    {isDisabled ? (
                      <Tooltip
                        title="Before enabling the feature, ensure that all resources comply with the policy within the cluster, that is, there are zero affected/excepted resources."
                        arrow
                      >
                        <div>{switchComponent}</div>
                      </Tooltip>
                    ) : (
                      switchComponent
                    )}
                  </>
                );
              }}
            />
            <ColumnGroup id="resources" title="RESOURCES">
              <Column
                id="affected"
                header="Affected"
                width={120}
                sortable
                renderCell={({
                  affected,
                  clusterId,
                }: PolicyEnforcementItemType) => (
                  <LinkBehaviour onClick={() => onAffectedClick(clusterId)}>
                    {affected}
                  </LinkBehaviour>
                )}
              />
              <Column
                id="excepted"
                header="Excepted"
                width={120}
                sortable
                renderCell={({
                  excepted,
                  clusterId,
                }: PolicyEnforcementItemType) => (
                  <LinkBehaviour onClick={() => onExceptedClick(clusterId)}>
                    {excepted}
                  </LinkBehaviour>
                )}
              />
            </ColumnGroup>
          </SearchableTable>
        </Box>
      </Stack>
    </SearchProvider>
  );
};
