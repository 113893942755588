import { useContext } from 'react';

import { styled } from '@mui/material';
import MUIListItemIcon, {
  ListItemIconProps as MuiListItemIconProps,
} from '@mui/material/ListItemIcon';

import { ListContext, ListProps } from './List';
import { IconContext } from '../icons';

type OwnerState = {
  size: ListProps['size'];
};

type ListItemIconRootProps = MuiListItemIconProps & {
  ownerState: OwnerState;
};

const ListItemIconRoot = styled(MUIListItemIcon, {
  name: 'DsListItemIcon',
  slot: 'Root',
  target: 'DsListItemIcon-root',
  overridesResolver: ({ ownerState }, theme) => {
    return [theme.root, theme[ownerState.size]];
  },
})<ListItemIconRootProps>({});

export type ListItemIconProps = MuiListItemIconProps;

export const ListItemIcon = (props: ListItemIconProps) => {
  const listContext = useContext(ListContext);
  const ownerState: OwnerState = { size: listContext?.size ?? 'medium' };

  return (
    <IconContext.Provider
      value={{
        weight: 'light',
        size: listContext?.size === 'small' ? '16px' : '20px',
      }}
    >
      <ListItemIconRoot ownerState={ownerState} {...props}>
        {props.children}
      </ListItemIconRoot>
    </IconContext.Provider>
  );
};
