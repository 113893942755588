import { TourStepProps } from 'core/tour';

export const vulnerabilityManagementTourId = 'image-security';

export const enum VulnerabilityManagementTourStepsIds {
  summary = 'summary',
  imagesTable = 'images-table',
  activateButton = 'activate-button',
}

export const vulnerabilityManagementTourSteps: {
  [key in keyof typeof VulnerabilityManagementTourStepsIds]: TourStepProps;
} = {
  summary: {
    type: vulnerabilityManagementTourId,
    step: VulnerabilityManagementTourStepsIds.summary,
    text: 'Vulnerabilities found in the running images are ranked.',
    placement: 'top',
    highlight: 'shadow',
  },
  imagesTable: {
    type: vulnerabilityManagementTourId,
    step: VulnerabilityManagementTourStepsIds.imagesTable,
    text: 'Each container image can be investigated further to clearly identify where vulnerabilities lie.',
    placement: 'right',
    highlight: 'bubble',
    beaconPosition: { top: 130, left: 315 },
    boxProps: { zIndex: 1 },
  },
  activateButton: {
    type: vulnerabilityManagementTourId,
    step: VulnerabilityManagementTourStepsIds.activateButton,
    text: 'Exceptions can be applied to known and acceptable vulnerabilities.',
    placement: 'bottom-end',
    highlight: 'bubble',
    beaconPosition: { top: 25, left: 112 },
    sx: { display: 'none' },
  },
};

export const vulnerabilityManagementTourStepsOrder = [
  VulnerabilityManagementTourStepsIds.summary,
  VulnerabilityManagementTourStepsIds.imagesTable,
  VulnerabilityManagementTourStepsIds.activateButton,
];
