import { ThemeOptions } from '@mui/material';

import { cssVars } from '../../../utils/cssVars';

const { getters, setValues } = cssVars(
  {
    size: undefined,
  },
  'ds-chart-indicator'
);

export const chartIndicatorTheme: ThemeOptions['components'] = {
  DsChartIndicator: {
    defaultProps: {
      size: 'small',
      variant: 'filled',
    },
    styleOverrides: {
      small: setValues({
        size: '8px',
      }),
      large: setValues({
        size: '12px',
      }),
      root: {
        display: 'inline-flex',
        borderRadius: '2px',
        width: getters.size(),
        height: getters.size(),
      },
    },
  },
};
