import { createContext } from 'react';

import { AssignCredentialsDialogSlide } from './types';

export const AssignCredentialsDialogContext = createContext<{
  dismiss: () => void;
  setActiveSlide: (slide: AssignCredentialsDialogSlide) => void;
  includeAutoscaler: boolean;
  setIncludeAutoscaler: (include: boolean) => void;
  includeSecurity: boolean;
  setIncludeSecurity: (include: boolean) => void;
}>(undefined as never);
