import intersectionWith from 'lodash/intersectionWith';

import { AgentStatus, Cluster } from '@cast/types';

export const getReconnectCluster = (
  previousClusters: Cluster[],
  clusters: Cluster[]
) => {
  return intersectionWith(
    clusters,
    previousClusters,
    (a: Cluster, b: Cluster) => {
      return (
        a.id === b.id &&
        b.agentStatus === AgentStatus.DISCONNECTED &&
        a.agentStatus === AgentStatus.ONLINE
      );
    }
  );
};
