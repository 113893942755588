import { withProps } from '@cast/design-system';
import { Linkify as _Linkify } from '@cast/utils';

import { ExternalLink } from './ExternalLink';

export const Linkify = withProps(_Linkify, {
  componentDecorator: (href: string, text: string, key: number) => (
    <ExternalLink link={href} key={key}>
      {text}
    </ExternalLink>
  ),
});
