import { ModalProvider } from 'mui-modal-provider';
import { Routes, Route } from 'react-router-dom';

import { ErrorPage } from './ErrorPage';

export const AuthController = () => {
  return (
    <ModalProvider>
      <Routes>
        <Route path="error" Component={ErrorPage} />
      </Routes>
    </ModalProvider>
  );
};
