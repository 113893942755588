import { useCallback, useMemo } from 'react';

import { pseudoUnique } from '@cast/utils';

import { FooterRow } from '../_components/FooterRow';
import { HeaderRow } from '../_components/HeaderRow';
import { Sorter as DefaultSorter } from '../_components/Sorter';
import { makeTreeUiUtils } from '../_utils';
import {
  InfoIcon as DefaultInfoIcon,
  ResizerIcon as DefaultResizerIcon,
  SortIcon as DefaultSortIcon,
} from '../components';
import { BodyRow } from '../components/BodyRow';
import { defaultTreeUiConfig, tableMeasurements } from '../constants';
import { RowSkeleton } from '../data-table/RowSkeleton';
import { LogPayload, NormalizedTableProps, TableProps } from '../types';

const defaultLogger = ({ message, data, logLevel }: LogPayload) => {
  // eslint-disable-next-line no-console
  let method = console.log;
  switch (logLevel) {
    case 'error':
      method = console.error;
      break;
    case 'warning':
      method = console.warn;
      break;
    case 'info':
      method = console.info;
      break;
  }
  method(`[DS-Table] ${message}`, data);
};

export const useTableProps = (props: TableProps): NormalizedTableProps => {
  const {
    rowKey: _rowKey,
    Sorter,
    SortIcon,
    ResizerIcon,
    InfoIcon,
    HeaderRowComponent,
    BodyRowComponent,
    FooterRowComponent,
    childRows: _childRows,
  } = props;
  const rowKey = useMemo(() => {
    if (!_rowKey) {
      return (row: any) => row.id;
    } else if (typeof _rowKey === 'function') {
      return _rowKey;
    }
    return (row: any) => row[_rowKey];
  }, [_rowKey]);
  const sizing = props.sizing || 'l';
  const rowHeight = props.rowHeight || tableMeasurements[sizing].rowHeight;
  const treeUiConfig = props.treeTable
    ? props.treeUiConfig || defaultTreeUiConfig
    : undefined;

  const [sysId] = useMemo(
    () => props.testId || String(pseudoUnique()),
    [props.testId]
  );

  if (props.treeTable && !_childRows) {
    throw new Error('Tree table must be provided with "childRows" prop');
  }

  const log = useCallback(
    (payload: LogPayload) => {
      props.log?.(payload);
      defaultLogger(payload);
    },
    [props]
  );

  return {
    ...props,
    rowKey,
    sizing,
    Sorter: Sorter || DefaultSorter,
    SortIcon: SortIcon || DefaultSortIcon,
    ResizerIcon: ResizerIcon || DefaultResizerIcon,
    InfoIcon: InfoIcon || DefaultInfoIcon,
    HeaderRowComponent: HeaderRowComponent || HeaderRow,
    BodyRowComponent: BodyRowComponent || BodyRow,
    FooterRowComponent: FooterRowComponent || FooterRow,
    overscan: props.overscan ?? 500,
    rowHeight,
    treeUiConfig,
    treeUiUtils: props.treeTable
      ? props.treeUiUtils || makeTreeUiUtils(treeUiConfig!)
      : undefined,
    childRows: props.childRows,
    loadingRowSkeleton: <RowSkeleton height="var(--rowHeight)" />,
    testId: props.testId || 'ds-table',
    log,
    loadingMoreText: props.loadingMoreText,
    // Needed for testing driver to support nested tables - to not include child table's body rows
    sysId: props.testId || sysId,
  };
};
