import { ComponentType } from 'react';

import { styled } from '@mui/material';
import clsx from 'clsx';

import { Icons } from '@cast/design-system';
import { Tendency } from '@cast/types';

import { TendencyStableIcon } from '../TendencyStableIcon';

export type TendencyIconProps = {
  variant: 'arrow' | 'caret';
  className?: string;
  tendency: Tendency;
  size?: number;
  invertColors?: boolean;
};

const arrowMap: Record<Tendency, ComponentType<{ className: string }>> = {
  up: ({ className }) => <Icons.ArrowUpRight className={className} />,
  down: ({ className }) => <Icons.ArrowDownRight className={className} />,
  stable: TendencyStableIcon,
};

const caretMap: Record<Tendency, ComponentType<{ className: string }>> = {
  up: ({ className }) => <Icons.CaretUp className={className} weight="fill" />,
  down: ({ className }) => (
    <Icons.CaretDown className={className} weight="fill" />
  ),
  stable: ({ className }) => <Icons.Minus className={className} />,
};

export const TendencyIcon = styled(
  ({ tendency, className, invertColors, variant }: TendencyIconProps) => {
    const Icon = variant === 'arrow' ? arrowMap[tendency] : caretMap[tendency];
    if (Icon) {
      return (
        <Icon
          className={clsx(
            className,
            tendency,
            invertColors && 'TendencyIcon-invertColors'
          )}
        />
      );
    }
    return null;
  }
)(({ theme, size = 14 }) => ({
  fontSize: size,
  '&.up': {
    color: theme.palette.green[500],
  },
  '&.down': {
    color: theme.palette.red[500],
  },
  '&.TendencyIcon-invertColors': {
    '&.up': {
      color: theme.palette.red[500],
    },
    '&.down': {
      color: theme.palette.green[500],
    },
  },
  '&.stable': {
    color: theme.palette.grey[200],
  },
}));
TendencyIcon.displayName = 'TendencyIcon';
