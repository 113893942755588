import { forwardRef, useContext } from 'react';

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Box, SxProps } from '@mui/material';

import { setRef } from '@cast/utils';

import { SettingsRow } from './SettingsRow';
import { ColumnsGroup } from '../_hooks/useSettingsDrawerState';
import { SettingsDrawerContext } from '../context';
import { SettingsRowModel } from '../types';

type Props = { rows: SettingsRowModel[]; sx?: SxProps; group: ColumnsGroup };

export const DraggableRows = forwardRef<HTMLDivElement, Props>(
  ({ rows, sx, group }, outerRef) => {
    const { reorderColumns } = useContext(SettingsDrawerContext);
    return (
      <DragDropContext
        onDragEnd={({ destination, draggableId }) => {
          if (!destination) {
            return;
          }
          reorderColumns({
            id: draggableId,
            newIndex: destination.index,
            group,
          });
        }}
      >
        <Droppable
          droppableId="table-settings"
          mode="standard"
          renderClone={(state, _, rubric) => {
            const style = state.draggableProps.style;
            if (style?.transform) {
              // Do not allow horizontal drag
              style.transform = style.transform.replace(
                /translate\(([^,]+),/,
                'translate(0,'
              );
            }
            return (
              <Box
                sx={{
                  '& > div': {
                    boxShadow:
                      '0px 2px 4px 0px rgba(82, 148, 247, 0.25), 0px -2px 4px 0px rgba(82, 148, 247, 0.15)',
                    borderTop: 'blue.500',
                    borderBottom: 'blue.500',
                    backgroundColor: 'white',
                  },
                }}
              >
                <SettingsRow
                  dragHandleProps={state.dragHandleProps || undefined}
                  dragContainerProps={state.draggableProps}
                  dragRef={state.innerRef}
                  column={rows[rubric.source.index]}
                />
              </Box>
            );
          }}
          ignoreContainerClipping
        >
          {(state) => (
            <Box
              ref={(ref: HTMLDivElement) => {
                state.innerRef(ref);
                setRef(outerRef, ref);
              }}
              sx={sx}
            >
              {rows.map((groupedProps, index) => {
                return (
                  <Draggable
                    key={groupedProps.id}
                    draggableId={groupedProps.id}
                    index={index}
                    isDragDisabled={!groupedProps.visible}
                  >
                    {(state) => {
                      return (
                        <SettingsRow
                          dragHandleProps={state.dragHandleProps || undefined}
                          dragContainerProps={state.draggableProps}
                          dragRef={state.innerRef}
                          column={groupedProps}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

DraggableRows.displayName = 'DraggableRows';
