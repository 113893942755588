import { PropsWithChildren } from 'react';

import { useAbility } from './hooks/useAbility';
import { Actions, Subjects } from './types';

export type AbilityGuardProps = PropsWithChildren<{
  action: Actions;
  subject: Subjects;
}>;

export const AbilityGuard = ({
  action,
  subject,
  children,
}: AbilityGuardProps) => {
  const ability = useAbility();

  if (ability.cannot(action, subject)) {
    return null;
  }

  return <>{children}</>;
};
