/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CastaiUsageV1beta1GetUsageReportResponse } from '../models';
// @ts-ignore
import { CastaiUsageV1beta1GetUsageSummaryResponse } from '../models';
/**
 * UsageAPIApi - axios parameter creator
 * @export
 */
export const UsageAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary [Deprecated] Use /v1/billing/usage-report instead.
         * @param {string} [filterPeriodFrom] Start time of resource usage period.
         * @param {string} [filterPeriodTo] End time of resource usage period.
         * @param {string} [filterClusterId] Optional cluster id for usage filtering
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usageAPIGetUsageReport: async (filterPeriodFrom?: string, filterPeriodTo?: string, filterClusterId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/report/usage/daily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterPeriodFrom !== undefined) {
                localVarQueryParameter['filter.period.from'] = (filterPeriodFrom as any instanceof Date) ?
                    (filterPeriodFrom as any).toISOString() :
                    filterPeriodFrom;
            }

            if (filterPeriodTo !== undefined) {
                localVarQueryParameter['filter.period.to'] = (filterPeriodTo as any instanceof Date) ?
                    (filterPeriodTo as any).toISOString() :
                    filterPeriodTo;
            }

            if (filterClusterId !== undefined) {
                localVarQueryParameter['filter.clusterId'] = filterClusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/billing/usage-report instead.
         * @param {string} [periodFrom] Start time of resource usage period.
         * @param {string} [periodTo] End time of resource usage period.
         * @param {string} [clusterId] Optional cluster id for which avg would be calculated.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usageAPIGetUsageSummary: async (periodFrom?: string, periodTo?: string, clusterId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/report/usage/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (periodFrom !== undefined) {
                localVarQueryParameter['period.from'] = (periodFrom as any instanceof Date) ?
                    (periodFrom as any).toISOString() :
                    periodFrom;
            }

            if (periodTo !== undefined) {
                localVarQueryParameter['period.to'] = (periodTo as any instanceof Date) ?
                    (periodTo as any).toISOString() :
                    periodTo;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsageAPIApi - functional programming interface
 * @export
 */
export const UsageAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsageAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary [Deprecated] Use /v1/billing/usage-report instead.
         * @param {string} [filterPeriodFrom] Start time of resource usage period.
         * @param {string} [filterPeriodTo] End time of resource usage period.
         * @param {string} [filterClusterId] Optional cluster id for usage filtering
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async usageAPIGetUsageReport(filterPeriodFrom?: string, filterPeriodTo?: string, filterClusterId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsageV1beta1GetUsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usageAPIGetUsageReport(filterPeriodFrom, filterPeriodTo, filterClusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsageAPIApi.usageAPIGetUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/billing/usage-report instead.
         * @param {string} [periodFrom] Start time of resource usage period.
         * @param {string} [periodTo] End time of resource usage period.
         * @param {string} [clusterId] Optional cluster id for which avg would be calculated.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async usageAPIGetUsageSummary(periodFrom?: string, periodTo?: string, clusterId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiUsageV1beta1GetUsageSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usageAPIGetUsageSummary(periodFrom, periodTo, clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsageAPIApi.usageAPIGetUsageSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsageAPIApi - factory interface
 * @export
 */
export const UsageAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsageAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary [Deprecated] Use /v1/billing/usage-report instead.
         * @param {UsageAPIApiUsageAPIGetUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usageAPIGetUsageReport(requestParameters: UsageAPIApiUsageAPIGetUsageReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsageV1beta1GetUsageReportResponse> {
            return localVarFp.usageAPIGetUsageReport(requestParameters.filterPeriodFrom, requestParameters.filterPeriodTo, requestParameters.filterClusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/billing/usage-report instead.
         * @param {UsageAPIApiUsageAPIGetUsageSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        usageAPIGetUsageSummary(requestParameters: UsageAPIApiUsageAPIGetUsageSummaryRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiUsageV1beta1GetUsageSummaryResponse> {
            return localVarFp.usageAPIGetUsageSummary(requestParameters.periodFrom, requestParameters.periodTo, requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usageAPIGetUsageReport operation in UsageAPIApi.
 * @export
 * @interface UsageAPIApiUsageAPIGetUsageReportRequest
 */
export interface UsageAPIApiUsageAPIGetUsageReportRequest {
    /**
     * Start time of resource usage period.
     * @type {string}
     * @memberof UsageAPIApiUsageAPIGetUsageReport
     */
    readonly filterPeriodFrom?: string

    /**
     * End time of resource usage period.
     * @type {string}
     * @memberof UsageAPIApiUsageAPIGetUsageReport
     */
    readonly filterPeriodTo?: string

    /**
     * Optional cluster id for usage filtering
     * @type {string}
     * @memberof UsageAPIApiUsageAPIGetUsageReport
     */
    readonly filterClusterId?: string
}

/**
 * Request parameters for usageAPIGetUsageSummary operation in UsageAPIApi.
 * @export
 * @interface UsageAPIApiUsageAPIGetUsageSummaryRequest
 */
export interface UsageAPIApiUsageAPIGetUsageSummaryRequest {
    /**
     * Start time of resource usage period.
     * @type {string}
     * @memberof UsageAPIApiUsageAPIGetUsageSummary
     */
    readonly periodFrom?: string

    /**
     * End time of resource usage period.
     * @type {string}
     * @memberof UsageAPIApiUsageAPIGetUsageSummary
     */
    readonly periodTo?: string

    /**
     * Optional cluster id for which avg would be calculated.
     * @type {string}
     * @memberof UsageAPIApiUsageAPIGetUsageSummary
     */
    readonly clusterId?: string
}

/**
 * UsageAPIApi - object-oriented interface
 * @export
 * @class UsageAPIApi
 * @extends {BaseAPI}
 */
export class UsageAPIApi extends BaseAPI {
    /**
     * 
     * @summary [Deprecated] Use /v1/billing/usage-report instead.
     * @param {UsageAPIApiUsageAPIGetUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UsageAPIApi
     */
    public usageAPIGetUsageReport(requestParameters: UsageAPIApiUsageAPIGetUsageReportRequest = {}, options?: RawAxiosRequestConfig) {
        return UsageAPIApiFp(this.configuration).usageAPIGetUsageReport(requestParameters.filterPeriodFrom, requestParameters.filterPeriodTo, requestParameters.filterClusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use /v1/billing/usage-report instead.
     * @param {UsageAPIApiUsageAPIGetUsageSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UsageAPIApi
     */
    public usageAPIGetUsageSummary(requestParameters: UsageAPIApiUsageAPIGetUsageSummaryRequest = {}, options?: RawAxiosRequestConfig) {
        return UsageAPIApiFp(this.configuration).usageAPIGetUsageSummary(requestParameters.periodFrom, requestParameters.periodTo, requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }
}

