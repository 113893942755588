import { useEffect, useMemo, useRef } from 'react';

import { Stack, Box } from '@mui/material';
import dayjs from 'dayjs';
import { DayButtonProps } from 'react-day-picker';

import { useDatePickerContext } from './useDatePickerContext';
import { withProps } from '../../../utils';

const EventMarker = withProps(Box, {
  bgcolor: ' green.400',
  width: 5,
  height: 5,
  border: '1px solid white',
  borderRadius: '50%',
});

export const DayWithEvents = (props: DayButtonProps) => {
  const { markedDates } = useDatePickerContext();
  const { day, modifiers, ...buttonProps } = props;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (modifiers.focused) ref.current?.focus();
  }, [modifiers.focused]);

  const filteredMarkers = useMemo(() => {
    return (
      markedDates?.filter((m) => dayjs(m.date).isSame(day.date, 'day')) ?? []
    );
  }, [markedDates, day.date]);

  if (!filteredMarkers.length) {
    return <button ref={ref} {...buttonProps} />;
  }

  const moreThenTwoMarkers = filteredMarkers.length > 2;

  return (
    <button ref={ref} {...buttonProps}>
      <Stack width={32} height={32} alignItems="center" mt={14}>
        {day.dateLib.format(day.date, 'd')}
        <Stack gap={moreThenTwoMarkers ? 0 : 4} direction="row">
          {filteredMarkers.map((m, index) => (
            <EventMarker
              key={index}
              ml={moreThenTwoMarkers ? -3 : 0}
              {...m.markerProps}
            />
          ))}
        </Stack>
      </Stack>
    </button>
  );
};
