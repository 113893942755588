import { PropsWithChildren, ReactNode } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import { DialogActions, DialogContent, Icons } from '@cast/design-system';

import { CloseDialogButton } from 'components/dialogs';
import { HexIconError } from 'components/hex-icons';

type ContentProps = PropsWithChildren<{
  title: string;
  actions: ReactNode;
  onClose?: () => void;
}>;

export const ClusterRemovalDialogContent = ({
  onClose,
  title,
  actions,
  children,
}: ContentProps) => {
  return (
    <>
      <CloseDialogButton onClose={onClose} />

      <DialogContent sx={{ p: 24 }}>
        <Stack direction="row" alignItems="flex-start" gap={8}>
          <HexIconError
            display="flex"
            alignSelf="flex-start"
            size={40}
            animationType="pulsateSlow"
            icon={<Icons.TrashSimple size={16} weight="fill" />}
            sx={{ mt: -4 }}
          />

          <Stack>
            <Typography variant="h4" mb={8}>
              {title}
            </Typography>
            {children}
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: '16px 32px' }}>{actions}</DialogActions>
    </>
  );
};
