import { useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import isFinite from 'lodash/isFinite';

import {
  DropdownProps,
  DsFormHelperText,
  DsFormLabel,
  Input,
  InputProps,
  mergeSx,
  Select,
} from '@cast/design-system';

import { TimeUnit } from './types';

const MULTIPLIERS = {
  [TimeUnit.SECOND]: {
    [TimeUnit.SECOND]: 1,
    [TimeUnit.MINUTE]: 60,
    [TimeUnit.HOUR]: 60 * 60,
    [TimeUnit.DAY]: 60 * 60 * 24,
  },
  [TimeUnit.MINUTE]: {
    [TimeUnit.SECOND]: 1 / 60,
    [TimeUnit.MINUTE]: 1,
    [TimeUnit.HOUR]: 60,
    [TimeUnit.DAY]: 60 * 24,
  },
  [TimeUnit.HOUR]: {
    [TimeUnit.SECOND]: 1 / (60 * 60),
    [TimeUnit.MINUTE]: 1 / 60,
    [TimeUnit.HOUR]: 1,
    [TimeUnit.DAY]: 24,
  },
  [TimeUnit.DAY]: {
    [TimeUnit.SECOND]: 1 / (60 * 60 * 24),
    [TimeUnit.MINUTE]: 1 / (60 * 60),
    [TimeUnit.HOUR]: 1 / 24,
    [TimeUnit.DAY]: 1,
  },
};

export const UNIT_NAMES = {
  [TimeUnit.SECOND]: 'Seconds',
  [TimeUnit.MINUTE]: 'Minutes',
  [TimeUnit.HOUR]: 'Hours',
  [TimeUnit.DAY]: 'Days',
};

const getMultiplier = (
  timeUnit: `${TimeUnit}`,
  time: number,
  allowedTimeUnits: TimeUnit[],
  initialTimeUnit?: `${TimeUnit}`
) => {
  const multipliers = MULTIPLIERS[timeUnit];
  if (initialTimeUnit) {
    return multipliers[initialTimeUnit];
  }
  for (const [unit, multiplier] of Object.entries(multipliers).reverse()) {
    if (!allowedTimeUnits.includes(unit as TimeUnit)) {
      continue;
    }
    if (time % multiplier === 0) {
      return multiplier;
    }
  }
  return multipliers[allowedTimeUnits[0]];
};

export type TimeInputProps = {
  timeUnit: `${TimeUnit}`;
  initialUnit?: `${TimeUnit}`;
  value: number;
  onChange: (time?: number | undefined) => void;
  allowedTimeUnits: `${TimeUnit}`[];
  disabled?: boolean;
  hint?: string;
  error?: string;
  hintAbsolute?: boolean;
  InputProps?: InputProps;
  SelectProps?: Partial<DropdownProps>;
  readOnly?: boolean;
  label?: string;
  sx?: SxProps;
  testId?: string;
};

export const TimeInput = ({
  timeUnit,
  initialUnit,
  value,
  onChange,
  allowedTimeUnits,
  disabled,
  hint,
  error,
  hintAbsolute,
  InputProps = {},
  SelectProps = {},
  readOnly,
  label,
  sx,
  testId = 'time-input',
}: TimeInputProps) => {
  const [multiplier, setMultiplier] = useState(() =>
    getMultiplier(timeUnit, value, allowedTimeUnits as TimeUnit[], initialUnit)
  );

  const inputValue = useMemo(() => {
    return value ? value / multiplier : value;
  }, [value, multiplier]);

  const options = useMemo(() => {
    return allowedTimeUnits.map((unit) => ({
      unit,
      multiplier: MULTIPLIERS[timeUnit][unit],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...allowedTimeUnits, timeUnit]);

  return (
    <Stack sx={sx} data-testid={testId}>
      <DsFormLabel disabled={disabled} testId="time-input-label">
        {label}
      </DsFormLabel>
      <Stack direction="row" gap={8}>
        <Input
          type="number"
          InputProps={{
            error: !!error,
            disabled,
            value: inputValue,
            onFocus: (e) => e.target.select(),
          }}
          onChange={(_: any, value: string) => {
            if (value == null) {
              onChange(undefined);
              return;
            }
            const num = Number(value);
            if (!isFinite(num)) {
              onChange(0);
            } else {
              onChange(num * multiplier);
            }
          }}
          readOnly={readOnly}
          testId="time-input-numeric-input"
          {...InputProps}
          sx={mergeSx({ width: 69 }, InputProps?.sx)}
        />
        <Select
          testId="time-input-unit-select"
          disabled={disabled}
          inputProps={{ error, readOnly }}
          value={multiplier as any}
          options={options}
          isOptionEqualToValue={(option, value) => {
            return (
              option.multiplier ===
              (typeof value === 'number' ? value : value.multiplier)
            );
          }}
          optionLabel={(option) => {
            if (typeof option === 'number') {
              return UNIT_NAMES[
                options.find((o) => o.multiplier === option)
                  ?.unit as keyof typeof UNIT_NAMES
              ];
            }
            return UNIT_NAMES[option.unit as keyof typeof UNIT_NAMES];
          }}
          onChange={({ multiplier }) => {
            setMultiplier(multiplier);

            if (typeof inputValue === 'number') {
              onChange(inputValue * multiplier);
            }
          }}
          sx={mergeSx({ width: 180 }, SelectProps?.sx)}
          {...SelectProps}
        />
      </Stack>
      {hint && (
        <DsFormHelperText
          error={!!error}
          disabled={disabled}
          hintAbsolute={hintAbsolute}
        >
          {hint || error}
        </DsFormHelperText>
      )}
    </Stack>
  );
};
