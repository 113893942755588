import { ReactNode, useMemo } from 'react';

import { capitalize, styled } from '@mui/material';

import { JsonBlock } from './components/JsonBlock/JsonBlock';
import { PreviewJsonProps } from './types';
import { shouldForwardProp } from '../../../utils/shouldForwardProp';
import { Scroller } from '../../scroller';
import { PreviewStateProvider } from '../_providers/PreviewStateProvider';
import { encodeClassName } from '../utils';

const StyledScroller = styled(Scroller, {
  name: 'DsPreviewJson',
  slot: 'Scroller',
  target: 'DsPreviewJson-Scroller os-theme-light',
  shouldForwardProp,
  overridesResolver: (_, styles) => styles.scroller,
})<{ ownerState: PreviewJsonProps }>({});

const SyntaxHighlight = styled('div', {
  name: 'DsPreviewJson',
  slot: 'SyntaxHighlight',
  target: 'DsPreviewJson-SyntaxHighlight',
  shouldForwardProp,
  overridesResolver: ({ ownerState }, styles) => [
    styles.syntaxHighlight,
    styles[`size${capitalize(ownerState.size)}`],
  ],
})<{ ownerState: PreviewJsonProps & { errorMarker?: string } }>({});

export const JsonContainer = (props: PreviewJsonProps) => {
  const { containerSx, children, indentBy } = props;
  let { data, markError = [] } = props;

  if (data === 'null') {
    data = '{}';
  }
  data = typeof data === 'string' ? JSON.parse(data) : data;

  let content: ReactNode | null;

  if (Array.isArray(data) || typeof data === 'object') {
    content = <JsonBlock data={data} level={0} classPrefix="$" />;
  } else if (typeof data === 'function') {
    throw new Error('Cannot display function as json');
  }

  if (typeof markError === 'string') {
    markError = [markError];
  }
  const errorMarker = markError.reduce((acc, curr) => {
    return (
      acc +
      `${acc.length ? ', ' : ''}& .${encodeClassName(
        curr
      )}.DsPreviewJson-BlockContainer-root:before`
    );
  }, '');
  const jsonText = useMemo(
    () => (data ? JSON.stringify(data, null, indentBy) : undefined),
    [data, indentBy]
  );

  if (content) {
    return (
      <PreviewStateProvider formattedCode={jsonText}>
        {children ? (
          typeof children === 'function' ? (
            <>{children({ formattedCode: jsonText })}</>
          ) : (
            <>{children}</>
          )
        ) : null}
        <StyledScroller ownerState={props}>
          <SyntaxHighlight
            ownerState={{ ...props, errorMarker }}
            sx={containerSx}
          >
            {content}
          </SyntaxHighlight>
        </StyledScroller>
      </PreviewStateProvider>
    );
  }
  return null;
};
