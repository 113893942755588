import {
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { styled } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import { shouldForwardProp } from '../../../utils/shouldForwardProp';

export type PaperProps = PropsWithChildren<
  {
    hoverable?: boolean;
    disabled?: boolean;
    sx?: SxProps;
    testId?: string;
  } & HTMLAttributes<HTMLDivElement>
>;

export const Paper = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, PaperProps>(
    (
      { className, children, disabled, hoverable, testId, ...rest }: PaperProps,
      ref
    ) => {
      return (
        <div
          ref={ref}
          className={clsx(
            'DsPaper-Root',
            disabled && 'Ds-disabled',
            hoverable && 'DsPaper-hoverable',
            className
          )}
          data-testid={testId}
          {...rest}
        >
          {children}
        </div>
      );
    }
  ),
  {
    name: 'DsPaper',
    slot: 'Root',
    target: 'DsPaper-Root',
    shouldForwardProp,
    overridesResolver: (_, styles) => [styles.root],
  }
)<PaperProps>`` as unknown as (props: PaperProps) => ReactElement;
