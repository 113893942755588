import { DotProps } from 'recharts';

import { pseudoUnique } from '@cast/utils';

export const ActiveDot = ({ cx = 0, cy = 0, fill }: DotProps) => {
  const id = String(pseudoUnique());

  return (
    <g data-testid="chart-active-dot">
      <defs>
        <filter id={id}>
          <feDropShadow
            dx="0"
            dy="1"
            stdDeviation="0.5"
            floodColor="#000000"
            floodOpacity="0.15"
          />
        </filter>
      </defs>
      <circle cx={cx} cy={cy} r={4.5} fill="white" filter={`url(#${id})`} />
      <circle cx={cx} cy={cy} r={1.5} fill={fill} />
    </g>
  );
};
