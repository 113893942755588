import { PropsWithChildren, useState } from 'react';

import { Box, styled } from '@mui/material';
import { useKeenSlider } from 'keen-slider/react';

import { Icons } from '@cast/design-system';
import 'keen-slider/keen-slider.min.css';

const ArrowWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  width: '40px',
  position: 'absolute',
  zIndex: 1,
  top: 0,
  bottom: 0,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  color: theme.palette.grey[500],

  '&:hover': {
    color: theme.palette.grey[400],
  },

  '& svg': {
    color: 'currentColor',
    fontSize: '44px',
  },
}));

export const Carousel = ({
  children,
  testId = 'carousel',
}: PropsWithChildren<{ testId?: string }>) => {
  const [page, setPage] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    mode: 'free-snap',
    selector: '.carousel-slide',
    slides: {
      perView: 4,
      spacing: 10,
    },
    slideChanged(s) {
      setPage(s?.track.details.abs || 0);
    },
  });

  const maxPage = instanceRef?.current?.track.details.maxIdx || 0;

  return (
    <div ref={sliderRef} className="keen-slider" data-testid={testId}>
      {page !== 0 && (
        <>
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              left: 0,
              width: 200,
              zIndex: 1,
              pointerEvents: 'none',
              bottom: 0,
              top: 0,
              background:
                'linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)',
            }}
          />
          <ArrowWrapper
            sx={{
              left: '8px',
            }}
            onClick={() => {
              instanceRef.current?.prev();
            }}
            data-testid="arrow-back"
          >
            <Icons.CaretCircleLeft weight="fill" />
          </ArrowWrapper>
        </>
      )}
      {maxPage !== page && (
        <>
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              right: 0,
              width: 200,
              zIndex: 1,
              bottom: 0,
              top: 0,
              pointerEvents: 'none',
              background:
                'linear-gradient(270deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)',
            }}
          />
          <ArrowWrapper
            sx={{
              right: '8px',
            }}
            onClick={() => {
              instanceRef.current?.next();
            }}
            data-testid="arrow-forward"
          >
            <Icons.CaretCircleRight weight="fill" />
          </ArrowWrapper>
        </>
      )}

      {children}
    </div>
  );
};
