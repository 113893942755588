export const generateCSSFilterForBorder = ({
  color = 'white',
  borderSize = 1,
}: {
  color?: string;
  borderSize?: number;
} = {}) => `
      drop-shadow(-${borderSize}px -${borderSize}px 0px ${color}) 
      drop-shadow(${borderSize}px -${borderSize}px 0px ${color}) 
      drop-shadow(${borderSize}px ${borderSize}px 0px ${color})
      drop-shadow(-${borderSize}px ${borderSize}px 0px ${color})
  `;
