Prism.languages.cel = {
  comment: /\/\/.*/,
  function: {
    pattern: /[_a-zA-Z][_a-zA-Z0-9]*(?=\()/,
  },
  object: {
    pattern: /[_a-zA-Z][_a-zA-Z0-9]*(?=\{)/,
  },
  'attribute-name': /[_a-zA-Z][_a-zA-Z0-9]*:/,
  'object-member': /\.[_a-zA-Z][_a-zA-Z0-9]*/,
  string: {
    pattern: /(["'])(?:\\.|(?!\1)[^\\\r\n])*\1/,
    greedy: true,
  },
  'raw-string': {
    pattern: /([rR])(["'])(?:\\.|(?!\2)[^\\\r\n])*\2/,
    greedy: true,
  },
  'escape-sequence': {
    pattern:
      /\\[bfnrt"'\\]|\\u[0-9a-fA-F]{4}|\\U[0-9a-fA-F]{8}|\\[xX][0-9a-fA-F]{2}|\\0[0-8]{3}/,
  },
  number: /\b\d+\.?\d*\b/,
  'language-constants': /\b(true|false|null)\b/,
  'logical-operator': /&&|\|\|/,
  'comparison-operator': /<=|<|>=|>|==|!=|(?<=[^A-Za-z_])in\b/,
  'arithmetic-operator': /\+|-|\*|\/|%|!/,
  punctuation: /[{}()[\],:]/,
};

Prism.languages.insertBefore('cel', 'string', {
  'multiline-string': {
    pattern: /("""|''')[\s\S]*?\1/,
    alias: 'string',
  },
});
