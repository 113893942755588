import { styled } from '@mui/material';

import { DataPopperContext } from './context';
import { DataPopperProps } from './types';
import { shouldForwardProp } from '../../utils/shouldForwardProp';

const DataPopperRoot = styled('div', {
  name: 'DsDataPopper',
  slot: 'Root',
  target: 'DsDataPopper-Root',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root],
})<{ ownerState: DataPopperProps }>``;

export const DataPopper = (props: DataPopperProps) => {
  const _props: DataPopperProps = { size: 'medium', ...props };
  return (
    <DataPopperContext.Provider value={{ popperProps: _props }}>
      <DataPopperRoot
        className={props.className}
        sx={props.sx}
        ownerState={_props}
        data-testid={_props.testId ?? 'data-popper-root'}
      >
        {props.children}
      </DataPopperRoot>
    </DataPopperContext.Provider>
  );
};
