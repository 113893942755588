import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

type Request = {
  ruleId: string;
  clusterIds: string[];
};

export const useEnforceCheckPolicyMutation = (
  successMessage = 'Check was successfully enforced.'
): UseMutationResult<unknown, unknown, Request, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ ruleId, clusterIds }) => {
      return apiClient.securityInsights.enforceCheckPolicy({
        ruleId,
        body: {
          clusterIds,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.SECURITY_BEST_PRACTICE_CHECK],
      });
      notify.success(successMessage);
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
