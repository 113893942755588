import { forwardRef, ReactNode } from 'react';

import {
  FormControlLabel,
  FormControlLabelProps,
  Typography,
  TypographyProps,
} from '@mui/material';

import { Radio, RadioProps } from './Radio';
import { mergeSx } from '../../../utils';

type LabeledRadioSizes = 'xs' | 's' | 'm';

export type DsLabeledRadioProps = {
  size?: LabeledRadioSizes;
  radioProps?: RadioProps;
  formControlLabelProps?: FormControlLabelProps;
  label: ReactNode;
  labelProps?: TypographyProps;
  description?: ReactNode;
  descriptionProps?: TypographyProps;
  className?: string;
};

const sizeToRadioSize: Record<LabeledRadioSizes, RadioProps['size']> = {
  xs: 'small',
  s: 'small',
  m: 'medium',
};

const sizeToDescriptionVariant: Record<LabeledRadioSizes, string> = {
  xs: 'P10R',
  s: 'P12R',
  m: 'P14R',
};

const sizeToLabelVariant: Record<LabeledRadioSizes, string> = {
  xs: 'P10M',
  s: 'P12M',
  m: 'P14M',
};

export const LabeledRadio = forwardRef<HTMLDivElement, DsLabeledRadioProps>(
  (
    {
      radioProps,
      formControlLabelProps,
      label,
      labelProps,
      description,
      descriptionProps,
      size = 's',
      className,
    },
    ref
  ) => {
    return (
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'max-content auto',
          gridTemplateRows: 'max-content auto',
          alignItems: 'flex-start',
          gridColumnGap: 8,
          gridRowGap: size !== 'xs' ? 2 : undefined,
        }}
        className={className}
        ref={ref}
      >
        <FormControlLabel
          control={
            <Radio
              size={sizeToRadioSize[size]}
              {...radioProps}
              sx={mergeSx({ alignSelf: 'center' }, radioProps?.sx)}
            />
          }
          label={
            <Typography
              variant={sizeToLabelVariant[size] as any}
              {...labelProps}
            >
              {label}
            </Typography>
          }
          {...formControlLabelProps}
          sx={mergeSx({ display: 'contents' }, formControlLabelProps?.sx)}
        />

        {!!description && (
          <Typography
            variant={sizeToDescriptionVariant[size] as any}
            color="grey.500"
            gridColumn={2}
            {...descriptionProps}
          >
            {description}
          </Typography>
        )}
      </div>
    );
  }
);

LabeledRadio.displayName = 'LabeledRadio';
