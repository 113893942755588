import { styled } from '@mui/material';

import { Button, ButtonProps, buttonVars } from '../../../buttons';

type TableActionButtonProps = ButtonProps & { disruptive?: boolean };

export const TableActionButton = styled(
  ({ children, ...rest }: TableActionButtonProps) => {
    return (
      <Button variant="ghost" size="small" {...rest}>
        {children}
      </Button>
    );
  },
  {
    shouldForwardProp: (propName: PropertyKey) => propName !== 'disruptive',
  }
)(({ theme, disruptive }) => [
  {
    ...buttonVars.setValues({
      background: theme.palette.grey[100],
      backgroundDisabled: theme.palette.grey[100],
    }),
  },
  disruptive && {
    ...buttonVars.setValues({
      color: theme.palette.red[400],
      colorHover: theme.palette.red[500],
      colorActive: theme.palette.red[600],
      colorDisabled: theme.palette.grey[300],
      background: theme.palette.grey[100],
      backgroundHover: theme.palette.red[50],
      backgroundActive: theme.palette.red[100],
      backgroundDisabled: theme.palette.grey[100],
      backgroundLoading: theme.palette.red[300],
      boxShadowHover: theme.palette.red[500],
      boxShadowActive: theme.palette.red[600],
      boxShadowDisabled: theme.palette.grey[100],
    }),
  },
]);
