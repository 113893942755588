import { useInfiniteQuery } from '@tanstack/react-query';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';

import { SortingState } from '@cast/design-system';
import { ListNotificationsRequest } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';

export type NotificationsFilter = {
  id?: string;
  name?: string;
  clusterId?: string;
  clusterName?: string;
  operationId?: string;
  operationType?: string;
  onlyUnresolved?: boolean;
  severities?: ListNotificationsRequest['filterSeverities'];
  project?: string;
  isExpired?: boolean;
};

type NotificationsQueryArgs = {
  filter: NotificationsFilter;
  sortingState?: SortingState;
  enabled?: boolean;
  refetch?: number | boolean;
};

export const useGetNotificationsQuery = ({
  filter = {},
  sortingState,
  enabled = true,
  refetch,
}: NotificationsQueryArgs) => {
  const {
    isLoading,
    isFetching,
    data: notificationsResponse,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [QueryKeys.NOTIFICATIONS, filter],
    queryFn: async ({ pageParam }) => {
      const { data } = await apiClient.notifications.listNotifications({
        pageLimit: '20',
        pageCursor: pageParam,
        filterClusterId: filter.clusterId,
        filterClusterName: filter.clusterName,
        filterNotificationId: filter.id,
        filterNotificationName: filter.name,
        filterOperationId: filter.operationId,
        filterOperationType: filter.operationType,
        filterIsAcked:
          typeof filter.onlyUnresolved === 'boolean'
            ? !filter.onlyUnresolved
            : undefined,
        filterIsExpired: filter.isExpired,
        filterProject: filter.project,
        filterSeverities: filter.severities,
        sortField: sortingState?.columnId,
        sortOrder: sortingState?.sortBy.direction ?? 'desc',
      });
      return data;
    },
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor || undefined;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchInterval: refetch === true ? 20_000 : refetch,
    enabled,
  });
  const pages = notificationsResponse?.pages;
  const items = pages ? flatMap(pages, ({ items }) => items) : [];

  const firstPage = pages?.[0];
  const count = firstPage?.count;
  const countUnacked = firstPage?.countUnacked;

  return {
    isLoading,
    notificationsResponse: {
      items: compact(items),
      count,
      countUnacked,
    },
    pages,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage: !!hasNextPage,
  };
};
