import { forwardRef } from 'react';

import { useThemeColor } from '../../../hooks/theme';
import IconBase, { IconProps } from '../IconBase';

export const Drag = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const color = useThemeColor(props.color || 'grey.700');

  return (
    <IconBase ref={ref} {...props} viewBox="0 0 16 16" fill="none">
      <path d="M3.5 7L12.5 7" stroke={color} strokeLinecap="round" />
      <path d="M3.5 9L12.5 9" stroke={color} strokeLinecap="round" />
      <path
        d="M7.61114 3.30921C7.81127 3.06162 8.18873 3.06162 8.38886 3.30921L8.85735 3.88882C9.12163 4.21579 8.88891 4.70312 8.46849 4.70312H7.53151C7.11109 4.70312 6.87837 4.21579 7.14265 3.88882L7.61114 3.30921Z"
        fill={color}
      />
      <path
        d="M7.61114 12.6908C7.81127 12.9384 8.18873 12.9384 8.38886 12.6908L8.85735 12.1112C9.12163 11.7842 8.88891 11.2969 8.46849 11.2969H7.53151C7.11109 11.2969 6.87837 11.7842 7.14265 12.1112L7.61114 12.6908Z"
        fill={color}
      />
    </IconBase>
  );
});

Drag.displayName = 'Drag';
