import { styled } from '@mui/material';
import { SxProps } from '@mui/system';

import { mergeSx, Spinner, SpinnerProps, withProps } from '@cast/design-system';
import { ImageScanStatus } from '@cast/types';

import { StatusChip, StatusChipProps } from 'components/chips';

const Loader = styled(
  withProps(Spinner, {
    thickness: 4,
    disableShrink: true,
    noBackground: true,
  })
)<SpinnerProps>({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'inherit !important',
});

const statusToDisplayName: Record<ImageScanStatus, string> = {
  [ImageScanStatus.Pending]: 'Scanning',
  [ImageScanStatus.Failed]: 'Scan failed',
  [ImageScanStatus.Completed]: 'Scanned',
  [ImageScanStatus.Unknown]: 'Unknown',
};

const statusToStyle: Record<ImageScanStatus, SxProps> = {
  [ImageScanStatus.Pending]: {
    backgroundColor: 'grey.100',
    color: 'grey.600',
  },
  [ImageScanStatus.Failed]: {
    backgroundColor: 'red.50',
    color: 'red.500',
  },
  [ImageScanStatus.Completed]: {
    backgroundColor: 'green.50',
    color: 'green.600',
  },
  [ImageScanStatus.Unknown]: {
    backgroundColor: 'grey.100',
    color: 'grey.600',
  },
};

type Props = { status: ImageScanStatus } & StatusChipProps;

export const ImageScanStatusChip = ({ status, ...props }: Props) => {
  return (
    <StatusChip
      startIcon={status === ImageScanStatus.Pending ? <Loader /> : undefined}
      {...props}
      sx={mergeSx(statusToStyle[status], props.sx)}
    >
      {statusToDisplayName[status]}
    </StatusChip>
  );
};
