import * as yup from 'yup';

export const isValidGCPCredential = (credentials: string) => {
  try {
    if (!credentials) {
      return false;
    }
    const data = JSON.parse(credentials);

    yup
      .object({
        type: yup.string().required(),
        project_id: yup.string().required(),
        private_key: yup.string().required(),
      })
      .validateSync(data);
  } catch (e) {
    return false;
  }
  return true;
};

export const isValidAzureCredential = (credentials: string) => {
  try {
    if (!credentials) {
      return false;
    }

    const data = JSON.parse(credentials);

    yup
      .object({
        subscriptionId: yup.string().required(),
        tenantId: yup.string().required(),
        clientId: yup.string().required(),
        clientSecret: yup.string().required(),
      })
      .validateSync(data);
  } catch (e) {
    return false;
  }
  return true;
};
