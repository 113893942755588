import { useQuery } from '@tanstack/react-query';

import { QueryKeys, apiClient } from 'core/react-query';

export const useGetWebhookCategoriesQuery = () => {
  const {
    data: categories,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.WEBHOOK_CATEGORIES],
    queryFn: async () => {
      const { data } = await apiClient.notifications.webhookCategories();
      return data.categories;
    },
  });
  return {
    categories,
    isLoading,
    error,
    refetch,
  };
};
