import { useEffect, useRef, useState } from 'react';

type UseDelayedEventProps = {
  milliseconds?: number;
  onEnded?: () => void;
};

export const useDelayedEvent = ({
  milliseconds: defaultMilliseconds,
  onEnded: defaultOnEnded,
}: UseDelayedEventProps = {}) => {
  const [waitingFor, setWaitingFor] = useState<number | undefined>();
  const timeoutRef = useRef<number>();
  const wasActiveRef = useRef<boolean>(false);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    },
    []
  );

  const clear = (onEnded = defaultOnEnded) => {
    setWaitingFor(undefined);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
    onEnded?.();
    defaultOnEnded?.();
  };

  return {
    activate: ({ milliseconds, onEnded }: UseDelayedEventProps = {}) => {
      const timeToWait = milliseconds || defaultMilliseconds || 1000;

      wasActiveRef.current = true;
      setWaitingFor(timeToWait);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => clear(onEnded), timeToWait) as any;
      return () => clear(onEnded);
    },
    isActive: waitingFor !== undefined,
    // Was activateFor has been called at least once?
    // (useful for cases like closing a drawer and not destroying overlay while drawer is closing)
    activated: wasActiveRef.current,
    clear,
  };
};
