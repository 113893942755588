import { Rectangle } from 'recharts';

import { Point } from './_types';

type Props = {
  cursorWidth: number;
  top?: number;
  height?: number;
  points?: Point[];
  width: number;
  color?: string;
};

const strokeWidth = 1;

export const CustomAreaCursor = ({
  cursorWidth,
  width,
  top = 0,
  points = [],
  height = 0,
  color = '#ffffff',
}: Props) => {
  const x = points?.[0]?.x - cursorWidth / 2 - strokeWidth / 2;

  return (
    <>
      <defs>
        <linearGradient id="light" x1=".5" x2=".5" y2="1">
          <stop offset="0%" stopColor={color} stopOpacity="0" />
          <stop offset="100%" stopColor={color} stopOpacity="0.5" />
        </linearGradient>
      </defs>

      <Rectangle
        x={x}
        y={top}
        width={width}
        height={height}
        fill="url(#light)"
        stroke="url(#light)"
        strokeWidth={strokeWidth}
      />
    </>
  );
};
