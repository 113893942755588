import { codeActionsTheme } from './code-actions/codeActionsTheme';
import { previewJsonTheme } from './json/previewJsonTheme';
import { previewYamlTheme } from './yaml/previewYamlTheme';

export * from './json';
export * from './yaml';
export * from './snippet';
export * from './code-actions';
export * from './types';
export * from './hooks';

export const codeTheme = {
  ...previewJsonTheme,
  ...previewYamlTheme,
  ...codeActionsTheme,
};
