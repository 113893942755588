import { useAvailableSavingsQuery } from 'hooks/queries/available-savings';
import { useCluster } from 'hooks/useCluster';

import { getCurrentPrice } from '../utils';

export const useCurrentPrice = (enabled = true) => {
  const { cluster } = useCluster();
  const { data } = useAvailableSavingsQuery(cluster.id, enabled);
  return { hasLoaded: !!data, ...getCurrentPrice(data) };
};
