import { SettingsColumn, SettingsRowModel } from './types';
import { GroupedColumnProps, isColumnsGroup } from '../../../../types';

export const getColumnGroupVisibilityState = (columns: SettingsColumn[]) => {
  if (columns.every(({ visible }) => visible)) {
    return true;
  }
  if (columns.every(({ visible }) => !visible)) {
    return false;
  }
  return 'indeterminate';
};

export const toSettingsRowModel = (
  props: GroupedColumnProps
): SettingsRowModel => {
  if (isColumnsGroup(props)) {
    const columns: SettingsColumn[] = props.columns.map(({ id, visible }) => ({
      id,
      visible: !!visible,
    }));
    return {
      id: props.id,
      locked: !!props.locked,
      visible: getColumnGroupVisibilityState(columns),
      excludeFromSettings: !!props.excludeFromSettings,
      columns,
    };
  }
  return {
    id: props.id,
    locked: !!props.locked,
    visible: !!props.visible,
    excludeFromSettings: !!props.excludeFromSettings,
  };
};
