import { ReactNode } from 'react';

import { Box, Stack } from '@mui/material';

import { DatePickerFooter } from '../../components/DatePickerFooter';
import { DatePickerFooterButtons } from '../../components/DatePickerFooterButtons';
import { TimezonePicker } from '../../components/TimezonePicker';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import { PeriodComparisonPickerProviderProps } from '../../types';

type Props = {
  isMini: boolean;
  hint?: ReactNode;
};

export const PeriodComparisonPickerFooter = ({ isMini, hint }: Props) => {
  const { hideTimezonePicker } =
    useDatePickerContext<PeriodComparisonPickerProviderProps>();

  if (isMini) {
    return <DatePickerFooter isMini={false} hint={hint} width={544} />;
  }

  const isOnlyButtons = hideTimezonePicker && !hint;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={isOnlyButtons ? 'flex-end' : 'space-between'}
      borderTop="grey.200"
      padding="8px 24px"
      gap={16}
    >
      {!hideTimezonePicker && <TimezonePicker />}

      {hint && (
        <Box flex={1} data-testid="date-picker-hint">
          {hint}
        </Box>
      )}

      <DatePickerFooterButtons />
    </Stack>
  );
};
