import { createContext, forwardRef, ReactNode, useContext } from 'react';

import MUIRadio, { RadioProps as MUIRadioProps } from '@mui/material/Radio';
import clsx from 'clsx';
import capitalize from 'lodash/capitalize';

type CustomProps = {
  error?: boolean;
  label?: ReactNode;
  testId?: string;
};

type RadioIconProps = { checked?: boolean };
const RadioIcon = ({ checked }: RadioIconProps) => (
  <div className={clsx('RadioIconBase', checked && 'RadioIconBase-checked')} />
);

export type RadioProps = MUIRadioProps & CustomProps;

export const RadioContext = createContext<RadioProps | undefined>(undefined);

export const Radio = forwardRef<HTMLButtonElement, RadioProps>(
  ({ error, testId, ...props }, ref) => {
    const radioContext = useContext(RadioContext) ?? {};
    const size = radioContext.size || props.size || 'medium';

    return (
      <MUIRadio
        icon={<RadioIcon />}
        checkedIcon={<RadioIcon checked />}
        data-testid={testId}
        {...radioContext}
        {...props}
        // FormControlLabel adds 'disabled: undefined'
        disabled={
          props.disabled ??
          radioContext.disabled ??
          props.readOnly ??
          radioContext.readOnly
        }
        className={clsx(
          props.className,
          'DsRadio-Root',
          `DsRadio-size${capitalize(size)}`
        )}
        color={error ? 'error' : props.color}
        ref={ref}
      />
    );
  }
);

Radio.displayName = 'Radio';
