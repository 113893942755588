import { useMemo, useRef, useState } from 'react';

import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';

import {
  clearQueryParamSilently,
  getQueryParam,
  setQueryParamSilently,
} from '@cast/utils';

import { IS_DEV } from 'common/constants';

import { Criterion } from '../types';
import { getInitialValue, isClient, isServer } from '../utils';

const addToUrl = (urlKey: string, value: Record<string, any>) => {
  setQueryParamSilently(urlKey, encodeURIComponent(JSON.stringify(value)));
};

const addOrRemoveToUrl = (value: Record<string, any>, urlKey?: string) => {
  if (urlKey) {
    if (Object.keys(value).length) {
      addToUrl(urlKey, value);
    } else {
      clearQueryParamSilently(urlKey);
    }
  }
};

const decodeCriteriaFromUrl = (id: string): Record<string, string> => {
  const searchParams = getQueryParam(id);
  if (!searchParams) {
    return {};
  }
  try {
    return JSON.parse(decodeURIComponent(searchParams));
  } catch (e) {
    console.error(e);
    return {};
  }
};

const criteriaToValues = (
  criteria: Criterion[],
  values: Record<string, any>
) => {
  const result: Record<string, any> = {};
  for (const { key } of criteria) {
    if (key in values) {
      result[key] = values[key];
    }
  }
  return result;
};

const useValueSubset = (
  criteria: Criterion[],
  values: Record<string, any>
): Record<string, any> => {
  const resultRef = useRef<Record<string, any>>({});
  return useMemo(() => {
    const result = criteriaToValues(criteria, values);
    if (!isEqual(result, resultRef.current)) {
      resultRef.current = result;
      return result;
    }
    return resultRef.current;
  }, [criteria, values]);
};

export const useCriteria = (allCriteria: Criterion[], urlKey?: string) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = useMemo(() => getInitialValue(allCriteria), []);

  const [selectedCriteria, setSelectedCriteria] = useState<Record<string, any>>(
    () => {
      if (urlKey) {
        return merge({}, initialValues, decodeCriteriaFromUrl(urlKey) || {});
      }
      return initialValues;
    }
  );

  const clientCriteria = useMemo(
    () => allCriteria.filter(isClient),
    [allCriteria]
  );
  const clientValues = useValueSubset(clientCriteria, selectedCriteria);

  const serverCriteria = useMemo(
    () => allCriteria.filter(isServer),
    [allCriteria]
  );
  const serverValues = useValueSubset(serverCriteria, selectedCriteria);

  const getCriteria = (key: string) => allCriteria.find((c) => c.key === key);
  const setValue = (key: string, value: any) => {
    if (IS_DEV) {
      const criteria = getCriteria(key);
      if (!criteria) {
        throw new Error(`Trying to set non existing criterion: ${key}`);
      }
    }
    setSelectedCriteria((currentValue) => {
      if (isEqual(currentValue[key], value)) {
        return currentValue;
      }
      const newValue = { ...currentValue };
      if (value == null && !!currentValue[key]) {
        delete newValue[key];
      } else {
        newValue[key] = value;
      }
      addOrRemoveToUrl(newValue, urlKey);
      return newValue;
    });
  };

  const clearValue = (keyToRemove?: string | string[]) => {
    const removedValues: Record<string, any> = {};
    setSelectedCriteria((currentValue) => {
      const newValue: Record<string, any> = {};
      if (keyToRemove) {
        const keysToRemove = Array.isArray(keyToRemove)
          ? keyToRemove
          : [keyToRemove];
        let removed = false;
        for (const key in currentValue) {
          if (!keysToRemove.includes(key)) {
            newValue[key] = currentValue[key];
          } else {
            removedValues[key] = currentValue[key];
            removed = true;
          }
        }
        if (removed) {
          addOrRemoveToUrl(newValue, urlKey);
          return newValue;
        }
        return currentValue;
      }
      return newValue;
    });

    return removedValues;
  };

  const resetInitial = () => {
    setSelectedCriteria(initialValues);
    addOrRemoveToUrl(initialValues, urlKey);
  };

  const valuesChanged = useMemo(() => {
    return !isEqual(initialValues, selectedCriteria);
  }, [initialValues, selectedCriteria]);

  const setValues = (values: Record<string, any>) => {
    setSelectedCriteria(values);
    addOrRemoveToUrl(values, urlKey);
  };

  return {
    values: selectedCriteria,
    setValue,
    setValues,
    clearValue,
    resetInitial,
    valuesChanged,
    clientValues,
    clientCriteria,
    serverValues,
    serverCriteria,
  };
};
