import { AxiosInstance } from 'axios';

import { AuthTokenAPIApiFactory } from '@cast/openapi';
import { ApiAccessKeyUpdateRequest } from '@cast/types';

import { AXIOS_CONFIGURATION } from './constants';

export const AccessKeysApiFactory = (axiosClient: AxiosInstance) => {
  const client = AuthTokenAPIApiFactory(AXIOS_CONFIGURATION, '', axiosClient);

  return {
    deleteApiAccessKey: (id: string) =>
      client.authTokenAPIDeleteAuthToken({ id }),
    updateApiAccessKey: (
      id: string,
      updatedParameters: ApiAccessKeyUpdateRequest
    ) => client.authTokenAPIUpdateAuthToken({ id, item: updatedParameters }),
    createApiAccessKey: (name: string, readonly: boolean) =>
      client.authTokenAPICreateAuthToken({ item: { name, readonly } }),
    getApiAccessKeys: () => client.authTokenAPIListAuthTokens(),
  };
};
