import { Button, Icons } from '@cast/design-system';

import { LogoutWrapper } from 'components/router/LogoutWrapper';

export const LogoutButton = () => {
  return (
    <LogoutWrapper>
      <Button
        startIcon={<Icons.SignOut />}
        sx={{ position: 'absolute', right: 30, top: 20, color: 'white' }}
        variant="ghost"
        size="large"
      >
        Log out
      </Button>
    </LogoutWrapper>
  );
};
