import { User } from '@cast/types';

export const _CASTAI_USER: User = {} as never;

export const user = (): User => {
  if (!_CASTAI_USER.id) {
    throw new Error('User is not initialized');
  }
  return _CASTAI_USER;
};

export const userId = (): string => {
  return user().id;
};
