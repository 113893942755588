import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import dayjs from 'dayjs';

import { RightsizingRecommendation } from '@cast/types';

import { clusterEvents } from 'core/analytics';
import { useRightsizingSummaryQuery } from 'hooks/queries/available-savings';
import { usePaginatedWorkloadsEfficiencyQuery } from 'hooks/queries/cost';
import { useCluster } from 'hooks/useCluster';
import { usePersistentState } from 'hooks/usePersistentState';

import { useAvailableSavingsOptimalConfiguration } from '../_hooks/useAvailableSavingsOptimalConfiguration';

type WorkloadRightsizingState = {
  avgCpuPrice: number;
  avgRamPrice: number;
  currentEfficiency: number;
  metricsServerAvailable: boolean;
  workloadRightsizingEnabled: boolean;
  toggleWorkloadRightsizing: () => void;
  recommendations?: RightsizingRecommendation;
};

const Context = createContext<WorkloadRightsizingState>(undefined as never);

export const WorkloadRightsizingProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const { cluster } = useCluster();
  const [workloadRightsizingEnabled, setWorkloadRightsizingEnabled] =
    usePersistentState<boolean>(
      `available-savings.workload.rightsizing.enabled-${cluster.id}`,
      false
    );

  const toggleWorkloadRightsizing = () => {
    const newValue = !workloadRightsizingEnabled;
    setWorkloadRightsizingEnabled(newValue);
    clusterEvents.toggledWorkloadRightsizing(cluster, newValue);
  };

  const now = useMemo(() => dayjs(), []);

  const { metricsServerAvailable } = usePaginatedWorkloadsEfficiencyQuery({
    clusterId: cluster.id,
    startTime: now.toISOString(),
    endTime: now.toISOString(),
    pageLimit: 1,
  });

  const { data: rightsizingRecommendation } = useRightsizingSummaryQuery({
    clusterId: cluster.id,
  });

  const {
    summary: { avgCpuPrice, avgRamPrice },
  } = useAvailableSavingsOptimalConfiguration();

  return (
    <Context.Provider
      value={{
        avgCpuPrice: Number(avgCpuPrice || 0),
        avgRamPrice: Number(avgRamPrice || 0),
        currentEfficiency: Math.max(
          0,
          Number(rightsizingRecommendation?.summary?.efficiency || 0) * 100
        ),
        metricsServerAvailable: !!metricsServerAvailable,
        workloadRightsizingEnabled,
        toggleWorkloadRightsizing,
        recommendations: rightsizingRecommendation,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useWorkloadRightsizingState = () => {
  return useContext(Context);
};
