import { Box } from '@mui/material';
import { useFormState } from 'react-final-form';

import { PreviewJson } from '@cast/design-system';

import { CodeEditor } from 'components/inputs';
import { RffCodeEditor, WhenMode } from 'core/forms/rff';

import { FormModel } from './types';

export const RequestTemplateRow = () => {
  const state = useFormState<FormModel>();
  const requestTemplate = state.values.requestTemplate;
  return (
    <WhenMode
      mode="view"
      fallback={
        <RffCodeEditor
          name="requestTemplate"
          rows={5}
          highlight="json"
          testId="webhook-request-template"
        />
      }
    >
      {!!requestTemplate?.length && (
        <Box
          sx={{
            '& .Json-error': { pl: '32px' },
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <PreviewJson
            data={requestTemplate}
            renderFallback={(data) => {
              return (
                <CodeEditor
                  value={data}
                  highlight="json"
                  disabled
                  sx={{ p: 16 }}
                  testId="request-template-preview-fallback"
                />
              );
            }}
          />
        </Box>
      )}
    </WhenMode>
  );
};
