import { useState } from 'react';

import { useField } from 'react-final-form';

import { RffCodeEditor, RffCodeEditorProps } from './RffCodeEditor';

type Props = Omit<RffCodeEditorProps, 'highlight'> & {
  indentation?: number;
};

export const RffJsonEditor = ({ indentation = 4, ...props }: Props) => {
  const field = useField(props.name);
  const { active } = field.meta;
  const [editableValue, setEditableValue] = useState<string>();
  const format = (value: string) => {
    try {
      return JSON.stringify(JSON.parse(value), null, indentation);
    } catch (_) {
      // This will often be called, because while editing, json could be invalid
    }
  };
  return (
    <RffCodeEditor
      {...props}
      onFocus={() => setEditableValue(format(field.input.value))}
      onBlur={() => {
        if (!editableValue) {
          field.input.onChange(undefined);
          return;
        }
        const stringified = format(editableValue);
        field.input.onChange(stringified ?? editableValue);
        if (stringified) {
          setEditableValue(undefined);
        }
      }}
      onChange={setEditableValue}
      value={active ? editableValue : undefined}
      fieldProps={{
        ...props.fieldProps,
        format: (value, name) => {
          if (props.fieldProps?.format) {
            return props.fieldProps.format(value, name);
          }
          return format(value) ?? value ?? '';
        },
      }}
      highlight="json"
    />
  );
};
