import { SecurityInsightsTicketItemType } from '@cast/types';

export const ticketTypeToStringMap: Record<
  SecurityInsightsTicketItemType,
  string
> = {
  [SecurityInsightsTicketItemType.Check]: 'Check',
  [SecurityInsightsTicketItemType.Image]: 'Image',
  [SecurityInsightsTicketItemType.Package]: 'Package',
  [SecurityInsightsTicketItemType.Vulnerability]: 'Vulnerability',
  [SecurityInsightsTicketItemType.AttackPath]: 'Attack path',
  [SecurityInsightsTicketItemType.RuntimeAnomaly]: 'Runtime anomaly',
  [SecurityInsightsTicketItemType.Unknown]: '',
};
