import { useSettingsDrawer } from './hooks/useSettingsDrawer/useSettingsDrawer';
import { IconButton, IconButtonProps } from '../../../buttons';
import { DrawerProvider } from '../../../drawer';
import { GearSix } from '../../../icons';

const Button = (props: IconButtonProps) => {
  const { openSettingsDrawer } = useSettingsDrawer();

  return (
    <IconButton
      onClick={openSettingsDrawer}
      variant="ghost"
      size="small"
      {...props}
    >
      <GearSix />
    </IconButton>
  );
};

export const SettingsButton = (props: IconButtonProps) => {
  return (
    <DrawerProvider>
      <Button {...props} />
    </DrawerProvider>
  );
};
