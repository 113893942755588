import { PropsWithChildren } from 'react';

import { Stack } from '@mui/material';
import clsx from 'clsx';

type Props = PropsWithChildren<{ visible?: boolean }>;

export const TableBulkActions = ({ children, visible }: Props) => {
  return (
    <Stack
      display="flex"
      direction="row"
      className={clsx('BulkActionsRow-root', visible && 'actionsVisible')}
      alignItems="center"
      sx={{
        overflow: 'hidden',

        '& .BulkActionsRow-Actions': {
          padding: '0 8px',
          transitionProperty: 'transform, opacity, border',
          transitionDuration: '0.1s',
          display: 'flex',
          flexDirection: 'row',
          opacity: 0,
          gap: '8px',
          transform: 'translateX(-100%)',
        },

        '&.actionsVisible': {
          '& .BulkActionsRow-Actions': {
            borderLeft: '1px solid',
            borderColor: 'grey.400',
            transform: 'translateX(0)',
            opacity: 1,
            transitionDelay: 0,
          },
        },
      }}
    >
      <div className="BulkActionsRow-Actions">{children}</div>
    </Stack>
  );
};
