import { DependencyList, useEffect } from 'react';

import { toggleChatBubbleVisibility } from 'utils/supportChat';

export const useChatVisibilityToggle = (
  isHidden: boolean,
  deps: DependencyList = []
) => {
  useEffect(() => {
    toggleChatBubbleVisibility(!isHidden);
    return () => {
      toggleChatBubbleVisibility(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden, ...deps]);
};
