import { DrawerOverlay, useDrawerInstance } from '@cast/design-system';

import { DestroyFormGuard, DestroyFormGuardProps } from '../../messages';

type Props = DestroyFormGuardProps & { testId?: string };

export const DestroyDrawerFormGuard = ({
  testId = 'destroy-drawer-form-guard',
  ...props
}: Props) => {
  const { shouldRenderGuard, setShouldRenderGuard, close } =
    useDrawerInstance();
  if (!shouldRenderGuard) {
    return null;
  }
  return (
    <DrawerOverlay testId={testId}>
      <DestroyFormGuard
        onContinue={() => setShouldRenderGuard(false)}
        onLeave={close}
        {...props}
      />
    </DrawerOverlay>
  );
};
