import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TRANSITION_PROPS } from '../_components/TourTooltip';
import { TourStepProps } from '../types';
import { setIsDismissed } from '../utils';

export type GlobalTourStepProps = Partial<TourStepProps>;

export type TourProviderState = {
  isActive: boolean;
  setIsActive: (active: boolean) => void;

  isInitActive: boolean;
  setIsInitActive: (active: boolean) => void;

  isDismissedOnce: boolean;
  dismiss: (type: string) => void;

  activeStep?: string;
  setActiveStep: (step: string) => void;
  nextStep: () => void;
  isLastStep: boolean;
  stepIndex: number;
  startTour: (
    type: string,
    steps: string[],
    globalTourStepProps?: Record<string, GlobalTourStepProps>
  ) => void;
  finishTour: () => void;
  finishTourImmediately: () => void;

  globalStepProps?: Record<string, GlobalTourStepProps>;

  steps?: string[];
  type?: string;
};

export const TourContext = createContext<TourProviderState>(undefined as never);

export const TourProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [type, setType] = useState<string | undefined>();
  const [steps, setSteps] = useState<string[] | undefined>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isInitActive, setIsInitActive] = useState<boolean>(false);
  const [isDismissedOnce, setIsDismissedOnce] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<string | undefined>();
  const [globalStepProps, setGlobalStepProps] = useState<
    Record<string, GlobalTourStepProps> | undefined
  >();

  const dismissTimeoutRef = useRef<number | undefined>();
  const closeTimeoutRef = useRef<number | undefined>();

  useEffect(() => {
    if (isActive) {
      const timeoutId = setTimeout(() => {
        setIsDismissedOnce(false);
      }, 200);
      document.body.classList.add('tour-in-progress');

      return () => {
        document.body.classList.remove('tour-in-progress');
        clearTimeout(timeoutId);
      };
    }
  }, [isActive, setIsDismissedOnce]);

  const nextStep = () => {
    if (!steps || !activeStep) {
      return;
    }
    const stepIndex = steps.indexOf(activeStep);
    const nextStep = steps[stepIndex + 1];
    setActiveStep(nextStep || steps[0]);
  };

  const stepIndex = useMemo(() => {
    if (!steps || !activeStep) {
      return -1;
    }
    return steps.indexOf(activeStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const startTour = (
    type: string,
    steps: string[],
    globalStepProps?: Record<string, GlobalTourStepProps>
  ) => {
    setType(type);
    setSteps(steps);
    setActiveStep(steps[0]);
    setIsActive(true);
    setIsInitActive(false);
    setIsDismissedOnce(false);
    setGlobalStepProps(globalStepProps);
  };

  const finishTour = () => {
    setIsActive(false);
    setIsInitActive(false);
    setSteps(undefined);
    setType(undefined);
    setActiveStep(undefined);
    setGlobalStepProps(undefined);
    if (dismissTimeoutRef.current) {
      clearTimeout(dismissTimeoutRef.current);
    }
  };

  const finishTourImmediately = () => {
    if (dismissTimeoutRef.current) {
      clearTimeout(dismissTimeoutRef.current);
    }
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    finishTour();
    closeTimeoutRef.current = setTimeout(() => {
      setIsDismissedOnce(false);
    }, TRANSITION_PROPS.timeout.exit) as any;
  };

  const dismiss = (type: string) => {
    setIsDismissed(type);
    setIsDismissedOnce(true);
    dismissTimeoutRef.current = setTimeout(() => {
      finishTour();
    }, 1300) as any;
    closeTimeoutRef.current = setTimeout(() => {
      setIsDismissedOnce(false);
    }, 1300 + TRANSITION_PROPS.timeout.exit) as any;
  };

  const isLastStep = stepIndex + 1 === steps?.length;

  return (
    <TourContext.Provider
      value={{
        isActive,
        setIsActive,
        isInitActive,
        setIsInitActive,
        activeStep,
        setActiveStep,
        isDismissedOnce,
        dismiss,
        steps,
        type,
        nextStep,
        isLastStep,
        stepIndex,
        startTour,
        finishTour,
        finishTourImmediately,
        globalStepProps,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};
