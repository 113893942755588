import { Box } from '@mui/material';
import { useLottie } from 'lottie-react';

import animationData from 'assets/lottie/logo-loader.json';

export const LogoLoader = () => {
  const options = {
    animationData,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <Box height={100} width={100} data-testid="logo-loader">
      {View}
    </Box>
  );
};
