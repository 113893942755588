import { useQuery } from '@tanstack/react-query';

import { PoliciesConfig } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type UsePoliciesQuery = {
  isLoading: boolean;
  policies?: PoliciesConfig;
  error: unknown;
};

export const usePoliciesQuery = (
  id?: string,
  disabled = false
): UsePoliciesQuery => {
  const {
    isLoading,
    data: policies,
    error,
  } = useQuery({
    queryKey: [QueryKeys.POLICIES, id],
    queryFn: async () => {
      const { data } = await apiClient.autoscaler.getPolicies({
        clusterId: id!,
      });
      return data;
    },
    enabled: !!id && !disabled,
  });

  return {
    isLoading,
    policies,
    error,
  };
};
