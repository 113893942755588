import { useTheme } from '@mui/material';

import { ComposedChartWithEstimate, CustomAreaCursor } from 'components/chart';

import { ComposedChartWithCostReportProps } from '../../types/costOverTime';

const yTickFormatter = (t: string) => `$${t}`;

const cursorWidth = 1;

export const CostReportLineChart = ({
  composedProps,
  xAxisProps,
  yAxisProps,
  data,
  ...chartProps
}: ComposedChartWithCostReportProps) => {
  const { palette } = useTheme();

  return (
    <ComposedChartWithEstimate
      {...chartProps}
      data={data}
      tooltipCursor={
        <CustomAreaCursor
          cursorWidth={cursorWidth}
          width={cursorWidth}
          color={palette.grey[200]}
        />
      }
      composedProps={{
        ...composedProps,
        margin: {
          top: -1,
        },
      }}
      xAxisProps={{
        ...xAxisProps,
      }}
      yAxisProps={{
        tickFormatter: yTickFormatter,
        ...yAxisProps,
      }}
    />
  );
};
