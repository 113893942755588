import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

import { isValidGCPCredential } from '../validators';

export const GCPCredentialsSchema = yup.object({
  credentials: yup
    .object()
    .shape({
      json: yup
        .string()
        .test(
          'is-valid-gcp-json',
          'Please enter a valid Service account JSON.',
          (value) => isEmpty(value) || isValidGCPCredential(value!)
        )
        .required(
          'Please set a Service account JSON for the cloud credential.'
        ),
    })
    .required('Please set a Service account JSON for the cloud credential.'),
});
