import { useMemo } from 'react';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { Icons, Tab, Tabs as DsTabs, Tooltip } from '@cast/design-system';
import { usePath } from '@cast/utils';

import { BetaLabelChip } from 'components/chips';
import { Link } from 'components/router';
import {
  makeCostMonitoringComputeCostLink,
  makeCostMonitoringEfficiencyLink,
  makeCostMonitoringGpuUtilizationLink,
  makeCostMonitoringNetworkCostLink,
} from 'core/navigation';

import { ReportTypeSegmentKeys } from '../../cluster/cost-report/navigation';
import { useCostReportContext } from '../hooks/useCostReportContext';
import { CostReportTab } from '../types/common';

export type CostReportTabsProps = {
  enabledTabs: CostReportTab[];
  currentTab?: CostReportTab;
  tabs?: Partial<Record<CostReportTab, TabValue>>;
};

export type TabValue = { label: string } & (
  | { reportType?: ReportTypeSegmentKeys }
  | { link?: string }
);

const defaultTabs: Partial<Record<CostReportTab, TabValue>> = {
  [CostReportTab.COMPUTE_COST]: {
    label: 'Compute cost',
    reportType: 'computeCost',
  },
  [CostReportTab.NETWORK_COST]: {
    label: 'Network cost',
    reportType: 'networkCost',
  },
  [CostReportTab.EFFICIENCY]: { label: 'Efficiency', reportType: 'efficiency' },
  [CostReportTab.TOTAL_COST]: { label: 'Total cost' },
};

export const CostReportTabs = ({
  enabledTabs,
  currentTab,
  tabs = defaultTabs,
}: CostReportTabsProps) => {
  const { palette } = useTheme();
  const { routeProps } = useCostReportContext();

  const computeCostLink = usePath(makeCostMonitoringComputeCostLink, {
    enabled:
      !isEmpty(routeProps) &&
      !!tabs?.COMPUTE_COST &&
      'reportType' in tabs?.COMPUTE_COST,
  });

  const networkCostLink = usePath(makeCostMonitoringNetworkCostLink, {
    enabled:
      !isEmpty(routeProps) &&
      !!tabs?.NETWORK_COST &&
      'reportType' in tabs?.NETWORK_COST,
  });

  const efficiencyLink = usePath(makeCostMonitoringEfficiencyLink, {
    enabled:
      !isEmpty(routeProps) &&
      !!tabs?.EFFICIENCY &&
      'reportType' in tabs?.EFFICIENCY,
  });

  const gpuUtilizationLink = usePath(makeCostMonitoringGpuUtilizationLink, {
    enabled:
      !isEmpty(routeProps) &&
      !!tabs?.GPU_UTILIZATION &&
      'reportType' in tabs?.GPU_UTILIZATION,
  });

  const makeLinkToReport = (reportType?: ReportTypeSegmentKeys) => {
    switch (reportType) {
      case 'computeCost': {
        return computeCostLink;
      }
      case 'networkCost': {
        return networkCostLink;
      }
      case 'efficiency': {
        return efficiencyLink;
      }
      case 'gpuUtilization': {
        return gpuUtilizationLink;
      }
      default: {
        return '/404';
      }
    }
  };

  const tabsWithBetaLabel = useMemo(() => {
    return [
      tabs[CostReportTab.NETWORK_COST]?.label,
      tabs[CostReportTab.GPU_UTILIZATION]?.label,
    ];
  }, [tabs]);

  if (isEmpty(routeProps)) {
    return null;
  }

  return (
    <DsTabs value={currentTab} size="medium" fullWidth>
      {(Object.entries(tabs) as Array<[CostReportTab, TabValue]>).map(
        ([value, tab]) => {
          const isEnabled = enabledTabs.includes(value);

          if (!isEnabled) {
            return (
              <Tab
                key={value}
                label={
                  <Tooltip
                    title={
                      <Box
                        display="flex"
                        alignItems="center"
                        fontSize={12}
                        gap={4}
                      >
                        <Icons.ClockClockwise />
                        <Typography variant="P12R">Coming soon</Typography>
                      </Box>
                    }
                    color="tertiary"
                    placement="top"
                    arrow
                    disableInteractive
                  >
                    <span>{tab.label}</span>
                  </Tooltip>
                }
                sx={{
                  pointerEvents: 'initial!important',
                  color: `${palette.grey[300]}!important`,
                  '&:after': {
                    display: 'none',
                  },
                }}
                disabled
              />
            );
          }

          return (
            <Tab
              key={value}
              value={value}
              component={Link}
              to={
                'reportType' in tab
                  ? makeLinkToReport(tab.reportType)
                  : 'link' in tab
                  ? tab.link
                  : null
              }
              label={
                <Stack direction="row" gap={8}>
                  {tab.label}

                  {tabsWithBetaLabel.includes(tab.label) && <BetaLabelChip />}
                </Stack>
              }
            />
          );
        }
      )}
    </DsTabs>
  );
};
