import compact from 'lodash/compact';

import { clearQueryParamSilently } from '@cast/utils';

import { SearchBoxCriterionGroup } from './constants';
import {
  Criterion,
  SearchBoxCriterion,
  ClientSideSearchCriterion,
} from './types';

export const getInitialValue = (criteria: Criterion[]) => {
  const initial: Record<string, any> = {};
  for (const { key, initialValue } of criteria) {
    // Need strict check to allow display values with 'false' values
    if (initialValue !== undefined && initialValue !== null) {
      initial[key] = initialValue;
    }
  }
  return initial;
};

export const isClient = <T extends unknown = any>(
  criterion: Criterion
): criterion is ClientSideSearchCriterion<T> => {
  const { prop, getValue, matcher } = criterion as ClientSideSearchCriterion;
  return prop !== undefined || getValue !== undefined || matcher !== undefined;
};

export const isServer = (criterion: Criterion) => {
  return !isClient(criterion);
};

export const makeCriteria = <T extends unknown = any>(
  ...criteria: Array<ClientSideSearchCriterion<T> | Criterion | false>
): Array<ClientSideSearchCriterion<T> | Criterion> => {
  return compact(criteria);
};

export const makeSearchBoxCriterion = <T extends unknown = any>(
  criterion: SearchBoxCriterion<T>
) => ({ ...criterion, group: SearchBoxCriterionGroup });

export const makeSearchBoxCriteria = <T extends unknown = any>(
  ..._criteria: Array<SearchBoxCriterion<T> | false>
): SearchBoxCriterion<T>[] => {
  return makeCriteria(..._criteria).map(
    makeSearchBoxCriterion
  ) as SearchBoxCriterion<T>[];
};

type MakeSearchQuery = {
  id: string;
  values?: Record<string, any>;
  freeText?: string;
  searchParams?: URLSearchParams;
};

export const makeSearchQuery = ({
  id,
  values,
  freeText,
  searchParams = new URLSearchParams(),
}: MakeSearchQuery): URLSearchParams => {
  if (Object.keys(values || {}).length) {
    searchParams.append(id, encodeURIComponent(JSON.stringify(values)));
  }
  if (freeText) {
    searchParams.append(`${id}_freeText`, freeText);
  }
  return searchParams;
};

export const clearUrlState = (urlKey: string) => {
  clearQueryParamSilently(urlKey);
  clearQueryParamSilently(`${urlKey}_sortBy`);
  clearQueryParamSilently(`${urlKey}_freeText`);
};
