import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';

import { readStorage, writeStorage } from 'utils/storage';

import { useCostOverTime } from '../../../../cost-report/hooks/useCostOverTime';
import { NormalizedCostMode } from '../../../../cost-report/types/costOverTime';

type ReducerState = {
  activeTab: NormalizedCostMode;
};

type ReducerAction = { type: 'changeActiveTab'; activeTab: NormalizedCostMode };

const makeInitialState = (storageKey: string): ReducerState => ({
  activeTab: readStorage(
    `${storageKey}.active-tab`,
    NormalizedCostMode.NORMALIZED
  ),
});

type NormalizedCostContextValue = [ReducerState, Dispatch<ReducerAction>];

export const NormalizedCostContext = createContext<NormalizedCostContextValue>(
  [] as never
);

const makeReducerFn =
  (storageKey: string) => (state: ReducerState, action: ReducerAction) => {
    switch (action.type) {
      case 'changeActiveTab': {
        writeStorage(`${storageKey}.active-tab`, action.activeTab);
        return {
          ...state,
          activeTab: action.activeTab,
        };
      }
    }
  };

export const NormalizedCostProvider = ({ children }: PropsWithChildren) => {
  const { key } = useCostOverTime();
  const STORAGE_KEY = `cost-report.${key}.normalized`;
  const [state, dispatch] = useReducer(
    makeReducerFn(STORAGE_KEY),
    makeInitialState(STORAGE_KEY)
  );

  return (
    <NormalizedCostContext.Provider value={[state, dispatch]}>
      {children}
    </NormalizedCostContext.Provider>
  );
};
