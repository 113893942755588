import { PrimitiveValue } from './PrimitiveValue';
import { makeSpaces } from '../../utils';
import { IndentationToken, ObjectKeyToken, Token, TokenType } from '../model';

type TokenProps<T extends Token> = {
  token: T;
};
const Indentation = ({ token }: TokenProps<IndentationToken>) => {
  return (
    <span className="DsPreviewYaml-Indentation">
      {makeSpaces(token.value * 2)}
    </span>
  );
};

const ArrayMark = () => {
  return <span className="DsPreviewYaml-ArrayMark">- </span>;
};

const ObjectKey = ({ token }: TokenProps<ObjectKeyToken>) => {
  return (
    <span className="DsPreviewYaml-Key">
      {token.value}
      <span className="DsPreviewYaml-Colon">:</span>{' '}
    </span>
  );
};

type Props = { token: Token };
export const YamlToken = ({ token }: Props) => {
  switch (token.type) {
    case TokenType.INDENTATION:
      return <Indentation token={token} />;
    case TokenType.ARRAY_MARK:
      return <ArrayMark />;
    case TokenType.OBJECT_KEY:
      return <ObjectKey token={token} />;
    case TokenType.PRIMITIVE_VALUE:
      return <PrimitiveValue data={token.value} />;
    default:
      throw new Error('Unknown token');
  }
};
