import { ThemeOptions } from '@mui/material/styles/createTheme';

import { cssVars } from '../../utils/cssVars';

const { getters, setValues } = cssVars(
  {
    background: undefined,
    color: undefined,
  },
  'ds-tooltip'
);

export const tooltipTheme: ThemeOptions['components'] = {
  MuiTooltip: {
    styleOverrides: {
      popper: ({ theme }) => ({
        maxWidth: 384,
        zIndex: theme.zIndex.drawer! + 1,
        ...setValues({
          background: theme.palette.grey[800],
          color: theme.palette.common.white,
        }),

        '&.DS-Tooltip-color-': {
          '&secondary': {
            ...setValues({
              background: theme.palette.grey[200],
              color: theme.palette.grey[700],
            }),
          },
          '&tertiary': {
            ...setValues({
              background: theme.palette.indigo[700],
              color: theme.palette.common.white,
            }),
          },
        },
        '&.DS-Tooltip-size-small': {
          maxWidth: 288,
        },
      }),
      tooltip: ({ theme }) => ({
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        ...theme.typography.P12R,
        backgroundColor: getters.background(),
        color: getters.color(),
        borderRadius: 4,
      }),
      arrow: {
        '&:before': { color: getters.background() },
      },
    },
  },
};
