import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { UserSession } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';
import { redirectToScreen } from 'utils/auth';

type UseSessionQuery = {
  isLoading: boolean;
  userSession?: UserSession;
  error?: unknown;
};

export const useSessionQuery = (): UseSessionQuery => {
  const { isLoading, data, error } = useQuery<UserSession>({
    queryKey: [QueryKeys.AUTH_SESSION],
    queryFn: async () => {
      const { data } = await apiClient.auth.session();
      const { userId, name, nickname, email } = data;
      Sentry.setUser({
        id: userId,
        name,
        nickname,
        email,
      });
      return data;
    },
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
      redirectToScreen(window.location.href);
    }
  }, [error]);

  return {
    isLoading,
    userSession: data,
    error,
  };
};
