import { alertsTheme } from './alerts/alertsTheme';
import { avatarTheme } from './avatar/avatarTheme';
import { boxesThemes } from './boxes/boxesThemes';
import { buttonsTheme } from './buttons/buttonsTheme';
import { chartLegendTheme } from './chart/legend';
import { chartTooltipTheme } from './chart/tooltip';
import { chipTheme } from './chip/chipTheme';
import { codeTheme } from './code';
import { controlsThemes } from './controls';
import { dataPopperTheme } from './data-popper/dataPopperTheme';
import { dialogTheme } from './dialog/dialogTheme';
import { drawerTheme } from './drawer/drawerTheme';
import { feedbackThemes } from './feedback/feedbackThemes';
import { fileDropTheme } from './file-drop/fileDropTheme';
import { badgeTheme } from './indicators/badge/badgeTheme';
import { chartIndicatorTheme } from './indicators/chartIndicator/chartIndicatorTheme';
import { indicatorsThemes } from './indicators/indicatorsThemes';
import { linkTheme } from './link/linkTheme';
import { listTheme } from './lists/listTheme';
import { previewMarkdownTheme } from './markdown/previewMarkdownTheme';
import { navigationTheme } from './navigation';
import { searchTheme } from './search/searchTheme';
import { snackbarTheme } from './snackbar/snackbarTheme';
import { tableTheme } from './table';
import { tooltipTheme } from './tooltip/tooltipTheme';

const chartThemes = { ...chartTooltipTheme, ...chartLegendTheme };

export const componentsThemes = {
  ...alertsTheme,
  ...avatarTheme,
  ...badgeTheme,
  ...buttonsTheme,
  ...dialogTheme,
  ...drawerTheme,
  ...controlsThemes,
  ...tableTheme,
  ...chartIndicatorTheme,
  ...indicatorsThemes,
  ...chartThemes,
  ...feedbackThemes,
  ...listTheme,
  ...chipTheme,
  ...navigationTheme,
  ...codeTheme,
  ...previewMarkdownTheme,
  ...tooltipTheme,
  ...snackbarTheme,
  ...boxesThemes,
  ...searchTheme,
  ...linkTheme,
  ...dataPopperTheme,
  ...fileDropTheme,
};
