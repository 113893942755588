import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useDeleteWebhookMutation = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => {
      return apiClient.notifications.deleteWebhook({ id });
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: [QueryKeys.WEBHOOKS] })
        .then(() => {
          notify.success('Webhook deleted successfully');
          onSuccess?.();
        });
    },
    onError: (error) => {
      notify.error(parseApiError(error));
      onError?.();
    },
  });
};
