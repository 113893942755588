import { ReactNode } from 'react';

import { ColumnProps, Column, RowState, ActionIcon } from '@cast/design-system';

type ActionColumnProps<T> = Partial<ColumnProps<T>> & {
  action?: (row: T, state: RowState<T>) => ReactNode;
};
export const makeActionsColumn = <T extends any>({
  action,
  id = 'actions',
  width = 56,
  ...props
}: ActionColumnProps<T>) => {
  return (
    <Column
      id={id}
      width={width}
      renderCell={
        action
          ? (row, state) => {
              const content = action(row, state);
              if (content) {
                return <ActionIcon>{content}</ActionIcon>;
              }
              return null;
            }
          : undefined
      }
      floating
      stopClickPropagation
      {...props}
    />
  );
};
