import { ReactNode } from 'react';

import { Form } from 'react-final-form';

import { makeValidateSync } from '@cast/utils';

import { RffTextArea } from 'core/forms/rff';
import { GCPCredentials } from 'types/credentials';
import { GCPCredentialsSchema } from 'utils/validators';

type Props = {
  onSubmit: (value: { credentials: GCPCredentials }) => void;
  setSubmitHandler: (v: () => void) => void;
  error?: ReactNode;
};

const validate = makeValidateSync(GCPCredentialsSchema);

export const GCPCredentialsForm = ({
  onSubmit,
  setSubmitHandler,
  error,
}: Props) => {
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        setSubmitHandler(handleSubmit);
        return (
          <form onSubmit={handleSubmit} noValidate>
            <RffTextArea
              testId="gcp-credentials-input"
              autoComplete="off"
              id="credentials-gcp"
              name="credentials.json"
              inputProps={{ 'aria-label': 'credentials gcp json' }}
              rows={5}
              required
              fullWidth
              placeholder="Service Account JSON"
              error={error}
            />
          </form>
        );
      }}
    />
  );
};
