import { createContext } from 'react';

import { User } from '@cast/types';

export type AuthState = {
  user?: User;
  isAuthenticated: boolean;
};

export const AuthContext = createContext<AuthState>({
  isAuthenticated: false,
});
