import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import mixpanel from 'mixpanel-browser';
import { ModalProvider } from 'mui-modal-provider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from 'react-router-dom';

import { ANALYTICS_ENABLED, IS_PROD } from 'common/constants';
import { AbilityProvider } from 'core/ability';
import { AccessControlProvider } from 'core/access-control/AccessControlProvider';
import { AuthProvider } from 'core/auth';
import { RootErrorBoundary } from 'core/error-handling';
import { HubspotContactProvider } from 'core/hubspot';
import { Initializer } from 'core/initializer';
import { ScheduleMeetingOverlay } from 'core/integrations';
import { PortalsProvider } from 'core/portals';
import { queryClient } from 'core/react-query';
import { SnackbarsProvider } from 'core/snackbars';
import { ThemeProvider } from 'core/ThemeProvider';
import { OrganizationsProvider } from 'features/organization';
import { AuthController } from 'pages/auth';

import 'monitoring/sentry';

import { App } from './App';
import './assets/fonts/fonts.css';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="*"
      element={
        <Routes>
          <Route path="/auth/*" Component={AuthController} />
          <Route
            path="*"
            Component={() => (
              <DndProvider backend={HTML5Backend}>
                <QueryClientProvider client={queryClient}>
                  <RootErrorBoundary>
                    <SnackbarsProvider>
                      <PortalsProvider>
                        <AuthProvider>
                          <OrganizationsProvider>
                            <AccessControlProvider useCurrentUser>
                              <AbilityProvider>
                                <Initializer>
                                  <HubspotContactProvider>
                                    <ModalProvider>
                                      <App />
                                      <ScheduleMeetingOverlay />
                                    </ModalProvider>
                                  </HubspotContactProvider>
                                </Initializer>
                              </AbilityProvider>
                            </AccessControlProvider>
                          </OrganizationsProvider>
                        </AuthProvider>
                        <ReactQueryDevtools
                          initialIsOpen={false}
                          buttonPosition="bottom-left"
                        />
                      </PortalsProvider>
                    </SnackbarsProvider>
                  </RootErrorBoundary>
                </QueryClientProvider>
              </DndProvider>
            )}
          />
        </Routes>
      }
    />
  )
);

const runApp = () => {
  createRoot(document.getElementById('root')!).render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

if (import.meta.env.VITE_ENABLE_MSW) {
  import('./testing/msw/browser').then(({ worker }) => {
    worker.start().then(() => runApp());
  });
} else {
  runApp();
}

window.hsConversationsSettings = {
  loadImmediately: false,
};

if (ANALYTICS_ENABLED) {
  try {
    mixpanel.init('b0304a3c6ba2e1f6b4dce198f732a7c1', {
      ignore_dnt: true,
      api_host: IS_PROD ? window.location.origin + '/analytics' : undefined,
    });
  } catch (e) {
    console.error('Failed to init mixpanel');
  }
}
