/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AIEnablerProvidersAPIUpdateProviderRequest } from '../models';
// @ts-ignore
import { AioptimizerV1GetProvidersResponse } from '../models';
// @ts-ignore
import { AioptimizerV1PredictModelsRequest } from '../models';
// @ts-ignore
import { AioptimizerV1PredictModelsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1PrioritizeProvidersRequest } from '../models';
// @ts-ignore
import { AioptimizerV1PrioritizeProvidersResponse } from '../models';
// @ts-ignore
import { AioptimizerV1RegisterProvidersRequest } from '../models';
/**
 * AIEnablerProvidersAPIApi - axios parameter creator
 * @export
 */
export const AIEnablerProvidersAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes LLM provider.
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIDeleteProvider: async (providerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('aIEnablerProvidersAPIDeleteProvider', 'providerId', providerId)
            const localVarPath = `/v1/llm/providers/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of registered LLM providers. If the `category` query parameter is provided, returns the providers that can be used for routing for the given category.
         * @param {string} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIGetProviders: async (category?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Predicts the best model order based on the prompt.
         * @param {AioptimizerV1PredictModelsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIPredictModels: async (body: AioptimizerV1PredictModelsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIEnablerProvidersAPIPredictModels', 'body', body)
            const localVarPath = `/v1/llm/models:predict`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Prioritizes registered LLM providers.
         * @param {AioptimizerV1PrioritizeProvidersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIPrioritizeProviders: async (body: AioptimizerV1PrioritizeProvidersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIEnablerProvidersAPIPrioritizeProviders', 'body', body)
            const localVarPath = `/v1/llm/providers:prioritize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Registers LLM providers.
         * @param {AioptimizerV1RegisterProvidersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIRegisterProviders: async (body: AioptimizerV1RegisterProvidersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIEnablerProvidersAPIRegisterProviders', 'body', body)
            const localVarPath = `/v1/llm/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the registered LLM provider.
         * @param {string} providerId 
         * @param {AIEnablerProvidersAPIUpdateProviderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIUpdateProvider: async (providerId: string, body: AIEnablerProvidersAPIUpdateProviderRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('aIEnablerProvidersAPIUpdateProvider', 'providerId', providerId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIEnablerProvidersAPIUpdateProvider', 'body', body)
            const localVarPath = `/v1/llm/providers/{providerId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIEnablerProvidersAPIApi - functional programming interface
 * @export
 */
export const AIEnablerProvidersAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIEnablerProvidersAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes LLM provider.
         * @param {string} providerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerProvidersAPIDeleteProvider(providerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerProvidersAPIDeleteProvider(providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerProvidersAPIApi.aIEnablerProvidersAPIDeleteProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the list of registered LLM providers. If the `category` query parameter is provided, returns the providers that can be used for routing for the given category.
         * @param {string} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerProvidersAPIGetProviders(category?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerProvidersAPIGetProviders(category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerProvidersAPIApi.aIEnablerProvidersAPIGetProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Predicts the best model order based on the prompt.
         * @param {AioptimizerV1PredictModelsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerProvidersAPIPredictModels(body: AioptimizerV1PredictModelsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1PredictModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerProvidersAPIPredictModels(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerProvidersAPIApi.aIEnablerProvidersAPIPredictModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Prioritizes registered LLM providers.
         * @param {AioptimizerV1PrioritizeProvidersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerProvidersAPIPrioritizeProviders(body: AioptimizerV1PrioritizeProvidersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1PrioritizeProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerProvidersAPIPrioritizeProviders(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerProvidersAPIApi.aIEnablerProvidersAPIPrioritizeProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Registers LLM providers.
         * @param {AioptimizerV1RegisterProvidersRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerProvidersAPIRegisterProviders(body: AioptimizerV1RegisterProvidersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerProvidersAPIRegisterProviders(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerProvidersAPIApi.aIEnablerProvidersAPIRegisterProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the registered LLM provider.
         * @param {string} providerId 
         * @param {AIEnablerProvidersAPIUpdateProviderRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerProvidersAPIUpdateProvider(providerId: string, body: AIEnablerProvidersAPIUpdateProviderRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerProvidersAPIUpdateProvider(providerId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerProvidersAPIApi.aIEnablerProvidersAPIUpdateProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AIEnablerProvidersAPIApi - factory interface
 * @export
 */
export const AIEnablerProvidersAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIEnablerProvidersAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes LLM provider.
         * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIDeleteProviderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIDeleteProvider(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIDeleteProviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIEnablerProvidersAPIDeleteProvider(requestParameters.providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of registered LLM providers. If the `category` query parameter is provided, returns the providers that can be used for routing for the given category.
         * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIGetProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIGetProviders(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIGetProvidersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetProvidersResponse> {
            return localVarFp.aIEnablerProvidersAPIGetProviders(requestParameters.category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Predicts the best model order based on the prompt.
         * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIPredictModelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIPredictModels(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIPredictModelsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1PredictModelsResponse> {
            return localVarFp.aIEnablerProvidersAPIPredictModels(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Prioritizes registered LLM providers.
         * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIPrioritizeProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIPrioritizeProviders(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIPrioritizeProvidersRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1PrioritizeProvidersResponse> {
            return localVarFp.aIEnablerProvidersAPIPrioritizeProviders(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Registers LLM providers.
         * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIRegisterProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIRegisterProviders(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIRegisterProvidersRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIEnablerProvidersAPIRegisterProviders(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the registered LLM provider.
         * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIUpdateProviderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerProvidersAPIUpdateProvider(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIUpdateProviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIEnablerProvidersAPIUpdateProvider(requestParameters.providerId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aIEnablerProvidersAPIDeleteProvider operation in AIEnablerProvidersAPIApi.
 * @export
 * @interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIDeleteProviderRequest
 */
export interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIDeleteProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerProvidersAPIApiAIEnablerProvidersAPIDeleteProvider
     */
    readonly providerId: string
}

/**
 * Request parameters for aIEnablerProvidersAPIGetProviders operation in AIEnablerProvidersAPIApi.
 * @export
 * @interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIGetProvidersRequest
 */
export interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIGetProvidersRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerProvidersAPIApiAIEnablerProvidersAPIGetProviders
     */
    readonly category?: string
}

/**
 * Request parameters for aIEnablerProvidersAPIPredictModels operation in AIEnablerProvidersAPIApi.
 * @export
 * @interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIPredictModelsRequest
 */
export interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIPredictModelsRequest {
    /**
     * 
     * @type {AioptimizerV1PredictModelsRequest}
     * @memberof AIEnablerProvidersAPIApiAIEnablerProvidersAPIPredictModels
     */
    readonly body: AioptimizerV1PredictModelsRequest
}

/**
 * Request parameters for aIEnablerProvidersAPIPrioritizeProviders operation in AIEnablerProvidersAPIApi.
 * @export
 * @interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIPrioritizeProvidersRequest
 */
export interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIPrioritizeProvidersRequest {
    /**
     * 
     * @type {AioptimizerV1PrioritizeProvidersRequest}
     * @memberof AIEnablerProvidersAPIApiAIEnablerProvidersAPIPrioritizeProviders
     */
    readonly body: AioptimizerV1PrioritizeProvidersRequest
}

/**
 * Request parameters for aIEnablerProvidersAPIRegisterProviders operation in AIEnablerProvidersAPIApi.
 * @export
 * @interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIRegisterProvidersRequest
 */
export interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIRegisterProvidersRequest {
    /**
     * 
     * @type {AioptimizerV1RegisterProvidersRequest}
     * @memberof AIEnablerProvidersAPIApiAIEnablerProvidersAPIRegisterProviders
     */
    readonly body: AioptimizerV1RegisterProvidersRequest
}

/**
 * Request parameters for aIEnablerProvidersAPIUpdateProvider operation in AIEnablerProvidersAPIApi.
 * @export
 * @interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIUpdateProviderRequest
 */
export interface AIEnablerProvidersAPIApiAIEnablerProvidersAPIUpdateProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerProvidersAPIApiAIEnablerProvidersAPIUpdateProvider
     */
    readonly providerId: string

    /**
     * 
     * @type {AIEnablerProvidersAPIUpdateProviderRequest}
     * @memberof AIEnablerProvidersAPIApiAIEnablerProvidersAPIUpdateProvider
     */
    readonly body: AIEnablerProvidersAPIUpdateProviderRequest
}

/**
 * AIEnablerProvidersAPIApi - object-oriented interface
 * @export
 * @class AIEnablerProvidersAPIApi
 * @extends {BaseAPI}
 */
export class AIEnablerProvidersAPIApi extends BaseAPI {
    /**
     * 
     * @summary Deletes LLM provider.
     * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIDeleteProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerProvidersAPIApi
     */
    public aIEnablerProvidersAPIDeleteProvider(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIDeleteProviderRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerProvidersAPIApiFp(this.configuration).aIEnablerProvidersAPIDeleteProvider(requestParameters.providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of registered LLM providers. If the `category` query parameter is provided, returns the providers that can be used for routing for the given category.
     * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIGetProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerProvidersAPIApi
     */
    public aIEnablerProvidersAPIGetProviders(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIGetProvidersRequest = {}, options?: RawAxiosRequestConfig) {
        return AIEnablerProvidersAPIApiFp(this.configuration).aIEnablerProvidersAPIGetProviders(requestParameters.category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Predicts the best model order based on the prompt.
     * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIPredictModelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerProvidersAPIApi
     */
    public aIEnablerProvidersAPIPredictModels(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIPredictModelsRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerProvidersAPIApiFp(this.configuration).aIEnablerProvidersAPIPredictModels(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Prioritizes registered LLM providers.
     * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIPrioritizeProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerProvidersAPIApi
     */
    public aIEnablerProvidersAPIPrioritizeProviders(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIPrioritizeProvidersRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerProvidersAPIApiFp(this.configuration).aIEnablerProvidersAPIPrioritizeProviders(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Registers LLM providers.
     * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIRegisterProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerProvidersAPIApi
     */
    public aIEnablerProvidersAPIRegisterProviders(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIRegisterProvidersRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerProvidersAPIApiFp(this.configuration).aIEnablerProvidersAPIRegisterProviders(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the registered LLM provider.
     * @param {AIEnablerProvidersAPIApiAIEnablerProvidersAPIUpdateProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerProvidersAPIApi
     */
    public aIEnablerProvidersAPIUpdateProvider(requestParameters: AIEnablerProvidersAPIApiAIEnablerProvidersAPIUpdateProviderRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerProvidersAPIApiFp(this.configuration).aIEnablerProvidersAPIUpdateProvider(requestParameters.providerId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

