import { Box } from '@mui/material';

import { Tooltip } from '../../../tooltip';
import { useTableContext } from '../../hooks';
import { ColumnGroupModel } from '../../types';

type Props = { group: ColumnGroupModel };

export const GroupHeader = ({ group }: Props) => {
  const {
    api: {
      current: {
        columnsInfo: { lockedColumnsWidth },
      },
    },
    tableProps: { InfoIcon },
  } = useTableContext();

  return (
    <Box
      key={group.id}
      className="DS-Table-GroupHeaderWrapper"
      left={
        group.locked
          ? group.startOffset
          : group.startOffset - lockedColumnsWidth
      }
    >
      <Box
        className="DS-Table-GroupHeader"
        width={group.endOffset - group.startOffset}
        sx={
          group.sx
            ? // Prevent using && to override theme styling
              { '&.DS-Table-GroupHeader': group.sx as any }
            : undefined
        }
        data-testid={group.testId}
      >
        {group.title}
        {group.infoTooltip && (
          <Tooltip
            arrow
            placement="top"
            {...(group.infoTooltipProps || {})}
            title={group.infoTooltipProps?.title ?? group.infoTooltip}
          >
            {group.InfoIcon ? (
              <group.InfoIcon />
            ) : (
              <InfoIcon className="DS-Table-GroupHeaderIcon" />
            )}
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
