import { MouseEvent, ReactNode } from 'react';

import { Field, FieldProps } from 'react-final-form';

import { Autocomplete, AutocompleteProps } from '@cast/design-system';

import { useControlsGroupState } from './controls-group';
import { useRffFormState } from '../hooks';
import { showErrorOnChange } from '../utils';

export type RffAutocompleteProps<T> = AutocompleteProps<T, any> & {
  name: string;
  label?: string;
  renderError?: (error: string | string[]) => ReactNode;
  fieldProps?: Partial<FieldProps<T, any>>;
  onMouseOver?: MouseEvent;
  onMouseLeave?: MouseEvent;
};

export const RffAutocomplete = <T extends unknown>({
  renderError,
  fieldProps,
  label,
  ...autocompleteProps
}: RffAutocompleteProps<T>) => {
  const { mode } = useRffFormState();
  const { disabled, readOnly, name } = useControlsGroupState({
    disabled: autocompleteProps.disabled,
    readOnly: mode === 'view' || autocompleteProps.inputProps?.readOnly,
    name: autocompleteProps.name,
  });

  return (
    <Field
      {...fieldProps}
      name={name || autocompleteProps.name}
      render={(props) => {
        const {
          input: { onBlur, onFocus, onChange, value },
          meta,
        } = props;
        const showError = showErrorOnChange(meta);
        return (
          <Autocomplete
            {...(autocompleteProps as any)}
            value={value || null}
            onChange={(option: any, val) => {
              autocompleteProps.onChange?.(option, val);
              onChange(val);
            }}
            inputProps={{
              readOnly,
              label,
              ...autocompleteProps.inputProps,
              onBlur,
              onFocus,
              error: showError
                ? renderError
                  ? renderError(meta.error)
                  : meta.error
                : undefined,
              // Tooltip passes these for it's direct child
              onMouseOver: autocompleteProps.onMouseOver,
              onMouseLeave: autocompleteProps.onMouseLeave,
            }}
            success={meta.touched && meta.valid}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
