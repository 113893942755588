import { styled, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

export type IndicatorProps = {
  size: 'large' | 'medium' | 'small' | 'dot';
  color: 'blue' | 'ocean' | 'indigo' | 'grey';
  count: number;
  sx?: SxProps;
};

const IndicatorRoot = styled('div', {
  name: 'DsIndicator',
  slot: 'Root',
  target: 'DsIndicator-root',
  overridesResolver: (props, styles) => [
    styles.root,
    styles[props.color],
    styles[props.size],
  ],
  shouldForwardProp: (propName) =>
    propName !== 'size' && propName !== 'color' && propName !== 'count',
})<IndicatorProps>({});

const IndicatorLabel = styled('span', {
  name: 'DsIndicator',
  slot: 'Label',
  target: 'DsIndicator-label',
  overridesResolver: (_, styles) => [styles.label],
})({});

export const Indicator = (props: IndicatorProps) => {
  const theme = useTheme();
  return (
    <IndicatorRoot
      {...(theme.components?.DsIndicator?.defaultProps ?? {})}
      {...props}
    >
      <IndicatorLabel>{props.count}</IndicatorLabel>
    </IndicatorRoot>
  );
};
