import { ThemeOptions } from '@mui/material';

export const ratioProgressBarTheme: ThemeOptions['components'] = {
  DsRatioProgressBar: {
    styleOverrides: {
      // Slots
      root: ({ theme }) => ({
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        border: `1px solid ${theme.palette.grey[700]}`,
        boxSizing: 'border-box',
      }),
      barContainer: {
        position: 'absolute',
        inset: 0,
        display: 'flex',
        borderRadius: 'inherit',
      },
      bar: {
        borderRadius: 'inherit',
      },
      // Modifiers
      large: {
        height: 8,
        borderRadius: 4,
      },
      medium: {
        height: 4,
        borderRadius: 2,
      },
      small: {
        height: 2,
        borderRadius: 1,
      },
      noBorders: {
        border: 'none',
      },
    },
  },
};
