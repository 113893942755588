/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CastaiRbacV1beta1AccessResolveResponse } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1CreateGroupRequestGroup } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1CreateRoleBindingsRequestRoleBinding } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1GetOrganizationResourcesResponse } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1Group } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1GroupUpdate } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1ListGroupsResponse } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1ListRoleBindingsResponse } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1ListRolesResponse } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1ResolveRoleBindingSubjectsResponse } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1Role } from '../models';
// @ts-ignore
import { CastaiRbacV1beta1RoleBinding } from '../models';
// @ts-ignore
import { GroupsIsTheGroupsToBeUpdated } from '../models';
// @ts-ignore
import { RbacServiceAPIAccessResolveRequest } from '../models';
// @ts-ignore
import { RoleBindingIsTheRoleBindingToBeUpdated } from '../models';
/**
 * RbacServiceAPIApi - axios parameter creator
 * @export
 */
export const RbacServiceAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary AccessResolve is used to resolve access for specific resource type.
         * @param {string} organizationId OrganizationID is the organization ID for which the access is resolved.
         * @param {RbacServiceAPIAccessResolveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIAccessResolve: async (organizationId: string, body: RbacServiceAPIAccessResolveRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIAccessResolve', 'organizationId', organizationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('rbacServiceAPIAccessResolve', 'body', body)
            const localVarPath = `/v1/organizations/{organizationId}/access-resolve`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CreateGroup creates a group for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is created.
         * @param {CastaiRbacV1beta1CreateGroupRequestGroup} group Group is the group to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPICreateGroup: async (organizationId: string, group: CastaiRbacV1beta1CreateGroupRequestGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPICreateGroup', 'organizationId', organizationId)
            // verify required parameter 'group' is not null or undefined
            assertParamExists('rbacServiceAPICreateGroup', 'group', group)
            const localVarPath = `/v1/organizations/{organizationId}/groups`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CreateRoleBindings creates role bindings for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role binding is created.
         * @param {Array<CastaiRbacV1beta1CreateRoleBindingsRequestRoleBinding>} roleBindings RoleBindings is the array of role bindings to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPICreateRoleBindings: async (organizationId: string, roleBindings: Array<CastaiRbacV1beta1CreateRoleBindingsRequestRoleBinding>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPICreateRoleBindings', 'organizationId', organizationId)
            // verify required parameter 'roleBindings' is not null or undefined
            assertParamExists('rbacServiceAPICreateRoleBindings', 'roleBindings', roleBindings)
            const localVarPath = `/v1/organizations/{organizationId}/role-bindings`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleBindings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteGroup deletes the group for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is deleted.
         * @param {string} id ID is the id of the groups to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIDeleteGroup: async (organizationId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIDeleteGroup', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rbacServiceAPIDeleteGroup', 'id', id)
            const localVarPath = `/v1/organizations/{organizationId}/groups/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteRoleBinding deletes the role binding for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role binding is deleted.
         * @param {string} id ID is the id of the role binding to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIDeleteRoleBinding: async (organizationId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIDeleteRoleBinding', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rbacServiceAPIDeleteRoleBinding', 'id', id)
            const localVarPath = `/v1/organizations/{organizationId}/role-bindings/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetGroup fetches a group for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is fetched.
         * @param {string} id ID is the id of the groups to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIGetGroup: async (organizationId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIGetGroup', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rbacServiceAPIGetGroup', 'id', id)
            const localVarPath = `/v1/organizations/{organizationId}/groups/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetOrganizationResources returns details about organization resources.
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIGetOrganizationResources: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIGetOrganizationResources', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/resources`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetRole fetches the role for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role is fetched.
         * @param {string} id ID is the id of the role to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIGetRole: async (organizationId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIGetRole', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rbacServiceAPIGetRole', 'id', id)
            const localVarPath = `/v1/organizations/{organizationId}/roles/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetRoleBinding fetches the role binding for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role binding is fetched.
         * @param {string} id ID is the id of the role binding to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIGetRoleBinding: async (organizationId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIGetRoleBinding', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rbacServiceAPIGetRoleBinding', 'id', id)
            const localVarPath = `/v1/organizations/{organizationId}/role-bindings/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListGroups lists the groups for the organization.
         * @param {string} organizationId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIListGroups: async (organizationId: string, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIListGroups', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/groups`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListRoleBindings lists the role bindings for the organization.
         * @param {string} organizationId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIListRoleBindings: async (organizationId: string, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIListRoleBindings', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/role-bindings`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListRoles lists the roles for the organization.
         * @param {string} organizationId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIListRoles: async (organizationId: string, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIListRoles', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/roles`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ResolveRoleBindingSubjects fetches the subjects details for the role binding.
         * @param {string} organizationId OrganizationID is the organization ID for which the role bindings are resolved.
         * @param {string} id ID is the ID of the role binding to be resolved.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIResolveRoleBindingSubjects: async (organizationId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIResolveRoleBindingSubjects', 'organizationId', organizationId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rbacServiceAPIResolveRoleBindingSubjects', 'id', id)
            const localVarPath = `/v1/organizations/{organizationId}/role-bindings/{id}/resolve-subjects`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateGroup updates the group for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is updated.
         * @param {string} groupId ID is the unique identifier of the group.
         * @param {GroupsIsTheGroupsToBeUpdated} group Groups is the groups to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIUpdateGroup: async (organizationId: string, groupId: string, group: GroupsIsTheGroupsToBeUpdated, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIUpdateGroup', 'organizationId', organizationId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('rbacServiceAPIUpdateGroup', 'groupId', groupId)
            // verify required parameter 'group' is not null or undefined
            assertParamExists('rbacServiceAPIUpdateGroup', 'group', group)
            const localVarPath = `/v1/organizations/{organizationId}/groups/{group.id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"group.id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateGroups updates list of groups for the organization, by adding or removing users from the groups.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is updated.
         * @param {Array<CastaiRbacV1beta1GroupUpdate>} groups Groups is the groups to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIUpdateGroups: async (organizationId: string, groups: Array<CastaiRbacV1beta1GroupUpdate>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIUpdateGroups', 'organizationId', organizationId)
            // verify required parameter 'groups' is not null or undefined
            assertParamExists('rbacServiceAPIUpdateGroups', 'groups', groups)
            const localVarPath = `/v1/organizations/{organizationId}/groups`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groups, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateRoleBinding updates the role binding for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role binding is updated.
         * @param {string} roleBindingId ID is the unique identifier of the role binding.
         * @param {RoleBindingIsTheRoleBindingToBeUpdated} roleBinding RoleBinding is the role binding to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIUpdateRoleBinding: async (organizationId: string, roleBindingId: string, roleBinding: RoleBindingIsTheRoleBindingToBeUpdated, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('rbacServiceAPIUpdateRoleBinding', 'organizationId', organizationId)
            // verify required parameter 'roleBindingId' is not null or undefined
            assertParamExists('rbacServiceAPIUpdateRoleBinding', 'roleBindingId', roleBindingId)
            // verify required parameter 'roleBinding' is not null or undefined
            assertParamExists('rbacServiceAPIUpdateRoleBinding', 'roleBinding', roleBinding)
            const localVarPath = `/v1/organizations/{organizationId}/role-bindings/{roleBinding.id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"roleBinding.id"}}`, encodeURIComponent(String(roleBindingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleBinding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RbacServiceAPIApi - functional programming interface
 * @export
 */
export const RbacServiceAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RbacServiceAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary AccessResolve is used to resolve access for specific resource type.
         * @param {string} organizationId OrganizationID is the organization ID for which the access is resolved.
         * @param {RbacServiceAPIAccessResolveRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIAccessResolve(organizationId: string, body: RbacServiceAPIAccessResolveRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1AccessResolveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIAccessResolve(organizationId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIAccessResolve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary CreateGroup creates a group for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is created.
         * @param {CastaiRbacV1beta1CreateGroupRequestGroup} group Group is the group to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPICreateGroup(organizationId: string, group: CastaiRbacV1beta1CreateGroupRequestGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPICreateGroup(organizationId, group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPICreateGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary CreateRoleBindings creates role bindings for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role binding is created.
         * @param {Array<CastaiRbacV1beta1CreateRoleBindingsRequestRoleBinding>} roleBindings RoleBindings is the array of role bindings to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPICreateRoleBindings(organizationId: string, roleBindings: Array<CastaiRbacV1beta1CreateRoleBindingsRequestRoleBinding>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CastaiRbacV1beta1RoleBinding>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPICreateRoleBindings(organizationId, roleBindings, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPICreateRoleBindings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DeleteGroup deletes the group for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is deleted.
         * @param {string} id ID is the id of the groups to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIDeleteGroup(organizationId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIDeleteGroup(organizationId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIDeleteGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DeleteRoleBinding deletes the role binding for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role binding is deleted.
         * @param {string} id ID is the id of the role binding to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIDeleteRoleBinding(organizationId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIDeleteRoleBinding(organizationId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIDeleteRoleBinding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetGroup fetches a group for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is fetched.
         * @param {string} id ID is the id of the groups to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIGetGroup(organizationId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIGetGroup(organizationId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIGetGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetOrganizationResources returns details about organization resources.
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIGetOrganizationResources(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1GetOrganizationResourcesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIGetOrganizationResources(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIGetOrganizationResources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetRole fetches the role for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role is fetched.
         * @param {string} id ID is the id of the role to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIGetRole(organizationId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIGetRole(organizationId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIGetRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetRoleBinding fetches the role binding for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role binding is fetched.
         * @param {string} id ID is the id of the role binding to be fetched.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIGetRoleBinding(organizationId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1RoleBinding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIGetRoleBinding(organizationId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIGetRoleBinding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListGroups lists the groups for the organization.
         * @param {string} organizationId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIListGroups(organizationId: string, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1ListGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIListGroups(organizationId, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIListGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListRoleBindings lists the role bindings for the organization.
         * @param {string} organizationId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIListRoleBindings(organizationId: string, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1ListRoleBindingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIListRoleBindings(organizationId, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIListRoleBindings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListRoles lists the roles for the organization.
         * @param {string} organizationId 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIListRoles(organizationId: string, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1ListRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIListRoles(organizationId, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIListRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ResolveRoleBindingSubjects fetches the subjects details for the role binding.
         * @param {string} organizationId OrganizationID is the organization ID for which the role bindings are resolved.
         * @param {string} id ID is the ID of the role binding to be resolved.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIResolveRoleBindingSubjects(organizationId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1ResolveRoleBindingSubjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIResolveRoleBindingSubjects(organizationId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIResolveRoleBindingSubjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UpdateGroup updates the group for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is updated.
         * @param {string} groupId ID is the unique identifier of the group.
         * @param {GroupsIsTheGroupsToBeUpdated} group Groups is the groups to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIUpdateGroup(organizationId: string, groupId: string, group: GroupsIsTheGroupsToBeUpdated, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIUpdateGroup(organizationId, groupId, group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIUpdateGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UpdateGroups updates list of groups for the organization, by adding or removing users from the groups.
         * @param {string} organizationId OrganizationID is the organization ID for which the group is updated.
         * @param {Array<CastaiRbacV1beta1GroupUpdate>} groups Groups is the groups to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIUpdateGroups(organizationId: string, groups: Array<CastaiRbacV1beta1GroupUpdate>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CastaiRbacV1beta1Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIUpdateGroups(organizationId, groups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIUpdateGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UpdateRoleBinding updates the role binding for the organization.
         * @param {string} organizationId OrganizationID is the organization ID for which the role binding is updated.
         * @param {string} roleBindingId ID is the unique identifier of the role binding.
         * @param {RoleBindingIsTheRoleBindingToBeUpdated} roleBinding RoleBinding is the role binding to be updated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rbacServiceAPIUpdateRoleBinding(organizationId: string, roleBindingId: string, roleBinding: RoleBindingIsTheRoleBindingToBeUpdated, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiRbacV1beta1RoleBinding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rbacServiceAPIUpdateRoleBinding(organizationId, roleBindingId, roleBinding, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RbacServiceAPIApi.rbacServiceAPIUpdateRoleBinding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RbacServiceAPIApi - factory interface
 * @export
 */
export const RbacServiceAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RbacServiceAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary AccessResolve is used to resolve access for specific resource type.
         * @param {RbacServiceAPIApiRbacServiceAPIAccessResolveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIAccessResolve(requestParameters: RbacServiceAPIApiRbacServiceAPIAccessResolveRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1AccessResolveResponse> {
            return localVarFp.rbacServiceAPIAccessResolve(requestParameters.organizationId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CreateGroup creates a group for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPICreateGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPICreateGroup(requestParameters: RbacServiceAPIApiRbacServiceAPICreateGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1Group> {
            return localVarFp.rbacServiceAPICreateGroup(requestParameters.organizationId, requestParameters.group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CreateRoleBindings creates role bindings for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPICreateRoleBindingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPICreateRoleBindings(requestParameters: RbacServiceAPIApiRbacServiceAPICreateRoleBindingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CastaiRbacV1beta1RoleBinding>> {
            return localVarFp.rbacServiceAPICreateRoleBindings(requestParameters.organizationId, requestParameters.roleBindings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteGroup deletes the group for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIDeleteGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIDeleteGroup(requestParameters: RbacServiceAPIApiRbacServiceAPIDeleteGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.rbacServiceAPIDeleteGroup(requestParameters.organizationId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteRoleBinding deletes the role binding for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIDeleteRoleBindingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIDeleteRoleBinding(requestParameters: RbacServiceAPIApiRbacServiceAPIDeleteRoleBindingRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.rbacServiceAPIDeleteRoleBinding(requestParameters.organizationId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetGroup fetches a group for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIGetGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIGetGroup(requestParameters: RbacServiceAPIApiRbacServiceAPIGetGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1Group> {
            return localVarFp.rbacServiceAPIGetGroup(requestParameters.organizationId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetOrganizationResources returns details about organization resources.
         * @param {RbacServiceAPIApiRbacServiceAPIGetOrganizationResourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIGetOrganizationResources(requestParameters: RbacServiceAPIApiRbacServiceAPIGetOrganizationResourcesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1GetOrganizationResourcesResponse> {
            return localVarFp.rbacServiceAPIGetOrganizationResources(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetRole fetches the role for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIGetRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIGetRole(requestParameters: RbacServiceAPIApiRbacServiceAPIGetRoleRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1Role> {
            return localVarFp.rbacServiceAPIGetRole(requestParameters.organizationId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetRoleBinding fetches the role binding for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIGetRoleBindingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIGetRoleBinding(requestParameters: RbacServiceAPIApiRbacServiceAPIGetRoleBindingRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1RoleBinding> {
            return localVarFp.rbacServiceAPIGetRoleBinding(requestParameters.organizationId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListGroups lists the groups for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIListGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIListGroups(requestParameters: RbacServiceAPIApiRbacServiceAPIListGroupsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1ListGroupsResponse> {
            return localVarFp.rbacServiceAPIListGroups(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListRoleBindings lists the role bindings for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIListRoleBindingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIListRoleBindings(requestParameters: RbacServiceAPIApiRbacServiceAPIListRoleBindingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1ListRoleBindingsResponse> {
            return localVarFp.rbacServiceAPIListRoleBindings(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListRoles lists the roles for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIListRolesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIListRoles(requestParameters: RbacServiceAPIApiRbacServiceAPIListRolesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1ListRolesResponse> {
            return localVarFp.rbacServiceAPIListRoles(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ResolveRoleBindingSubjects fetches the subjects details for the role binding.
         * @param {RbacServiceAPIApiRbacServiceAPIResolveRoleBindingSubjectsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIResolveRoleBindingSubjects(requestParameters: RbacServiceAPIApiRbacServiceAPIResolveRoleBindingSubjectsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1ResolveRoleBindingSubjectsResponse> {
            return localVarFp.rbacServiceAPIResolveRoleBindingSubjects(requestParameters.organizationId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateGroup updates the group for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIUpdateGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIUpdateGroup(requestParameters: RbacServiceAPIApiRbacServiceAPIUpdateGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1Group> {
            return localVarFp.rbacServiceAPIUpdateGroup(requestParameters.organizationId, requestParameters.groupId, requestParameters.group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateGroups updates list of groups for the organization, by adding or removing users from the groups.
         * @param {RbacServiceAPIApiRbacServiceAPIUpdateGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIUpdateGroups(requestParameters: RbacServiceAPIApiRbacServiceAPIUpdateGroupsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CastaiRbacV1beta1Group>> {
            return localVarFp.rbacServiceAPIUpdateGroups(requestParameters.organizationId, requestParameters.groups, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateRoleBinding updates the role binding for the organization.
         * @param {RbacServiceAPIApiRbacServiceAPIUpdateRoleBindingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rbacServiceAPIUpdateRoleBinding(requestParameters: RbacServiceAPIApiRbacServiceAPIUpdateRoleBindingRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiRbacV1beta1RoleBinding> {
            return localVarFp.rbacServiceAPIUpdateRoleBinding(requestParameters.organizationId, requestParameters.roleBindingId, requestParameters.roleBinding, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for rbacServiceAPIAccessResolve operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIAccessResolveRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIAccessResolveRequest {
    /**
     * OrganizationID is the organization ID for which the access is resolved.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIAccessResolve
     */
    readonly organizationId: string

    /**
     * 
     * @type {RbacServiceAPIAccessResolveRequest}
     * @memberof RbacServiceAPIApiRbacServiceAPIAccessResolve
     */
    readonly body: RbacServiceAPIAccessResolveRequest
}

/**
 * Request parameters for rbacServiceAPICreateGroup operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPICreateGroupRequest
 */
export interface RbacServiceAPIApiRbacServiceAPICreateGroupRequest {
    /**
     * OrganizationID is the organization ID for which the group is created.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPICreateGroup
     */
    readonly organizationId: string

    /**
     * Group is the group to be created.
     * @type {CastaiRbacV1beta1CreateGroupRequestGroup}
     * @memberof RbacServiceAPIApiRbacServiceAPICreateGroup
     */
    readonly group: CastaiRbacV1beta1CreateGroupRequestGroup
}

/**
 * Request parameters for rbacServiceAPICreateRoleBindings operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPICreateRoleBindingsRequest
 */
export interface RbacServiceAPIApiRbacServiceAPICreateRoleBindingsRequest {
    /**
     * OrganizationID is the organization ID for which the role binding is created.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPICreateRoleBindings
     */
    readonly organizationId: string

    /**
     * RoleBindings is the array of role bindings to be created.
     * @type {Array<CastaiRbacV1beta1CreateRoleBindingsRequestRoleBinding>}
     * @memberof RbacServiceAPIApiRbacServiceAPICreateRoleBindings
     */
    readonly roleBindings: Array<CastaiRbacV1beta1CreateRoleBindingsRequestRoleBinding>
}

/**
 * Request parameters for rbacServiceAPIDeleteGroup operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIDeleteGroupRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIDeleteGroupRequest {
    /**
     * OrganizationID is the organization ID for which the group is deleted.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIDeleteGroup
     */
    readonly organizationId: string

    /**
     * ID is the id of the groups to be deleted.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIDeleteGroup
     */
    readonly id: string
}

/**
 * Request parameters for rbacServiceAPIDeleteRoleBinding operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIDeleteRoleBindingRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIDeleteRoleBindingRequest {
    /**
     * OrganizationID is the organization ID for which the role binding is deleted.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIDeleteRoleBinding
     */
    readonly organizationId: string

    /**
     * ID is the id of the role binding to be deleted.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIDeleteRoleBinding
     */
    readonly id: string
}

/**
 * Request parameters for rbacServiceAPIGetGroup operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIGetGroupRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIGetGroupRequest {
    /**
     * OrganizationID is the organization ID for which the group is fetched.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIGetGroup
     */
    readonly organizationId: string

    /**
     * ID is the id of the groups to be fetched.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIGetGroup
     */
    readonly id: string
}

/**
 * Request parameters for rbacServiceAPIGetOrganizationResources operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIGetOrganizationResourcesRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIGetOrganizationResourcesRequest {
    /**
     * 
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIGetOrganizationResources
     */
    readonly organizationId: string
}

/**
 * Request parameters for rbacServiceAPIGetRole operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIGetRoleRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIGetRoleRequest {
    /**
     * OrganizationID is the organization ID for which the role is fetched.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIGetRole
     */
    readonly organizationId: string

    /**
     * ID is the id of the role to be fetched.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIGetRole
     */
    readonly id: string
}

/**
 * Request parameters for rbacServiceAPIGetRoleBinding operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIGetRoleBindingRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIGetRoleBindingRequest {
    /**
     * OrganizationID is the organization ID for which the role binding is fetched.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIGetRoleBinding
     */
    readonly organizationId: string

    /**
     * ID is the id of the role binding to be fetched.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIGetRoleBinding
     */
    readonly id: string
}

/**
 * Request parameters for rbacServiceAPIListGroups operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIListGroupsRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIListGroupsRequest {
    /**
     * 
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListGroups
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListGroups
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListGroups
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for rbacServiceAPIListRoleBindings operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIListRoleBindingsRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIListRoleBindingsRequest {
    /**
     * 
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListRoleBindings
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListRoleBindings
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListRoleBindings
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for rbacServiceAPIListRoles operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIListRolesRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIListRolesRequest {
    /**
     * 
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListRoles
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListRoles
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIListRoles
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for rbacServiceAPIResolveRoleBindingSubjects operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIResolveRoleBindingSubjectsRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIResolveRoleBindingSubjectsRequest {
    /**
     * OrganizationID is the organization ID for which the role bindings are resolved.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIResolveRoleBindingSubjects
     */
    readonly organizationId: string

    /**
     * ID is the ID of the role binding to be resolved.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIResolveRoleBindingSubjects
     */
    readonly id: string
}

/**
 * Request parameters for rbacServiceAPIUpdateGroup operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIUpdateGroupRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIUpdateGroupRequest {
    /**
     * OrganizationID is the organization ID for which the group is updated.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIUpdateGroup
     */
    readonly organizationId: string

    /**
     * ID is the unique identifier of the group.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIUpdateGroup
     */
    readonly groupId: string

    /**
     * Groups is the groups to be updated.
     * @type {GroupsIsTheGroupsToBeUpdated}
     * @memberof RbacServiceAPIApiRbacServiceAPIUpdateGroup
     */
    readonly group: GroupsIsTheGroupsToBeUpdated
}

/**
 * Request parameters for rbacServiceAPIUpdateGroups operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIUpdateGroupsRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIUpdateGroupsRequest {
    /**
     * OrganizationID is the organization ID for which the group is updated.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIUpdateGroups
     */
    readonly organizationId: string

    /**
     * Groups is the groups to be updated.
     * @type {Array<CastaiRbacV1beta1GroupUpdate>}
     * @memberof RbacServiceAPIApiRbacServiceAPIUpdateGroups
     */
    readonly groups: Array<CastaiRbacV1beta1GroupUpdate>
}

/**
 * Request parameters for rbacServiceAPIUpdateRoleBinding operation in RbacServiceAPIApi.
 * @export
 * @interface RbacServiceAPIApiRbacServiceAPIUpdateRoleBindingRequest
 */
export interface RbacServiceAPIApiRbacServiceAPIUpdateRoleBindingRequest {
    /**
     * OrganizationID is the organization ID for which the role binding is updated.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIUpdateRoleBinding
     */
    readonly organizationId: string

    /**
     * ID is the unique identifier of the role binding.
     * @type {string}
     * @memberof RbacServiceAPIApiRbacServiceAPIUpdateRoleBinding
     */
    readonly roleBindingId: string

    /**
     * RoleBinding is the role binding to be updated.
     * @type {RoleBindingIsTheRoleBindingToBeUpdated}
     * @memberof RbacServiceAPIApiRbacServiceAPIUpdateRoleBinding
     */
    readonly roleBinding: RoleBindingIsTheRoleBindingToBeUpdated
}

/**
 * RbacServiceAPIApi - object-oriented interface
 * @export
 * @class RbacServiceAPIApi
 * @extends {BaseAPI}
 */
export class RbacServiceAPIApi extends BaseAPI {
    /**
     * 
     * @summary AccessResolve is used to resolve access for specific resource type.
     * @param {RbacServiceAPIApiRbacServiceAPIAccessResolveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIAccessResolve(requestParameters: RbacServiceAPIApiRbacServiceAPIAccessResolveRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIAccessResolve(requestParameters.organizationId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CreateGroup creates a group for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPICreateGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPICreateGroup(requestParameters: RbacServiceAPIApiRbacServiceAPICreateGroupRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPICreateGroup(requestParameters.organizationId, requestParameters.group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CreateRoleBindings creates role bindings for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPICreateRoleBindingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPICreateRoleBindings(requestParameters: RbacServiceAPIApiRbacServiceAPICreateRoleBindingsRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPICreateRoleBindings(requestParameters.organizationId, requestParameters.roleBindings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteGroup deletes the group for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIDeleteGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIDeleteGroup(requestParameters: RbacServiceAPIApiRbacServiceAPIDeleteGroupRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIDeleteGroup(requestParameters.organizationId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteRoleBinding deletes the role binding for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIDeleteRoleBindingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIDeleteRoleBinding(requestParameters: RbacServiceAPIApiRbacServiceAPIDeleteRoleBindingRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIDeleteRoleBinding(requestParameters.organizationId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetGroup fetches a group for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIGetGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIGetGroup(requestParameters: RbacServiceAPIApiRbacServiceAPIGetGroupRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIGetGroup(requestParameters.organizationId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetOrganizationResources returns details about organization resources.
     * @param {RbacServiceAPIApiRbacServiceAPIGetOrganizationResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIGetOrganizationResources(requestParameters: RbacServiceAPIApiRbacServiceAPIGetOrganizationResourcesRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIGetOrganizationResources(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetRole fetches the role for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIGetRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIGetRole(requestParameters: RbacServiceAPIApiRbacServiceAPIGetRoleRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIGetRole(requestParameters.organizationId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetRoleBinding fetches the role binding for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIGetRoleBindingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIGetRoleBinding(requestParameters: RbacServiceAPIApiRbacServiceAPIGetRoleBindingRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIGetRoleBinding(requestParameters.organizationId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListGroups lists the groups for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIListGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIListGroups(requestParameters: RbacServiceAPIApiRbacServiceAPIListGroupsRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIListGroups(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListRoleBindings lists the role bindings for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIListRoleBindingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIListRoleBindings(requestParameters: RbacServiceAPIApiRbacServiceAPIListRoleBindingsRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIListRoleBindings(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListRoles lists the roles for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIListRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIListRoles(requestParameters: RbacServiceAPIApiRbacServiceAPIListRolesRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIListRoles(requestParameters.organizationId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ResolveRoleBindingSubjects fetches the subjects details for the role binding.
     * @param {RbacServiceAPIApiRbacServiceAPIResolveRoleBindingSubjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIResolveRoleBindingSubjects(requestParameters: RbacServiceAPIApiRbacServiceAPIResolveRoleBindingSubjectsRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIResolveRoleBindingSubjects(requestParameters.organizationId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateGroup updates the group for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIUpdateGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIUpdateGroup(requestParameters: RbacServiceAPIApiRbacServiceAPIUpdateGroupRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIUpdateGroup(requestParameters.organizationId, requestParameters.groupId, requestParameters.group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateGroups updates list of groups for the organization, by adding or removing users from the groups.
     * @param {RbacServiceAPIApiRbacServiceAPIUpdateGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIUpdateGroups(requestParameters: RbacServiceAPIApiRbacServiceAPIUpdateGroupsRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIUpdateGroups(requestParameters.organizationId, requestParameters.groups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateRoleBinding updates the role binding for the organization.
     * @param {RbacServiceAPIApiRbacServiceAPIUpdateRoleBindingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RbacServiceAPIApi
     */
    public rbacServiceAPIUpdateRoleBinding(requestParameters: RbacServiceAPIApiRbacServiceAPIUpdateRoleBindingRequest, options?: RawAxiosRequestConfig) {
        return RbacServiceAPIApiFp(this.configuration).rbacServiceAPIUpdateRoleBinding(requestParameters.organizationId, requestParameters.roleBindingId, requestParameters.roleBinding, options).then((request) => request(this.axios, this.basePath));
    }
}

