import { Box, Stack, Typography } from '@mui/material';
import round from 'lodash/round';
import NumberFormat from 'react-number-format';

import { DataPopper, DataPopperRow } from '@cast/design-system';

import { GaugeChart } from 'components/chart';
import { DataTooltip } from 'components/tooltip';

type Props = {
  resource: string;
  total: number;
  provisioned: number;
  requested: number;
  used: number;
  unit: string;
  testId?: string;
};

export const GaugeCard = ({
  resource,
  total,
  provisioned,
  requested,
  used,
  unit,
  testId,
}: Props) => {
  const requestedPercentage = ((requested - used) * 100) / (total || 1);
  const usedPercentage = (used * 100) / (total || 1);

  const tooltipRowValue = (value: number) => (
    <Typography display="flex" flexDirection="row" gap="4px" variant="inherit">
      <NumberFormat
        displayType="text"
        value={round(value, 2)}
        thousandSeparator
      />
      {unit}
    </Typography>
  );

  return (
    <Stack gap={16} minWidth={120} data-testid={testId}>
      <DataTooltip
        placement="top"
        title={
          <DataPopper sx={{ minWidth: 229 }}>
            <DataPopperRow
              left={`Current ${resource} details:`}
              dividerBottom
              sx={{ color: 'grey.400', borderColor: 'grey.100' }}
            />
            <DataPopperRow
              left="Provisioned"
              right={tooltipRowValue(provisioned)}
            />
            <DataPopperRow
              indicator="green.100"
              left="Used"
              right={tooltipRowValue(used)}
            />
            <DataPopperRow
              indicator="blue.300"
              left="Requested"
              right={tooltipRowValue(requested)}
            />
            <DataPopperRow
              indicator={{ color: 'blue.600', variant: 'outlined' }}
              left="Allocatable"
              right={tooltipRowValue(total)}
            />
          </DataPopper>
        }
      >
        <Box width={100} height={100}>
          <GaugeChart
            outerLayer={[
              {
                color: 'green.100',
                value: usedPercentage,
              },
              {
                value: requestedPercentage,
                color: 'blue.200',
              },
            ]}
            title={unit}
            pieLabelProps={{ sx: (theme) => theme.typography.A1 }}
            barWidth={7}
            variant="full"
          />
        </Box>
      </DataTooltip>
      <Stack gap={2}>
        <Typography variant="L10R">{`${resource} total`}</Typography>
        <Typography variant="h5">{round(total || 0, 2)}</Typography>
      </Stack>
    </Stack>
  );
};
