import { PropsWithChildren, ReactNode } from 'react';

import { Box, Divider, Stack } from '@mui/material';
import { SxProps } from '@mui/system';

import { mergeSx, Icons, IconButton } from '@cast/design-system';

export type DrawerHeaderProps = PropsWithChildren<{
  actions?: ReactNode;
  onClose?: () => void;
  noDivider?: boolean;
  sx?: SxProps;
  containerSx?: SxProps;
  sticky?: boolean;
  closeProps?: {
    placement: 'left' | 'right';
  };
}>;

export const DrawerHeader = ({
  actions,
  onClose,
  noDivider,
  children,
  sx,
  containerSx,
  sticky = true,
  closeProps,
}: DrawerHeaderProps) => {
  return (
    <Box
      className="DrawerHeader-root"
      flex={0}
      sx={mergeSx(
        {
          px: 32,
        },
        sticky && {
          '.Drawer-scrolledContent &': {
            boxShadow: '0 4px 11px rgba(0, 0, 0, 0.16)',
          },
        },
        sx
      )}
    >
      <Stack direction="column" pt={24} mb={8} sx={containerSx}>
        <Stack
          direction={closeProps?.placement === 'right' ? 'row-reverse' : 'row'}
          flexWrap="nowrap"
          justifyContent={
            closeProps?.placement === 'right' ? 'flex-start' : 'space-between'
          }
          gap={8}
        >
          <IconButton
            variant="ghost"
            size="small"
            sx={closeProps?.placement === 'right' ? { mr: -16 } : { ml: -16 }}
            onClick={onClose}
            testId="close-drawer"
          >
            {closeProps?.placement === 'right' ? (
              <Icons.X />
            ) : (
              <Icons.CaretDoubleRight />
            )}
          </IconButton>
          {actions}
        </Stack>
        <Stack>{children}</Stack>
      </Stack>
      {children && !noDivider && <Divider />}
    </Box>
  );
};
