import { Divider, Stack, useTheme } from '@mui/material';
import { StackProps } from '@mui/system';

import { DrawerProvider, Icons } from '@cast/design-system';

import { LogoFullSvg } from 'assets/svg';
import { EXTERNAL_LINKS } from 'common/links';
import { Link as RouterLink, Link } from 'components/router';
import { AbilityGuard, useAbility } from 'core/ability';
import { user } from 'core/auth';
import { TourStep } from 'core/tour';
import { dashboardTourId, tourSteps } from 'features/dashboard';
import { NotificationsBell } from 'features/notifications';
import { useCreateOrganizationDialog } from 'features/organization';
import { useOrganizations } from 'hooks/useOrganizations';

import { HeaderButton } from './_components';
import { ApiMenu } from './ApiMenu';
import { HelpMenu } from './HelpMenu';
import { OrganizationMenu } from './OrganizationMenu';
import { ProfileMenu } from './ProfileMenu';
import { ContentContainer } from '../ContentContainer';

type HeaderProps = Omit<StackProps, 'ref'> & {
  noSidebar?: boolean;
  noMenu?: boolean;
};

const Menu = () => {
  const { openModal: openCreateOrganizationModal } =
    useCreateOrganizationDialog();
  const { organizations, currentOrganization, setCurrentOrganization } =
    useOrganizations();

  const canViewOrganizationManagement = useAbility().can(
    'view',
    'OrganizationManagement'
  );

  const profile = user();

  return (
    <Stack direction="row" justifyContent="flex-end" gap={8}>
      <Stack direction="row" gap={24} mr={8}>
        <HeaderButton
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          component="a"
          href={EXTERNAL_LINKS.castai_referral_program}
          target="_blank"
          rel="noopener noreferrer"
        >
          Referral program
        </HeaderButton>
        <HeaderButton
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          component="a"
          href={EXTERNAL_LINKS.docs}
          target="_blank"
          rel="noopener noreferrer"
        >
          Documentation
        </HeaderButton>

        <ApiMenu />

        <HelpMenu />
      </Stack>

      <Divider orientation="vertical" flexItem />

      <HeaderButton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component={RouterLink}
        to="/organization/management"
        startIcon={<Icons.Sliders />}
      >
        {canViewOrganizationManagement ? 'Manage Org' : 'View Org'}
      </HeaderButton>

      <Divider orientation="vertical" flexItem />

      <ProfileMenu user={profile.firstName} email={profile.email} />

      <Divider orientation="vertical" flexItem />

      <Stack
        direction="row"
        gap="12px"
        ml="4px"
        alignItems="center"
        height="100%"
      >
        <AbilityGuard subject="OrganizationNotifications" action="view">
          <DrawerProvider>
            <NotificationsBell />
          </DrawerProvider>
        </AbilityGuard>

        <TourStep
          step={tourSteps.organization}
          type={dashboardTourId}
          text="Now you can go ahead and invite more team members to your organization."
          highlight="bubble"
          placement="top-end"
          beaconPosition={{
            top: 6,
            right: 6,
          }}
        >
          <OrganizationMenu
            activeOrganizationId={currentOrganization?.id}
            organizations={organizations}
            onChangeOrganization={(org) => setCurrentOrganization(org.id)}
            onCreateNewOrganization={openCreateOrganizationModal}
          />
        </TourStep>
      </Stack>
    </Stack>
  );
};

export const Header = ({ noSidebar, noMenu, ...props }: HeaderProps) => {
  const { palette } = useTheme();
  return (
    <ContentContainer
      {...props}
      component="header"
      data-testid="main-layout-header"
    >
      <Stack
        direction="row"
        justifyContent={noSidebar ? 'space-between' : 'flex-end'}
        gap="8px"
        height="48px"
        py="10px"
      >
        {noSidebar && (
          <Link to="/dashboard">
            <LogoFullSvg aria-label="logo" color={palette.grey[800]} />
          </Link>
        )}

        {!noMenu && <Menu />}
      </Stack>
    </ContentContainer>
  );
};
