import {
  PoliciesConfig,
  SpotInstances,
  NodeDownscaler,
  ClusterLimitsPolicy,
  UnschedulablePodsPolicy,
} from '@cast/types';

export enum PolicyKey {
  UnschedulablePods = 'unschedulablePods',
  NodeDownscaler = 'nodeDownscaler',
  ClusterLimits = 'clusterLimits',
}

export type Policies = {
  [PolicyKey.ClusterLimits]: ClusterLimitsPolicy;
  [PolicyKey.NodeDownscaler]: NodeDownscaler;
  [PolicyKey.UnschedulablePods]: UnschedulablePodsPolicy;
};

export type PoliciesList = Record<
  keyof Policies,
  { label: string; enabled: boolean }
>;

export type PoliciesUnion = PoliciesConfig[keyof PoliciesConfig];

export const isClusterLimitsPolicy = (
  policy: PoliciesUnion
): policy is ClusterLimitsPolicy => {
  return (policy as ClusterLimitsPolicy).cpu !== undefined;
};

export const isSpotInstancesPolicy = (
  policy: PoliciesUnion
): policy is SpotInstances => {
  return (policy as SpotInstances).maxReclaimRate !== undefined;
};

export const isNodeDownscalerPolicy = (
  policy: PoliciesUnion
): policy is NodeDownscaler => {
  return (policy as NodeDownscaler).emptyNodes !== undefined;
};

export const isUnschedulablePodsPolicy = (
  policy: PoliciesUnion
): policy is UnschedulablePodsPolicy => {
  return (policy as UnschedulablePodsPolicy).headroom !== undefined;
};

export const isPolicy = (
  policy: PoliciesUnion
): policy is Policies[keyof Policies] => {
  return typeof policy !== 'boolean';
};
