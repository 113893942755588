import { useQuery } from '@tanstack/react-query';

import { CAN_VIEW_SECURITY } from '@cast/console/constants';

import { QueryKeys, apiClient } from 'core/react-query';

type UseSecurityInsightsAgents = {
  clusterIds: string[];
  enabled?: boolean;
};

export const useSecurityInsightsAgents = ({
  clusterIds,
  enabled = true,
}: UseSecurityInsightsAgents) => {
  const {
    data: agentsData,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.SECURITY_AGENT_STATUSES, clusterIds],
    queryFn: async () => {
      const { data } = await apiClient.securityInsights.getAgentsStatuses({
        body: { clusterIds },
      });
      return data;
    },
    enabled: enabled && CAN_VIEW_SECURITY(),
  });
  return { agentsData, isLoading, error, refetch };
};
