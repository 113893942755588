import { ReactElement, ReactNode } from 'react';

import { BoxProps, Stack, StackProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { Tooltip as ReTooltip, TooltipProps as ReTooltipProps } from 'recharts';

import { TooltipSize } from './types';

const StyledTooltipFooter = styled(Stack, {
  name: 'DsChartTooltip',
  slot: 'Footer',
  target: 'DsChartTooltip-Footer',
  overridesResolver: (props, styles) => {
    return [styles.footer];
  },
})<StackProps>({});

StyledTooltipFooter.displayName = 'ChartTooltipFooter';

const StyledTooltipHeader = styled(Stack, {
  name: 'DsChartTooltip',
  slot: 'Header',
  target: 'DsChartTooltip-Header',
  overridesResolver: (props, styles) => {
    return [styles.header];
  },
})<StackProps>({});

StyledTooltipHeader.displayName = 'ChartTooltipHeader';

type ValueType = number | string | Array<number | string>;
type NameType = number | string;
type HeaderPropsLeft = { leftText?: string } | { leftNode?: ReactNode };
type HeaderPropsRight = { rightText?: string } | { rightNode?: ReactNode };
type HeaderProps = HeaderPropsLeft & HeaderPropsRight;

export type ChartTooltipProps = Omit<
  ReTooltipProps<ValueType, NameType>,
  'content'
> & {
  content: Exclude<
    ReTooltipProps<ValueType, NameType>['content'],
    ReactElement
  >;
  size?: TooltipSize;
  disabled?: boolean;
  header?: HeaderProps;
  footer?: ReactNode;
  sx?: SxProps;
};

const StyledTooltip = styled(Box, {
  name: 'DsChartTooltip',
  slot: 'Root',
  target: 'DsChartTooltip-Root',
  overridesResolver: ({ size, disabled }, styles) => {
    return [styles.root, styles[size], disabled && styles.disabled];
  },
})<BoxProps & { size: TooltipSize; disabled?: boolean }>({});

StyledTooltip.displayName = 'ChartTooltip';

export const ChartTooltip = ({
  content,
  header,
  footer,
  disabled = false,
  size = 'medium',
  sx,
  ...props
}: ChartTooltipProps) => {
  return (
    <ReTooltip
      allowEscapeViewBox={{
        x: false,
        y: false,
      }}
      isAnimationActive={false}
      offset={30}
      {...props}
      content={(props) => (
        <StyledTooltip size={size} sx={sx} disabled={disabled}>
          {!!header && (
            <StyledTooltipHeader>
              {'leftText' in header && <Stack>{header.leftText}</Stack>}
              {'leftNode' in header && header.leftNode}
              {'rightText' in header && <Stack>{header.rightText}</Stack>}
              {'rightNode' in header && header.rightNode}
            </StyledTooltipHeader>
          )}

          <Stack>{content?.(props)}</Stack>

          {!!footer && <StyledTooltipFooter>{footer}</StyledTooltipFooter>}
        </StyledTooltip>
      )}
    />
  );
};
