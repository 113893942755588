import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useContext,
} from 'react';

import clsx from 'clsx';
import { IconContext } from 'phosphor-react';

export type IconWeight =
  | 'thin'
  | 'light'
  | 'regular'
  | 'bold'
  | 'fill'
  | 'duotone';

export type RenderFunction = (
  weight: IconWeight,
  color: string
) => ReactNode | null;

const defaultWeight = 'light';
interface IconBaseProps extends IconProps {
  renderPath?: RenderFunction;
}

export type PaintFunction = (color: string) => ReactNode;

export const renderPathForWeight = (
  weight: IconWeight,
  color: string,
  pathsByWeight: Map<IconWeight, PaintFunction>
): ReactNode | null => {
  const path = pathsByWeight.get(weight) || pathsByWeight.get(defaultWeight);
  if (path) return path(color);
  console.error(
    'Unsupported icon weight. Choose from "thin", "light", "regular", "bold", "fill", or "duotone".'
  );

  return null;
};

export interface IconProps extends ComponentPropsWithoutRef<'svg'> {
  alt?: string;
  color?: string;
  size?: string | number;
  weight?: IconWeight;
  mirrored?: boolean;
  className?: string;
}

const IconBase = forwardRef<SVGSVGElement, IconBaseProps>((props, ref) => {
  const {
    alt,
    className,
    color,
    size,
    weight,
    mirrored,
    // eslint-disable-next-line react/prop-types
    children,
    renderPath,
    ...restProps
  } = props;

  const {
    color: contextColor = 'currentColor',
    size: contextSize,
    weight: contextWeight = 'regular',
    mirrored: contextMirrored = false,
    ...restContext
  } = useContext(IconContext);

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? contextSize}
      height={size ?? contextSize}
      fill={color ?? contextColor}
      viewBox="0 0 32 32"
      transform={mirrored || contextMirrored ? 'scale(-1, 1)' : undefined}
      {...restContext}
      {...restProps}
      className={clsx(className, 'DsIcon-root')}
    >
      {!!alt && <title>{alt}</title>}
      {children}
      {renderPath?.(weight ?? contextWeight, color ?? contextColor)}
    </svg>
  );
});

IconBase.displayName = 'IconBase';

export default IconBase;
