import { ReactNode, useMemo } from 'react';

import { HeaderCell } from './HeaderCell';
import { useTableContext } from '../../hooks';

export const HeaderRow = () => {
  const { api, tableProps } = useTableContext();
  const columnModels = api.current.columnsInfo.columnModels;
  const shouldRenderHeader = columnModels.some(
    (column) => column.header || column.renderHeader
  );
  const headerCells = useMemo(() => {
    if (columnModels) {
      const headerCells: ReactNode[] = [];
      for (const columnModel of columnModels) {
        headerCells.push(
          <HeaderCell
            key={columnModel.id}
            columnModel={columnModel}
            api={api}
            Sorter={columnModel.Sorter || tableProps.Sorter}
            SortIcon={columnModel.SortIcon || tableProps.SortIcon}
            InfoIcon={columnModel.InfoIcon || tableProps.InfoIcon}
          />
        );
      }
      return headerCells;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnModels, api.current.state.sortingState, shouldRenderHeader]);
  return (
    <div
      className={`DS-Table-RowWrapper DS-Table-HeaderRowWrapper ${
        !shouldRenderHeader ? 'DS-Table-headerHidden' : ''
      }`}
    >
      <div className="DS-Table-Row DS-Table-HeaderRow">{headerCells}</div>
    </div>
  );
};
