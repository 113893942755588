import { useMemo } from 'react';

import dayjs from 'dayjs';

import { PriceType } from '@cast/types';

import { useCostOverTime } from 'features/cost-report/hooks/useCostOverTime';
import { useCostReportContext } from 'features/cost-report/hooks/useCostReportContext';
import {
  ComputeSpendChartData,
  CostReportChartData,
  CostReportChartDataByPeriod,
  CostReportChartType,
} from 'features/cost-report/types/costOverTime';
import {
  getDataHoursMultiplier,
  getUptimeMultiplier,
} from 'features/cost-report/utils/costOverTime';
import { getDailyAverageMetrics } from 'features/cost-report/utils/metrics/getDailyAverageMetrics';
import { findNearestDataPointToTimestamp } from 'utils/data/findNearestDataPointToTimestamp';

export type UseDailyCostChartData = {
  isLoading: boolean;
  data: CostReportChartData[];
};

const mapToSelectedRateCost =
  (chartType: CostReportChartType) =>
  (datapoint: CostReportChartDataByPeriod): ComputeSpendChartData => {
    const multiplier =
      chartType === CostReportChartType.BAR
        ? getDataHoursMultiplier(datapoint)
        : 24;

    let onDemand: number | null = null;
    let onDemandForecast: number | null = null;
    let fallback: number | null = null;
    let fallbackForecast: number | null = null;
    let spot: number | null = null;
    let spotForecast: number | null = null;
    let storage: number | null = null;
    let storageForecast: number | null = null;

    if (datapoint.onDemandCost !== null) {
      onDemand = datapoint.onDemandCost * multiplier;
      onDemandForecast = datapoint.onDemandCost * 24;
    }

    if (datapoint.fallbackCost !== null) {
      fallback = datapoint.fallbackCost * multiplier;
      fallbackForecast = datapoint.fallbackCost * 24;
    }

    if (datapoint.spotCost !== null) {
      spot = datapoint.spotCost * multiplier;
      spotForecast = datapoint.spotCost * 24;
    }

    if (datapoint.totalStorageCost !== null) {
      storage = datapoint.totalStorageCost * multiplier;
      storageForecast = datapoint.totalStorageCost * 24;
    }

    return {
      timestamp: datapoint.timestamp,
      forecast: datapoint.forecast,
      onDemand,
      fallback,
      spot,
      storage,
      onDemandForecast,
      fallbackForecast,
      spotForecast,
      storageForecast,
      onDemandCpuCount: datapoint.onDemandCpuCount,
      fallbackCpuCount: datapoint.fallbackCpuCount,
      spotCpuCount: datapoint.spotCpuCount,
      fallbackRamGib: datapoint.fallbackRamGib,
      onDemandRamGib: datapoint.onDemandRamGib,
      spotRamGib: datapoint.spotRamGib,
      anomalies: [],
    };
  };

const mapToUptimeCost = (
  datapoint: CostReportChartDataByPeriod,
  applyUptime: boolean
): ComputeSpendChartData => {
  const multiplier = applyUptime
    ? getUptimeMultiplier(datapoint)
    : { onDemand: 1, spot: 1, fallback: 1 };

  let onDemand: number | null = null;
  let fallback: number | null = null;
  let spot: number | null = null;
  let storage: number | null = null;

  if (datapoint.onDemandCost !== null) {
    onDemand = datapoint.onDemandCost * multiplier.onDemand;
  }

  if (datapoint.fallbackCost !== null) {
    fallback = datapoint.fallbackCost * multiplier.fallback;
  }

  if (datapoint.spotCost !== null) {
    spot = datapoint.spotCost * multiplier.spot;
  }

  if (datapoint.totalStorageCost !== null) {
    storage = datapoint.totalStorageCost;
  }

  return {
    timestamp: datapoint.timestamp,
    onDemand,
    fallback,
    spot,
    storage,
    onDemandRamGib: datapoint.onDemandRamGib,
    fallbackRamGib: datapoint.fallbackCost,
    spotRamGib: datapoint.spotRamGib,
    onDemandCpuCount: datapoint.onDemandCpuCount,
    fallbackCpuCount: datapoint.fallbackCpuCount,
    spotCpuCount: datapoint.spotCpuCount,
    forecast: datapoint.forecast,
    anomalies: [],
  };
};

export const useDailyCostChartData = (): UseDailyCostChartData => {
  const {
    current,
    projectEndOfDayCost,
    applyUptime,
    timeSeriesStep,
    anomalies,
  } = useCostOverTime();
  const { chartType } = useCostReportContext();

  const data = useMemo(() => {
    const dataPoints =
      chartType === CostReportChartType.BAR &&
      timeSeriesStep !== PriceType.DAILY
        ? getDailyAverageMetrics(current.chartData, {
            includeGenerated: false,
            includeForecasted: true,
          })
        : current.chartData;

    const mappedData = dataPoints.map(
      projectEndOfDayCost
        ? mapToSelectedRateCost(chartType)
        : (data) => mapToUptimeCost(data, applyUptime)
    );

    anomalies?.forEach((anomaly) => {
      const pointToMark = findNearestDataPointToTimestamp(
        mappedData,
        dayjs(anomaly.timestamp)
      );
      if (pointToMark) {
        pointToMark.anomalies.push(anomaly);
      }
    });

    return mappedData;
  }, [
    applyUptime,
    chartType,
    current.chartData,
    anomalies,
    projectEndOfDayCost,
    timeSeriesStep,
  ]);

  return {
    isLoading: current.isLoading,
    data,
  };
};
