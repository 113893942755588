import { useRef } from 'react';

import { Divider, Typography } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import pluralize from 'pluralize';

import {
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  Icons,
  DialogActions,
} from '@cast/design-system';

import { HexIconError } from 'components/hex-icons';
import { useDeleteImagesMutation } from 'hooks/mutations/security-insights';
import { ShowModalOutput } from 'types/modal';

import { ExpandableList } from '../../_components/ExpandableList';

type Props = DialogProps & {
  tagNames: string[];
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteImagesDialog = ({
  tagNames,
  onClose,
  onDelete,
  ...props
}: Props) => {
  const { mutate, isPending } = useDeleteImagesMutation(() => {
    onDelete();
    onClose();
  });

  return (
    <Dialog
      {...props}
      onClose={onClose}
      PaperProps={{ sx: { overflow: 'visible' } }}
      testId="delete-images-dialog"
    >
      <DialogTitle
        variant="h4"
        sx={{ display: 'flex', alignItems: 'center', gap: 8, pb: 8 }}
      >
        <HexIconError size={40} icon={<Icons.TrashSimple />} />
        Remove {pluralize('repository', tagNames.length)} from the list?
      </DialogTitle>

      <DialogContent>
        <Typography variant="P14R" color="textSecondary" mb={16}>
          Image repositories will be removed from the list, but they may
          reappear again if detected by CAST AI.
        </Typography>
        <Typography variant="h6">
          {pluralize('Repository', tagNames.length)} to be removed:
        </Typography>
        <ExpandableList>
          {tagNames.map((tagName) => (
            <Typography key={tagName} variant="P14R" noWrap component="div">
              {tagName}
            </Typography>
          ))}
        </ExpandableList>
      </DialogContent>

      <Divider sx={{ borderColor: 'grey.100' }} />

      <DialogActions>
        <Button variant="tertiary" size="medium" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          testId="remove-images-button"
          size="medium"
          onClick={() => mutate(tagNames)}
          loading={isPending}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useDeleteImagesDialog = (onDelete: () => void) => {
  const { showModal } = useModal();
  const modalInstance = useRef<ShowModalOutput | undefined>();

  const openDeleteImagesDialog = (tagNames: string[]) => {
    modalInstance.current = showModal(DeleteImagesDialog, {
      tagNames,
      onClose: () => modalInstance.current?.destroy(),
      onDelete,
    });
  };

  return { openDeleteImagesDialog };
};
