import { ForwardedRef, useRef, useState } from 'react';

export const useMergedRef = <T extends any = any>(
  outerRef: ForwardedRef<T>,
  saveInState = true,
  enabled = true
): [T | undefined, (r?: T | null) => void] => {
  const [stateRef, setRef] = useState<T | undefined>();
  const ref = useRef<T | undefined>();
  return [
    saveInState ? stateRef : (ref.current as T),
    (r?: T | null) => {
      if (typeof outerRef === 'function') {
        outerRef(r as T);
      } else if (outerRef) {
        outerRef.current = r as T;
      }
      if (!enabled) {
        return;
      }
      if (saveInState) {
        setRef(r || undefined);
      } else {
        ref.current = r || undefined;
      }
    },
  ];
};
