import { styled } from '@mui/material';

import { SupportedMarkdownElementKeys } from './types';

export const PreviewMarkdownBaseElement = styled('span', {
  name: 'DsPreviewMarkdown',
  slot: 'Element',
  overridesResolver: ({ type }, theme) => {
    return [theme[type]];
  },
  shouldForwardProp: (propName) =>
    propName !== 'as' && propName !== 'type' && propName !== 'sx',
})<{ type: SupportedMarkdownElementKeys }>({});
