import { AxiosInstance } from 'axios';

import { DiscountsAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const DiscountsApiAdapter = (client: AxiosInstance) => {
  const generatedApi = DiscountsAPIApiFactory(AXIOS_CONFIGURATION, '', client);
  return {
    listDiscounts: generatedApi.discountsAPIListDiscounts,
    createDiscount: generatedApi.discountsAPICreateDiscount,
    updateDiscount: generatedApi.discountsAPIUpdateDiscount,
    deleteDiscount: generatedApi.discountsAPIDeleteDiscount,
  };
};
