import { ThemeOptions } from '@mui/material';

export const codeActionsTheme: ThemeOptions['components'] = {
  DsCodeActions: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: 15,
        right: 20,
        zIndex: 1,
      },
      sizeMedium: {
        '& svg': {
          fontSize: 16,
        },
      },
      sizeLarge: {
        '& svg': {
          fontSize: 20,
        },
      },
    },
  },
};
