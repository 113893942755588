import {
  CastaiNotificationsV1beta1ListNotificationsResponse,
  CastaiNotificationsV1beta1ListWebhookCategoriesResponse,
  CastaiNotificationsV1beta1ListWebhookConfigsResponse,
} from '@cast/openapi';

import { NotificationsApiAdapter } from '../api-client/notifications-api-adapter';
import {
  isDemoClusterRequest,
  rejectDemoClusterRequest,
  resolveDemoClusterRequest,
} from '../utils';

export const NotificationsApiProxy = (
  origin: ReturnType<typeof NotificationsApiAdapter>
) => {
  return {
    ...origin,
    ackNotifications: async (
      ...args: Parameters<typeof origin.ackNotifications>
    ) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.ackNotifications(...args);
    },
    listNotifications: async (
      ...args: Parameters<typeof origin.listNotifications>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture: CastaiNotificationsV1beta1ListNotificationsResponse = {
          items: [],
          count: 0,
          countUnacked: 0,
        };
        return resolveDemoClusterRequest(fixture);
      }

      return origin.listNotifications(...args);
    },
    listWebhooks: async (...args: Parameters<typeof origin.listWebhooks>) => {
      if (isDemoClusterRequest(args)) {
        const fixture: CastaiNotificationsV1beta1ListWebhookConfigsResponse = {
          items: [],
        };
        return resolveDemoClusterRequest(fixture);
      }

      return origin.listWebhooks(...args);
    },
    createWebhook: async (...args: Parameters<typeof origin.createWebhook>) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.createWebhook(...args);
    },
    updateWebhook: async (...args: Parameters<typeof origin.updateWebhook>) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.updateWebhook(...args);
    },
    deleteWebhook: async (...args: Parameters<typeof origin.deleteWebhook>) => {
      if (isDemoClusterRequest(args)) {
        return rejectDemoClusterRequest();
      }

      return origin.deleteWebhook(...args);
    },
    webhookCategories: async (
      ...args: Parameters<typeof origin.webhookCategories>
    ) => {
      if (isDemoClusterRequest(args)) {
        const fixture: CastaiNotificationsV1beta1ListWebhookCategoriesResponse =
          {
            categories: [
              {
                name: 'Demo',
                value: 'demo',
              },
            ],
          };
        return resolveDemoClusterRequest(fixture);
      }

      return origin.webhookCategories(...args);
    },
  };
};
