import { PropsWithChildren } from 'react';

import { SxProps } from '@mui/system';

import { usePreviewConfig } from '../../_providers';
import { makeSpaces } from '../../utils';
import { PreviewJsonData } from '../types';

type Props = PropsWithChildren<{
  indent?: number;
  sx?: SxProps;
}>;

export const Indentation = ({ indent = 0, children }: Props) => {
  const { indentBy } = usePreviewConfig<PreviewJsonData>();

  return (
    <span className="DsPreviewJson-Indentation">
      {makeSpaces(indent * indentBy)}
      {children}
    </span>
  );
};
