import { Base64 } from 'js-base64';

import { isUrl } from '@cast/utils';

import { Primitive, PrimitiveVal } from './_types';

export const isPrimitive = (data: any): data is PrimitiveVal => {
  return (
    ['string', 'number', 'boolean'].includes(typeof data) ||
    data === null ||
    data === undefined
  );
};

export const makeSpaces = (spaces: number) =>
  [...Array(spaces)].map(() => ' ').join('');

export const encodeClassName = Base64.encodeURI;

export const getPrimitiveType = (x: any): Primitive | undefined => {
  if (!isPrimitive(x)) {
    return;
  }
  switch (typeof x) {
    case 'string':
      if (isUrl(x)) {
        return Primitive.LINK;
      }
      if (String(parseFloat(x)) === x) {
        return Primitive.NUMERIC_STRING;
      }
      if (Date.parse(x) && String(parseFloat(x)) !== x) {
        return Primitive.DATE;
      }
      return Primitive.STRING;
    case 'number':
      return Primitive.NUMBER;
    case 'boolean':
      return Primitive.BOOLEAN;
    default:
      if (x === null) {
        return Primitive.NULL;
      }
      if (x === undefined) {
        return Primitive.UNDEFINED;
      }
  }
};
