import capitalize from 'lodash/capitalize';

import { hasErrorDetails, isAxiosError } from 'types/api';

export const parseApiError = (
  error: unknown,
  fallbackMessage = 'Oops! Something went wrong! Please try again later.'
) => {
  if (isAxiosError(error)) {
    const axiosError = error?.response?.data;

    if (axiosError && hasErrorDetails(axiosError)) {
      if (axiosError?.fieldViolations?.length) {
        // Always show only first error
        return capitalize(axiosError.fieldViolations[0].description);
      }

      return capitalize(axiosError.message) ?? fallbackMessage;
    }
  }

  return fallbackMessage;
};
