import { useQuery } from '@tanstack/react-query';

import { BestPracticeCheckStandard } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type UseBestPracticeOverviewQuery = {
  standard?: BestPracticeCheckStandard;
};

export const useBestPracticeOverviewQuery = ({
  standard = 'cast',
}: UseBestPracticeOverviewQuery = {}) => {
  const {
    isLoading,
    data: overview,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.SECURITY_OVERVIEW_BEST_PRACTICE, standard],
    queryFn: async () => {
      const { data } = await apiClient.securityInsights.getBestPracticeOverview(
        {
          standard,
        }
      );
      return data;
    },
  });

  return { isLoading, overview, error, refetch };
};
