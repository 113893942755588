import { useMemo } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import flatMap from 'lodash/flatMap';

import { SECONDS_IN_DAY } from '@cast/constants';
import { SortDirection } from '@cast/design-system';
import {
  NoDataReason,
  WorkloadEfficiencyListResponseRaw,
  WorkloadEfficiencyTopReportItem,
  WorkloadsEfficiencyReportItem,
} from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';

export type PaginatedWorkloadsEfficiencyQueryArgs = {
  clusterId: string;
  startTime: string;
  endTime: string;
  stepSeconds?: number;
  enabled?: boolean;
  pageLimit?: number;
  labels?: Array<{ label?: string; value?: string }>;
  namespaces?: string[];
  workloadNames?: string[];
  workloadTypes?: string[];
  sortBy?: string;
  sortDirection?: SortDirection;
};

export type PaginatedWorkloadsEfficiencyQuery = {
  workloads?: WorkloadsEfficiencyReportItem[];
  topWorkloads?: WorkloadEfficiencyTopReportItem[];
  metricsServerAvailable?: boolean;
  count?: number;
  noDataReason?: NoDataReason;
  isLoading: boolean;
  error?: AxiosError | null;
  refetch?: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  isFetching: boolean;
};

export const usePaginatedWorkloadsEfficiencyQuery = ({
  clusterId,
  startTime,
  endTime,
  stepSeconds = SECONDS_IN_DAY,
  enabled = true,
  pageLimit = 50,
  namespaces,
  workloadNames,
  workloadTypes,
  sortBy,
  sortDirection,
  labels,
}: PaginatedWorkloadsEfficiencyQueryArgs): PaginatedWorkloadsEfficiencyQuery => {
  const {
    isLoading,
    data,
    error,
    refetch,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteQuery<WorkloadEfficiencyListResponseRaw, AxiosError>({
    queryKey: [
      QueryKeys.WORKLOADS_COST_EFFICIENCY,
      clusterId,
      startTime,
      endTime,
      namespaces,
      workloadNames,
      workloadTypes,
      JSON.stringify(labels),
      pageLimit,
      sortBy,
      sortDirection,
    ],
    queryFn: async ({ pageParam }) => {
      const { data } = await apiClient.costReport.getWorkloadEfficiency({
        clusterId,
        startTime,
        endTime,
        filter: {
          workloadNames,
          workloadTypes,
          namespaces,
          labels,
        },
        stepSeconds,
        pageLimit: String(pageLimit),
        pageCursor: pageParam as string,
        sortField: sortBy,
        sortOrder: sortDirection,
      });

      return data;
    },
    initialPageParam: '',
    getNextPageParam: ({ nextCursor }) =>
      nextCursor?.length ? nextCursor : undefined,
    enabled: !!clusterId && enabled,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });

  const workloads = useMemo(
    () => flatMap(data?.pages, (page) => page.items || []),
    [data]
  );

  const topWorkloads = useMemo(
    () =>
      flatMap(
        data?.pages,
        (page) =>
          page.topItems?.map((item) => ({
            ...item,
            costImpactHistory: item.costImpactHistory?.map((item) => ({
              ...item,
              timestamp: dayjs(item.timestamp).subtract(1, 'day').toISOString(),
            })),
          })) || []
      ),
    [data]
  );

  const lastPage = data?.pages.at(-1);

  return {
    workloads,
    topWorkloads,
    count: parseInt(lastPage?.count || '0', 10),
    noDataReason: lastPage?.noDataReason,
    metricsServerAvailable: lastPage?.metricsServerAvailable,
    isLoading,
    error,
    refetch,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  };
};
