import { AxiosInstance } from 'axios';

import { NotificationAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const NotificationsApiAdapter = (client: AxiosInstance) => {
  const generatedApi = NotificationAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  return {
    ackNotifications: generatedApi.notificationAPIAckNotifications,
    listNotifications: generatedApi.notificationAPIListNotifications,
    listWebhooks: generatedApi.notificationAPIListWebhookConfigs,
    createWebhook: generatedApi.notificationAPICreateWebhookConfig,
    updateWebhook: generatedApi.notificationAPIUpdateWebhookConfig,
    deleteWebhook: generatedApi.notificationAPIDeleteWebhookConfig,
    webhookCategories: generatedApi.notificationAPIListWebhookCategories,
  };
};
