import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { TableStateConverter } from '../../persistence/types';
import { ColumnSettings, isColumnsGroup } from '../../types';

export type PersistData = {
  columnResizings?: boolean;
  columnVisibility?: boolean;
  columnsOrder?: boolean;
  columnLocking?: boolean;
};

export const makeStateConverter = (
  persistData: PersistData
): TableStateConverter => {
  const hasPersistables = Object.values(persistData).some((enabled) => enabled);
  const { columnResizings, columnVisibility, columnsOrder, columnLocking } =
    persistData;
  if (!hasPersistables) {
    return {
      toSerializable: (_) => ({}),
      toTable: (_, initial) => ({
        state: initial.state,
        requiresReset: false,
      }),
    };
  }
  return {
    toSerializable: (state) => {
      const settings = state.columnSettings;
      const columnSettings: Partial<ColumnSettings> = {};
      if (columnResizings && Object.keys(settings.resizedColumns).length) {
        columnSettings.resizedColumns = settings.resizedColumns;
      }
      if (columnVisibility && Object.keys(settings.hiddenColumns).length) {
        columnSettings.hiddenColumns = settings.hiddenColumns;
      }
      if (columnLocking && Object.keys(settings.lockedColumns).length) {
        columnSettings.lockedColumns = settings.lockedColumns;
      }
      if (columnsOrder && settings.columnsOrder.length) {
        columnSettings.columnsOrder = settings.columnsOrder;
      }
      return { columnSettings };
    },
    toTable: (
      { columnSettings = {} },
      { state, columnsInfo: { groupedColumnsProps, columnsProps } }
    ) => {
      let requiresReset = false;
      const result = cloneDeep(columnSettings);
      if (columnResizings && columnSettings.resizedColumns) {
        const serializedKeys = Object.keys(columnSettings.resizedColumns);
        const resizedColumns = serializedKeys.filter((key) =>
          columnsProps.some(({ id, resizable }) => resizable && id === key)
        );
        result.resizedColumns = resizedColumns.reduce(
          (acc, key) => ({
            ...acc,
            [key]: columnSettings.resizedColumns?.[key] as number,
          }),
          {} as Record<string, number>
        );
        requiresReset = resizedColumns.length !== serializedKeys.length;
      }
      if (columnsOrder && columnSettings.columnsOrder?.length) {
        // Filter columns that were removed from layout
        const filtered = columnSettings.columnsOrder.filter((ordering) =>
          groupedColumnsProps.some((props) => props.id === ordering.id)
        );
        if (filtered.length !== columnSettings.columnsOrder.length) {
          requiresReset = true;
        }
        // Filter columns that were newly added to layout after ordering was saved to storage last time
        const newlyAdded = groupedColumnsProps
          .filter(
            (props) =>
              !columnSettings.columnsOrder!.some(({ id }) => id === props.id)
          )
          .map((props) => {
            if (isColumnsGroup(props)) {
              return {
                id: props.id,
                columns: props.columns.map(({ id }) => id),
              };
            }
            return { id: props.id };
          });
        if (newlyAdded.length) {
          requiresReset = true;
          result.columnsOrder = [...filtered, ...newlyAdded];
        } else {
          result.columnsOrder = filtered;
        }
      }
      if (columnLocking && columnSettings.lockedColumns) {
        const serializedKeys = Object.keys(columnSettings.lockedColumns);
        const lockedColumns = serializedKeys.filter((key) =>
          groupedColumnsProps.some(({ id }) => id === key)
        );
        result.lockedColumns = lockedColumns.reduce(
          (acc, key) => ({
            ...acc,
            [key]: columnSettings.lockedColumns?.[key] as boolean,
          }),
          {} as Record<string, boolean>
        );
        // If some of the saved columns are missing, reset the state
        requiresReset =
          requiresReset || lockedColumns.length !== serializedKeys.length;
      }
      return {
        state: merge({}, state, { columnSettings: result }),
        requiresReset,
      };
    },
  };
};
