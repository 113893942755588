import { SxProps, Theme, styled } from '@mui/material';

const Label = styled('tspan')(({ theme }) => ({
  color: theme.palette.grey[900],
  ...theme.typography.h6,
}));

export type PieLabelProps = {
  viewBox?: { cx: number; cy: number };
  title: string;
  testId?: string;
  sx?: SxProps<Theme>;
};

export const PieLabel = ({ viewBox, title, sx, testId }: PieLabelProps) => {
  const cx = viewBox?.cx || 0;
  const cy = viewBox?.cy || 0;
  return (
    <text
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
    >
      <Label
        x={cx}
        y={cy}
        alignmentBaseline="middle"
        data-testid={testId}
        sx={sx}
      >
        {title}
      </Label>
    </text>
  );
};
