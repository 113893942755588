import { PropsWithChildren, createContext } from 'react';

import { Cluster } from '@cast/types';

export type ClusterContextProps = {
  cluster: Cluster;
  isLoading: boolean;
  error?: string;
};

export const ClusterContext = createContext<ClusterContextProps>({
  isLoading: false,
  cluster: { id: '' } as never,
});

export const ClusterProvider = ({
  children,
  cluster,
  isLoading,
  error,
}: PropsWithChildren<ClusterContextProps>) => {
  return (
    <ClusterContext.Provider value={{ cluster, isLoading, error }}>
      {children}
    </ClusterContext.Provider>
  );
};
