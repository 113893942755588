import filter from 'lodash/filter';

import { PoliciesConfig } from '@cast/types';

import {
  isClusterLimitsPolicy,
  isNodeDownscalerPolicy,
  isPolicy,
  isSpotInstancesPolicy,
  isUnschedulablePodsPolicy,
  Policies,
  PoliciesList,
  PolicyKey,
} from 'types/policies';

export const filterAvailablePolicies = (policiesConfig?: PoliciesConfig) => {
  return filter(
    policiesConfig,
    (policy) => isPolicy(policy) && !isSpotInstancesPolicy(policy)
  ) as Policies[keyof Policies][];
};

export const filterEnabledPolicies = (
  policiesConfig?: PoliciesConfig
): Policies[keyof Policies][] => {
  return filter(filterAvailablePolicies(policiesConfig), (policy) =>
    Boolean(policy.enabled)
  ).filter((v) => v);
};

export const getPoliciesList = (
  policies: Policies[keyof Policies][]
): PoliciesList => ({
  [PolicyKey.UnschedulablePods]: {
    label: 'Unscheduled pods policy',
    enabled: !!policies.find(isUnschedulablePodsPolicy)?.enabled,
  },
  [PolicyKey.NodeDownscaler]: {
    label: 'Node deletion policy',
    enabled: !!policies.find(isNodeDownscalerPolicy)?.enabled,
  },
  [PolicyKey.ClusterLimits]: {
    label: 'CPU limit policy',
    enabled: !!policies.find(isClusterLimitsPolicy)?.enabled,
  },
});

export const applyUnscheduledPodsPolicyToggle = (
  policies: PoliciesConfig
): PoliciesConfig => {
  const { spotInstances, unschedulablePods } = policies;

  return {
    ...policies,
    enabled: true,
    unschedulablePods: {
      ...unschedulablePods,
      enabled: !unschedulablePods?.enabled,
    },
    spotInstances: {
      ...spotInstances,
    },
  };
};

export const applyEmptyWorkerNodesPolicyToggle = (
  policies: PoliciesConfig
): PoliciesConfig => {
  return {
    ...policies,
    enabled: true,
    nodeDownscaler: {
      enabled: !policies.nodeDownscaler?.enabled,
      emptyNodes: {
        delaySeconds: policies.nodeDownscaler?.emptyNodes?.delaySeconds,
      },
    },
  };
};

export const applyClusterLimitPolicyToggle = (
  policies: PoliciesConfig
): PoliciesConfig => ({
  ...policies,
  enabled: true,
  clusterLimits: {
    ...policies.clusterLimits,
    enabled: !policies.clusterLimits?.enabled,
  },
});

export const getEnabledPolicies = (policiesList: PoliciesList) =>
  Object.values(policiesList).filter((policy) => policy.enabled);
