/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CastaiEvictorV1AdvancedConfig } from '../models';
/**
 * EvictorAPIApi - axios parameter creator
 * @export
 */
export const EvictorAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets evictor advanced configuration for the target cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictorAPIGetAdvancedConfig: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('evictorAPIGetAdvancedConfig', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/evictor-advanced-config`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert cluster\'s evictor advanced configuration.
         * @param {string} clusterId The ID of the cluster.
         * @param {CastaiEvictorV1AdvancedConfig} advancedConfig EvictionConfig details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictorAPIUpsertAdvancedConfig: async (clusterId: string, advancedConfig: CastaiEvictorV1AdvancedConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('evictorAPIUpsertAdvancedConfig', 'clusterId', clusterId)
            // verify required parameter 'advancedConfig' is not null or undefined
            assertParamExists('evictorAPIUpsertAdvancedConfig', 'advancedConfig', advancedConfig)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/evictor-advanced-config`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advancedConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EvictorAPIApi - functional programming interface
 * @export
 */
export const EvictorAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EvictorAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets evictor advanced configuration for the target cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evictorAPIGetAdvancedConfig(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiEvictorV1AdvancedConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evictorAPIGetAdvancedConfig(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvictorAPIApi.evictorAPIGetAdvancedConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upsert cluster\'s evictor advanced configuration.
         * @param {string} clusterId The ID of the cluster.
         * @param {CastaiEvictorV1AdvancedConfig} advancedConfig EvictionConfig details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evictorAPIUpsertAdvancedConfig(clusterId: string, advancedConfig: CastaiEvictorV1AdvancedConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiEvictorV1AdvancedConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evictorAPIUpsertAdvancedConfig(clusterId, advancedConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvictorAPIApi.evictorAPIUpsertAdvancedConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EvictorAPIApi - factory interface
 * @export
 */
export const EvictorAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EvictorAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets evictor advanced configuration for the target cluster.
         * @param {EvictorAPIApiEvictorAPIGetAdvancedConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictorAPIGetAdvancedConfig(requestParameters: EvictorAPIApiEvictorAPIGetAdvancedConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiEvictorV1AdvancedConfig> {
            return localVarFp.evictorAPIGetAdvancedConfig(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert cluster\'s evictor advanced configuration.
         * @param {EvictorAPIApiEvictorAPIUpsertAdvancedConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictorAPIUpsertAdvancedConfig(requestParameters: EvictorAPIApiEvictorAPIUpsertAdvancedConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiEvictorV1AdvancedConfig> {
            return localVarFp.evictorAPIUpsertAdvancedConfig(requestParameters.clusterId, requestParameters.advancedConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for evictorAPIGetAdvancedConfig operation in EvictorAPIApi.
 * @export
 * @interface EvictorAPIApiEvictorAPIGetAdvancedConfigRequest
 */
export interface EvictorAPIApiEvictorAPIGetAdvancedConfigRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof EvictorAPIApiEvictorAPIGetAdvancedConfig
     */
    readonly clusterId: string
}

/**
 * Request parameters for evictorAPIUpsertAdvancedConfig operation in EvictorAPIApi.
 * @export
 * @interface EvictorAPIApiEvictorAPIUpsertAdvancedConfigRequest
 */
export interface EvictorAPIApiEvictorAPIUpsertAdvancedConfigRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof EvictorAPIApiEvictorAPIUpsertAdvancedConfig
     */
    readonly clusterId: string

    /**
     * EvictionConfig details.
     * @type {CastaiEvictorV1AdvancedConfig}
     * @memberof EvictorAPIApiEvictorAPIUpsertAdvancedConfig
     */
    readonly advancedConfig: CastaiEvictorV1AdvancedConfig
}

/**
 * EvictorAPIApi - object-oriented interface
 * @export
 * @class EvictorAPIApi
 * @extends {BaseAPI}
 */
export class EvictorAPIApi extends BaseAPI {
    /**
     * 
     * @summary Gets evictor advanced configuration for the target cluster.
     * @param {EvictorAPIApiEvictorAPIGetAdvancedConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvictorAPIApi
     */
    public evictorAPIGetAdvancedConfig(requestParameters: EvictorAPIApiEvictorAPIGetAdvancedConfigRequest, options?: RawAxiosRequestConfig) {
        return EvictorAPIApiFp(this.configuration).evictorAPIGetAdvancedConfig(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert cluster\'s evictor advanced configuration.
     * @param {EvictorAPIApiEvictorAPIUpsertAdvancedConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvictorAPIApi
     */
    public evictorAPIUpsertAdvancedConfig(requestParameters: EvictorAPIApiEvictorAPIUpsertAdvancedConfigRequest, options?: RawAxiosRequestConfig) {
        return EvictorAPIApiFp(this.configuration).evictorAPIUpsertAdvancedConfig(requestParameters.clusterId, requestParameters.advancedConfig, options).then((request) => request(this.axios, this.basePath));
    }
}

