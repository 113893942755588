import { PropsWithChildren } from 'react';

import { styled } from '@mui/material';
import { SxProps } from '@mui/system';

const DrawerOverlayRoot = styled('div', {
  name: 'DsDrawerOverlay',
  skipVariantsResolver: true,
  target: 'DsDrawerOverlay-Root',
  overridesResolver: (_, styles) => {
    return [styles.root];
  },
})({});

const OpaqueOverlay = styled('div', {
  name: 'DsDrawerOverlay',
  skipVariantsResolver: true,
  target: 'DsDrawerOverlay-OpaqueOverlay',
  overridesResolver: (_, styles) => {
    return [styles.opaqueOverlay];
  },
})({});

const ContentWrapper = styled('div', {
  name: 'DsDrawerOverlay',
  skipVariantsResolver: true,
  target: 'DsDrawerOverlay-ContentWrapper',
  overridesResolver: (_, styles) => {
    return [styles.contentWrapper];
  },
})({});

export type DrawerOverlayProps = PropsWithChildren<{
  className?: string;
  testId?: string;
  sx?: SxProps;
  opaqueOverlaySx?: SxProps;
  contentWrapperSx?: SxProps;
}>;
export const DrawerOverlay = ({
  className,
  sx,
  opaqueOverlaySx,
  contentWrapperSx,
  testId,
  children,
}: DrawerOverlayProps) => {
  return (
    <DrawerOverlayRoot className={className} sx={sx} data-testid={testId}>
      <OpaqueOverlay sx={opaqueOverlaySx} />
      <ContentWrapper sx={contentWrapperSx}>{children}</ContentWrapper>
    </DrawerOverlayRoot>
  );
};
