export default {
  50: '#F9E7E7',
  100: '#EFC3C3',
  200: '#E49B9B',
  300: '#D97272',
  400: '#D15454',
  500: '#C93636',
  600: '#BC2929',
  700: '#AE1818',
  800: '#9D160D',
  900: '#8C0B03',
};
