import {
  FormControlLabel as MuiFormControlLabel,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { Radio, RadioGroup } from '@cast/design-system';

import { useAvailableSavingsSpotAnalysis } from '../../../_providers/SpotAnalysisProvider';
import { SpotAnalysisMode } from '../../../types';

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  '& .MuiFormControlLabel-label': {
    ...theme.typography.P12R,
    color: 'black',
    marginLeft: 8,
  },
}));

export const SetSpotAnalysisMode = () => {
  const { changeSpotAnalysisMode, spotAnalysisMode } =
    useAvailableSavingsSpotAnalysis();

  return (
    <RadioGroup
      value={spotAnalysisMode}
      onChange={(_, value) => changeSpotAnalysisMode(+value)}
      sx={{ gap: 12 }}
    >
      <Typography variant="P12M">Suggest spot nodes for:</Typography>
      <Stack direction="row">
        <FormControlLabel
          control={
            <Radio
              value={SpotAnalysisMode.ALL_WORKLOADS}
              color="tertiary"
              testId="spot-analysis-mode-all-workloads"
            />
          }
          label="All workloads"
        />
        <FormControlLabel
          control={
            <Radio
              value={SpotAnalysisMode.SPOT_FRIENDLY_WORKLOADS}
              color="tertiary"
              testId="spot-analysis-mode-friendly-workloads"
            />
          }
          label="Spot-friendly workloads"
        />
      </Stack>
    </RadioGroup>
  );
};
