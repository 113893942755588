import {
  ComponentProps,
  ComponentType,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import { Alert } from '@cast/design-system';

import {
  addClosedAlert,
  isAlertAlreadyClosed,
  makePersonalizedAlertId,
} from './utils';

export type CloseableAlertProps<
  T extends ComponentType<{ onClose?: () => any }>
> = ComponentProps<T> & {
  children: (props: { onClose: (event: any) => void }) => void | ReactNode;
  closeable?: string;
  Component?: T;
};

export const CloseableAlert = ({
  closeable,
  children,
  Component = Alert,
  ...alertProps
}: CloseableAlertProps<any>) => {
  const alertId = closeable && makePersonalizedAlertId(closeable);

  const [visible, setIsVisible] = useState(() =>
    alertId ? !isAlertAlreadyClosed(alertId) : true
  );

  const onClose = useCallback(
    (event: any) => {
      if (alertId) {
        addClosedAlert(alertId);
      }
      setIsVisible(false);
      alertProps.onClose?.(event);
    },
    [alertId, alertProps]
  );

  if (!visible) {
    return null;
  }

  return (
    <Component {...alertProps} onClose={closeable ? onClose : undefined}>
      {typeof children === 'function' ? children({ onClose }) : children}
    </Component>
  );
};
