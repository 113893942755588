import {
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useImperativeHandle,
  useRef,
} from 'react';

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { FormApi, Mutator } from 'final-form';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash/isEqual';
import { Form, FormProps, FormRenderProps } from 'react-final-form';

import { RffFormContext } from './_providers';
import { FormMode } from './types';

export type RffFormRef<
  InitialFormValues extends unknown,
  FormValues extends unknown = InitialFormValues
> = {
  form: FormApi<FormValues, InitialFormValues>;
};

type Props<
  InitialFormValues extends unknown,
  FormValues extends unknown = InitialFormValues
> = Omit<
  FormProps<FormValues, InitialFormValues>,
  'initialValuesEqual' | 'initialValues' | 'mutators'
> & {
  children?:
    | ReactNode
    | ((form: FormRenderProps<FormValues, InitialFormValues>) => ReactNode);
  array?: boolean;
  mode?: FormMode;
  mutators?: { [key: string]: Mutator<FormValues, InitialFormValues> };
  initialValues: InitialFormValues;
  initialValuesEqual?: (
    a?: InitialFormValues,
    b?: InitialFormValues
  ) => boolean;
  onDirty?: (isDirty: boolean) => void;
  onValidate?: (isValid: boolean) => void;
  sx?: SxProps;
  testId?: string;
  submitting?: boolean;
};

const InnerRffForm = <
  InitialFormValues extends unknown,
  FormValues extends unknown = InitialFormValues
>(
  {
    children,
    mutators = {},
    array,
    mode = 'create',
    onDirty,
    onValidate,
    sx,
    testId,
    submitting,
    initialValuesEqual = isEqual,
    ...formProps
  }: Props<FormValues, InitialFormValues>,
  ref: Ref<RffFormRef<FormValues, InitialFormValues>>
) => {
  const formRef = useRef<RffFormRef<FormValues, InitialFormValues>>(
    undefined as never
  );
  const dirtyRef = useRef<boolean | undefined>();
  const validRef = useRef<boolean | undefined>();

  useImperativeHandle(ref, () => formRef.current);

  return (
    <Form
      render={(formProps) => {
        const { handleSubmit, ...rest } = formProps;

        if (dirtyRef.current !== formProps.dirty) {
          onDirty?.(formProps.dirty);
          dirtyRef.current = formProps.dirty;
        }

        if (validRef.current !== formProps.valid) {
          onValidate?.(formProps.valid);
          validRef.current = formProps.valid;
        }

        if (!formRef.current) {
          formRef.current = {
            form: rest.form,
          } as unknown as RffFormRef<FormValues, InitialFormValues>;
        }

        return (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={sx}
            data-testid={testId}
            className={clsx('RffForm-root', formProps.dirty && 'RffForm-dirty')}
          >
            <RffFormContext.Provider
              value={{ mode, submitting: submitting ?? formProps.submitting }}
            >
              {typeof children === 'function'
                ? children(formProps as any)
                : children}
            </RffFormContext.Provider>
          </Box>
        );
      }}
      mutators={array ? { ...arrayMutators, ...mutators } : { ...mutators }}
      initialValuesEqual={initialValuesEqual}
      {...(formProps as any)}
    />
  );
};

export const RffForm = forwardRef(InnerRffForm) as (<
  FormValues extends unknown,
  InitialFormValues extends unknown = FormValues
>(
  props: Props<FormValues, InitialFormValues> & {
    ref?: Ref<RffFormRef<FormValues, InitialFormValues> | undefined>;
  }
) => ReactElement | null) & { displayName: string };

RffForm.displayName = 'RffForm';
