import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import throttle from 'lodash/throttle';

export const useElementRect = <T = DOMRect>(
  element?: HTMLElement | null,
  isEnabled = true,
  selector?: (rect: DOMRectReadOnly) => T,
  isEqual: (a: T, b: T) => boolean = (a: T, b: T) => a === b
): T => {
  const [elementDimensions, setElementDimensions] = useState<T | undefined>();
  useLayoutEffect(() => {
    if (element && isEnabled) {
      const rect = element.getBoundingClientRect();
      if (selector) {
        const slice = selector(rect);
        if (!isEqual(slice, elementDimensions!)) {
          setElementDimensions(slice);
        }
      } else {
        setElementDimensions(rect as any);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, isEnabled]);

  const elementObserverRef = useRef<ResizeObserver | undefined>();

  const cleanObservers = () => {
    elementObserverRef.current?.disconnect();
    elementObserverRef.current = undefined;
  };

  useEffect(() => {
    if (!element || !isEnabled) {
      cleanObservers();
      return;
    }
    elementObserverRef.current = new ResizeObserver((entries) => {
      const entry = entries[0];
      const rect = entry.target.getBoundingClientRect();
      if (selector) {
        const slice = selector(rect);
        if (!isEqual(slice, elementDimensions!)) {
          setElementDimensions(slice);
        }
      } else {
        setElementDimensions(rect as any);
      }
    });
    elementObserverRef.current.observe(element);
    return cleanObservers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, isEnabled]);
  return elementDimensions as T;
};

export const useElementWidth = (
  element?: HTMLElement | null,
  isEnabled = true
) => useElementRect(element, isEnabled, ({ width }) => width);

export const useElementHeight = (
  element?: HTMLElement | null,
  isEnabled = true
) => useElementRect(element, isEnabled, ({ height }) => height);

type UseWindowDimensions = {
  throttleTime?: number;
  enabled?: boolean;
  width?: boolean;
  height?: boolean;
};
export const useWindowDimensions = ({
  throttleTime = 200,
  width: emitWidth = true,
  height: emitHeight = true,
  enabled = true,
}: UseWindowDimensions = {}) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const throttledHandleResize = throttle(() => {
      if (emitWidth) {
        setWidth(window.innerWidth);
      }
      if (emitHeight) {
        setHeight(window.innerHeight);
      }
    }, throttleTime);

    if (enabled) {
      window.addEventListener('resize', throttledHandleResize, {
        passive: true,
      });
    }

    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, [emitWidth, emitHeight, throttleTime, enabled]);

  return { width, height };
};

export const useWindowWidth = ({
  width = true,
  ...rest
}: Omit<UseWindowDimensions, 'height'> = {}) => {
  return useWindowDimensions({ ...rest, width, height: false }).width;
};

export const useWindowHeight = ({
  height = true,
  ...rest
}: Omit<UseWindowDimensions, 'width'> = {}) => {
  return useWindowDimensions({ ...rest, height, width: false }).height;
};
