import Big from 'big.js';

import { theme } from '@cast/design-system';
import {
  CostReportClusterResourceUsage,
  CostReportClusterSummary,
  NodeManagedByEnum,
} from '@cast/types';

import { nodeTypeColors } from 'common/maps';

import { TimeSeries } from './metrics';

export type CostReportClusterSummaryNormalized = {
  clusterId?: string;
  cpuAllocatableTotal: Big;
  cpuRequestedTotal: Big;
  cpuProvisionedTotal: Big;
  cpuCostHourlyTotal: Big;
  ramAllocatableTotal: Big;
  ramRequestedTotal: Big;
  ramProvisionedTotal: Big;
  gpuProvisionedTotal: Big;
  gpuAllocatableTotal: Big;
  gpuUsedTotal: Big;
  gpuRequestedTotal: Big;
  nodesCountTotal: Big;
  nodesCountManagedTotal: Big;
} & {
  [K in keyof Omit<CostReportClusterSummary, 'clusterId'>]-?: Big;
};

export type CostReportNodesSummaryNormalized = {
  cpuOverprovisioning: Big;
  linuxNodeCount: Big;
  managedBy: NodeManagedByEnum;
  ramOverprovisioning: Big;
  windowsNodeCount: Big;
};

export type CostReportResourceUsageNormalized = TimeSeries<{
  [K in keyof Omit<CostReportClusterResourceUsage, 'timestamp'>]-?: Big;
}>;

export const computeSpendColorsMap = {
  ...nodeTypeColors,
  storage: theme.palette.aqua[300],
};

export enum ComputeSpendType {
  ON_DEMAND = 'onDemand',
  SPOT = 'spot',
  FALLBACK = 'fallback',
  STORAGE = 'storage',
}
