import { forwardRef } from 'react';

import {
  capitalize,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
} from '@mui/material';
import clsx from 'clsx';

export type DrawerColor = 'primary' | 'secondary';
export type DrawerSize =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge';
export type DrawerProps = MuiDrawerProps & {
  color?: DrawerColor;
  size?: DrawerSize;
  testId?: string;
};

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  (
    {
      color = 'primary',
      size = 'medium',
      children,
      PaperProps = {},
      testId = 'ds-drawer',
      ...rest
    }: DrawerProps,
    ref
  ) => {
    return (
      <MuiDrawer
        ref={ref}
        anchor="right"
        {...rest}
        PaperProps={{
          ...PaperProps,
          className: clsx(
            'DS-Drawer-root',
            `DS-Drawer-color${capitalize(color)}`,
            !!size && `DS-Drawer-size${capitalize(size)}`,
            PaperProps?.className
          ),
        }}
        data-testid={testId}
      >
        {children}
      </MuiDrawer>
    );
  }
);

Drawer.displayName = 'Drawer';
