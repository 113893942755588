import { styled } from '@mui/material';

import { Icons } from '@cast/design-system';

export const NotificationIcon = styled(Icons.CirclesFour)(({ theme }) => ({
  circle: {
    display: 'none',
    '&:nth-of-type(1)': {
      display: 'block',
      fill: theme.palette.red[400],
      stroke: theme.palette.red[400],
    },
  },
}));
