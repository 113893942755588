import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';

import { RbacRole, RbacUserResource, RoleAccess } from '@cast/types';

import { useAuth } from 'core/auth';
import { useClustersQuery } from 'hooks/queries/cluster/useClustersQuery';
import { useRbacResolveAccessQuery } from 'hooks/queries/rbac/useRbacResolveAccessQuery';
import { useRbacRolesQuery } from 'hooks/queries/rbac/useRbacRolesQuery';
import { useResolveFeatureFlags } from 'hooks/useResolveFeatureFlags';

import {
  AccessVia,
  isUserResourceBinding,
  AccessControlContextState,
  ControlData,
  ControlDataBySubjectId,
  ResourceAccessByResourceId,
} from './types';

const AccessControlContext = createContext<AccessControlContextState>(
  {} as never
);

const getAccessVia = (access: RoleAccess): AccessVia => {
  if (access.groupsRef?.length) {
    return access.groupsRef;
  }

  if (access.inherited) {
    return 'inherited';
  }

  return 'direct';
};

const getEndpoints = (rolesById: Record<string, RbacRole>, id?: string) => {
  return id
    ? rolesById[id]?.definition?.permissions?.find((p) => p.effect === 'ALLOW')
        ?.endpoints ?? []
    : [];
};

const reduceResources = (
  rolesById: Record<string, RbacRole>,
  resources: RbacUserResource[],
  access: ResourceAccessByResourceId
) => {
  for (const resource of resources) {
    if (resource.id) {
      const _access = orderBy(resource.access, ['roleLevel'], ['desc'])?.[0];
      if (_access) {
        access[resource.id] = {
          id: resource.id,
          access: _access,
          accessVia: getAccessVia(_access),
          hasAccessTo: getEndpoints(rolesById, _access.roleId),
        };
      }
    }
  }
};

type Props = {
  users?: string[];
  groups?: string[];
  useCurrentUser?: boolean;
};

export const AccessControlProvider = ({
  users,
  groups,
  useCurrentUser,
  children,
}: PropsWithChildren<Props>) => {
  const { user } = useAuth();
  const { isLoadingFeatureFlags, featureFlags } = useResolveFeatureFlags();

  const isRBACV2Enabled = Boolean(
    user?.guid &&
      !isLoadingFeatureFlags &&
      featureFlags?.find((f) => f.flagName === 'wire-rbacv2-enabled')?.boolean
  );

  const {
    isLoading: isLoadingAccessData,
    data: accessData,
    isError: accessDataError,
    refetch: refetchAccessData,
  } = useRbacResolveAccessQuery({
    enabled: isRBACV2Enabled,
    users: useCurrentUser ? [user?.guid ?? ''] : users,
    groups: groups ?? [],
  });

  const {
    isLoading: isLoadingRoles,
    data: roles,
    isError: rolesError,
    refetch: refetchRoles,
  } = useRbacRolesQuery(isRBACV2Enabled);

  const rolesById = keyBy(roles, 'id');

  const accessControlData: ControlDataBySubjectId = useMemo(() => {
    const result: ControlDataBySubjectId = {};
    const userData = accessData?.users || [];
    const groupData = accessData?.groups || [];

    if (!userData && !groupData) {
      return result;
    }

    const data = [...userData, ...groupData].map((binding) => ({
      subjectId: isUserResourceBinding(binding)
        ? binding.userId
        : binding.groupId,
      resources: binding.resources,
    }));

    for (const { subjectId, resources } of data) {
      const controlData: ControlData = {
        clusters: {},
        organizations: {},
      };
      if (!subjectId || !resources) {
        continue;
      }
      const { CLUSTER: clusterResources, ORGANIZATION: orgResources } = groupBy(
        resources,
        'type'
      );

      if (clusterResources) {
        reduceResources(rolesById, clusterResources, controlData.clusters);
      }

      if (orgResources) {
        reduceResources(rolesById, orgResources, controlData.organizations);
      }
      result[subjectId] = controlData;
    }
    return result;
  }, [accessData?.groups, accessData?.users, rolesById]);

  return (
    <AccessControlContext.Provider
      value={{
        isLoading: isLoadingAccessData || isLoadingRoles,
        accessControlData,
        error: accessDataError || rolesError,
        refetch: () => {
          return Promise.all([refetchAccessData(), refetchRoles()]);
        },
      }}
    >
      {children}
    </AccessControlContext.Provider>
  );
};

type UseAccessControlDataProps = {
  excludeOrphanedClusters?: boolean;
};

export const useAccessControlData = ({
  excludeOrphanedClusters = true,
}: UseAccessControlDataProps) => {
  const ctx = useContext(AccessControlContext);

  const { clusters, isLoading: isLoadingClusters } = useClustersQuery({
    enabled: excludeOrphanedClusters,
  });

  let isLoading = ctx.isLoading;
  let accessControlData = ctx.accessControlData;
  const refetch = ctx.refetch;
  const error = ctx.error;

  if (excludeOrphanedClusters) {
    const clustersById = keyBy(clusters, 'id');

    isLoading = isLoading || isLoadingClusters;
    accessControlData = mapValues(accessControlData, (accessData) => ({
      ...accessData,
      clusters: pickBy(
        accessData.clusters,
        (cluster) => cluster.id in clustersById
      ),
    }));
  }

  return {
    isLoading,
    accessControlData,
    refetch,
    error,
  };
};

type UseAccessControlProps = {
  subjectId?: string;
  excludeOrphanedClusters?: boolean;
};
export const useAccessControl = ({
  subjectId,
  excludeOrphanedClusters = true,
}: UseAccessControlProps) => {
  const accessCtx = useAccessControlData({
    excludeOrphanedClusters,
  });

  if (!subjectId) {
    return {
      isLoading: true,
      subjectAccessData: {
        organizations: {},
        clusters: {},
      },
      refetch: () => undefined,
      error: false,
    };
  }

  return {
    isLoading: accessCtx.isLoading,
    subjectAccessData: accessCtx.accessControlData[subjectId],
    refetch: accessCtx.refetch,
    error: accessCtx.error,
  };
};
