import { ReactNode } from 'react';

import { Field, FieldProps } from 'react-final-form';

import { PasswordInput, PasswordInputProps } from '@cast/design-system';

import { useRffFormState } from '../hooks';
import { showErrorOnChange } from '../utils';

type Props = Omit<PasswordInputProps, 'name'> & {
  name: string;
  renderError?: (error: string | string[]) => ReactNode;
  fieldProps?: Partial<FieldProps<string, any>>;
};

export const RffPasswordInput = ({
  fieldProps = {},
  renderError,
  ...inputProps
}: Props) => {
  const { mode } = useRffFormState();
  const readOnly = mode === 'view';
  return (
    <Field
      {...fieldProps}
      name={inputProps.name}
      render={(props) => {
        const {
          input: { onBlur, onFocus, onChange, value },
          meta,
        } = props;
        const showError = showErrorOnChange(meta);
        return (
          <PasswordInput
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            value={value}
            error={
              showError ? renderError?.(meta.error) || meta.error : undefined
            }
            readOnly={readOnly}
            {...inputProps}
            success={meta.touched && meta.valid}
          />
        );
      }}
    />
  );
};
