import { MouseEvent, useCallback } from 'react';

import { Box, Stack, StackProps, styled } from '@mui/material';
import clsx from 'clsx';
import copyFn from 'copy-to-clipboard';
import { Copy } from 'phosphor-react';

import { shouldForwardProp } from '../../../utils/shouldForwardProp';
import { getThemeColor } from '../../../utils/theme';
import { Scroller } from '../../scroller';

export type PreviewSnippetProps = Omit<StackProps, 'onCopy'> & {
  code: string;
  onCopy?: (code: string, e: MouseEvent) => void;
  renderPreview?: (code: string) => string;
  errorHighlight?: boolean | string;
  maxHeight?: number;
  testId?: string;
};

const CodeStack = styled(Stack, {
  target: 'DsPreviewSnippet-codeStack',
})(({ theme }) => ({
  position: 'relative',
  '& > pre': {
    whiteSpace: 'inherit',
    wordBreak: 'break-all',
    paddingRight: '32px',
  },
  '& a': {
    color: theme.palette.blue[400],
    textDecoration: 'none',
    wordBreak: 'break-all',
    '&:hover': {
      color: theme.palette.blue[500],
      textDecoration: 'underline',
    },
  },
}));

const StyledScroller = styled(Scroller, {
  target: 'DsPreviewSnippet-Scroller os-theme-light',
  shouldForwardProp,
})({});

const CopyCode = styled(Copy, {
  target: 'DsPreviewSnippet-copyCode',
})({
  cursor: 'pointer',
  fontSize: '16px',
  color: 'white',
  position: 'absolute',
  top: 8,
  right: 8,

  '&: hover': {
    color: 'inherit',
  },
});

export const PreviewSnippet = styled(
  ({
    code,
    onCopy,
    renderPreview,
    errorHighlight,
    maxHeight,
    testId,
    ...props
  }: PreviewSnippetProps) => {
    const handleCopy = useCallback(
      (e: MouseEvent) => {
        copyFn(code);
        onCopy?.(code, e);
      },
      [code, onCopy]
    );

    return (
      <Stack
        position="relative"
        data-testid={testId}
        {...props}
        className={clsx(props.className, 'PreviewSnippet-Root')}
      >
        <StyledScroller
          options={{ scrollbars: { autoHide: 'move' } }}
          sx={{
            maxHeight,
            '&.os-host-overflow': { height: '100%' },
          }}
        >
          <CodeStack
            direction="row"
            justifyContent="space-between"
            gap={8}
            data-testid="preview-snippet"
            sx={
              errorHighlight
                ? (theme) => ({
                    paddingLeft: '8px',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      width: '3px',
                      borderRadius: '8px',
                      borderLeft: `3px solid ${getThemeColor(
                        theme,
                        typeof errorHighlight === 'string'
                          ? errorHighlight
                          : 'red.400'
                      )}`,
                    },
                  })
                : undefined
            }
          >
            <Box
              flex={1}
              component="pre"
              margin={0}
              dangerouslySetInnerHTML={
                renderPreview
                  ? {
                      __html: renderPreview(code),
                    }
                  : undefined
              }
            >
              {!renderPreview ? code : null}
            </Box>
          </CodeStack>
        </StyledScroller>
        {Boolean(onCopy) && <CopyCode onClick={handleCopy} />}
      </Stack>
    );
  },
  {
    target: 'DsPreviewSnippet-root',
  }
)<PreviewSnippetProps>(({ theme }) => ({
  typography: 'C12',
  backgroundColor: theme.palette.grey[900],
  color: 'white',
  borderRadius: '4px',
  padding: '12px 0 12px 12px',
}));
