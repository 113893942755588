import { useContext } from 'react';

import { styled } from '@mui/material';
import clsx from 'clsx';

import { Cell } from './Cell';
import { DataPopperContext } from './context';
import { RowDivider } from './RowDivider';
import { RowIndicator } from './RowIndicator';
import {
  DataPopperProps,
  RowIndicatorProps,
  DataPopperRowProps,
} from './types';
import { shouldForwardProp } from '../../utils/shouldForwardProp';

type OwnerState = DataPopperRowProps & { popperProps: DataPopperProps };

const RowRoot = styled('div', {
  name: 'DsDataPopperRow',
  slot: 'Root',
  target: 'DsDataPopperRow-Root',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.root],
})<{ ownerState: OwnerState }>``;

const RowContent = styled('div', {
  name: 'DsDataPopperRow',
  slot: 'RowContent',
  target: 'DsDataPopperRow-RowContent',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.rowContent],
})<{ ownerState: OwnerState }>``;

export const DataPopperRow = (props: DataPopperRowProps) => {
  const { popperProps } = useContext(DataPopperContext);
  const _props = { size: popperProps.size, ...props };
  let indicatorProps: RowIndicatorProps | undefined;
  if (typeof props.indicator === 'string') {
    indicatorProps = { color: props.indicator };
  } else {
    indicatorProps = props.indicator;
  }
  const ownerState = { ..._props, popperProps };
  return (
    <DataPopperContext.Provider value={{ popperProps, rowProps: props }}>
      {props.dividerTop && <RowDivider />}
      <RowRoot
        className={clsx('DsDataPopper-Row', props.className)}
        ownerState={ownerState}
        sx={props.sx}
        data-testid={props.testId}
      >
        {indicatorProps && <RowIndicator {...indicatorProps} />}
        <RowContent ownerState={ownerState}>
          <Cell data-testid="data-popper-cell-left">{props.left}</Cell>
          {props.children}
          <Cell data-testid="data-popper-cell-right">{props.right}</Cell>
        </RowContent>
      </RowRoot>
      {props.dividerBottom && <RowDivider />}
    </DataPopperContext.Provider>
  );
};
