import { alpha, Stack, styled, Typography } from '@mui/material';

import { Input, Slider } from '@cast/design-system';

import { WhenProvider } from 'components/cluster';

import { useAvailableSavingsArmSupport } from '../../../_providers/ArmSupportProvider';

const StyledSlider = styled(Slider)(({ theme }) => ({
  '--thumbColor': theme.palette.grey[800],
  '--thumbHoverColor': theme.palette.grey[800],
  '--trackColor': theme.palette.grey[600],
  '--activeShadowColor': alpha(theme.palette.grey[800]!, 0.3),
}));

export const SetCpusToRunOnArm = () => {
  const { armCpuPercentage, setArmCpuPercentage } =
    useAvailableSavingsArmSupport();

  return (
    <Stack maxWidth={244}>
      <StyledSlider
        size="small"
        value={armCpuPercentage}
        onChange={(_, value) =>
          setArmCpuPercentage(Array.isArray(value) ? value[0] : value)
        }
      />
      <Stack direction="row" alignItems="center" gap={8}>
        <Input
          type="number"
          size="small"
          endAdornment="%"
          value={armCpuPercentage}
          onChange={(_, value) => setArmCpuPercentage(+value)}
          sx={{ width: 50 }}
          testId="cpus-to-run-on-arm"
        />
        <Typography variant="P12M">
          CPUs to run on{' '}
          <WhenProvider provider="eks" fallback="ARM">
            Graviton(ARM)
          </WhenProvider>
        </Typography>
      </Stack>
    </Stack>
  );
};
