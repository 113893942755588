import {
  Box,
  BoxProps,
  styled,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import clsx from 'clsx';
import { useModal } from 'mui-modal-provider';

import { mergeSx } from '@cast/design-system';

import { PlaySvg } from 'assets/svg';
import { analyticsEvents } from 'core/analytics';

import { VideoDialog } from './VideoDialog';

export type VideoModalProps = {
  thumbnail: string;
  video: string;
  playIconAlwaysVisible?: boolean;
  eventName?: string;
  titleProps?: TypographyProps;
  iconSx?: SxProps;
  iconSize?: number;
  disabled?: boolean;
  testId?: string;
} & BoxProps;

const StyledPlayIcon = styled(PlaySvg)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  transition: 'opacity 0.3s ease-in-out',
  zIndex: 1,
  width: '1em',
  height: '1em',
});

export const videoModalClasses = {
  root: 'VideoModal-root',
  icon: 'VideoModal-icon',
  title: 'VideoModal-title',
};

export const Video = ({
  thumbnail,
  video,
  playIconAlwaysVisible = true,
  sx,
  className,
  title,
  eventName,
  titleProps = {},
  iconSx,
  iconSize = 37,
  disabled,
  testId = 'video',
  ...boxProps
}: VideoModalProps) => {
  const { showModal } = useModal();

  return (
    <Box
      onClick={
        !disabled
          ? () => {
              showModal(VideoDialog, { video, testId });
              analyticsEvents.playedVideo(eventName || title || video);
            }
          : undefined
      }
      sx={mergeSx(
        {
          position: 'relative',
          display: 'inline-flex',
          width: '100%',
          height: '100%',
          p: '8px 16px',
          backgroundImage: `url(${thumbnail})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          cursor: !disabled ? 'pointer' : undefined,
        },
        !playIconAlwaysVisible && {
          [`& .${videoModalClasses.icon}`]: {
            opacity: 0,
          },
          [`&:hover .${videoModalClasses.icon}`]: {
            opacity: 1,
          },
        },
        sx
      )}
      className={clsx(videoModalClasses.root, className)}
      data-testid={testId}
      {...boxProps}
    >
      <StyledPlayIcon
        className={videoModalClasses.icon}
        sx={mergeSx({ fontSize: iconSize }, iconSx)}
      />
      {title && (
        <Typography
          variant="P14B"
          color="white"
          zIndex={1}
          noWrap
          {...titleProps}
          className={clsx(videoModalClasses.title, titleProps?.className)}
        >
          {title}
        </Typography>
      )}
    </Box>
  );
};
