export const StackedBarShape = (props: any) => {
  const {
    fill,
    stroke,
    strokeOpacity,
    x,
    y,
    width,
    height,
    radius,
    last,
    background,
    minValue,
  } = props;
  const h = height;
  // x axis Y coordinate
  const y0 = background.height + background.y;
  // calculated height to chart x axis
  const h0 = y0 - y - radius;
  const empty = !h || h0 <= 0 || props.value[1] - props.value[0] === 0;
  const isFirstStack =
    (minValue !== undefined && props.value[0] < minValue) || // props.value[0] < minValue -> previous stack value is lower than expected
    props.value[0] === 0;
  const noBackgroundFiller = `v${h - radius} h${-width} v${-h + radius}`;
  const backgroundFiller = `v${h} q0,${-radius} ${-radius},${-radius}  h${
    -width + radius * 2
  } q${-radius},0 ${-radius},${radius} v${-h}`;

  if (empty) {
    return null;
  }

  return (
    <g className="bar-shape" data-testid={`chart-bar-${props.index}`}>
      <path
        x={x}
        y={y}
        width={width}
        height={h}
        fill={fill}
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth={1}
        d={`M${x + width / 2},${y} h${
          width / 2 - radius
        } q${radius},0 ${radius},${radius} ${
          isFirstStack ? noBackgroundFiller : backgroundFiller
        } q0,${-radius} ${radius},${-radius} z`}
      />

      {fill === 'none' && (
        <>
          <path
            x={x}
            y={y}
            stroke={stroke}
            strokeOpacity={strokeOpacity}
            strokeWidth={1}
            fill="none"
            d={`M${x},${y + radius} q0,${-radius} ${radius},${-radius}  h${
              width - radius * 2
            } q${radius},0 ${radius},${radius}`}
          />

          <path
            x={x}
            y={y}
            stroke={stroke}
            strokeOpacity={strokeOpacity}
            strokeWidth={1}
            d={`M${x},${last ? y + radius : y + radius} v${h0}`}
          />

          <path
            x={x}
            y={y}
            stroke={stroke}
            strokeOpacity={strokeOpacity}
            strokeWidth={1}
            d={`M${x + width},${last ? y + radius : y + radius} v${h0}`}
          />
        </>
      )}
    </g>
  );
};
