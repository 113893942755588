import { ThemeOptions } from '@mui/material';

export const multiSorterTheme: ThemeOptions['components'] = {
  DsMultiSorter: {
    styleOverrides: {
      iconWrapper: {
        cursor: 'pointer',
        marginLeft: '5px',
      },
      icon: ({
        ownerState: { isOpen, sortingActive },
        theme: { palette },
      }) => ({
        fontSize: '16px',
        color: isOpen || sortingActive ? palette.blue[500] : palette.grey[500],
      }),
    },
  },
};
