export default {
  50: '#E6DFFF',
  100: '#C8BAFF',
  200: '#A58EFF',
  300: '#7F61F0',
  400: '#593EBE',
  500: '#473297',
  600: '#372772',
  700: '#2D2060',
  800: '#271D51',
  900: '#1A1B41',
};
