import { styled } from '@mui/material';

import { ConfigType } from '@cast/types';

import { ComputeSpendType } from 'types/cost-report';
import { NormalizedCostMode } from 'types/nodes';

export type NodeIndicatorProps = {
  size: number;
  type:
    | `${ComputeSpendType}`
    | `${NormalizedCostMode}`
    | `${ConfigType}${ComputeSpendType}`;
};

export const NodeIndicator = styled('div')<NodeIndicatorProps>(
  ({ theme: { palette }, size = 12, type }) => {
    const base = {
      height: size,
      width: size,
      borderRadius: size / 6,
    };

    switch (type) {
      case `${NormalizedCostMode.NORMALIZED}`:
        return {
          ...base,
          backgroundColor: palette.blue[700],
          border: `1px solid ${palette.blue[700]}`,
        };
      case ComputeSpendType.ON_DEMAND:
      case `${NormalizedCostMode.ON_DEMAND}`:
        return {
          ...base,
          backgroundColor: palette.blue[200],
          border: `1px solid ${palette.blue[200]}`,
        };

      case ComputeSpendType.FALLBACK:
      case `${NormalizedCostMode.FALLBACK}`:
      case `${ConfigType.CURRENT}${ComputeSpendType.FALLBACK}`:
        return {
          ...base,
          backgroundColor: palette.yellow[200],
          border: `1px solid ${palette.yellow[200]}`,
        };
      case `${NormalizedCostMode.SPOT}`:
      case ComputeSpendType.SPOT:
        return {
          ...base,
          backgroundColor: palette.indigo[800],
          border: `1px solid ${palette.indigo[800]}`,
        };
      case `${ConfigType.CURRENT}${ComputeSpendType.ON_DEMAND}`:
        return {
          ...base,
          backgroundColor: palette.blue[200],
          border: `1px solid ${palette.blue[200]}`,
          opacity: 0.5,
        };
      case `${ConfigType.CURRENT}${ComputeSpendType.SPOT}`:
        return {
          ...base,
          backgroundColor: palette.blue[500],
          border: `1px solid ${palette.blue[500]}`,
          opacity: 0.5,
        };
      case `${ConfigType.OPTIMAL}${ComputeSpendType.ON_DEMAND}`:
        return {
          ...base,
          backgroundColor: palette.green[200],
          border: `1px solid ${palette.green[200]}`,
          opacity: 0.5,
        };
      case `${ConfigType.OPTIMAL}${ComputeSpendType.FALLBACK}`:
        return {
          ...base,
          backgroundColor: palette.common.white,
          border: `1px dashed ${palette.green[200]}`,
        };
      case `${ConfigType.OPTIMAL}${ComputeSpendType.SPOT}`:
        return {
          ...base,
          backgroundColor: palette.green[500],
          border: `1px solid ${palette.green[500]}`,
          opacity: 0.5,
        };
      case `${ComputeSpendType.STORAGE}`:
        return {
          ...base,
          backgroundColor: palette.aqua[300],
          border: `1px solid ${palette.aqua[300]}`,
        };
    }
  }
);

NodeIndicator.displayName = 'NodeIndicator';
