import { forwardRef, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

import { TooltipProps } from '@cast/design-system';

import { TooltipWithClickAwayListener } from 'components/tooltip';

export const TRANSITION_PROPS = {
  timeout: {
    exit: 500,
  },
};

type Props = {
  open?: boolean;
  text: string | ReactNode;
  note?: string | ReactNode;
  footer: ReactNode;
  children: TooltipProps['children'];
  placement: TooltipProps['placement'];
  onClickAway?: () => void;
  popperProps?: TooltipProps['PopperProps'];
  testId?: string;
};

export const TourTooltip = forwardRef<TooltipProps['ref'], Props>(
  (
    {
      text,
      note,
      footer,
      placement,
      open,
      popperProps,
      children,
      onClickAway,
      testId,
    }: Props,
    ref
  ) => {
    return (
      <TooltipWithClickAwayListener
        ref={ref}
        open={open}
        placement={placement}
        onClickAway={onClickAway}
        TransitionProps={TRANSITION_PROPS}
        title={
          <Stack gap="16px" data-testid={testId}>
            <Stack direction="row" gap="8px">
              <Stack gap="8px">
                {typeof text === 'string' ? (
                  <Typography variant="P14R">{text}</Typography>
                ) : (
                  text
                )}
                {note &&
                  (typeof note === 'string' ? (
                    <Typography variant="P12R" color="grey.400">
                      {note}
                    </Typography>
                  ) : (
                    note
                  ))}
              </Stack>
            </Stack>
            {footer}
          </Stack>
        }
        PopperProps={{
          ...(popperProps || {}),
          sx: (theme) => {
            return {
              '&.MuiTooltip-popper': {
                zIndex: theme.zIndex.drawer - 1,
              },
              '& .MuiTooltip-tooltip': {
                maxWidth: '337px',
                p: '16px',
                borderRadius: '4px',
                border: '1px solid',
                borderColor: 'aqua.600',
                boxShadow: `0px 0px 24px rgba(168, 255, 255, 0.5)`,
              },

              '& .MuiTooltip-arrow::before': {
                border: `1px solid`,
                borderColor: 'aqua.600',
              },
            };
          },
        }}
        arrow
      >
        {children}
      </TooltipWithClickAwayListener>
    );
  }
);

TourTooltip.displayName = 'TourTooltip';
