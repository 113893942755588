import { ElementType, PropsWithChildren, ReactNode } from 'react';

import {
  Box,
  Link,
  Stack,
  StackProps,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import { Icons } from '@cast/design-system';

import { Marker, MarkerProps } from 'components/dividers';
import { InfoTooltip, InfoTooltipProps } from 'components/tooltip/InfoTooltip';

type LinkWrapperProps = { link?: string; iconSize?: number };
export type MetricsBoxProps<C extends ElementType = any> = {
  markerColor?: string;
  markerEmpty?: boolean;
  title: ReactNode;
  values?: ReactNode;
  primaryValue: ReactNode;
  secondaryValue?: ReactNode;
  titleProps?: TypographyProps<C>;
  valuesContainerProps?: StackProps;
  valueStackProps?: StackProps;
  linkWrapperProps?: LinkWrapperProps;
  primaryValueProps?: TypographyProps<C>;
  secondaryValueProps?: TypographyProps<C>;
  markerProps?: Partial<MarkerProps>;
  gap?: number;
  valueLink?: string;
  tooltip?: ReactNode;
  tooltipProps?: Partial<InfoTooltipProps>;
  sx?: SxProps;
  className?: string;
  testId?: string;
  extraBottomContent?: ReactNode;
};

const LinkWrapper = ({
  link,
  iconSize = 14,
  children,
}: PropsWithChildren<LinkWrapperProps>) => {
  if (!link) {
    return <>{children}</>;
  }
  return (
    <Link
      to={link}
      component={RouterLink}
      sx={{
        textDecoration: 'none !important',
        display: 'inline-flex',
        gap: 5,
        alignItems: 'baseline',
        width: 'max-content',
        '& > svg': {
          visibility: 'hidden',
        },
        '&:hover': {
          '& .MetricsBox-value, & .MetricsBox-secondaryValue': {
            color: 'inherit',
          },
          '& svg': {
            visibility: 'initial',
          },
        },
      }}
    >
      {children}
      <Icons.ArrowSquareOut size={iconSize} color="currentColor" />
    </Link>
  );
};

export const Values = <C extends ElementType = any>({
  primaryValue,
  primaryValueProps,
  secondaryValue,
  secondaryValueProps,
  gap = 4,
  testId,
  stackProps,
}: Pick<
  MetricsBoxProps<C>,
  | 'primaryValue'
  | 'primaryValueProps'
  | 'secondaryValue'
  | 'secondaryValueProps'
  | 'gap'
  | 'testId'
> & { stackProps: StackProps }) => (
  <Stack direction="row" gap={gap} alignItems="baseline" {...stackProps}>
    <Typography
      className="MetricsBox-value"
      variant="h4"
      fontWeight={700}
      lineHeight="28px"
      noWrap
      data-testid={`${testId}-primary-value`}
      {...primaryValueProps}
    >
      {primaryValue}
    </Typography>
    {!!secondaryValue && (
      <Typography
        component="span"
        {...secondaryValueProps}
        className={clsx(
          'MetricsBox-secondaryValue',
          secondaryValueProps?.className
        )}
        data-testid={`${testId}-secondary-value`}
      >
        {secondaryValue}
      </Typography>
    )}
  </Stack>
);

export const MetricsBox = <C extends ElementType = 'span'>({
  markerColor,
  markerEmpty,
  title,
  primaryValue,
  secondaryValue,
  titleProps,
  linkWrapperProps = {},
  valuesContainerProps = {},
  valueStackProps = {},
  primaryValueProps,
  secondaryValueProps,
  markerProps,
  tooltip,
  tooltipProps,
  gap = 8,
  valueLink,
  sx,
  className,
  extraBottomContent,
  testId = 'metrics-box',
}: MetricsBoxProps<C>) => {
  return (
    <Box
      className={clsx('MetricsBox-root', className)}
      display="flex"
      alignItems="center"
      sx={sx}
      data-testid={testId}
    >
      {!!markerColor && (
        <Marker
          minHeight={42}
          width={markerEmpty ? 4 : 2}
          mr={10}
          flexShrink={0}
          {...markerProps}
          color={markerColor}
          empty={markerEmpty}
        />
      )}
      <Stack minWidth={0} width="100%" {...valuesContainerProps}>
        <Typography
          variant="L12R"
          component="div"
          color="grey.900"
          noWrap
          data-testid={`${testId}-title`}
          display="inline-flex"
          alignItems="center"
          gap="4px"
          {...titleProps}
          className={clsx('MetricsBox-title', titleProps?.className)}
        >
          {title}
          {tooltip && (
            <InfoTooltip title={tooltip} placement="top" {...tooltipProps} />
          )}
        </Typography>

        <LinkWrapper link={valueLink} {...linkWrapperProps}>
          <Values
            primaryValue={primaryValue}
            primaryValueProps={primaryValueProps}
            secondaryValue={secondaryValue}
            secondaryValueProps={secondaryValueProps}
            gap={gap}
            testId={testId}
            stackProps={valueStackProps}
          />
        </LinkWrapper>
        {extraBottomContent && (
          <Box data-testid={`${testId}-extra-bottom-content`}>
            {extraBottomContent}
          </Box>
        )}
      </Stack>
    </Box>
  );
};
