import { forwardRef, useEffect, useMemo, useRef } from 'react';

import { useOverlayScrollbars } from 'overlayscrollbars-react';
import { ScrollerProps } from 'react-virtuoso';

import { setRef } from '@cast/utils';

import { useTableContext } from '../hooks';

export const TableScroller = forwardRef<HTMLDivElement, ScrollerProps>(
  ({ style, children, ...rest }, ref) => {
    const ctx = useTableContext();
    const {
      rootRef: tableRef,
      api: {
        current: {
          columnsInfo: { floatingColumn },
        },
      },
      isFloatingColumnSticky,
      setIsFloatingColumnSticky,
    } = ctx;

    const [groupHeaders, headers] = useMemo(() => {
      if (tableRef) {
        return [
          tableRef.querySelector('[data-group-headers]'),
          tableRef.querySelector('[data-headers]'),
        ];
      }
      return [];
    }, [tableRef]);

    const [initialize, instance] = useOverlayScrollbars({
      events: {
        scroll: (_, { target }) => {
          const { scrollLeft, scrollWidth, clientWidth } =
            target as HTMLDivElement;

          if (floatingColumn) {
            const activateFloatingColumn =
              scrollLeft -
                scrollWidth +
                clientWidth +
                floatingColumn.computedWidth <
              0;
            if (activateFloatingColumn !== isFloatingColumnSticky) {
              setIsFloatingColumnSticky(activateFloatingColumn);
            }
          }
          // Preventing anything more abstract, such as generic selector and looping through HTMLCollection to be as performant, as possible
          if (groupHeaders) {
            groupHeaders.scrollLeft = scrollLeft;
          }
          if (headers) {
            headers.scrollLeft = scrollLeft;
          }
          tableRef?.style.setProperty('--scrollLeft', `${scrollLeft}px`);
        },
      },
      options: {
        showNativeOverlaidScrollbars: false,
        scrollbars: {
          autoHide: 'move',
          theme: 'os-theme-dark',
        },
      },
    });

    const rootRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      initialize({
        target: rootRef.current!,
        elements: {
          viewport: contentRef.current,
          content: contentRef.current,
        },
      });
    }, [initialize, contentRef, rootRef]);

    const resizedColumns = ctx.api.current.state.columnSettings.resizedColumns;

    useEffect(() => {
      // Recalculate scrollbars when columns are resized
      instance()?.update(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resizedColumns]);

    return (
      <div
        ref={(el) => {
          if (el) {
            rootRef.current = el;
          }
        }}
        className="DS-Table-Scroller"
        style={{
          height: style?.height,
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          ref={(el) => {
            if (el) {
              contentRef.current = el;
              setRef(ref, el);
            }
          }}
          {...rest}
        >
          {children}
        </div>
      </div>
    );
  }
);
TableScroller.displayName = 'TableScroller';
