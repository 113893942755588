import { ChangeEvent, useRef, useState } from 'react';

import {
  Box,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useModal } from 'mui-modal-provider';

import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Icons,
  Input,
} from '@cast/design-system';
import { Cluster } from '@cast/types';

import { EXTERNAL_LINKS } from 'common/links';
import { CloseDialogButton } from 'components/dialogs';
import { K8sProviderIcon } from 'components/icons';
import { ShowModalOutput } from 'types/modal';
import { mapClusterToCloud } from 'utils/csp';

import { useDisconnectClusterMutation } from '../../../mutations';

type Props = DialogProps & {
  onClose: () => void;
  cluster: Cluster;
};

export const DisconnectPhase2ClusterDialog = ({
  cluster,
  onClose,
  ...props
}: Props) => {
  const [name, setName] = useState('');
  const [deleteProvisionedNodes, setDeleteProvisionedNodes] = useState(false);
  const { mutate, isPending } = useDisconnectClusterMutation(cluster.id);
  const cloud = mapClusterToCloud(cluster);

  const handleChange = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(evt.target.value);
  };

  const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setDeleteProvisionedNodes(event.target.checked);
  };

  const handleDelete = () => {
    mutate({ deleteProvisionedNodes });
    setDeleteProvisionedNodes(false);
    onClose();
  };

  const handleClose = () => {
    setName('');
    setDeleteProvisionedNodes(false);
    onClose();
  };

  return (
    <Dialog {...props} onClose={handleClose} testId="phase2-disconnect-dialog">
      <DialogTitle sx={{ p: '16px 32px' }}>
        <Stack gap={24}>
          <Stack direction="row">
            <Stack direction="row" gap={4}>
              <K8sProviderIcon provider={cluster.providerType} size={24} />
              <Typography variant="A1">{cluster.name}</Typography>
            </Stack>

            <CloseDialogButton onClose={onClose} />
          </Stack>

          <Stack gap={8}>
            <Typography variant="h4">Disconnect your cluster</Typography>
            <Typography variant="P14R" color="grey.500">
              This action will remove all CAST AI resources managing your
              cluster. CAST AI user can’t be deleted automatically, please go to{' '}
              {cloud?.toUpperCase()} IAM and delete it manually.{' '}
              <Button
                variant="text"
                component="a"
                href={EXTERNAL_LINKS.docs_remove_resource}
                target="_blank"
                rel="noreferrer noopener"
                sx={{ typography: 'P14M' }}
              >
                Full list of resources
              </Button>
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>

      <Divider sx={{ borderColor: 'grey.100' }} />

      <DialogContent sx={{ p: '16px 32px 24px' }}>
        <Stack gap={8}>
          <Stack gap={6}>
            <Typography variant="A1">Confirmation</Typography>
            <Typography variant="P12R" color="grey.500">
              Please confirm that you want to disconnect from CAST AI by
              entering the cluster name below.
            </Typography>
          </Stack>

          <Input
            testId="cluster-name-input"
            disabled
            fullWidth
            value={cluster.name}
            inputProps={{ 'aria-label': 'cluster name display' }}
          />

          <Input
            testId="repeat-cluster-name-input"
            autoFocus
            onChange={handleChange}
            placeholder="Enter the cluster name"
            inputProps={{ 'aria-label': 'cluster name input' }}
            id="name"
            type="text"
            fullWidth
            hiddenLabel
          />
        </Stack>
      </DialogContent>

      <Box bgcolor="blue.50">
        <DialogContent sx={{ p: '16px 32px 20px' }}>
          <FormControlLabel
            sx={{ display: 'flex', alignItems: 'baseline', gap: 8, m: 0 }}
            control={
              <Checkbox
                testId="delete-castai-nodes-checkbox"
                size="small"
                color="primary"
                checked={deleteProvisionedNodes}
                onChange={handleChecked}
              />
            }
            label={
              <Stack gap={4}>
                <Stack direction="row" gap={8}>
                  <Typography variant="A1">
                    Delete all CAST AI created nodes
                  </Typography>

                  <Chip
                    size="small"
                    startIcon={<Icons.Warning />}
                    sx={{ backgroundColor: 'yellow.700', color: 'white' }}
                  >
                    Might cause downtime
                  </Chip>
                </Stack>

                <Typography variant="P12R" color="grey.500">
                  All CAST AI created nodes will be deleted. Depending on your
                  application configuration this action might cause downtime.
                </Typography>
              </Stack>
            }
          />
        </DialogContent>
      </Box>

      <DialogActions sx={{ p: '16px 32px' }}>
        <Stack direction="row" justifyContent="flex-end" gap={16}>
          <Button
            variant="tertiary"
            onClick={handleClose}
            testId="close-dialog-action"
          >
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={handleDelete}
            disabled={name !== cluster.name}
            loading={isPending}
            testId="disconnect-cluster-action"
          >
            Disconnect
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const useDisconnectPhase2ClusterDialog = () => {
  const { showModal } = useModal();
  const modalInstance = useRef<ShowModalOutput | undefined>();

  return {
    openDisconnectPhase2ClusterDialog: (cluster: Cluster) => {
      modalInstance.current = showModal(
        DisconnectPhase2ClusterDialog,
        {
          cluster,
          onClose: () => modalInstance.current?.destroy(),
        },
        { rootId: 'root' }
      );
    },
  };
};
