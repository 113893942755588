import clsx from 'clsx';

export type CustomYAxisTickProps = {
  x?: number;
  y?: number;
  dx?: number;
  dy?: number;
  fill?: string;
  payload?: any;
  index?: number;
  tickFormatter?: (value: string, index: number) => string;
  fontFamily?: string;
  fontSize?: number;
  width?: number;
  height?: number;
  className?: string;
};

export const CustomYAxisTick = (props: CustomYAxisTickProps) => {
  const {
    x = 0,
    y = 0,
    dx = 10,
    dy = -24,
    fill,
    payload,
    index = 0,
    tickFormatter,
    fontFamily = 'Poppins',
    fontSize = 10,
    width,
    height,
    className,
  } = props;

  const label = tickFormatter?.(payload.value, index);

  if (!label) {
    return null;
  }

  return (
    <>
      <style>
        {`
          .y-axis-tick-value {
            color: black;
            background: rgba(255,255,255,0.5);
            border-radius: 4px;
            padding: 0 2px;
            font-family: ${fontFamily};
            font-size: ${fontSize}px;
            color: ${fill};
          }
        `}
      </style>
      <foreignObject
        x={x + dx}
        y={y + dy}
        width={width}
        height={height}
        xmlns="http://www.w3.org/1999/xhtml"
      >
        <span className={clsx('y-axis-tick-value', className)}>{label}</span>
      </foreignObject>
    </>
  );
};
