import { K8sProvider } from '@cast/types';

import {
  isClusterLimitsPolicy,
  isNodeDownscalerPolicy,
  isUnschedulablePodsPolicy,
  Policies,
  PoliciesList,
  PolicyKey,
} from 'types/policies';

// Supported providers for phase 2
export const MANAGED_PROVIDERS = [
  K8sProvider.EKS,
  K8sProvider.KOPS,
  K8sProvider.GKE,
  K8sProvider.AKS,
];

export const getPoliciesList = (
  policies: Policies[keyof Policies][]
): PoliciesList => ({
  [PolicyKey.UnschedulablePods]: {
    label: 'Unscheduled pods policy',
    enabled: !!policies.find(isUnschedulablePodsPolicy)?.enabled,
  },
  [PolicyKey.NodeDownscaler]: {
    label: 'Node deletion policy',
    enabled: !!policies.find(isNodeDownscalerPolicy)?.enabled,
  },
  [PolicyKey.ClusterLimits]: {
    label: 'CPU limit policy',
    enabled: !!policies.find(isClusterLimitsPolicy)?.enabled,
  },
});
