import { Stack } from '@mui/material';

import { Button, DrawerOverlay } from '@cast/design-system';

import { HexIconError } from 'components/hex-icons';
import { StatusMessage } from 'components/messages';

type Props = {
  onCancel: () => void;
  onDelete: () => void;
};

export const DeleteOverlay = ({ onCancel, onDelete }: Props) => {
  return (
    <DrawerOverlay>
      <StatusMessage
        icon={<HexIconError />}
        title="Are you sure you want to delete webhook?"
        body={<>This action cannot be undone.</>}
        actions={
          <Stack direction="row" gap="16px">
            <Button
              variant="tertiary"
              size="medium"
              onClick={(e: UIEvent) => {
                e.stopPropagation();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              size="medium"
              onClick={(e: UIEvent) => {
                e.stopPropagation();
                onDelete();
              }}
              testId="delete-webhook-button"
            >
              Delete
            </Button>
          </Stack>
        }
      />
    </DrawerOverlay>
  );
};
