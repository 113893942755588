import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';

import { PriceType } from '@cast/types';

import { readStorage, writeStorage } from 'utils/storage';

import { useCostOverTime } from '../hooks/useCostOverTime';

type ReducerState = {
  costRate: PriceType;
};

type ReducerAction = { type: 'changeCostRate'; costRate: PriceType };

type Props = PropsWithChildren<unknown>;

const makeInitialState = (storageKey: string): ReducerState => ({
  costRate: readStorage(`${storageKey}.cost-rate`, PriceType.MONTHLY),
});

type CostOverTimeSectionContextValue = [ReducerState, Dispatch<ReducerAction>];

export const CostOverTimeSectionContext =
  createContext<CostOverTimeSectionContextValue>([] as never);

const makeReducerFn =
  (storageKey: string) => (state: ReducerState, action: ReducerAction) => {
    switch (action.type) {
      case 'changeCostRate': {
        writeStorage(`${storageKey}.cost-rate`, action.costRate);
        return {
          ...state,
          costRate: action.costRate,
        };
      }
    }
  };

export const CostOverTimeSectionProvider = ({ children }: Props) => {
  const { key } = useCostOverTime();
  const STORAGE_KEY = `cost-report.${key}`;
  const [state, dispatch] = useReducer(
    makeReducerFn(STORAGE_KEY),
    makeInitialState(STORAGE_KEY)
  );

  return (
    <CostOverTimeSectionContext.Provider value={[state, dispatch]}>
      {children}
    </CostOverTimeSectionContext.Provider>
  );
};
