import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import capitalize from 'lodash/capitalize';
import partition from 'lodash/partition';
import { bindToggle } from 'material-ui-popup-state/core';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { Virtuoso } from 'react-virtuoso';

import {
  IconButton,
  Icons,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSearchInput,
  ListSearchInputProps,
  ListSubheader,
  VirtuosoScroller,
  withProps,
} from '@cast/design-system';
import { Organization } from '@cast/types';

import { OrganizationAvatar } from 'components/avatars';
import { orgManagementEvents } from 'core/analytics';

import { HeaderPopover } from '../_components';

const LIST_HEIGHT = 297;

const OrganizationListItemText = withProps(ListItemText, {
  primaryTypographyProps: { variant: 'P12M' },
  secondaryTypographyProps: { variant: 'P10R' },
});

type Actions = {
  onChangeOrganization?: (organization: Organization) => void;
  onCreateNewOrganization?: () => void;
};

export type OrganizationMenuProps = {
  activeOrganizationId?: Organization['id'];
  organizations?: Organization[];
} & Actions;

export const OrganizationMenu = ({
  activeOrganizationId,
  organizations,
  onChangeOrganization,
  onCreateNewOrganization,
}: OrganizationMenuProps) => {
  const [organizationFilter, setOrganizationFilter] = useState<string>('');
  const [height, setHeight] = useState<number>(LIST_HEIGHT);
  const handleListHeight = useCallback((h: number) => {
    setHeight(h);
  }, []);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'profile-menu',
  });

  const [[activeOrganization], otherOrganizations] = partition(
    organizations,
    (o) => o.id === activeOrganizationId
  );

  const handleOnChange = useCallback<
    Required<ListSearchInputProps>['onChange']
  >((e, v) => {
    e.stopPropagation();
    setOrganizationFilter(v);
  }, []);

  const handleOnClear = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setOrganizationFilter('');
  }, []);

  const handleChangeOrganization = useCallback(
    (newOrganization: Organization) => {
      orgManagementEvents.switchedOrganizations(
        activeOrganization.name,
        newOrganization.name
      );
      setOrganizationFilter('');
      onChangeOrganization?.(newOrganization);
    },
    [activeOrganization.name, onChangeOrganization]
  );

  const filteredOrganizations = useMemo(
    () =>
      otherOrganizations.filter(
        (o) =>
          o.name
            .toLocaleLowerCase()
            .includes(organizationFilter.toLocaleLowerCase()) ||
          o.id === organizationFilter
      ),
    [organizationFilter, otherOrganizations]
  );

  if (!activeOrganization) {
    return null;
  }

  return (
    <>
      <Box
        {...bindToggle(popupState)}
        display="inline-block"
        data-testid="org-menu-list-btn"
      >
        <OrganizationAvatar
          size="s"
          name={activeOrganization.name}
          active={popupState.isOpen}
        />
      </Box>

      <HeaderPopover {...bindPopover(popupState)}>
        <List
          size="medium"
          disablePadding
          sx={{ minWidth: '252px' }}
          testId="org-menu-list"
        >
          <ListItem
            testId="active-organization"
            sx={(theme) => ({
              '&, &:hover': {
                borderBottom: `1px solid ${theme.palette.grey[100]}`,
                background: theme.palette.grey[50],
                backgroundImage: `linear-gradient(0deg, rgba(8, 41, 57, 0.05) 0%, rgba(8, 41, 57, 0) 25%)`,
              },
            })}
            alignItems="flex-start"
            multiline
            disableHover
          >
            <Stack direction="row" gap={8} pb={8}>
              <ListItemAvatar>
                <OrganizationAvatar size="m" name={activeOrganization.name} />
              </ListItemAvatar>
              <OrganizationListItemText
                primary={activeOrganization.name}
                secondary={capitalize(activeOrganization.role)}
              />
            </Stack>
          </ListItem>

          {!!otherOrganizations.length && (
            <List size="medium" disablePadding testId="existing-organizations">
              <ListSearchInput
                placeholder="Search"
                listItemProps={{ component: 'li' }}
                clearButtonProps={{ onClick: handleOnClear }}
                value={organizationFilter}
                onChange={handleOnChange}
                autoFocus
                showClearButton
                testId="orgs-search"
              />

              <ListSubheader>Organizations</ListSubheader>

              {filteredOrganizations.length ? (
                <Virtuoso
                  totalListHeightChanged={handleListHeight}
                  data={filteredOrganizations}
                  style={{
                    height,
                    maxHeight: LIST_HEIGHT,
                  }}
                  itemContent={(index) => {
                    const organization = filteredOrganizations[index];
                    return (
                      <ListItem
                        testId="existing-organization"
                        key={organization.id}
                        startAdornment={
                          <ListItemAvatar>
                            <OrganizationAvatar
                              size="m"
                              name={organization.name}
                            />
                          </ListItemAvatar>
                        }
                        multiline
                        onClick={() => {
                          popupState.close();
                          handleChangeOrganization(organization);
                        }}
                      >
                        <OrganizationListItemText
                          primary={organization.name}
                          secondary={capitalize(organization.role)}
                        />
                      </ListItem>
                    );
                  }}
                  components={{
                    Scroller: VirtuosoScroller,
                  }}
                  overscan={LIST_HEIGHT}
                />
              ) : (
                <ListItem multiline disableHover>
                  <OrganizationListItemText
                    primary="No organizations found"
                    secondary="Try adjusting filter"
                  />
                </ListItem>
              )}
            </List>
          )}
          <ListItem
            startAdornment={
              <ListItemAvatar>
                <IconButton variant="tertiary" size="medium">
                  <Icons.Plus />
                </IconButton>
              </ListItemAvatar>
            }
            multiline
            sx={{
              borderTop: 'grey.100',
            }}
            onClick={() => {
              popupState.close();
              onCreateNewOrganization?.();
            }}
            testId="new-org-btn"
          >
            <OrganizationListItemText primary="New Organization" />
          </ListItem>
        </List>
      </HeaderPopover>
    </>
  );
};
