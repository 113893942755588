import { ColumnModel, TableApi } from '../../types';

type Props = { model: ColumnModel; api: TableApi };

export const FooterCell = ({ model: { renderFooter, id }, api }: Props) => {
  return (
    <div
      className={`DS-Table-CellWrapper DS-Table-FooterCellWrapper DS-Table-CellWrapper-${id}`}
    >
      <div className="DS-Table-Cell DS-Table-FooterCell">
        {renderFooter?.(api)}
      </div>
    </div>
  );
};
