import { ThemeOptions } from '@mui/material';

import { getThemeColor } from '../../../utils/theme';

export const previewYamlTheme: ThemeOptions['components'] = {
  DsPreviewYaml: {
    styleOverrides: {
      root: {
        position: 'relative',
        color: 'white',
      },
      scroller: ({ ownerState }) => ({
        height: ownerState.height || '100%',
        width: '100%',
      }),
      syntaxHighlight: ({
        theme,
        ownerState: { horizontalPadding = 44, marker, showLineNumbers },
      }) => ({
        paddingLeft: horizontalPadding + (showLineNumbers ? 30 : 0),
        paddingRight: horizontalPadding,
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: 'inherit',
        position: 'relative',
        '& *': {
          color: 'white',
        },
        '& .DsPreviewYaml-PrimitiveValueContainer': {
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        },
        '& .DsPreviewYaml-Key, & .DsPreviewYaml-Colon, & .DsPreviewYaml-Null, & .DsPreviewYaml-Boolean, & .DsPreviewYaml-Number':
          {
            color: theme.palette.green[300],
          },
        '& .DsPreviewYaml-Key': {
          whiteSpace: 'nowrap',
        },
        '& .DsPreviewYaml-Undefined': {
          color: theme.palette.grey[300],
        },
        '& .DsPreviewYaml-Link': {
          color: theme.palette.blue[300],
          cursor: 'pointer',
          '&:hover': { color: theme.palette.blue[300] },
        },
        '& .DsCode-ExpandToggler': {
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '1em',
          width: '1em',
          position: 'absolute',
          userSelect: 'none',
          left: -18,
          top: 0,
          transform: 'translateY(5px)',
        },
        '& .DsPreviewYaml-ChildCount': {
          fontSize: '0.8em',
        },
        '& .DsPreviewYaml-Line': {
          '&-root': {
            position: 'relative',
            whiteSpace: 'pre',
          },
          '&-Marker': {
            content: '""',
            borderRadius: '10px',
            display: 'block',
            position: 'absolute',
            top: -2,
            bottom: -2,
            left: -25,
            width: 3,
            backgroundColor: getThemeColor(theme, marker?.color),
          },
          '&-Index': {
            position: 'absolute',
            left: -60,
            '&-LineNumber': {
              position: 'absolute',
            },
            '&-MaxNumber': {
              visibility: 'hidden',
              height: 0,
            },
          },
          '&-Content': {
            position: 'relative',
            display: 'inline',
          },
        },
      }),
      colorPrimary: ({ theme }) => ({
        backgroundColor: theme.palette.grey[800],
      }),
      colorSecondary: ({ theme }) => ({
        backgroundColor: theme.palette.grey[900],
      }),
      sizeMedium: ({ theme }) => theme.typography.C12,
      sizeLarge: ({ theme }) => theme.typography.C14,
    },
  },
};
