import { useQuery } from '@tanstack/react-query';

import { UserProfile } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';
import { gtmPushEvent } from 'utils/gtmPushEvent';

type UseUserProfileQuery = {
  isLoading: boolean;
  userProfile?: UserProfile;
  error?: unknown;
};

export const useUserProfileQuery = (userId?: string): UseUserProfileQuery => {
  const { isLoading, data, error } = useQuery<UserProfile>({
    queryKey: [QueryKeys.USER_PROFILE],
    queryFn: async () => {
      const { data } = await apiClient.user.currentUserProfile();
      if (data.firstLogin) {
        const notificationKey = `product-signup-${userId}`;
        if (!localStorage.getItem(notificationKey)) {
          gtmPushEvent(
            'product-signup',
            {
              'user-id': userId!,
              email: data.email!,
              name: data.name!,
            },
            data.email
          );
          localStorage.setItem(notificationKey, 'true');
        }
      }
      return data as UserProfile;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!userId,
  });

  return {
    isLoading,
    userProfile: data,
    error,
  };
};
