import { SerializableObject } from '@cast/types';

import {
  ColumnSettings,
  ColumnSettingsProps,
  TableApi,
  TableState,
} from '../types';

export type PersistenceState = {
  accept: (api: TableApi) => void;
  retrieve: (initial: TableApi) => TableState;
};

// in-out to persistence layer
export type PersistenceDriver = {
  retrieve: <T = SerializableObject>(id: string) => T | undefined;
  accept: (id: string, state: SerializableObject) => void;
};

export type SerializableTableState = {
  columnSettings?: Partial<ColumnSettings>;
};

export type TableStateToSerializableState = (
  api: TableState
) => SerializableTableState;

export type SerializableStateToTableState = (
  serializableState: SerializableTableState,
  api: TableApi
) => { state: TableState; requiresReset: boolean };

export type TableStateConverter = {
  toSerializable: TableStateToSerializableState;
  toTable: SerializableStateToTableState;
};

export const serializableSettings = [
  'visible',
  'locked',
  'floating',
  'frozen',
  'lockSettingDisabled',
  'visibilitySettingDisabled',
  'reorderSettingDisabled',
] as const;

type SerializableSettings = Pick<
  ColumnSettingsProps,
  (typeof serializableSettings)[number]
>;

export type SerializableColumn = {
  id: string;
  resizable?: boolean;
} & SerializableSettings;

export type SerializableColumnGroup = {
  id: string;
  columns: SerializableColumn[];
} & SerializableSettings;

export type SerializableGroupedColumn =
  | SerializableColumn
  | SerializableColumnGroup;

type TableStateMigratorArgs = {
  lastSavedLayout: SerializableGroupedColumn[];
  currentLayout: SerializableGroupedColumn[];
  savedState: SerializableTableState;
  currentTableApi: TableApi;
};
export type TableStateMigrator = (args: TableStateMigratorArgs) => TableState;
