import { FocusEventHandler } from 'react';

import { Field, FieldProps, useField } from 'react-final-form';

import { mergeSx } from '@cast/design-system';

import { CodeEditor, CodeEditorProps } from 'components/inputs';

import { useRffFormState } from '../hooks';
import { showErrorOnChange } from '../utils';

export type RffCodeEditorProps = Omit<
  CodeEditorProps,
  'value' | 'onChange' | 'name'
> & {
  name: string;
  value?: string;
  onBlur?: (event: FocusEventHandler<HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEventHandler<HTMLTextAreaElement>) => void;
  onChange?: (value: string) => void;
  hideError?: boolean;
  fieldProps?: Partial<FieldProps<string, any>>;
};

export const RffCodeEditor = ({
  fieldProps = {},
  highlight,
  name,
  placeholder,
  sx,
  value: clientValue,
  onBlur: clientOnBlur,
  onFocus: clientOnFocus,
  onChange: clientOnChange,
  hideError,
  ...rest
}: RffCodeEditorProps) => {
  const field = useField(name);
  const showError = showErrorOnChange(field.meta) && !hideError;
  const { mode } = useRffFormState();
  const readOnly = mode === 'view';

  return (
    <Field
      {...fieldProps}
      name={name}
      render={(props) => {
        const {
          input: { onBlur, onFocus, onChange, value },
        } = props;
        return (
          <CodeEditor
            onBlur={(event) => {
              clientOnBlur?.(event);
              if (!event.defaultPrevented) {
                onBlur(event);
              }
            }}
            onFocus={(event) => {
              clientOnFocus?.(event);
              if (!event.defaultPrevented) {
                onFocus(event);
              }
            }}
            onChange={(value) => {
              if (clientOnChange) {
                clientOnChange(value);
              } else {
                onChange(value);
              }
            }}
            value={clientValue ?? value ?? ''}
            readOnly={readOnly}
            highlight={highlight}
            padding={10}
            placeholder={placeholder}
            error={showError ? field.meta.error[0] : undefined}
            sx={mergeSx(
              {
                '& .CodeEditor-editor': {
                  minHeight: '140px',
                },
              },
              sx
            )}
            {...rest}
          />
        );
      }}
    />
  );
};
