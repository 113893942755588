import { ReactElement, ReactNode } from 'react';

import Link from '@mui/material/Link';

import { Indentation } from './Indentation';
import { Primitive, PrimitiveVal } from '../../_types';
import { getPrimitiveType } from '../../utils';

type Props = {
  data: PrimitiveVal;
  indent?: number;
};

const render: Record<Primitive, (content?: ReactNode) => ReactElement> = {
  [Primitive.STRING]: (content: ReactNode) => (
    <span className="DsPreviewJson-String">&quot;{content}&quot;</span>
  ),
  [Primitive.NUMBER]: (content: ReactNode) => (
    <span className="DsPreviewJson-Number">{content}</span>
  ),
  [Primitive.BOOLEAN]: (content: ReactNode) => (
    <span className="DsPreviewJson-Boolean">{String(content)}</span>
  ),
  [Primitive.NUMERIC_STRING]: (content: ReactNode) => (
    <span className="DsPreviewJson-NumericString">&quot;{content}&quot;</span>
  ),
  [Primitive.LINK]: (content: ReactNode) => (
    <Link
      className="DsPreviewJson-Link"
      target="_blank"
      rel="noreferrer"
      href={content as string}
    >
      &quot;{content}&quot;
    </Link>
  ),
  [Primitive.DATE]: (content: ReactNode) => (
    <span className="DsPreviewJson-Date">&quot;{content}&quot;</span>
  ),
  [Primitive.NULL]: () => <span className="DsPreviewJson-Null">null</span>,
  [Primitive.UNDEFINED]: () => (
    <span className="DsPreviewJson-Undefined">&quot;undefined&quot;</span>
  ),
};

export const PrimitiveValue = ({ data, indent }: Props) => {
  const type = getPrimitiveType(data);
  if (!type) {
    throw new Error('Trying to render non primitive value as primitive');
  }

  return (
    <span className="DsPreviewJson-PrimitiveValueContainer">
      <Indentation indent={indent}>{render[type](data)}</Indentation>
    </span>
  );
};
