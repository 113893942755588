import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

import { Icons } from '@cast/design-system';
import { Cluster, ClusterDisplayStatus } from '@cast/types';

import { ClusterStatusTooltip } from 'components/tooltip';

import { generateCSSFilterForBorder } from './_utils/generateCSSFilterForBorder';
import { K8sProviderIcon } from './K8sProviderIcon';

const EyeIcon = Icons.Eye;
const CircleIcon = Icons.Circle;

type Props = {
  cluster?: Cluster;
  size?: number;
  className?: string;
};

export const K8sProviderIconStatus = ({
  cluster,
  size = 16,
  className,
}: Props) => {
  if (!cluster) {
    return null;
  }

  const provider = cluster.providerType;
  const status = cluster.displayStatus;
  const isManaged = Boolean(cluster.credentialsId);

  const tooltipMessage = `${provider.toUpperCase()} cluster ${
    isManaged ? 'managed by CAST AI' : ''
  }`;

  return (
    <ClusterStatusTooltip cluster={cluster} title={tooltipMessage}>
      <Box
        className={className}
        sx={{
          position: 'relative',
          height: '1em',
          width: '1em',
          minHeight: '1em',
          minWidth: '1em',
          cursor: 'pointer',
          fontSize: size,
        }}
      >
        <K8sProviderIcon size="100%" provider={provider} />
        {isManaged ? null : <ManagedByCastAI />}
        <Status status={status} />
      </Box>
    </ClusterStatusTooltip>
  );
};

const ManagedByCastAI = () => {
  const { palette } = useTheme();

  return (
    <EyeIcon
      color={palette.grey[400]}
      weight="fill"
      css={{
        width: '0.5em',
        height: '0.5em',
        position: 'absolute',
        right: 0,
        bottom: 0,
        filter: generateCSSFilterForBorder({ borderSize: 0.5 }),
      }}
      data-testid="managed-by-cast-ai-icon"
    />
  );
};

const Status = ({ status }: { status: ClusterDisplayStatus }) => {
  const { palette } = useTheme();

  const grey = palette.grey[400];
  const green = palette.green[400] as string;
  const yellow = palette.yellow[500] as string;
  const red = palette.red[400] as string;

  const statusToColorMapper: Record<`${ClusterDisplayStatus}`, string> = {
    'waiting-connection': grey,
    disconnecting: grey,
    connecting: grey,
    deleted: grey,
    deleting: grey,
    online: green,
    'read-only': green,
    ready: green,
    'non-responding': yellow,
    paused: yellow,
    warning: yellow,
    disconnected: red,
    failed: red,
    pausing: grey,
    resuming: grey,
  };

  const color = statusToColorMapper[status] ?? grey;

  return (
    <CircleIcon
      color={color}
      weight="fill"
      css={{
        width: '0.5em',
        height: '0.5em',
        position: 'absolute',
        right: 0,
        top: 0,
        filter: generateCSSFilterForBorder({ borderSize: 0.5 }),
      }}
      data-testid="cluster-status-icon"
    />
  );
};
