import { PropsWithChildren, useEffect, useState } from 'react';

import { useElementHeight } from '@cast/utils';

import { useSwipeableViews } from '../hooks/useSwipeableViews';

export const SwipeableView = ({ children }: PropsWithChildren<unknown>) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const height = useElementHeight(ref);
  const ctx = useSwipeableViews();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => ctx?.updateHeight?.(), [height]);
  return (
    <div className="SwipeableView-root" ref={setRef}>
      {children}
    </div>
  );
};
