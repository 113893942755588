import { ThemeOptions } from '@mui/material/styles';

import { cssVars } from '../../../utils/cssVars';

export const progressBarWithLabelVars = cssVars(
  {
    gap: undefined,
  },
  'ds-progress-bar-with-label'
);

export const progressBarTheme: ThemeOptions['components'] = {
  MuiLinearProgress: {
    styleOverrides: {
      barColorPrimary: ({ theme }) => ({
        backgroundColor: theme.palette.blue[300],
      }),
      barColorSecondary: ({ theme }) => ({
        backgroundColor: theme.palette.green[400],
      }),

      bar: ({ theme }) => ({
        borderRadius: '24px',
        '.DSuiProgressBar-disabled &': {
          backgroundColor: theme.palette.grey[300],
        },
      }),
      root: ({ theme }) => ({
        borderRadius: '24px',
        height: 'var(--ProgressBarHeight)',
        backgroundColor: theme.palette.grey[200],

        '& .MuiLinearProgress-barColorTertiary': {
          backgroundColor: theme.palette.grey[600],
        },

        '&.DSuiProgressBar-size-xlarge': {
          '--ProgressBarHeight': '16px',
        },
        '&.DSuiProgressBar-size-large': {
          '--ProgressBarHeight': '8px',
        },
        '&.DSuiProgressBar-size-medium': {
          '--ProgressBarHeight': '4px',
        },
        '&.DSuiProgressBar-size-small': {
          '--ProgressBarHeight': '2px',
        },
      }),
    },
  },
  DsProgressBarWithLabel: {
    styleOverrides: {
      root: {
        display: 'grid',
        alignItems: 'center',
      },
      startAdornment: {
        gridTemplateColumns: 'max-content auto',
      },
      endAdornment: {
        gridTemplateColumns: 'auto max-content',
      },
      bothAdornments: {
        gridTemplateColumns: 'max-content auto max-content',
      },
      xlarge: ({ theme }) => ({
        ...progressBarWithLabelVars.setValues({ gap: '16px' }),
        ...theme.typography.P16M,
      }),
      large: ({ theme }) => ({
        ...progressBarWithLabelVars.setValues({ gap: '12px' }),
        ...theme.typography.P14M,
      }),
      medium: ({ theme }) => ({
        ...progressBarWithLabelVars.setValues({ gap: '8px' }),
        ...theme.typography.P12M,
      }),
      small: ({ theme }) => ({
        ...progressBarWithLabelVars.setValues({ gap: '8px' }),
        ...theme.typography.P10M,
      }),
      startAdornmentWrapper: {
        paddingRight: progressBarWithLabelVars.getters.gap(),
      },
      endAdornmentWrapper: {
        paddingLeft: progressBarWithLabelVars.getters.gap(),
      },
    },
  },
};
