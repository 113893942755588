import { VulnerabilitySeverity } from '@cast/types';

import { CheckResourceState, VulnerabilitiesResourceState } from './types';

export const availableSeverityLevels: VulnerabilitySeverity[] = [
  VulnerabilitySeverity.critical,
  VulnerabilitySeverity.high,
  VulnerabilitySeverity.medium,
  VulnerabilitySeverity.low,
  VulnerabilitySeverity.none,
  VulnerabilitySeverity.notAvailable,
];

export const severitySortWeight: Record<VulnerabilitySeverity, number> = {
  [VulnerabilitySeverity.critical]: 5,
  [VulnerabilitySeverity.high]: 4,
  [VulnerabilitySeverity.medium]: 3,
  [VulnerabilitySeverity.low]: 2,
  [VulnerabilitySeverity.none]: 1,
  [VulnerabilitySeverity.notAvailable]: 0,
  [VulnerabilitySeverity.any]: 0,
};

export const severityToColorMap: Partial<
  Record<
    VulnerabilitySeverity,
    { color: string; bgcolor: string; markerColor: string }
  >
> = {
  [VulnerabilitySeverity.critical]: {
    color: 'red.900',
    bgcolor: 'red.200',
    markerColor: 'red.800',
  },
  [VulnerabilitySeverity.high]: {
    color: 'red.500',
    bgcolor: 'red.50',
    markerColor: 'red.300',
  },
  [VulnerabilitySeverity.medium]: {
    color: 'red.400',
    bgcolor: 'yellow.200',
    markerColor: 'yellow.700',
  },
  [VulnerabilitySeverity.low]: {
    color: 'yellow.900',
    bgcolor: 'yellow.50',
    markerColor: 'yellow.200',
  },
  [VulnerabilitySeverity.none]: {
    color: 'indigo.500',
    bgcolor: 'indigo.50',
    markerColor: 'indigo.100',
  },
  [VulnerabilitySeverity.notAvailable]: {
    color: 'grey.600',
    bgcolor: 'grey.200',
    markerColor: 'grey.200',
  },
};

export const severityToTextMap: Partial<Record<VulnerabilitySeverity, string>> =
  {
    [VulnerabilitySeverity.notAvailable]: 'N/A',
  };

export const severityToTooltipMap: Partial<
  Record<VulnerabilitySeverity, string>
> = {
  [VulnerabilitySeverity.none]:
    'Issue that does not impact security or operations but contains recommendation level information.',
  [VulnerabilitySeverity.notAvailable]: 'CVSS score not provided yet.',
};

export const availableCheckResourceStates: CheckResourceState[] = [
  CheckResourceState.NOT_AVAILABLE,
  CheckResourceState.UNAFFECTED,
  CheckResourceState.AFFECTED,
];

export const availableVulnerabilitiesResourceStates: VulnerabilitiesResourceState[] =
  [
    VulnerabilitiesResourceState.UNAFFECTED,
    VulnerabilitiesResourceState.AFFECTED,
  ];

export const checkResourceStateToText: Record<CheckResourceState, string> = {
  [CheckResourceState.NOT_AVAILABLE]: 'N/A',
  [CheckResourceState.AFFECTED]: 'Not compliant',
  [CheckResourceState.UNAFFECTED]: 'Compliant',
};
