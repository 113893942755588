/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { PodmutationsV1GetInstallCmdForMutatorResponse } from '../models';
// @ts-ignore
import { PodmutationsV1GetMutatorStatusResponse } from '../models';
// @ts-ignore
import { PodmutationsV1GetPodMutationResponse } from '../models';
// @ts-ignore
import { PodmutationsV1ListPodMutationsResponse } from '../models';
// @ts-ignore
import { PodmutationsV1PodMutation } from '../models';
/**
 * PodMutationsAPIApi - axios parameter creator
 * @export
 */
export const PodMutationsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a pod mutation.
         * @summary Create pod mutation
         * @param {PodmutationsV1PodMutation} podMutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPICreatePodMutation: async (podMutation: PodmutationsV1PodMutation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'podMutation' is not null or undefined
            assertParamExists('podMutationsAPICreatePodMutation', 'podMutation', podMutation)
            const localVarPath = `/v1/kubernetes/pod-mutations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(podMutation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes pod mutation configuration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIDeletePodMutation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('podMutationsAPIDeletePodMutation', 'id', id)
            const localVarPath = `/v1/kubernetes/pod-mutations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the install command for the pod mutator.
         * @summary Get install command for mutator
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIGetInstallCmdForMutator: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('podMutationsAPIGetInstallCmdForMutator', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/pod-mutations/clusters/{clusterId}/components/pod-mutator/install-script`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the install script for the pod mutator.
         * @summary Get install script
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIGetInstallScriptForMutator: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('podMutationsAPIGetInstallScriptForMutator', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/pod-mutations/clusters/{clusterId}/components/pod-mutator/install.sh`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if the mutator is running on the cluster.
         * @summary Get mutator status
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIGetMutatorStatus: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('podMutationsAPIGetMutatorStatus', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/pod-mutations/clusters/{clusterId}/components/pod-mutator`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get pod mutation by id.
         * @summary Get pod mutations
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIGetPodMutation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('podMutationsAPIGetPodMutation', 'id', id)
            const localVarPath = `/v1/kubernetes/pod-mutations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists active pod mutations.
         * @summary List pod mutations
         * @param {string} [clusterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIListPodMutations: async (clusterId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/kubernetes/pod-mutations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a pod mutation.
         * @summary Update pod mutation
         * @param {string} id 
         * @param {PodmutationsV1PodMutation} podMutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIUpdatePodMutation: async (id: string, podMutation: PodmutationsV1PodMutation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('podMutationsAPIUpdatePodMutation', 'id', id)
            // verify required parameter 'podMutation' is not null or undefined
            assertParamExists('podMutationsAPIUpdatePodMutation', 'podMutation', podMutation)
            const localVarPath = `/v1/kubernetes/pod-mutations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(podMutation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PodMutationsAPIApi - functional programming interface
 * @export
 */
export const PodMutationsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PodMutationsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a pod mutation.
         * @summary Create pod mutation
         * @param {PodmutationsV1PodMutation} podMutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podMutationsAPICreatePodMutation(podMutation: PodmutationsV1PodMutation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PodmutationsV1PodMutation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podMutationsAPICreatePodMutation(podMutation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodMutationsAPIApi.podMutationsAPICreatePodMutation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes pod mutation configuration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podMutationsAPIDeletePodMutation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podMutationsAPIDeletePodMutation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodMutationsAPIApi.podMutationsAPIDeletePodMutation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the install command for the pod mutator.
         * @summary Get install command for mutator
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podMutationsAPIGetInstallCmdForMutator(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PodmutationsV1GetInstallCmdForMutatorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podMutationsAPIGetInstallCmdForMutator(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodMutationsAPIApi.podMutationsAPIGetInstallCmdForMutator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the install script for the pod mutator.
         * @summary Get install script
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podMutationsAPIGetInstallScriptForMutator(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podMutationsAPIGetInstallScriptForMutator(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodMutationsAPIApi.podMutationsAPIGetInstallScriptForMutator']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Check if the mutator is running on the cluster.
         * @summary Get mutator status
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podMutationsAPIGetMutatorStatus(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PodmutationsV1GetMutatorStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podMutationsAPIGetMutatorStatus(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodMutationsAPIApi.podMutationsAPIGetMutatorStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get pod mutation by id.
         * @summary Get pod mutations
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podMutationsAPIGetPodMutation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PodmutationsV1GetPodMutationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podMutationsAPIGetPodMutation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodMutationsAPIApi.podMutationsAPIGetPodMutation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists active pod mutations.
         * @summary List pod mutations
         * @param {string} [clusterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podMutationsAPIListPodMutations(clusterId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PodmutationsV1ListPodMutationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podMutationsAPIListPodMutations(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodMutationsAPIApi.podMutationsAPIListPodMutations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a pod mutation.
         * @summary Update pod mutation
         * @param {string} id 
         * @param {PodmutationsV1PodMutation} podMutation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async podMutationsAPIUpdatePodMutation(id: string, podMutation: PodmutationsV1PodMutation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PodmutationsV1PodMutation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.podMutationsAPIUpdatePodMutation(id, podMutation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PodMutationsAPIApi.podMutationsAPIUpdatePodMutation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PodMutationsAPIApi - factory interface
 * @export
 */
export const PodMutationsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PodMutationsAPIApiFp(configuration)
    return {
        /**
         * Creates a pod mutation.
         * @summary Create pod mutation
         * @param {PodMutationsAPIApiPodMutationsAPICreatePodMutationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPICreatePodMutation(requestParameters: PodMutationsAPIApiPodMutationsAPICreatePodMutationRequest, options?: RawAxiosRequestConfig): AxiosPromise<PodmutationsV1PodMutation> {
            return localVarFp.podMutationsAPICreatePodMutation(requestParameters.podMutation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes pod mutation configuration
         * @param {PodMutationsAPIApiPodMutationsAPIDeletePodMutationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIDeletePodMutation(requestParameters: PodMutationsAPIApiPodMutationsAPIDeletePodMutationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.podMutationsAPIDeletePodMutation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the install command for the pod mutator.
         * @summary Get install command for mutator
         * @param {PodMutationsAPIApiPodMutationsAPIGetInstallCmdForMutatorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIGetInstallCmdForMutator(requestParameters: PodMutationsAPIApiPodMutationsAPIGetInstallCmdForMutatorRequest, options?: RawAxiosRequestConfig): AxiosPromise<PodmutationsV1GetInstallCmdForMutatorResponse> {
            return localVarFp.podMutationsAPIGetInstallCmdForMutator(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the install script for the pod mutator.
         * @summary Get install script
         * @param {PodMutationsAPIApiPodMutationsAPIGetInstallScriptForMutatorRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIGetInstallScriptForMutator(requestParameters: PodMutationsAPIApiPodMutationsAPIGetInstallScriptForMutatorRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.podMutationsAPIGetInstallScriptForMutator(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if the mutator is running on the cluster.
         * @summary Get mutator status
         * @param {PodMutationsAPIApiPodMutationsAPIGetMutatorStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIGetMutatorStatus(requestParameters: PodMutationsAPIApiPodMutationsAPIGetMutatorStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<PodmutationsV1GetMutatorStatusResponse> {
            return localVarFp.podMutationsAPIGetMutatorStatus(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get pod mutation by id.
         * @summary Get pod mutations
         * @param {PodMutationsAPIApiPodMutationsAPIGetPodMutationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIGetPodMutation(requestParameters: PodMutationsAPIApiPodMutationsAPIGetPodMutationRequest, options?: RawAxiosRequestConfig): AxiosPromise<PodmutationsV1GetPodMutationResponse> {
            return localVarFp.podMutationsAPIGetPodMutation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists active pod mutations.
         * @summary List pod mutations
         * @param {PodMutationsAPIApiPodMutationsAPIListPodMutationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIListPodMutations(requestParameters: PodMutationsAPIApiPodMutationsAPIListPodMutationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PodmutationsV1ListPodMutationsResponse> {
            return localVarFp.podMutationsAPIListPodMutations(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a pod mutation.
         * @summary Update pod mutation
         * @param {PodMutationsAPIApiPodMutationsAPIUpdatePodMutationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        podMutationsAPIUpdatePodMutation(requestParameters: PodMutationsAPIApiPodMutationsAPIUpdatePodMutationRequest, options?: RawAxiosRequestConfig): AxiosPromise<PodmutationsV1PodMutation> {
            return localVarFp.podMutationsAPIUpdatePodMutation(requestParameters.id, requestParameters.podMutation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for podMutationsAPICreatePodMutation operation in PodMutationsAPIApi.
 * @export
 * @interface PodMutationsAPIApiPodMutationsAPICreatePodMutationRequest
 */
export interface PodMutationsAPIApiPodMutationsAPICreatePodMutationRequest {
    /**
     * 
     * @type {PodmutationsV1PodMutation}
     * @memberof PodMutationsAPIApiPodMutationsAPICreatePodMutation
     */
    readonly podMutation: PodmutationsV1PodMutation
}

/**
 * Request parameters for podMutationsAPIDeletePodMutation operation in PodMutationsAPIApi.
 * @export
 * @interface PodMutationsAPIApiPodMutationsAPIDeletePodMutationRequest
 */
export interface PodMutationsAPIApiPodMutationsAPIDeletePodMutationRequest {
    /**
     * 
     * @type {string}
     * @memberof PodMutationsAPIApiPodMutationsAPIDeletePodMutation
     */
    readonly id: string
}

/**
 * Request parameters for podMutationsAPIGetInstallCmdForMutator operation in PodMutationsAPIApi.
 * @export
 * @interface PodMutationsAPIApiPodMutationsAPIGetInstallCmdForMutatorRequest
 */
export interface PodMutationsAPIApiPodMutationsAPIGetInstallCmdForMutatorRequest {
    /**
     * 
     * @type {string}
     * @memberof PodMutationsAPIApiPodMutationsAPIGetInstallCmdForMutator
     */
    readonly clusterId: string
}

/**
 * Request parameters for podMutationsAPIGetInstallScriptForMutator operation in PodMutationsAPIApi.
 * @export
 * @interface PodMutationsAPIApiPodMutationsAPIGetInstallScriptForMutatorRequest
 */
export interface PodMutationsAPIApiPodMutationsAPIGetInstallScriptForMutatorRequest {
    /**
     * 
     * @type {string}
     * @memberof PodMutationsAPIApiPodMutationsAPIGetInstallScriptForMutator
     */
    readonly clusterId: string
}

/**
 * Request parameters for podMutationsAPIGetMutatorStatus operation in PodMutationsAPIApi.
 * @export
 * @interface PodMutationsAPIApiPodMutationsAPIGetMutatorStatusRequest
 */
export interface PodMutationsAPIApiPodMutationsAPIGetMutatorStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof PodMutationsAPIApiPodMutationsAPIGetMutatorStatus
     */
    readonly clusterId: string
}

/**
 * Request parameters for podMutationsAPIGetPodMutation operation in PodMutationsAPIApi.
 * @export
 * @interface PodMutationsAPIApiPodMutationsAPIGetPodMutationRequest
 */
export interface PodMutationsAPIApiPodMutationsAPIGetPodMutationRequest {
    /**
     * 
     * @type {string}
     * @memberof PodMutationsAPIApiPodMutationsAPIGetPodMutation
     */
    readonly id: string
}

/**
 * Request parameters for podMutationsAPIListPodMutations operation in PodMutationsAPIApi.
 * @export
 * @interface PodMutationsAPIApiPodMutationsAPIListPodMutationsRequest
 */
export interface PodMutationsAPIApiPodMutationsAPIListPodMutationsRequest {
    /**
     * 
     * @type {string}
     * @memberof PodMutationsAPIApiPodMutationsAPIListPodMutations
     */
    readonly clusterId?: string
}

/**
 * Request parameters for podMutationsAPIUpdatePodMutation operation in PodMutationsAPIApi.
 * @export
 * @interface PodMutationsAPIApiPodMutationsAPIUpdatePodMutationRequest
 */
export interface PodMutationsAPIApiPodMutationsAPIUpdatePodMutationRequest {
    /**
     * 
     * @type {string}
     * @memberof PodMutationsAPIApiPodMutationsAPIUpdatePodMutation
     */
    readonly id: string

    /**
     * 
     * @type {PodmutationsV1PodMutation}
     * @memberof PodMutationsAPIApiPodMutationsAPIUpdatePodMutation
     */
    readonly podMutation: PodmutationsV1PodMutation
}

/**
 * PodMutationsAPIApi - object-oriented interface
 * @export
 * @class PodMutationsAPIApi
 * @extends {BaseAPI}
 */
export class PodMutationsAPIApi extends BaseAPI {
    /**
     * Creates a pod mutation.
     * @summary Create pod mutation
     * @param {PodMutationsAPIApiPodMutationsAPICreatePodMutationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodMutationsAPIApi
     */
    public podMutationsAPICreatePodMutation(requestParameters: PodMutationsAPIApiPodMutationsAPICreatePodMutationRequest, options?: RawAxiosRequestConfig) {
        return PodMutationsAPIApiFp(this.configuration).podMutationsAPICreatePodMutation(requestParameters.podMutation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes pod mutation configuration
     * @param {PodMutationsAPIApiPodMutationsAPIDeletePodMutationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodMutationsAPIApi
     */
    public podMutationsAPIDeletePodMutation(requestParameters: PodMutationsAPIApiPodMutationsAPIDeletePodMutationRequest, options?: RawAxiosRequestConfig) {
        return PodMutationsAPIApiFp(this.configuration).podMutationsAPIDeletePodMutation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the install command for the pod mutator.
     * @summary Get install command for mutator
     * @param {PodMutationsAPIApiPodMutationsAPIGetInstallCmdForMutatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodMutationsAPIApi
     */
    public podMutationsAPIGetInstallCmdForMutator(requestParameters: PodMutationsAPIApiPodMutationsAPIGetInstallCmdForMutatorRequest, options?: RawAxiosRequestConfig) {
        return PodMutationsAPIApiFp(this.configuration).podMutationsAPIGetInstallCmdForMutator(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the install script for the pod mutator.
     * @summary Get install script
     * @param {PodMutationsAPIApiPodMutationsAPIGetInstallScriptForMutatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodMutationsAPIApi
     */
    public podMutationsAPIGetInstallScriptForMutator(requestParameters: PodMutationsAPIApiPodMutationsAPIGetInstallScriptForMutatorRequest, options?: RawAxiosRequestConfig) {
        return PodMutationsAPIApiFp(this.configuration).podMutationsAPIGetInstallScriptForMutator(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if the mutator is running on the cluster.
     * @summary Get mutator status
     * @param {PodMutationsAPIApiPodMutationsAPIGetMutatorStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodMutationsAPIApi
     */
    public podMutationsAPIGetMutatorStatus(requestParameters: PodMutationsAPIApiPodMutationsAPIGetMutatorStatusRequest, options?: RawAxiosRequestConfig) {
        return PodMutationsAPIApiFp(this.configuration).podMutationsAPIGetMutatorStatus(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get pod mutation by id.
     * @summary Get pod mutations
     * @param {PodMutationsAPIApiPodMutationsAPIGetPodMutationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodMutationsAPIApi
     */
    public podMutationsAPIGetPodMutation(requestParameters: PodMutationsAPIApiPodMutationsAPIGetPodMutationRequest, options?: RawAxiosRequestConfig) {
        return PodMutationsAPIApiFp(this.configuration).podMutationsAPIGetPodMutation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists active pod mutations.
     * @summary List pod mutations
     * @param {PodMutationsAPIApiPodMutationsAPIListPodMutationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodMutationsAPIApi
     */
    public podMutationsAPIListPodMutations(requestParameters: PodMutationsAPIApiPodMutationsAPIListPodMutationsRequest = {}, options?: RawAxiosRequestConfig) {
        return PodMutationsAPIApiFp(this.configuration).podMutationsAPIListPodMutations(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a pod mutation.
     * @summary Update pod mutation
     * @param {PodMutationsAPIApiPodMutationsAPIUpdatePodMutationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PodMutationsAPIApi
     */
    public podMutationsAPIUpdatePodMutation(requestParameters: PodMutationsAPIApiPodMutationsAPIUpdatePodMutationRequest, options?: RawAxiosRequestConfig) {
        return PodMutationsAPIApiFp(this.configuration).podMutationsAPIUpdatePodMutation(requestParameters.id, requestParameters.podMutation, options).then((request) => request(this.axios, this.basePath));
    }
}

