import { ReactNode, useContext } from 'react';

import { FlagsContext } from './FlagsContext';

type Props = {
  children: (error: string) => ReactNode;
};

export const RffFlagsGroupError = ({ children }: Props) => {
  const context = useContext(FlagsContext);
  if (!context) {
    throw new Error('RffFlagsGroupError must be used inside RffFlagsGroup');
  }
  if (context.errors) {
    return <>{children(context.errors[0])}</>;
  }
  return null;
};
