import { ThemeOptions } from '@mui/material';

import { primary } from '../../../theme/colors';
import { DsShadow } from '../../../theme/shadows';
import { AbsolutePseudo, focusProps } from '../../../theme/utils';
import { cssVars } from '../../../utils/cssVars';

const { setValues, getters } = cssVars({
  color: primary[500],
  colorDisabledSelected: primary[200],
  fontSize: '14px',
  lineHeight: '22px',
  bottomPadding: '8px',
});

export const tabsTheme: ThemeOptions['components'] = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: 0,
        width: 'max-content',
        '&.DS-Tabs-fullWidth': {
          width: 'initial',
        },
        '&.DS-Tabs-color': {
          '&Primary': setValues({
            color: theme.palette.blue[500],
            colorDisabledSelected: theme.palette.blue[200],
          }),
          '&Secondary': setValues({
            color: theme.palette.green[500],
            colorDisabledSelected: theme.palette.green[200],
          }),
        },
        '&.DS-Tabs-size': {
          '&Small': setValues({
            fontSize: '12px',
            lineHeight: '18px',
            bottomPadding: '4px',
          }),
          '&Medium': setValues({
            fontSize: '14px',
            lineHeight: '22px',
            bottomPadding: '8px',
          }),
        },
        '&.DS-Tabs-variant': {
          '&NoUnderline': {
            '& .MuiTabs-flexContainer': {
              borderBottom: 'none',
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          },
          '&Card': {
            '& .MuiTab-root': {
              backgroundColor: theme.palette.grey[50],
              boxShadow: theme.shadows[DsShadow.SHADOW],
              borderRadius: '4px 4px 0px 0px',
              clipPath: 'inset(-4px -4px 0px -4px)',
              padding: '4px 8px',
              '&.Mui-selected': {
                backgroundColor: 'white',
              },
            },

            '& .MuiTabs-scroller': {
              overflow: 'visible !important',
            },
            '& .MuiTabs-flexContainer': {
              borderBottom: 'none',
              gap: '4px',
            },
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          },
        },
        '&.DS-Tabs-activeTabHover': {
          '&.DS-Tabs-color': {
            '&Primary': setValues({ color: theme.palette.blue[600] }),
            '&Secondary': setValues({ color: theme.palette.green[600] }),
          },
        },
      }),
      flexContainer: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        gap: '32px',
      }),
      indicator: {
        height: '1px',
        backgroundColor: getters.color(),
        borderRadius: '4px',
      },
      scroller: {
        height: 'max-content',
      },
    },
  },
  MuiTab: {
    defaultProps: { disableRipple: true },
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: 'none',
        minHeight: 'unset',
        minWidth: 'unset',
        padding: '0 4px',
        paddingBottom: getters.bottomPadding(),
        ...theme.typography.P14M,
        color: theme.palette.grey[400],
        fontSize: getters.fontSize(),
        lineHeight: getters.lineHeight(),

        '&:hover': {
          overflow: 'visible',
          '&:not(.Mui-selected)': {
            color: theme.palette.grey[600],
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            height: 1,
            width: '100%',
            backgroundColor: theme.palette.grey[300],
            borderRadius: '4px',
            bottom: -1,

            '.DS-Tabs-variantNoUnderline &': {
              height: 0,
              bottom: 0,
            },
            '.DS-Tabs-variantCard &': {
              bottom: -2,
            },
          },
        },
        '&.Mui-selected': {
          color: getters.color(),
          fontWeight: 600,
        },
        '&.Mui-disabled': {
          color: theme.palette.grey[300],
          '&.Mui-selected': {
            color: getters.colorDisabledSelected(),
          },
        },
        '& .DsTab-Label': {
          paddingLeft: '4px',
          paddingRight: '4px',
        },
        '&.Mui-focusVisible': {
          '& .DsTab-Label': {
            position: 'relative',
            '&:after': {
              ...AbsolutePseudo,
              ...focusProps(theme),
              borderRadius: '4px',
            },
          },
        },
      }),
    },
  },
};
