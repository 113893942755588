import { useEffect } from 'react';

import { CheckResourceStatus } from '@cast/types';

import { FilterSelect } from 'components/inputs';
import { useSearchCriterion } from 'components/search';

type Props = {
  initialValue?: CheckResourceStatus;
};

const options = [
  {
    title: 'Affected',
    value: CheckResourceStatus.affected,
  },
  {
    title: 'Excepted',
    value: CheckResourceStatus.excepted,
  },
];

export const ResourcesSelect = ({ initialValue }: Props) => {
  const criterion = useSearchCriterion('type');

  useEffect(() => {
    if (initialValue) {
      criterion?.setValue?.(initialValue);
    }
    // Only set on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterSelect
      testId="resources-select"
      criterion="type"
      startAdornment="Resources:"
      sx={{ width: '100%' }}
      options={options}
      SelectProps={{ applySortingWhileSearching: false }}
    />
  );
};
