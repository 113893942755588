import { useEffect, useRef } from 'react';

import isEqual from 'lodash/isEqual';

export const usePrevious = <T>(value: T, distinctUntilChanged = false) => {
  const ref = useRef<T>();
  useEffect(() => {
    if (distinctUntilChanged) {
      if (!isEqual(value, ref.current)) {
        ref.current = value;
      }
    } else {
      ref.current = value;
    }
  });
  return ref.current;
};
