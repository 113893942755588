import {
  capitalize,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@mui/material';
import clsx from 'clsx';
import { IconContext, IconProps } from 'phosphor-react';

const getIconProps = (size: ToggleButtonGroupProps['size']): IconProps => {
  switch (size) {
    case 'small':
      return {
        size: '15px',
        weight: 'regular',
      };
    case 'large':
      return {
        size: '20px',
        weight: 'bold',
      };
    default:
      return {
        size: '20px',
        weight: 'bold',
      };
  }
};

export type ToggleButtonGroupProps = MuiToggleButtonGroupProps & {
  allowUnselect?: boolean;
  testId?: string;
};

export const ToggleButtonGroup = ({
  children,
  className,
  size = 'medium',
  exclusive = true,
  allowUnselect,
  onChange,
  testId,
  ...props
}: ToggleButtonGroupProps) => {
  return (
    <IconContext.Provider value={getIconProps(size)}>
      <MuiToggleButtonGroup
        {...props}
        size={size}
        className={clsx(
          className,
          `MuiToggleButtonGroup-size${capitalize(size)}`
        )}
        exclusive={exclusive}
        onChange={
          allowUnselect
            ? onChange
            : (event, val) => {
                if (val !== null) {
                  onChange?.(event, val);
                }
              }
        }
        data-testid={testId}
      >
        {children}
      </MuiToggleButtonGroup>
    </IconContext.Provider>
  );
};
