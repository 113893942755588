import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  clearQueryParamSilently,
  getQueryParam,
  setQueryParamSilently,
  sort,
} from '@cast/utils';

import { NormalizedTableProps, SortingState, TableApi } from '../../../types';
import { applySecondarySort } from '../_utils';

const serializeSort = ({ columnId, sortBy }: SortingState) =>
  `${columnId}:${sortBy.direction}${
    typeof sortBy.accessor === 'string' ? `:${sortBy.accessor}` : ''
  }`;

const applySortingType = (api: TableApi, sortingState?: SortingState) => {
  if (!sortingState) {
    return sortingState;
  }
  const columnModel = api.columnsInfo.columnModels.find(
    ({ id }) => id === sortingState.columnId
  );
  if (!columnModel) {
    return sortingState;
  }
  if (sortingState.sortBy.sortingType === columnModel.sortingType) {
    return sortingState;
  }
  return {
    ...sortingState,
    sortBy: {
      ...sortingState.sortBy,
      sortingType: columnModel.sortingType,
    },
  };
};

const deserializeSort = (
  tableApi: TableApi,
  sortStr: string
): SortingState | undefined => {
  const defaultSort = tableApi.columnsInfo.defaultSortingState;
  const [columnId, direction, _accessor] = sortStr.split(':');
  if (!(columnId && direction)) {
    return defaultSort;
  }
  const columnModel = tableApi.columnsInfo.columnModels.find(
    ({ id }) => id === columnId
  );
  if (!columnModel) {
    console.error(`Cannot find column: ${columnId}`);
    return defaultSort;
  }
  const accessor = _accessor || columnModel.accessor;
  return {
    columnId,
    sortBy: { direction, accessor, sortingType: columnModel.sortingType },
  } as SortingState;
};

export const usePersistedSort = (
  tableApi: TableApi,
  key?: string
): [
  SortingState | undefined,
  (sortingState: SortingState | undefined) => void
] => {
  const sortKey = key ? `${key}_sortBy` : undefined;
  const [sortingState, _setSortingState] = useState<SortingState | undefined>(
    () => {
      const defaultSort = tableApi.columnsInfo.defaultSortingState;
      if (sortKey) {
        const sortParam = getQueryParam(sortKey);
        if (!sortParam) {
          return applySortingType(tableApi, defaultSort);
        }
        return deserializeSort(tableApi, sortParam) || defaultSort;
      }
      return defaultSort;
    }
  );
  const setSortingState = (sortingState?: SortingState) => {
    sortingState = applySortingType(tableApi, sortingState);
    _setSortingState(sortingState);
    if (!sortKey) {
      return;
    }
    if (!sortingState) {
      clearQueryParamSilently(sortKey);
    } else {
      setQueryParamSilently(sortKey, serializeSort(sortingState));
    }
  };
  return [sortingState, setSortingState];
};

export const useSorting = <T = any>(
  tableApi: MutableRefObject<TableApi<T>>,
  {
    data,
    secondarySort,
    onDataSort,
    onSortingChanged,
    controlledSorting,
    urlKey,
    reorderableRows,
  }: NormalizedTableProps<T>
) => {
  const [currentSort, _setCurrentSort] = usePersistedSort(
    tableApi.current,
    urlKey
  );
  const setCurrentSort = useCallback(
    (currentSort?: SortingState) => {
      onDataSort?.(currentSort);
      _setCurrentSort(currentSort);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [controlledSorting]
  );

  // Emit initial sorting state if sorting is controlled
  useEffect(() => {
    if (controlledSorting) {
      onDataSort?.(currentSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledSorting]);

  const sortColumn = useCallback(
    (newSortingState?: SortingState) => {
      if (!newSortingState) {
        setCurrentSort(undefined);
      } else {
        const columnModel = tableApi.current.columnsInfo.columnModels.find(
          ({ id }) => id === newSortingState.columnId
        );
        if (!columnModel) {
          throw new Error('Trying to sort non existing column');
        }
        setCurrentSort(newSortingState);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableApi, controlledSorting]
  );

  const sortFn = useCallback(
    (data: T[]) => {
      if (reorderableRows) {
        return data;
      }
      if (currentSort?.sortBy || secondarySort) {
        return sort(
          data,
          applySecondarySort(currentSort?.sortBy, secondarySort)
        );
      }
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSort, reorderableRows]
  );

  useEffect(() => {
    if (onSortingChanged) {
      onSortingChanged(sortFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFn]);

  if (!tableApi.current?.sortColumn) {
    tableApi.current.sortColumn = sortColumn;
  }

  const { sortedData, sortingState } = useMemo(() => {
    if (controlledSorting || onSortingChanged) {
      return { sortedData: data, sortingState: currentSort };
    } else {
      const sortedData = data ? sortFn(data) : [];
      return {
        sortedData,
        sortingState: currentSort,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledSorting, data, currentSort, sortFn]);

  tableApi.current.state.sortedData = sortedData;
  tableApi.current.state.sortingState = sortingState;
};
