import { useContext } from 'react';

import { PriceType } from '@cast/types';

import { CostOverTimeSectionContext } from '../providers/CostOverTimeSectionProvider';

export const useCostOverTimeSection = () => {
  const [state, dispatch] = useContext(CostOverTimeSectionContext);

  const changeCostRate = (costRate: PriceType) => {
    dispatch({ type: 'changeCostRate', costRate });
  };

  return {
    ...state,
    changeCostRate,
  } as const;
};
