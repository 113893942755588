import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import clsx from 'clsx';

import { useTableContext } from '../../hooks';
import { ResizerLineApi } from '../../types';

export const ResizerLine = forwardRef<ResizerLineApi, any>((_, ref) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [isResizing, setIsResizing] = useState(false);
  const {
    tableProps: { ResizerIcon },
  } = useTableContext();

  useImperativeHandle(
    ref,
    () =>
      ({
        startResizing: ({ column, startingPoint, onResizingEnded }) => {
          const overlay = rootRef.current?.parentElement;
          const toRelativeX = (clientX: number) => {
            return clientX - (overlay?.getBoundingClientRect().left || 0);
          };
          setIsResizing(true);
          let moved = 0;
          if (rootRef.current) {
            rootRef.current.style.left = `${toRelativeX(startingPoint)}px`;
          }
          document.body.style.userSelect = 'none';
          const onMouseMove = (event: MouseEvent) => {
            moved = event.clientX - startingPoint;
            const newWidth = column.computedWidth + moved;
            if (
              newWidth >= (column.minResizeWidth || 32) &&
              newWidth <= (column.maxResizeWidth || 2000) &&
              rootRef.current
            ) {
              rootRef.current.style.transform = `translateX(${moved}px)`;
            }
          };
          const onMouseUp = () => {
            document.body.style.userSelect = 'initial';
            document.body.removeEventListener('mouseup', onMouseUp);
            document.body.removeEventListener('mousemove', onMouseMove as any);
            setIsResizing(false);
            onResizingEnded(column.computedWidth + moved);
            if (rootRef.current) {
              rootRef.current.style.transform = 'unset';
            }
          };
          document.body.addEventListener('mouseup', onMouseUp);
          document.body.addEventListener('mousemove', onMouseMove);
        },
      } as ResizerLineApi),
    [rootRef]
  );

  return (
    <div
      ref={rootRef}
      className={clsx('DS-Table-ResizerLine', {
        'DS-Table-resizing': isResizing,
      })}
    >
      <div className="DS-Table-ResizerIconWrapper">
        <ResizerIcon className="DS-Table-ResizerIcon" />
      </div>
    </div>
  );
});

ResizerLine.displayName = 'ResizerLine';
