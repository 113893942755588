import { Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

type DayPickerColorSchema = {
  typography: TypographyOptions;
  text: string;
  textDisabled: string;
  label: string;
  accentLight: string;
  accentExtraLight: string;
  accentContrast: string;
  accent: string;
  accentDark: string;
  accentExtraDark: string;
  background: string;
  backgroundTrail: string;
  activeBackground: string;
};

export const dayPickerStyles = ({
  typography,
  text,
  textDisabled,
  label,
  accent,
  accentLight,
  accentExtraLight,
  accentContrast,
  accentDark,
  accentExtraDark,
  background,
  backgroundTrail,
  activeBackground,
}: DayPickerColorSchema): SystemStyleObject<Theme> => ({
  '.date-picker-help-container': {
    position: 'absolute',
    top: 6,
    left: 24,
    right: 24,
    zIndex: 1,
    ...typography.P10R,
    bgcolor: accentExtraLight,
    borderRadius: '4px',
    padding: '1px 6px',
  },
  '.os-scrollbar-track': {
    display: 'none',
  },
  '.rdp-root': {
    paddingBottom: 12,
  },
  '.rdp-month_caption': {
    paddingBottom: 8,
  },
  '.rdp-caption_label': {
    ...typography.P12B,
    color: label,
  },
  '.rdp-hidden': {
    visibility: 'hidden',
  },
  '.rdp-month_grid': {
    borderSpacing: '0px',
  },
  '.rdp-day': {
    overflow: 'hidden',
    boxSizing: 'border-box',
    margin: '0',
    width: 32,
    height: 32,
    border: 'none',
    padding: 0,
    '.rdp-day_button': {
      cursor: 'pointer',
      background: 'none',
      borderRadius: '100%',
      ...typography.P12R,
      color: text,
      border: 'none',
      width: 32,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:hover': {
        background: background,
      },
    },
  },
  '.rdp-week': {
    borderSpacing: 0,
  },
  '.rdp-disabled .rdp-day_button': {
    cursor: 'default',
    color: textDisabled,
    '&:hover': {
      background: 'unset',
    },
  },

  // SINGLE and MULTI day pickers stylings
  '.rdp-mode_multiple, .rdp-mode_single': {
    '.rdp-selected .rdp-day_button': {
      background: accentDark,
      border: 'none',
      color: 'white',
      ...typography.P12B,
      '&:hover': {
        background: activeBackground,
      },
      '&:focus': {
        background: accentDark,
      },
      '&:active': {
        background: accentExtraDark,
      },
    },
    '.rdp-today:not(.rdp-selected) .rdp-day_button': {
      border: `1px solid ${accent}`,
    },
  },

  // RANGE and COMPARE picker stylings
  '.rdp-mode_range, .rdp-mode_compare': {
    '.hover-trail': {
      background: backgroundTrail,
    },
    '.hover-trail-start-up, .hover-trail-end-down:not(.rdp-range_middle)': {
      background: `linear-gradient(to left, white 50%, ${backgroundTrail} 50%)`,
    },
    '.hover-trail-start-down, .hover-trail-end-up:not(.rdp-range_middle)': {
      background: `linear-gradient(to right, white 50%, ${backgroundTrail} 50%)`,
    },
    '.rdp-range_start:not(.rdp-range_end)': {
      background: `linear-gradient(to right, white 50%, ${accentExtraLight} 50%)`,
    },
    '.rdp-range_end:not(.rdp-range_start)': {
      background: `linear-gradient(to left, white 50%, ${accentExtraLight} 50%)`,
    },
    '.rdp-range_start, .rdp-range_end, .rdp-selected:not(.rdp-range_middle)': {
      '.rdp-day_button': {
        background: accentDark,
        border: 'none',
        color: 'white',
        ...typography.P12B,
        '&:hover': {
          background: activeBackground,
        },
        '&:focus': {
          background: accentDark,
        },
        '&:active': {
          background: accentExtraDark,
        },
      },
    },
    '.rdp-range_middle': {
      background: accentExtraLight,
      '.rdp-day_button': {
        '&:hover': {
          background: accentContrast,
        },
      },
    },
    '.rdp-today:not(.rdp-range_start, .rdp-range_end, .rdp-selected:not(.rdp-range_middle)) .rdp-day_button':
      {
        border: `1px solid ${accent}`,
      },
    '.rdp-day:first-of-type, .first-month-day': {
      borderTopLeftRadius: '100%',
      borderBottomLeftRadius: '100%',
    },
    '.rdp-day:last-child, .last-month-day': {
      borderTopRightRadius: '100%',
      borderBottomRightRadius: '100%',
    },
  },

  // COMPARE picker stylings
  '.rdp-mode_compare': {
    '.hover-trail-start-active:not(.rdp-selected) .rdp-day_button:hover': {
      background: accentLight,
    },

    '.hover-trail-start-active:not(.rdp-selected, .hover-trail-end-active)': {
      background: `linear-gradient(to right, white 50%, ${backgroundTrail} 50%)`,
    },

    '.hover-trail-end-active:not(.rdp-selected, .hover-trail-start-active)': {
      background: `linear-gradient(to left, white 50%, ${backgroundTrail} 50%)`,
      '.rdp-day_button': {
        background: backgroundTrail,
      },
    },
  },
});
