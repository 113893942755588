import { styled } from '@mui/material';

import { StatusChipBase } from './StatusChip';

type Props = {
  className?: string;
};

export const ClusterDemoStatusChip = styled((props: Props) => {
  return (
    <StatusChipBase className={props.className} hasCaption={false}>
      Demo
    </StatusChipBase>
  );
})(({ theme }) => ({
  // FIXME: remove ! after old theme.d.ts is removed
  backgroundColor: theme.palette.aqua![100],
  color: theme.palette.aqua![900],
}));

ClusterDemoStatusChip.displayName = 'DemoStatusChip';
