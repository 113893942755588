import { useCallback, useState } from 'react';

import { Box, Divider, Stack } from '@mui/material';
import { Query, useQueryClient } from '@tanstack/react-query';

import { DialogActions, Button } from '@cast/design-system';
import { Cluster } from '@cast/types';

import { QueryKeys } from 'core/react-query';

import { GetScriptContent } from './GetScriptContent';
import { OnboardedClusterDialogHeader } from '../../../_components/OnboardedClusterDialogHeader';

type ReconnectStepProps = {
  cluster: Cluster;
  onClose: () => void;
  phase: 1 | 2;
  onReconnectStart: () => void;
};

export const SetupStep = ({
  onReconnectStart,
  cluster,
  onClose,
  phase,
}: ReconnectStepProps) => {
  const [openManualStep, setOpenManualStep] = useState(false);
  const [isManuallySubmitting, setIsManuallySubmitting] = useState(false);

  let submitForm: () => void;

  const handleFormSubmit = () => {
    submitForm();
  };

  const setSubmitForm = (submitHandler: () => void) => {
    submitForm = submitHandler;
  };

  const queryClient = useQueryClient();

  const handleIranScript = useCallback(() => {
    queryClient
      .invalidateQueries({
        predicate: (query: Query) =>
          [QueryKeys.EXTERNAL_CLUSTER, QueryKeys.EXTERNAL_CLUSTERS].includes(
            query.queryKey[0] as QueryKeys
          ),
      })
      .then(() => onReconnectStart());
  }, [onReconnectStart, queryClient]);

  return (
    <Box data-testid="setup-step">
      <OnboardedClusterDialogHeader cluster={cluster} onClose={onClose} />
      <GetScriptContent
        cluster={cluster}
        phase={phase}
        openManualStep={openManualStep}
        onClose={onClose}
        setOpenManualStep={setOpenManualStep}
        setSubmitForm={setSubmitForm}
        setIsManuallySubmitting={setIsManuallySubmitting}
      />
      <Divider />
      <DialogActions sx={{ p: '16px 32px' }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={16}
        >
          {openManualStep ? (
            <Button
              testId="give-access-button"
              type="submit"
              variant="primary"
              size="small"
              onClick={handleFormSubmit}
              loading={isManuallySubmitting}
            >
              {isManuallySubmitting ? 'Reconnecting' : 'Give access'}
            </Button>
          ) : (
            <Button
              variant="primary"
              size="small"
              onClick={handleIranScript}
              testId="i-ran-the-script-button"
            >
              I ran the script
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Box>
  );
};
