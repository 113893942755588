import { Box } from '@mui/material';

import { TableRecordsCount, TableRecordsCountProps } from '@cast/design-system';

import { useSearchContext } from './hooks';

// https://castai.atlassian.net/browse/UI-2138
export const SearchableTableRecordsCount = (props: TableRecordsCountProps) => {
  const searchCtx = useSearchContext();

  if (searchCtx?.isLoading || !searchCtx?.filteredData?.length) {
    return <Box height={22} sx={props.sx} />;
  }

  return (
    <TableRecordsCount outOf={searchCtx?.filteredData?.length} {...props} />
  );
};
