import { useCallback } from 'react';

import { ClickAwayListener } from '@mui/material';

import { Tooltip, TooltipProps } from '@cast/design-system';

export const TooltipWithClickAwayListener = ({
  onClickAway,
  children,
  ...props
}: TooltipProps & { onClickAway?: () => void }) => {
  const handleTooltipClose = useCallback(() => {
    if (onClickAway) {
      onClickAway();
    }
  }, [onClickAway]);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip {...props}>{children}</Tooltip>
    </ClickAwayListener>
  );
};
