import { PropsWithChildren } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import * as yup from 'yup';

import { Button, DialogActions } from '@cast/design-system';
import { makeValidateSync } from '@cast/utils';

import { OrganizationAvatar } from 'components/avatars';
import { RffInput, RffForm } from 'core/forms/rff';
import { OrganizationFormValues } from 'types/organization';

type Actions = {
  onSubmit: (value: OrganizationFormValues) => void;
  onCancel: () => void;
};

type Props = PropsWithChildren<
  {
    submitting: boolean;
    initialValues?: OrganizationFormValues;
    errors?: string;
  } & Actions
>;

const createOrganizationSchema = yup.object({
  name: yup
    .string()
    .required('Please set an organization name.')
    .matches(
      /^[aA-zZ\s-._0-9]+$/,
      'Invalid company name - only alphabets, numbers and ".-_" are allowed'
    ),
});

export const OrganizationForm = ({
  initialValues,
  submitting,
  errors,
  children,
  onSubmit,
  onCancel,
}: Props) => {
  const validate = makeValidateSync(createOrganizationSchema);
  const inEditMode = Boolean(initialValues);

  return (
    <RffForm
      initialValues={initialValues}
      initialValuesEqual={(a, b) => a === b}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ values }) => {
        return (
          <Box>
            <Stack
              gap={40}
              py={20}
              px={24}
              direction="row"
              flexWrap="nowrap"
              width="100%"
              alignItems="flex-end"
            >
              <Stack direction="column" flex={1}>
                <Stack>{children}</Stack>
                <RffInput
                  {...(errors ? { error: errors } : {})}
                  testId="organization-name-input"
                  name="name"
                  placeholder="Name"
                  required
                  fullWidth
                  inputProps={{ 'aria-label': 'Organization name input' }}
                  autoFocus
                  sx={{ marginTop: 24 }}
                />
              </Stack>
              <Stack flexBasis={100} mt={40}>
                <OrganizationAvatar
                  name={values?.name || ''}
                  size="xxl"
                  color={values?.name?.length ? 'indigo' : 'grey'}
                />
              </Stack>
            </Stack>

            <Divider sx={{ borderColor: 'grey.100' }} />

            <DialogActions>
              <Button onClick={onCancel} variant="tertiary">
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={submitting}
                data-testid="submit-button"
              >
                {inEditMode ? 'Save changes' : 'Set up organization'}
              </Button>
            </DialogActions>
          </Box>
        );
      }}
    </RffForm>
  );
};
