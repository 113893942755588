import { ReactElement, ReactNode, useCallback } from 'react';

import { SxProps } from '@mui/system';

import { Button, Icons } from '@cast/design-system';

import { HexIconError } from 'components/hex-icons';
import {
  StatusMessage,
  StatusMessageProps,
} from 'components/messages/StatusMessage';
import { openChat } from 'utils/supportChat';

type Props = {
  title?: ReactNode;
  body?: ReactNode;
  refresh?: () => void;
  compact?: boolean;
  iconSize?: number;
  icon?: ReactElement;
  sx?: SxProps;
} & Partial<StatusMessageProps>;

export const FailedToLoad = ({
  title = 'Failed to load data',
  body,
  refresh,
  compact,
  iconSize,
  icon = <Icons.Warning weight="fill" />,
  sx,
  ...rest
}: Props) => {
  const handleRefresh = useCallback(() => {
    refresh ? refresh() : window.location.reload();
  }, [refresh]);

  return (
    <StatusMessage
      icon={<HexIconError size={iconSize || (compact ? 34 : 44)} icon={icon} />}
      title={title}
      body={
        body ?? (
          <>
            We’ve got a problem here. Try to refresh the page or reach out to
            our{' '}
            <Button variant="text" onClick={openChat}>
              customer support.
            </Button>
          </>
        )
      }
      actions={
        <Button
          variant="text"
          startIcon={<Icons.ArrowClockwise />}
          onClick={handleRefresh}
        >
          {refresh ? 'Retry' : 'Refresh the page'}
        </Button>
      }
      actionsProps={compact ? { mt: 8 } : undefined}
      sx={sx}
      testId="failed-to-load"
      {...rest}
    />
  );
};
