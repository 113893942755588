import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { captureMessage } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { HubspotObject } from '@cast/types';

import { HUBSPOT_COOKIE_KEY, IS_PROD } from 'common/constants';
import { apiClient } from 'core/api-client';
import { useAuth } from 'core/auth';
import { useHubspotContact } from 'core/hubspot';
import { parseApiError } from 'utils/api';
import { getCookie } from 'utils/cookie';

type Payload = { email: string; pageName: string };

type Props = {
  pageName: string;
};

export const useCreateHubspotContactMutation = ({ pageName }: Props) => {
  const { user } = useAuth();
  const { initContactState, setInitContactState } = useHubspotContact();

  const { mutate } = useMutation({
    mutationFn: ({ email, pageName }: Payload) => {
      const hubspotCookie = getCookie(HUBSPOT_COOKIE_KEY);
      return apiClient.hubspot.createHubspotContact({
        submittedAt: new Date().getTime(),
        fields: [
          { objectTypeId: HubspotObject.CONTACT, name: 'email', value: email },
        ],
        context: {
          hutk: hubspotCookie!,
          pageUri: window.document.URL,
          pageName,
        },
      });
    },
    onSuccess: () => {
      setInitContactState('success');
      Sentry.captureMessage('HS DEBUG: Created hs initial user entity', {
        level: 'debug',
      });
    },
    onError: (error, payload) => {
      setInitContactState('failed');
      Sentry.captureMessage(
        'HS DEBUG: Failed to create hs initial user entity',
        {
          level: 'debug',
        }
      );

      captureMessage(
        `Hubspot: Unable to submit INITIAL signup form. Payload: ${JSON.stringify(
          payload
        )}; Error: ${parseApiError(error)}`
      );
    },
  });

  const enabled =
    IS_PROD &&
    initContactState !== 'success' &&
    (user?.firstLogin || initContactState === 'failed');

  useEffect(() => {
    Sentry.captureMessage('HS DEBUG: Trying to create hs initial user entity', {
      level: 'debug',
      extra: { enabled, user, initContactState },
    });
  }, [enabled, initContactState, user]);

  useEffect(() => {
    if (enabled && user?.id && user?.email) {
      mutate({ email: user.email, pageName });
    }
  }, [user, user?.email, user?.id, enabled, pageName, mutate]);

  return { success: initContactState === 'success' };
};
