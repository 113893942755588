import { ThemeOptions } from '@mui/material';

import { AbsolutePseudo, focusProps } from '../../../theme/utils';

export const radioTheme: ThemeOptions['components'] = {
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&.DsRadio-size': {
          '&Medium': {
            fontSize: '20px',
          },
          '&Small': {
            fontSize: '16px',
            '&.Mui-checked .RadioIconBase': {
              // Antialiasing issue
              borderWidth: 'thick',
            },
          },
        },
        padding: 0,
        width: '1em',
        height: '1em',
        '& .RadioIconBase': {
          borderRadius: '50%',
          boxSizing: 'border-box',
          transition: 'height ease-in-out 0.1s',
          width: '1em',
          height: '1em',
        },
        '&.Mui-checked': {
          border: 'none',
          '&.MuiRadio-color': {
            '&Primary': {
              color: theme.palette.blue[500],
              '&:hover': {
                color: theme.palette.blue[600],
              },
            },
            '&Secondary': {
              color: theme.palette.green[500],
              '&:hover': {
                color: theme.palette.green[600],
              },
            },
            '&Tertiary': {
              color: theme.palette.grey[800],
              '&:hover': {
                color: theme.palette.grey[900],
              },
            },
            '&Error': {
              color: theme.palette.red[500],
              '&:hover': {
                color: theme.palette.red[600],
              },
            },
          },
          '&.Mui-disabled': {
            opacity: 0.3,
          },
          '& .RadioIconBase': {
            color: 'inherit',
            backgroundColor: 'unset',
            border: 'solid currentColor 0.3em',
          },
        },

        '&:not(.Mui-checked)': {
          backgroundColor: 'unset',
          '& .RadioIconBase': {
            backgroundColor: 'inherit',
            border: `1px solid ${theme.palette.grey[200]}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          '&:hover .RadioIconBase': {
            borderColor: theme.palette.grey[400],
          },
          '&.Mui-disabled .RadioIconBase': {
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[200],
          },
        },

        '&:active .RadioIconBase': {
          height: 'calc(1em - 2px)',
        },

        '&.Mui-focusVisible .RadioIconBase:after': {
          ...AbsolutePseudo,
          ...focusProps(theme),
          borderRadius: 'inherit',
        },
      }),
    },
  },
};
