import { Dialog } from '@cast/design-system';
import { Cluster } from '@cast/types';

import { AssignCredentialsDialogContent } from '../../_components/AssignCredentialsDialogContent';

type Props = {
  cluster: Cluster;
  destroy: () => void;
  onSecurityEnabled?: () => void;
};

export const AssignCredentialsDialog = (props: Props) => {
  return (
    <Dialog
      open
      onClose={props.destroy}
      disableCloseOnBackdropClick
      disableEscapeKeyDown
      sx={{ '& .MuiDialogActions-root': { py: 16 } }}
      componentsProps={
        {
          root: {
            'data-testid': 'phase-2-onboarding-dialog',
            onClick: (e: MouseEvent) => {
              // Prevents drawer inside org security from closing when clicking on the dialog
              e.stopPropagation();
            },
          },
        } as any
      }
    >
      <AssignCredentialsDialogContent {...props} />
    </Dialog>
  );
};
