import { useMemo } from 'react';

import { Box, Stack, Typography, styled } from '@mui/material';
import round from 'lodash/round';

import { Paper } from '@cast/design-system';
import { K8sProvider } from '@cast/types';

import { GaugeChart } from 'components/chart';
import { getSecurityStandardData } from 'features/organization/security-insights/utils';
import { useBestPracticeOverviewQuery } from 'hooks/queries/security-insights';
import { useClusters } from 'hooks/useClusters';

import { SlimFailedToLoad } from './SlimFailedToLoad';

const CardContainer = styled(Paper)({
  display: 'flex',
  padding: '16px 16px 14px 16px',
  gap: 24,
});

export const ComplianceCard = () => {
  const { clusters } = useClusters();
  const existingProviders = useMemo(
    () =>
      Array.from(
        new Set<K8sProvider>(clusters.map(({ providerType }) => providerType))
      ),
    [clusters]
  );
  const currentStandard = getSecurityStandardData(existingProviders[0]);
  const { overview, error, refetch } = useBestPracticeOverviewQuery({
    standard: currentStandard.value,
  });

  const totalChecks =
    overview?.failedChecks != null && overview?.passedChecks != null
      ? overview?.failedChecks + overview?.passedChecks
      : null;
  const passedChecksPercentage =
    totalChecks != null
      ? totalChecks === 0
        ? 0
        : round(((overview?.passedChecks ?? 0) / totalChecks) * 100, 2)
      : null;

  if (error) {
    return (
      <CardContainer>
        <SlimFailedToLoad
          title="Failed to load compliance data"
          refresh={refetch}
        />
      </CardContainer>
    );
  }

  return (
    <CardContainer testId="compliance-overview-card">
      <Box width={74} my={-5} ml={-32}>
        <GaugeChart
          outerLayer={[
            { color: 'grey.700', value: passedChecksPercentage ?? 0 },
          ]}
          outerLayerBackground="grey.200"
          barWidth={7}
          variant="right"
          borderColor="transparent"
        />
      </Box>
      <Stack gap={8}>
        <Stack direction="row" alignItems="center" gap={8}>
          <Typography variant="L12R" component="span">
            Compliance with
          </Typography>
          <Typography
            variant="L12B"
            component="span"
            data-testid="standard-string"
          >
            {currentStandard.title}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            typography="h5"
            data-testid="passed-checks-percentage"
            mr={24}
          >
            {passedChecksPercentage ?? '--'}%
          </Typography>
          <Typography
            variant="h6"
            color="grey.700"
            data-testid="passed-checks-count"
          >
            {overview?.passedChecks ?? '--'}
          </Typography>
          <Typography
            variant="h6"
            color="grey.400"
            data-testid="total-checks-count"
          >
            /{totalChecks ?? '--'}
          </Typography>
          <Typography variant="P12R" color="textSecondary" ml={4} mt={4}>
            checks passed
          </Typography>
        </Stack>
      </Stack>
    </CardContainer>
  );
};
