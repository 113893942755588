import { useContext } from 'react';

import { useCluster } from 'hooks/useCluster';

import { AbilityContext } from '../AbilityContext';
import { AbilityConditions, Actions, Subjects } from '../types';

export const useAbility = () => {
  const ability = useContext(AbilityContext);
  const { cluster } = useCluster();

  const can = (
    action: Actions,
    subject: Subjects,
    conditions: AbilityConditions = {}
  ) => {
    const { subjectId, organizationId, clusterId } = conditions;

    return ability.can(action, subject, {
      subjectId: subjectId,
      organizationId: organizationId,
      clusterId: clusterId ?? cluster.id,
    });
  };

  const canMany = (
    rules: Array<[Actions, Subjects]>,
    conditions: AbilityConditions = {}
  ) => {
    return rules.map(([action, subject]) => can(action, subject, conditions));
  };

  const cannot = (
    action: Actions,
    subject: Subjects,
    conditions: AbilityConditions = {}
  ) => {
    const { subjectId, organizationId, clusterId } = conditions;
    return ability.cannot(action, subject, {
      subjectId: subjectId,
      organizationId: organizationId,
      clusterId: clusterId ?? cluster.id,
    });
  };

  const cannotMany = (
    rules: Array<[Actions, Subjects]>,
    conditions: AbilityConditions = {}
  ) => {
    return rules.map(([action, subject]) =>
      cannot(action, subject, conditions)
    );
  };

  const canOneOf = (
    action: Actions,
    subjects: Subjects[],
    conditions: AbilityConditions = {}
  ) => {
    return subjects.some((subject) => can(action, subject, conditions));
  };

  const cannotOneOf = (
    action: Actions,
    subjects: Subjects[],
    conditions: AbilityConditions = {}
  ) => {
    return subjects.some((subject) => cannot(action, subject, conditions));
  };

  const canAllOf = (
    action: Actions,
    subjects: Subjects[],
    conditions: AbilityConditions = {}
  ) => {
    return subjects.every((subject) => can(action, subject, conditions));
  };

  const cannotAllOf = (
    action: Actions,
    subjects: Subjects[],
    conditions: AbilityConditions = {}
  ) => {
    return subjects.every((subject) => cannot(action, subject, conditions));
  };

  const relevantRuleFor = (
    action: Actions,
    subject: Subjects,
    conditions: AbilityConditions = {}
  ) => {
    const { subjectId, organizationId, clusterId } = conditions;
    return ability.relevantRuleFor(action, subject, {
      subjectId: subjectId,
      organizationId: organizationId,
      clusterId: clusterId ?? cluster.id,
    });
  };

  if (ability.isLoading) {
    return ability;
  }

  return {
    ...ability,
    can,
    canMany,
    cannot,
    cannotMany,
    canOneOf,
    canAllOf,
    cannotOneOf,
    cannotAllOf,
    relevantRuleFor,
  };
};
