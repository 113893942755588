import dayjs from 'dayjs';
import maxBy from 'lodash/maxBy';

import {
  NodeResponse,
  RebalancingPlan,
  RebalancingPlanOperation,
  RebalancingPlanStatusEnum,
} from '@cast/types';

import {
  GENERATED_REBALANCING_STATES,
  REBALANCING_IN_PROGRESS_STATES,
} from 'misc/constants';

export const tsKeysForPlanStatus: Record<RebalancingPlanStatusEnum, string> = {
  [RebalancingPlanStatusEnum.error]: 'createAt',
  [RebalancingPlanStatusEnum.finished]: 'finishedAt',
  [RebalancingPlanStatusEnum.generating]: 'createdAt',
  [RebalancingPlanStatusEnum.generated]: 'generatedAt',
  [RebalancingPlanStatusEnum.preparing_nodes]: 'generatedAt',
  [RebalancingPlanStatusEnum.creating_nodes]: 'generatedAt',
  [RebalancingPlanStatusEnum.draining_nodes]: 'createdNodesAt',
  [RebalancingPlanStatusEnum.deleting_nodes]: 'drainedNodesAt',
  [RebalancingPlanStatusEnum.invalid]: 'invalidAt',
  [RebalancingPlanStatusEnum.partially_finished]: 'partiallyFinishedAt',
};

type RebalancingPlanOperationCount = {
  total: number;
  completed: number;
  create: number;
  created: number;
  drain: number;
  drained: number;
  delete: number;
  deleted: number;
};

export const aggregateOperations = (
  rebalancePlanOperations: RebalancingPlanOperation[] = []
): RebalancingPlanOperationCount => {
  return rebalancePlanOperations.reduce(
    (
      count: RebalancingPlanOperationCount,
      operation: RebalancingPlanOperation
    ) => {
      if (operation.type === 'create_node') {
        count.total += 1;
        count.create += 1;

        if (operation.finishedAt) {
          count.created += 1;
          count.completed += 1;
        }
      }

      if (operation.type === 'drain_node') {
        count.total += 1;
        count.drain += 1;

        if (operation.finishedAt) {
          count.drained += 1;
          count.completed += 1;
        }
      }

      if (operation.type === 'delete_node') {
        count.total += 1;
        count.delete += 1;

        if (operation.finishedAt) {
          count.deleted += 1;
          count.completed += 1;
        }
      }

      return count;
    },
    {
      total: 0,
      completed: 0,
      create: 0,
      created: 0,
      drain: 0,
      drained: 0,
      delete: 0,
      deleted: 0,
    }
  );
};

export const isNodeFailedToDrain = ({ annotations }: NodeResponse) =>
  !!annotations && annotations['rebalancing.cast.ai/status'] === 'drain-failed';

export const isRebalancingPlanOlderThanHour = (timestamp: string) =>
  Boolean(dayjs(timestamp).isBefore(dayjs(new Date()).subtract(1, 'hour')));

export const getRebalancingPlanTimestamp = (plan: RebalancingPlan) =>
  plan[tsKeysForPlanStatus[plan.status!] as keyof RebalancingPlan] as string;

export const getLatestFinishedRebalancingPlan = (plans: RebalancingPlan[]) => {
  return maxBy(
    plans.filter((plan) => plan.status === RebalancingPlanStatusEnum.finished),
    (plan: RebalancingPlan) => getRebalancingPlanTimestamp(plan)
  );
};

export const getLatestActiveRebalancingPlan = (
  plans: RebalancingPlan[]
): { plan: RebalancingPlan; lastActivityAt: string } | undefined => {
  const plansWithActiveRebalancing = plans.filter(
    (plan: RebalancingPlan) =>
      REBALANCING_IN_PROGRESS_STATES.includes(plan.status!) ||
      (GENERATED_REBALANCING_STATES.includes(plan.status!) &&
        !isRebalancingPlanOlderThanHour(getRebalancingPlanTimestamp(plan)))
  );
  const latestFinishedPlan = getLatestFinishedRebalancingPlan(plans);
  let latestPlan: RebalancingPlan | undefined;

  if (latestFinishedPlan) {
    const plansAfterTheLatestFinished = plansWithActiveRebalancing.filter(
      (plan: RebalancingPlan) =>
        dayjs(getRebalancingPlanTimestamp(plan)).isSameOrAfter(
          dayjs(getRebalancingPlanTimestamp(latestFinishedPlan))
        )
    );
    latestPlan = maxBy(plansAfterTheLatestFinished, (plan: RebalancingPlan) =>
      getRebalancingPlanTimestamp(plan)
    );
  } else {
    latestPlan = maxBy(plansWithActiveRebalancing, (plan: RebalancingPlan) =>
      getRebalancingPlanTimestamp(plan)
    );
  }

  return latestPlan
    ? {
        plan: latestPlan,
        lastActivityAt: getRebalancingPlanTimestamp(latestPlan),
      }
    : undefined;
};
