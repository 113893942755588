import { alpha, ThemeOptions } from '@mui/material';

import { DsShadow } from '../../../theme/shadows';
import { focusProps } from '../../../theme/utils';

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    tertiary: true;
  }
}

export const sliderTheme: ThemeOptions['components'] = {
  MuiSlider: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(ownerState.color === 'tertiary' && {
          '--thumbColor': theme.palette.grey[700],
          '--thumbHoverColor': theme.palette.grey[800],
          '--trackColor': theme.palette.grey[600],
          '--activeShadowColor': alpha(theme.palette.grey[500]!, 0.3),
        }),
      }),
      sizeSmall: {
        '& .MuiSlider-thumb': {
          width: 16,
          height: 16,
          '&:before': {
            width: 6.4,
            height: 6.4,
          },
          '&.Mui-focusVisible': {
            '&:not(:active):after': {
              width: 15,
              height: 15,
            },
          },
        },
        '& .MuiSlider-rail': {
          marginLeft: -1,
          height: 4,
        },
        '& .MuiSlider-track': {
          height: 2,
          boxSizing: 'border-box',
        },
      },
      thumb: ({ theme }) => ({
        width: 24,
        height: 24,
        '&:before': {
          boxShadow: 'unset',
          backgroundColor: 'white',
          width: 9,
          height: 9,
        },
        backgroundColor: 'var(--thumbColor)',
        '&:hover': {
          backgroundColor: 'var(--thumbHoverColor)',
        },
        '&.Mui-focusVisible': {
          '&:not(:active):after': {
            width: 23,
            height: 23,
            ...focusProps(theme),
          },
          border: 'none',
          boxShadow: theme.shadows[DsShadow.NONE],
        },
        '&:not(.Mui-focusVisible):hover': {
          boxShadow: 'unset',
        },
        '&:active': {
          border: `unset`,
          boxShadow: `0 0 0 4px var(--activeShadowColor)`,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[300],
          '&:before': {
            backgroundColor: theme.palette.grey[200],
          },
        },
      }),
      rail: ({ theme }) => ({
        marginLeft: -2,
        height: 12,
        backgroundColor: theme.palette.grey[200],
      }),
      track: ({ theme }) => ({
        backgroundColor: 'var(--trackColor)',
        height: 8,
        boxSizing: 'border-box',
        border: 'none',
        '.Mui-disabled &': {
          backgroundColor: theme.palette.grey[300],
        },
      }),
      colorPrimary: ({ theme }) => ({
        '--thumbColor': theme.palette.blue[500],
        '--thumbHoverColor': theme.palette.blue[600],
        '--trackColor': theme.palette.blue[400],
        '--activeShadowColor': alpha(theme.palette.blue[500]!, 0.3),
      }),
      colorSecondary: ({ theme }) => ({
        '--thumbColor': theme.palette.green[500],
        '--thumbHoverColor': theme.palette.green[600],
        '--trackColor': theme.palette.green[400],
        '--activeShadowColor': alpha(theme.palette.green[500]!, 0.3),
      }),
      valueLabel: ({ theme }) => ({
        ...theme.typography.P12R,
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[700],
        borderRadius: '4px',
        padding: '4px 8px ',
      }),
    },
  },
};
