/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AioptimizerV1Data } from '../models';
// @ts-ignore
import { AioptimizerV1GetCategorizedPromptsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsAPIKeyUsageReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsAPIKeysSavingsReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsCategoryRecommendationsReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsCategoryUsageReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsRecommendationsReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetChatCompletionsUsageReportResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetModelsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetRoutingEventsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1GetSupportedProvidersResponse } from '../models';
/**
 * AIEnablerAPIApi - axios parameter creator
 * @export
 */
export const AIEnablerAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetCategorizedPrompts returns a list of categorized prompts from the AI Enabler.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetCategorizedPrompts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/categorized-prompts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific api key.
         * @param {string} apiKeyId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsAPIKeyUsageReport: async (apiKeyId: string, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('aIEnablerAPIGetChatCompletionsAPIKeyUsageReport', 'apiKeyId', apiKeyId)
            const localVarPath = `/v1/llm/openai/chat-completions/reports/api-keys/{apiKeyId}/usage`
                .replace(`{${"apiKeyId"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a recommendations report for the API keys used in chat completions.
         * @param {string} apiKeyId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport: async (apiKeyId: string, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport', 'apiKeyId', apiKeyId)
            const localVarPath = `/v1/llm/openai/chat-completions/reports/api-keys/{apiKeyId}/recommendations`
                .replace(`{${"apiKeyId"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a savings report for the API keys used in chat completions.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport: async (fromTime?: string, toTime?: string, categoryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/chat-completions/reports/api-keys-savings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a recommendations report for the category used in chat completions.
         * @param {string} categoryId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport: async (categoryId: string, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport', 'categoryId', categoryId)
            const localVarPath = `/v1/llm/openai/chat-completions/reports/categories/{categoryId}/recommendations`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific category.
         * @param {string} categoryId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsCategoryUsageReport: async (categoryId: string, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('aIEnablerAPIGetChatCompletionsCategoryUsageReport', 'categoryId', categoryId)
            const localVarPath = `/v1/llm/openai/chat-completions/reports/categories/{categoryId}/usage`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a recommendations report of chat completions data.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsRecommendationsReport: async (fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/chat-completions/reports/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsUsageReport: async (fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/chat-completions/reports/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of models available for routing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetModels: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetRoutingEvents returns a list of routing events.
         * @param {string} promptId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<AIEnablerAPIGetRoutingEventsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetRoutingEvents: async (promptId: string, fromTime?: string, toTime?: string, pageLimit?: string, pageCursor?: string, type?: Array<AIEnablerAPIGetRoutingEventsTypeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptId' is not null or undefined
            assertParamExists('aIEnablerAPIGetRoutingEvents', 'promptId', promptId)
            const localVarPath = `/v1/llm/openai/routing-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promptId !== undefined) {
                localVarQueryParameter['promptId'] = promptId;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of supported providers along with the supported models.
         * @param {boolean} [supportedInPlayground] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetSupportedProviders: async (supportedInPlayground?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/openai/supported-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (supportedInPlayground !== undefined) {
                localVarQueryParameter['supportedInPlayground'] = supportedInPlayground;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ReportChatCompletions reports OpenAI chat completions data to the AI Enabler.
         * @param {AioptimizerV1Data} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIReportChatCompletions: async (data: AioptimizerV1Data, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('aIEnablerAPIReportChatCompletions', 'data', data)
            const localVarPath = `/v1/llm/openai/chat-completions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateCategorizedPrompt updates the categorized prompt.
         * @param {string} categorizedPromptId 
         * @param {AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum} feedbackScore 
         * @param {string} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIUpdateCategorizedPrompt: async (categorizedPromptId: string, feedbackScore: AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum, feedback: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categorizedPromptId' is not null or undefined
            assertParamExists('aIEnablerAPIUpdateCategorizedPrompt', 'categorizedPromptId', categorizedPromptId)
            // verify required parameter 'feedbackScore' is not null or undefined
            assertParamExists('aIEnablerAPIUpdateCategorizedPrompt', 'feedbackScore', feedbackScore)
            // verify required parameter 'feedback' is not null or undefined
            assertParamExists('aIEnablerAPIUpdateCategorizedPrompt', 'feedback', feedback)
            const localVarPath = `/v1/llm/openai/categorized-prompts/{categorizedPromptId}`
                .replace(`{${"categorizedPromptId"}}`, encodeURIComponent(String(categorizedPromptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (feedbackScore !== undefined) {
                localVarQueryParameter['feedbackScore'] = feedbackScore;
            }

            if (feedback !== undefined) {
                localVarQueryParameter['feedback'] = feedback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIEnablerAPIApi - functional programming interface
 * @export
 */
export const AIEnablerAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIEnablerAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetCategorizedPrompts returns a list of categorized prompts from the AI Enabler.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetCategorizedPrompts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetCategorizedPromptsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetCategorizedPrompts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetCategorizedPrompts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific api key.
         * @param {string} apiKeyId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetChatCompletionsAPIKeyUsageReport(apiKeyId: string, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsAPIKeyUsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetChatCompletionsAPIKeyUsageReport(apiKeyId, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetChatCompletionsAPIKeyUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a recommendations report for the API keys used in chat completions.
         * @param {string} apiKeyId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport(apiKeyId: string, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsRecommendationsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport(apiKeyId, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a savings report for the API keys used in chat completions.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport(fromTime?: string, toTime?: string, categoryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsAPIKeysSavingsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport(fromTime, toTime, categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a recommendations report for the category used in chat completions.
         * @param {string} categoryId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport(categoryId: string, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsCategoryRecommendationsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport(categoryId, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific category.
         * @param {string} categoryId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetChatCompletionsCategoryUsageReport(categoryId: string, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsCategoryUsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetChatCompletionsCategoryUsageReport(categoryId, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetChatCompletionsCategoryUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a recommendations report of chat completions data.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetChatCompletionsRecommendationsReport(fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsRecommendationsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetChatCompletionsRecommendationsReport(fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetChatCompletionsRecommendationsReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data.
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetChatCompletionsUsageReport(fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetChatCompletionsUsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetChatCompletionsUsageReport(fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetChatCompletionsUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of models available for routing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetModels(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetModels(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetRoutingEvents returns a list of routing events.
         * @param {string} promptId 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<AIEnablerAPIGetRoutingEventsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetRoutingEvents(promptId: string, fromTime?: string, toTime?: string, pageLimit?: string, pageCursor?: string, type?: Array<AIEnablerAPIGetRoutingEventsTypeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetRoutingEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetRoutingEvents(promptId, fromTime, toTime, pageLimit, pageCursor, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetRoutingEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of supported providers along with the supported models.
         * @param {boolean} [supportedInPlayground] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIGetSupportedProviders(supportedInPlayground?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetSupportedProvidersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIGetSupportedProviders(supportedInPlayground, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIGetSupportedProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ReportChatCompletions reports OpenAI chat completions data to the AI Enabler.
         * @param {AioptimizerV1Data} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIReportChatCompletions(data: AioptimizerV1Data, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIReportChatCompletions(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIReportChatCompletions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UpdateCategorizedPrompt updates the categorized prompt.
         * @param {string} categorizedPromptId 
         * @param {AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum} feedbackScore 
         * @param {string} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerAPIUpdateCategorizedPrompt(categorizedPromptId: string, feedbackScore: AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum, feedback: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerAPIUpdateCategorizedPrompt(categorizedPromptId, feedbackScore, feedback, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerAPIApi.aIEnablerAPIUpdateCategorizedPrompt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AIEnablerAPIApi - factory interface
 * @export
 */
export const AIEnablerAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIEnablerAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary GetCategorizedPrompts returns a list of categorized prompts from the AI Enabler.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetCategorizedPrompts(options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetCategorizedPromptsResponse> {
            return localVarFp.aIEnablerAPIGetCategorizedPrompts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific api key.
         * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsAPIKeyUsageReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsAPIKeyUsageReportResponse> {
            return localVarFp.aIEnablerAPIGetChatCompletionsAPIKeyUsageReport(requestParameters.apiKeyId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a recommendations report for the API keys used in chat completions.
         * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsRecommendationsReportResponse> {
            return localVarFp.aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport(requestParameters.apiKeyId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a savings report for the API keys used in chat completions.
         * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsAPIKeysSavingsReportResponse> {
            return localVarFp.aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport(requestParameters.fromTime, requestParameters.toTime, requestParameters.categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a recommendations report for the category used in chat completions.
         * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsCategoryRecommendationsReportResponse> {
            return localVarFp.aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport(requestParameters.categoryId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data for a specific category.
         * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsCategoryUsageReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsCategoryUsageReportResponse> {
            return localVarFp.aIEnablerAPIGetChatCompletionsCategoryUsageReport(requestParameters.categoryId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a recommendations report of chat completions data.
         * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsRecommendationsReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsRecommendationsReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsRecommendationsReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsRecommendationsReportResponse> {
            return localVarFp.aIEnablerAPIGetChatCompletionsRecommendationsReport(requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a report of chat completions usage data.
         * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetChatCompletionsUsageReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsUsageReportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetChatCompletionsUsageReportResponse> {
            return localVarFp.aIEnablerAPIGetChatCompletionsUsageReport(requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of models available for routing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetModels(options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetModelsResponse> {
            return localVarFp.aIEnablerAPIGetModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetRoutingEvents returns a list of routing events.
         * @param {AIEnablerAPIApiAIEnablerAPIGetRoutingEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetRoutingEvents(requestParameters: AIEnablerAPIApiAIEnablerAPIGetRoutingEventsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetRoutingEventsResponse> {
            return localVarFp.aIEnablerAPIGetRoutingEvents(requestParameters.promptId, requestParameters.fromTime, requestParameters.toTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of supported providers along with the supported models.
         * @param {AIEnablerAPIApiAIEnablerAPIGetSupportedProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIGetSupportedProviders(requestParameters: AIEnablerAPIApiAIEnablerAPIGetSupportedProvidersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetSupportedProvidersResponse> {
            return localVarFp.aIEnablerAPIGetSupportedProviders(requestParameters.supportedInPlayground, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ReportChatCompletions reports OpenAI chat completions data to the AI Enabler.
         * @param {AIEnablerAPIApiAIEnablerAPIReportChatCompletionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIReportChatCompletions(requestParameters: AIEnablerAPIApiAIEnablerAPIReportChatCompletionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIEnablerAPIReportChatCompletions(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateCategorizedPrompt updates the categorized prompt.
         * @param {AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPromptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerAPIUpdateCategorizedPrompt(requestParameters: AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPromptRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.aIEnablerAPIUpdateCategorizedPrompt(requestParameters.categorizedPromptId, requestParameters.feedbackScore, requestParameters.feedback, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aIEnablerAPIGetChatCompletionsAPIKeyUsageReport operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReportRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReport
     */
    readonly apiKeyId: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport
     */
    readonly apiKeyId: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReportRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReport
     */
    readonly toTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReport
     */
    readonly categoryId?: string
}

/**
 * Request parameters for aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReportRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReport
     */
    readonly categoryId: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIEnablerAPIGetChatCompletionsCategoryUsageReport operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReportRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReport
     */
    readonly categoryId: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIEnablerAPIGetChatCompletionsRecommendationsReport operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsRecommendationsReportRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsRecommendationsReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsRecommendationsReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsRecommendationsReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIEnablerAPIGetChatCompletionsUsageReport operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsUsageReportRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetChatCompletionsUsageReportRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsUsageReport
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetChatCompletionsUsageReport
     */
    readonly toTime?: string
}

/**
 * Request parameters for aIEnablerAPIGetRoutingEvents operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetRoutingEventsRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetRoutingEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetRoutingEvents
     */
    readonly promptId: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetRoutingEvents
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetRoutingEvents
     */
    readonly toTime?: string

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetRoutingEvents
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetRoutingEvents
     */
    readonly pageCursor?: string

    /**
     * 
     * @type {Array<'EVENT_TYPE_INVALID' | 'EVENT_TYPE_PROMPT_RECEIVED' | 'EVENT_TYPE_PROMPT_CATEGORIZED' | 'EVENT_TYPE_LOOKUP_PROVIDERS_AND_MODELS' | 'EVENT_TYPE_ORDER_PROVIDERS_AND_MODELS' | 'EVENT_TYPE_PICK_PROVIDER_AND_MODEL' | 'EVENT_TYPE_ROUTE_REQUEST'>}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetRoutingEvents
     */
    readonly type?: Array<AIEnablerAPIGetRoutingEventsTypeEnum>
}

/**
 * Request parameters for aIEnablerAPIGetSupportedProviders operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIGetSupportedProvidersRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIGetSupportedProvidersRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AIEnablerAPIApiAIEnablerAPIGetSupportedProviders
     */
    readonly supportedInPlayground?: boolean
}

/**
 * Request parameters for aIEnablerAPIReportChatCompletions operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIReportChatCompletionsRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIReportChatCompletionsRequest {
    /**
     * 
     * @type {AioptimizerV1Data}
     * @memberof AIEnablerAPIApiAIEnablerAPIReportChatCompletions
     */
    readonly data: AioptimizerV1Data
}

/**
 * Request parameters for aIEnablerAPIUpdateCategorizedPrompt operation in AIEnablerAPIApi.
 * @export
 * @interface AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPromptRequest
 */
export interface AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPromptRequest {
    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPrompt
     */
    readonly categorizedPromptId: string

    /**
     * 
     * @type {'FEEDBACK_SCORE_UNSPECIFIED' | 'BAD' | 'GOOD'}
     * @memberof AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPrompt
     */
    readonly feedbackScore: AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum

    /**
     * 
     * @type {string}
     * @memberof AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPrompt
     */
    readonly feedback: string
}

/**
 * AIEnablerAPIApi - object-oriented interface
 * @export
 * @class AIEnablerAPIApi
 * @extends {BaseAPI}
 */
export class AIEnablerAPIApi extends BaseAPI {
    /**
     * 
     * @summary GetCategorizedPrompts returns a list of categorized prompts from the AI Enabler.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetCategorizedPrompts(options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetCategorizedPrompts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a report of chat completions usage data for a specific api key.
     * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetChatCompletionsAPIKeyUsageReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeyUsageReportRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetChatCompletionsAPIKeyUsageReport(requestParameters.apiKeyId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a recommendations report for the API keys used in chat completions.
     * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReportRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport(requestParameters.apiKeyId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a savings report for the API keys used in chat completions.
     * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsAPIKeysSavingsReportRequest = {}, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport(requestParameters.fromTime, requestParameters.toTime, requestParameters.categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a recommendations report for the category used in chat completions.
     * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryRecommendationsReportRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport(requestParameters.categoryId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a report of chat completions usage data for a specific category.
     * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetChatCompletionsCategoryUsageReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsCategoryUsageReportRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetChatCompletionsCategoryUsageReport(requestParameters.categoryId, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a recommendations report of chat completions data.
     * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsRecommendationsReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetChatCompletionsRecommendationsReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsRecommendationsReportRequest = {}, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetChatCompletionsRecommendationsReport(requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a report of chat completions usage data.
     * @param {AIEnablerAPIApiAIEnablerAPIGetChatCompletionsUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetChatCompletionsUsageReport(requestParameters: AIEnablerAPIApiAIEnablerAPIGetChatCompletionsUsageReportRequest = {}, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetChatCompletionsUsageReport(requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of models available for routing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetModels(options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetRoutingEvents returns a list of routing events.
     * @param {AIEnablerAPIApiAIEnablerAPIGetRoutingEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetRoutingEvents(requestParameters: AIEnablerAPIApiAIEnablerAPIGetRoutingEventsRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetRoutingEvents(requestParameters.promptId, requestParameters.fromTime, requestParameters.toTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of supported providers along with the supported models.
     * @param {AIEnablerAPIApiAIEnablerAPIGetSupportedProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIGetSupportedProviders(requestParameters: AIEnablerAPIApiAIEnablerAPIGetSupportedProvidersRequest = {}, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIGetSupportedProviders(requestParameters.supportedInPlayground, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ReportChatCompletions reports OpenAI chat completions data to the AI Enabler.
     * @param {AIEnablerAPIApiAIEnablerAPIReportChatCompletionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIReportChatCompletions(requestParameters: AIEnablerAPIApiAIEnablerAPIReportChatCompletionsRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIReportChatCompletions(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateCategorizedPrompt updates the categorized prompt.
     * @param {AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPromptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerAPIApi
     */
    public aIEnablerAPIUpdateCategorizedPrompt(requestParameters: AIEnablerAPIApiAIEnablerAPIUpdateCategorizedPromptRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerAPIApiFp(this.configuration).aIEnablerAPIUpdateCategorizedPrompt(requestParameters.categorizedPromptId, requestParameters.feedbackScore, requestParameters.feedback, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AIEnablerAPIGetRoutingEventsTypeEnum = {
    INVALID: 'EVENT_TYPE_INVALID',
    PROMPT_RECEIVED: 'EVENT_TYPE_PROMPT_RECEIVED',
    PROMPT_CATEGORIZED: 'EVENT_TYPE_PROMPT_CATEGORIZED',
    LOOKUP_PROVIDERS_AND_MODELS: 'EVENT_TYPE_LOOKUP_PROVIDERS_AND_MODELS',
    ORDER_PROVIDERS_AND_MODELS: 'EVENT_TYPE_ORDER_PROVIDERS_AND_MODELS',
    PICK_PROVIDER_AND_MODEL: 'EVENT_TYPE_PICK_PROVIDER_AND_MODEL',
    ROUTE_REQUEST: 'EVENT_TYPE_ROUTE_REQUEST'
} as const;
export type AIEnablerAPIGetRoutingEventsTypeEnum = typeof AIEnablerAPIGetRoutingEventsTypeEnum[keyof typeof AIEnablerAPIGetRoutingEventsTypeEnum];
/**
 * @export
 */
export const AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum = {
    FEEDBACK_SCORE_UNSPECIFIED: 'FEEDBACK_SCORE_UNSPECIFIED',
    BAD: 'BAD',
    GOOD: 'GOOD'
} as const;
export type AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum = typeof AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum[keyof typeof AIEnablerAPIUpdateCategorizedPromptFeedbackScoreEnum];
