import { mergeSx, Paper, PaperProps } from '@cast/design-system';

import { useCombinedAvailableSavings } from '../../hooks/useCombinedAvailableSavings';

export const ColoredPaper = ({ children, sx, ...rest }: PaperProps) => {
  const { isClusterOptimal } = useCombinedAvailableSavings();
  return (
    <Paper
      sx={mergeSx(
        isClusterOptimal
          ? {
              backgroundColor: 'blue.50',
              border: 'blue.200',
              boxShadow: 'dropdown',
            }
          : {
              backgroundColor: 'green.50',
              border: 'green.200',
              boxShadow: 'shadow',
            },
        sx
      )}
      {...rest}
    >
      {children}
    </Paper>
  );
};
