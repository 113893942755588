import { createContext, PropsWithChildren, useContext } from 'react';

import { AVERAGE_HOURS_IN_MONTH, HOURS_IN_WEEK } from '@cast/constants';

import { clusterEvents } from 'core/analytics';
import { useCluster } from 'hooks/useCluster';
import { usePersistentState } from 'hooks/usePersistentState';

import { PauseScheduleMode } from '../configuration-preferences/SchedulePreferences/types';

export type AvailableSavingsPauseScheduleState = {
  clusterScheduleEnabled: boolean;
  toggleClusterScheduleEnabled: () => void;
  clusterScheduleMode: PauseScheduleMode;
  changeClusterScheduleMode: (mode: PauseScheduleMode) => void;
  hoursRunning: number;
  setHoursRunning: (hours: number) => void;
  hoursRunningPerMonth: number;
  clusterScheduleSavings: number;
  clusterScheduleRunningPercentage: number;
};

const Context = createContext<AvailableSavingsPauseScheduleState>(
  undefined as never
);

const INITIAL_HOURS_RUNNING = 75;

export const PauseScheduleProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const { cluster } = useCluster();
  const [clusterScheduleEnabled, setClusterScheduleEnabled] =
    usePersistentState<boolean>(
      `available-savings.pause-schedule.enabled-${cluster.id}`,
      false
    );
  const toggleClusterScheduleEnabled = () => {
    setClusterScheduleEnabled(!clusterScheduleEnabled);
    clusterEvents.toggledUseClusterSchedule(cluster, !clusterScheduleEnabled);
  };
  const [clusterScheduleMode, changeClusterScheduleMode] =
    usePersistentState<PauseScheduleMode>(
      `available-savings.pause-schedule.mode-${cluster.id}`,
      PauseScheduleMode.BUSINESS_HOURS
    );
  const [hoursRunning, setHoursRunning] = usePersistentState<number>(
    `available-savings.pause-schedule.hours-running-${cluster.id}`,
    INITIAL_HOURS_RUNNING
  );
  const percentageOfWeek = (hoursRunning * 100) / HOURS_IN_WEEK;
  const clusterScheduleRunningPercentage = clusterScheduleEnabled
    ? (hoursRunning * 100) / HOURS_IN_WEEK
    : 100;
  const clusterScheduleSavings = clusterScheduleEnabled
    ? 100 - clusterScheduleRunningPercentage
    : 0;
  return (
    <Context.Provider
      value={{
        clusterScheduleEnabled,
        toggleClusterScheduleEnabled,
        clusterScheduleMode,
        changeClusterScheduleMode: (mode: PauseScheduleMode) => {
          changeClusterScheduleMode(mode);
          if (mode === PauseScheduleMode.BUSINESS_HOURS) {
            setHoursRunning(INITIAL_HOURS_RUNNING);
          }
        },
        hoursRunning: clusterScheduleEnabled ? hoursRunning : HOURS_IN_WEEK,
        setHoursRunning: (hours: number) =>
          setHoursRunning(Math.min(hours, HOURS_IN_WEEK)),
        hoursRunningPerMonth: clusterScheduleEnabled
          ? (AVERAGE_HOURS_IN_MONTH * percentageOfWeek) / 100
          : AVERAGE_HOURS_IN_MONTH,
        clusterScheduleSavings,
        clusterScheduleRunningPercentage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

/**
 * @returns calculations made from pause schedule view settings
 */
export const useAvailableSavingsPauseSchedule = () => {
  return useContext(Context);
};
