type ColumnConfig<T> = Partial<{
  [K in keyof T]: { header: string; map?: (val: T[K]) => string } | string;
}>;

type DescriptionItem = {
  name: string;
  description: string;
};

export const toCsv = <T>(
  cfg: ColumnConfig<T>,
  data: T[],
  description: DescriptionItem[] = []
) => {
  // Data
  const csv = data.map((entity: any) =>
    Object.entries<ColumnConfig<T>>(cfg as any)
      .map(([prop, value]) => {
        if (typeof value === 'string') {
          return `"${entity[prop]}"`;
        }
        return `"${(
          value as unknown as Record<'map', (str: string) => string>
        ).map(entity[prop])}"`;
      })
      .join(';')
  );

  // Header
  csv.unshift(
    Object.entries(cfg)
      .map(
        ([_, val]: [string, any]) =>
          `"${typeof val === 'string' ? val : val.header}"`
      )
      .join(';')
  );

  // Description
  if (description.length) {
    const paddingFromLeft =
      csv.length > 0
        ? Array(Object.keys(cfg).length).fill(';').join('') + ';'
        : '';
    description.forEach(({ name, description }: DescriptionItem, index) => {
      if (index === 0) {
        // Header
        if (csv[0]) {
          csv[0] += ';;name;description';
        } else {
          csv.push('name;description');
        }
      }
      if (csv[index + 1]) {
        csv[index + 1] += `;;"${name}";"${description}"`;
      } else {
        csv.push(`${paddingFromLeft}"${name}";"${description}"`);
      }
    });
  }

  return csv.join('\r\n');
};
