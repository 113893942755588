import { useQuery } from '@tanstack/react-query';

import { SecurityInsightsCheckExceptionsGroup } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type Params = {
  ruleId: string;
  groups: SecurityInsightsCheckExceptionsGroup[];
  enabled: boolean;
};

export const useBestPracticeCheckExceptionsResourcesQuery = ({
  ruleId,
  groups,
  enabled,
}: Params) => {
  const {
    isLoading,
    data: resources,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      QueryKeys.SECURITY_BEST_PRACTICES_CHECK_EXCEPTIONS_RESOURCES,
      ruleId,
      groups,
    ],
    queryFn: async () => {
      const { data } =
        await apiClient.securityInsights.getCheckExceptionsResources({
          ruleId,
          payload: {
            groups,
          },
        });
      return data.resources ?? [];
    },
    enabled,
  });

  return { isLoading, resources, error, refetch };
};
