import { CpuType } from '@cast/types';

export enum NodeType {
  ON_DEMAND = 'onDemand',
  SPOT = 'spot',
  FALLBACK = 'fallback',
}

export enum NormalizedCostMode {
  ON_DEMAND = CpuType.ON_DEMAND,
  FALLBACK = CpuType.FALLBACK,
  SPOT = CpuType.SPOT,
  NORMALIZED = CpuType.NORMALIZED,
}
