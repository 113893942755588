import { styled } from '@mui/material';

import { IconButton, IconButtonProps, iconButtonVars } from '../../../buttons';

type PaginatorIconButtonProps = Omit<IconButtonProps, 'size' | 'variant'> & {
  size: 'small' | 'medium';
};

export const PaginatorIconButton = styled(
  ({ children, ...rest }: PaginatorIconButtonProps) => {
    return (
      <IconButton {...rest} size="small" variant="ghost">
        {children}
      </IconButton>
    );
  }
)(({ theme, size }) => [
  {
    ...iconButtonVars.setValues({
      color: theme.palette.grey[600],
      colorActive: '#FFFFFF',
      colorDisabled: theme.palette.grey[300],
      background: 'unset',
      backgroundHover: theme.palette.grey[200],
      backgroundActive: theme.palette.grey[600],
      backgroundDisabled: 'unset',
      boxShadowHover: 'unset',
      boxShadowActive: 'unset',
      boxShadowDisabled: 'unset',
      spinnerColor: theme.palette.grey[600],
      spinnerBorderColor: theme.palette.grey[300],
    }),
  },
  size === 'medium' && {
    ...iconButtonVars.setValues({
      iconSize: '20px',
    }),
    minHeight: '28px',
    minWidth: '28px',
  },
  size === 'small' && {
    ...iconButtonVars.setValues({
      iconSize: '16px',
    }),
    minHeight: '24px',
    minWidth: '24px',
  },
]) as typeof IconButton;
