/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { RuntimeSecurityAPIAddListEntriesRequest } from '../models';
// @ts-ignore
import { RuntimeSecurityAPIEditRuleRequest } from '../models';
// @ts-ignore
import { RuntimeV1AckAnomaliesRequest } from '../models';
// @ts-ignore
import { RuntimeV1CloseAnomaliesRequest } from '../models';
// @ts-ignore
import { RuntimeV1CreateListRequest } from '../models';
// @ts-ignore
import { RuntimeV1CreateRuleRequest } from '../models';
// @ts-ignore
import { RuntimeV1DeleteListsRequest } from '../models';
// @ts-ignore
import { RuntimeV1DeleteRulesRequest } from '../models';
// @ts-ignore
import { RuntimeV1GetAnomaliesOverviewResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetAnomaliesResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetAnomalyEventsResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetAnomalyResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetClusterWorkloadsNetflowResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetListEntriesResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetListsResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetRuleResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetRulesResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetRuntimeEventGroupsResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetRuntimeEventsProcessTreeResponse } from '../models';
// @ts-ignore
import { RuntimeV1GetRuntimeEventsResponse } from '../models';
// @ts-ignore
import { RuntimeV1ListHeader } from '../models';
// @ts-ignore
import { RuntimeV1Rule } from '../models';
// @ts-ignore
import { RuntimeV1ToggleRulesRequest } from '../models';
// @ts-ignore
import { RuntimeV1ValidateRequest } from '../models';
// @ts-ignore
import { RuntimeV1ValidateResponse } from '../models';
/**
 * RuntimeSecurityAPIApi - axios parameter creator
 * @export
 */
export const RuntimeSecurityAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RuntimeV1AckAnomaliesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIAckAnomalies: async (body: RuntimeV1AckAnomaliesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPIAckAnomalies', 'body', body)
            const localVarPath = `/v1/security/runtime/anomalies/ack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RuntimeSecurityAPIAddListEntriesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIAddListEntries: async (id: string, body: RuntimeSecurityAPIAddListEntriesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPIAddListEntries', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPIAddListEntries', 'body', body)
            const localVarPath = `/v1/security/runtime/list/{id}/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RuntimeV1CloseAnomaliesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPICloseAnomalies: async (body: RuntimeV1CloseAnomaliesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPICloseAnomalies', 'body', body)
            const localVarPath = `/v1/security/runtime/anomalies/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RuntimeV1CreateListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPICreateList: async (body: RuntimeV1CreateListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPICreateList', 'body', body)
            const localVarPath = `/v1/security/runtime/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RuntimeV1CreateRuleRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPICreateRule: async (body: RuntimeV1CreateRuleRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPICreateRule', 'body', body)
            const localVarPath = `/v1/security/runtime/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RuntimeV1DeleteListsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIDeleteLists: async (body: RuntimeV1DeleteListsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPIDeleteLists', 'body', body)
            const localVarPath = `/v1/security/runtime/list/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RuntimeV1DeleteRulesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIDeleteRules: async (body: RuntimeV1DeleteRulesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPIDeleteRules', 'body', body)
            const localVarPath = `/v1/security/runtime/rules/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RuntimeSecurityAPIEditRuleRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIEditRule: async (id: string, body: RuntimeSecurityAPIEditRuleRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPIEditRule', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPIEditRule', 'body', body)
            const localVarPath = `/v1/security/runtime/rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [clusterIds] 
         * @param {Array<string>} [namespaces] 
         * @param {RuntimeSecurityAPIGetAnomaliesStatusEnum} [status] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetAnomaliesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {string} [resourceId] 
         * @param {Array<string>} [types] 
         * @param {string} [search] search is a plain-text search over all fields of the anomaly (rule name, cluster name, namespace, workload name or rule type).
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetAnomalies: async (clusterIds?: Array<string>, namespaces?: Array<string>, status?: RuntimeSecurityAPIGetAnomaliesStatusEnum, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetAnomaliesSortOrderEnum, resourceId?: string, types?: Array<string>, search?: string, startTime?: string, endTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/runtime/anomalies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (namespaces) {
                localVarQueryParameter['namespaces'] = namespaces;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }

            if (resourceId !== undefined) {
                localVarQueryParameter['resourceId'] = resourceId;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetAnomaliesOverview returns an overview of anomalies for all nodes and workloads.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetAnomaliesOverview: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/runtime/overview/anomalies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetAnomaly: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPIGetAnomaly', 'id', id)
            const localVarPath = `/v1/security/runtime/anomalies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetAnomalyEvents: async (id: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPIGetAnomalyEvents', 'id', id)
            const localVarPath = `/v1/security/runtime/anomalies/{id}/events`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clusterId 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetClusterWorkloadsNetflow: async (clusterId: string, startTime?: string, endTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('runtimeSecurityAPIGetClusterWorkloadsNetflow', 'clusterId', clusterId)
            const localVarPath = `/v1/security/runtime/workloads-netflow/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetList: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPIGetList', 'id', id)
            const localVarPath = `/v1/security/runtime/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [search] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetListEntriesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetListEntries: async (id: string, search?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetListEntriesSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPIGetListEntries', 'id', id)
            const localVarPath = `/v1/security/runtime/list/{id}/entries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetListsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetLists: async (search?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetListsSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/runtime/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRule: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPIGetRule', 'id', id)
            const localVarPath = `/v1/security/runtime/rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [enabled] 
         * @param {string} [category] 
         * @param {Array<RuntimeSecurityAPIGetRulesSeverityEnum>} [severity] 
         * @param {string} [search] search is a plain-text search over all fields of the rule (name, type, category).
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetRulesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRules: async (enabled?: boolean, category?: string, severity?: Array<RuntimeSecurityAPIGetRulesSeverityEnum>, search?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetRulesSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/runtime/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (severity) {
                localVarQueryParameter['severity'] = severity;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [clusterIds] 
         * @param {Array<string>} [types] 
         * @param {Array<string>} [groupBy] 
         * @param {string} [search] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRuntimeEventGroups: async (clusterIds?: Array<string>, types?: Array<string>, groupBy?: Array<string>, search?: string, startTime?: string, endTime?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/runtime/events/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (groupBy) {
                localVarQueryParameter['groupBy'] = groupBy;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [clusterIds] 
         * @param {Array<string>} [types] 
         * @param {Array<string>} [groupSelectors] 
         * @param {string} [search] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRuntimeEvents: async (clusterIds?: Array<string>, types?: Array<string>, groupSelectors?: Array<string>, search?: string, startTime?: string, endTime?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/security/runtime/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (groupSelectors) {
                localVarQueryParameter['groupSelectors'] = groupSelectors;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clusterId 
         * @param {string} [containerId] 
         * @param {number} [processPid] 
         * @param {string} [processStartTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRuntimeEventsProcessTree: async (clusterId: string, containerId?: string, processPid?: number, processStartTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('runtimeSecurityAPIGetRuntimeEventsProcessTree', 'clusterId', clusterId)
            const localVarPath = `/v1/security/runtime/events/process-tree/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (containerId !== undefined) {
                localVarQueryParameter['containerId'] = containerId;
            }

            if (processPid !== undefined) {
                localVarQueryParameter['processPid'] = processPid;
            }

            if (processStartTime !== undefined) {
                localVarQueryParameter['processStartTime'] = processStartTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RuntimeSecurityAPIAddListEntriesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIRemoveListEntries: async (id: string, body: RuntimeSecurityAPIAddListEntriesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPIRemoveListEntries', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPIRemoveListEntries', 'body', body)
            const localVarPath = `/v1/security/runtime/list/{id}/remove`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RuntimeV1ToggleRulesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIToggleRules: async (body: RuntimeV1ToggleRulesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPIToggleRules', 'body', body)
            const localVarPath = `/v1/security/runtime/rules/toggle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPITriggerAnomalyWebhook: async (id: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runtimeSecurityAPITriggerAnomalyWebhook', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPITriggerAnomalyWebhook', 'body', body)
            const localVarPath = `/v1/security/runtime/anomalies/{id}/trigger-webhook`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RuntimeV1ValidateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIValidate: async (body: RuntimeV1ValidateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('runtimeSecurityAPIValidate', 'body', body)
            const localVarPath = `/v1/security/runtime/rules/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RuntimeSecurityAPIApi - functional programming interface
 * @export
 */
export const RuntimeSecurityAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RuntimeSecurityAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RuntimeV1AckAnomaliesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIAckAnomalies(body: RuntimeV1AckAnomaliesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIAckAnomalies(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIAckAnomalies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {RuntimeSecurityAPIAddListEntriesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIAddListEntries(id: string, body: RuntimeSecurityAPIAddListEntriesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIAddListEntries(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIAddListEntries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RuntimeV1CloseAnomaliesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPICloseAnomalies(body: RuntimeV1CloseAnomaliesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPICloseAnomalies(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPICloseAnomalies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RuntimeV1CreateListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPICreateList(body: RuntimeV1CreateListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1ListHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPICreateList(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPICreateList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RuntimeV1CreateRuleRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPICreateRule(body: RuntimeV1CreateRuleRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1Rule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPICreateRule(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPICreateRule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RuntimeV1DeleteListsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIDeleteLists(body: RuntimeV1DeleteListsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIDeleteLists(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIDeleteLists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RuntimeV1DeleteRulesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIDeleteRules(body: RuntimeV1DeleteRulesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIDeleteRules(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIDeleteRules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {RuntimeSecurityAPIEditRuleRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIEditRule(id: string, body: RuntimeSecurityAPIEditRuleRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1Rule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIEditRule(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIEditRule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [clusterIds] 
         * @param {Array<string>} [namespaces] 
         * @param {RuntimeSecurityAPIGetAnomaliesStatusEnum} [status] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetAnomaliesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {string} [resourceId] 
         * @param {Array<string>} [types] 
         * @param {string} [search] search is a plain-text search over all fields of the anomaly (rule name, cluster name, namespace, workload name or rule type).
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetAnomalies(clusterIds?: Array<string>, namespaces?: Array<string>, status?: RuntimeSecurityAPIGetAnomaliesStatusEnum, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetAnomaliesSortOrderEnum, resourceId?: string, types?: Array<string>, search?: string, startTime?: string, endTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetAnomaliesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetAnomalies(clusterIds, namespaces, status, pageLimit, pageCursor, sortField, sortOrder, resourceId, types, search, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetAnomalies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetAnomaliesOverview returns an overview of anomalies for all nodes and workloads.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetAnomaliesOverview(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetAnomaliesOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetAnomaliesOverview(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetAnomaliesOverview']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetAnomaly(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetAnomalyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetAnomaly(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetAnomaly']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetAnomalyEvents(id: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetAnomalyEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetAnomalyEvents(id, pageLimit, pageCursor, sortField, sortOrder, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetAnomalyEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clusterId 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetClusterWorkloadsNetflow(clusterId: string, startTime?: string, endTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetClusterWorkloadsNetflowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetClusterWorkloadsNetflow(clusterId, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetClusterWorkloadsNetflow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetList(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1ListHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetList(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [search] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetListEntriesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetListEntries(id: string, search?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetListEntriesSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetListEntriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetListEntries(id, search, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetListEntries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetListsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetLists(search?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetListsSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetListsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetLists(search, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetLists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetRule(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetRule(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetRule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [enabled] 
         * @param {string} [category] 
         * @param {Array<RuntimeSecurityAPIGetRulesSeverityEnum>} [severity] 
         * @param {string} [search] search is a plain-text search over all fields of the rule (name, type, category).
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetRulesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetRules(enabled?: boolean, category?: string, severity?: Array<RuntimeSecurityAPIGetRulesSeverityEnum>, search?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetRulesSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetRulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetRules(enabled, category, severity, search, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetRules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [clusterIds] 
         * @param {Array<string>} [types] 
         * @param {Array<string>} [groupBy] 
         * @param {string} [search] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetRuntimeEventGroups(clusterIds?: Array<string>, types?: Array<string>, groupBy?: Array<string>, search?: string, startTime?: string, endTime?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetRuntimeEventGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetRuntimeEventGroups(clusterIds, types, groupBy, search, startTime, endTime, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetRuntimeEventGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [clusterIds] 
         * @param {Array<string>} [types] 
         * @param {Array<string>} [groupSelectors] 
         * @param {string} [search] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetRuntimeEvents(clusterIds?: Array<string>, types?: Array<string>, groupSelectors?: Array<string>, search?: string, startTime?: string, endTime?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetRuntimeEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetRuntimeEvents(clusterIds, types, groupSelectors, search, startTime, endTime, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetRuntimeEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} clusterId 
         * @param {string} [containerId] 
         * @param {number} [processPid] 
         * @param {string} [processStartTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIGetRuntimeEventsProcessTree(clusterId: string, containerId?: string, processPid?: number, processStartTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1GetRuntimeEventsProcessTreeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIGetRuntimeEventsProcessTree(clusterId, containerId, processPid, processStartTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIGetRuntimeEventsProcessTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {RuntimeSecurityAPIAddListEntriesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIRemoveListEntries(id: string, body: RuntimeSecurityAPIAddListEntriesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIRemoveListEntries(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIRemoveListEntries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RuntimeV1ToggleRulesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIToggleRules(body: RuntimeV1ToggleRulesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIToggleRules(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIToggleRules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPITriggerAnomalyWebhook(id: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPITriggerAnomalyWebhook(id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPITriggerAnomalyWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RuntimeV1ValidateRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runtimeSecurityAPIValidate(body: RuntimeV1ValidateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuntimeV1ValidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runtimeSecurityAPIValidate(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RuntimeSecurityAPIApi.runtimeSecurityAPIValidate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RuntimeSecurityAPIApi - factory interface
 * @export
 */
export const RuntimeSecurityAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RuntimeSecurityAPIApiFp(configuration)
    return {
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIAckAnomaliesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIAckAnomalies(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIAckAnomaliesRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runtimeSecurityAPIAckAnomalies(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIAddListEntriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIAddListEntries(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIAddListEntriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runtimeSecurityAPIAddListEntries(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPICloseAnomaliesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPICloseAnomalies(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPICloseAnomaliesRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runtimeSecurityAPICloseAnomalies(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPICreateListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPICreateList(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPICreateListRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1ListHeader> {
            return localVarFp.runtimeSecurityAPICreateList(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPICreateRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPICreateRule(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPICreateRuleRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1Rule> {
            return localVarFp.runtimeSecurityAPICreateRule(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteListsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIDeleteLists(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteListsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runtimeSecurityAPIDeleteLists(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteRulesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIDeleteRules(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteRulesRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runtimeSecurityAPIDeleteRules(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIEditRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIEditRule(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIEditRuleRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1Rule> {
            return localVarFp.runtimeSecurityAPIEditRule(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomaliesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetAnomalies(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomaliesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetAnomaliesResponse> {
            return localVarFp.runtimeSecurityAPIGetAnomalies(requestParameters.clusterIds, requestParameters.namespaces, requestParameters.status, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, requestParameters.resourceId, requestParameters.types, requestParameters.search, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetAnomaliesOverview returns an overview of anomalies for all nodes and workloads.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetAnomaliesOverview(options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetAnomaliesOverviewResponse> {
            return localVarFp.runtimeSecurityAPIGetAnomaliesOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetAnomaly(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetAnomalyResponse> {
            return localVarFp.runtimeSecurityAPIGetAnomaly(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetAnomalyEvents(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEventsRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetAnomalyEventsResponse> {
            return localVarFp.runtimeSecurityAPIGetAnomalyEvents(requestParameters.id, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflowRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetClusterWorkloadsNetflow(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflowRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetClusterWorkloadsNetflowResponse> {
            return localVarFp.runtimeSecurityAPIGetClusterWorkloadsNetflow(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetList(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetListRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1ListHeader> {
            return localVarFp.runtimeSecurityAPIGetList(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetListEntries(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetListEntriesResponse> {
            return localVarFp.runtimeSecurityAPIGetListEntries(requestParameters.id, requestParameters.search, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetListsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetLists(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetListsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetListsResponse> {
            return localVarFp.runtimeSecurityAPIGetLists(requestParameters.search, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRule(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuleRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetRuleResponse> {
            return localVarFp.runtimeSecurityAPIGetRule(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRulesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRules(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRulesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetRulesResponse> {
            return localVarFp.runtimeSecurityAPIGetRules(requestParameters.enabled, requestParameters.category, requestParameters.severity, requestParameters.search, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRuntimeEventGroups(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroupsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetRuntimeEventGroupsResponse> {
            return localVarFp.runtimeSecurityAPIGetRuntimeEventGroups(requestParameters.clusterIds, requestParameters.types, requestParameters.groupBy, requestParameters.search, requestParameters.startTime, requestParameters.endTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRuntimeEvents(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetRuntimeEventsResponse> {
            return localVarFp.runtimeSecurityAPIGetRuntimeEvents(requestParameters.clusterIds, requestParameters.types, requestParameters.groupSelectors, requestParameters.search, requestParameters.startTime, requestParameters.endTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTreeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIGetRuntimeEventsProcessTree(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTreeRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1GetRuntimeEventsProcessTreeResponse> {
            return localVarFp.runtimeSecurityAPIGetRuntimeEventsProcessTree(requestParameters.clusterId, requestParameters.containerId, requestParameters.processPid, requestParameters.processStartTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIRemoveListEntriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIRemoveListEntries(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIRemoveListEntriesRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runtimeSecurityAPIRemoveListEntries(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIToggleRulesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIToggleRules(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIToggleRulesRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runtimeSecurityAPIToggleRules(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPITriggerAnomalyWebhookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPITriggerAnomalyWebhook(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPITriggerAnomalyWebhookRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runtimeSecurityAPITriggerAnomalyWebhook(requestParameters.id, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIValidateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runtimeSecurityAPIValidate(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIValidateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RuntimeV1ValidateResponse> {
            return localVarFp.runtimeSecurityAPIValidate(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for runtimeSecurityAPIAckAnomalies operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIAckAnomaliesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIAckAnomaliesRequest {
    /**
     * 
     * @type {RuntimeV1AckAnomaliesRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIAckAnomalies
     */
    readonly body: RuntimeV1AckAnomaliesRequest
}

/**
 * Request parameters for runtimeSecurityAPIAddListEntries operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIAddListEntriesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIAddListEntriesRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIAddListEntries
     */
    readonly id: string

    /**
     * 
     * @type {RuntimeSecurityAPIAddListEntriesRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIAddListEntries
     */
    readonly body: RuntimeSecurityAPIAddListEntriesRequest
}

/**
 * Request parameters for runtimeSecurityAPICloseAnomalies operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPICloseAnomaliesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPICloseAnomaliesRequest {
    /**
     * 
     * @type {RuntimeV1CloseAnomaliesRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPICloseAnomalies
     */
    readonly body: RuntimeV1CloseAnomaliesRequest
}

/**
 * Request parameters for runtimeSecurityAPICreateList operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPICreateListRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPICreateListRequest {
    /**
     * 
     * @type {RuntimeV1CreateListRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPICreateList
     */
    readonly body: RuntimeV1CreateListRequest
}

/**
 * Request parameters for runtimeSecurityAPICreateRule operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPICreateRuleRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPICreateRuleRequest {
    /**
     * 
     * @type {RuntimeV1CreateRuleRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPICreateRule
     */
    readonly body: RuntimeV1CreateRuleRequest
}

/**
 * Request parameters for runtimeSecurityAPIDeleteLists operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteListsRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteListsRequest {
    /**
     * 
     * @type {RuntimeV1DeleteListsRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteLists
     */
    readonly body: RuntimeV1DeleteListsRequest
}

/**
 * Request parameters for runtimeSecurityAPIDeleteRules operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteRulesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteRulesRequest {
    /**
     * 
     * @type {RuntimeV1DeleteRulesRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteRules
     */
    readonly body: RuntimeV1DeleteRulesRequest
}

/**
 * Request parameters for runtimeSecurityAPIEditRule operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIEditRuleRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIEditRuleRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIEditRule
     */
    readonly id: string

    /**
     * 
     * @type {RuntimeSecurityAPIEditRuleRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIEditRule
     */
    readonly body: RuntimeSecurityAPIEditRuleRequest
}

/**
 * Request parameters for runtimeSecurityAPIGetAnomalies operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomaliesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomaliesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly clusterIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly namespaces?: Array<string>

    /**
     * 
     * @type {'ANOMALY_STATUS_FILTER_UNSPECIFIED' | 'ANOMALY_STATUS_FILTER_OPEN' | 'ANOMALY_STATUS_FILTER_UNACKED' | 'ANOMALY_STATUS_FILTER_CLOSED'}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly status?: RuntimeSecurityAPIGetAnomaliesStatusEnum

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly sortOrder?: RuntimeSecurityAPIGetAnomaliesSortOrderEnum

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly resourceId?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly types?: Array<string>

    /**
     * search is a plain-text search over all fields of the anomaly (rule name, cluster name, namespace, workload name or rule type).
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly startTime?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalies
     */
    readonly endTime?: string
}

/**
 * Request parameters for runtimeSecurityAPIGetAnomaly operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomaly
     */
    readonly id: string
}

/**
 * Request parameters for runtimeSecurityAPIGetAnomalyEvents operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEventsRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEvents
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEvents
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEvents
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEvents
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEvents
     */
    readonly sortOrder?: RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEvents
     */
    readonly search?: string
}

/**
 * Request parameters for runtimeSecurityAPIGetClusterWorkloadsNetflow operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflowRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflowRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflow
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflow
     */
    readonly startTime?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflow
     */
    readonly endTime?: string
}

/**
 * Request parameters for runtimeSecurityAPIGetList operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetListRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetListRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetList
     */
    readonly id: string
}

/**
 * Request parameters for runtimeSecurityAPIGetListEntries operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntriesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntriesRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntries
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntries
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntries
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntries
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntries
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntries
     */
    readonly sortOrder?: RuntimeSecurityAPIGetListEntriesSortOrderEnum
}

/**
 * Request parameters for runtimeSecurityAPIGetLists operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetListsRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetListsRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetLists
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetLists
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetLists
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetLists
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetLists
     */
    readonly sortOrder?: RuntimeSecurityAPIGetListsSortOrderEnum
}

/**
 * Request parameters for runtimeSecurityAPIGetRule operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuleRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuleRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRule
     */
    readonly id: string
}

/**
 * Request parameters for runtimeSecurityAPIGetRules operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRulesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRulesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRules
     */
    readonly enabled?: boolean

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRules
     */
    readonly category?: string

    /**
     * 
     * @type {Array<'SEVERITY_UNKNOWN' | 'SEVERITY_NONE' | 'SEVERITY_LOW' | 'SEVERITY_MEDIUM' | 'SEVERITY_HIGH' | 'SEVERITY_CRITICAL'>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRules
     */
    readonly severity?: Array<RuntimeSecurityAPIGetRulesSeverityEnum>

    /**
     * search is a plain-text search over all fields of the rule (name, type, category).
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRules
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRules
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRules
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRules
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRules
     */
    readonly sortOrder?: RuntimeSecurityAPIGetRulesSortOrderEnum
}

/**
 * Request parameters for runtimeSecurityAPIGetRuntimeEventGroups operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroupsRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroupsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly clusterIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly types?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly groupBy?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly startTime?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly endTime?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroups
     */
    readonly sortOrder?: RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum
}

/**
 * Request parameters for runtimeSecurityAPIGetRuntimeEvents operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly clusterIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly types?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly groupSelectors?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly startTime?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly endTime?: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEvents
     */
    readonly sortOrder?: RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum
}

/**
 * Request parameters for runtimeSecurityAPIGetRuntimeEventsProcessTree operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTreeRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTreeRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTree
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTree
     */
    readonly containerId?: string

    /**
     * 
     * @type {number}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTree
     */
    readonly processPid?: number

    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTree
     */
    readonly processStartTime?: string
}

/**
 * Request parameters for runtimeSecurityAPIRemoveListEntries operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIRemoveListEntriesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIRemoveListEntriesRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIRemoveListEntries
     */
    readonly id: string

    /**
     * 
     * @type {RuntimeSecurityAPIAddListEntriesRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIRemoveListEntries
     */
    readonly body: RuntimeSecurityAPIAddListEntriesRequest
}

/**
 * Request parameters for runtimeSecurityAPIToggleRules operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIToggleRulesRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIToggleRulesRequest {
    /**
     * 
     * @type {RuntimeV1ToggleRulesRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIToggleRules
     */
    readonly body: RuntimeV1ToggleRulesRequest
}

/**
 * Request parameters for runtimeSecurityAPITriggerAnomalyWebhook operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPITriggerAnomalyWebhookRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPITriggerAnomalyWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPITriggerAnomalyWebhook
     */
    readonly id: string

    /**
     * 
     * @type {object}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPITriggerAnomalyWebhook
     */
    readonly body: object
}

/**
 * Request parameters for runtimeSecurityAPIValidate operation in RuntimeSecurityAPIApi.
 * @export
 * @interface RuntimeSecurityAPIApiRuntimeSecurityAPIValidateRequest
 */
export interface RuntimeSecurityAPIApiRuntimeSecurityAPIValidateRequest {
    /**
     * 
     * @type {RuntimeV1ValidateRequest}
     * @memberof RuntimeSecurityAPIApiRuntimeSecurityAPIValidate
     */
    readonly body: RuntimeV1ValidateRequest
}

/**
 * RuntimeSecurityAPIApi - object-oriented interface
 * @export
 * @class RuntimeSecurityAPIApi
 * @extends {BaseAPI}
 */
export class RuntimeSecurityAPIApi extends BaseAPI {
    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIAckAnomaliesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIAckAnomalies(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIAckAnomaliesRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIAckAnomalies(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIAddListEntriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIAddListEntries(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIAddListEntriesRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIAddListEntries(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPICloseAnomaliesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPICloseAnomalies(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPICloseAnomaliesRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPICloseAnomalies(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPICreateListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPICreateList(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPICreateListRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPICreateList(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPICreateRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPICreateRule(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPICreateRuleRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPICreateRule(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteListsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIDeleteLists(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteListsRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIDeleteLists(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIDeleteRules(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIDeleteRulesRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIDeleteRules(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIEditRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIEditRule(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIEditRuleRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIEditRule(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomaliesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetAnomalies(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomaliesRequest = {}, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetAnomalies(requestParameters.clusterIds, requestParameters.namespaces, requestParameters.status, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, requestParameters.resourceId, requestParameters.types, requestParameters.search, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetAnomaliesOverview returns an overview of anomalies for all nodes and workloads.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetAnomaliesOverview(options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetAnomaliesOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetAnomaly(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetAnomaly(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetAnomalyEvents(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetAnomalyEventsRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetAnomalyEvents(requestParameters.id, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetClusterWorkloadsNetflow(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetClusterWorkloadsNetflowRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetClusterWorkloadsNetflow(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetList(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetListRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetList(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetListEntries(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetListEntriesRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetListEntries(requestParameters.id, requestParameters.search, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetListsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetLists(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetListsRequest = {}, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetLists(requestParameters.search, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetRule(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuleRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetRule(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetRules(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRulesRequest = {}, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetRules(requestParameters.enabled, requestParameters.category, requestParameters.severity, requestParameters.search, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetRuntimeEventGroups(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventGroupsRequest = {}, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetRuntimeEventGroups(requestParameters.clusterIds, requestParameters.types, requestParameters.groupBy, requestParameters.search, requestParameters.startTime, requestParameters.endTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetRuntimeEvents(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsRequest = {}, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetRuntimeEvents(requestParameters.clusterIds, requestParameters.types, requestParameters.groupSelectors, requestParameters.search, requestParameters.startTime, requestParameters.endTime, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTreeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIGetRuntimeEventsProcessTree(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIGetRuntimeEventsProcessTreeRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIGetRuntimeEventsProcessTree(requestParameters.clusterId, requestParameters.containerId, requestParameters.processPid, requestParameters.processStartTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIRemoveListEntriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIRemoveListEntries(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIRemoveListEntriesRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIRemoveListEntries(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIToggleRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIToggleRules(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIToggleRulesRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIToggleRules(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPITriggerAnomalyWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPITriggerAnomalyWebhook(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPITriggerAnomalyWebhookRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPITriggerAnomalyWebhook(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RuntimeSecurityAPIApiRuntimeSecurityAPIValidateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuntimeSecurityAPIApi
     */
    public runtimeSecurityAPIValidate(requestParameters: RuntimeSecurityAPIApiRuntimeSecurityAPIValidateRequest, options?: RawAxiosRequestConfig) {
        return RuntimeSecurityAPIApiFp(this.configuration).runtimeSecurityAPIValidate(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const RuntimeSecurityAPIGetAnomaliesStatusEnum = {
    UNSPECIFIED: 'ANOMALY_STATUS_FILTER_UNSPECIFIED',
    OPEN: 'ANOMALY_STATUS_FILTER_OPEN',
    UNACKED: 'ANOMALY_STATUS_FILTER_UNACKED',
    CLOSED: 'ANOMALY_STATUS_FILTER_CLOSED'
} as const;
export type RuntimeSecurityAPIGetAnomaliesStatusEnum = typeof RuntimeSecurityAPIGetAnomaliesStatusEnum[keyof typeof RuntimeSecurityAPIGetAnomaliesStatusEnum];
/**
 * @export
 */
export const RuntimeSecurityAPIGetAnomaliesSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type RuntimeSecurityAPIGetAnomaliesSortOrderEnum = typeof RuntimeSecurityAPIGetAnomaliesSortOrderEnum[keyof typeof RuntimeSecurityAPIGetAnomaliesSortOrderEnum];
/**
 * @export
 */
export const RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum = typeof RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum[keyof typeof RuntimeSecurityAPIGetAnomalyEventsSortOrderEnum];
/**
 * @export
 */
export const RuntimeSecurityAPIGetListEntriesSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type RuntimeSecurityAPIGetListEntriesSortOrderEnum = typeof RuntimeSecurityAPIGetListEntriesSortOrderEnum[keyof typeof RuntimeSecurityAPIGetListEntriesSortOrderEnum];
/**
 * @export
 */
export const RuntimeSecurityAPIGetListsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type RuntimeSecurityAPIGetListsSortOrderEnum = typeof RuntimeSecurityAPIGetListsSortOrderEnum[keyof typeof RuntimeSecurityAPIGetListsSortOrderEnum];
/**
 * @export
 */
export const RuntimeSecurityAPIGetRulesSeverityEnum = {
    UNKNOWN: 'SEVERITY_UNKNOWN',
    NONE: 'SEVERITY_NONE',
    LOW: 'SEVERITY_LOW',
    MEDIUM: 'SEVERITY_MEDIUM',
    HIGH: 'SEVERITY_HIGH',
    CRITICAL: 'SEVERITY_CRITICAL'
} as const;
export type RuntimeSecurityAPIGetRulesSeverityEnum = typeof RuntimeSecurityAPIGetRulesSeverityEnum[keyof typeof RuntimeSecurityAPIGetRulesSeverityEnum];
/**
 * @export
 */
export const RuntimeSecurityAPIGetRulesSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type RuntimeSecurityAPIGetRulesSortOrderEnum = typeof RuntimeSecurityAPIGetRulesSortOrderEnum[keyof typeof RuntimeSecurityAPIGetRulesSortOrderEnum];
/**
 * @export
 */
export const RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum = typeof RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum[keyof typeof RuntimeSecurityAPIGetRuntimeEventGroupsSortOrderEnum];
/**
 * @export
 */
export const RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum = typeof RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum[keyof typeof RuntimeSecurityAPIGetRuntimeEventsSortOrderEnum];
