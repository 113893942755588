import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { fromThemeOrDefault } from 'utils/theme';

export const useThemeValue = (prefix: keyof Theme, path?: string) => {
  const theme = useTheme();
  if (!path) {
    return path;
  }
  return fromThemeOrDefault(theme[prefix], path);
};
