import { Children, Fragment, isValidElement, ReactNode } from 'react';

import { SortBy } from '@cast/utils';

import { Column } from '../../Column';
import { ColumnGroup } from '../../ColumnGroup';
import { ColumnProps, GroupedColumnProps } from '../../types';

export const applySecondarySort = <T = any>(
  sortBy?: SortBy<T>,
  secondarySort?: SortBy<T> | SortBy<T>[]
): SortBy<T> | SortBy<T>[] => {
  const result: SortBy<T>[] = [];
  if (sortBy) {
    result.push(sortBy);
  }
  if (secondarySort) {
    if (Array.isArray(secondarySort)) {
      result.push(...secondarySort);
    } else {
      result.push(secondarySort);
    }
  }
  if (result.length === 1) {
    return result[0];
  }
  return result;
};

const getColumnProps = (children: ReactNode): GroupedColumnProps[] => {
  const columnProps: GroupedColumnProps[] = [];
  Children.forEach(children, (c) => {
    if (isValidElement(c)) {
      if (c.type === Column) {
        columnProps.push(c.props);
      } else if (c.type === ColumnGroup) {
        const { children, ...groupProps } = c.props;
        const columnPropsOfGroup = getColumnProps(children) as ColumnProps[];
        columnProps.push({
          ...groupProps,
          columns: columnPropsOfGroup,
        });
      } else if (c.type === Fragment) {
        const { children } = c.props;
        columnProps.push(...getColumnProps(children));
      }
    }
  });
  return columnProps;
};

export const parseColumnProps = (children: ReactNode): GroupedColumnProps[] => {
  let hasNonFrozenColumns = false;
  let hasFloatingColumns = false;
  const result = getColumnProps(children).map((props) => {
    if (props.frozen) {
      if (hasNonFrozenColumns) {
        throw new Error('Frozen columns has to be defined first');
      }
    } else {
      hasNonFrozenColumns = true;
    }
    if (props.excludeFromSettings && !props.frozen) {
      throw new Error('Only frozen columns can be excluded from settings');
    }
    if (props.floating) {
      if (hasFloatingColumns) {
        throw new Error('Only single floating column is supported');
      }
      hasFloatingColumns = true;
    }
    return props;
  });
  if (hasFloatingColumns && !result[result.length - 1].floating) {
    throw new Error('Floating column must be last');
  }
  return result;
};
