import { ThemeOptions } from '@mui/material/styles/createTheme';

import { activeProps } from '../../../theme/utils';
import { cssVars } from '../../../utils/cssVars';

const { getters, setValues } = cssVars(
  {
    height: '40px',
    horizontalPadding: '10px',
    adornmentSpacing: '8px',
    fontSize: '14px',
    lineHeight: '22px',
  },
  'ds-input'
);

export const inputGetters = getters;

export const inputTheme: ThemeOptions['components'] = {
  DsInput: {
    styleOverrides: {
      root: ({ theme }) => {
        return {
          display: 'flex',
          flexDirection: 'column',
          ...theme.typography.P16R,

          '.MuiInputLabel': {
            lineHeight: '18px',
            '&-sizeSmall': {
              lineHeight: '16px',
            },
          },

          '& .MuiFilledInput-root': {
            height: getters.height(),
            paddingLeft: getters.horizontalPadding(),
            paddingRight: getters.horizontalPadding(),
            '&.MuiInputBase-formControl': {
              backgroundColor: 'white',
              border: `1px solid ${theme.palette.grey[200]}`,
              borderRadius: '4px',
              '&:after, &:before': {
                display: 'none',
              },
              '&.DS-Input-readOnly': {
                backgroundColor: theme.palette.grey[100],
                '& .MuiInputBase-input': {
                  color: theme.palette.grey[500],
                },
                '&.Mui-focused': {
                  boxShadow: 'none',
                },
              },
            },
            '&:hover': {
              borderColor: theme.palette.grey[300],
            },
            '&.Mui-error': {
              borderColor: theme.palette.red[500],
            },
            '&.DS-success': {
              borderColor: theme.palette.green[500],
            },
            '&.Mui-focused': {
              ...activeProps(theme),
            },
            '&.DS-Input-readOnly': {
              borderColor: theme.palette.grey[200],
            },
            '&.Mui-disabled': {
              color: theme.palette.grey[300],
              borderColor: theme.palette.grey[200],
              '& .DS-Input-adornment-root': {
                color: 'inherit',
              },
            },
          },

          '&.DS-Input-readOnly': {
            '& .DS-InputLabel-root': {
              color: theme.palette.grey[500],
            },
          },

          '& .MuiInputBase-input': {
            margin: 0,
            padding: 0,
            fontSize: getters.fontSize(),
            lineHeight: getters.lineHeight(),
            '&::placeholder': {
              color: theme.palette.grey[300],
              opacity: 'unset',
            },
            '&:focus::placeholder': {
              color: theme.palette.grey[200],
            },
          },

          '& .DS-Input-adornment-root': {
            fontSize: getters.fontSize(),
            lineHeight: getters.lineHeight(),
            color: theme.palette.grey[400],
            height: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            '& svg': {
              color: 'inherit',
              fontSize: '1.2em',
            },
            '&.Mui-disabled': {
              color: theme.palette.grey[300],
            },
          },
          '& .DS-Input-startAdornment-root': {
            paddingRight: getters.adornmentSpacing(),
          },
          '& .DS-Input-endAdornment-root': {
            paddingLeft: getters.adornmentSpacing(),
          },
          '& .MuiInputLabel-root': {
            position: 'relative',
            transform: 'unset',
          },

          '& .MuiInputBase-input[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
          },
        };
      },
      small: setValues({
        height: '28px',
        horizontalPadding: '8px',
        adornmentSpacing: '4px',
        fontSize: '12px',
        lineHeight: '18px',
      }),
      medium: setValues({
        height: '32px',
        horizontalPadding: '10px',
        adornmentSpacing: '8px',
        fontSize: '14px',
        lineHeight: '22px',
      }),
      large: setValues({
        height: '40px',
        horizontalPadding: '12px',
        adornmentSpacing: '8px',
        fontSize: '14px',
        lineHeight: '22px',
      }),
    },
  },
};
