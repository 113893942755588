import { applyOrdering } from './applyOrdering';
import { ColumnSettings, GroupedColumnProps, isColumnsGroup } from '../types';

export type ApplyColumnSettingsArgs = {
  groupedColumnsProps: GroupedColumnProps[];
  /**
   * should invisible columns move to bottom and locked columns move to the top
   */
  reorder?: boolean;
} & Omit<ColumnSettings, 'resizedColumns'>;

export const applyColumnSettings = ({
  groupedColumnsProps,
  columnsOrder,
  hiddenColumns,
  lockedColumns,
  reorder = true,
}: ApplyColumnSettingsArgs): GroupedColumnProps[] => {
  const orderedColumnsProps = applyOrdering({
    groupedColumnsProps,
    columnsOrder,
  });
  const isVisible = (props: GroupedColumnProps) =>
    props.id in hiddenColumns
      ? !hiddenColumns[props.id]
      : props.visible ?? true;
  let hasLocked = false;
  const res = orderedColumnsProps.map((props) => {
    if (isColumnsGroup(props)) {
      const columns = props.columns.map((col) => ({
        ...col,
        visible: isVisible(col),
      }));
      const locked = lockedColumns[props.id] ?? !!props.locked;
      if (locked) {
        hasLocked = true;
      }
      return {
        ...props,
        visible: columns.some((col) => col.visible),
        locked,
        columns,
      };
    }
    const locked = lockedColumns[props.id] ?? !!props.locked;
    if (locked) {
      hasLocked = true;
    }
    return {
      ...props,
      visible: isVisible(props),
      locked,
    };
  });
  if (hasLocked) {
    res.forEach((col) => {
      if (col.frozen) {
        col.locked = true;
      }
    });
  }
  if (!reorder) {
    return res;
  }
  const locked: GroupedColumnProps[] = [];
  const normal: GroupedColumnProps[] = [];
  const hidden: GroupedColumnProps[] = [];
  for (const col of res) {
    if (col.locked) {
      locked.push(col);
    } else if (!col.visible) {
      hidden.push(col);
    } else {
      normal.push(col);
    }
  }
  return [...locked, ...normal, ...hidden];
};
