import { Fragment, ReactNode, useState } from 'react';

import { Popover, styled } from '@mui/material';
import { SxProps } from '@mui/system';

import {
  ColumnModel,
  ColumnProps,
  Icons,
  List,
  ListItem,
  SortDirection,
  TableApi,
  TableComponents,
} from '@cast/design-system';

type SortBy = {
  name: ReactNode | [ReactNode, ReactNode];
  accessor: Required<ColumnProps>['accessor'];
  order?: SortDirection[];
  testId?: string;
  buttonSx?: SxProps;
  namePrefixMap?: typeof namePrefixMapDefault;
};

type Props = {
  api: TableApi;
  columnModel: ColumnModel;
  SortIcon: Required<TableComponents>['SortIcon'];
  className?: string;
};

const StyledListItemButton = styled(ListItem)(({ theme }) => ({
  ...(theme as any).typography.P12R,
  color: theme.palette.grey[700],
  height: 32,
}));

const SortIcon = styled(Icons.FunnelSimple, {
  target: 'Ds-Table-HeaderIcon-root',
})(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.grey[400],
  cursor: 'pointer',
}));

const namePrefixMapDefault: Record<SortDirection, string> = {
  desc: 'high to low',
  asc: 'low to high',
};

type MakeMultiSorterProps = {
  sortBy: SortBy[];
};

export const makeMultiSorter = ({ sortBy }: MakeMultiSorterProps) => {
  const MultiSorter = ({ api, columnModel }: Props) => {
    const { id } = columnModel;
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const isOpen = !!anchorEl;

    if (!columnModel.sortable) {
      return null;
    }
    const sortingState =
      api.state.sortingState?.columnId === id
        ? api.state.sortingState
        : undefined;
    const currentAccessor = sortingState?.sortBy.accessor;
    const currentSortDirection = sortingState?.sortBy.direction;

    return (
      <>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          disableScrollLock
        >
          <div data-testid={`multi-sort-options-${columnModel.id}`}>
            <List>
              {sortBy.map(
                (
                  {
                    name,
                    accessor,
                    testId = name,
                    buttonSx,
                    order = ['desc', 'asc'],
                    namePrefixMap = namePrefixMapDefault,
                  },
                  index
                ) => {
                  const thisAccessorActive = accessor === currentAccessor;
                  const handleClick = (direction: SortDirection) => {
                    const newState =
                      thisAccessorActive && currentSortDirection === direction
                        ? undefined
                        : {
                            columnId: id,
                            sortBy: { accessor, direction },
                          };
                    api.sortColumn(newState);
                    setAnchorEl(null);
                  };
                  return (
                    <Fragment key={index}>
                      {order.map((direction, orderIndex) => {
                        return (
                          <StyledListItemButton
                            key={direction}
                            onClick={() => handleClick(direction)}
                            selected={
                              thisAccessorActive &&
                              currentSortDirection === direction
                            }
                            sx={buttonSx}
                            testId={`${testId}-${direction}`}
                          >
                            {Array.isArray(name)
                              ? name[orderIndex]
                              : `${name} ${namePrefixMap[direction]}`}
                          </StyledListItemButton>
                        );
                      })}
                    </Fragment>
                  );
                }
              )}
            </List>
          </div>
        </Popover>
        <SortIcon
          onClick={(e) => setAnchorEl(e.target)}
          sx={{
            color: isOpen || sortingState ? 'blue.500' : undefined,
          }}
          data-testid="open-multi-sort"
        />
      </>
    );
  };
  return MultiSorter;
};
