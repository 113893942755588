import { Box, BoxProps, styled } from '@mui/material';

import { ChartLegendSize, Variant } from './types';
import { useThemeColor } from '../../../hooks/theme';

export type ChartLegendProps = {
  size?: ChartLegendSize;
  variant?: Variant;
  color?: string;
};

const StyledLegend = styled(Box, {
  name: 'DsChartLegend',
  slot: 'Root',
  target: 'DsChartLegend-Root',
  overridesResolver: ({ ownerState }, styles) => {
    return [styles.root, styles[ownerState.size], styles[ownerState.variant]];
  },
})<BoxProps<'div', { ownerState: ChartLegendProps }>>({});

StyledLegend.displayName = 'ChartLegend';

export const ChartLegend = ({
  size = 'large',
  variant = 'filled',
  color = 'transparent',
}: ChartLegendProps) => {
  color = useThemeColor(color);
  return <StyledLegend ownerState={{ size, variant, color }} />;
};
