import { useLocation, To, parsePath } from 'react-router-dom';

import { addOrgIdToLocation } from 'features/organization';
import { GetLocationDescriptorSettings } from 'utils/url';

import { useOrganizations } from './useOrganizations';

export const useLocationWithOrgId = (
  path?: To,
  settings?: GetLocationDescriptorSettings
) => {
  const { currentOrganization } = useOrganizations();
  const location = useLocation();
  const parsedPath = typeof path === 'string' ? parsePath(path) : path;
  return addOrgIdToLocation(
    parsedPath,
    currentOrganization?.id,
    location,
    settings
  );
};
