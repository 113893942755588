import { useState } from 'react';

import isEqual from 'lodash/isEqual';

import { Criterion, RecentSearchEntry } from '../types';

type UseRecentSearch = {
  recentSearches: RecentSearchEntry[];
  saveRecentSearch: (searchState: {
    freeText?: string;
    values?: Record<string, any>;
  }) => void;
  restoreSearch: (index: number) => void;
};

const storageKey = (key: string) => `recent-search-${key}`;

const maxRecentSearches = 4;

const loadRecentSearches = (key: string) => {
  const restored = sessionStorage.getItem(storageKey(key));
  if (restored) {
    try {
      return JSON.parse(restored);
    } catch (e) {
      console.error(e);
    }
  }
};

const saveRecentSearches = (
  key: string,
  recentSearches: RecentSearchEntry[]
) => {
  if (!recentSearches.length) {
    sessionStorage.removeItem(storageKey(key));
  } else {
    try {
      sessionStorage.setItem(storageKey(key), JSON.stringify(recentSearches));
    } catch (e) {
      console.error(e);
    }
  }
};

const disbaledRecentSearch: UseRecentSearch = {
  recentSearches: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveRecentSearch: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  restoreSearch: () => {},
};

export const useRecentSearch = (
  key: string | undefined,
  criteria: Criterion[],
  onRestore: (entry: RecentSearchEntry) => void
): UseRecentSearch => {
  const [recentSearches, _setRecentSearches] = useState<RecentSearchEntry[]>(
    () => {
      return key ? loadRecentSearches(key) || [] : [];
    }
  );

  if (!key) {
    return disbaledRecentSearch;
  }

  const setRecentSearches = (newRecentSearhes: RecentSearchEntry[]) => {
    _setRecentSearches(newRecentSearhes);
    saveRecentSearches(key, newRecentSearhes);
  };

  return {
    recentSearches: recentSearches || [],
    saveRecentSearch: ({ freeText = '', values = {} }) => {
      const valuesToSave: Record<string, any> = {};
      for (const key in values) {
        if (criteria.some((c) => c.key === key)) {
          valuesToSave[key] = values[key];
        }
      }
      if (!freeText && !Object.keys(valuesToSave).length) {
        return;
      }
      const duplicateIndex = recentSearches.findIndex(
        (rs) => rs.freeText === freeText && isEqual(rs.values, valuesToSave)
      );
      if (duplicateIndex > -1) {
        const _recentSearches = [...recentSearches];
        const removedSearch = _recentSearches.splice(duplicateIndex, 1)[0];
        setRecentSearches([removedSearch, ..._recentSearches]);
        return;
      }
      if (recentSearches.length >= maxRecentSearches) {
        const newRecentSearches: RecentSearchEntry[] = [
          { freeText, values: valuesToSave },
          ...recentSearches.slice(0, maxRecentSearches),
        ];
        setRecentSearches(newRecentSearches);
      } else {
        const newRecentSearches: RecentSearchEntry[] = [
          { freeText, values: valuesToSave },
          ...recentSearches,
        ];
        setRecentSearches(newRecentSearches);
      }
    },
    restoreSearch: (index: number) => {
      const searchToRestore = recentSearches[index];
      if (searchToRestore) {
        setRecentSearches([
          searchToRestore,
          ...recentSearches.filter((search) => search !== searchToRestore),
        ]);
        onRestore(searchToRestore);
      }
    },
  };
};
