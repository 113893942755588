import { useCallback } from 'react';

import { Dayjs } from 'dayjs';

import { DateTimeInput } from '../../components/DateTimeInput';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import { SingleDayPickerProviderProps } from '../../types';
import { dayjsToTZDate } from '../../utils';

export const SingleDayPickerInput = () => {
  const ctx = useDatePickerContext<SingleDayPickerProviderProps>();

  const validateDate = useCallback(
    (date: Dayjs) => {
      const newDate = !ctx.allowTimeInput ? date.startOf('day') : date;

      const minDate =
        typeof ctx.minDate === 'function'
          ? ctx.minDate({ timezone: ctx.timezone })
          : ctx.minDate;

      if (minDate && newDate.isBefore(minDate)) {
        return 'Date is before min date';
      }

      const maxDate =
        typeof ctx.maxDate === 'function'
          ? ctx.maxDate({ timezone: ctx.timezone })
          : ctx.maxDate;

      if (maxDate && newDate.isAfter(maxDate)) {
        return 'Date is after max date';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ctx.timezone, ctx.maxDate, ctx.minDate, ctx.allowTimeInput]
  );

  const onDateChange = (date?: Dayjs) => {
    const newDate = date
      ? dayjsToTZDate(
          !ctx.allowTimeInput ? date.startOf('day') : date,
          ctx.timezone
        )
      : date;

    ctx.setSelected(newDate);
    if (newDate) {
      ctx.pickerRef?.scrollToMonth(newDate);
    }
  };

  return (
    <DateTimeInput
      sx={{ px: 24, maxWidth: 272 }}
      startAdornment="Date:"
      onDateChange={onDateChange}
      dateToSyncWith={ctx.selected}
      dateValidator={validateDate}
    />
  );
};
