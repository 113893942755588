import { Stack, Typography } from '@mui/material';

import { Cluster } from '@cast/types';

import { HexIconSuccess } from 'components/hex-icons';
import { K8sProviderIcon } from 'components/icons';

type ConnectedStateProps = {
  cluster?: Cluster;
  reconnect?: boolean;
};

export const ConnectedState = ({ cluster, reconnect }: ConnectedStateProps) => {
  return (
    <Stack direction="row" gap={8} pt={4} data-testid="connected-state">
      <HexIconSuccess
        display="flex"
        alignSelf="flex-start"
        mt={-4}
        size={40}
        animationType="pulsateSlow"
        iconSize={16}
      />

      <Stack gap={4}>
        <Typography
          variant="h6"
          sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
        >
          Great!{' '}
          {cluster?.providerType && (
            <K8sProviderIcon provider={cluster.providerType} size={16} />
          )}{' '}
          {cluster?.name}
        </Typography>
        <Typography variant="P12R">
          {reconnect ? 'Reconnected' : 'Connected'} successfully!
        </Typography>
      </Stack>
    </Stack>
  );
};
