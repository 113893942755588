import { capitalize, styled } from '@mui/material';

import { YamlContainer } from './components/YamlContainer';
import { PreviewYamlProps } from './types';
import { shouldForwardProp } from '../../../utils/shouldForwardProp';
import { CodeErrorBoundary } from '../_components';
import { PreviewConfigProvider } from '../_providers';

const Root = styled('div', {
  name: 'DsPreviewYaml',
  slot: 'Root',
  target: 'DsPreviewYaml-root',
  shouldForwardProp,
  overridesResolver: ({ ownerState }, styles) => [
    styles.root,
    styles[`color${capitalize(ownerState.color)}`],
  ],
})<{ ownerState: PreviewYamlProps }>({});

export const PreviewYaml = (props: PreviewYamlProps) => {
  props = {
    ...props,
    color: props.color ?? 'primary',
    size: props.size ?? 'large',
    errorMessage: props.errorMessage ?? 'Cannot display YAML',
  };
  return (
    <PreviewConfigProvider {...props}>
      <Root ownerState={props} sx={props.rootSx}>
        <CodeErrorBoundary>
          <YamlContainer {...props} />
        </CodeErrorBoundary>
      </Root>
    </PreviewConfigProvider>
  );
};
