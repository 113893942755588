import { useMatch } from 'react-router-dom';

import { isDemoMode } from '@cast/utils';

import { isDisconnectedForMonth } from 'features/cluster';
import { useClusterQuery } from 'hooks/queries/cluster';
import { isClusterOnboardingPhase2 } from 'utils/cluster';

import { SidebarItemProps } from '../_components/SidebarItem';

export const useAutoscalerSidebarItemProps = (): Partial<SidebarItemProps> => {
  const match = useMatch('/external-clusters/:clusterId/*');
  const clusterId = match?.params?.clusterId;
  const { cluster } = useClusterQuery(clusterId);
  const isManaged = Boolean(cluster?.credentialsId);
  const isDisconnected = cluster && isDisconnectedForMonth(cluster);

  if (!isDemoMode() && (!isManaged || isDisconnected)) {
    return {
      disabled: true,
      tooltip: 'Enable cost optimization',
    };
  }

  if (
    !isDemoMode() &&
    cluster &&
    isClusterOnboardingPhase2(cluster.status, isManaged)
  ) {
    return {
      disabled: true,
      tooltip: 'Invalid cluster status, cluster must be in ready state',
    };
  }

  return {
    disabled: false,
  };
};
