import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { SecurityInsightsCheckExceptionsPayload } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useSaveBestPracticeCheckExceptionsMutation = (
  ruleId: string,
  successMessage = 'Exception configuration was successfully applied.'
): UseMutationResult<
  unknown,
  unknown,
  SecurityInsightsCheckExceptionsPayload,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => {
      return apiClient.securityInsights.saveCheckExceptions({
        ruleId,
        payload,
      });
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [QueryKeys.SECURITY_BEST_PRACTICES_CHECK_EXCEPTIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.SECURITY_BEST_PRACTICE_CHECKS],
      });
      notify.success(successMessage);
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
