import { PropsWithChildren, createContext, useContext } from 'react';

import { HubspotContact } from '@cast/types';

import { useAuth } from 'core/auth/hooks';
import { usePersistentState } from 'hooks/usePersistentState';

type InitContactMutationStatus = 'pending' | 'success' | 'failed';
type FullContactMutationStatus = 'pending' | 'success' | HubspotContact;

type HubspotContactState = {
  contactState: FullContactMutationStatus;
  setContactState: (value: FullContactMutationStatus) => void;
  wasContactInitialized: boolean;
  initContactState: InitContactMutationStatus;
  setInitContactState: (value: InitContactMutationStatus) => void;
};

const HubspotContactContext = createContext<HubspotContactState>(
  undefined as never
);

export const HubspotContactProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth();

  const [initContactState, setInitContactState] =
    usePersistentState<InitContactMutationStatus>(
      `hubspot-contact-initial-${user?.id}`,
      'pending'
    );

  const [contactState, setContactState] =
    usePersistentState<FullContactMutationStatus>(
      `hubspot-contact-full-${user?.id}`,
      'pending'
    );

  const wasContactInitialized = user?.firstLogin
    ? initContactState === 'success'
    : initContactState !== 'failed';

  return (
    <HubspotContactContext.Provider
      value={{
        contactState,
        setContactState,
        wasContactInitialized,
        initContactState,
        setInitContactState,
      }}
    >
      {children}
    </HubspotContactContext.Provider>
  );
};

export const useHubspotContact = () => {
  return useContext(HubspotContactContext);
};
