import { ColumnProps, ColumnSettings, ColumnsInfo } from '../../../types';

export type UseColumnWidths = {
  tableWidth: number;
  columnWidths: Record<string, number>;
};

export const getColumnWidths = (
  columnProps: ColumnProps[],
  containerWidth = 0,
  {
    hiddenColumns = {},
    resizedColumns = {},
  }: Pick<ColumnSettings, 'hiddenColumns' | 'resizedColumns'>
): UseColumnWidths => {
  let staticWidthsSum = 0;
  const columnWidths: Record<string, number> = {};
  const dynamicColumnIndexes: number[] = [];
  let dynamicWidthsSum = 0;
  let widthSum = 0;
  for (let i = 0; i < columnProps.length; i++) {
    const props = columnProps[i];
    const key = props.id;
    if (props.id in hiddenColumns && hiddenColumns[props.id]) {
      continue;
    }
    let width: number | undefined;
    if (props.resizable && key in resizedColumns) {
      width = resizedColumns[key];
    } else if (props.width !== undefined) {
      width = props.width;
    }
    if (width !== undefined) {
      staticWidthsSum += width;
      widthSum += width;
      columnWidths[key] = width;
    } else {
      dynamicColumnIndexes.push(i);
      const minWidth = props.minWidth;
      if (minWidth === undefined) {
        throw new Error(
          `Column has to have either width or minWidth specified. Column ${key} has neither.`
        );
      }
      dynamicWidthsSum += minWidth;
    }
  }
  const spaceLeft = containerWidth - staticWidthsSum;
  if (dynamicWidthsSum < spaceLeft) {
    for (let i = 0; i < dynamicColumnIndexes.length; i++) {
      const props = columnProps[dynamicColumnIndexes[i]];
      const { minWidth, maxWidth = Number.MAX_SAFE_INTEGER } = props;
      const percentage = (minWidth! * 100) / dynamicWidthsSum;
      let proportionalWidth = (spaceLeft / 100) * percentage;
      if (proportionalWidth > maxWidth) {
        proportionalWidth = maxWidth;
      }
      columnWidths[props.id] = proportionalWidth;
      widthSum += proportionalWidth;
    }
  } else {
    for (let i = 0; i < dynamicColumnIndexes.length; i++) {
      const props = columnProps[dynamicColumnIndexes[i]];
      columnWidths[props.id] = props.minWidth!;
      widthSum += props.minWidth!;
    }
  }
  return { columnWidths, tableWidth: widthSum };
};

// When locked columns takes all available container width or more, make additional scroller
export const getHorizontalScrollerWidth = (
  {
    lockedColumnsWidth,
    scrollableColumnsWidth,
  }: Pick<ColumnsInfo, 'lockedColumnsWidth' | 'scrollableColumnsWidth'>,
  availableWidth?: number
) => {
  if (!availableWidth || !lockedColumnsWidth || !scrollableColumnsWidth) {
    return '100%';
  }
  const scrollableAreaWidth = availableWidth - lockedColumnsWidth;
  const desiredScrollableWidth = Math.min(200, scrollableColumnsWidth / 2);
  if (scrollableAreaWidth < desiredScrollableWidth) {
    return `${availableWidth + desiredScrollableWidth}px`;
  }
  return '100%';
};
