import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { CostReportClusterResourceUsage } from '@cast/types';

import { QueryKeys, apiClient } from 'core/react-query';
import { CostReportResourceUsageNormalized } from 'types/cost-report';
import { toBigNumberTypes } from 'utils/api-utils';

dayjs.extend(timezone);

export const useResourceUsageQuery = (parameters: {
  clusterId: string;
  startTime: string;
  endTime: string;
  stepSeconds?: number;
  disabled?: boolean;
  refetchInterval?: (data: unknown) => number | false;
}) => {
  const client = useQueryClient();

  const {
    clusterId,
    startTime,
    endTime,
    stepSeconds,
    disabled = false,
    refetchInterval,
  } = parameters;
  const {
    data: resourceUsage,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      QueryKeys.CLUSTER_DASHBOARD_RESOURCE_USAGE,
      clusterId,
      startTime,
      endTime,
      stepSeconds,
    ],
    queryFn: async () => {
      return apiClient.costReport
        .getResourceUsage({ clusterId, startTime, endTime, stepSeconds })
        .then((resp) => {
          return (
            resp.data.items?.map((item) => ({
              ...toBigNumberTypes<
                CostReportClusterResourceUsage,
                CostReportResourceUsageNormalized[number]
              >(item, ['timestamp']),
              timestamp: dayjs(item.timestamp).unix(),
            })) || null
          );
        });
    },
    enabled: !!clusterId && !disabled,
    refetchInterval: ({ queryKey }) => {
      if (refetchInterval) {
        return refetchInterval(client.getQueryData(queryKey));
      }
      return false;
    },
  });

  return {
    resourceUsage: resourceUsage || undefined,
    isLoading,
    error,
    refetch,
  };
};
