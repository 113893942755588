import { ClientSideSearchCriterion } from 'components/search';

import { PolicyEnforcementItemType } from './types';

export const policiesCriteria: ClientSideSearchCriterion<PolicyEnforcementItemType>[] =
  [
    {
      key: 'clusterId',
      prop: 'clusterId',
    },
  ];
