import { ThemeOptions } from '@mui/material/styles/createTheme';

import { focusProps } from '../../../theme/utils';

export const checkboxTheme: ThemeOptions['components'] = {
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&:hover, &:active, .MuiListItemButton-root:hover &': {
          '--checkboxBorder': theme.palette.grey[400],
        },
        padding: '0',
        '& .MuiCheckbox-body': {
          background: 'white',
          width: '1em',
          height: '1em',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          border: `1px solid var(--checkboxBorder, ${theme.palette.grey[200]})`,
          transition: 'border-radius ease-in-out 0.1s',
          '&.MuiCheckbox-fontSize-medium': {
            fontSize: 20,
          },
          '&.MuiCheckbox-fontSize-small': {
            fontSize: 16,
          },
        },
        '& .MuiCheckbox-checkmark': {
          fontSize: '0.5em',
        },
        '&:active': {
          '& .MuiCheckbox-body': {
            borderRadius: '40%',
          },
        },
        '&.Mui-focusVisible .MuiCheckbox-body': {
          ...focusProps(theme),
        },

        '&.Mui-checked': {
          '& .MuiCheckbox-body': {
            '&:not(.Mui-focusVisible)': {
              border: 'none',
            },
            backgroundColor: 'var(--checkboxBackground)',
          },
          '&:not(.MuiCheckbox-indeterminate) .MuiCheckbox-checkmark': {
            fontSize: '0.7em',
            '& polyline': {
              strokeWidth: 32,
            },
          },
        },
        '&.Mui-disabled:not(.Mui-checked) .MuiCheckbox-body': {
          backgroundColor: theme.palette.grey[100],
        },
      }),
      colorPrimary: ({ theme }) => ({
        '--checkboxBackground': theme.palette.blue[500],
        '&:hover': {
          '--checkboxBackground': theme.palette.blue[600],
        },
        '&.Mui-disabled': {
          '--checkboxBackground': theme.palette.blue[100],
        },
      }),
      colorSecondary: ({ theme }) => ({
        '--checkboxBackground': theme.palette.green[500],
        '&:hover': {
          '--checkboxBackground': theme.palette.green[600],
        },
        '&.Mui-disabled': {
          '--checkboxBackground': theme.palette.green[100],
        },
      }),
      colorTertiary: ({ theme }) => ({
        '--checkboxBackground': theme.palette.grey[800],
        '&:hover': {
          '--checkboxBackground': theme.palette.grey[900],
        },
        '&.Mui-disabled': {
          '--checkboxBackground': theme.palette.grey[200],
        },
      }),
      colorError: ({ theme }) => ({
        '--checkboxBackground': theme.palette.red[500],
        '--checkboxBorder': theme.palette.red[500],
        '&:hover, .MuiListItemButton-root:hover &': {
          '--checkboxBorder': theme.palette.red[500],
        },
      }),
    },
  },
};
