export type SettingsColumn = {
  id: string;
  locked?: boolean;
  visible?: boolean | 'indeterminate';
  excludeFromSettings?: boolean;
};

export type SettingsGroupedColumn = SettingsColumn & {
  columns: SettingsColumn[];
};

export type SettingsRowModel = SettingsGroupedColumn | SettingsColumn;

export const isColumnsGroup = (
  groupedColumnProps: SettingsRowModel
): groupedColumnProps is SettingsGroupedColumn =>
  !!(groupedColumnProps as SettingsGroupedColumn).columns;
