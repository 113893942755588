import { ThemeOptions } from '@mui/material/styles/createTheme';

export const previewMarkdownTheme: ThemeOptions['components'] = {
  DsPreviewMarkdown: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.grey[900],
      }),
      h1: ({ theme }) => ({
        ...theme.typography.h1,
        marginBottom: '20px',
        marginTop: 0,
      }),
      h2: ({ theme }) => ({
        ...theme.typography.h2,
        marginTop: 0,
        marginBottom: '16px',
      }),
      h3: ({ theme }) => ({
        ...theme.typography.h3,
        marginTop: 0,
        marginBottom: '12px',
      }),
      h4: ({ theme }) => ({
        ...theme.typography.h4,
        marginTop: 0,
        marginBottom: '8px',
      }),
      h5: ({ theme }) => ({
        ...theme.typography.h5,
        marginTop: 0,
        marginBottom: '4px',
      }),
      strong: ({ theme }) => ({
        ...theme.typography.L12B,
        marginTop: 0,
        marginBottom: '12px',
      }),
      p: ({ theme }) => ({
        ...theme.typography.P12R,
        marginTop: 0,
        marginBottom: '8px',
      }),
      ol: ({ theme }) => ({
        ...theme.typography.P12R,
        marginTop: 0,
        marginBottom: '8px',
      }),
      ul: ({ theme }) => ({
        ...theme.typography.P12R,
        marginTop: 0,
        marginBottom: '8px',
      }),
      code: ({ theme }) => ({
        ...theme.typography.C12,
        backgroundColor: theme.palette.grey[800],
        color: 'white',
        padding: '8px 12px',
        borderRadius: '4px',
        whiteSpace: 'break-spaces',
      }),
      codeInline: ({ theme }) => ({
        ...theme.typography.C12,
        display: 'inline-block',
        lineHeight: '14px',
        backgroundColor: theme.palette.grey[100],
        border: `1px solid ${theme.palette.grey[200]}`,
        color: theme.palette.grey[600],
        borderRadius: '2px',
        whiteSpace: 'break-spaces',
      }),
      divider: {
        margin: '4px 0px',
      },
      link: ({ theme }) => ({
        ...theme.typography.P12R,
        color: theme.palette.blue[500],
        whiteSpace: 'unset',
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'underline',
        },
      }),
    },
  },
};
