import { ThemeOptions } from '@mui/material';

export const paginatorTheme: ThemeOptions['components'] = {
  DsPaginator: {
    styleOverrides: {
      root: ({ theme, ownerState: { size } }) => ({
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.grey[600],
        ...(size === 'small'
          ? {
              height: 24,
              ...theme.typography.P12R,
            }
          : { height: 28, ...theme.typography.P14R }),
      }),
      icon: {
        color: 'inherit',
      },
      text: { lineHeight: 1, marginRight: 18 },
    },
  },
};
