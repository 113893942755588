import { checkboxTheme } from './checkbox/checkboxTheme';
import { chipInputTheme } from './chip-input';
import { dropdownsTheme } from './dropdown/dropdownsTheme';
import { formHelperTextTheme } from './form-helper-text';
import { formLabelTheme } from './form-label';
import { inputTheme } from './input';
import { passwordInputTheme } from './password/passwordTheme';
import { radioTheme } from './radio/radioTheme';
import { sliderTheme } from './slider/sliderTheme';
import { textAreaTheme } from './text-area/textAreaTheme';

export * from './checkbox';
export * from './dropdown';
export * from './input';
export * from './password';
export * from './radio';
export * from './slider';
export * from './switch';
export * from './text-area';
export { default as AutoSizingInput } from './AutoSizingInput';
export * from './form-helper-text';
export * from './form-label';
export * from './chip-input';

export const controlsThemes = {
  ...formLabelTheme,
  ...formHelperTextTheme,
  ...inputTheme,
  ...chipInputTheme,
  ...dropdownsTheme,
  ...checkboxTheme,
  ...radioTheme,
  ...sliderTheme,
  ...textAreaTheme,
  ...passwordInputTheme,
};
