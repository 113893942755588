import { To, Location } from 'react-router-dom';

import { organizationHeader, organizationQueryKey } from '@cast/constants';
import { client } from '@cast/core';

import { Organization } from 'types/organization';
import {
  getLocationDescriptor,
  GetLocationDescriptorSettings,
} from 'utils/url';

export const setOrgIdToBrowserStorages = (orgId: string) => {
  client.defaults.headers.common[organizationHeader] = orgId;
  sessionStorage.setItem(organizationQueryKey, orgId);
  localStorage.setItem(organizationQueryKey, orgId);
};

const findOrganization = (
  organizationId?: string | null,
  organizations?: Organization[]
) =>
  organizationId
    ? organizations?.find(({ id }) => id === organizationId)
    : undefined;

export const determineCurrentOrganization = (
  organizations?: Organization[],
  orgIdFromQuery?: string | null
) => {
  if (!organizations?.length) {
    return;
  }
  if (orgIdFromQuery) {
    const org = findOrganization(orgIdFromQuery, organizations);
    if (org) {
      return org;
    }
  }
  const orgIdFromSession = sessionStorage.getItem(organizationQueryKey);
  if (orgIdFromSession) {
    const org = findOrganization(orgIdFromSession, organizations);
    if (org) {
      return org;
    }
  }
  const orgIdFromLocalStorage = localStorage.getItem(organizationQueryKey);
  if (orgIdFromLocalStorage) {
    const org = findOrganization(orgIdFromLocalStorage, organizations);
    if (org) {
      return org;
    }
  }
  return organizations[0];
};

export const addOrgIdToLocation = (
  path: To | undefined,
  currentOrganization: Organization['id'] | undefined,
  location: Location,
  settings?: GetLocationDescriptorSettings
) => {
  if (!path) {
    return path;
  }
  const newDestination = getLocationDescriptor(path, location, settings);
  if (!currentOrganization) {
    return newDestination;
  }
  const search = new URLSearchParams(newDestination.search);
  if (!window.location.search.includes(organizationQueryKey)) {
    search.append(organizationQueryKey, currentOrganization);
  }

  return {
    ...newDestination,
    search: search.toString(),
  };
};
