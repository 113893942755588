import { PropsWithChildren } from 'react';

import { useSearchParamState } from '@cast/utils';

import { TabsContext } from './TabsContext';

type Props<T = any> = PropsWithChildren<{
  initialTab?: T;
  currentTab?: T;
  setCurrentTab?: (tab: T) => void;
  searchParamKey?: string;
}>;

export const TabsProvider = ({
  children,
  currentTab,
  setCurrentTab,
  initialTab,
  searchParamKey,
}: Props) => {
  const isControlled = currentTab !== undefined && !!setCurrentTab;
  const { state, setState } = useSearchParamState(searchParamKey, initialTab);
  const selectedTab = isControlled ? currentTab : state;
  const selectTab = isControlled ? setCurrentTab! : setState;
  return (
    <TabsContext.Provider
      value={{ currentTab: selectedTab, setCurrentTab: selectTab }}
    >
      {children}
    </TabsContext.Provider>
  );
};
