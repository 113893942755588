import { createContext } from 'react';

export type OverflowCounterState = {
  observeElement: (el: HTMLElement) => () => void;
  unobserveElement: (el: HTMLElement) => void;
};

export const OverflowCounterContext = createContext<OverflowCounterState>(
  undefined as never
);

export const HiddenElementsContext = createContext<number>(0);
