import { useQuery } from '@tanstack/react-query';

import { RightsizingRecommendation } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type RightsizingSummaryQuery = {
  isLoading: boolean;
  data?: RightsizingRecommendation;
  error: unknown;
  refetch: () => void;
};

type UseRightsizingSummaryQuery = {
  clusterId: string;
  enabled?: boolean;
};

export const useRightsizingSummaryQuery = ({
  clusterId,
  enabled = true,
}: UseRightsizingSummaryQuery): RightsizingSummaryQuery => {
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: [QueryKeys.RIGHTSIZING_SUMMARY, clusterId],
    queryFn: async () => {
      const { data } = await apiClient.costReport.getRightsizingSummary({
        clusterId: clusterId!,
      });
      return data?.rightsizingRecommendation || null;
    },
    enabled: !!clusterId && enabled,
  });

  return {
    isLoading,
    data: data || undefined,
    error,
    refetch,
  };
};
