import { useRef } from 'react';

import { Stack, styled } from '@mui/material';

const ArrowIcon = () => (
  <svg
    width="7"
    height="4"
    viewBox="0 0 7 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 3.5L3.75 0.5L6.5 3.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SpinButton = styled('button')(({ theme }) => ({
  width: '100%',
  appearance: 'none',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  color: theme.palette.grey[400],
  '&:nth-of-type(2)': {
    transform: 'rotate(180deg)',
  },
}));

const numberOrDefault = (
  value: string | number | undefined,
  defaultValue: number = 0
) => {
  if (!value) {
    return defaultValue;
  }
  const num = Number(value);
  if (Number.isFinite(num)) {
    return num;
  }

  return defaultValue;
};

export type SpinButtonsProps = {
  step?: number;
  inputElement?: HTMLInputElement;
  onIncrement?: (event: React.MouseEvent, value: number) => void;
  onDecrement?: (event: React.MouseEvent, value: number) => void;
  spinSpeed?: number;
  spinInitialDelay?: number;
};

export const SpinButtons = ({
  onIncrement,
  onDecrement,
  inputElement,
  step,
  spinSpeed = 75,
  spinInitialDelay = 500,
}: SpinButtonsProps) => {
  const handleIncrement = (event: React.MouseEvent) => {
    const value = numberOrDefault(inputElement?.value);
    const stepAmount = numberOrDefault(step, 1);
    onIncrement?.(event, value + stepAmount);
  };

  const handleDecrement = (event: React.MouseEvent) => {
    const value = numberOrDefault(inputElement?.value);
    const stepAmount = numberOrDefault(step, 1);
    onDecrement?.(event, value - stepAmount);
  };

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const handleStartSpinning = (
    e: React.MouseEvent,
    callback: (e: React.MouseEvent) => void
  ) => {
    callback(e);
    timeoutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        callback(e);
      }, spinSpeed);
    }, spinInitialDelay);
  };

  const handleStopSpinning = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  return (
    <Stack>
      <SpinButton
        tabIndex={-1}
        type="button"
        aria-label="Increment value"
        data-testid="increment-spin-button"
        onMouseDown={(e) => handleStartSpinning(e, handleIncrement)}
        onMouseUp={handleStopSpinning}
        onMouseLeave={handleStopSpinning}
      >
        <ArrowIcon />
      </SpinButton>
      <SpinButton
        tabIndex={-1}
        type="button"
        aria-label="Decrement value"
        data-testid="decrement-spin-button"
        onMouseDown={(e) => handleStartSpinning(e, handleDecrement)}
        onMouseUp={handleStopSpinning}
        onMouseLeave={handleStopSpinning}
      >
        <ArrowIcon />
      </SpinButton>
    </Stack>
  );
};
