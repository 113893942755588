import isEmpty from 'lodash/isEmpty';
import {
  NavigateOptions as NavigateOptionsFromReactRouter,
  To,
  useLocation,
  useNavigate as useNavigateFromReactRouter,
} from 'react-router-dom';

import { addOrgIdToLocation } from 'features/organization/utils';
import { GetLocationDescriptorSettings } from 'utils/url';

import { useOrganizations } from './useOrganizations';

type NavigationOptions = NavigateOptionsFromReactRouter & {
  descriptor?: GetLocationDescriptorSettings;
};

export const useNavigate = () => {
  const navigate = useNavigateFromReactRouter();
  const { currentOrganization } = useOrganizations();
  const location = useLocation();

  return (to: To, options?: NavigationOptions) => {
    const { descriptor, ..._options } = options || {};

    const destination = addOrgIdToLocation(
      to,
      currentOrganization?.id,
      location
    );

    if (destination) {
      return navigate(
        addOrgIdToLocation(to, currentOrganization?.id, location, descriptor)!,
        options
      );
    }

    return navigate(to, isEmpty(_options) ? _options : undefined);
  };
};

export const useNavigateBack = () => {
  const navigate = useNavigateFromReactRouter();

  return () => navigate(-1);
};
