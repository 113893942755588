import { styled } from '@mui/material';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const Scroller = styled(OverlayScrollbarsComponent)(({ theme }) => ({
  '& .os-scrollbar .os-scrollbar-track  .os-scrollbar-handle': {
    width: '4px',
    backgroundColor: theme.palette.grey[300],
  },
}));
Scroller.displayName = 'Scroller';

export default Scroller;
