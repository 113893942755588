import { useContext } from 'react';

import { styled } from '@mui/material';
import MuiListItemAvatar, {
  ListItemAvatarProps as MuiListItemAvatarProps,
} from '@mui/material/ListItemAvatar';

import { ListContext, ListProps } from './List';

type OwnerState = {
  size: ListProps['size'];
};

type ListItemAvatarRootProps = MuiListItemAvatarProps & {
  ownerState: OwnerState;
};

const ListItemAvatarRoot = styled(MuiListItemAvatar, {
  name: 'DsListItemAvatar',
  slot: 'Root',
  target: 'DsListItemAvatar-root',
  overridesResolver: ({ ownerState }, theme) => {
    return [theme.root, theme[ownerState.size]];
  },
})<ListItemAvatarRootProps>({});

export type ListItemAvatarProps = MuiListItemAvatarProps;

export const ListItemAvatar = (props: ListItemAvatarProps) => {
  const listContext = useContext(ListContext);
  const size = listContext?.size ?? 'medium';
  const ownerState: OwnerState = { size };

  return (
    <ListItemAvatarRoot ownerState={ownerState} {...props}>
      {props.children}
    </ListItemAvatarRoot>
  );
};
