import { ReactNode } from 'react';

import { Box, BoxProps } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { FieldArray } from 'react-final-form-arrays';

import {
  RffInputsArrayContext,
  RffInputsArrayRowContext,
  RffInputsArrayRowState,
} from './RffInputsArrayContext';

export type RffInputsArrayProps<Value = any> = {
  name: string;
  valueTemplate?: Value;
  renderInputsRow: (args: RffInputsArrayRowState<Value>) => ReactNode;
  footer?: ReactNode;
  className?: string;
  sx?: SxProps;
  rowsWrapperProps?: BoxProps;
  isEqual?: any;
  testId?: string;
};

type RffInputsRowProps<Value = any> = {
  fields: any;
  field: string;
  index: number;
  renderInputsRow: (args: RffInputsArrayRowState<Value>) => ReactNode;
  remove: () => void;
};

const RffInputsRow = ({
  field,
  index,
  renderInputsRow,
  fields,
  remove,
}: RffInputsRowProps) => {
  const rowState: RffInputsArrayRowState = {
    field,
    index,
    isLast: index === (fields.length || 0) - 1,
    isOnly: fields.length === 1,
    remove,
    fields,
  };

  return (
    <RffInputsArrayRowContext.Provider value={rowState} key={index}>
      <div className="RffInputsArray-row" data-testid={`inputs-row-${index}`}>
        {renderInputsRow(rowState)}
      </div>
    </RffInputsArrayRowContext.Provider>
  );
};

export const RffInputsArray = <Value = any,>({
  name,
  className,
  sx,
  valueTemplate,
  renderInputsRow,
  footer,
  rowsWrapperProps,
  testId = 'inputs-array',
}: RffInputsArrayProps<Value>) => {
  return (
    <FieldArray name={name}>
      {({ fields }) => {
        const add = () => {
          fields.push(valueTemplate);
        };

        const remove = (index: number) => {
          fields.remove(index);
        };

        return (
          <RffInputsArrayContext.Provider
            value={{
              add,
              remove,
            }}
          >
            <Box
              className={clsx('RffInputsArray-root', className)}
              sx={sx}
              data-testid={testId}
            >
              <Box
                {...rowsWrapperProps}
                className={clsx(
                  'RffInputsArray-rows',
                  rowsWrapperProps?.className
                )}
              >
                {fields.map((field, index) => {
                  return (
                    <RffInputsRow
                      key={index}
                      field={field}
                      index={index}
                      fields={fields}
                      renderInputsRow={renderInputsRow}
                      remove={() => remove(index)}
                    />
                  );
                })}
              </Box>
              {footer && <div className="RffInputsArray-footer">{footer}</div>}
            </Box>
          </RffInputsArrayContext.Provider>
        );
      }}
    </FieldArray>
  );
};
