import { ThemeOptions } from '@mui/material/styles/createTheme';

export const passwordInputTheme: ThemeOptions['components'] = {
  DsPasswordInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& [type="password"]:not(:placeholder-shown)': {
          fontFamily: 'caption',
          fontSize: '24px',
          letterSpacing: '6px',
        },
        '& .DS-Input-adornment-root svg': {
          cursor: 'pointer',
        },
        '& .DS-Password-strength-root': {
          display: 'flex',
          flexDirection: 'column',
          fontWeight: '500',

          '&.DS-Password-strength-': {
            '&weak': {
              '--strengthLevel': '25%',
              '--strengthColor': theme.palette.red[400],
            },
            '&medium': {
              '--strengthLevel': '50%',
              '--strengthColor': theme.palette.yellow[600],
            },
            '&good': {
              '--strengthLevel': '75%',
              '--strengthColor': theme.palette.blue[400],
            },
            '&strong': {
              '--strengthLevel': '100%',
              '--strengthColor': theme.palette.green[400],
            },
          },
          '& .DS-Password-strength-indicatorRow': {
            display: 'flex',
            alignItems: 'center',
            '& .DS-Password-strength-title': {
              flexGrow: 0,
              color: 'var(--strengthColor)',
              minWidth: '4.5em',
            },
            '& .DS-Password-strength-indicatorContainer': {
              borderRadius: '4px',
              flexGrow: 1,
              display: 'flex',
              height: '4px',
              alignItems: 'stretch',
              backgroundColor: theme.palette.grey[200],
              '& .DS-Password-strength-indicator': {
                borderRadius: 'inherit',
                backgroundColor: 'var(--strengthColor)',
                flexBasis: 'var(--strengthLevel)',
              },
            },
          },
        },
      }),
    },
  },
};
