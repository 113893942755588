import { ThemeOptions } from '@mui/material';

import { AbsolutePseudo, focusProps } from '../../../theme/utils';
import { cssVars } from '../../../utils/cssVars';

const { setValues, getters } = cssVars({
  stepSize: '32px',
  labelSpacing: '-23px',
});

const addBorder = (color: string) => ({
  '&:after': {
    ...AbsolutePseudo,
    border: `1px solid ${color}`,
    borderRadius: 'inherit',
  },
});

export const stepperTheme: ThemeOptions['components'] = {
  MuiStepper: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.DS-Stepper-size': {
          '&Small': setValues({ stepSize: '24px' }),
          '&Medium': setValues({ stepSize: '32px' }),
        },
        '&.DS-Stepper-fullHeight': {
          marginTop: getters.labelSpacing(),
        },
        '& .MuiStepConnector-line': {
          borderColor: theme.palette.grey[200],
        },
        '& .MuiStepConnector-root': {
          '&.Mui-active, &.Mui-completed': {
            '& .MuiStepConnector-line': {
              borderColor: theme.palette.blue[500],
              borderWidth: '2px',
            },
          },
        },
      }),
    },
  },
  MuiStep: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontFamily: theme.typography.h5.fontFamily,
        fontWeight: 'bold',
        backgroundColor: theme.palette.blue[500],
        color: 'white',
        borderRadius: '50%',
        width: getters.stepSize(),
        height: getters.stepSize(),
        padding: 'unset',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',

        '.DS-Stepper-sizeSmall &': {
          fontSize: '12px',
        },
        '& .MuiStepLabel-': {
          '&root': {
            justifyContent: 'center',
          },
          '&labelContainer': {
            position: 'absolute',
            bottom: getters.labelSpacing(),
            justifyContent: 'center',
            width: 'auto',
          },
          '&label': {
            width: 'max-content',
            color: theme.palette.grey[900],
            '.DS-Stepper-sizeSmall &': theme.typography.P10M,
            '.DS-Stepper-sizeMedium &': theme.typography.P12M,
          },
          '&iconContainer': {
            paddingRight: 'unset',
          },
        },
        '& .DS-Step-statusIcon': {
          display: 'none',
        },
        '&.Mui-completed, &.DS-Step-error, &.DS-Step-loading': {
          '& .DS-Step-statusIcon': {
            display: 'initial',
          },
          '& .DS-Step-content': {
            display: 'none',
          },
        },
        '&.Mui-completed:not(.DS-Step-loading), &.Mui-active:not(.DS-Step-loading)':
          {
            '&:hover': {
              backgroundColor: theme.palette.blue[600],
            },
            '&:active:not(.Mui-disabled)': {
              backgroundColor: theme.palette.blue[600],
              ...addBorder(theme.palette.blue[700]!),
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.blue[200],
            },
          },
        '&:not(.Mui-completed):not(.Mui-active)': {
          backgroundColor: theme.palette.grey[200],
          '&:hover': {
            backgroundColor: theme.palette.grey[300],
          },
          '& .DS-Step-content': {
            color: theme.palette.grey[500],
          },
          '&:active': {
            backgroundColor: theme.palette.grey[300],
            '& .DS-Step-content': {
              color: theme.palette.grey[600],
            },
            ...addBorder(theme.palette.grey[400]),
          },
          '& .MuiStepLabel-label': {
            color: theme.palette.grey[500],
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[100],
            ...addBorder(theme.palette.grey[200]),
          },
        },
        '&.DS-Step-error': {
          '&:not(.Mui-disabled):not(.DS-Step-loading)': {
            backgroundColor: theme.palette.red[400],
            '&:hover': {
              backgroundColor: theme.palette.red[500],
            },
            '&:active': {
              backgroundColor: theme.palette.red[500],
              ...addBorder(theme.palette.red[800]!),
            },
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.red[100],
          },
        },
        '&:focus-visible': {
          outline: 'none',
          '&:after': {
            ...AbsolutePseudo,
            ...focusProps(theme),
            borderRadius: 'inherit',
          },
        },
        '&.DS-Step-loading': {
          backgroundColor: theme.palette.blue[200],
          '&.DS-Step-error': {
            backgroundColor: theme.palette.red[200],
          },
          '& .MuiStepLabel-label': {
            color: theme.palette.grey[500],
          },
        },
        '& .DS-Step-loadingIcon.DS-Spinner-root': {
          color: 'white',
        },
      }),
    },
  },
};
