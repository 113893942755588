import {
  cloneElement,
  createContext,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  MouseEvent,
  useContext,
} from 'react';

import { capitalize, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import omit from 'lodash/omit';

import {
  makeShouldForwardProp,
  shouldForwardProp,
} from '../../utils/shouldForwardProp';
import { X } from '../icons';

const Root = styled('div', {
  name: 'DsChip',
  slot: 'Root',
  target: 'DsChip-root',
  shouldForwardProp,
  overridesResolver: (
    { ownerState: { size = 'medium', iconChip } },
    styles
  ) => {
    return [styles.root, styles[size], iconChip && styles.iconChip];
  },
})<{ ownerState: ChipProps }>({});

const DestroyChipIcon = styled(X, {
  name: 'DsChip',
  slot: 'DestroyIcon',
  target: 'DsChip-DestroyIcon',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.destroyIcon],
})<{ ownerState: ChipProps }>({});

const OverlayIconWrapper = styled('div', {
  name: 'DsChip',
  slot: 'OverlayIconWrapper',
  target: 'DsChip-OverlayIconWrapper',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.overlayIconWrapper],
})<{ ownerState: ChipProps }>({});

const IconWrapper = styled('div', {
  name: 'DsChip',
  slot: 'IconWrapper',
  target: 'DsChip-IconWrapper',
  shouldForwardProp: makeShouldForwardProp('destroyIconWrapper'),
  overridesResolver: ({ destroyIconWrapper }, styles) => [
    styles.iconWrapper,
    destroyIconWrapper && styles.destroyIconWrapper,
  ],
})<
  HTMLAttributes<HTMLDivElement> & {
    destroyIconWrapper?: boolean;
    ownerState: ChipProps;
  }
>({});

const Content = styled('div', {
  name: 'DsChip',
  slot: 'Content',
  target: 'DsChip-Content',
  shouldForwardProp,
  overridesResolver: (_, styles) => [styles.content],
})<{ ownerState: ChipProps }>({});

export type ChipProps = PropsWithChildren<
  {
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'micro';
    onDestroy?: (e: MouseEvent) => void;
    iconChip?: boolean;
    disabled?: boolean;
    multiline?: boolean;
    sx?: SxProps;
    overlayIcon?: ReactElement | false;
    testId?: string;
  } & HTMLAttributes<HTMLDivElement>
>;

export const ChipContext = createContext<
  Omit<ChipProps, 'children'> | undefined
>(undefined);

const Chip = forwardRef<HTMLDivElement, ChipProps>((props: ChipProps, ref) => {
  const chipContext = useContext(ChipContext);
  const _props = {
    ...(chipContext || {}),
    ...props,
  };

  const {
    children,
    startIcon,
    onDestroy,
    size = 'medium',
    className,
    disabled,
    iconChip,
    sx,
    testId,
    overlayIcon,
  } = _props;

  let { endIcon } = _props;

  const showDestroyIcon = !endIcon && !!onDestroy;
  if (showDestroyIcon) {
    endIcon = (
      <DestroyChipIcon
        weight="bold"
        onClick={onDestroy}
        ownerState={_props}
        data-testid="destroy-chip"
      />
    );
  }

  return (
    <Root
      {...omit(props, [
        'onDestroy',
        'startIcon',
        'endIcon',
        'iconChip',
        'multiline',
        'overlayIcon',
        'testId',
      ])}
      data-testid={testId}
      ref={ref}
      sx={sx}
      className={clsx(
        `DsChip-size${capitalize(size)}`,
        showDestroyIcon && 'DsChip-destroyable',
        disabled && 'Ds-disabled',
        iconChip && 'DsChip-iconChip',
        className
      )}
      ownerState={_props}
    >
      {startIcon && (
        <IconWrapper className="DsChip-StartIconWrapper" ownerState={_props}>
          {startIcon}
        </IconWrapper>
      )}

      {Boolean(children) && (
        <Content
          className={clsx(iconChip && 'DsChip-IconWrapper')}
          ownerState={_props}
        >
          {!!overlayIcon && (
            <OverlayIconWrapper ownerState={_props}>
              {cloneElement(overlayIcon, {
                className: `${overlayIcon.props.className} DsChip-OverlayIcon`,
              })}
            </OverlayIconWrapper>
          )}
          {children}
        </Content>
      )}

      {endIcon && (
        <IconWrapper
          className="DsChip-EndIconWrapper"
          destroyIconWrapper={showDestroyIcon}
          ownerState={_props}
        >
          {endIcon}
        </IconWrapper>
      )}
    </Root>
  );
});

Chip.displayName = 'Chip';

export default Chip;
