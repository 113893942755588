import { ReactNode } from 'react';

import { Box, Stack, StackProps } from '@mui/material';

import { DatePickerFooterButtons } from './DatePickerFooterButtons';
import { TimezonePicker } from './TimezonePicker';
import { useDatePickerContext } from './useDatePickerContext';

type Props = StackProps & {
  isMini: boolean;
  hint?: ReactNode;
};

export const DatePickerFooter = ({ isMini, hint, ...rest }: Props) => {
  const { hideTimezonePicker } = useDatePickerContext();

  return (
    <Stack
      borderTop="grey.200"
      padding="8px 24px"
      gap={8}
      width={isMini ? 272 : 450}
      {...rest}
    >
      {!!hint && (
        <Box flex={1} data-testid="date-picker-hint">
          {hint}
        </Box>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={!hideTimezonePicker ? 'space-between' : 'flex-end'}
      >
        {!hideTimezonePicker && <TimezonePicker isMini={isMini} />}
        <DatePickerFooterButtons />
      </Stack>
    </Stack>
  );
};
