import { ExpandToggler, ExpandTogglerProps } from '../../../_components';
import { Indentation } from '../Indentation';
import { Colon } from '../tokens';

type Props = {
  title: string;
  level: number;
  expandTogglerProps?: ExpandTogglerProps;
};

export const ValueTitle = ({ title, level, expandTogglerProps }: Props) => {
  return (
    <span className="DsPreviewJson-ObjectDisplay-KeyContainer">
      <span className="DsPreviewJson-ObjectDisplay-KeyIndentation">
        <Indentation indent={level} />
      </span>
      <span className="DsPreviewJson-ObjectDisplay-KeyWrapper">
        {expandTogglerProps && <ExpandToggler {...expandTogglerProps} />}
        <span className="DsPreviewJson-Key">&quot;{title}&quot;</span>
        <Colon />
      </span>
    </span>
  );
};
