import { useContext } from 'react';

import { styled, Typography } from '@mui/material';
import MUIListSubheader, {
  ListSubheaderProps as MuiListSubheaderProps,
} from '@mui/material/ListSubheader';

import { ListContext, ListProps } from './List';
import { ConditionalWrapper } from '../../utils';

type OwnerState = {
  size: ListProps['size'];
};

type ListSubheaderRootProps = MuiListSubheaderProps<
  'li',
  {
    ownerState: OwnerState;
  }
>;

const ListSubheaderRoot = styled(MUIListSubheader, {
  name: 'DsListSubheader',
  slot: 'Root',
  target: 'DsListSubheader-root',
  overridesResolver: ({ ownerState }, theme) => {
    return [theme.root, theme[ownerState.size]];
  },
})<ListSubheaderRootProps>({});

export type ListSubheaderProps = MuiListSubheaderProps & { testId?: string };

export const ListSubheader = ({
  children,
  testId,
  ...props
}: ListSubheaderProps) => {
  const listContext = useContext(ListContext);
  const ownerState: OwnerState = { size: listContext?.size ?? 'medium' };

  return (
    <ListSubheaderRoot ownerState={ownerState} data-testid={testId} {...props}>
      <ConditionalWrapper
        condition={typeof children === 'string'}
        wrapper={(children) => {
          return (
            <Typography variant="L10B" color="inherit">
              {children}
            </Typography>
          );
        }}
      >
        {children}
      </ConditionalWrapper>
    </ListSubheaderRoot>
  );
};
