import { ReactNode } from 'react';

import { YamlToken } from './YamlToken';
import { ExpandToggler } from '../../_components';
import { usePreviewConfig } from '../../_providers';
import { TokenType, YamlNode } from '../model';
import { PreviewYamlProps } from '../types';

type Props = { line: YamlNode; onToggle: () => void; linesCount: number };

export const YamlLine = ({ line, linesCount, onToggle }: Props) => {
  const yamlProps = usePreviewConfig<PreviewYamlProps>();
  if (!line.isVisible || !line.tokens.length) {
    return null;
  }

  const marker = yamlProps.marker;
  const markerEl: ReactNode =
    marker?.path && line.path.startsWith(marker.path) ? (
      <span className="DsPreviewYaml-Line-Marker" />
    ) : null;

  const indentation: ReactNode =
    line.tokens[0].type === TokenType.INDENTATION ? (
      <YamlToken token={line.tokens[0]} />
    ) : null;

  return (
    <div className="DsPreviewYaml-Line-root">
      {yamlProps.showLineNumbers && (
        <span className="DsPreviewYaml-Line-Index">
          <span className="DsPreviewYaml-Line-Index-LineNumber">
            {line.index + 1}
          </span>
          <span className="DsPreviewYaml-Line-Index-MaxNumber">
            {linesCount}
          </span>
        </span>
      )}
      {markerEl}
      {indentation}
      <span className="DsPreviewYaml-Line-Content">
        {yamlProps.interactive && !!line.childNodes.length && (
          <ExpandToggler
            isCollapsed={!line.isExpanded}
            setIsCollapsed={() => {
              line.toggle();
              onToggle();
            }}
          />
        )}
        {line.tokens.slice(indentation ? 1 : 0).map((token, index) => (
          <YamlToken key={index} token={token} />
        ))}
        {!line.isExpanded && !!line.childNodes.length && (
          <span className="DsPreviewYaml-ChildCount">
            {'  '}
            {line.childNodes.length + 1} objects
          </span>
        )}
      </span>
    </div>
  );
};
