import { styled } from '@mui/material';

import { Chip, ChipProps } from '@cast/design-system';

export type LabelChipProps = ChipProps;

export const LabelChip = styled(Chip, {
  target: 'LabelChip-root',
})<LabelChipProps>(({ size, theme }) => [
  {
    ...theme.typography.L10M,
    borderRadius: '16px',
    '& .DsChip-IconWrapper': {
      fontSize: '12px',
    },
  },
  size === 'micro' && {
    '--ds-chip-height': '10px',
    fontSize: '8px',
    letterSpacing: '0.5px',
  },
  size === 'extraSmall' && {
    '--ds-chip-height': '14px',
    letterSpacing: '0.5px',
  },
  size === 'small' && {
    '--ds-chip-height': '16px',
  },
  size === 'medium' && {
    '--ds-chip-height': '20px',
  },
  size === 'large' && {
    ...theme.typography.L12M,
    '--ds-chip-height': '24px',
    '& .DsChip-IconWrapper': {
      fontSize: '16px',
    },
  },
]) as unknown as typeof Chip;
