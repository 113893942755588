import { ChangeEvent, ReactNode, useRef } from 'react';

import { styled, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import round from 'lodash/round';
import pluralize from 'pluralize';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { shouldForwardProp } from '../../utils/shouldForwardProp';
import { Button } from '../buttons';
import { DownloadSimple } from '../icons';

export enum FileDropErrorType {
  INVALID_FILE_SIZE = 'invalid-file-size',
  INVALID_FILE_TYPE = 'invalid-file-type',
  MULTIPLE_FILES_NOT_ALLOWED = 'multiple-files-not-allowed',
}

const errorMessagesMap = {
  [FileDropErrorType.INVALID_FILE_SIZE]: (maxSizeInKB: number) =>
    `Invalid file size. (Max file size is ${round(
      maxSizeInKB / 10 ** 6,
      2
    )} MB)`,
  [FileDropErrorType.INVALID_FILE_TYPE]: `Invalid file type.`,
  [FileDropErrorType.MULTIPLE_FILES_NOT_ALLOWED]:
    'Multiple files upload is not allowed.',
};

export type FileDropError = {
  type: FileDropErrorType;
  message?: string;
};

type Actions = {
  onChange?: (files: File[]) => void;
  onError?: (error: FileDropError) => void;
};

export type FileDropProps = {
  placeholder?: string;
  icon?: ReactNode;
  allowMultiple?: boolean;
  showButton?: boolean;
  acceptFiles?: string[] | '*';
  maxFileSize?: number;
  sx?: SxProps;
  testId?: string;
} & Actions;

const Root = styled('div', {
  name: 'DsFileDrop',
  slot: 'Root',
  target: 'DsFileDrop-Root',
  shouldForwardProp,
  overridesResolver: (props, styles) => {
    return [styles.root, props.ownerState.active ? styles.active : undefined];
  },
})<{ ownerState: { active: boolean } }>({});

const Placeholder = styled('div', {
  name: 'DsFileDrop',
  slot: 'Placeholder',
  target: 'DsFileDrop-Placeholder',
  shouldForwardProp,
  overridesResolver: (_props, styles) => {
    return [styles.placeholder];
  },
})<{ ownerState: { active: boolean } }>({});

const handleFileChanges = (
  files: File[],
  {
    allowMultiple,
    maxFileSize,
    acceptFiles,
    onChange,
    onError,
  }: Required<Pick<FileDropProps, 'allowMultiple' | 'maxFileSize'>> &
    Partial<Pick<FileDropProps, 'acceptFiles' | 'onChange' | 'onError'>>
) => {
  if (!files.length) {
    return;
  }

  const checkSize = (file: File) => file.size >= 0 && file.size <= maxFileSize;
  const checkMimeType = (file: File) =>
    acceptFiles && acceptFiles !== '*' ? acceptFiles.includes(file.type) : true;

  if (allowMultiple) {
    let filesWithInvalidSize = 0;
    let filesWithInvalidType = 0;
    const validFiles = [...files].filter((file: File) => {
      const validSize = checkSize(file);
      if (!validSize) {
        ++filesWithInvalidSize;
      }
      const validFileType = checkMimeType(file);
      if (!validFileType) {
        ++filesWithInvalidType;
      }

      return validSize && validFileType;
    });

    if (validFiles.length > 0) {
      onChange?.(validFiles);
    }

    if (filesWithInvalidType) {
      return onError?.({
        type: FileDropErrorType.INVALID_FILE_TYPE,
        message: errorMessagesMap[FileDropErrorType.INVALID_FILE_TYPE],
      });
    } else if (filesWithInvalidSize) {
      return onError?.({
        type: FileDropErrorType.INVALID_FILE_SIZE,
        message:
          errorMessagesMap[FileDropErrorType.INVALID_FILE_SIZE](maxFileSize),
      });
    }
  } else {
    if (files.length > 1) {
      return onError?.({
        type: FileDropErrorType.MULTIPLE_FILES_NOT_ALLOWED,
        message: errorMessagesMap[FileDropErrorType.MULTIPLE_FILES_NOT_ALLOWED],
      });
    }
    const file = files[0];
    const validSize = checkSize(file);
    const validFileType = checkMimeType(file);

    if (!validSize) {
      return onError?.({
        type: FileDropErrorType.INVALID_FILE_SIZE,
        message:
          errorMessagesMap[FileDropErrorType.INVALID_FILE_SIZE](maxFileSize),
      });
    }

    if (!validFileType) {
      return onError?.({
        type: FileDropErrorType.INVALID_FILE_TYPE,
        message: errorMessagesMap[FileDropErrorType.INVALID_FILE_TYPE],
      });
    }

    onChange?.([file]);
  }
};

export const FileDrop = ({
  placeholder = 'Drop file here',
  icon,
  allowMultiple = false,
  showButton = true,
  acceptFiles = '*',
  maxFileSize = 10 ** 6 * 0.5, // ~0.5 MB
  sx,
  testId = 'ds-filedrop',
  onChange,
  onError,
}: FileDropProps) => {
  const { palette } = useTheme();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files: File[] = [...(e.target.files || [])];

    return handleFileChanges(files, {
      allowMultiple,
      acceptFiles,
      maxFileSize,
      onChange,
      onError,
    });
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item, monitor: DropTargetMonitor) => {
      // Handle the dropped file(s) here
      const files = monitor.getItem<any>().files;

      return handleFileChanges(files, {
        allowMultiple,
        acceptFiles,
        maxFileSize,
        onChange,
        onError,
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <Root
      ref={drop}
      ownerState={{ active: isActive }}
      sx={sx}
      data-testid={testId}
    >
      <Placeholder
        ownerState={{ active: isActive }}
        data-testid="file-drop-container"
      >
        {icon || (
          <DownloadSimple
            size={20}
            color={isActive ? palette.blue[500] : palette.grey[400]}
          />
        )}
        {placeholder}
      </Placeholder>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
        data-testid="ds-filedrop-input"
        multiple={allowMultiple}
      />
      {showButton && (
        <Button
          variant="text"
          size="medium"
          onClick={() => fileInputRef.current?.click()}
          disabled={isActive}
        >
          Choose {pluralize('file', allowMultiple ? 2 : 1)}
        </Button>
      )}
    </Root>
  );
};
