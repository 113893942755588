import { useEffect } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';

import { useCompoundReducer, usePrevious } from '@cast/utils';

import { getGroupedColumn } from '../../../../../_utils';
import { useTableContext } from '../../../../../hooks';
import {
  ColumnSettings,
  GroupedColumnOrdering,
  GroupedColumnProps,
} from '../../../../../types';
import { reorderRows } from '../../../../../utils';
import { applyColumnSettings } from '../../../../../utils/applyColumnSettings';
import { toColumnsOrdering } from '../../../../../utils/toColumnsOrdering';
import { isColumnsGroup, SettingsRowModel } from '../types';
import { getColumnGroupVisibilityState, toSettingsRowModel } from '../utils';

export type ColumnsGroup = 'lockedColumns' | 'unlockedColumns';

type SettingsDrawerState = {
  initialized: boolean;
  frozenColumns: SettingsRowModel[];
  lockedColumns: SettingsRowModel[];
  unlockedColumns: SettingsRowModel[];
  floatingColumns: SettingsRowModel[];
  updateSettings?: (settings: ColumnSettings) => ColumnSettings;
};

const initialState: SettingsDrawerState = {
  initialized: false,
  frozenColumns: [],
  lockedColumns: [],
  unlockedColumns: [],
  floatingColumns: [],
};

type State = typeof initialState;

type InitArgs = {
  groupedColumnsProps: GroupedColumnProps[];
  settings: ColumnSettings;
};

type ReorderColumnsArgs = {
  id: string;
  newIndex: number;
  group: ColumnsGroup;
};

type SwitchColumnVisibilityArgs = {
  id: string;
  visible: boolean;
  group: ColumnsGroup;
};

type SwitchColumnLockingArgs = {
  id: string;
  locked: boolean;
};

const toColumnsOrder = (
  settings: SettingsRowModel[]
): GroupedColumnOrdering[] =>
  settings.map((setting) =>
    isColumnsGroup(setting)
      ? { id: setting.id, columns: setting.columns.map((c) => c.id) }
      : { id: setting.id }
  );

const extractColumnsOrder = (
  state: SettingsDrawerState
): GroupedColumnOrdering[] =>
  toColumnsOrder([
    ...state.frozenColumns,
    ...state.lockedColumns,
    ...state.unlockedColumns,
    ...state.floatingColumns,
  ]);

export const useSettingsDrawerState = (isOpen: boolean) => {
  const wasOpen = usePrevious(isOpen);

  const { api } = useTableContext();

  const { dispatcher, state } = useCompoundReducer(
    {
      initialize: (state, { groupedColumnsProps, settings }: InitArgs) => {
        const withSettingsApplied = applyColumnSettings({
          groupedColumnsProps,
          ...settings,
        });
        const frozenColumns: SettingsRowModel[] = [];
        const lockedColumns: SettingsRowModel[] = [];
        let unlockedColumns: SettingsRowModel[] = [];
        const floatingColumns: SettingsRowModel[] = [];
        for (const props of withSettingsApplied) {
          if (props.frozen) {
            frozenColumns.push(toSettingsRowModel(props));
          } else if (props.floating) {
            floatingColumns.push(toSettingsRowModel(props));
          } else if (props.locked) {
            lockedColumns.push(toSettingsRowModel(props));
          } else {
            unlockedColumns.push(toSettingsRowModel(props));
            unlockedColumns = orderBy(unlockedColumns, ['visible'], ['desc']);
          }
        }
        return {
          ...state,
          initialized: true,
          frozenColumns,
          lockedColumns,
          unlockedColumns,
          floatingColumns,
        };
      },
      restoreDefaults: (state) => {
        return { ...state, initialized: false };
      },
      reorderColumns: (state, { id, newIndex, group }: ReorderColumnsArgs) => {
        const newState = { ...state };
        newState[group] = reorderRows(
          {
            rowKey: id,
            newIndex,
          },
          newState[group],
          'id'
        );
        newState.updateSettings = (settings) => ({
          ...settings,
          columnsOrder: toColumnsOrdering([
            ...newState.frozenColumns,
            ...newState.lockedColumns,
            ...newState.unlockedColumns,
          ]),
        });
        return newState;
      },
      switchColumnVisibility: (
        state,
        { id, visible, group: settingsGroup }: SwitchColumnVisibilityArgs
      ) => {
        const hidden = !visible;
        const newModels = cloneDeep(state[settingsGroup]);
        const { column, group } = getGroupedColumn(id, newModels);
        const hiddenColumns: Record<string, boolean> = {};
        if (!column && !group) {
          throw new Error(
            `Cannot switch visibility for column with id "${id}". No column found`
          );
        } else if (group && !column) {
          // Visibility changed on group level
          group.visible = visible;
          group.columns?.forEach((column) => {
            column.visible = visible;
            hiddenColumns[column.id] = hidden;
          });
        } else if (group && column) {
          // Visibility changed on column that belongs to group
          column.visible = visible;
          group.visible = getColumnGroupVisibilityState(group.columns!);
          hiddenColumns[column.id] = hidden;
        } else if (!group && column) {
          // Visibility changed on non-grouped column
          column.visible = visible;
          hiddenColumns[column.id] = hidden;
        }
        return {
          ...state,
          [settingsGroup]: newModels,
          updateSettings: (settings) => {
            return {
              ...settings,
              hiddenColumns: { ...settings.hiddenColumns, ...hiddenColumns },
            };
          },
        };
      },
      switchColumnLocking: (
        { lockedColumns, unlockedColumns, ...state },
        { id, locked }: SwitchColumnLockingArgs
      ) => {
        let result: State;
        if (locked) {
          const column = cloneDeep(unlockedColumns.find((c) => c.id === id));
          if (!column) {
            throw new Error(
              `Cannot lock column. No column in unlocked group with id "${id}"`
            );
          }
          column.locked = true;
          result = {
            ...state,
            unlockedColumns: unlockedColumns.filter((c) => c.id !== id),
            // Place locked column to the end of locked columns group
            lockedColumns: [...lockedColumns, column],
          };
        } else {
          const column = cloneDeep(lockedColumns.find((c) => c.id === id));
          if (!column) {
            throw new Error(
              `Cannot unlock column. No column in locked group with id "${id}"`
            );
          }
          column.locked = false;
          result = {
            ...state,
            lockedColumns: lockedColumns.filter((c) => c.id !== id),
            // Place unlocked column to the start of unlocked columns group
            unlockedColumns: [column, ...unlockedColumns],
          };
        }
        result.updateSettings = (settings) => ({
          ...settings,
          lockedColumns: { ...settings.lockedColumns, [id]: locked },
          columnsOrder: extractColumnsOrder(result),
        });
        return result;
      },
    },
    initialState
  );

  useEffect(
    () => {
      if (state.updateSettings) {
        api.current.setColumnSettings(state.updateSettings);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.updateSettings]
  );

  useEffect(() => {
    if (!(wasOpen && !isOpen)) {
      return;
    }
    // If there was locked columns that became invisible, unlock them
    const lockedInvisibleColumns = state.lockedColumns.filter(
      (column) => !column.visible
    );
    if (lockedInvisibleColumns.length) {
      api.current.setColumnSettings((settings) => {
        const lockedColumns = cloneDeep(settings.lockedColumns);
        lockedInvisibleColumns.forEach((col) => {
          lockedColumns[col.id] = false;
        });
        return { ...settings, lockedColumns };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, isOpen, wasOpen]);

  return { ...dispatcher, ...state };
};
