import { MutableRefObject } from 'react';

import { NormalizedTableProps, TableApi } from '../../../types';

export const useFlexibleColumns = (
  tableApi: MutableRefObject<TableApi>,
  _: NormalizedTableProps
) => {
  if (!tableApi.current.state.columnSettings) {
    tableApi.current.state.columnSettings = {
      resizedColumns: {},
      hiddenColumns: {},
      lockedColumns: {},
      columnsOrder: [],
    };
  }
  if (!tableApi.current.setColumnSettings) {
    tableApi.current.setColumnSettings = (settings) => {
      const newSettings =
        typeof settings === 'function'
          ? settings(tableApi.current.state.columnSettings)
          : settings;
      tableApi.current.setState((state) => ({
        ...state,
        columnSettings: newSettings,
      }));
    };
  }
  if (!tableApi.current.resizeColumn) {
    tableApi.current.resizeColumn = (columnId, newSize) => {
      tableApi.current.setColumnSettings((settings) => {
        return {
          ...settings,
          resizedColumns: { ...settings.resizedColumns, [columnId]: newSize },
        };
      });
    };
  }
};
