import clsx from 'clsx';

import { ValueTitle } from './ValueTitle';
import { encodeClassName, isPrimitive } from '../../../utils';
import { JsonBlockContainer } from '../JsonBlockContainer';
import { PrimitiveValue } from '../PrimitiveValue';
import { Comma } from '../tokens';

type Props = {
  data: object | Array<any>;
  level: number;
  hasNext?: boolean;
  openingBracketIndentation?: number;
  closingBracketIndentation?: number;
  title?: string;
  classPrefix?: string;
};

export const JsonBlock = ({
  data,
  level,
  hasNext,
  openingBracketIndentation,
  closingBracketIndentation,
  title,
  classPrefix = '.',
}: Props) => {
  const isArray = Array.isArray(data);
  const entries = isArray ? data : Object.entries(data);

  return (
    <JsonBlockContainer
      type={isArray ? 'array' : 'object'}
      openingBracketIndentation={openingBracketIndentation}
      closingBracketIndentation={closingBracketIndentation}
      hasNext={hasNext}
      title={title}
      contentLength={entries.length}
      className={encodeClassName(classPrefix)}
    >
      {entries.map((entry, index) => {
        const key = isArray ? index : entry[0];
        const value = isArray ? entry : entry[1];
        const cls = isArray
          ? `${classPrefix}.[${key}]`
          : `${classPrefix}.${key}`;
        const encodedCls = encodeClassName(cls);
        const hasNext = index < entries.length - 1;

        if (isPrimitive(value)) {
          const className = isArray
            ? 'DsPreviewJson-ArrayValueWrapper'
            : 'DsPreviewJson-ObjectDisplay-ValueContainer DsPreviewJson-PrimitiveValueContainer';
          return (
            <div key={key} className={clsx(className, encodedCls)}>
              {!isArray && <ValueTitle title={key} level={level + 1} />}
              <PrimitiveValue
                data={value}
                indent={isArray ? level + 1 : undefined}
              />
              {hasNext && <Comma />}
            </div>
          );
        }
        if (Array.isArray(value) || typeof value === 'object') {
          if (isArray) {
            return (
              <JsonBlock
                key={key}
                data={value}
                level={level + 1}
                hasNext={hasNext}
                closingBracketIndentation={level + 1}
                openingBracketIndentation={level + 1}
                classPrefix={cls}
              />
            );
          }
          return (
            <div
              key={key}
              className={clsx(
                'DsPreviewJson-ObjectDisplay-ValueContainer',
                encodedCls
              )}
            >
              <JsonBlock
                data={value}
                level={level + 1}
                hasNext={hasNext}
                closingBracketIndentation={level + 1}
                title={key}
                classPrefix={cls}
              />
            </div>
          );
        }
        return null;
      })}
    </JsonBlockContainer>
  );
};
