import dayjs, { OpUnitType } from 'dayjs';

import { ConvertableToDateTime, TimeUnit } from '@cast/types';

export function* dateRangeIterator(
  from: ConvertableToDateTime,
  to: ConvertableToDateTime,
  unit: TimeUnit = 'day',
  step = 1
) {
  from = dayjs(from);
  to = dayjs(to);
  let current = from;
  if (from.isBefore(to, unit as OpUnitType)) {
    while (
      current.isBefore(to, unit as OpUnitType) ||
      current.isSame(to, unit as OpUnitType)
    ) {
      yield current;
      current = current.add(step, unit);
    }
  } else if (from.isAfter(to, unit as OpUnitType)) {
    while (
      current.isAfter(to, unit as OpUnitType) ||
      current.isSame(to, unit as OpUnitType)
    ) {
      yield current;
      current = current.subtract(step, unit);
    }
  } else if (from.isSame(to, unit as OpUnitType)) {
    yield to;
  }
}

export const timeRange = (
  from: ConvertableToDateTime,
  to: ConvertableToDateTime,
  unit: TimeUnit = 'day',
  step = 1
) => Array.from(dateRangeIterator(from, to, unit, step));
