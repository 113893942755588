import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SxProps } from '@mui/system';

import {
  Search as DsSearch,
  InputChipProps,
  mergeSx,
  Criterion,
  ChipInputProps,
} from '@cast/design-system';
import { useDebounce } from '@cast/utils';

import { SearchEventArgs } from 'core/analytics';

import { useRecentSearch, useInitialStateReset } from '../_hooks';
import { SearchBoxCriterionGroup } from '../constants';
import { useSearchContext } from '../hooks';
import {
  CriteriaGuard,
  SearchBoxCriterion,
  ClientSideSearchCriterion,
} from '../types';
import { isClient, isServer } from '../utils';

const baseChipProps: Partial<InputChipProps> = {
  delimiter: '',
  size: 'medium',
  sx: { backgroundColor: 'indigo.300', color: 'white' },
  titleProps: { variant: 'P12M' },
  valueProps: { variant: 'P12R' },
};

export const FREE_TEXT_CHIP_KEY = 'free-text-chip';

export type SearchProps = {
  data?: any[];
  filteredData?: any[];
  criteria?: SearchBoxCriterion[];
  values?: Record<string, any>;
  setValue?: (key: string, value: any) => void;
  setValues?: (values: Record<string, any>) => void;
  clearValue?: (key?: string | string[]) => void;
  disableFreeText?: boolean;
  setFreeText?: (text?: string) => void;
  freeText?: string;
  criteriaGuard?: CriteriaGuard;
  initialText?: string;
  recentSearchKey?: string;
  disableRecentSearch?: boolean;
  disabled?: boolean;
  placeholder?: string;
  searchWhileActive?: boolean;
  rootSx?: SxProps;
  criteriaSx?: SxProps;
  inputChipSx?: SxProps;
  listHeight?: number;
  testId?: string;
  analyticsHandler?: ({ freeText, chips }: SearchEventArgs) => void;
  size?: ChipInputProps<InputChipProps>['size'];
  chipInputProps?: Omit<Partial<ChipInputProps>, 'chips'>;
};

const useAnalytics = (
  analyticsHandler: SearchProps['analyticsHandler'],
  freeText?: string,
  values?: Record<string, any>
) => {
  const debouncedFreeText = useDebounce(freeText, 500);
  const handlerRef = useRef(analyticsHandler);
  handlerRef.current = analyticsHandler;

  useEffect(() => {
    if (debouncedFreeText || Object.keys(values ?? {}).length) {
      handlerRef.current?.({ freeText: debouncedFreeText, chips: values });
    }
  }, [debouncedFreeText, values]);
};

const blur = () => {
  (document.activeElement as HTMLInputElement)?.blur();
};

const useProps = (props: SearchProps): SearchProps => {
  const ctx = useSearchContext();

  const criteria = useMemo(() => {
    return (
      props.criteria ||
      ctx?.criteria?.filter(({ group }) => group === SearchBoxCriterionGroup)
    );
  }, [ctx?.criteria, props.criteria]);

  const selectedCriteria: Record<string, any> | undefined = useMemo(() => {
    if (props.values) {
      return props.values;
    }
    if (criteria) {
      const values: Record<string, any> = {};

      if (ctx) {
        for (const { key } of criteria) {
          if (key in ctx.values) {
            values[key] = ctx.values[key];
          }
        }
      }

      return values;
    }
    return ctx?.values;
  }, [props.values, criteria, ctx]);

  if (!ctx) {
    return props;
  }

  return {
    ...ctx,
    ...props,
    recentSearchKey: props.disableRecentSearch
      ? undefined
      : props.recentSearchKey || ctx.urlKey,
    criteria,
    values: selectedCriteria,
  };
};

const getValuesWithTitles = (
  criteria: Array<SearchBoxCriterion<any>>,
  values: Record<string, any>,
  activeCriterionKey?: string
): InputChipProps[] => {
  const result: InputChipProps[] = [];
  for (const key in values) {
    if (key === activeCriterionKey) {
      continue;
    }
    const criterion = criteria.find((c) => c.key === key);

    if (criterion) {
      result.push({
        ...baseChipProps,
        key,
        title: criterion.title || key,
        value: criterion.renderOption
          ? criterion.renderOption(values[key], 0)
          : values[key],
      });
    } else {
      result.push({
        ...baseChipProps,
        key,
        title: key,
        value: values[key],
      });
    }
  }
  return result;
};

export const Search = ({
  placeholder = 'Enter search keywords',
  inputChipSx,
  ..._props
}: SearchProps) => {
  const {
    data,
    filteredData,
    criteria,
    criteriaSx,
    initialText,
    values,
    setValue,
    setValues,
    clearValue,
    disableFreeText,
    freeText,
    setFreeText,
    criteriaGuard,
    recentSearchKey,
    disableRecentSearch,
    disabled,
    searchWhileActive,
    listHeight,
    testId = 'search',
    analyticsHandler,
    size,
    chipInputProps,
  } = useProps(_props);

  useAnalytics(analyticsHandler, freeText, values);

  const [activeCriterionKey, setActiveCriterionKey] = useState<
    string | undefined
  >();

  const activeCriterion = useMemo(() => {
    return activeCriterionKey
      ? criteria?.find(({ key }) => key === activeCriterionKey)
      : undefined;
  }, [criteria, activeCriterionKey]);

  const [isFreeTextAsChip, setIsFreeTextAsChip] = useState(
    Boolean((freeText || initialText) && criteria?.length)
  );

  const [inputValue, setInputValue] = useState(
    isFreeTextAsChip ? '' : initialText || ''
  );

  const searchInCriteria = useMemo(() => {
    let criteriaToShow = criteria?.filter(({ key }) => {
      // Filter available to select chips
      if (values && key in values) {
        return false;
      }
      return key !== activeCriterionKey;
    });
    if (criteriaGuard) {
      criteriaToShow = criteriaGuard(
        criteriaToShow,
        values,
        inputValue,
        activeCriterion
      );
    }
    return criteriaToShow || [];
  }, [
    criteria,
    criteriaGuard,
    values,
    activeCriterionKey,
    inputValue,
    activeCriterion,
  ]);

  useInitialStateReset(() => {
    setActiveCriterionKey(undefined);
    setInputValue('');
    setIsFreeTextAsChip(false);
  });

  const dropdownOptions = useMemo(() => {
    if (activeCriterion?.options) {
      return activeCriterion.options;
    }
    if (!activeCriterion || (!filteredData && !data)) {
      return [];
    }
    if (isServer(activeCriterion)) {
      return [];
    }
    if (activeCriterion.getOptions) {
      return activeCriterion.getOptions(data, filteredData);
    }

    const { getValue, prop, allowEmptyValue } =
      activeCriterion as ClientSideSearchCriterion & SearchBoxCriterion;
    const _dropdownOptions = new Set<string>();
    if (allowEmptyValue) {
      _dropdownOptions.add('');
    }
    const _data = filteredData || data || [];
    for (const option of _data) {
      let value: any;
      if (getValue) {
        value = getValue(option);
      } else if (prop) {
        value = option[prop];
      }
      if (Array.isArray(value)) {
        for (const innerVal of value) {
          if (innerVal != null) {
            _dropdownOptions.add(innerVal);
          }
        }
      } else {
        if (value != null) {
          _dropdownOptions.add(value);
        }
      }
    }

    const result = Array.from(_dropdownOptions);
    if (activeCriterion.mapOptions) {
      return activeCriterion.mapOptions(result, values);
    }
    return result;
  }, [activeCriterion, data, filteredData, values]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { recentSearches, saveRecentSearch, restoreSearch } = useRecentSearch(
    disableRecentSearch ? undefined : recentSearchKey,
    criteria || [],
    ({ freeText = '', values }) => {
      if (!disableFreeText) {
        setFreeText?.(freeText);
        //add free text to input only if search has no criteria
        if (!criteria?.length) {
          setInputValue(freeText);
        }
        if (activeCriterionKey) {
          setActiveCriterionKey(undefined);
        }
      }
      setValues?.(values);
      setIsDropdownOpen(false);
      blur();
    }
  );

  const [selectedOptions, setSelectedOptions] = useState<
    Record<string, boolean>
  >({});
  useEffect(() => {
    if (!activeCriterion?.key) {
      setSelectedOptions({});
    }
  }, [activeCriterion?.key]);

  const selectedValuesWithTitles = useMemo(() => {
    if (criteria && values) {
      const chips = getValuesWithTitles(criteria, values, activeCriterionKey);

      if (isFreeTextAsChip) {
        chips.push({
          ...baseChipProps,
          key: FREE_TEXT_CHIP_KEY,
          testId: FREE_TEXT_CHIP_KEY,
          title: '',
          value: freeText,
        });
      }

      return chips;
    }
    return undefined;
  }, [criteria, values, activeCriterionKey, freeText, isFreeTextAsChip]);

  const activeCriterionValue = activeCriterionKey
    ? values?.[activeCriterionKey]
    : undefined;

  const clearCriteria = useCallback(
    (key?: string | string[]) => {
      if (!key) {
        setActiveCriterionKey(undefined);
        setFreeText?.(undefined);
        setIsFreeTextAsChip(false);
        setInputValue('');
        clearValue?.(Object.keys(values || []));
      } else if (key === activeCriterionKey) {
        setActiveCriterionKey(undefined);
        setInputValue('');
        if (searchWhileActive) {
          clearValue?.(key);
        }
      } else if (key === FREE_TEXT_CHIP_KEY) {
        setFreeText?.(undefined);
        setIsFreeTextAsChip(false);
      } else {
        clearValue?.(key);
      }
    },
    [activeCriterionKey, clearValue, searchWhileActive, setFreeText, values]
  );

  const editCriteria = useCallback(
    (key: string) => {
      if (key === FREE_TEXT_CHIP_KEY) {
        setIsFreeTextAsChip(false);
        setInputValue(freeText ?? '');
      } else {
        const criterion = criteria?.find((c) => c.key === key);

        if (!criterion) {
          return;
        }

        setActiveCriterionKey(criterion.key);
        /**
         * Clear previously selected values when single selection is allowed
         * or multiple selections allowed but no options are given
         */
        if (
          !criterion.allowMultiple ||
          (criterion.allowMultiple && !criterion.options?.length)
        ) {
          const prev = values?.[key];
          clearValue?.(key);
          setSelectedOptions(
            prev && Array.isArray(prev)
              ? prev.reduce((acc: Record<string, boolean>, curr: string) => {
                  return { ...acc, [curr]: true };
                }, {})
              : {}
          );
        } else {
          const criterionValues = values?.[criterion.key];
          if (Array.isArray(criterionValues)) {
            setSelectedOptions(
              criterionValues.reduce((acc, curr) => {
                acc[curr] = true;
                return acc;
              }, {})
            );
          }
        }
      }
    },
    [criteria, clearValue, freeText, values]
  );
  return (
    <DsSearch
      rootSx={mergeSx(
        {
          width: '100%',
        },
        _props.rootSx
      )}
      disabled={disabled}
      inputChipSx={inputChipSx}
      searchWhileActive={searchWhileActive}
      disableFreeText={disableFreeText || isFreeTextAsChip}
      activeCriterion={activeCriterion}
      activeCriterionValue={activeCriterionValue}
      selectedOptions={selectedOptions}
      toggleOption={(optionKey: string) => {
        setSelectedOptions((currentValue) => ({
          ...currentValue,
          [optionKey]: !currentValue[optionKey],
        }));
      }}
      criteriaSearchProps={{
        criteria: searchInCriteria,
        onSelect: (criterion: Criterion) => {
          setActiveCriterionKey(criterion.key);
          setInputValue('');
          if (freeText) {
            setIsFreeTextAsChip(true);
          }
          if (searchInCriteria.length || activeCriterionKey) {
            setIsDropdownOpen(true);
          }
        },
        criteriaSx,
      }}
      recentSearchProps={{
        recentSearches,
        criteria,
        onSelect: restoreSearch,
      }}
      chipInputProps={{
        activeTitle: activeCriterion
          ? activeCriterion.title || activeCriterion.key
          : undefined,
        inputProps: {
          placeholder:
            !activeCriterion && !selectedValuesWithTitles?.length
              ? placeholder
              : undefined,
        },
        chips: selectedValuesWithTitles,
        onClear: () => {
          clearCriteria();
        },
        onKeyDown: (e) => {
          // Only handle Enter on server side search
          if (
            !activeCriterionKey ||
            (activeCriterion && isClient(activeCriterion))
          ) {
            return;
          }
          if (e.key === 'Enter') {
            setValue?.(activeCriterionKey, inputValue);
            setActiveCriterionKey(undefined);
            setInputValue('');
          }
        },
        disabled,
        size,
        ...chipInputProps,
      }}
      dropdownProps={{
        options: dropdownOptions,
        open: isDropdownOpen,
        onOpen: () => {
          if (searchInCriteria.length || activeCriterionKey) {
            setIsDropdownOpen(true);
          }
        },
        onClose: () => {
          setIsDropdownOpen(false);
        },
        onChange: (e, v) => {
          if (!activeCriterionKey) {
            return;
          }
          if (!activeCriterion?.allowMultiple) {
            setValue?.(
              activeCriterionKey,
              activeCriterion?.mapOptionOnSelect
                ? activeCriterion.mapOptionOnSelect(v as string[])
                : v
            );
          }
          setInputValue('');
          setActiveCriterionKey(undefined);
        },
        onInputChange: (value: string, reason: 'reset' | 'change') => {
          if (reason !== 'reset') {
            setInputValue(value);
            if (!activeCriterion) {
              setFreeText?.(value);
            } else if (searchWhileActive && activeCriterionKey) {
              setValue?.(activeCriterionKey, value);
            }
          }
        },
      }}
      autocompleteProps={{
        inputValue,
      }}
      clearCriteria={clearCriteria as any}
      editCriteria={editCriteria as any}
      onClearAll={() => {
        setSelectedOptions({});
      }}
      onApply={() => {
        if (!activeCriterionKey) {
          return;
        }
        if (activeCriterion?.allowMultiple) {
          const options = Object.entries(selectedOptions)
            .filter(([_, checked]) => checked)
            .map(([option]) => option);
          const checkedOptions = options.length ? options : undefined;
          setValue?.(
            activeCriterionKey,
            activeCriterion?.mapOptionOnSelect
              ? activeCriterion.mapOptionOnSelect(checkedOptions)
              : checkedOptions
          );
        }
        setInputValue('');
        setActiveCriterionKey(undefined);
      }}
      listHeight={listHeight}
      testId={testId}
      onPopperClose={() => {
        if (freeText && !isFreeTextAsChip && criteria?.length) {
          setIsFreeTextAsChip(true);
          setInputValue('');
        }
        saveRecentSearch({ freeText, values });
      }}
    />
  );
};
