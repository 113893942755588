export default {
  50: '#E3FFEF',
  100: '#C9F7DC',
  200: '#A0EBC7',
  300: '#72E3AC',
  400: '#4ED996',
  500: '#26BB74',
  600: '#20A665',
  700: '#179A5B',
  800: '#14864F',
  900: '#147B4A',
};
