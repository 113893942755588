import { ThemeOptions } from '@mui/material';

export const spinnerTheme: ThemeOptions['components'] = {
  MuiCircularProgress: {
    styleOverrides: {
      circle: {
        strokeLinecap: 'round',
      },
      root: ({ theme, ownerState }) => ({
        boxSizing: 'border-box',
        '& svg': {
          zIndex: '1',
          position: 'relative',
        },
        '&.DS-Spinner-withBackground:before': {
          content: "''",
          position: 'absolute',
          inset: 0,
          border: `${ownerState.thickness! / 44}em solid ${
            theme.palette.grey[200]
          }`,
          borderRadius: '50%',
        },
        '&.MuiCircularProgress-color': {
          '&Primary': {
            color: theme.palette.blue[400],
          },
          '&Secondary': {
            color: theme.palette.green[400],
          },
          '&Tertiary': {
            color: theme.palette.grey[600],
          },
        },
      }),
    },
  },
};
