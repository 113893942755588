import { forwardRef } from 'react';

import { styled, Box, BoxProps } from '@mui/material';

import { useMainLayout } from './hooks';
import { SidebarState } from './types';

const NO_SIDEBAR_WIDTH = {
  default: 'calc(100vw - 96px)',
  xl: 1560,
  lg: 1560,
  md: 1512,
  sm: 1320,
};

const MINIMIZED_SIDEBAR_WIDTH = {
  default: 'calc(100vw - 144px)',
  xl: 1680,
  lg: 1680,
  md: 1536,
  sm: 1296,
};

const MAXIMIZED_SIDEBAR_WIDTH = {
  default: 'calc(100vw - 312px)',
  xl: 1512,
  lg: 1512,
  md: 1368,
  sm: 1128,
};

const ContentContainerRoot = styled(Box, {
  target: 'Layout-ContentContainer',
  shouldForwardProp: (propName: PropertyKey) => propName !== 'ownerState',
})<
  BoxProps & {
    ownerState: {
      sidebarState: SidebarState;
    };
  }
>(({ theme: { breakpoints }, ownerState }) => {
  let contentWidthMap = NO_SIDEBAR_WIDTH;

  if (ownerState.sidebarState === 'minimized') {
    contentWidthMap = MINIMIZED_SIDEBAR_WIDTH;
  }

  if (ownerState.sidebarState === 'maximized') {
    contentWidthMap = MAXIMIZED_SIDEBAR_WIDTH;
  }

  return {
    position: 'relative',
    margin: '0 auto',
    width: contentWidthMap.default,

    [breakpoints.up('xl')]: {
      width: contentWidthMap.xl,
      marginLeft: ownerState.sidebarState ? '40px' : undefined,
    },
    [breakpoints.between('lg', 'xl')]: {
      width: contentWidthMap.lg,
      marginLeft: ownerState.sidebarState ? '40px' : undefined,
    },
    [breakpoints.between('md', 'lg')]: {
      width: contentWidthMap.md,
    },
    [breakpoints.between('sm', 'md')]: {
      width: contentWidthMap.sm,
    },
  };
});

export const ContentContainer = forwardRef<HTMLDivElement, BoxProps>(
  (props: BoxProps, ref) => {
    const { sidebarState } = useMainLayout();
    return (
      <ContentContainerRoot
        {...props}
        ownerState={{ sidebarState }}
        ref={ref}
      />
    );
  }
);

ContentContainer.displayName = 'ContentContainer';
