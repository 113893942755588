import { Cluster, ClusterDisplayStatus, ClusterStatus } from '@cast/types';

export const isClusterOperable = (cluster: Cluster, isManaged: boolean) => {
  return (
    [ClusterStatus.READY, ClusterStatus.WARNING].includes(cluster.status) &&
    isManaged
  );
};

export const isClusterOnboardingPhase1 = (
  status: ClusterDisplayStatus,
  isManaged: boolean
) => {
  return status === ClusterDisplayStatus.WAITING_CONNECTION && !isManaged;
};

export const isClusterOnboardingPhase2 = (
  status: ClusterStatus,
  isManaged: boolean
) => {
  return status === ClusterStatus.CONNECTING && isManaged;
};
