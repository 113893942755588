import { differenceInSeconds, parseISO } from 'date-fns';
import round from 'lodash/round';

import { RebalancingPlan } from '@cast/types';

import { aggregateOperations } from 'utils/cluster';

import {
  RebalancePlanSimulationProgress,
  RebalanceProgressPercentage,
} from './_types';

/**
 * Calculating simulated progress so the user could see that something is happening while first operation is still being executed.
 * Progress is calculated separately for single type and all operations counts.
 * I.e.: total ops - 10, create ops - 2, if we simulate that first operation is 20% done result is for all ops 2% and for create ops - 10%.
 *
 * @param timePassed time in seconds use to create progress simulation
 * @param totalSimulatedOperations Single type operations count used to get simulation percentage in proportion to single type operations.
 * @param totalAllOperations  All operations count used to get simulation percentage in proportion to all operations.
 * @param maxSimulationDuration Limits simulation duration.
 * @return Progress percentages in proportion to single type and all operations counts.
 */

export const calculateSimulatedProgress = (
  timePassed: number,
  totalSimulatedOperations: number,
  totalAllOperations: number,
  maxSimulationDuration = 60
): RebalancePlanSimulationProgress => {
  const maxProgressForSimulatedOperations = round(
    (1 / totalSimulatedOperations) * 100,
    1
  );
  const maxProgressForAllOperations = round((1 / totalAllOperations) * 100, 1);

  if (timePassed >= maxSimulationDuration) {
    return {
      simulatedProgress: maxProgressForSimulatedOperations,
      overallSimulatedProgress: maxProgressForAllOperations,
    };
  }
  return {
    simulatedProgress: round(
      (timePassed / maxSimulationDuration) * maxProgressForSimulatedOperations,
      1
    ),
    overallSimulatedProgress: round(
      (timePassed / maxSimulationDuration) * maxProgressForAllOperations,
      1
    ),
  };
};

export const calculateOperationProgress = (
  completed: number,
  total: number
) => {
  if (!total) {
    return 0;
  }
  return round((completed * 100) / total, 1);
};

export const getRebalanceProgress = (
  rebalancePlan?: RebalancingPlan
): RebalanceProgressPercentage => {
  if (!rebalancePlan) {
    return {
      create: null,
      cleanup: null,
      overall: null,
    };
  }

  const operationCount = aggregateOperations(rebalancePlan?.operations);
  const cleanUpOpsCount = operationCount.drain + operationCount.delete;
  const cleanedUpOpsCount = operationCount.drained + operationCount.deleted;

  const hasCreateOps = Boolean(operationCount.create);

  const rebalanceLengthInSeconds = rebalancePlan.triggeredAt
    ? differenceInSeconds(new Date(), parseISO(rebalancePlan.triggeredAt))
    : 0;

  const { simulatedProgress, overallSimulatedProgress } =
    calculateSimulatedProgress(
      rebalanceLengthInSeconds,
      hasCreateOps ? operationCount.create : cleanUpOpsCount,
      operationCount.total
    );

  const createdOperationsPercentage = Math.max(
    simulatedProgress,
    calculateOperationProgress(operationCount.created, operationCount.create)
  );

  const cleanedUpOperationsPercentage = Math.max(
    !hasCreateOps ? simulatedProgress : 0,
    calculateOperationProgress(cleanedUpOpsCount, cleanUpOpsCount)
  );

  const overallCompletedOperations = operationCount.created + cleanedUpOpsCount;
  const overallPercentage = Math.max(
    calculateOperationProgress(
      overallCompletedOperations,
      operationCount.total
    ),
    overallSimulatedProgress
  );

  return {
    create: {
      percentage: createdOperationsPercentage,
      operations: {
        completed: operationCount.created,
        total: operationCount.create,
      },
    },
    cleanup: {
      percentage: cleanedUpOperationsPercentage, // drain + delete operations percentage
      operations: {
        completed: operationCount.deleted, // cleanup operations counts once node is deleted
        total: operationCount.delete, // total cleanup operations count for delete node
      },
    },
    overall: {
      percentage: overallPercentage,
      operations: {
        completed: overallCompletedOperations,
        total: operationCount.total,
      },
    },
  };
};
