import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

import { isValidAzureCredential } from '../validators';

export const AzureCredentialsSchema = yup.object({
  credentials: yup
    .object()
    .shape({
      json: yup
        .string()
        .test(
          'is-valid-azure-json',
          'Credentials JSON must contain subscriptionId, tenantId, clientId, and clientSecret.',
          (value) => isEmpty(value) || isValidAzureCredential(value!)
        )
        .required('Please set a Credentials JSON for the cloud credential.'),
    })
    .required('Please set a Credentials JSON for the cloud credential.'),
});
