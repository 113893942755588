import { useFlagsQuery } from 'hooks/queries/useFlagsQuery';

export const useIsPhase1WoopEnabled = (clusterId?: string, enabled = true) => {
  const { data, isLoading, error } = useFlagsQuery({
    flagName: 'woop-phase1-enabled',
    enabled: enabled && !!clusterId,
    clusterId,
  });

  const isPhase1WoopEnabled = data?.[0]?.boolean;
  return { isPhase1WoopEnabled, isLoading, error };
};
