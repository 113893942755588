export enum Severity {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NONE = 'none',
  NOT_AVAILABLE = 'notAvailable',
}

export enum CheckResourceState {
  NOT_AVAILABLE = 'notAvailable',
  AFFECTED = 'affected',
  UNAFFECTED = 'unaffected',
}

export enum VulnerabilitiesResourceState {
  AFFECTED = 'affected',
  UNAFFECTED = 'unaffected',
}
