import { useEffect } from 'react';

import { usePrevious } from '@cast/utils';

import { useSecurityInsightsAgentQuery } from 'hooks/queries/security-insights';

export const useWasSecurityEnabled = (
  clusterId: string,
  onEnabled?: () => void,
  enabled?: boolean
) => {
  const { agent: securityAgent } = useSecurityInsightsAgentQuery(
    clusterId,
    enabled ? 20_000 : false
  );
  const isSecurityAgentInstalled = Boolean(securityAgent?.installed);
  const wasSecurityAgentInstalled = usePrevious(securityAgent?.installed);

  useEffect(() => {
    if (
      enabled &&
      typeof wasSecurityAgentInstalled === 'boolean' &&
      !wasSecurityAgentInstalled &&
      isSecurityAgentInstalled
    ) {
      onEnabled?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasSecurityAgentInstalled, isSecurityAgentInstalled]);

  return isSecurityAgentInstalled;
};
