import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import {
  PreviewSnippet,
  Icons,
  Button,
  DrawerProps,
  Drawer,
  useDrawerInstance,
} from '@cast/design-system';
import { Webhook, WebhookStatus } from '@cast/types';

import { DestroyDrawerFormGuard } from 'components/drawer/ds';
import { webhooksEvents } from 'core/analytics';
import { FormMode } from 'core/forms/rff';
import { notify } from 'core/snackbars';
import {
  useUpdateWebhookMutation,
  useDeleteWebhookMutation,
} from 'hooks/mutations/notifications';

import { DeleteOverlay } from './DeleteOverlay';
import { FormModel } from './types';
import { makeApiModel } from './utils';
import { WebhookForm } from './WebhookForm';
import { WebhookFormSubdrawerActions } from './WebhookFormSubdrawerActions';
import { WebhookStatusChip } from '../../_components';

type Props = DrawerProps & { webhook: Webhook };

export const WebhookFormSubdrawer = forwardRef<HTMLDivElement, Props>(
  ({ webhook, ...drawerProps }, ref) => {
    useEffect(() => {
      webhooksEvents.visitedSetupPage();
    }, []);

    const { close, shouldRenderGuard, setOnCloseGuard } = useDrawerInstance();
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [formMode, setFormMode] = useState<FormMode>('view');

    useEffect(() => {
      if (formMode === 'edit' && formIsDirty) {
        setOnCloseGuard(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formMode, formIsDirty]);

    const { mutateAsync } = useUpdateWebhookMutation(() => {
      close();
    });

    const [shouldRenderDeleteGuard, setOnDeleteGuard] = useState(false);
    const { mutate: deleteWebhook } = useDeleteWebhookMutation(
      () => {
        close();
      },
      () => {
        setOnDeleteGuard(false);
      }
    );

    const handleDelete = useCallback(() => {
      if (webhook?.id) {
        deleteWebhook(webhook.id!);
      }
    }, [deleteWebhook, webhook?.id]);

    const handleSubmit = useCallback(
      async (values: FormModel) => {
        if (webhook?.id) {
          await mutateAsync(
            {
              id: webhook.id!,
              webhook: makeApiModel(values),
            },
            {
              onSuccess: () => {
                webhooksEvents.addedWebhook(values.callbackUrl);
              },
            }
          );
        }
      },
      [mutateAsync, webhook?.id]
    );

    const actionsContainer = useRef<HTMLDivElement | null>(null);

    return (
      <Drawer
        {...drawerProps}
        size="medium"
        ref={ref}
        hideBackdrop
        testId="webhook-form-subdrawer"
      >
        <Box px="32px" height="100%" overflow="auto">
          <Stack position="relative" pt="58px">
            <Stack
              direction="row"
              position="absolute"
              top={10}
              right={-15}
              gap={8}
              ref={actionsContainer}
            >
              {!!actionsContainer.current && (
                <WebhookFormSubdrawerActions
                  formMode={formMode}
                  onEdit={() => setFormMode('edit')}
                  onDelete={() => setOnDeleteGuard(true)}
                  container={actionsContainer.current}
                />
              )}
              <Button
                variant="ghost"
                size="small"
                sx={{
                  width: 28,
                  padding: 0,
                }}
                onClick={() => {
                  close();
                }}
              >
                <Icons.X size={16} />
              </Button>
            </Stack>
            {webhook && (
              <>
                <Typography variant="h4" color="grey.900">
                  {webhook.name}
                </Typography>
                <WebhookStatusChip
                  status={webhook.status}
                  sx={{ mt: '16px' }}
                />
                {webhook.status === WebhookStatus.FAILED && !!webhook.error && (
                  <PreviewSnippet
                    code={webhook.error}
                    onCopy={() => notify.success('Copied to clipboard!')}
                    sx={{
                      wordBreak: 'break-word',
                    }}
                    mt="24px"
                    p="12px 0 12px 12px"
                    maxHeight={300}
                    testId="webhook-error-snippet"
                  />
                )}
              </>
            )}
          </Stack>
          {webhook && (
            <WebhookForm
              mode={formMode}
              webhook={webhook}
              onSubmit={handleSubmit}
              onCancel={() => {
                close();
              }}
              onDirty={setFormIsDirty}
            />
          )}
        </Box>
        {shouldRenderDeleteGuard && (
          <DeleteOverlay
            onDelete={handleDelete}
            onCancel={() => setOnDeleteGuard(false)}
          />
        )}
        {shouldRenderGuard && (
          <DestroyDrawerFormGuard
            onContinue={() => {
              setOnCloseGuard(false);
              setTimeout(() => {
                if (formIsDirty) {
                  setOnCloseGuard(true);
                }
              }, 0);
            }}
            onLeave={() => close()}
          />
        )}
      </Drawer>
    );
  }
);

WebhookFormSubdrawer.displayName = 'WebhookFormSubdrawer';
