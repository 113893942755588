import { Box, Stack, Typography } from '@mui/material';

import { Column, Icons, TableHeader, Tooltip } from '@cast/design-system';
import {
  CheckResourceStatus,
  SecurityInsightsCheckResource,
} from '@cast/types';

import { tableCacheKeys } from 'common/tableCacheKeys';
import {
  NoData,
  NoResults,
  SearchableTable,
  SearchableTableRecordsCount,
  useSearchContext,
} from 'components/search';

import { nonNamespacedLabel } from '../../../constants';

type Props = {
  entity: string;
  analyticsId: string;
  testId?: string;
};

const dotDataMap = {
  [CheckResourceStatus.unaffected]: {
    title: 'Unaffected',
    color: 'green.300',
  },
  [CheckResourceStatus.affected]: {
    title: 'Affected',
    color: 'red.300',
  },
  [CheckResourceStatus.excepted]: {
    title: 'Excepted',
    color: 'grey.500',
  },
};

const Dot = ({ type }: { type: CheckResourceStatus }) => {
  const { title, color } = dotDataMap[type];
  return (
    <Tooltip title={title} arrow placement="left">
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: '100%',
          backgroundColor: color,
          flexShrink: 0,
        }}
      />
    </Tooltip>
  );
};

export const ResourcesTable = ({ entity, analyticsId, testId }: Props) => {
  const ctx = useSearchContext();
  return (
    <SearchableTable
      cacheKey={tableCacheKeys.SECURITY.COMPLIANCE_RESOURCES}
      testId={testId}
      analyticsId={analyticsId}
      sizing="m"
      fillParentHeight
      components={{
        noData: <NoData icon={<Icons.MagnifyingGlass />} entity={entity} />,
        noResults: (
          <NoResults icon={<Icons.MagnifyingGlass />} entity={entity} />
        ),
      }}
      outerHeader={
        <TableHeader
          recordsCount={
            <SearchableTableRecordsCount
              title="resource"
              outOf={ctx?.metadata.totalCount}
            />
          }
          px={32}
        />
      }
    >
      <Column
        id="name"
        header="Resource name"
        width={200}
        resizable
        sortable
        cellSx={{
          ml: 20,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        renderCell={({ resource, status }: SecurityInsightsCheckResource) => (
          <Stack direction="row" alignItems="center" gap={8}>
            <Dot type={status!} />
            <Typography variant="inherit" noWrap>
              {resource?.name}
            </Typography>
          </Stack>
        )}
        defaultSort="asc"
      />
      <Column
        id="kind"
        header="Kind"
        accessor={({ resource }) => resource?.kind}
        renderCell={({ resource }: SecurityInsightsCheckResource) =>
          resource?.kind
        }
        minWidth={160}
        sortable
      />
      <Column
        id="namespace"
        header="Namespace"
        minWidth={166}
        sortable
        cellSx={{
          mr: 20,
        }}
        renderCell={({ resource }: SecurityInsightsCheckResource) => {
          return resource?.namespace === ''
            ? nonNamespacedLabel
            : resource?.namespace;
        }}
      />
      <Column
        id="cluster_name"
        header="Cluster"
        accessor={({ resource }) => resource?.clusterName}
        renderCell={({ resource }: SecurityInsightsCheckResource) =>
          resource?.clusterName
        }
        minWidth={178}
        sortable
        cellSx={{
          mr: 20,
        }}
      />
    </SearchableTable>
  );
};
