import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';

import { mergeSx } from '@cast/design-system';

type Props = BoxProps & { contentWrapperProps?: BoxProps };

/**
 * @deprecated Use `DrawerContent` from `components/ds` instead.
 */
export const DrawerContent = ({
  children,
  className,
  sx,
  contentWrapperProps = {},
  ...props
}: Props) => {
  return (
    <Box
      className={clsx('Drawer-content', className)}
      sx={mergeSx(
        {
          px: 32,
          overflowY: 'auto',
          minHeight: 0,
        },
        sx
      )}
      {...props}
    >
      <Box
        {...contentWrapperProps}
        className={clsx(
          'Drawer-contentWrapper',
          contentWrapperProps?.className
        )}
        sx={mergeSx(
          {
            '& .Detail-root + .Detail-root': {
              marginTop: '12px',
            },
          },
          contentWrapperProps.sx
        )}
      >
        {children}
      </Box>
    </Box>
  );
};
