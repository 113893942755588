import { memo } from 'react';

import { useTheme } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { ResponsiveContainer } from 'recharts';

import { DonutChart } from 'components/chart';

import { SavingsTooltip } from './_components/SavingsTooltip';

type Props = {
  value: {
    configurationSavings: number;
    workloadRightsizing: number;
  };
  size: number;
  disableAnimation?: boolean;
  disableTooltip?: boolean;
};

export const StaticSavingsPieChart = ({
  value,
  size = 65,
  disableTooltip = false,
  disableAnimation = false,
}: Props) => {
  const theme = useTheme();
  const remaining =
    100 - value.configurationSavings - value.workloadRightsizing;

  return (
    <DonutChart
      startAngle={90}
      endAngle={-270}
      data={[
        {
          value: value.configurationSavings,
          color: 'green.500',
          style: {
            stroke: theme.palette.green[500],
            strokeWidth: '1px',
            strokeLinejoin: 'round',
          },
        },
        {
          value: value.workloadRightsizing,
          color: 'aqua.800',
          style: {
            stroke: theme.palette.aqua![800],
            strokeWidth: '1px',
            strokeLinejoin: 'round',
          },
        },
        {
          value: remaining,
          color: 'green.100',
          style: {
            stroke: theme.palette.green[300],
            strokeWidth: '1px',
            strokeLinejoin: 'round',
          },
        },
      ]}
      size={size}
      disableAnimation={disableAnimation}
      disableOnHover
      disableDataSorting
      TooltipComponent={
        !disableTooltip
          ? () => <SavingsTooltip title="You can save" {...value} />
          : undefined
      }
      tooltipProps={{ allowEscapeViewBox: { x: true, y: true } }}
    />
  );
};

export const SavingsPieChart = memo((props: Props) => {
  return (
    <ResponsiveContainer>
      <StaticSavingsPieChart {...props} />
    </ResponsiveContainer>
  );
}, isEqual);

SavingsPieChart.displayName = 'SavingsPieChart';
