import { AxiosInstance } from 'axios';

import { HubspotFormBody, HubspotToken } from '@cast/types';

const hubspotUrl = 'https://api.hsforms.com';
const firstStep =
  '/submissions/v3/integration/submit/20263710/385e6c75-a777-43b4-8ddd-05fa74cf1776';
const secondStep =
  '/submissions/v3/integration/submit/20263710/b6c89275-32dc-46f0-982b-1500041e6de1';

export const HubspotApiFactory = (client: AxiosInstance) => ({
  getHubspotToken: () => client.get<HubspotToken>('/v1/me/hubspot-token'),
  createHubspotContact: (form: HubspotFormBody) =>
    client.post(firstStep, form, { baseURL: hubspotUrl }),
  createFullHubspotContact: (form: HubspotFormBody) =>
    client.post(secondStep, form, { baseURL: hubspotUrl }),
});
