import { useQuery } from '@tanstack/react-query';

import { ClusterCleanupResponse } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type UseOffboardingScriptQuery = {
  isLoading: boolean;
  script?: ClusterCleanupResponse['script'];
  error: unknown;
  refetch: () => void;
};

export const useOffboardingScriptQuery = (
  id: string
): UseOffboardingScriptQuery => {
  const {
    isLoading,
    data: script,
    error,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.EXTERNAL_CLUSTER_CLEANUP, id],
    queryFn: async () => {
      const { data } = await apiClient.clusters.getOffboardingScript({
        clusterId: id,
      });
      return data.script;
    },
    enabled: !!id,
  });

  return {
    isLoading,
    script,
    error,
    refetch,
  };
};
