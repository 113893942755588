import { useMemo, useRef } from 'react';

import { getYaml, YamlNode } from './model';

export const useYaml = (data: object): YamlNode | undefined => {
  const prevYaml = useRef<YamlNode>();
  const prevData = useRef<string>();
  return useMemo(() => {
    if (!data) {
      prevData.current = undefined;
      return;
    }
    const currentDataStr = JSON.stringify(data);
    if (prevData.current !== currentDataStr) {
      const root = getYaml(data);
      prevYaml.current = root;
      prevData.current = currentDataStr;
      return root;
    }
    return prevYaml.current;
  }, [data]);
};
