import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { getWeekDays } from './_utils';

export const WeekdaysHeader = () => {
  const weekDays = useMemo(() => getWeekDays(), []);

  return (
    <Box display="grid" gridTemplateColumns="repeat(7, 1fr)" px={24}>
      {weekDays.map((day) => (
        <Typography
          key={day}
          variant="P10R"
          color="grey.400"
          height={24}
          textAlign="center"
          alignContent="center"
        >
          {day}
        </Typography>
      ))}
    </Box>
  );
};
