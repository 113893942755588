import { forwardRef, useContext } from 'react';

import { Field, FieldProps } from 'react-final-form';

import { Switch, SwitchProps } from '@cast/design-system';

import { useControlsGroupState } from './controls-group';
import { FlagsContext } from './flags';
import { useRffFormState } from '../hooks';

type Props = Omit<SwitchProps, 'name'> & {
  name: string;
  fieldProps?: Partial<FieldProps<string, any>>;
};

export const RffSwitch = forwardRef<HTMLElement, Props>(
  (
    { fieldProps = {}, disabled: _disabled, ...checkboxProps }: Props,
    ref: any
  ) => {
    const ctx = useContext(FlagsContext);
    const { mode } = useRffFormState();
    const { disabled, readOnly, name } = useControlsGroupState({
      disabled: _disabled,
      readOnly: mode === 'view',
      name: checkboxProps.name,
    });

    return (
      <Field
        type="CHECKBOX"
        {...fieldProps}
        name={name || checkboxProps.name}
        render={(props) => {
          const { input } = props;
          let onChange = input.onChange;
          let value = input.value;
          if (ctx) {
            onChange = () => {
              ctx.toggleFlag(checkboxProps.name);
            };
            value = ctx.isFlagChecked(checkboxProps.name);
          }

          return (
            <Switch
              disabled={disabled || readOnly}
              onChange={onChange}
              checked={typeof value === 'string' ? value === 'true' : value}
              ref={ref}
              {...checkboxProps}
              name={name || checkboxProps.name}
            />
          );
        }}
      />
    );
  }
);

RffSwitch.displayName = 'RffSwitch';
