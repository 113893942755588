import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';

import { Button, Icons } from '@cast/design-system';

import { HexIconError } from 'components/hex-icons';
import { StatusMessage } from 'components/messages';

export const RootErrorBoundary = (props: PropsWithChildren<unknown>) => {
  const { children } = props;

  const renderFallback = () => {
    const refreshPage = () => {
      window.location.reload();
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
        aria-label="fallback"
        data-hc="error"
        pt={100}
      >
        <StatusMessage
          icon={<HexIconError icon={<Icons.XCircle weight="fill" />} />}
          title="Oops! Something went wrong!"
          actions={
            <Button onClick={refreshPage} variant="primary">
              Click to reload
            </Button>
          }
        />
      </Box>
    );
  };

  return (
    <Sentry.ErrorBoundary
      fallback={renderFallback}
      onError={(error) => {
        Sentry.captureMessage(
          'lazy load debug: root error boundary fallback kicked in',
          {
            level: 'debug',
            extra: { error },
          }
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
