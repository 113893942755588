import { MatcherType } from './types';
import { FreeTextPredicate, ClientSideSearchCriterion } from '../types';

export const getValue = <T>(
  option: T,
  criterion: ClientSideSearchCriterion<T>
) => {
  if (criterion.getValue) {
    return criterion.getValue(option);
  }
  if (criterion.prop) {
    return option[criterion.prop];
  }
  throw new Error(
    'Criterion must have either getValue, prop, or matcher as function provided'
  );
};

export const getMatcher = (matcherType?: MatcherType) => {
  switch (matcherType) {
    case 'exact':
      return (searchValue: string, opt: string) => searchValue === opt;
    case 'notEqual':
      return (searchValue: string, opt: string) => searchValue !== opt;
    case 'startsWith':
      return (searchValue: string, opt: string) => opt.startsWith(searchValue);
    case 'includes':
      return (searchValue: string, opt: string) => opt.includes(searchValue);
    case 'excludes':
      return (searchValue: string, opt: string) => !opt.includes(searchValue);
    case 'moreThan':
      return (searchValue: string, opt: string) => {
        if (!searchValue) {
          return true;
        }

        const parsedValue = parseInt(searchValue);
        const parsedOption = parseInt(opt);

        if (isNaN(parsedValue) || isNaN(parsedOption)) {
          return false;
        }

        return parsedOption >= parsedValue;
      };
    case 'lessThan':
      return (searchValue: string, opt: string) => {
        if (!searchValue) {
          return true;
        }

        const parsedValue = parseInt(searchValue);
        const parsedOption = parseInt(opt);

        if (isNaN(parsedValue) || isNaN(parsedOption)) {
          return false;
        }

        return parsedOption <= parsedValue;
      };
    default:
      return (searchValue: string, opt: string) => opt.includes(searchValue);
  }
};

export const makeCriterionFilter = <T extends object>(
  criterion: ClientSideSearchCriterion<T>,
  value: any
): ((option: T) => boolean) => {
  const { matcher } = criterion;
  if (typeof matcher === 'function') {
    return (option: T) => matcher(option, value);
  }
  const matcherFn = getMatcher(matcher);

  if (Array.isArray(value)) {
    return (option: T) => {
      let optionVal = getValue(option, criterion);
      if (Array.isArray(optionVal)) {
        if (matcher === 'notEqual') {
          return optionVal.some((opt) =>
            value.every(
              (criterionVal) =>
                opt != null && matcherFn(criterionVal, String(opt))
            )
          );
        }
        return optionVal.some((opt) =>
          value.some(
            (criteriaVal) => opt != null && matcherFn(criteriaVal, String(opt))
          )
        );
      }
      if (optionVal == null) {
        return false;
      }
      optionVal = String(optionVal);
      if (matcher === 'notEqual') {
        return value.every((criterionVal) =>
          matcherFn(criterionVal, optionVal)
        );
      }
      return value.some((criterionVal) => matcherFn(criterionVal, optionVal));
    };
  }
  const criterionVal = String(value);
  return (option: T) => {
    if (criterionVal === '') {
      if (criterion.allowEmptyValue) {
        if (criterion.prop) {
          return criterion.prop in option;
        }
        if (criterion.getValue) {
          return Boolean(criterion.getValue(option));
        }
      }
      return false;
    }
    const optionVal = getValue(option, criterion);
    if (Array.isArray(optionVal)) {
      return optionVal.some((val: any) => matcherFn(criterionVal, String(val)));
    }
    if (optionVal == null) {
      return false;
    }
    return matcherFn(criterionVal, String(optionVal));
  };
};

const filterJson = (option: any, freeText?: string) => {
  if (!freeText) {
    return true;
  }
  return JSON.stringify(option)
    .toLocaleLowerCase()
    .includes(freeText.toLocaleLowerCase());
};

export const filterByFreeText = (
  data: any[],
  freeText?: string,
  dataContainsFreeText: FreeTextPredicate<any> = filterJson
) => {
  if (!freeText) {
    return data;
  }
  return data.filter((option) => dataContainsFreeText(option, freeText));
};
