import { forwardRef, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import pluralize from 'pluralize';

import { Button, DrawerProps } from '@cast/design-system';
import { SecurityInsightsContainerImage } from '@cast/types';

import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { orgSecurityEvents } from 'core/analytics';
import { ExpandableList } from 'features/organization/security-insights/_components';

import { useCreateException } from './useCreateException';
import { getImageName } from '../../../utils';
import { ExceptionReason } from '../../image-details/VulnerabilitiesTab/_components/ExceptionReason';

const reasonsList = [
  'The dependency is not used in the production',
  'Vulnerable functionality is not used',
  'Exposure mitigation in place',
  'False positive or incorrect vulnerability details',
];

type Props = {
  images: SecurityInsightsContainerImage[];
  onCreated?: () => void;
} & Omit<DrawerProps, 'testId'>;

export const CreateImageExceptionDrawer = forwardRef<HTMLDivElement, Props>(
  ({ images, onCreated, ...drawerProps }, ref) => {
    const [reason, setReason] = useState(reasonsList[0]);

    const createException = useCreateException({
      images,
      onCreated,
    });

    return (
      <Drawer
        ref={ref}
        size="small"
        {...drawerProps}
        testId="create-exceptions-drawer"
      >
        <DrawerHeader noDivider sticky={false}>
          <Typography variant="h5" mb={8}>
            Except repository
          </Typography>
          <Typography variant="P14R" color="textSecondary" mb={16}>
            Selected repositories would be removed from the Active repositories
            list
          </Typography>
        </DrawerHeader>
        <DrawerContent>
          <Stack
            mx={-32}
            padding="16px 32px"
            bgcolor="grey.50"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="grey.200"
          >
            <Typography variant="P14B" color="grey.900">
              {pluralize('image', images.length, true)} repositories selected:
            </Typography>
            <ExpandableList childrenContainerSx={{ gap: 10, my: 8 }}>
              {images.map((image) => (
                <Typography
                  key={image.tags?.[0].name}
                  variant="P12M"
                  color="grey.900"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {getImageName(image)}
                </Typography>
              ))}
            </ExpandableList>
          </Stack>
          <ExceptionReason reasons={reasonsList} setReason={setReason} />
          <Stack direction="row" justifyContent="flex-end" mt={56} gap={24}>
            <Button variant="tertiary" onClick={drawerProps.onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e: MouseEvent) => {
                createException(reason);
                drawerProps?.onClose?.(e, 'backdropClick');
                orgSecurityEvents.vulnerabilityManagementRepositoryExceptionCreated();
              }}
              testId="except-button"
            >
              Except {pluralize('repository', images.length)}
            </Button>
          </Stack>
        </DrawerContent>
      </Drawer>
    );
  }
);

CreateImageExceptionDrawer.displayName = 'CreateImageExceptionDrawer';
