import { ReactNode } from 'react';

import { Box, Stack, styled, Typography, TypographyProps } from '@mui/material';

import { Button, Icons, Paper } from '@cast/design-system';
import { getQueryParam, wrapEmailsWithTag } from '@cast/utils';

import { GridBackgroundSvg, LogoFullSvg } from 'assets/svg';
import { IS_PROD_EU } from 'common/constants';
import { LabelChip } from 'components/chips';
import { HexIconCheck, HexIconError } from 'components/hex-icons';
import { StatusMessage } from 'components/messages';
import { LogoutWrapper } from 'components/router';
import { getRedirectToScreenUrl } from 'utils/auth';

const Background = styled(GridBackgroundSvg)({
  position: 'absolute',
  width: '100vw',
  bottom: 0,
  left: 0,
  right: 0,
});

const Logo = () => (
  <Stack
    sx={{
      flexDirection: 'row',
      gap: 4,
      position: 'relative',
      top: 14,
      left: 20,
    }}
  >
    <LogoFullSvg aria-label="logo" style={{ color: 'white' }} />
    {IS_PROD_EU && (
      <LabelChip
        size="micro"
        sx={{
          backgroundColor: 'white',
          color: 'grey.800',
          mt: 11,
        }}
      >
        EU
      </LabelChip>
    )}
  </Stack>
);

export const ErrorPage = () => {
  const title = getQueryParam('title') || 'Failed to authorize';
  let description: ReactNode = getQueryParam('description');
  const errorType = getQueryParam('error-type');

  let actions = (
    <LogoutWrapper screenHint="SIGNUP">
      <Button>Try again</Button>
    </LogoutWrapper>
  );
  let icon: ReactNode;

  switch (errorType) {
    case 'unverified-email':
      icon = (
        <HexIconCheck size={94} icon={<Icons.Envelope weight="regular" />} />
      );
      actions = (
        <Stack direction="row" gap={16}>
          <LogoutWrapper>
            <Button variant="tertiary" startIcon={<Icons.ArrowLeft />}>
              Back
            </Button>
          </LogoutWrapper>
          <Button
            component="a"
            href={getRedirectToScreenUrl(window.location.origin)}
          >
            Check again and continue
          </Button>
        </Stack>
      );
      description = (
        <Typography
          variant="inherit"
          dangerouslySetInnerHTML={{
            __html: wrapEmailsWithTag(description as string),
          }}
        />
      );
      break;
    default:
      icon = <HexIconError size={94} icon={<Icons.Warning weight="fill" />} />;
  }

  return (
    <Box position="relative" bgcolor="grey.900" height="100vh">
      <Background />
      <Logo />
      <Paper
        sx={{
          width: 518,
          mx: 'auto',
          pb: 24,
          mt: '50vh',
          transform: 'translateY(-65%)',
        }}
      >
        <StatusMessage
          icon={
            <Box height={47} sx={{ transform: 'translateY(-47px)' }}>
              {icon}
            </Box>
          }
          titleProps={
            { variant: 'h5', 'data-testid': 'error-title' } as TypographyProps
          }
          title={title}
          bodyProps={
            {
              component: 'div',
              variant: 'P16R',
              'data-testid': 'error-description',
            } as TypographyProps
          }
          body={description}
          actions={actions}
          sx={{ maxWidth: 594, px: 24 }}
        />
      </Paper>
    </Box>
  );
};
