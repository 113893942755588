const EXCLUDE_EMAILS = /^.+@(e2e.test|cast.ai|castai.co)$/i;

export const excludeEmail = (email: string) =>
  EXCLUDE_EMAILS.test(email) || !email.includes('@');

export const checkForChanges = (
  original: NonNullable<unknown>,
  updated: NonNullable<unknown>,
  path: string = ''
): Record<string, 'changed' | 'added' | 'removed'> => {
  const result: Record<string, 'changed' | 'added' | 'removed'> = {};

  function compareValues(
    key: string,
    originalValue: NonNullable<unknown>,
    updatedValue: NonNullable<unknown>
  ) {
    const currentPath = path ? `${path}.${key}` : key;

    if (Array.isArray(originalValue) && Array.isArray(updatedValue)) {
      if (JSON.stringify(originalValue) !== JSON.stringify(updatedValue)) {
        result[currentPath] = 'changed';
      }
    } else if (
      typeof originalValue === 'object' &&
      originalValue !== null &&
      typeof updatedValue === 'object' &&
      updatedValue !== null
    ) {
      const nestedComparison = checkForChanges(
        originalValue,
        updatedValue,
        currentPath
      );
      Object.assign(result, nestedComparison);
    } else if (!Object.is(originalValue, updatedValue)) {
      result[currentPath] = 'changed';
    }
  }

  // Check for changed properties
  for (const key in original) {
    if (key in updated) {
      compareValues(
        key,
        original[key as keyof typeof original],
        updated[key as keyof typeof updated]
      );
    } else {
      result[path ? `${path}.${key}` : key] = 'removed';
    }
  }

  // Check for new properties
  for (const key in updated) {
    const currentPath = path ? `${path}.${key}` : key;
    if (!(key in original)) {
      result[currentPath] = 'added';
    }
  }

  return result;
};
