import { PropsWithChildren, ReactNode } from 'react';

import { BoxProps, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = PropsWithChildren<{
  title?: ReactNode;
  testId?: string;
  control?: ReactNode;
  sx?: SxProps;
  childrenWrapperProps?: BoxProps;
}>;

export const ReportSection = ({
  title,
  testId,
  children,
  control,
  sx,
}: Props) => {
  return (
    <Stack data-hc="success" data-testid={testId} sx={sx}>
      {(!!title || !!control) && (
        <Stack
          direction="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
        >
          {!!title &&
            (typeof title === 'string' ? (
              <Typography variant="A2" color="textSecondary">
                {title}
              </Typography>
            ) : (
              title
            ))}
          {control}
        </Stack>
      )}
      {children}
    </Stack>
  );
};
