export default {
  50: '#ECF4FF',
  100: '#D4E5FF',
  200: '#A9CBFF',
  300: '#82B4FF',
  400: '#5294F7',
  500: '#417AE9',
  600: '#2A5CBB',
  700: '#17449B',
  800: '#133880',
  900: '#192C70',
};
