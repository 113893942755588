import { useField } from 'react-final-form';

import { ShowErrorFunc, ShowErrorProps } from 'types/form';

export const showErrorOnChange: ShowErrorFunc = (meta: ShowErrorProps) =>
  !!((meta.submitError || meta.error) && (meta.touched || meta.dirty));

const config = {
  subscription: {
    error: true,
    submitError: true,
    dirtySinceLastSubmit: true,
    touched: true,
    modified: true,
  },
};

export const useFieldForErrors = (name: string) => useField(name, config);
