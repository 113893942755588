import { GroupHeader } from './GroupHeader';
import { useTableContext } from '../../hooks';
import { ColumnGroupModel } from '../../types';

export const GroupHeadersRow = () => {
  const {
    api: {
      current: {
        columnsInfo: { groupModels },
      },
    },
  } = useTableContext();

  if (!groupModels?.length) {
    return null;
  }

  const lockedGroups: ColumnGroupModel[] = [];
  const scrollableGroups: ColumnGroupModel[] = [];
  for (const group of groupModels) {
    (group.locked ? lockedGroups : scrollableGroups).push(group);
  }

  return (
    <div className="DS-Table-GroupHeadersWrapper">
      <div className="DS-Table-GroupHeaders">
        <div className="DS-Table-LockedGroupHeaders">
          {lockedGroups.map((group) => (
            <GroupHeader key={group.id} group={group} />
          ))}
        </div>
        <div className="DS-Table-ScrollableGroupHeaders">
          {scrollableGroups.map((group) => (
            <GroupHeader key={group.id} group={group} />
          ))}
        </div>
      </div>
    </div>
  );
};
