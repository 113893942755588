import {
  Navigate as NavigateFromReactRouter,
  NavigateProps,
} from 'react-router-dom';

import { useLocationWithOrgId } from 'hooks/useLocationWithOrgId';

export const Navigate = ({ to, ...props }: NavigateProps) => {
  const location = useLocationWithOrgId(to);

  return <NavigateFromReactRouter replace {...props} to={location!} />;
};
