/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Status of the rebalancing plan.  * `generating` - the rebalancing plan is new and currently is being generated. * `generated` - the rebalancing plan has been generated and can be previewed. * `creating_nodes` - the rebalancing plan is being executed, green nodes are being created. * `preparing_nodes` - the rebalancing plan is being executed, green nodes are being prepared. * `draining_nodes` - the rebalancing plan is being executed, blue nodes are being drained. * `deleting_nodes` - the rebalancing plan is being executed, blue nodes are being deleted. * `finished` - the rebalancing plan has finished successfully. * `partially_finished` - the rebalancing plan has partially finished. Used when graceful rebalancing is enabled. * `error` - the rebalancing plan has failed.
 * @export
 * @enum {string}
 */

export const CastaiAutoscalerV1beta1Status = {
    invalid: 'invalid',
    generating: 'generating',
    generated: 'generated',
    creating_nodes: 'creating_nodes',
    preparing_nodes: 'preparing_nodes',
    draining_nodes: 'draining_nodes',
    deleting_nodes: 'deleting_nodes',
    finished: 'finished',
    error: 'error',
    partially_finished: 'partially_finished'
} as const;

export type CastaiAutoscalerV1beta1Status = typeof CastaiAutoscalerV1beta1Status[keyof typeof CastaiAutoscalerV1beta1Status];



