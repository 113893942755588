import { userId } from 'core/auth/user';

const CLOSED_ALERTS_KEY = 'closedAlerts';

const getClosedAlerts = (): string[] => {
  const closedAlerts = localStorage.getItem(CLOSED_ALERTS_KEY);
  if (closedAlerts) {
    try {
      return JSON.parse(closedAlerts);
    } catch (error) {
      console.error('Error parsing closed alerts from local storage');
      return [];
    }
  }
  return [];
};

const setClosedAlerts = (ids: string[]) =>
  localStorage.setItem(CLOSED_ALERTS_KEY, JSON.stringify(ids));

export const addClosedAlert = (id: string) => {
  const closedAlerts = getClosedAlerts();

  if (!closedAlerts.includes(id)) {
    closedAlerts.push(id);
    setClosedAlerts(closedAlerts);
  }
};

export const isAlertAlreadyClosed = (id: string) =>
  getClosedAlerts().includes(id);

export const makePersonalizedAlertId = (id: string) => `${userId()}_${id}`;

export const isAlertDismissed = (id: string) =>
  isAlertAlreadyClosed(makePersonalizedAlertId(id));
