import { useMemo } from 'react';

import { Skeleton, SkeletonProps } from '@cast/design-system';

import { usePoliciesQuery } from 'hooks/queries/autoscaler';
import {
  filterAvailablePolicies,
  getEnabledPolicies,
  getPoliciesList,
} from 'utils/cluster';

type Props = {
  clusterId?: string;
  skeletonProps?: SkeletonProps;
};

export const SavingsPoliciesCount = ({ clusterId, skeletonProps }: Props) => {
  const { isLoading, policies } = usePoliciesQuery(clusterId);

  const { enabled, all } = useMemo(() => {
    if (!policies) {
      return { enabled: 0, all: 0 };
    }
    const available = filterAvailablePolicies(policies);
    const policiesList = getPoliciesList(available);
    return {
      enabled: getEnabledPolicies(policiesList).length,
      all: Object.keys(policiesList).length,
    };
  }, [policies]);

  if (isLoading) {
    if (skeletonProps) {
      return <Skeleton variant="text" {...skeletonProps} />;
    }
    return null;
  }

  return (
    <>
      {enabled}/{all}
    </>
  );
};
