import { ReactNode } from 'react';

import { OptionsWithExtraProps, ProviderContext, useSnackbar } from 'notistack';

import { IconButton, Icons } from '@cast/design-system';
import { pseudoUnique } from '@cast/utils';

import { NotificationVariant } from 'types/notifications';

let useSnackbarRef: ProviderContext;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export const notify = {
  success(
    message: ReactNode,
    options?: OptionsWithExtraProps<NotificationVariant>
  ) {
    this.notification(message, {
      variant: NotificationVariant.SUCCESS,
      ...options,
    });
  },
  warning(
    message: ReactNode,
    options?: OptionsWithExtraProps<NotificationVariant>
  ) {
    this.notification(message, {
      variant: NotificationVariant.WARNING,
      ...options,
    });
  },
  info(
    message: ReactNode,
    options?: OptionsWithExtraProps<NotificationVariant>
  ) {
    this.notification(message, {
      variant: NotificationVariant.INFO,
      ...options,
    });
  },
  error(
    message: ReactNode,
    options?: OptionsWithExtraProps<NotificationVariant>
  ) {
    this.notification(message, {
      variant: NotificationVariant.ERROR,
      ...options,
    });
  },
  notification(
    message: ReactNode,
    options: OptionsWithExtraProps<NotificationVariant> = {
      variant: NotificationVariant.INFO,
    }
  ) {
    const key = options.key ?? pseudoUnique();
    useSnackbarRef.enqueueSnackbar(message, {
      preventDuplicate: true,
      autoHideDuration: 3200,
      key,
      style: { whiteSpace: 'pre-line' },
      action: (
        <IconButton
          size="small"
          color="inherit"
          onClick={() => useSnackbarRef.closeSnackbar(key)}
        >
          <Icons.X strokeWidth="1.5" />
        </IconButton>
      ),
      ...options,
    });
  },
};
