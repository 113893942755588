import { useCallback } from 'react';

import { Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { DateTimeInput } from '../../components/DateTimeInput';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import {
  DatePeriod,
  InfiniteDayPickerRef,
  PeriodComparisonPickerProviderProps,
  PeriodCondition,
} from '../../types';
import { dayjsToTZDate, rangeToUtc } from '../../utils';

type PeriodComparisonPickerInputProps = {
  label: string;
  selectedRange: DatePeriod;
  setSelectedRange: (range: DatePeriod) => void;
  endPlaceholder: string;
  startPlaceholder: string;
  minDate?: PeriodCondition;
  maxDate?: PeriodCondition;
  otherRange: DatePeriod;
  pickerRef: InfiniteDayPickerRef;
};

export const PeriodComparisonPickerInput = ({
  label,
  selectedRange,
  setSelectedRange,
  startPlaceholder,
  endPlaceholder,
  minDate: _minDate,
  maxDate: _maxDate,
  otherRange,
  pickerRef,
}: PeriodComparisonPickerInputProps) => {
  const ctx = useDatePickerContext<PeriodComparisonPickerProviderProps>();

  const validateFromDate = useCallback(
    (date: Dayjs) => {
      if (!ctx.activePeriod && selectedRange.to) {
        if (date.isAfter(selectedRange.to)) {
          return 'From date is after to date';
        }

        if (
          !ctx.activePeriod &&
          selectedRange.to &&
          ctx.maxNumberOfDays &&
          Math.abs(date.diff(selectedRange.to, 'days')) >
            ctx.maxNumberOfDays - 1
        ) {
          return `Date range is above max days limit - (${ctx.maxNumberOfDays})`;
        }
      }

      const rangeInUtc = rangeToUtc(selectedRange);
      const otherRangeInUtc = rangeToUtc(otherRange);

      const minDate =
        typeof _minDate === 'function'
          ? _minDate({
              range: rangeInUtc,
              otherRange: otherRangeInUtc,
              timezone: ctx.timezone,
            })
          : _minDate;

      if (minDate && date.isBefore(minDate)) {
        return 'From date is before min date';
      }

      const maxDate =
        typeof _maxDate === 'function'
          ? _maxDate({
              range: rangeInUtc,
              otherRange: otherRangeInUtc,
              timezone: ctx.timezone,
            })
          : _maxDate;

      if (maxDate && date.isAfter(maxDate)) {
        return 'From date is after max date';
      }
    },
    [
      _maxDate,
      _minDate,
      ctx.activePeriod,
      ctx.maxNumberOfDays,
      ctx.timezone,
      otherRange,
      selectedRange,
    ]
  );

  const validateToDate = useCallback(
    (date: Dayjs) => {
      if (!ctx.activePeriod && selectedRange.from) {
        if (date.isBefore(selectedRange.from)) {
          return 'To date is before from date';
        }

        if (
          ctx.maxNumberOfDays &&
          Math.abs(date.diff(selectedRange.from, 'days')) >
            ctx.maxNumberOfDays - 1
        ) {
          return `Date range is above max days limit - (${ctx.maxNumberOfDays})`;
        }
      }

      const rangeInUtc = rangeToUtc(selectedRange);
      const otherRangeInUtc = rangeToUtc(otherRange);

      const minDate =
        typeof _minDate === 'function'
          ? _minDate({
              range: rangeInUtc,
              otherRange: otherRangeInUtc,
              timezone: ctx.timezone,
            })
          : _minDate;

      const minDateToCompare = minDate
        ? ctx.activePeriod
          ? dayjs
              .utc(minDate)
              .subtract(1, 'ms')
              .add(ctx.activePeriod, 'minutes')
          : dayjs.utc(minDate)
        : undefined;

      if (minDateToCompare && date.endOf('day').isBefore(minDateToCompare)) {
        return 'To date is before min date';
      }

      const maxDate =
        typeof _maxDate === 'function'
          ? _maxDate({
              range: rangeInUtc,
              otherRange: otherRangeInUtc,
              timezone: ctx.timezone,
            })
          : _maxDate;

      const maxDateToCompare = maxDate
        ? ctx.activePeriod
          ? dayjs
              .utc(maxDate)
              .subtract(1, 'ms')
              .add(ctx.activePeriod, 'minutes')
          : dayjs.utc(maxDate)
        : undefined;

      if (maxDateToCompare && date.isAfter(maxDateToCompare)) {
        return 'To date is after max date';
      }
    },
    [
      _maxDate,
      _minDate,
      ctx.activePeriod,
      ctx.timezone,
      ctx.maxNumberOfDays,
      otherRange,
      selectedRange,
    ]
  );

  const onFromDateChange = (date?: Dayjs) => {
    if (!date) {
      setSelectedRange({
        from: undefined,
        to: selectedRange.to,
      });
      return;
    }
    const newFromDate = !ctx.allowTimeInput ? date.startOf('day') : date;

    if (ctx.activePeriod) {
      const endDay = newFromDate
        .add(ctx.activePeriod, 'minutes')
        .subtract(1, 'ms');

      setSelectedRange({
        from: dayjsToTZDate(newFromDate, ctx.timezone),
        to: dayjsToTZDate(
          !ctx.allowTimeInput ? endDay.subtract(1, 'ms') : endDay,
          ctx.timezone
        ),
      });
    } else {
      if (selectedRange.to) {
        ctx.setActivePeriod(
          Math.abs(newFromDate.diff(selectedRange.to, 'minutes'))
        );
      }
      setSelectedRange({
        from: dayjsToTZDate(newFromDate, ctx.timezone),
        to: selectedRange.to,
      });
    }

    if (newFromDate) {
      pickerRef?.scrollToMonth(dayjsToTZDate(newFromDate, ctx.timezone));
    }
  };

  const onToDateChange = useCallback(
    (date?: Dayjs) => {
      if (!date) {
        setSelectedRange({
          from: selectedRange.from,
          to: undefined,
        });
        return;
      }

      const newToDate = !ctx.allowTimeInput
        ? date.endOf('day')
        : date.subtract(1, 'ms');

      if (ctx.activePeriod) {
        const startDay = newToDate
          .add(1, 'ms')
          .subtract(ctx.activePeriod, 'minutes');

        setSelectedRange({
          from: dayjsToTZDate(
            !ctx.allowTimeInput ? startDay.startOf('day') : startDay,
            ctx.timezone
          ),
          to: dayjsToTZDate(newToDate, ctx.timezone),
        });
      } else {
        if (selectedRange.from) {
          ctx.setActivePeriod(
            Math.abs(newToDate.add(1, 'ms').diff(selectedRange.from, 'minutes'))
          );
        }
        setSelectedRange({
          from: selectedRange.from,
          to: dayjsToTZDate(newToDate, ctx.timezone),
        });
      }
      if (newToDate) {
        pickerRef?.scrollToMonth(dayjsToTZDate(newToDate, ctx.timezone));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ctx.allowTimeInput,
      ctx.activePeriod,
      ctx.timezone,
      selectedRange.from,
      validateFromDate,
      validateToDate,
    ]
  );

  return (
    <Stack gap={8}>
      <DateTimeInput
        sx={{
          maxWidth: 224,
          '&& ::placeholder': {
            color: 'grey.400',
          },
        }}
        label={label}
        testId={`${label}-from-input`}
        startAdornment="From:"
        placeholder={startPlaceholder}
        onDateChange={onFromDateChange}
        dateToSyncWith={selectedRange.from}
        dateValidator={validateFromDate}
      />
      <DateTimeInput
        sx={{
          maxWidth: 224,
          '&& ::placeholder': {
            color: 'grey.400',
          },
          '.DS-Input-startAdornment-root': { pr: 26 },
        }}
        testId={`${label}-to-input`}
        startAdornment="To:"
        placeholder={endPlaceholder}
        onDateChange={onToDateChange}
        dateToSyncWith={selectedRange.to}
        dateValidator={validateToDate}
      />
    </Stack>
  );
};
