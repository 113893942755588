import { PropsWithChildren } from 'react';

import { styled, useTheme } from '@mui/material';
import startCase from 'lodash/startCase';

import { shouldForwardProp } from '../../../utils/shouldForwardProp';
export type BadgeProps = {
  size: 'large' | 'medium' | 'small' | 'dot' | 'triangle';
  variant: 'primary' | 'secondary' | 'tertiary';
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'right';
  count: number;
};

const BadgeWrapper = styled('div', {
  name: 'DsBadge',
  slot: 'Wrapper',
  target: 'DsBadge-wrapper',
  overridesResolver: (props, styles) => [styles.wrapper, styles[props.variant]],
})<{ ownerState: BadgeProps }>({});

const BadgeRoot = styled('div', {
  name: 'DsBadge',
  slot: 'Root',
  target: 'DsBadge-root',
  overridesResolver: ({ ownerState }, styles) => [
    styles.root,
    ownerState?.variant && styles[ownerState.variant],
    ownerState?.size && styles[ownerState.size],
    ownerState?.vertical &&
      ownerState?.horizontal &&
      styles[`${ownerState.vertical}${startCase(ownerState.horizontal)}`],
  ],
  shouldForwardProp,
})<{ ownerState: BadgeProps }>({});

const BadgeLabel = styled('span', {
  name: 'DsBadge',
  slot: 'Label',
  target: 'DsBadge-label',
  overridesResolver: ({ ownerState }, styles) => [
    styles.label,
    ownerState?.variant && styles[ownerState.variant],
  ],
})<{ ownerState: BadgeProps }>({});

const BadgeTriangle = styled(
  (props) => {
    return (
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3.49006 0.75C3.68251 0.416667 4.16364 0.416667 4.35609 0.75L7.75357 6.63462C7.94602 6.96795 7.70546 7.38462 7.32056 7.38462H0.525593C0.140693 7.38462 -0.09987 6.96795 0.0925801 6.63462L3.49006 0.75Z"
          fill="inherit"
        />
      </svg>
    );
  },
  {
    name: 'DsBadge',
    slot: 'Triangle',
    target: 'DsBadge-triangle',
    overridesResolver: ({ ownerState }, styles) => [
      styles.triangle,
      ownerState.vertical &&
        ownerState.horizontal &&
        styles[`${ownerState.vertical}${startCase(ownerState.horizontal)}`],
      ownerState.variant && styles[ownerState.variant],
    ],
  }
)<{ ownerState: BadgeProps }>({});

export const Badge = (props: PropsWithChildren<BadgeProps>) => {
  const theme = useTheme();
  const _props = {
    ...(theme.components?.DsBadge?.defaultProps ?? {}),
    ...props,
  };

  return (
    <BadgeWrapper ownerState={_props}>
      {_props.size === 'triangle' ? (
        <BadgeTriangle ownerState={_props} />
      ) : (
        <BadgeRoot ownerState={_props}>
          <BadgeLabel ownerState={_props}>
            {_props.count > 99 ? '99+' : _props.count}
          </BadgeLabel>
        </BadgeRoot>
      )}

      {_props.children}
    </BadgeWrapper>
  );
};
