import { useMemo } from 'react';

import { AgentStatus } from '@cast/types';

import { useClusters } from 'hooks/useClusters';

type Props = {
  enabled?: boolean;
};

export const useActiveClusters = ({ enabled = true }: Props = {}) => {
  const { clusters, isLoading, hasError, refetchClusters } = useClusters({
    enabled,
  });
  const activeClusters = useMemo(
    () =>
      clusters?.filter(
        (cluster) =>
          ![AgentStatus.NOT_RESPONDING, AgentStatus.DISCONNECTED].includes(
            cluster.agentStatus
          )
      ),
    [clusters]
  );
  return { clusters: activeClusters, isLoading, hasError, refetchClusters };
};
