import { useEffect } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useField, useForm } from 'react-final-form';

import { Alert } from '@cast/design-system';
import { NotificationSeverity } from '@cast/types';

import { RffCheckbox, RffFlagsGroup, RffFlagsGroupError } from 'core/forms/rff';

import { SeverityChip } from '../../_components';
import { KnownSeverities } from '../../constants';

export const SeverityCheckboxes = () => {
  const categoryField = useField('category');
  const form = useForm();

  const isSecurityCategorySelected = categoryField.input.value === 'security';

  useEffect(() => {
    if (isSecurityCategorySelected) {
      form.change('selectedSeverities', [NotificationSeverity.INFO]);
    }
  }, [form, isSecurityCategorySelected]);

  return (
    <>
      {isSecurityCategorySelected && (
        <Alert
          color="info"
          size="small"
          noIcon
          sx={{ mb: 16 }}
          testId="security-severity-alert"
        >
          Security reporting notifications has only one severity level which is
          “Info” and is selected as a default
        </Alert>
      )}
      <Typography variant="P12M">Select severity</Typography>
      <RffFlagsGroup name="selectedSeverities">
        <Box
          pt="8px"
          display="grid"
          gridTemplateRows="repeat(3, auto)"
          gridAutoColumns="max-content"
          gridAutoFlow="column"
          columnGap="45px"
          rowGap="16px"
        >
          {KnownSeverities.map((severity) => (
            <Stack direction="row" gap="8px" key={severity}>
              <RffCheckbox
                name={severity}
                testId={`webhook-severity-${severity}-checkbox`}
                disabled={isSecurityCategorySelected}
              />
              <SeverityChip severity={severity} size="medium" />
            </Stack>
          ))}
        </Box>
        <RffFlagsGroupError>
          {(error) => (
            <Typography color="red.500" variant="P12R" pt="4px">
              {error}
            </Typography>
          )}
        </RffFlagsGroupError>
      </RffFlagsGroup>
    </>
  );
};
