import { ReactNode } from 'react';

export const ConditionalWrapper = <T extends ReactNode>(props: {
  condition: boolean;
  wrapper: (child: T) => ReactNode;
  children: T;
}): JSX.Element => {
  return props.condition ? (
    <>{props.wrapper(props.children)}</>
  ) : (
    <>{props.children}</>
  );
};
