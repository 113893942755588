import { cloneElement, ReactElement } from 'react';

import { useTheme } from '@mui/material';

import { Icons, mergeSx } from '@cast/design-system';

import { HexIconNotification } from 'components/hex-icons';
import { StatusMessage, StatusMessageProps } from 'components/messages';
import { getThemeColor } from 'utils/theme';

import { useSearchContext } from './hooks';

type NoResultsProps = {
  icon?: ReactElement;
  entity?: string;
} & Partial<StatusMessageProps>;

export const NoResults = ({
  icon = <Icons.MagnifyingGlass />,
  entity = 'entry',
  sx,
  ...statusMessageProps
}: NoResultsProps) => {
  const theme = useTheme();
  const searchContext = useSearchContext();

  return (
    <StatusMessage
      icon={
        <HexIconNotification
          size={72}
          icon={
            icon
              ? cloneElement(icon, {
                  weight: 'fill',
                  color: getThemeColor(theme, 'grey.500'),
                })
              : icon
          }
        />
      }
      title="No results found"
      titleProps={{ sx: { margin: 0 } }}
      body={
        searchContext?.freeText && !!entity
          ? `Unfortunately, we did not find any ${entity} matching “${searchContext?.freeText}”.`
          : 'Please adjust your filters and try again.'
      }
      sx={mergeSx(
        {
          marginTop: '32px',
        },
        sx
      )}
      testId="no-results-found"
      {...statusMessageProps}
    />
  );
};
