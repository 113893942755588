import { ClusterEditParams, Cloud } from '@cast/types';

import { AzureCredentials, GCPCredentials } from 'types/credentials';

import { AWSCredentialsForm } from './AWSCredentialsForm';
import { AzureCredentialsForm } from './AzureCredentialsForm';
import { GCPCredentialsForm } from './GCPCredentialsForm';

type Props = {
  onSubmit: (value: ClusterEditParams) => void;
  setSubmitHandler: (v: () => void) => void;
  csp?: Cloud;
  error?: string;
};

export const AssignCredentialsForm = ({
  csp,
  onSubmit,
  setSubmitHandler,
  error,
}: Props) => {
  switch (csp) {
    case Cloud.aws:
      return (
        <AWSCredentialsForm
          onSubmit={onSubmit}
          setSubmitHandler={setSubmitHandler}
          error={error}
        />
      );
    case Cloud.gcp: {
      const onGCPSubmit = (value: { credentials: GCPCredentials }) => {
        return onSubmit({ credentials: JSON.parse(value.credentials.json) });
      };

      return (
        <GCPCredentialsForm
          onSubmit={onGCPSubmit}
          setSubmitHandler={setSubmitHandler}
          error={error}
        />
      );
    }
    case Cloud.azure: {
      const onAzureSubmit = (value: { credentials: AzureCredentials }) => {
        return onSubmit({ credentials: JSON.parse(value.credentials.json) });
      };

      return (
        <AzureCredentialsForm
          onSubmit={onAzureSubmit}
          setSubmitHandler={setSubmitHandler}
          error={error}
        />
      );
    }

    default:
      return null;
  }
};
