import { useDrawer } from '@cast/design-system';
import { BestPracticeCheck } from '@cast/types';

import { ExceptionsManagementDrawer } from './ExceptionsManagementDrawer';

export const useExceptionsManagementDrawer = () => {
  const { open } = useDrawer();

  const openExceptionsManagementDrawer = (check: BestPracticeCheck) => {
    open(ExceptionsManagementDrawer, {
      check,
    });
  };

  return { openExceptionsManagementDrawer };
};
