import { SyntheticEvent, useState } from 'react';

import {
  capitalize,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
} from '@mui/material';
import clsx from 'clsx';

import { InternalTabsContext } from './InternalTabsContext';
import { useTabsState } from './useTabsState';

type TabsVariant = 'standard' | 'noUnderline' | 'card';
type TabsColor = 'primary' | 'secondary';
type TabsSize = 'small' | 'medium';

export type TabsProps = Omit<MuiTabsProps, 'variant'> & {
  variant?: TabsVariant;
  fullWidth?: boolean;
  color?: TabsColor;
  size?: TabsSize;
  testId?: string;
};

export const Tabs = ({
  children,
  className,
  variant = 'standard',
  color = 'primary',
  size = 'medium',
  fullWidth,
  testId,
  ...rest
}: TabsProps) => {
  const context = useTabsState();
  const [currentTab, setCurrentTab] = useState(
    rest.value || context?.currentTab
  );
  const [tabValueWithHover, setTabValueWithHover] = useState<any>(undefined);
  const activeTab = context?.currentTab || currentTab;
  const setActiveTab = context?.setCurrentTab || setCurrentTab;

  const onChange = (_: SyntheticEvent, value: any) => {
    setActiveTab(value);
    rest.onChange?.(_, value);
  };

  const activeTabHover = tabValueWithHover === activeTab;

  return (
    <InternalTabsContext.Provider
      value={{
        onTabMouseEnter: setTabValueWithHover,
        onTabMouseLeave: () => setTabValueWithHover(undefined),
      }}
    >
      <MuiTabs
        visibleScrollbar={false}
        data-testid={testId}
        {...rest}
        className={clsx(
          'DS-Tabs-root',
          `DS-Tabs-variant${capitalize(variant)}`,
          `DS-Tabs-color${capitalize(color)}`,
          `DS-Tabs-size${capitalize(size)}`,
          activeTabHover && 'DS-Tabs-activeTabHover',
          fullWidth && 'DS-Tabs-fullWidth',
          className
        )}
        onChange={onChange}
        value={activeTab}
      >
        {children}
      </MuiTabs>
    </InternalTabsContext.Provider>
  );
};
