import { styled } from '@mui/material';

import { Chip, ChipProps } from '@cast/design-system';
import { NodeResourceOffering } from '@cast/types';

type Props = {
  resourceOffering: NodeResourceOffering;
  size?: ChipProps['size'];
};

type OwnerState = {
  resourceOffering: Props['resourceOffering'];
};

const ChipBase = styled(Chip, {
  target: 'ResourceOfferingChip-root',
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<
  ChipProps & {
    ownerState?: OwnerState;
  }
>(({ theme, ownerState }) => {
  return [
    {
      ...theme.typography.L10M,
      color: 'white',
      borderRadius: 16,
    },
    ownerState?.resourceOffering === NodeResourceOffering.ON_DEMAND && {
      backgroundColor: theme.palette.blue[300],
    },
    ownerState?.resourceOffering === NodeResourceOffering.FALLBACK && {
      backgroundColor: theme.palette.yellow[400],
    },
    ownerState?.resourceOffering === NodeResourceOffering.SPOT && {
      backgroundColor: theme.palette.indigo[800],
    },
  ];
});

export const ResourceOfferingChip = ({
  resourceOffering,
  size = 'small',
}: Props) => {
  return (
    <ChipBase ownerState={{ resourceOffering }} size={size}>
      {resourceOffering}
    </ChipBase>
  );
};
