import { Divider, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import { PreviewMarkdownProps } from '@cast/design-system';

import { PreviewMarkdown } from 'components/preview-markdown';
import { ExternalLink } from 'components/router';

type Props = { sx?: SxProps } & Omit<PreviewMarkdownProps, 'components'>;

export const CheckDetails = (props: Props) => {
  return (
    <PreviewMarkdown
      {...props}
      components={{
        h1: ({ children }) => (
          <Typography variant="L14B" component="h1" pb={8}>
            {children}
          </Typography>
        ),
        h2: ({ children }) => (
          <Typography variant="L14B" component="h2" pb={8}>
            {children}
          </Typography>
        ),
        h3: ({ children }) => (
          <Typography variant="L14B" component="h3" pb={8}>
            {children}
          </Typography>
        ),
        h4: ({ children }) => (
          <Typography variant="L14B" component="h4" pb={8}>
            {children}
          </Typography>
        ),
        hr: () => <Divider sx={{ my: 32 }} />,
        p: ({ children }) => (
          <Typography variant="P14R" component="p">
            {children}
          </Typography>
        ),
        ol: ({ children }) => (
          <Typography variant="P14R" component="ol" pl={20} pr={10}>
            {children}
          </Typography>
        ),
        a: ({ href, children }) => {
          return <ExternalLink link={href}>{children}</ExternalLink>;
        },
      }}
    />
  );
};
