import { ComponentType } from 'react';

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { DotProps, XAxisProps, YAxisProps } from 'recharts';

import { CustomYAxisTickProps, useIsAnimationActive } from 'components/chart';
import { useChartProps } from 'hooks/useChartProps';
import { ChartConfig } from 'types/charts';
import { TimeSeries } from 'types/metrics';

import { CostReportAreaChart } from './CostReportAreaChart';
import { CostReportBarChart } from './CostReportBarChart';
import { CostReportLineChart } from './CostReportLineChart';
import { getFutureDatapointIndex } from './utils';
import {
  ComposedChartWithCostReportProps,
  CostReportChartType,
} from '../../types/costOverTime';

export type CostReportChartProps<T extends TimeSeries<any> = any> = {
  chartType: CostReportChartType;
  TooltipComponent?: ComponentType<{ payload?: any }>;
  data: T;
  height: number | string;
  config: ChartConfig[];
  yAxisProps?: Omit<YAxisProps, 'tick'> & { tick?: CustomYAxisTickProps };
  xAxisProps?: XAxisProps;
  sx?: SxProps;
  chartSx?: SxProps;
  hideXAxisOuterTicks?: boolean;
  cursorWidth?: number;
  testId?: string;
  syncId?: string;
};

const AnomalyMarker = ({ cx = 0, cy = 0 }: DotProps) => {
  return (
    <svg
      x={cx - 6}
      y={cy - 6}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.35244 1.87512L1.22744 9.00012C1.16172 9.11394 1.12707 9.24303 1.12695 9.37446C1.12684 9.50589 1.16126 9.63504 1.22677 9.74898C1.29229 9.86291 1.38659 9.95763 1.50024 10.0236C1.61388 10.0897 1.74288 10.1247 1.87431 10.1251H10.1243C10.2557 10.1247 10.3847 10.0897 10.4984 10.0236C10.612 9.95763 10.7063 9.86291 10.7718 9.74898C10.8374 9.63504 10.8718 9.50589 10.8717 9.37446C10.8716 9.24303 10.8369 9.11394 10.7712 9.00012L6.64619 1.87512C6.58097 1.76115 6.48679 1.66644 6.3732 1.60056C6.2596 1.53469 6.13062 1.5 5.99931 1.5C5.868 1.5 5.73902 1.53469 5.62543 1.60056C5.51183 1.66644 5.41766 1.76115 5.35244 1.87512V1.87512Z"
        fill="#D15454"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4.875V6.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9C6.31066 9 6.5625 8.74816 6.5625 8.4375C6.5625 8.12684 6.31066 7.875 6 7.875C5.68934 7.875 5.4375 8.12684 5.4375 8.4375C5.4375 8.74816 5.68934 9 6 9Z"
        fill="white"
      />
    </svg>
  );
};

export const CostReportChart = <T extends TimeSeries<any> = any>({
  chartType,
  TooltipComponent,
  data,
  height,
  config,
  yAxisProps = {},
  sx,
  chartSx,
  testId,
  syncId,
}: CostReportChartProps<T>) => {
  const isAnimationActive = useIsAnimationActive(data);
  const computedChartProps = useChartProps(
    data,
    chartType !== CostReportChartType.BAR
  );
  const futureDatapointIndex = getFutureDatapointIndex(computedChartProps.data);

  const chartProps: ComposedChartWithCostReportProps = {
    data: computedChartProps.data,
    chartConfig: config,
    TooltipComponent,
    estimateStartPoint: futureDatapointIndex,
    isAnimationActive,
    composedProps: {
      syncId: syncId || 'cost-over-time',
      reverseStackOrder: true,
    },
    xAxisProps: computedChartProps.xAxisProps,
    yAxisProps: {
      padding: { top: 30 },
      fontSize: 10,
      ...yAxisProps,
    },
    highlightTimestamp: (payload) =>
      'anomalies' in payload && !!payload.anomalies.length,
    highlighterProps: { shape: <AnomalyMarker /> },
  };

  if (chartType === CostReportChartType.AREA) {
    return (
      <Box
        display="flex"
        height={height}
        width="100%"
        sx={sx}
        data-testid={testId}
      >
        <CostReportAreaChart {...chartProps} sx={chartSx} />
      </Box>
    );
  }

  if (chartType === CostReportChartType.BAR) {
    return (
      <Box display="flex" height={height} sx={sx} data-testid={testId}>
        <CostReportBarChart
          {...chartProps}
          sx={chartSx}
          isAutoBarResizing={true}
        />
      </Box>
    );
  }

  if (chartType === CostReportChartType.LINE) {
    return (
      <Box display="flex" height={height} sx={sx} data-testid={testId}>
        <CostReportLineChart {...chartProps} sx={chartSx} />
      </Box>
    );
  }

  return null;
};
