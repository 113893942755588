import { useModal } from 'mui-modal-provider';

import { Organization } from '@cast/types';

import { ShowModalOutput } from 'types/modal';

import { CreatedOrganizationDialog } from './CreatedOrganizationDialog';
import { CreateOrganizationDialog } from './CreateOrganizationDialog';

export const useCreateOrganizationDialog = () => {
  const { showModal } = useModal();

  let createOrgModal: ShowModalOutput;
  let createdOrgModal: ShowModalOutput;

  const openModal = () => {
    createOrgModal = showModal(CreateOrganizationDialog, {
      destroy: (organization?: Organization) => {
        createOrgModal.destroy();

        if (organization) {
          createdOrgModal = showModal(CreatedOrganizationDialog, {
            name: organization.name,
            destroy: () => {
              createdOrgModal.destroy();
            },
          });
        }
      },
    });
  };

  return { openModal };
};
