import { useCallback, useEffect, useMemo, useState } from 'react';

import 'overlayscrollbars/styles/overlayscrollbars.css';

import { BodyRows } from './_components/Rows';
import { TreeRow } from './_components/TreeRow/TreeRow';
import { useLoadingRows } from './_hooks/useLoadingRows';
import { useMaxHeight } from './_hooks/useMaxHeight';
import { useTableContext } from '../../hooks';
import { DraggableState } from '../../types';

// Uncomment to see internal virtuoso logs
// (window as any).VIRTUOSO_LOG_LEVEL = LogLevel.DEBUG;

export const Body = () => {
  const { api, rootRef, tableProps, peripheralsHeight = 0 } = useTableContext();
  const BodyRowComponent = tableProps.BodyRowComponent;
  const {
    disableVirtualization,
    overscan,
    rowHeight,
    useFixedRowHeight,
    treeTable,
    minHeightPx,
    onBodyRendered,
    sysId,
  } = tableProps;
  const disableHeightCalc =
    !tableProps.fillParentHeight &&
    !tableProps.maxHeightPx &&
    !tableProps.maxHeightVh &&
    !tableProps.maxRows &&
    !tableProps.maxHeight;

  const maxHeight = useMaxHeight(
    tableProps,
    rootRef,
    peripheralsHeight,
    disableHeightCalc
  );

  const {
    state: { expandedRows, sortedData },
    columnsInfo: { columnModels, tableWidth },
    tableInfo,
  } = api.current;

  const loadingRows = useLoadingRows(
    sortedData?.length ? sortedData.length : undefined,
    !!tableProps.loadingRowSkeleton
  );

  const renderTree = useCallback(
    (index: number, row: any) => {
      return <TreeRow row={row} index={index} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedRows, api, columnModels, BodyRowComponent, treeTable]
  );

  const renderBody = useCallback(
    (index: number, row: any, draggableState?: DraggableState) => {
      return (
        <BodyRowComponent
          row={row}
          index={index}
          draggableState={draggableState}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedRows, api, columnModels, BodyRowComponent, treeTable]
  );

  const renderRow = treeTable ? renderTree : renderBody;

  const [totalListHeight, setTotalListHeight] = useState<number>(0);

  const bodyHeight = useMemo(() => {
    if (disableHeightCalc) {
      return 0;
    }
    if (!totalListHeight || !maxHeight) {
      return 1;
    }
    const fullTableHeight = totalListHeight + peripheralsHeight;
    let bodyHeight = maxHeight - peripheralsHeight;
    if (fullTableHeight < maxHeight) {
      bodyHeight = totalListHeight;
    }

    if (minHeightPx && minHeightPx > bodyHeight) {
      return minHeightPx;
    }

    return bodyHeight;
  }, [
    peripheralsHeight,
    totalListHeight,
    maxHeight,
    minHeightPx,
    disableHeightCalc,
  ]);

  useEffect(() => {
    tableInfo.setBodyHeight(
      bodyHeight,
      Boolean(tableInfo.bodyHeight) || tableInfo.bodyHeight !== bodyHeight
    );
    onBodyRendered?.(api.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyHeight]);

  if (!sortedData?.length && !loadingRows.length) {
    return null;
  }

  return (
    <div className="DS-Table-Body" data-sysid={sysId}>
      <BodyRows
        setTotalListHeight={setTotalListHeight}
        rows={tableProps.isLoading ? loadingRows : sortedData}
        fixedItemHeight={useFixedRowHeight ? (rowHeight as number) : undefined}
        renderRow={renderRow}
        overscan={disableVirtualization ? Number.MAX_SAFE_INTEGER : overscan}
        width={tableWidth}
        height={bodyHeight}
      />
    </div>
  );
};
