import { Divider, Stack, Typography } from '@mui/material';

import { Button, DialogTitle, IconButton, Icons } from '@cast/design-system';
import { Cluster } from '@cast/types';

import { EXTERNAL_LINKS } from 'common/links';
import { CloseDialogButton } from 'components/dialogs';
import { K8sProviderIcon } from 'components/icons';
import { openChat } from 'utils/supportChat';

type Props = { cluster: Cluster; onClose?: () => void };

export const OnboardedClusterDialogHeader = ({ cluster, onClose }: Props) => {
  return (
    <DialogTitle sx={{ p: '16px 32px' }}>
      <Stack direction="row" data-testid="reconnect-cluster-header">
        <Stack direction="row" gap={4}>
          <K8sProviderIcon provider={cluster.providerType} size={24} />
          <Typography variant="A1">{cluster.name}</Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          sx={{ position: 'absolute', top: 10, right: 8 }}
        >
          <Button
            variant="text"
            component="a"
            href={EXTERNAL_LINKS.castai_terraform}
            target="_blank"
          >
            Terraform
          </Button>

          <Divider
            orientation="vertical"
            sx={{
              paddingY: '4px',
              height: '20px',
              mx: '8px',
            }}
          />

          <IconButton variant="ghost" size="small" onClick={openChat}>
            <Icons.Question />
          </IconButton>

          <CloseDialogButton
            onClose={onClose}
            sx={{ position: 'relative', top: 'unset', right: 'unset' }}
          />
        </Stack>
      </Stack>
    </DialogTitle>
  );
};
