import { PersistenceDriver } from '../../persistence/types';

const makeBrowserStorageDriver = (
  storage: typeof localStorage | typeof sessionStorage
): PersistenceDriver => {
  return {
    retrieve: (id) => {
      const retrievedState = storage.getItem(`${id}_state`);
      if (!retrievedState) {
        return;
      }
      return JSON.parse(retrievedState);
    },
    accept: (id, state) => {
      storage.setItem(`${id}_state`, JSON.stringify(state));
    },
  };
};

export const persistenceDrivers = {
  local: makeBrowserStorageDriver(localStorage),
  session: makeBrowserStorageDriver(sessionStorage),
} satisfies Record<string, PersistenceDriver>;
