import { styled } from '@mui/material';

export type ChartIndicatorProps = {
  variant: 'filled' | 'outlined';
  size: 'large' | 'small';
  color: string;
};

export const ChartIndicator = styled('div', {
  name: 'DsChartIndicator',
  slot: 'Root',
  target: 'DsChartIndicator-root',
  shouldForwardProp: (propName) =>
    propName !== 'size' && propName !== 'color' && propName !== 'variant',
  overridesResolver: (props, styles) => [
    styles.root,
    styles[props.size],
    props.variant === 'filled'
      ? {
          backgroundColor: props.color,
        }
      : {
          border: `2px solid ${props.color}`,
        },
  ],
})<ChartIndicatorProps>({});
