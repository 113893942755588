import { ThemeOptions } from '@mui/material';

import { indigo } from '../../theme/colors';
import { cssVars } from '../../utils/cssVars';

const { getters, setValues } = cssVars({
  alertTextColor: indigo[400],
  borderColor: indigo[100],
  backgroundColor: indigo[50],
});

export const alertsTheme: ThemeOptions['components'] = {
  MuiAlertTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.P20B,
        textTransform: 'capitalize',
        margin: 0,
        '.DSuiAlert-hasChildren &': {
          marginBottom: '4px',
        },
        '.DSuiAlert-small &': {
          ...theme.typography.P14B,
          marginBottom: '0',
        },
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: '4px 12px',
        border: '1px solid',
        alignItems: 'flex-start',
        ...setValues({ alertTextColor: theme.palette.indigo[400] }),

        '&.DS-Alert-noIcon': {
          '& .MuiAlert-icon': {
            display: 'none',
          },
        },
        '& .DSuiAlert-content': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '12px',
        },
        '&.DS-AlertColor': {
          '&Exceptional': setValues({
            alertTextColor: theme.palette.indigo[400],
            borderColor: theme.palette.indigo[100],
            backgroundColor: theme.palette.indigo[50],
          }),
          '&Error': setValues({
            alertTextColor: theme.palette.red[500],
            borderColor: theme.palette.red[100],
            backgroundColor: theme.palette.red[50],
          }),
          '&Warning': setValues({
            alertTextColor: theme.palette.yellow[900],
            borderColor: theme.palette.yellow[100],
            backgroundColor: theme.palette.yellow[50],
          }),
          '&Success': setValues({
            alertTextColor: theme.palette.green[700],
            borderColor: theme.palette.green[100],
            backgroundColor: theme.palette.green[50],
          }),
          '&Info': setValues({
            alertTextColor: theme.palette.blue[600],
            borderColor: theme.palette.blue[100],
            backgroundColor: theme.palette.blue[50],
          }),
          '&Advisory': setValues({
            alertTextColor: theme.palette.grey[900],
            borderColor: theme.palette.grey[300],
            backgroundColor: theme.palette.grey[200],
          }),
        },
        '&.MuiAlert-filled': {
          borderColor: getters.borderColor(),
          backgroundColor: getters.backgroundColor(),
        },

        '&.MuiAlert-outlined': {
          borderColor: getters.alertTextColor(),
        },

        '& .DSuiAlert-close': {
          fontSize: '16px',
          color: getters.alertTextColor(),

          '&:hover': {
            opacity: 0.7,
            cursor: 'pointer',
          },
          '&:active': {
            opacity: '1',
          },
        },
        '&& .MuiSvgIcon-root': {
          strokeLinecap: 'round',
          fontSize: '16px',

          '.DSuiAlert-hasTitle &': {
            fontSize: '20px',
          },
        },
      }),
      icon: {
        opacity: '1',
        fontSize: '20px',
        '& svg': {
          color: getters.alertTextColor(),
        },
        '.DSuiAlert-hasTitle &': {
          fontSize: '24px',
        },
        '.DSuiAlert-hasTitle.DSuiAlert-small &': {
          fontSize: '20px',
        },
        '.DSuiAlert-small &': {
          fontSize: '16px',
          marginRight: '8px',
        },
      },
      action: {
        marginRight: '0',
        paddingTop: 8,
      },
      message: ({ theme }) => ({
        ...theme.typography.P14R,
        paddingTop: '7px', // acounts for 1px border
        color: getters.alertTextColor(),

        '.DSuiAlert-small &': {
          ...theme.typography.P12R,
        },
      }),
    },
  },
};
