import { PropsWithChildren, ReactNode } from 'react';

import { useRffFormState } from './hooks';
import { FormMode } from './types';

type Props = PropsWithChildren<{
  mode?: FormMode | FormMode[];
  fallback?: ReactNode;
}>;

export const WhenMode = ({ mode, fallback = null, children }: Props) => {
  const { mode: currentMode } = useRffFormState();
  if (!currentMode) {
    throw new Error('ForMode has to be used inside RffForm');
  }
  if (Array.isArray(mode)) {
    if (mode.includes(currentMode)) {
      return <>{children}</>;
    }
  } else if (currentMode === mode) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};
