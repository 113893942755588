import { AVERAGE_HOURS_IN_MONTH } from '@cast/constants';
import { convertUnit } from '@cast/utils';

import { useCurrentPrice } from '../_hooks/useCurrentPrice';
import { useAvailableSavingsPauseSchedule } from '../_providers/PauseScheduleProvider';
import { useWorkloadRightsizingState } from '../_providers/WorkloadsRightsizingProvider';
import { useCombinedAvailableSavings } from '../hooks/useCombinedAvailableSavings';

/**
 * @returns calculations based on the configured preferences baseline, additionally includes the total number of active running cluster hours per month
 */
export const useAdditionalSavings = () => {
  const { currentMonthlyPrice } = useCurrentPrice();
  const { monthlyPrice, monthlySavings } = useCombinedAvailableSavings();
  const { hoursRunningPerMonth, clusterScheduleEnabled } =
    useAvailableSavingsPauseSchedule();
  const {
    recommendations,
    workloadRightsizingEnabled,
    metricsServerAvailable,
    avgCpuPrice,
    avgRamPrice,
  } = useWorkloadRightsizingState();

  const hoursRatio = clusterScheduleEnabled
    ? hoursRunningPerMonth > 0
      ? hoursRunningPerMonth / AVERAGE_HOURS_IN_MONTH
      : 0
    : 1;

  const cpuCoresDifference =
    Number(recommendations?.summary?.cpuCoresDifference || 0) * hoursRatio;

  const ramBytesDifference =
    Number(recommendations?.summary?.ramBytesDifference || 0) * hoursRatio;

  const potentialSavings =
    metricsServerAvailable && workloadRightsizingEnabled
      ? avgCpuPrice * cpuCoresDifference * AVERAGE_HOURS_IN_MONTH +
        avgRamPrice *
          convertUnit(ramBytesDifference, 'Bytes', 'GiB') *
          AVERAGE_HOURS_IN_MONTH
      : 0;

  const _monthlyPrice = monthlyPrice - potentialSavings;
  const _monthlySavings = monthlySavings + potentialSavings;

  const totalPercentage = 100 - (_monthlyPrice / currentMonthlyPrice) * 100;

  const rightsizingSavingsRatioPercentage =
    _monthlySavings > 0 ? (potentialSavings * 100) / _monthlySavings : 0;

  const configurationSavingsRatioPercentage =
    totalPercentage - rightsizingSavingsRatioPercentage;

  const _recommendations = {
    cpuCoresDifference,
    ramBytesDifference,
    cpuEfficiency: recommendations?.summary?.cpuEfficiency,
    efficiency: recommendations?.summary?.efficiency,
    memoryEfficiency: recommendations?.summary?.memoryEfficiency,
  };

  return {
    monthlyPrice: _monthlyPrice,
    monthlySavings: _monthlySavings,
    rightsizingSavingsRatioPercentage,
    configurationSavingsRatioPercentage,
    totalPercentage,
    potentialSavings,
    recommendations: _recommendations,
  };
};
