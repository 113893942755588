import { ThemeOptions } from '@mui/material';

import { grey } from '../../../theme/colors';
//
export const formLabelTheme: ThemeOptions['components'] = {
  DsFormLabel: {
    styleOverrides: {
      root: {
        fontSize: '12px',
        lineHeight: '18px',
        color: grey[900],
        fontWeight: 500,
        transform: 'unset',
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        marginBottom: '4px',
        '.Mui-error &, .Mui-focused &': {
          color: grey[900],
        },
        '.Mui-disabled &, &.DS-disabled': {
          color: grey[300],
        },
      },
      small: {
        fontSize: '10px',
        lineHeight: '16px',
      },
      medium: {
        fontSize: '12px',
        lineHeight: '18px',
      },
      large: {
        fontSize: '12px',
        lineHeight: '18px',
      },
      icon: {
        fontSize: '1.1em',
        pointerEvents: 'initial',
      },
      empty: {
        whiteSpace: 'pre',
      },
    },
  },
};
