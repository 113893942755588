import { ReactNode } from 'react';

import { Button, Icons } from '@cast/design-system';

import { HexIconNotification } from 'components/hex-icons';
import { StatusMessage } from 'components/messages';
import { Link as RouterLink } from 'components/router/Link';

type Props = {
  body?: ReactNode;
  action?: ReactNode;
};

export const ErrorPage403 = ({
  body = 'It seems you don’t have permissions to access the page.',
  action,
}: Props) => {
  return (
    <StatusMessage
      stackProps={{ mt: 180 }}
      icon={<HexIconNotification icon={<Icons.Lock weight="fill" />} />}
      title="Access denied"
      titleProps={{ variant: 'h4' }}
      body={body}
      bodyProps={{ variant: 'P16R', sx: { whiteSpace: 'pre-line' } }}
      actions={
        action === undefined ? (
          <Button
            fullWidth
            variant="primary"
            component={RouterLink}
            to="/dashboard"
            startIcon={<Icons.ArrowClockwise />}
          >
            Go to cluster list
          </Button>
        ) : (
          action
        )
      }
      testId="error-page"
    />
  );
};
