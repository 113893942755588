import groupBy from 'lodash/groupBy';

import { AvailableSavingsNode } from '@cast/types';

import { bytesToGiBiBytes } from '../unitConverters';

type ModifiedAvailableSavingsNode = AvailableSavingsNode & {
  quantity?: number;
  ramGiBiBytes?: number;
};

export const groupUniqueNodesWithCount = (
  nodes?: AvailableSavingsNode[]
): ModifiedAvailableSavingsNode[] => {
  if (!nodes) {
    return [];
  }
  const uniqueNodes: ModifiedAvailableSavingsNode[] = [];
  const groupedNodes = groupBy(
    nodes,
    ({ instanceType, spot, fallback, infra, master, os }) =>
      `${instanceType}${os}${spot ? '-spot' : ''}${
        fallback ? '-fallback' : ''
      }${infra ? '-infra' : ''}${master ? '-master' : ''}`
  );

  Object.keys(groupedNodes).forEach((nodeKey) => {
    const node = groupedNodes[nodeKey][0];

    uniqueNodes.push({
      ...node,
      quantity: groupedNodes[nodeKey].length,
      ramGiBiBytes: bytesToGiBiBytes(node.ramBytes),
    });
  });
  return uniqueNodes;
};
