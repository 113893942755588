import { useEffect } from 'react';

import { usePrevious } from '@cast/utils';

import { useClusterQuery } from 'hooks/queries/cluster';

export const useWasCredentialsAssigned = (
  clusterId: string,
  onCredentialsAssigned?: () => void,
  enabled?: boolean
) => {
  const { cluster: _cluster } = useClusterQuery(
    clusterId,
    enabled ? undefined : false
  );

  const isManaged = Boolean(_cluster?.credentialsId);
  const wasManaged = usePrevious(isManaged);

  useEffect(() => {
    if (
      enabled &&
      typeof wasManaged === 'boolean' &&
      !wasManaged &&
      isManaged
    ) {
      onCredentialsAssigned?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasManaged, isManaged]);

  return isManaged;
};
