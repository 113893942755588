import { forwardRef, HTMLProps, MouseEventHandler, useContext } from 'react';

import { styled } from '@mui/material';
import { IconContext, MagnifyingGlass, XCircle } from 'phosphor-react';

import { ListContext } from './List';
import { ListItem, ListItemProps } from './ListItem';
import { IconButton } from '../buttons';
import { Input, InputProps } from '../controls/input';

type ClearButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
};

export type ListSearchInputProps = InputProps & {
  showClearButton?: boolean;
  listItemProps?: ListItemProps;
  clearButtonProps?: ClearButtonProps;
};

const sizeToIconSize = {
  small: '16px',
  medium: '16px',
  large: '20px',
};

const mergeInputProps = (
  { onMouseDown, ...inputProps }: HTMLProps<HTMLInputElement>,
  disabled?: boolean,
  readOnly?: boolean
): HTMLProps<HTMLInputElement> => {
  return {
    ...inputProps,
    onMouseDown:
      disabled || readOnly
        ? undefined
        : onMouseDown
        ? (e) => {
            onMouseDown(e);
          }
        : onMouseDown,
  };
};

const StyledInput = styled(Input, {
  name: 'DsListSearchInput',
  slot: 'Root',
  target: 'DsListSearchInput-root',
  overridesResolver: ({ size }, styles) => {
    return [styles.root, styles[size]];
  },
})<InputProps>({});

StyledInput.displayName = 'StyledInput';

export const ListSearchInput = forwardRef<
  HTMLInputElement,
  ListSearchInputProps
>(
  (
    {
      inputProps = {},
      listItemProps = {},
      clearButtonProps,
      showClearButton,
      testId,
      ...props
    }: ListSearchInputProps,
    ref
  ) => {
    const listContext = useContext(ListContext);
    const size = listContext?.size || props.size || 'medium';

    const clearInput = showClearButton &&
      !!clearButtonProps &&
      (!!inputProps.value || !!props.value) && (
        <IconButton
          {...clearButtonProps}
          className="clearInput"
          testId="clear-input-btn"
          size={size}
          onClick={!props.disabled ? clearButtonProps.onClick : undefined}
        >
          <XCircle weight="fill" />
        </IconButton>
      );

    return (
      <ListItem disableHover disableGutters testId={testId} {...listItemProps}>
        <IconContext.Provider value={{ size: sizeToIconSize[size] }}>
          <StyledInput
            {...props}
            size={size}
            inputProps={{
              ...mergeInputProps(inputProps, props.disabled, props.readOnly),
              ref,
            }}
            startOutsideAdornment={<MagnifyingGlass />}
            endOutsideAdornment={clearInput}
          />
        </IconContext.Provider>
      </ListItem>
    );
  }
);

ListSearchInput.displayName = 'ListSearchInput';
