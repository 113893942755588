import { styled } from '@mui/material';

export const CarouselItem = styled('div', { target: 'carousel-slide' })({
  position: 'relative',
  height: '198px',
  width: '350px',
  '&:after': {
    pointerEvents: 'none',
    content: '""',
    position: 'absolute',
    inset: 0,
    transition: 'background 0.3s ease-in-out',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  '&:hover:after': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
});

CarouselItem.displayName = 'CarouselItem';
