import { useCallback, useRef } from 'react';

import dayjs from 'dayjs';

import { DateTime } from '@cast/types';

type StartJob = {
  when: DateTime;
  job: () => void;
  intervalMs?: number;
};
export const useScheduledJob = () => {
  const intervalRef = useRef<number>();
  const clear = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  }, [intervalRef]);

  const start = useCallback(
    ({ when, job, intervalMs = 1000 }: StartJob) => {
      const handle = setInterval(() => {
        const now = dayjs();
        if (now.isAfter(when) || now.isSame(when)) {
          job();
          clear();
        }
      }, intervalMs);
      intervalRef.current = handle as any;
    },
    [clear, intervalRef]
  );

  return {
    start,
    clear,
  };
};
