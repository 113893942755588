import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import { FeatureFlagResolved } from '@cast/types';

import { apiClient } from 'core/api-client';
import { useAuth } from 'core/auth/hooks/useAuth';
import { QueryKeys } from 'core/react-query/constants';
import { useOrganizations } from 'hooks/useOrganizations';

export type UseFlagsQueryArgs = {
  clusterId?: string;
  flagNamePrefix?: string;
  flagName?: string;
  enabled?: boolean;
};

// const generateTracingId = () => {
//   return `trace-${Math.random().toString(36).substring(2, 15)}`;
// };

export const useFlagsQuery = ({
  clusterId,
  flagName,
  flagNamePrefix,
  enabled = true,
}: UseFlagsQueryArgs = {}) => {
  const { currentOrganization: organization } = useOrganizations();
  const { user } = useAuth();

  return useQuery<FeatureFlagResolved[]>({
    queryKey: [
      QueryKeys.FLAGS,
      organization?.id,
      clusterId,
      flagNamePrefix,
      flagName,
    ],
    retry: 3,
    queryFn: async () => {
      const { data } = await apiClient.featureFlags.resolveFlags({
        message: {
          flagNamePrefix,
          flagName,
          payload: {
            context: {
              environment: window.env.VITE_ENV,
              organizationId: organization?.id,
              userId: user?.id,
              // traceId: generateTracingId(),
              clusterId,
            },
          },
        },
      });

      return data.items ?? [];
    },
    enabled: enabled && !isEmpty(organization) && !isEmpty(user),
  });
};
