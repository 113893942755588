import { QueryKeys } from './constants';

export const QueryKeyFactory = {
  securityImageVulnerabilities: (params: {
    tagId: string;
    packageId?: string;
    exceptionFilter?: string;
  }) => [
    QueryKeys.SECURITY_IMAGE_VULNERABILITIES,
    params.tagId,
    params.packageId,
    params.exceptionFilter,
  ],
  securityImageDetails: (params: { tagId: string }) => [
    QueryKeys.SECURITY_IMAGE_DETAILS,
    params.tagId,
  ],
  securityImagePackages: (params: {
    tagId: string;
    exceptionFilter: string;
  }) => [
    QueryKeys.SECURITY_IMAGE_PACKAGES,
    params.tagId,
    params.exceptionFilter,
  ],
};
