import { createContext, forwardRef, useContext } from 'react';

import { styled, capitalize } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

export type AvatarProps = {
  text: string;
  variant?: 'circular' | 'square';
  size?: 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs';
  color?: 'blue' | 'green' | 'indigo' | 'aqua' | 'yellow' | 'red' | 'grey';
  className?: string;
  disabled?: boolean;
  active?: boolean;
  testId?: string;
  sx?: SxProps;
};

const AvatarRoot = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLButtonElement, AvatarProps>(
    ({ className, active, disabled, text, testId }: AvatarProps, ref) => {
      return (
        <ButtonBase
          ref={ref}
          className={clsx(className, { active })}
          disabled={disabled}
          disableRipple
          data-testid={testId}
        >
          {text.substring(0, 2)}
        </ButtonBase>
      );
    }
  ),
  {
    name: 'DsAvatar',
    slot: 'Root',
    target: 'DsAvatar-root',
    overridesResolver: ({ variant, size, color, disabled }, theme) => {
      return [
        theme.root,
        color ? theme[variant + capitalize(color.toString())] : {},
        theme[variant],
        theme[size],
        disabled ? theme.disabled : {},
      ];
    },
  }
)<AvatarProps>({});

AvatarRoot.displayName = 'AvatarRoot';

export const AvatarContext = createContext<Partial<AvatarProps> | undefined>(
  undefined
);

export const Avatar = forwardRef<HTMLButtonElement, AvatarProps>(
  (props: AvatarProps, ref) => {
    const avatarContext = useContext(AvatarContext) ?? {};
    return <AvatarRoot {...avatarContext} {...props} ref={ref} />;
  }
);

Avatar.displayName = 'Avatar';
