export const dashboardTourId = 'dashboard';

export const tourSteps = {
  clustersTable: 'clusters-table',
  featuresCards: 'features-cards',
  connectCluster: 'connect-cluster',
  organization: 'organization',
};

export const dashboardTourStepsOrder = [
  tourSteps.clustersTable,
  tourSteps.featuresCards,
  tourSteps.organization,
  tourSteps.connectCluster,
];
