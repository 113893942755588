import { useAvailableSavingsQuery } from 'hooks/queries/available-savings';
import { useCluster } from 'hooks/useCluster';

import { useAvailableSavingsSpotAnalysis } from '../_providers/SpotAnalysisProvider';
import { SpotAnalysisMode } from '../types';

export const useAvailableSavingsRecommendations = () => {
  const { cluster } = useCluster();
  const { data } = useAvailableSavingsQuery(cluster.id);
  const spotCtx = useAvailableSavingsSpotAnalysis();

  if (spotCtx.spotAnalysisEnabled) {
    return spotCtx.spotAnalysisMode === SpotAnalysisMode.SPOT_FRIENDLY_WORKLOADS
      ? data?.recommendations?.SpotInstances
      : data?.recommendations?.SpotOnly;
  } else {
    return data?.recommendations?.Layman;
  }
};
