import { ReactNode } from 'react';

import { Box, styled, SxProps } from '@mui/material';
import clsx from 'clsx';
import { highlight, languages } from 'prismjs';
import Editor from 'react-simple-code-editor';

import {
  DsFormHelperText,
  DsFormLabel,
  FormLabelProps,
} from '@cast/design-system';
import './code-editor.css';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-yaml';
import './cel-language';

const highlightJson = (code: string) => {
  return highlight(code, languages.js, 'js');
};
const highlightBash = (code: string) => highlight(code, languages.bash, 'bash');
const highlightYaml = (code: string) => highlight(code, languages.yaml, 'yaml');
const highlightCel = (code: string) => highlight(code, languages.cel, 'cel');

const highlightFnMap: Record<
  'json' | 'bash' | 'yaml' | 'cel',
  (c: string) => string | ReactNode
> = {
  bash: highlightBash,
  json: highlightJson,
  yaml: highlightYaml,
  cel: highlightCel,
};

const StyledEditor = styled(Editor)({
  fontSize: 12,
  borderRadius: '4px',
});

type Padding<T> = T | { top?: T; right?: T; bottom?: T; left?: T };

export type CodeEditorProps = {
  className?: string;
  hint?: ReactNode;
  error?: ReactNode;
  label?: ReactNode;
  highlight: keyof typeof highlightFnMap;
  onChange?: (value: string) => void;
  sx?: SxProps;
  rows?: number;

  // Props for the component
  value: string;
  tabSize?: number;
  insertSpaces?: boolean;
  ignoreTabKey?: boolean;
  padding?: Padding<number | string>;
  style?: React.CSSProperties;

  textareaId?: string;
  textareaClassName?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  form?: string;
  maxLength?: number;
  minLength?: number;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  onClick?: React.MouseEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;

  // Props for the hightlighted code’s pre element
  preClassName?: string;
} & FormLabelProps;

export const CodeEditor = ({
  className,
  onChange,
  hint,
  error,
  highlight,
  label,
  labelTooltipProps,
  sx,
  testId,
  ...rest
}: CodeEditorProps) => {
  return (
    <Box
      className={clsx('DS-CodeEditor-root', className)}
      sx={sx}
      data-testid={testId}
    >
      {!!label && (
        <DsFormLabel
          className="DS-CodeEditorLabel-root"
          labelTooltipProps={labelTooltipProps}
        >
          {label}
        </DsFormLabel>
      )}
      <StyledEditor
        onValueChange={(val) => {
          onChange?.(val);
        }}
        highlight={highlightFnMap[highlight]}
        className={clsx(`code-editor-${highlight} CodeEditor-editor`, {
          error: !!error,
        })}
        {...(rest as any)}
      />
      {!!(error || hint) && (
        <DsFormHelperText className="DS-CodeEditor-hint" error={!!error}>
          {error || hint}
        </DsFormHelperText>
      )}
    </Box>
  );
};
