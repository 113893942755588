import {
  CSSProperties,
  Fragment,
  memo,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Stack, Typography } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { ItemProps, Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { Spinner } from '../../../../feedback';
import { useTableContext } from '../../../hooks/useTableContext';
import { DraggableState } from '../../../types';
import { getDraggableContainer } from '../../DraggableContainer';
import { TableScroller } from '../../TableScroller';

type Props = {
  setTotalListHeight: (height: number) => void;
  rows?: any;
  fixedItemHeight?: number;
  renderRow: (
    index: number,
    row: any,
    draggableState?: DraggableState
  ) => ReactNode;
  overscan: number;
  width?: number;
  height?: number;
};

const DraggableRowContainer = ({ children, ...props }: ItemProps<any>) => {
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);

  return (
    <div
      {...props}
      className="DS-Table-DraggableRowContainer"
      style={
        {
          '--child-height': `${size}px`,
        } as any
      }
    >
      {children}
    </div>
  );
};

const Footer = () => {
  const { tableProps } = useTableContext();
  if (!tableProps.isLoading && tableProps.isFetching) {
    return (
      <Stack
        height={tableProps.rowHeight}
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={12}
        borderTop="grey.100"
      >
        <Spinner size={16} color="tertiary" />
        <Typography variant="P12R" color="textSecondary">
          {tableProps.loadingMoreText ?? 'Loading more...'}
        </Typography>
      </Stack>
    );
  }
  return null;
};

export const BodyRows = memo(
  ({
    rows,
    fixedItemHeight,
    renderRow,
    overscan,
    width,
    height,
    setTotalListHeight,
  }: Props) => {
    const { tableProps, rootRef, api } = useTableContext();
    const {
      rowKey,
      scrollToIndex: initialTopMostItemIndex,
      isLoading,
    } = tableProps;

    const virtuoseRef = useRef<VirtuosoHandle>(null);

    const hasScrolled = useRef(false);
    useEffect(() => {
      if (!isLoading && !hasScrolled.current && virtuoseRef.current) {
        hasScrolled.current = true;
        if (initialTopMostItemIndex != null) {
          virtuoseRef.current.scrollToIndex(initialTopMostItemIndex);
        }
      }
    }, [initialTopMostItemIndex, isLoading]);

    if (!height) {
      return (
        <div>
          {rows.map((row: any, index: number) => (
            <Fragment key={rowKey(row)}>{renderRow(index, row)}</Fragment>
          ))}
        </div>
      );
    }

    const virtuosoStyle: CSSProperties = {
      height,
      width,
    };

    if (tableProps.reorderableRows) {
      return (
        <DragDropContext
          onDragEnd={({ reason, destination, draggableId }) => {
            if (reason === 'DROP' && destination) {
              api.current.reorderRow({
                rowKey: draggableId,
                newIndex: destination.index,
              });
            }
          }}
        >
          <Droppable
            droppableId={
              tableProps.testId || tableProps.sysId || 'droppable-table-rows'
            }
            mode="virtual"
            renderClone={(provided, draggableSnapshot, rubric) => {
              return renderRow(rubric.source.index, rows[rubric.source.index], {
                provided,
                draggableSnapshot,
                rubric,
              });
            }}
            getContainerForClone={() => getDraggableContainer(rootRef!)}
            ignoreContainerClipping
          >
            {(provided, droppableSnapshot) => {
              return (
                <Virtuoso
                  ref={virtuoseRef}
                  components={{
                    Scroller: TableScroller,
                    Footer,
                    Item: DraggableRowContainer,
                  }}
                  style={virtuosoStyle}
                  totalListHeightChanged={setTotalListHeight}
                  data={rows}
                  fixedItemHeight={fixedItemHeight}
                  overscan={{ main: overscan, reverse: overscan }}
                  endReached={tableProps.onLoadMore}
                  scrollerRef={(ref) => {
                    if (ref) {
                      provided.innerRef(ref as HTMLElement);
                    }
                  }}
                  computeItemKey={(index, item) =>
                    tableProps.rowKey(item) ?? index
                  }
                  itemContent={(index, item) => {
                    const key = tableProps.rowKey(item);
                    return (
                      <Draggable draggableId={key} index={index} key={key}>
                        {(provided, draggableSnapshot, rubric) => {
                          return renderRow(index, item, {
                            provided,
                            droppableSnapshot,
                            draggableSnapshot,
                            rubric,
                          });
                        }}
                      </Draggable>
                    );
                  }}
                />
              );
            }}
          </Droppable>
        </DragDropContext>
      );
    }

    return (
      <Virtuoso
        ref={virtuoseRef}
        style={virtuosoStyle}
        totalListHeightChanged={setTotalListHeight}
        data={rows}
        fixedItemHeight={fixedItemHeight}
        itemContent={renderRow}
        overscan={{ main: overscan, reverse: overscan }}
        components={{
          Scroller: TableScroller,
          Footer,
        }}
        endReached={tableProps.onLoadMore}
      />
    );
  },
  isEqual
);

BodyRows.displayName = 'BodyRows';
