import { ThemeOptions } from '@mui/material';

import { cssVars } from '../../../utils/cssVars';

const { getters, setValues } = cssVars(
  {
    color: undefined,
    size: undefined,
    fontSize: undefined,
  },
  'ds-badge'
);

export const badgeTheme: ThemeOptions['components'] = {
  DsBadge: {
    defaultProps: {
      variant: 'primary',
      size: 'small',
      count: 0,
    },

    styleOverrides: {
      wrapper: {
        display: 'inline-flex',
        position: 'relative',
      },
      small: setValues({
        size: '16px',
        fontSize: '10px',
      }),
      medium: setValues({
        size: '20px',
        fontSize: '12px',
      }),
      large: setValues({
        size: '24px',
        fontSize: '12px',
      }),
      dot: setValues({
        size: '8px',
        fontSize: '0px',
      }),
      topLeft: {
        top: 0,
        left: 0,
        transform: 'scale(1) translate(-50%, -50%)',
      },
      topRight: {
        top: 0,
        right: 0,
        transform: 'scale(1) translate(50%, -50%)',
        transformOrigin: '100% 0%',
      },
      bottomLeft: {
        bottom: 0,
        left: 0,
        transform: 'scale(1) translate(-50%, 50%)',
        transformOrigin: '0% 100%',
      },
      bottomRight: {
        bottom: 0,
        right: 0,
        transform: 'scale(1) translate(50%, 50%)',
        transformOrigin: '100% 100%',
      },
      primary: ({ theme }) =>
        setValues({
          color: theme.palette.red[400],
        }),
      secondary: ({ theme }) =>
        setValues({
          color: theme.palette.blue[400],
        }),
      tertiary: ({ theme }) =>
        setValues({
          color: theme.palette.green[400],
        }),

      root: {
        position: 'absolute',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '40px',
        minWidth: getters.size(),
        height: getters.size(),
        backgroundColor: getters.color(),
        color: 'white',
        padding: '0 4px',
      },
      triangle: {
        position: 'absolute',
        fill: getters.color(),
      },
      label: ({ theme }) => ({
        ...theme.typography.P16M,
        fontSize: getters.fontSize(),
      }),
    },
  },
};
