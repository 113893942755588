import { ReactNode } from 'react';

import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
} from '@mui/material';
import clsx from 'clsx';

export type ToggleButtonProps = Omit<
  MuiToggleButtonProps<
    'button',
    { icon?: ReactNode; text?: string; testId?: string }
  >,
  'color'
>;

export const ToggleButton = ({
  icon,
  text,
  className,
  testId,
  ...props
}: ToggleButtonProps) => {
  let buttonType = null;
  if (icon && text) {
    buttonType = 'Mixed';
  } else if (icon && !text) {
    buttonType = 'Icon';
  } else if (!icon && text) {
    buttonType = 'Text';
  }
  if (!buttonType) {
    console.error('Toggle button must have either icon, text or both');
    return null;
  }
  return (
    <MuiToggleButton
      {...props}
      className={clsx(
        className,
        'DsToggleButton-root',
        `DsToggleButton-type${buttonType}`
      )}
      data-testid={`${testId}-toggle`}
    >
      {icon}
      {text && <span className="DsToggleButton-text">{text}</span>}
    </MuiToggleButton>
  );
};
