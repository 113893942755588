import { forwardRef, HTMLProps, useContext } from 'react';

import { styled } from '@mui/material';
import clsx from 'clsx';

import Input, { InputProps } from '../../input/Input';
import { EndAdornment } from '../_components/EndAdornment';
import { StartAdornment } from '../_components/StartAdornment';
import { DropdownContext } from '../DropdownContext';

type Props = InputProps;

const mergeInputProps = (
  { onBlur, onFocus, ...inputProps }: HTMLProps<HTMLInputElement>,
  {
    onBlur: userOnBlur,
    onFocus: userOnFocus,
    onMouseDown: userOnMouseDown,
    ...userInputProps
  }: HTMLProps<HTMLInputElement>,
  disabled?: boolean,
  readOnly?: boolean
): HTMLProps<HTMLInputElement> => {
  return {
    ...inputProps,
    ...userInputProps,
    onBlur:
      onBlur && userOnBlur
        ? (e) => {
            onBlur(e);
            userOnBlur(e);
          }
        : onBlur || userOnBlur,
    onFocus: disabled
      ? undefined
      : onFocus && userOnFocus
      ? (e) => {
          onFocus(e);
          userOnFocus(e);
        }
      : onFocus || userOnFocus,
    onMouseDown:
      disabled || readOnly
        ? undefined
        : userOnMouseDown
        ? (e) => {
            userOnMouseDown(e);
          }
        : undefined,
  };
};

const StyledInput = styled(Input, {
  name: 'DsDropdown',
  slot: 'Input',
  target: 'DsDropdown-Input',
  overridesResolver: (_, styles) => {
    return [styles.input];
  },
})({});
StyledInput.displayName = 'StyledInput';

export const DropdownInput = forwardRef<HTMLInputElement, Props>(
  // eslint-disable-next-line react/prop-types
  ({ className, inputProps = {}, ...rest }, ref) => {
    const {
      dropdownProps: {
        size,
        multiple,
        inputProps: userInputProps = {},
        prefix,
        startOutsideAdornment,
        endOutsideAdornment,
        disabled: rootDisabled,
      },
      autocomplete: {
        states: { popupOpen, value, showMultipleValueText },
      },
    } = useContext(DropdownContext);
    const disabled = rest.disabled || userInputProps.disabled || rootDisabled;
    const readOnly = rest.readOnly || userInputProps.readOnly;

    const _inputProps = mergeInputProps(
      {
        onKeyDown: (event) => {
          const allowedKeys = ['Escape', 'Enter', 'Tab'];
          if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
            event.stopPropagation();
          }
        },
      },
      inputProps,
      disabled,
      readOnly
    );

    return (
      <StyledInput
        {...userInputProps}
        {...rest}
        className={clsx(
          'DS-DropdownInput-root',
          `DS-DropdownInput-popup${popupOpen ? 'Open' : 'Closed'}`,
          multiple && 'DS-DropdownInput-multiple',
          showMultipleValueText && 'DS-DropdownInput-multipleValueSelected',
          className
        )}
        size={size}
        inputProps={{
          ..._inputProps,
          ref,
        }}
        InputProps={{
          className: 'DS-DropdownInput-inputWrapper',
        }}
        placeholder={
          !value || (Array.isArray(value) && !value.length)
            ? userInputProps?.placeholder
            : ''
        }
        startAdornment={<StartAdornment />}
        endAdornment={<EndAdornment />}
        startOutsideAdornment={
          prefix ? (
            <div
              className="DS-Dropdown-DropdownPrefix"
              data-testid="dropdown-input-prefix"
            >
              {prefix}
            </div>
          ) : (
            startOutsideAdornment
          )
        }
        endOutsideAdornment={endOutsideAdornment}
        disabled={disabled}
      />
    );
  }
);

DropdownInput.displayName = 'DropdownInput';
