import { HTMLAttributes, useMemo } from 'react';

import { Stack, styled, Typography } from '@mui/material';

import { ListItem, List } from '../../lists';
import { Criterion, RecentSearchEntry } from '../types';

const Root = styled('div', {
  name: 'DsSearch',
  slot: 'RecentSearch',
  target: 'DsSearch-RecentSearch',
  overridesResolver: (_, styles) => {
    return [styles.recentSearch];
  },
})<HTMLAttributes<HTMLDivElement>>``;

const Label = styled('div', {
  name: 'DsSearch',
  slot: 'RecentSearchLabel',
  target: 'DsSearch-RecentSearchLabel',
  overridesResolver: (_, styles) => {
    return [styles.recentSearchLabel];
  },
})<HTMLAttributes<HTMLDivElement>>``;

const StyledList = styled(List, {
  name: 'DsSearch',
  slot: 'RecentSearch',
  target: 'DsSearch-RecentSearchList',
})<HTMLAttributes<HTMLDivElement>>``;

export type RecentSearchProps = {
  criteria?: Criterion[];
  recentSearches?: RecentSearchEntry[];
  onSelect?: (index: number) => void;
};

export const RecentSearch = ({
  criteria,
  recentSearches,
  onSelect,
}: RecentSearchProps) => {
  const searches = useMemo(() => {
    if (!recentSearches?.length) {
      return [];
    }
    const result: Array<Array<{ title?: string; value: any }>> = [];
    for (const { freeText, values } of recentSearches) {
      const entry: Array<{ title?: string; value: any }> = [];
      if (values) {
        for (const key in values) {
          const criterion = criteria?.find((c) => c.key === key);
          if (criterion) {
            entry.push({
              title: criterion.title || criterion.key,
              value: values[key],
            });
          }
        }
      }
      if (freeText) {
        entry.push({ value: freeText });
      }
      result.push(entry);
    }
    return result;
  }, [criteria, recentSearches]);

  if (!recentSearches?.length || !searches.some((s) => !!s.length)) {
    return null;
  }

  return (
    <Root>
      <Label>Recent search</Label>
      <StyledList disablePadding>
        {searches.map((entry, index) => {
          return (
            <ListItem
              key={index}
              onClick={() => {
                onSelect?.(index);
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {entry.map(({ title, value }, index) => {
                const notLast = index < entry.length - 1;
                return (
                  <Stack key={index} gap="4px" direction="row" flexWrap="wrap">
                    {title && (
                      <Typography variant="P14M" color="grey.900">
                        {title}
                      </Typography>
                    )}
                    {value && Array.isArray(value) ? (
                      value.map((v, i) => {
                        return (
                          <Typography variant="P14M" key={i}>
                            {v}
                            {value.length - 1 !== i ? ',' : null}
                          </Typography>
                        );
                      })
                    ) : (
                      <Typography variant="P14R">
                        {value}
                        {notLast && ','}
                      </Typography>
                    )}
                  </Stack>
                );
              })}
            </ListItem>
          );
        })}
      </StyledList>
    </Root>
  );
};
