import round from 'lodash/round';

import { AVERAGE_HOURS_IN_MONTH } from '@cast/constants';
import { PriceType } from '@cast/types';

export const hasLongFloat = (
  value: string | number,
  floatLength = 3
): boolean => {
  const [, float] = round(+value, 10).toString().split('.');
  return float?.length >= floatLength;
};

export const formatCurrency = (
  currency: number,
  options: Omit<Intl.NumberFormatOptions, 'style' | 'currency'> = {}
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  }).format(currency);
};

export const getCurrencyPrecision = (v?: number) => {
  return v && v < 1 ? 5 : 2;
};

const multipliers = {
  [PriceType.HOURLY]: {
    [PriceType.HOURLY]: 1,
    [PriceType.DAILY]: 24,
    [PriceType.MONTHLY]: AVERAGE_HOURS_IN_MONTH,
  },
  [PriceType.DAILY]: {
    [PriceType.HOURLY]: 1 / 24,
    [PriceType.DAILY]: 1,
    [PriceType.MONTHLY]: 30,
  },
  [PriceType.MONTHLY]: {
    [PriceType.HOURLY]: 1 / (30 * 24),
    [PriceType.DAILY]: 1 / 30,
    [PriceType.MONTHLY]: 1,
  },
};

export const getPriceMultiplier = (
  priceType: PriceType = PriceType.DAILY,
  timeSeriesStep = PriceType.HOURLY
) => {
  return multipliers[timeSeriesStep][priceType];
};
