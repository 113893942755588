export enum HubspotObject {
  CONTACT = '0-1',
  COMPANY = '0-2',
  DEAL = '0-3',
  TICKET = '0-5',
}

export type HubspotFormField = {
  objectTypeId: HubspotObject;
  name: string;
  value: string;
};

export type HubspotFormBody = {
  submittedAt: number;
  fields: HubspotFormField[];
  context: {
    hutk: string;
    pageUri: string;
    pageName: string;
  };
};

export type HubspotContact = {
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
};

export type HubspotToken = {
  token: string;
};
