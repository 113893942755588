/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CostreportV1beta1GetClusterWorkloadEfficiencyReportByNameResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetClusterWorkloadEfficiencyReportResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetClusterWorkloadLabelsResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetClusterWorkloadReportResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetClusterWorkloadRightsizingPatchRequestWorkloads } from '../models';
// @ts-ignore
import { CostreportV1beta1GetNamespacesForClustersResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetSingleWorkloadCostReportResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetSingleWorkloadDataTransferCostResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetSingleWorkloadGPUSummaryResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadCostSummariesResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadDataTransferCostResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadLabelNamesResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadLabelValuesDeprecatedResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadLabelValuesResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadTrafficDestinationsHistoryResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadTrafficDestinationsResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadsGPUSummaryRequestFilter } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadsGPUSummaryResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadsGPUUtilizationRequestFilter } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadsGPUUtilizationResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadsMetadataResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadsWastedGPUCostImpactRequestFilter } from '../models';
// @ts-ignore
import { CostreportV1beta1GetWorkloadsWastedGPUCostImpactResponse } from '../models';
// @ts-ignore
import { CostreportV1beta1NamespaceFilters } from '../models';
// @ts-ignore
import { CostreportV1beta1WorkloadFilter } from '../models';
// @ts-ignore
import { CostreportV1beta1WorkloadLabelFilters } from '../models';
/**
 * WorkloadReportAPIApi - axios parameter creator
 * @export
 */
export const WorkloadReportAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets cluster workload efficiency report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {Array<string>} [filterWorkloadNames] 
         * @param {WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum} [filterLabelsOperator] 
         * @param {Array<string>} [filterWorkloadTypes] 
         * @param {Array<string>} [filterNamespaces] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadEfficiencyReport: async (clusterId: string, startTime: string, endTime: string, stepSeconds?: number, filterWorkloadNames?: Array<string>, filterLabelsOperator?: WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum, filterWorkloadTypes?: Array<string>, filterNamespaces?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReport', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/workload-efficiency`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (filterWorkloadNames) {
                localVarQueryParameter['filter.workloadNames'] = filterWorkloadNames;
            }

            if (filterLabelsOperator !== undefined) {
                localVarQueryParameter['filter.labelsOperator'] = filterLabelsOperator;
            }

            if (filterWorkloadTypes) {
                localVarQueryParameter['filter.workloadTypes'] = filterWorkloadTypes;
            }

            if (filterNamespaces) {
                localVarQueryParameter['filter.namespaces'] = filterNamespaces;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadFilter} filter Filter workloads by labels or names.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadEfficiencyReport2: async (clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1WorkloadFilter, stepSeconds?: number, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReport2', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReport2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReport2', 'endTime', endTime)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReport2', 'filter', filter)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/workload-efficiency`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report for a workload by name.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace Namespace of the workload.
         * @param {string} workloadType Workload type, e.g. Deployment, StatefulSet, DaemonSet.
         * @param {string} workloadName Name of the workload.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {boolean} [includeCurrent] Optional parameter marking whether the current state for workload and its containers should be returned.
         * @param {boolean} [includeHistory] Optional parameter marking whether the history of workload and its containers should be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadEfficiencyReportByName: async (clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, includeCurrent?: boolean, includeHistory?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReportByName', 'clusterId', clusterId)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReportByName', 'namespace', namespace)
            // verify required parameter 'workloadType' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReportByName', 'workloadType', workloadType)
            // verify required parameter 'workloadName' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReportByName', 'workloadName', workloadName)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReportByName', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadEfficiencyReportByName', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespaces/{namespace}/{workloadType}/{workloadName}/efficiency`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"workloadType"}}`, encodeURIComponent(String(workloadType)))
                .replace(`{${"workloadName"}}`, encodeURIComponent(String(workloadName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (includeCurrent !== undefined) {
                localVarQueryParameter['includeCurrent'] = includeCurrent;
            }

            if (includeHistory !== undefined) {
                localVarQueryParameter['includeHistory'] = includeHistory;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use `/v1/cost-reports/workload-labels/names` to get the label names and `/v1/cost-reports/workload-labels/values` to get the label values.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadLabels: async (clusterId: string, startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadLabels', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadLabels', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadLabels', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/workload-labels`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster workloads cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {Array<string>} [filterWorkloadNames] 
         * @param {WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum} [filterLabelsOperator] 
         * @param {Array<string>} [filterWorkloadTypes] 
         * @param {Array<string>} [filterNamespaces] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<string>} [labelsToInclude] Labels to be included for each workload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadReport: async (clusterId: string, startTime: string, endTime: string, stepSeconds?: number, filterWorkloadNames?: Array<string>, filterLabelsOperator?: WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum, filterWorkloadTypes?: Array<string>, filterNamespaces?: Array<string>, pageLimit?: string, pageCursor?: string, labelsToInclude?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadReport', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/workload-costs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (filterWorkloadNames) {
                localVarQueryParameter['filter.workloadNames'] = filterWorkloadNames;
            }

            if (filterLabelsOperator !== undefined) {
                localVarQueryParameter['filter.labelsOperator'] = filterLabelsOperator;
            }

            if (filterWorkloadTypes) {
                localVarQueryParameter['filter.workloadTypes'] = filterWorkloadTypes;
            }

            if (filterNamespaces) {
                localVarQueryParameter['filter.namespaces'] = filterNamespaces;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (labelsToInclude) {
                localVarQueryParameter['labelsToInclude'] = labelsToInclude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster workloads cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadFilter} filter Filter workloads by labels or names.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<string>} [labelsToInclude] Labels to be included for each workload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadReport2: async (clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1WorkloadFilter, stepSeconds?: number, pageLimit?: string, pageCursor?: string, labelsToInclude?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadReport2', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadReport2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadReport2', 'endTime', endTime)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadReport2', 'filter', filter)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/workload-costs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (labelsToInclude) {
                localVarQueryParameter['labelsToInclude'] = labelsToInclude;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report for the workloads.
         * @param {string} clusterId Defines cluster id.
         * @param {CostreportV1beta1GetClusterWorkloadRightsizingPatchRequestWorkloads} workloads Reference to the Workloads IDs list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadRightsizingPatch: async (clusterId: string, workloads: CostreportV1beta1GetClusterWorkloadRightsizingPatchRequestWorkloads, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadRightsizingPatch', 'clusterId', clusterId)
            // verify required parameter 'workloads' is not null or undefined
            assertParamExists('workloadReportAPIGetClusterWorkloadRightsizingPatch', 'workloads', workloads)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/rightsizing-patch.sh`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workloads, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the kubernetes namespaces for the given cluster IDs.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1NamespaceFilters} namespaceFilters Filter items by workload specific fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetNamespacesForClusters: async (startTime: string, endTime: string, namespaceFilters: CostreportV1beta1NamespaceFilters, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetNamespacesForClusters', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetNamespacesForClusters', 'endTime', endTime)
            // verify required parameter 'namespaceFilters' is not null or undefined
            assertParamExists('workloadReportAPIGetNamespacesForClusters', 'namespaceFilters', namespaceFilters)
            const localVarPath = `/v1/cost-reports/namespaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(namespaceFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets single workload cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace 
         * @param {string} workloadType 
         * @param {string} workloadName 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetSingleWorkloadCostReport: async (clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadCostReport', 'clusterId', clusterId)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadCostReport', 'namespace', namespace)
            // verify required parameter 'workloadType' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadCostReport', 'workloadType', workloadType)
            // verify required parameter 'workloadName' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadCostReport', 'workloadName', workloadName)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadCostReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadCostReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespaces/{namespace}/{workloadType}/{workloadName}/cost`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"workloadType"}}`, encodeURIComponent(String(workloadType)))
                .replace(`{${"workloadName"}}`, encodeURIComponent(String(workloadName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets single workload cost report.
         * @param {string} clusterId 
         * @param {string} namespace 
         * @param {string} workloadType 
         * @param {string} workloadName 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetSingleWorkloadDataTransferCost: async (clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadDataTransferCost', 'clusterId', clusterId)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadDataTransferCost', 'namespace', namespace)
            // verify required parameter 'workloadType' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadDataTransferCost', 'workloadType', workloadType)
            // verify required parameter 'workloadName' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadDataTransferCost', 'workloadName', workloadName)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadDataTransferCost', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadDataTransferCost', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespaces/{namespace}/{workloadType}/{workloadName}/datatransfer-costs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"workloadType"}}`, encodeURIComponent(String(workloadType)))
                .replace(`{${"workloadName"}}`, encodeURIComponent(String(workloadName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets single workload GPU summary.
         * @param {string} clusterId Workload cluster ID.
         * @param {string} namespace Namespace the workload is in.
         * @param {string} workloadType Type of the workload.
         * @param {string} workloadName Name of the workload.
         * @param {string} [startTime] Filter items to include from specified time.
         * @param {string} [endTime] Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetSingleWorkloadGPUSummary: async (clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime?: string, endTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadGPUSummary', 'clusterId', clusterId)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadGPUSummary', 'namespace', namespace)
            // verify required parameter 'workloadType' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadGPUSummary', 'workloadType', workloadType)
            // verify required parameter 'workloadName' is not null or undefined
            assertParamExists('workloadReportAPIGetSingleWorkloadGPUSummary', 'workloadName', workloadName)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespaces/{namespace}/{workloadType}/{workloadName}/gpu-summary`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"workloadType"}}`, encodeURIComponent(String(workloadType)))
                .replace(`{${"workloadName"}}`, encodeURIComponent(String(workloadName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster workloads compute cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadFilter} filter Workload filtering options.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {Array<string>} [labelsToInclude] Labels to be included for each workload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadCostSummaries: async (clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1WorkloadFilter, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum, labelsToInclude?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadCostSummaries', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadCostSummaries', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadCostSummaries', 'endTime', endTime)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadCostSummaries', 'filter', filter)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/workload-cost-summaries`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }

            if (labelsToInclude) {
                localVarQueryParameter['labelsToInclude'] = labelsToInclude;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workloads datatransfer costs
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {Array<string>} [filterWorkloadNames] 
         * @param {WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum} [filterLabelsOperator] 
         * @param {Array<string>} [filterWorkloadTypes] 
         * @param {Array<string>} [filterNamespaces] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadDataTransferCost: async (clusterId: string, startTime: string, endTime: string, stepSeconds?: number, filterWorkloadNames?: Array<string>, filterLabelsOperator?: WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum, filterWorkloadTypes?: Array<string>, filterNamespaces?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadDataTransferCost', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadDataTransferCost', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadDataTransferCost', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/datatransfer-costs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (filterWorkloadNames) {
                localVarQueryParameter['filter.workloadNames'] = filterWorkloadNames;
            }

            if (filterLabelsOperator !== undefined) {
                localVarQueryParameter['filter.labelsOperator'] = filterLabelsOperator;
            }

            if (filterWorkloadTypes) {
                localVarQueryParameter['filter.workloadTypes'] = filterWorkloadTypes;
            }

            if (filterNamespaces) {
                localVarQueryParameter['filter.namespaces'] = filterNamespaces;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workloads datatransfer costs
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadFilter} filter Filter workloads by labels or names.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadDataTransferCost2: async (clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1WorkloadFilter, stepSeconds?: number, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadDataTransferCost2', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadDataTransferCost2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadDataTransferCost2', 'endTime', endTime)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadDataTransferCost2', 'filter', filter)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/datatransfer-costs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the kubernetes label names applied to the workloads in the organization.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadLabelFilters} labelFilters Filter items by more label specific fields.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadLabelNames: async (startTime: string, endTime: string, labelFilters: CostreportV1beta1WorkloadLabelFilters, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelNames', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelNames', 'endTime', endTime)
            // verify required parameter 'labelFilters' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelNames', 'labelFilters', labelFilters)
            const localVarPath = `/v1/cost-reports/workload-labels/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labelFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the kubernetes label values for the given label name.
         * @param {string} label Label name. Max length of 317 &#x3D; 253 (optional prefix) + 1 (slash) + 63 (name segment) according to https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadLabelFilters} labelFilters Filter items by more label specific fields.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadLabelValues: async (label: string, startTime: string, endTime: string, labelFilters: CostreportV1beta1WorkloadLabelFilters, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'label' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelValues', 'label', label)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelValues', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelValues', 'endTime', endTime)
            // verify required parameter 'labelFilters' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelValues', 'labelFilters', labelFilters)
            const localVarPath = `/v1/cost-reports/workload-labels/values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labelFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use `/v1/cost-reports/workload-labels/values` instead
         * @param {string} label Label name. Max length of 317 &#x3D; 253 (optional prefix) + 1 (slash) + 63 (name segment) according to https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadLabelFilters} labelFilters Filter items by more label specific fields.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadLabelValuesDeprecated: async (label: string, startTime: string, endTime: string, labelFilters: CostreportV1beta1WorkloadLabelFilters, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'label' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelValuesDeprecated', 'label', label)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelValuesDeprecated', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelValuesDeprecated', 'endTime', endTime)
            // verify required parameter 'labelFilters' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadLabelValuesDeprecated', 'labelFilters', labelFilters)
            const localVarPath = `/v1/cost-reports/workload-labels/{label}/values`
                .replace(`{${"label"}}`, encodeURIComponent(String(label)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labelFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workload traffic destinations with their costs.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace Namespace of the workload.
         * @param {string} workloadType Workload type, e.g. Deployment, StatefulSet, DaemonSet.
         * @param {string} workloadName Name of the workload.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {number} [limit] Limit the number of items in destinations. Default value is 0 &#x3D; UNLIMITED.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadTrafficDestinations: async (clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinations', 'clusterId', clusterId)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinations', 'namespace', namespace)
            // verify required parameter 'workloadType' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinations', 'workloadType', workloadType)
            // verify required parameter 'workloadName' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinations', 'workloadName', workloadName)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinations', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinations', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespaces/{namespace}/{workloadType}/{workloadName}/traffic-destinations`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"workloadType"}}`, encodeURIComponent(String(workloadType)))
                .replace(`{${"workloadName"}}`, encodeURIComponent(String(workloadName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workload traffic destinations with their costs and history.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace Namespace of the workload.
         * @param {string} workloadType Workload type, e.g. Deployment, StatefulSet, DaemonSet.
         * @param {string} workloadName Name of the workload.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {number} [limit] Limit the number of items in destinations. Default value is 0 &#x3D; UNLIMITED.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadTrafficDestinationsHistory: async (clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinationsHistory', 'clusterId', clusterId)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinationsHistory', 'namespace', namespace)
            // verify required parameter 'workloadType' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinationsHistory', 'workloadType', workloadType)
            // verify required parameter 'workloadName' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinationsHistory', 'workloadName', workloadName)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinationsHistory', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadTrafficDestinationsHistory', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespaces/{namespace}/{workloadType}/{workloadName}/traffic-destination-histories`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"workloadType"}}`, encodeURIComponent(String(workloadType)))
                .replace(`{${"workloadName"}}`, encodeURIComponent(String(workloadName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workloads GPU summary report.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1GetWorkloadsGPUSummaryRequestFilter} filter Workloads filtering options.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadsGPUSummary: async (startTime: string, endTime: string, filter: CostreportV1beta1GetWorkloadsGPUSummaryRequestFilter, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsGPUSummary', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsGPUSummary', 'endTime', endTime)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsGPUSummary', 'filter', filter)
            const localVarPath = `/v1/cost-reports/workloads/gpu-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets real time workloads GPU utilization %.
         * @param {CostreportV1beta1GetWorkloadsGPUUtilizationRequestFilter} filter Filter workloads by cluster_ids or workload specific data.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadsGPUUtilization: async (filter: CostreportV1beta1GetWorkloadsGPUUtilizationRequestFilter, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsGPUUtilization', 'filter', filter)
            const localVarPath = `/v1/cost-reports/workloads/gpu-utilization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the workloads metadata
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {CostreportV1beta1WorkloadLabelFilters} filters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadsMetadata: async (startTime: string, endTime: string, filters: CostreportV1beta1WorkloadLabelFilters, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsMetadata', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsMetadata', 'endTime', endTime)
            // verify required parameter 'filters' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsMetadata', 'filters', filters)
            const localVarPath = `/v1/cost-reports/workloads/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workloads cost impact of wasted GPU resources report.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1GetWorkloadsWastedGPUCostImpactRequestFilter} filter Workloads filtering options.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadsWastedGPUCostImpact: async (startTime: string, endTime: string, filter: CostreportV1beta1GetWorkloadsWastedGPUCostImpactRequestFilter, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsWastedGPUCostImpact', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsWastedGPUCostImpact', 'endTime', endTime)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('workloadReportAPIGetWorkloadsWastedGPUCostImpact', 'filter', filter)
            const localVarPath = `/v1/cost-reports/workloads/gpu-wasted-cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkloadReportAPIApi - functional programming interface
 * @export
 */
export const WorkloadReportAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkloadReportAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets cluster workload efficiency report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {Array<string>} [filterWorkloadNames] 
         * @param {WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum} [filterLabelsOperator] 
         * @param {Array<string>} [filterWorkloadTypes] 
         * @param {Array<string>} [filterNamespaces] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetClusterWorkloadEfficiencyReport(clusterId: string, startTime: string, endTime: string, stepSeconds?: number, filterWorkloadNames?: Array<string>, filterLabelsOperator?: WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum, filterWorkloadTypes?: Array<string>, filterNamespaces?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterWorkloadEfficiencyReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetClusterWorkloadEfficiencyReport(clusterId, startTime, endTime, stepSeconds, filterWorkloadNames, filterLabelsOperator, filterWorkloadTypes, filterNamespaces, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetClusterWorkloadEfficiencyReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadFilter} filter Filter workloads by labels or names.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetClusterWorkloadEfficiencyReport2(clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1WorkloadFilter, stepSeconds?: number, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterWorkloadEfficiencyReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetClusterWorkloadEfficiencyReport2(clusterId, startTime, endTime, filter, stepSeconds, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetClusterWorkloadEfficiencyReport2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report for a workload by name.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace Namespace of the workload.
         * @param {string} workloadType Workload type, e.g. Deployment, StatefulSet, DaemonSet.
         * @param {string} workloadName Name of the workload.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {boolean} [includeCurrent] Optional parameter marking whether the current state for workload and its containers should be returned.
         * @param {boolean} [includeHistory] Optional parameter marking whether the history of workload and its containers should be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetClusterWorkloadEfficiencyReportByName(clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, includeCurrent?: boolean, includeHistory?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterWorkloadEfficiencyReportByNameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetClusterWorkloadEfficiencyReportByName(clusterId, namespace, workloadType, workloadName, startTime, endTime, stepSeconds, includeCurrent, includeHistory, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetClusterWorkloadEfficiencyReportByName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use `/v1/cost-reports/workload-labels/names` to get the label names and `/v1/cost-reports/workload-labels/values` to get the label values.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async workloadReportAPIGetClusterWorkloadLabels(clusterId: string, startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterWorkloadLabelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetClusterWorkloadLabels(clusterId, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetClusterWorkloadLabels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster workloads cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {Array<string>} [filterWorkloadNames] 
         * @param {WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum} [filterLabelsOperator] 
         * @param {Array<string>} [filterWorkloadTypes] 
         * @param {Array<string>} [filterNamespaces] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<string>} [labelsToInclude] Labels to be included for each workload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetClusterWorkloadReport(clusterId: string, startTime: string, endTime: string, stepSeconds?: number, filterWorkloadNames?: Array<string>, filterLabelsOperator?: WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum, filterWorkloadTypes?: Array<string>, filterNamespaces?: Array<string>, pageLimit?: string, pageCursor?: string, labelsToInclude?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterWorkloadReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetClusterWorkloadReport(clusterId, startTime, endTime, stepSeconds, filterWorkloadNames, filterLabelsOperator, filterWorkloadTypes, filterNamespaces, pageLimit, pageCursor, labelsToInclude, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetClusterWorkloadReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster workloads cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadFilter} filter Filter workloads by labels or names.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<string>} [labelsToInclude] Labels to be included for each workload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetClusterWorkloadReport2(clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1WorkloadFilter, stepSeconds?: number, pageLimit?: string, pageCursor?: string, labelsToInclude?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterWorkloadReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetClusterWorkloadReport2(clusterId, startTime, endTime, filter, stepSeconds, pageLimit, pageCursor, labelsToInclude, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetClusterWorkloadReport2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report for the workloads.
         * @param {string} clusterId Defines cluster id.
         * @param {CostreportV1beta1GetClusterWorkloadRightsizingPatchRequestWorkloads} workloads Reference to the Workloads IDs list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetClusterWorkloadRightsizingPatch(clusterId: string, workloads: CostreportV1beta1GetClusterWorkloadRightsizingPatchRequestWorkloads, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetClusterWorkloadRightsizingPatch(clusterId, workloads, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetClusterWorkloadRightsizingPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the kubernetes namespaces for the given cluster IDs.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1NamespaceFilters} namespaceFilters Filter items by workload specific fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetNamespacesForClusters(startTime: string, endTime: string, namespaceFilters: CostreportV1beta1NamespaceFilters, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetNamespacesForClustersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetNamespacesForClusters(startTime, endTime, namespaceFilters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetNamespacesForClusters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets single workload cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace 
         * @param {string} workloadType 
         * @param {string} workloadName 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetSingleWorkloadCostReport(clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetSingleWorkloadCostReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetSingleWorkloadCostReport(clusterId, namespace, workloadType, workloadName, startTime, endTime, stepSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetSingleWorkloadCostReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets single workload cost report.
         * @param {string} clusterId 
         * @param {string} namespace 
         * @param {string} workloadType 
         * @param {string} workloadName 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetSingleWorkloadDataTransferCost(clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetSingleWorkloadDataTransferCostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetSingleWorkloadDataTransferCost(clusterId, namespace, workloadType, workloadName, startTime, endTime, stepSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetSingleWorkloadDataTransferCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets single workload GPU summary.
         * @param {string} clusterId Workload cluster ID.
         * @param {string} namespace Namespace the workload is in.
         * @param {string} workloadType Type of the workload.
         * @param {string} workloadName Name of the workload.
         * @param {string} [startTime] Filter items to include from specified time.
         * @param {string} [endTime] Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetSingleWorkloadGPUSummary(clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime?: string, endTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetSingleWorkloadGPUSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetSingleWorkloadGPUSummary(clusterId, namespace, workloadType, workloadName, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetSingleWorkloadGPUSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster workloads compute cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadFilter} filter Workload filtering options.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {Array<string>} [labelsToInclude] Labels to be included for each workload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadCostSummaries(clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1WorkloadFilter, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum, labelsToInclude?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadCostSummariesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadCostSummaries(clusterId, startTime, endTime, filter, pageLimit, pageCursor, sortField, sortOrder, labelsToInclude, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadCostSummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workloads datatransfer costs
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {Array<string>} [filterWorkloadNames] 
         * @param {WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum} [filterLabelsOperator] 
         * @param {Array<string>} [filterWorkloadTypes] 
         * @param {Array<string>} [filterNamespaces] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadDataTransferCost(clusterId: string, startTime: string, endTime: string, stepSeconds?: number, filterWorkloadNames?: Array<string>, filterLabelsOperator?: WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum, filterWorkloadTypes?: Array<string>, filterNamespaces?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadDataTransferCostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadDataTransferCost(clusterId, startTime, endTime, stepSeconds, filterWorkloadNames, filterLabelsOperator, filterWorkloadTypes, filterNamespaces, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadDataTransferCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workloads datatransfer costs
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadFilter} filter Filter workloads by labels or names.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadDataTransferCost2(clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1WorkloadFilter, stepSeconds?: number, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadDataTransferCostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadDataTransferCost2(clusterId, startTime, endTime, filter, stepSeconds, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadDataTransferCost2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the kubernetes label names applied to the workloads in the organization.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadLabelFilters} labelFilters Filter items by more label specific fields.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadLabelNames(startTime: string, endTime: string, labelFilters: CostreportV1beta1WorkloadLabelFilters, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadLabelNamesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadLabelNames(startTime, endTime, labelFilters, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadLabelNames']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the kubernetes label values for the given label name.
         * @param {string} label Label name. Max length of 317 &#x3D; 253 (optional prefix) + 1 (slash) + 63 (name segment) according to https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadLabelFilters} labelFilters Filter items by more label specific fields.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadLabelValues(label: string, startTime: string, endTime: string, labelFilters: CostreportV1beta1WorkloadLabelFilters, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadLabelValuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadLabelValues(label, startTime, endTime, labelFilters, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadLabelValues']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use `/v1/cost-reports/workload-labels/values` instead
         * @param {string} label Label name. Max length of 317 &#x3D; 253 (optional prefix) + 1 (slash) + 63 (name segment) according to https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1WorkloadLabelFilters} labelFilters Filter items by more label specific fields.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadLabelValuesDeprecated(label: string, startTime: string, endTime: string, labelFilters: CostreportV1beta1WorkloadLabelFilters, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadLabelValuesDeprecatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadLabelValuesDeprecated(label, startTime, endTime, labelFilters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadLabelValuesDeprecated']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workload traffic destinations with their costs.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace Namespace of the workload.
         * @param {string} workloadType Workload type, e.g. Deployment, StatefulSet, DaemonSet.
         * @param {string} workloadName Name of the workload.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {number} [limit] Limit the number of items in destinations. Default value is 0 &#x3D; UNLIMITED.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadTrafficDestinations(clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadTrafficDestinationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadTrafficDestinations(clusterId, namespace, workloadType, workloadName, startTime, endTime, stepSeconds, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadTrafficDestinations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workload traffic destinations with their costs and history.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace Namespace of the workload.
         * @param {string} workloadType Workload type, e.g. Deployment, StatefulSet, DaemonSet.
         * @param {string} workloadName Name of the workload.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {number} [limit] Limit the number of items in destinations. Default value is 0 &#x3D; UNLIMITED.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadTrafficDestinationsHistory(clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, stepSeconds?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadTrafficDestinationsHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadTrafficDestinationsHistory(clusterId, namespace, workloadType, workloadName, startTime, endTime, stepSeconds, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadTrafficDestinationsHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workloads GPU summary report.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1GetWorkloadsGPUSummaryRequestFilter} filter Workloads filtering options.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadsGPUSummary(startTime: string, endTime: string, filter: CostreportV1beta1GetWorkloadsGPUSummaryRequestFilter, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadsGPUSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadsGPUSummary(startTime, endTime, filter, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadsGPUSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets real time workloads GPU utilization %.
         * @param {CostreportV1beta1GetWorkloadsGPUUtilizationRequestFilter} filter Filter workloads by cluster_ids or workload specific data.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadsGPUUtilization(filter: CostreportV1beta1GetWorkloadsGPUUtilizationRequestFilter, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadsGPUUtilizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadsGPUUtilization(filter, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadsGPUUtilization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the workloads metadata
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {CostreportV1beta1WorkloadLabelFilters} filters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadsMetadata(startTime: string, endTime: string, filters: CostreportV1beta1WorkloadLabelFilters, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadsMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadsMetadata(startTime, endTime, filters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadsMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workloads cost impact of wasted GPU resources report.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1GetWorkloadsWastedGPUCostImpactRequestFilter} filter Workloads filtering options.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadReportAPIGetWorkloadsWastedGPUCostImpact(startTime: string, endTime: string, filter: CostreportV1beta1GetWorkloadsWastedGPUCostImpactRequestFilter, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadsWastedGPUCostImpactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadReportAPIGetWorkloadsWastedGPUCostImpact(startTime, endTime, filter, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadReportAPIApi.workloadReportAPIGetWorkloadsWastedGPUCostImpact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkloadReportAPIApi - factory interface
 * @export
 */
export const WorkloadReportAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkloadReportAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets cluster workload efficiency report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadEfficiencyReport(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterWorkloadEfficiencyReportResponse> {
            return localVarFp.workloadReportAPIGetClusterWorkloadEfficiencyReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.filterWorkloadNames, requestParameters.filterLabelsOperator, requestParameters.filterWorkloadTypes, requestParameters.filterNamespaces, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadEfficiencyReport2(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2Request, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterWorkloadEfficiencyReportResponse> {
            return localVarFp.workloadReportAPIGetClusterWorkloadEfficiencyReport2(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.stepSeconds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report for a workload by name.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByNameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadEfficiencyReportByName(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByNameRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterWorkloadEfficiencyReportByNameResponse> {
            return localVarFp.workloadReportAPIGetClusterWorkloadEfficiencyReportByName(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.includeCurrent, requestParameters.includeHistory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use `/v1/cost-reports/workload-labels/names` to get the label names and `/v1/cost-reports/workload-labels/values` to get the label values.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadLabels(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabelsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterWorkloadLabelsResponse> {
            return localVarFp.workloadReportAPIGetClusterWorkloadLabels(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster workloads cost report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadReport(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterWorkloadReportResponse> {
            return localVarFp.workloadReportAPIGetClusterWorkloadReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.filterWorkloadNames, requestParameters.filterLabelsOperator, requestParameters.filterWorkloadTypes, requestParameters.filterNamespaces, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.labelsToInclude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster workloads cost report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadReport2(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2Request, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterWorkloadReportResponse> {
            return localVarFp.workloadReportAPIGetClusterWorkloadReport2(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.stepSeconds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.labelsToInclude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster workload efficiency report for the workloads.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadRightsizingPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetClusterWorkloadRightsizingPatch(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadRightsizingPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.workloadReportAPIGetClusterWorkloadRightsizingPatch(requestParameters.clusterId, requestParameters.workloads, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the kubernetes namespaces for the given cluster IDs.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClustersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetNamespacesForClusters(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClustersRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetNamespacesForClustersResponse> {
            return localVarFp.workloadReportAPIGetNamespacesForClusters(requestParameters.startTime, requestParameters.endTime, requestParameters.namespaceFilters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets single workload cost report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetSingleWorkloadCostReport(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetSingleWorkloadCostReportResponse> {
            return localVarFp.workloadReportAPIGetSingleWorkloadCostReport(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets single workload cost report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetSingleWorkloadDataTransferCost(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetSingleWorkloadDataTransferCostResponse> {
            return localVarFp.workloadReportAPIGetSingleWorkloadDataTransferCost(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets single workload GPU summary.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetSingleWorkloadGPUSummary(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetSingleWorkloadGPUSummaryResponse> {
            return localVarFp.workloadReportAPIGetSingleWorkloadGPUSummary(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster workloads compute cost report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummariesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadCostSummaries(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummariesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadCostSummariesResponse> {
            return localVarFp.workloadReportAPIGetWorkloadCostSummaries(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, requestParameters.labelsToInclude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workloads datatransfer costs
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadDataTransferCost(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadDataTransferCostResponse> {
            return localVarFp.workloadReportAPIGetWorkloadDataTransferCost(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.filterWorkloadNames, requestParameters.filterLabelsOperator, requestParameters.filterWorkloadTypes, requestParameters.filterNamespaces, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workloads datatransfer costs
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadDataTransferCost2(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2Request, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadDataTransferCostResponse> {
            return localVarFp.workloadReportAPIGetWorkloadDataTransferCost2(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.stepSeconds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the kubernetes label names applied to the workloads in the organization.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNamesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadLabelNames(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNamesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadLabelNamesResponse> {
            return localVarFp.workloadReportAPIGetWorkloadLabelNames(requestParameters.startTime, requestParameters.endTime, requestParameters.labelFilters, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the kubernetes label values for the given label name.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadLabelValues(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadLabelValuesResponse> {
            return localVarFp.workloadReportAPIGetWorkloadLabelValues(requestParameters.label, requestParameters.startTime, requestParameters.endTime, requestParameters.labelFilters, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use `/v1/cost-reports/workload-labels/values` instead
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecatedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadLabelValuesDeprecated(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecatedRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadLabelValuesDeprecatedResponse> {
            return localVarFp.workloadReportAPIGetWorkloadLabelValuesDeprecated(requestParameters.label, requestParameters.startTime, requestParameters.endTime, requestParameters.labelFilters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workload traffic destinations with their costs.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadTrafficDestinations(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadTrafficDestinationsResponse> {
            return localVarFp.workloadReportAPIGetWorkloadTrafficDestinations(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workload traffic destinations with their costs and history.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadTrafficDestinationsHistory(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadTrafficDestinationsHistoryResponse> {
            return localVarFp.workloadReportAPIGetWorkloadTrafficDestinationsHistory(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workloads GPU summary report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadsGPUSummary(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadsGPUSummaryResponse> {
            return localVarFp.workloadReportAPIGetWorkloadsGPUSummary(requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets real time workloads GPU utilization %.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilizationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadsGPUUtilization(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilizationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadsGPUUtilizationResponse> {
            return localVarFp.workloadReportAPIGetWorkloadsGPUUtilization(requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the workloads metadata
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadsMetadata(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadsMetadataResponse> {
            return localVarFp.workloadReportAPIGetWorkloadsMetadata(requestParameters.startTime, requestParameters.endTime, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workloads cost impact of wasted GPU resources report.
         * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadReportAPIGetWorkloadsWastedGPUCostImpact(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpactRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadsWastedGPUCostImpactResponse> {
            return localVarFp.workloadReportAPIGetWorkloadsWastedGPUCostImpact(requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for workloadReportAPIGetClusterWorkloadEfficiencyReport operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly stepSeconds?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly filterWorkloadNames?: Array<string>

    /**
     * 
     * @type {'OR' | 'AND'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly filterLabelsOperator?: WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly filterWorkloadTypes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly filterNamespaces?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport
     */
    readonly sortOrder?: WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum
}

/**
 * Request parameters for workloadReportAPIGetClusterWorkloadEfficiencyReport2 operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2Request
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2Request {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly endTime: string

    /**
     * Filter workloads by labels or names.
     * @type {CostreportV1beta1WorkloadFilter}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly filter: CostreportV1beta1WorkloadFilter

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly stepSeconds?: number

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2
     */
    readonly sortOrder?: WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum
}

/**
 * Request parameters for workloadReportAPIGetClusterWorkloadEfficiencyReportByName operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByNameRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByNameRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly clusterId: string

    /**
     * Namespace of the workload.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly namespace: string

    /**
     * Workload type, e.g. Deployment, StatefulSet, DaemonSet.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly workloadType: string

    /**
     * Name of the workload.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly workloadName: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly stepSeconds?: number

    /**
     * Optional parameter marking whether the current state for workload and its containers should be returned.
     * @type {boolean}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly includeCurrent?: boolean

    /**
     * Optional parameter marking whether the history of workload and its containers should be returned.
     * @type {boolean}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByName
     */
    readonly includeHistory?: boolean
}

/**
 * Request parameters for workloadReportAPIGetClusterWorkloadLabels operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabelsRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabelsRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabels
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabels
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabels
     */
    readonly endTime: string
}

/**
 * Request parameters for workloadReportAPIGetClusterWorkloadReport operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReportRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReportRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly stepSeconds?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly filterWorkloadNames?: Array<string>

    /**
     * 
     * @type {'OR' | 'AND'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly filterLabelsOperator?: WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly filterWorkloadTypes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly filterNamespaces?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly pageCursor?: string

    /**
     * Labels to be included for each workload.
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport
     */
    readonly labelsToInclude?: Array<string>
}

/**
 * Request parameters for workloadReportAPIGetClusterWorkloadReport2 operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2Request
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2Request {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2
     */
    readonly endTime: string

    /**
     * Filter workloads by labels or names.
     * @type {CostreportV1beta1WorkloadFilter}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2
     */
    readonly filter: CostreportV1beta1WorkloadFilter

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2
     */
    readonly stepSeconds?: number

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2
     */
    readonly pageCursor?: string

    /**
     * Labels to be included for each workload.
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2
     */
    readonly labelsToInclude?: Array<string>
}

/**
 * Request parameters for workloadReportAPIGetClusterWorkloadRightsizingPatch operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadRightsizingPatchRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadRightsizingPatchRequest {
    /**
     * Defines cluster id.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadRightsizingPatch
     */
    readonly clusterId: string

    /**
     * Reference to the Workloads IDs list.
     * @type {CostreportV1beta1GetClusterWorkloadRightsizingPatchRequestWorkloads}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadRightsizingPatch
     */
    readonly workloads: CostreportV1beta1GetClusterWorkloadRightsizingPatchRequestWorkloads
}

/**
 * Request parameters for workloadReportAPIGetNamespacesForClusters operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClustersRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClustersRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClusters
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClusters
     */
    readonly endTime: string

    /**
     * Filter items by workload specific fields.
     * @type {CostreportV1beta1NamespaceFilters}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClusters
     */
    readonly namespaceFilters: CostreportV1beta1NamespaceFilters
}

/**
 * Request parameters for workloadReportAPIGetSingleWorkloadCostReport operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReportRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReportRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReport
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReport
     */
    readonly namespace: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReport
     */
    readonly workloadType: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReport
     */
    readonly workloadName: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReport
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReport
     */
    readonly stepSeconds?: number
}

/**
 * Request parameters for workloadReportAPIGetSingleWorkloadDataTransferCost operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCostRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCostRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCost
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCost
     */
    readonly namespace: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCost
     */
    readonly workloadType: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCost
     */
    readonly workloadName: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCost
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCost
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCost
     */
    readonly stepSeconds?: number
}

/**
 * Request parameters for workloadReportAPIGetSingleWorkloadGPUSummary operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummaryRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummaryRequest {
    /**
     * Workload cluster ID.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummary
     */
    readonly clusterId: string

    /**
     * Namespace the workload is in.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummary
     */
    readonly namespace: string

    /**
     * Type of the workload.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummary
     */
    readonly workloadType: string

    /**
     * Name of the workload.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummary
     */
    readonly workloadName: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummary
     */
    readonly startTime?: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummary
     */
    readonly endTime?: string
}

/**
 * Request parameters for workloadReportAPIGetWorkloadCostSummaries operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummariesRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummariesRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly endTime: string

    /**
     * Workload filtering options.
     * @type {CostreportV1beta1WorkloadFilter}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly filter: CostreportV1beta1WorkloadFilter

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly sortOrder?: WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum

    /**
     * Labels to be included for each workload.
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummaries
     */
    readonly labelsToInclude?: Array<string>
}

/**
 * Request parameters for workloadReportAPIGetWorkloadDataTransferCost operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCostRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCostRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly stepSeconds?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly filterWorkloadNames?: Array<string>

    /**
     * 
     * @type {'OR' | 'AND'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly filterLabelsOperator?: WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly filterWorkloadTypes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly filterNamespaces?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost
     */
    readonly sortOrder?: WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum
}

/**
 * Request parameters for workloadReportAPIGetWorkloadDataTransferCost2 operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2Request
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2Request {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly endTime: string

    /**
     * Filter workloads by labels or names.
     * @type {CostreportV1beta1WorkloadFilter}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly filter: CostreportV1beta1WorkloadFilter

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly stepSeconds?: number

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2
     */
    readonly sortOrder?: WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum
}

/**
 * Request parameters for workloadReportAPIGetWorkloadLabelNames operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNamesRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNamesRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNames
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNames
     */
    readonly endTime: string

    /**
     * Filter items by more label specific fields.
     * @type {CostreportV1beta1WorkloadLabelFilters}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNames
     */
    readonly labelFilters: CostreportV1beta1WorkloadLabelFilters

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNames
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNames
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for workloadReportAPIGetWorkloadLabelValues operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesRequest {
    /**
     * Label name. Max length of 317 &#x3D; 253 (optional prefix) + 1 (slash) + 63 (name segment) according to https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValues
     */
    readonly label: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValues
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValues
     */
    readonly endTime: string

    /**
     * Filter items by more label specific fields.
     * @type {CostreportV1beta1WorkloadLabelFilters}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValues
     */
    readonly labelFilters: CostreportV1beta1WorkloadLabelFilters

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValues
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValues
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for workloadReportAPIGetWorkloadLabelValuesDeprecated operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecatedRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecatedRequest {
    /**
     * Label name. Max length of 317 &#x3D; 253 (optional prefix) + 1 (slash) + 63 (name segment) according to https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#syntax-and-character-set
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecated
     */
    readonly label: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecated
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecated
     */
    readonly endTime: string

    /**
     * Filter items by more label specific fields.
     * @type {CostreportV1beta1WorkloadLabelFilters}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecated
     */
    readonly labelFilters: CostreportV1beta1WorkloadLabelFilters
}

/**
 * Request parameters for workloadReportAPIGetWorkloadTrafficDestinations operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinations
     */
    readonly clusterId: string

    /**
     * Namespace of the workload.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinations
     */
    readonly namespace: string

    /**
     * Workload type, e.g. Deployment, StatefulSet, DaemonSet.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinations
     */
    readonly workloadType: string

    /**
     * Name of the workload.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinations
     */
    readonly workloadName: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinations
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinations
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinations
     */
    readonly stepSeconds?: number

    /**
     * Limit the number of items in destinations. Default value is 0 &#x3D; UNLIMITED.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinations
     */
    readonly limit?: number
}

/**
 * Request parameters for workloadReportAPIGetWorkloadTrafficDestinationsHistory operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistoryRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistoryRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistory
     */
    readonly clusterId: string

    /**
     * Namespace of the workload.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistory
     */
    readonly namespace: string

    /**
     * Workload type, e.g. Deployment, StatefulSet, DaemonSet.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistory
     */
    readonly workloadType: string

    /**
     * Name of the workload.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistory
     */
    readonly workloadName: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistory
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistory
     */
    readonly endTime: string

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistory
     */
    readonly stepSeconds?: number

    /**
     * Limit the number of items in destinations. Default value is 0 &#x3D; UNLIMITED.
     * @type {number}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistory
     */
    readonly limit?: number
}

/**
 * Request parameters for workloadReportAPIGetWorkloadsGPUSummary operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummaryRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummaryRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummary
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummary
     */
    readonly endTime: string

    /**
     * Workloads filtering options.
     * @type {CostreportV1beta1GetWorkloadsGPUSummaryRequestFilter}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummary
     */
    readonly filter: CostreportV1beta1GetWorkloadsGPUSummaryRequestFilter

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummary
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummary
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummary
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummary
     */
    readonly sortOrder?: WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum
}

/**
 * Request parameters for workloadReportAPIGetWorkloadsGPUUtilization operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilizationRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilizationRequest {
    /**
     * Filter workloads by cluster_ids or workload specific data.
     * @type {CostreportV1beta1GetWorkloadsGPUUtilizationRequestFilter}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilization
     */
    readonly filter: CostreportV1beta1GetWorkloadsGPUUtilizationRequestFilter

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilization
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilization
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilization
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilization
     */
    readonly sortOrder?: WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum
}

/**
 * Request parameters for workloadReportAPIGetWorkloadsMetadata operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadataRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadata
     */
    readonly startTime: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadata
     */
    readonly endTime: string

    /**
     * 
     * @type {CostreportV1beta1WorkloadLabelFilters}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadata
     */
    readonly filters: CostreportV1beta1WorkloadLabelFilters
}

/**
 * Request parameters for workloadReportAPIGetWorkloadsWastedGPUCostImpact operation in WorkloadReportAPIApi.
 * @export
 * @interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpactRequest
 */
export interface WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpactRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpact
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpact
     */
    readonly endTime: string

    /**
     * Workloads filtering options.
     * @type {CostreportV1beta1GetWorkloadsWastedGPUCostImpactRequestFilter}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpact
     */
    readonly filter: CostreportV1beta1GetWorkloadsWastedGPUCostImpactRequestFilter

    /**
     * 
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpact
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpact
     */
    readonly pageCursor?: string
}

/**
 * WorkloadReportAPIApi - object-oriented interface
 * @export
 * @class WorkloadReportAPIApi
 * @extends {BaseAPI}
 */
export class WorkloadReportAPIApi extends BaseAPI {
    /**
     * 
     * @summary Gets cluster workload efficiency report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetClusterWorkloadEfficiencyReport(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetClusterWorkloadEfficiencyReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.filterWorkloadNames, requestParameters.filterLabelsOperator, requestParameters.filterWorkloadTypes, requestParameters.filterNamespaces, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster workload efficiency report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetClusterWorkloadEfficiencyReport2(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReport2Request, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetClusterWorkloadEfficiencyReport2(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.stepSeconds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster workload efficiency report for a workload by name.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetClusterWorkloadEfficiencyReportByName(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadEfficiencyReportByNameRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetClusterWorkloadEfficiencyReportByName(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.includeCurrent, requestParameters.includeHistory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use `/v1/cost-reports/workload-labels/names` to get the label names and `/v1/cost-reports/workload-labels/values` to get the label values.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetClusterWorkloadLabels(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadLabelsRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetClusterWorkloadLabels(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster workloads cost report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetClusterWorkloadReport(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReportRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetClusterWorkloadReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.filterWorkloadNames, requestParameters.filterLabelsOperator, requestParameters.filterWorkloadTypes, requestParameters.filterNamespaces, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.labelsToInclude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster workloads cost report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetClusterWorkloadReport2(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadReport2Request, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetClusterWorkloadReport2(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.stepSeconds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.labelsToInclude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster workload efficiency report for the workloads.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadRightsizingPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetClusterWorkloadRightsizingPatch(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetClusterWorkloadRightsizingPatchRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetClusterWorkloadRightsizingPatch(requestParameters.clusterId, requestParameters.workloads, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the kubernetes namespaces for the given cluster IDs.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClustersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetNamespacesForClusters(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetNamespacesForClustersRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetNamespacesForClusters(requestParameters.startTime, requestParameters.endTime, requestParameters.namespaceFilters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets single workload cost report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetSingleWorkloadCostReport(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadCostReportRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetSingleWorkloadCostReport(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets single workload cost report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetSingleWorkloadDataTransferCost(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadDataTransferCostRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetSingleWorkloadDataTransferCost(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets single workload GPU summary.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetSingleWorkloadGPUSummary(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetSingleWorkloadGPUSummaryRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetSingleWorkloadGPUSummary(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster workloads compute cost report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummariesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadCostSummaries(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadCostSummariesRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadCostSummaries(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, requestParameters.labelsToInclude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workloads datatransfer costs
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadDataTransferCost(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCostRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadDataTransferCost(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.filterWorkloadNames, requestParameters.filterLabelsOperator, requestParameters.filterWorkloadTypes, requestParameters.filterNamespaces, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workloads datatransfer costs
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadDataTransferCost2(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadDataTransferCost2Request, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadDataTransferCost2(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.stepSeconds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the kubernetes label names applied to the workloads in the organization.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadLabelNames(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelNamesRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadLabelNames(requestParameters.startTime, requestParameters.endTime, requestParameters.labelFilters, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the kubernetes label values for the given label name.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadLabelValues(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadLabelValues(requestParameters.label, requestParameters.startTime, requestParameters.endTime, requestParameters.labelFilters, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use `/v1/cost-reports/workload-labels/values` instead
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadLabelValuesDeprecated(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadLabelValuesDeprecatedRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadLabelValuesDeprecated(requestParameters.label, requestParameters.startTime, requestParameters.endTime, requestParameters.labelFilters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workload traffic destinations with their costs.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadTrafficDestinations(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadTrafficDestinations(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workload traffic destinations with their costs and history.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadTrafficDestinationsHistory(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadTrafficDestinationsHistoryRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadTrafficDestinationsHistory(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, requestParameters.stepSeconds, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workloads GPU summary report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadsGPUSummary(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUSummaryRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadsGPUSummary(requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets real time workloads GPU utilization %.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadsGPUUtilization(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsGPUUtilizationRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadsGPUUtilization(requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the workloads metadata
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadsMetadata(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsMetadataRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadsMetadata(requestParameters.startTime, requestParameters.endTime, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workloads cost impact of wasted GPU resources report.
     * @param {WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadReportAPIApi
     */
    public workloadReportAPIGetWorkloadsWastedGPUCostImpact(requestParameters: WorkloadReportAPIApiWorkloadReportAPIGetWorkloadsWastedGPUCostImpactRequest, options?: RawAxiosRequestConfig) {
        return WorkloadReportAPIApiFp(this.configuration).workloadReportAPIGetWorkloadsWastedGPUCostImpact(requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum = {
    OR: 'OR',
    AND: 'AND'
} as const;
export type WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum = typeof WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum[keyof typeof WorkloadReportAPIGetClusterWorkloadEfficiencyReportFilterLabelsOperatorEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum = typeof WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum[keyof typeof WorkloadReportAPIGetClusterWorkloadEfficiencyReportSortOrderEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum = typeof WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum[keyof typeof WorkloadReportAPIGetClusterWorkloadEfficiencyReport2SortOrderEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum = {
    OR: 'OR',
    AND: 'AND'
} as const;
export type WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum = typeof WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum[keyof typeof WorkloadReportAPIGetClusterWorkloadReportFilterLabelsOperatorEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum = typeof WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum[keyof typeof WorkloadReportAPIGetWorkloadCostSummariesSortOrderEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum = {
    OR: 'OR',
    AND: 'AND'
} as const;
export type WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum = typeof WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum[keyof typeof WorkloadReportAPIGetWorkloadDataTransferCostFilterLabelsOperatorEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum = typeof WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum[keyof typeof WorkloadReportAPIGetWorkloadDataTransferCostSortOrderEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum = typeof WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum[keyof typeof WorkloadReportAPIGetWorkloadDataTransferCost2SortOrderEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum = typeof WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum[keyof typeof WorkloadReportAPIGetWorkloadsGPUSummarySortOrderEnum];
/**
 * @export
 */
export const WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum = typeof WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum[keyof typeof WorkloadReportAPIGetWorkloadsGPUUtilizationSortOrderEnum];
