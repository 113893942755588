import { forwardRef, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import {
  DrawerProps,
  mergeSx,
  Tab as DsTab,
  TabContent as DsTabContent,
  TabContentProps,
  TabProps,
  Tabs,
  TabsProvider,
  Icons,
  Tooltip,
} from '@cast/design-system';
import { BestPracticeCheckStandard, CheckResourceStatus } from '@cast/types';
import { clearQueryParamSilently, useSearchParamState } from '@cast/utils';

import { Drawer, DrawerContent, DrawerHeader } from 'components/drawer';
import { FailedToLoad } from 'components/messages';
import { useAbility } from 'core/ability';
import { useBestPracticeCheckQuery } from 'hooks/queries/security-insights';

import { Resources } from './_components';
import { CheckActions } from './_components/CheckActions';
import { PolicyEnforcement } from './_components/PolicyEnforcement';
import { TabValue } from './types';
import { CheckOverview } from '../../../_components/CheckOverview';
import { checkDetailsDrawerUrlKey } from '../../../constants';

const activeTabQueryParamKey = 'checks-drawer-tab';

const Tab = (props: TabProps<'button', TabValue>) => {
  return <DsTab {...props} />;
};

const TabContent = (props: TabContentProps<TabValue>) => (
  <DsTabContent {...props} />
);

export type CheckDetailsDrawerPayload = {
  ruleId: string;
  tab?: TabValue;
  selectedClusters?: string[];
  selectedNamespaces?: string[];
  selectedLabels?: string[];
  selectedStandard?: BestPracticeCheckStandard;
  onAction?: () => void;
  onClose?: () => void;
};

export type CheckDetailsDrawerProps = Omit<DrawerProps, 'onClose'> &
  CheckDetailsDrawerPayload;

export const CheckDetailsDrawer = forwardRef<any, any>(
  (
    {
      ruleId,
      tab = TabValue.OVERVIEW,
      selectedClusters: selectedClustersProp,
      selectedNamespaces,
      selectedLabels,
      selectedStandard,
      ...drawerProps
    }: CheckDetailsDrawerProps,
    ref
  ) => {
    const { isLoading, check, error, refetch } = useBestPracticeCheckQuery({
      ruleId,
      standard: selectedStandard,
    });

    const [selectedClusters, setSelectedClusters] =
      useState(selectedClustersProp);
    const [selectedType, setSelectedType] = useState<CheckResourceStatus>(
      CheckResourceStatus.affected
    );

    const canViewPolicyEnforcement = useAbility().can(
      'view',
      'FeatureOrganizationSecurityPolicyEnforcement'
    );

    const { state: _currentTab, setState: setCurrentTab } =
      useSearchParamState<TabValue>(activeTabQueryParamKey, tab);
    const currentTab =
      _currentTab === TabValue.POLICY_ENFORCEMENT && !canViewPolicyEnforcement
        ? TabValue.OVERVIEW
        : _currentTab;

    const isTableTab = [
      TabValue.RESOURCES,
      TabValue.POLICY_ENFORCEMENT,
    ].includes(currentTab as TabValue);

    useEffect(() => {
      return () => {
        drawerProps.onClose?.();
        clearQueryParamSilently(activeTabQueryParamKey);
        clearQueryParamSilently(checkDetailsDrawerUrlKey);
      };
      // Only run this effect when the drawer is closed
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAffectedClick = (clusterId: string) => {
      setSelectedClusters([clusterId]);
      setSelectedType(CheckResourceStatus.affected);
      setCurrentTab(TabValue.RESOURCES);
    };

    const onExceptedClick = (clusterId: string) => {
      setSelectedClusters([clusterId]);
      setSelectedType(CheckResourceStatus.excepted);
      setCurrentTab(TabValue.RESOURCES);
    };

    if (error) {
      return (
        <Drawer ref={ref} size="medium" {...drawerProps}>
          <Box display="flex" alignItems="center" px="40px" py="16px" mt="60px">
            <FailedToLoad title="Failed to load details" refresh={refetch} />
          </Box>
        </Drawer>
      );
    }

    return (
      <Drawer
        testId="check-details-drawer"
        isLoading={isLoading}
        ref={ref}
        size="xlarge"
        sx={mergeSx(
          isTableTab && {
            '& .Drawer-': {
              '&scroller': {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              },
              '&content': {
                flexGrow: 1,
              },
              '&contentWrapper': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              },
            },
          }
        )}
        {...drawerProps}
      >
        <DrawerHeader
          noDivider
          sticky={false}
          actions={<CheckActions check={check} />}
        >
          <Typography variant="L12R" color="grey.400" mt={16}>
            Check
          </Typography>
          <Typography variant="h5" mb={16}>
            {check?.name}
          </Typography>
          <Typography variant="P14R" color="grey.500" mb={24}>
            {check?.description}
          </Typography>
        </DrawerHeader>
        <DrawerContent>
          <TabsProvider currentTab={currentTab} setCurrentTab={setCurrentTab}>
            <Tabs
              size="small"
              fullWidth
              sx={{ mb: 24 }}
              testId="check-details-drawer-tabs"
            >
              <Tab value={TabValue.OVERVIEW} label="Overview & details" />
              <Tab value={TabValue.RESOURCES} label="Resources" />
              {canViewPolicyEnforcement && (
                <Tab
                  value={TabValue.POLICY_ENFORCEMENT}
                  label="Policy enforcement"
                  icon={
                    <Tooltip
                      title="Apply Policy Enforcement in order to prevent the deployment of non-compliant resources to the cluster."
                      arrow
                    >
                      <Icons.Info />
                    </Tooltip>
                  }
                  iconPosition="end"
                />
              )}
            </Tabs>
            <TabContent value={TabValue.OVERVIEW}>
              <CheckOverview check={check} sx={{ pt: 8 }} />
            </TabContent>
            <TabContent value={TabValue.RESOURCES}>
              <Resources
                ruleId={ruleId}
                selectedClusters={selectedClusters}
                selectedNamespaces={selectedNamespaces}
                selectedLabels={selectedLabels}
                selectedType={selectedType}
              />
            </TabContent>
            <TabContent value={TabValue.POLICY_ENFORCEMENT}>
              <PolicyEnforcement
                check={check}
                onAffectedClick={onAffectedClick}
                onExceptedClick={onExceptedClick}
              />
            </TabContent>
          </TabsProvider>
        </DrawerContent>
      </Drawer>
    );
  }
);

CheckDetailsDrawer.displayName = 'CheckDetailsDrawer';
