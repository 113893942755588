import { styled, Theme } from '@mui/material';

import { Chip } from '@cast/design-system';
import { VulnerabilitySeverity } from '@cast/types';

import { getThemeColor } from 'utils/theme';

import { severityToColorMap } from './constants';

const getColors = (theme: Theme, severity: VulnerabilitySeverity) => ({
  backgroundColor: getThemeColor(theme, severityToColorMap[severity]?.bgcolor),
  color: getThemeColor(theme, severityToColorMap[severity]?.color),
});

export const SeverityChip = styled(Chip)(({ theme }) => ({
  textTransform: 'capitalize',
  [`&.${VulnerabilitySeverity.critical}`]: getColors(
    theme,
    VulnerabilitySeverity.critical
  ),
  [`&.${VulnerabilitySeverity.high}`]: getColors(
    theme,
    VulnerabilitySeverity.high
  ),
  [`&.${VulnerabilitySeverity.medium}`]: getColors(
    theme,
    VulnerabilitySeverity.medium
  ),
  [`&.${VulnerabilitySeverity.low}`]: getColors(
    theme,
    VulnerabilitySeverity.low
  ),
  [`&.${VulnerabilitySeverity.none}`]: getColors(
    theme,
    VulnerabilitySeverity.none
  ),
  [`&.${VulnerabilitySeverity.notAvailable}`]: {
    textTransform: 'uppercase',
    ...getColors(theme, VulnerabilitySeverity.notAvailable),
  },
}));

SeverityChip.displayName = 'SeverityChip';
