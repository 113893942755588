export default {
  50: '#DCFBFF',
  100: '#BCF7FF',
  200: '#A4F4FF',
  300: '#7EECFB',
  400: '#5DE2F4',
  500: '#3FD3E7',
  600: '#00B1C9',
  700: '#1890A1',
  800: '#0D7381',
  900: '#004F5A',
};
