import { createContext } from 'react';

import { ClusterAnomaly, NoDataReason, PriceType } from '@cast/types';

import {
  AggregatedMetrics,
  CostReportChartDataByPeriod,
  CostReportDataByPeriod,
} from '../types/costOverTime';

export type ComputeCostDynamicCopiesMap = {
  summary: {
    totalSpendTooltip: string;
    currentSpendTooltip: string;
    monthlyForecastTooltip: string;
    monthlyAverageTooltip: string;
    averageCpuCost: string;
  };
  computeSpend: {
    mainTitle: string;
    mainTooltip: string;
  };
  normalized: {
    mainDefaultTooltip: string;
    mainMonthlyTooltip: string;
    onDemandDefaultTooltip: string;
    onDemandMonthlyTooltip: string;
    fallbackDefaultTooltip: string;
    fallbackMonthlyTooltip: string;
    spotDefaultTooltip: string;
    spotMonthlyTooltip: string;
  };
  podCost?: {
    mainHourlyTooltip: string;
    mainDailyTooltip: string;
    mainMonthlyTooltip: string;
    onDemandHourlyTooltip: string;
    onDemandDailyTooltip: string;
    onDemandMonthlyTooltip: string;
    fallbackHourlyTooltip: string;
    fallbackDailyTooltip: string;
    fallbackMonthlyTooltip: string;
    spotHourlyTooltip: string;
    spotDailyTooltip: string;
    spotMonthlyTooltip: string;
  };
  podCount?: {
    average: string;
  };
};

type CostReportState = {
  isLoading: boolean;
  data: CostReportDataByPeriod[];
  chartData: CostReportChartDataByPeriod[];
  error?: unknown;
};

type CostOverTimeContextValue = {
  key: 'cluster' | 'workload' | 'namespace' | 'allocation-group';
  isCollecting: boolean;
  hasData: boolean;
  projectEndOfDayCost: boolean;
  applyUptime: boolean;
  timeSeriesStep: PriceType;
  error?: unknown;
  refetch?: () => void;
  current: CostReportState;
  previous: CostReportState;
  aggregatedMetrics: AggregatedMetrics;
  dynamicCopiesMap: ComputeCostDynamicCopiesMap;
  noDataReason?: NoDataReason;
  anomalies?: ClusterAnomaly[];
};

export const CostOverTimeContext = createContext<CostOverTimeContextValue>({
  key: '' as never,
  isCollecting: true,
  hasData: false,
  projectEndOfDayCost: true,
  applyUptime: true,
  timeSeriesStep: PriceType.DAILY,
  current: {
    isLoading: true,
    data: [],
    chartData: [],
  },
  previous: {
    isLoading: true,
    data: [],
    chartData: [],
  },
  aggregatedMetrics: {} as never,
  dynamicCopiesMap: {} as never,
});
