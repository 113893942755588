import { createContext } from 'react';

import { ClusterHourlyCostByInterval } from '@cast/types';

import { ClusterListEntity, CostsByTimestamp } from './types';

export const DashboardContext = createContext<{
  failedToLoadClusters?: boolean;
  failedToLoadCostMetrics?: boolean;
  failedToLoadSummary?: boolean;
  clusters?: ClusterListEntity[];
  hourlyCosts?: ClusterHourlyCostByInterval[];
  costsByTimestamp?: CostsByTimestamp;
  hasSecurityEnabled?: boolean;
  refetchClusters?: () => void;
  refetchCostMetrics?: () => void;
  refetchSummary?: () => void;
}>({});
