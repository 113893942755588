import { createContext, useContext } from 'react';

import { RangePickerOptions, Store } from './types';

export const RangePickerContext = createContext<Store & RangePickerOptions>(
  undefined as never
);

export const useRangePickerContext = () => {
  return useContext(RangePickerContext);
};
