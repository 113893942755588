import { forwardRef } from 'react';

import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

type Props = SvgIconProps<'svg', { isExpanded?: boolean }>;

export const ExpandIcon = forwardRef<SVGSVGElement, Props>(
  ({ isExpanded, ...props }: Props, ref) => {
    return (
      <SvgIcon
        className="DS-TreeTable-ExpanderIcon"
        ref={ref}
        width="13"
        height="13"
        viewBox="0 0 15 14"
        fill="none"
        {...props}
      >
        {!isExpanded && (
          <line x1="7.5" y1="3.5" x2="7.5" y2="10.5" stroke="#666461" />
        )}
        <line x1="11" y1="7" x2="4" y2="7" stroke="#666461" />
        <path
          d="M1 2.5C1 1.39543 1.89543 0.5 3 0.5H12C13.1046 0.5 14 1.39543 14 2.5V11.5C14 12.6046 13.1046 13.5 12 13.5H3C1.89543 13.5 1 12.6046 1 11.5V2.5Z"
          stroke="#666461"
          fill="none"
        />
      </SvgIcon>
    );
  }
);

ExpandIcon.displayName = 'ExpandIcon';
