import {
  RebalancingPlanError,
  WorkloadMigrationStatusMigrationStatusEnum,
} from '@cast/types';

export enum RebalanceErrorType {
  PERMISSIONS = 'InsufficientPermissions',
  QUOTA = 'QuotaExceeded',
  GENERATION = 'rebalancingPlanGenerationFailed',
  UPSCALING_FAILED = 'upscalingFailed',
  NODE_DRAIN_FAILED = 'nodeDrainFailed',
  NODE_CREATE_FAILED = 'nodeCreateFailed',
  NODE_DELETE_FAILED = 'nodeDeleteFailed',
  PLAN_TIMEOUT = 'rebalancingPlanTimeout',
}

export type RebalanceErrorTrace =
  | undefined
  | RebalancingPlanError
  | RebalancingPlanError[]
  | Record<string, unknown>
  | Record<string, unknown>[];

export type RebalanceErrorDescription = {
  title: string;
  subtitle: string[] | string;
};

export enum RebalanceNodeEnum {
  READY = WorkloadMigrationStatusMigrationStatusEnum.ready,
  NOT_READY = WorkloadMigrationStatusMigrationStatusEnum.not_ready,
}
