import { ToggleButton, ToggleButtonGroup } from '@cast/design-system';

import { ChartTypeToggleOptions } from 'types/charts';

export type ChartTypeToggleProps<T> = {
  active: T;
  options: ChartTypeToggleOptions;
  onChange?: (type: T) => void;
};

export const ChartTypeToggle = <T extends unknown>({
  active,
  onChange,
  options,
}: ChartTypeToggleProps<T>) => {
  return (
    <ToggleButtonGroup
      value={active}
      size="small"
      onChange={(_, value) => {
        if (value) {
          onChange?.(value);
        }
      }}
      testId="chart-type-toggle-group"
    >
      {options.map(({ value, icon }) => (
        <ToggleButton
          key={value}
          value={value}
          icon={icon}
          testId={`${value}-chart`}
        />
      ))}
    </ToggleButtonGroup>
  );
};
