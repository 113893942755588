import { PropsWithChildren } from 'react';

import {
  createTheme,
  CSSObject,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';

import { theme } from '@cast/design-system';

import { MOST_EXPENSIVE_ITEM_COLOR_MAP } from 'common/constants';
import { getThemeColor } from 'utils/theme';

const consoleTheme = createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiCssBaseline: {
      styleOverrides: {
        '*': { boxSizing: 'border-box' },
        body: {
          margin: 0,
          backgroundColor: '#F4F6F9',

          '.tour-active': {
            scrollMarginTop: '300px',
          },

          '.tour-active .tour-highlight': {
            filter: 'drop-shadow(0px 0px 4px rgba(178, 237, 255, 0.6))',
          },

          '.rankedRow': {
            ...MOST_EXPENSIVE_ITEM_COLOR_MAP.colorByUsageRank.reduce(
              (acc, curr, index) => {
                acc[
                  `&.rank-${index} > .DS-Table-BodyRow > .DS-Table-CellWrapper:first-of-type:before`
                ] = {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: '3px',
                  backgroundColor: getThemeColor(theme, curr),
                };
                return acc;
              },
              {} as Record<string, CSSObject>
            ),
          },
        },
      },
    },
  },
});

export const ThemeProvider = ({ children }: PropsWithChildren<unknown>) => {
  return <MuiThemeProvider theme={consoleTheme}>{children}</MuiThemeProvider>;
};
