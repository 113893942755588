import { useContext } from 'react';

import { Typography } from '@mui/material';

import { CAN_VIEW_SECURITY } from '@cast/console/constants';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  Icons,
} from '@cast/design-system';

import { EXTERNAL_LINKS } from 'common/links';
import { ExternalLink } from 'components/router';

import { AgentAutoscalerSetup } from './setup-blocks/AgentAutoscalerSetup';
import { AgentSecuritySetup } from './setup-blocks/AgentSecuritySetup';
import { AssignCredentialsDialogHeader } from '../../AssignCredentialsDialogHeader';
import { AssignCredentialsDialogContext } from '../context';

export const TerraformClusterSlide = () => {
  const { dismiss } = useContext(AssignCredentialsDialogContext);

  return (
    <>
      <AssignCredentialsDialogHeader dismiss={dismiss} />
      <DialogContent sx={{ pb: 0 }}>
        {CAN_VIEW_SECURITY() && (
          <>
            <Typography variant="h4" mb={12}>
              Enable CAST AI to get:
            </Typography>

            <AgentAutoscalerSetup noCheckbox />
            <AgentSecuritySetup noCheckbox />
          </>
        )}

        <Alert
          sx={{ mt: 12, mb: 32 }}
          color="advisory"
          title="Use Terraform to enable CAST AI"
          size="small"
          icon={<Icons.Info />}
        >
          The cluster was connected using Terraform. Enable CAST AI using the
          same platform.
        </Alert>
      </DialogContent>
      <DialogActions sx={{ borderTop: 'grey.100' }}>
        <Button variant="tertiary" onClick={dismiss}>
          Close
        </Button>
        <Button
          component={ExternalLink}
          link={EXTERNAL_LINKS.castai_terraform}
          noIcon
        >
          Terraform
        </Button>
      </DialogActions>
    </>
  );
};
