import { ReactNode } from 'react';

import { styled, Typography } from '@mui/material';

import { Chip, ChipProps } from '@cast/design-system';

export const StatusChipBase = styled(Chip, {
  target: 'StatusChip-root',
  shouldForwardProp: (prop) => prop !== 'hasCaption',
})<{ hasCaption: boolean }>(({ size, iconChip, startIcon, hasCaption }) => [
  size === 'small' && {
    paddingLeft: startIcon ? 6 : 8,
    paddingRight: 8,
    fontSize: hasCaption ? 8 : 10,
    '& .DsChip-IconWrapper': {
      fontSize: 12,
    },
    '& .MuiCircularProgress-root': {
      width: '12px !important',
      height: '12px !important',
    },
  },
  size === 'large' && {
    gap: '6px',
    fontSize: 12,
    paddingLeft: startIcon ? 6 : undefined,
    '& .DsChip-IconWrapper': {
      fontSize: 16,
    },
    '& .MuiCircularProgress-root': {
      width: '12px !important',
      height: '12px !important',
    },
  },
  size === 'extraLarge' && {
    paddingLeft: startIcon ? 10 : undefined,
    '& .DsChip-IconWrapper': {
      fontSize: 20,
    },
    '& .MuiCircularProgress-root': {
      width: '16px !important',
      height: '16px !important',
    },
  },
  iconChip && { paddingLeft: 0, paddingRight: 0 },
  hasCaption && { '& .DsChip-Content': { flexDirection: 'column' } },
]);

export type StatusChipProps = ChipProps & { caption?: ReactNode };

export const StatusChip = ({
  children,
  caption,
  ...props
}: StatusChipProps) => {
  const size = props.size;
  return (
    <StatusChipBase {...props} hasCaption={!!caption}>
      {children}
      {caption && (
        <Typography
          className="StatusChip-Caption"
          display="flex"
          fontWeight={400}
          lineHeight={1}
          {...(size === 'small'
            ? {
                fontSize: 8,
              }
            : { fontSize: 10, lineHeight: '14px' })}
        >
          {caption}
        </Typography>
      )}
    </StatusChipBase>
  );
};
