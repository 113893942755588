import { useMutation } from '@tanstack/react-query';

import { referralClient } from '@cast/api-client';
import { ReferralEvent } from '@cast/types';

export const useReferralMutation = () => {
  return useMutation({
    mutationFn: (event: ReferralEvent) =>
      referralClient.postReferralEvent(event),
    onError: undefined,
  });
};
