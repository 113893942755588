import { MutableRefObject } from 'react';

import { NormalizedTableProps, TableApi } from '../../../types';
import { reorderRows } from '../../../utils';

export const useReorderableRows = (
  { current: api }: MutableRefObject<TableApi>,
  { rowKey, onRowReordered }: NormalizedTableProps
) => {
  if (api.state.reorderedData === undefined) {
    // FIXME: this introduces a coupling between plugins and could be solved by using a common `displayData` var
    api.state.reorderedData = api.state.sortedData;
  }

  api.reorderRow = (reorderedRow) => {
    if (onRowReordered) {
      onRowReordered({ ...reorderedRow, tableApi: api });
    } else {
      api.setState((old) => {
        const newRows = reorderRows(
          reorderedRow,
          old.reorderedData || [],
          rowKey
        );
        return {
          ...old,
          reorderedData: newRows,
        };
      }, true);
    }
  };
};
