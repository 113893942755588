import { ElementType, ReactNode } from 'react';

import { styled } from '@mui/material';
import clsx from 'clsx';

import { Info } from '../../icons';
import { TooltipProps } from '../../tooltip';
import { Tooltip } from '../../tooltip/Tooltip';

export type FormLabelProps = {
  children?: ReactNode;
  labelTooltipProps?: Partial<TooltipProps>;
  size?: 'small' | 'medium' | 'large';
  icon?: ElementType;
  disabled?: boolean;
  className?: string;
  empty?: boolean;
  testId?: string;
};

const InfoIcon = styled(Info, {
  name: 'DsFormLabel',
  slot: 'Icon',
  target: 'DS-FormLabel-icon',
  overridesResolver: (_, styles) => {
    return styles.icon;
  },
})({});

const DsFormLabel = styled(
  ({
    children,
    labelTooltipProps = {},
    icon,
    className,
    disabled,
    empty,
    testId,
  }: FormLabelProps) => {
    if (!empty && !(children ?? labelTooltipProps.title)) {
      return null;
    }
    return (
      <div
        className={clsx(className, disabled && 'DS-disabled')}
        data-testid={testId}
      >
        {(children ?? empty) && (
          <span className="DS-InputLabel-label">
            {children ?? (empty && ' ')}
          </span>
        )}
        {labelTooltipProps.title && (
          <Tooltip
            placement="top"
            arrow
            {...labelTooltipProps}
            title={labelTooltipProps.title}
          >
            <InfoIcon as={icon} />
          </Tooltip>
        )}
      </div>
    );
  },
  {
    name: 'DsFormLabel',
    slot: 'Root',
    target: 'DS-FormLabel-root',
    overridesResolver: ({ size = 'medium', empty }, styles) => {
      return [styles.root, styles[size], empty && styles.empty];
    },
  }
)({});

export default DsFormLabel;
