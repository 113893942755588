import {
  Box,
  BoxProps,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

type Props = {
  className?: string;
  BoxProps?: BoxProps;
};

const Border = styled('span')(({ theme }) => ({
  position: 'relative',
  width: 1,
  height: '100%',
  color: theme.palette.green[700],
  backgroundColor: theme.palette.green[700],

  '&::after': {
    position: 'absolute',
    color: theme.palette.green[700],
    content: 'url("/assets/svg/triangle.svg")',
  },
  '&.left::after': {
    top: -11,
    left: -5,
  },
  '&.right::after': {
    top: -11,
    right: -5,
  },

  '&::before': {
    position: 'absolute',
    color: theme.palette.green[700],
    content: 'url("/assets/svg/triangle.svg")',
    transform: 'rotate(-180deg)',
  },
  '&.left::before': {
    bottom: -11,
    left: -5,
  },
  '&.right::before': {
    bottom: -11,
    right: -5,
  },
}));

export const OptimalZone = ({ className, BoxProps = {} }: Props) => {
  const theme = useTheme();
  const isCompact = useMediaQuery(theme.breakpoints.down('xl'));
  return (
    <Box
      className={className}
      display="flex"
      justifyContent="space-between"
      {...BoxProps}
    >
      <Border className="left" />
      <Typography
        component="span"
        position="absolute"
        textAlign="center"
        bottom={-8}
        left={0}
        right={0}
      >
        <Typography variant="L10R" color="green.700">
          Optimal {!isCompact ? 'zone' : ''}
        </Typography>
      </Typography>
      <Border className="right" />
    </Box>
  );
};
