import { MouseEvent, useMemo } from 'react';

import { useSelectableRows } from './hooks/useSelectableRows';
import { usePersistence } from './persistence/usePersistence';
import { DataTableProps } from './types';
import { mergeSx } from '../../../utils';
import { useDrawerInstance } from '../../drawer/hooks/useDrawerInstance';
import { CaretDown, CaretUp } from '../../icons';
import { Column } from '../Column';
import { ActionIcon } from '../components';
import { PersistenceProvider } from '../persistence/PersistenceProvider';
import { Table } from '../Table';
import { RowState } from '../types';

const DataTable = <T extends any = any>(_props: DataTableProps<T>) => {
  const {
    rowKey: _rowKey,
    expanderIcon,
    expanderColumnProps,
    columnsBeforeCheckbox,
    ...props
  } = _props;
  const { isInDrawer } = useDrawerInstance();
  const rowKey = useMemo(() => {
    if (!_rowKey) {
      return (row: any) => row.id;
    } else if (typeof _rowKey === 'function') {
      return _rowKey;
    }
    return (row: any) => row[_rowKey];
  }, [_rowKey]);

  const expandableRows = !!_props.getExpandedPanel;

  const { checkboxColumn, getRowClass } = useSelectableRows({
    ...props,
    rowKey,
  });

  const getExpanderIcon = (row: any, state: RowState<any>) => {
    if (expanderIcon) {
      return expanderIcon(row, state);
    }
    return state.isExpanded ? CaretUp : CaretDown;
  };

  const onExpanderClick = (
    e: MouseEvent<HTMLDivElement>,
    state: RowState,
    row: any
  ) => {
    state.tableApi.toggleIsRowExpanded(row);
    e.stopPropagation();
  };

  const persistenceProviderProps = usePersistence(_props);

  return (
    <PersistenceProvider {...persistenceProviderProps}>
      <Table
        {...props}
        getRowClass={getRowClass}
        rowKey={rowKey}
        sx={mergeSx(
          isInDrawer && {
            '& .DS-Table-RowWrapper.DS-Table-HeaderRowWrapper': {
              borderRadius: 0,
              borderBottom: 'grey.100',
              borderTop: 'grey.100',
            },
            '& .DS-Table-hScroller': {
              filter: 'none',
            },
            '& .DS-Table-Body:after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '1px',
              backgroundColor: 'grey.100',
            },
          },
          _props.sx
        )}
      >
        <>{columnsBeforeCheckbox}</>
        {checkboxColumn}
        {_props.children}
        {expandableRows && (
          <Column
            id="expander"
            width={50}
            renderCell={(row, state) => {
              const Icon = getExpanderIcon(row, state);
              if (
                !_props.isRowExpandable ||
                _props.isRowExpandable(row, state)
              ) {
                return (
                  <ActionIcon onClick={(e) => onExpanderClick(e, state, row)}>
                    <Icon data-testid="table-row-expander" />
                  </ActionIcon>
                );
              }
              return null;
            }}
            {...expanderColumnProps}
            cellSx={mergeSx(
              {
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'grey.500',
              },
              expanderColumnProps?.cellSx
            )}
            floating
          />
        )}
      </Table>
    </PersistenceProvider>
  );
};

export default DataTable;
