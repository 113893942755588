import { useDrawer } from '@cast/design-system';

import { CreateJiraTicketDrawer } from './CreateJiraTicketDrawer';
import { CreateJiraTicketDrawerProps } from './types';

export const useCreateJiraTicketDrawer = () => {
  const { open } = useDrawer();

  const openCreateJiraTicketDrawer = (props: CreateJiraTicketDrawerProps) => {
    open(CreateJiraTicketDrawer, props);
  };

  return { openCreateJiraTicketDrawer };
};
