export const getElementFont = (el: HTMLElement) => {
  const defaultFontWeight = 'normal';
  const defaultFontSize = '16px';
  const defaultFontFamily = 'Open Sans';
  if (!window) {
    return `${defaultFontWeight} ${defaultFontSize} ${defaultFontFamily}`;
  }
  const style = window.getComputedStyle(el, null);
  const fontWeight = style.getPropertyValue('font-weight') || defaultFontWeight;
  const fontSize = style.getPropertyValue('font-size') || '16px';
  const fontFamily = style.getPropertyValue('font-family') || 'Times New Roman';
  return `${fontWeight} ${fontSize} ${fontFamily}`;
};

export const getTextWidth = (
  text: string,
  canvasEl: HTMLCanvasElement,
  font: string
) => {
  const context = canvasEl.getContext('2d');
  if (!context) {
    throw new Error("Can't get canvas 2d context");
  }
  context.font = font;
  return context.measureText(text)?.width;
};
