import { cloneElement, ReactElement, ReactNode } from 'react';

import { BoxProps } from '@mui/material';
import { SxProps } from '@mui/system';
import pluralize from 'pluralize';

import { Icons, mergeSx } from '@cast/design-system';

import { HexIcon, HexIconProps } from 'components/hex-icons';
import { StatusMessage } from 'components/messages';

type NoDataProps = {
  icon?: ReactElement;
  iconSize?: number;
  iconColor?: string;
  iconWrapperProps?: BoxProps;
  title?: string;
  entity?: string;
  body?: ReactNode;
  sx?: SxProps;
  hexProps?: HexIconProps;
};

export const NoData = ({
  icon = <Icons.BellSimpleRinging weight="fill" />,
  iconSize = 64,
  iconWrapperProps,
  title,
  entity,
  body,
  sx,
  hexProps = { iconColor: 'grey.500', hexColor: 'grey.300' },
}: NoDataProps) => {
  return (
    <StatusMessage
      icon={
        <HexIcon
          size={iconSize}
          icon={cloneElement(icon, {
            weight: 'fill',
          })}
          {...hexProps}
        />
      }
      iconWrapperProps={iconWrapperProps}
      title={title || `No ${entity ? pluralize(entity, 2) : 'data'}`}
      titleProps={{ sx: { margin: 0 } }}
      body={body}
      sx={mergeSx(
        {
          marginTop: '32px',
        },
        sx
      )}
      testId="no-data"
    />
  );
};
