import { AxiosInstance } from 'axios';

import { InventoryAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const InventoryApiAdapter = (client: AxiosInstance) => {
  const generatedApi = InventoryAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    listRegions: generatedApi.inventoryAPIListRegions,
    listInstanceTypes: generatedApi.inventoryAPIListInstanceTypeNames,
  };
};
