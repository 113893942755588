import get from 'lodash/get';

import { Cloud } from '@cast/types';

import {
  Cluster,
  isAKSCluster,
  isEKSCluster,
  isGKECluster,
  isKOPSCluster,
  isROSACluster,
} from 'types/cluster';

export const mapClusterToCloud = (cluster: Cluster) => {
  const supportedProvidersMap = [
    { validator: isEKSCluster, value: Cloud.aws },
    { validator: isGKECluster, value: Cloud.gcp },
    { validator: isAKSCluster, value: Cloud.azure },
    {
      validator: isKOPSCluster,
      value: () => {
        return isKOPSCluster(cluster) ? cluster.kops.cloud : undefined;
      },
    },
    {
      validator: isROSACluster,
      value: () => {
        return isROSACluster(cluster) ? cluster.openshift.cloud : undefined;
      },
    },
  ];

  return supportedProvidersMap.reduce((acc: Cloud | undefined, curr) => {
    if (curr.validator(cluster)) {
      return typeof curr.value === 'function' ? curr.value() : curr.value;
    }

    return acc;
  }, undefined);
};

export const getUniqueIdentifier = (
  cluster: Cluster
): { value: string; label: string } | undefined => {
  const pathMap = [
    { guard: isEKSCluster, label: 'Account ID', path: 'providerNamespaceId' },
    { guard: isGKECluster, label: 'Project', path: 'providerNamespaceId' },
    {
      guard: isAKSCluster,
      label: 'Resource group',
      path: 'providerNamespaceId',
    },
    {
      guard: isKOPSCluster,
      label: null,
      path: null,
    },
    {
      guard: isROSACluster,
      label: null,
      path: null,
    },
  ];

  return pathMap.reduce(
    (acc: { value: string; label: string } | undefined, curr) => {
      if (curr.guard(cluster) && curr.path) {
        return {
          value: get(cluster, curr.path) || '',
          label: curr.label || '',
        };
      }

      return acc;
    },
    undefined
  );
};
