import { useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';

import { usePrevious } from '@cast/utils';

export const useIsAnimationActive = <T>(value: T | T[]) => {
  const previousValue = usePrevious<T | T[]>(value);
  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(false);

  useEffect(() => {
    if (previousValue && !isEqual(previousValue, value)) {
      setIsAnimationActive(true);
    }
  }, [value, previousValue]);

  // Disables animation in Cypress E2E/integration tests for faster tests
  if ((window as any)?.Cypress) {
    return false;
  }

  return isAnimationActive;
};
