import { FeedbackDialog } from 'components/dialogs';
import { HexIconSuccess } from 'components/hex-icons';

type Props = {
  name: string;
  destroy: () => void;
};

export const CreatedOrganizationDialog = ({ name, destroy }: Props) => {
  return (
    <FeedbackDialog
      icon={<HexIconSuccess />}
      title={`${name} organization created!`}
      body="Go ahead and invite your team to your new organization."
      destroy={destroy}
    />
  );
};
