import { Typography } from '@mui/material';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { Cluster } from '@cast/types';
import { isManagedByTerraform } from '@cast/utils';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useDeleteClusterMutation = (
  cluster: Cluster
): UseMutationResult<unknown, unknown, void, unknown> => {
  const queryClient = useQueryClient();
  const { name, id, managedBy } = cluster;
  const isTerraformManaged = isManagedByTerraform(managedBy);

  return useMutation({
    mutationFn: () => {
      return apiClient.clusters.deleteCluster({ clusterId: id });
    },
    onSuccess: () => {
      if (isTerraformManaged) {
        notify.success(
          <Typography variant="P14R">
            <Typography variant="P14R" color="green.200" component="span">
              Cluster removed:
            </Typography>{' '}
            {name}
          </Typography>
        );
      }
      return queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === QueryKeys.EXTERNAL_CLUSTERS ||
          (query.queryKey[0] === QueryKeys.EXTERNAL_CLUSTER &&
            query.queryKey[1] === id),
      });
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
