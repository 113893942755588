import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { organizationQueryKey } from '@cast/constants';
import {
  clearQueryParamSilently,
  getQueryParam,
  setQueryParamSilently,
} from '@cast/utils';

import { AUTH_PROVIDER_QUERY_KEY, IS_PROD } from 'common/constants';
import { QueryKeys } from 'core/react-query';
import { useOrganizationsQuery } from 'hooks/queries/organization';
import { useNavigate } from 'hooks/useNavigate';
import { Organization } from 'types/organization';
import { isE2E } from 'utils/isE2E';

import { OrganizationContext } from './OrganizationContext';
import {
  determineCurrentOrganization,
  setOrgIdToBrowserStorages,
} from './utils';

const getOrganization = (
  organizationId?: string | null,
  organizations?: Organization[]
) =>
  organizationId
    ? organizations?.find(({ id }) => id === organizationId)
    : undefined;

export const useOrganizationsState = () => {
  const { organizations, isLoading } = useOrganizationsQuery();
  const [requestedOrganizationId, setRequestedOrganizationId] = useState<
    Organization['id'] | undefined
  >();
  const [currentOrganizationId, setCurrentOrganizationId] = useState<
    Organization['id'] | undefined
  >();

  const currentOrganization = useMemo(
    () => getOrganization(currentOrganizationId, organizations),
    [organizations, currentOrganizationId]
  );

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organizationIdFromQuery = queryParams.get(organizationQueryKey);

  const handleSetOrganization = useCallback(
    (newOrgId: Organization['id']) => {
      if (getOrganization(newOrgId, organizations)) {
        setCurrentOrganizationId(newOrgId);
        setOrgIdToBrowserStorages(newOrgId);
        // Organization was changed by user
        if (currentOrganization && currentOrganizationId !== newOrgId) {
          // reset query cache
          queryClient.removeQueries({
            predicate: (query) =>
              // Clear all queries except these ones
              !(
                query.queryKey.includes(QueryKeys.ORGANIZATIONS) ||
                query.queryKey.includes(QueryKeys.AUTH_SESSION) ||
                query.queryKey.includes(QueryKeys.USER_PROFILE)
              ),
          });

          if (window.location.pathname.startsWith('/organization')) {
            navigate(`/organization/management/dashboard`, { replace: true });
          } else {
            navigate(`/dashboard`);
          }
        }
      } else {
        console.error('Trying to set non existing organization');
      }
    },
    [
      currentOrganization,
      currentOrganizationId,
      queryClient,
      navigate,
      organizations,
    ]
  );

  useEffect(() => {
    if (requestedOrganizationId) {
      handleSetOrganization(requestedOrganizationId);
    }
  }, [handleSetOrganization, requestedOrganizationId]);

  useEffect(() => {
    const orgIdInQuery = getQueryParam(organizationQueryKey);
    const isForbidden = location.pathname.includes('/forbidden');
    // Add current org id to query if not present
    if (
      currentOrganizationId &&
      currentOrganizationId !== orgIdInQuery &&
      !isForbidden
    ) {
      setQueryParamSilently(organizationQueryKey, currentOrganizationId);
    }
    clearQueryParamSilently(AUTH_PROVIDER_QUERY_KEY);
  }, [organizationIdFromQuery, currentOrganizationId, location]);

  useEffect(() => {
    // Determine current organization
    if (organizations) {
      const hasQueryOrgAccess =
        !organizationIdFromQuery ||
        organizations.some((org) => org.id === organizationIdFromQuery);
      if (!hasQueryOrgAccess) {
        return navigate('/forbidden', {
          state: {
            message:
              'It seems you’re not part of this organization.\nReach out to the owner of the organization for access.',
          },
        });
      }

      const orgToSet = determineCurrentOrganization(
        organizations,
        organizationIdFromQuery
      );

      if (orgToSet) {
        handleSetOrganization(orgToSet.id);
      } else {
        console.error('Organizations empty');
      }
    }
  }, [navigate, organizations, organizationIdFromQuery, handleSetOrganization]);

  return {
    organizations,
    isLoading,
    isReady: !!currentOrganizationId,
    currentOrganization,
    setCurrentOrganization: setRequestedOrganizationId,
  };
};

export const OrganizationsProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const state = useOrganizationsState();
  const canViewSentry = !isE2E() && IS_PROD;

  useEffect(() => {
    if (canViewSentry && state.currentOrganization) {
      Sentry.configureScope((scope) => {
        scope.setTags({
          organization: state.currentOrganization?.name,
          organizationId: state.currentOrganization?.id,
          organizationRole: state.currentOrganization?.role,
        });
      });
    }
  }, [state.currentOrganization, canViewSentry]);

  return (
    <OrganizationContext.Provider value={state}>
      {children}
    </OrganizationContext.Provider>
  );
};
