import merge from 'lodash/merge';

import { useFlagsQuery } from 'hooks/queries/useFlagsQuery';

export const useResolveFeatureFlags = () => {
  const {
    isLoading: isLoadingFeatureFlags,
    data: featureFlags,
    error: featureFlagsError,
  } = useFlagsQuery({ flagNamePrefix: 'ui_' });
  const {
    isLoading: isLoadingFeatureFlags2,
    data: featureFlags2,
    error: featureFlagsError2,
  } = useFlagsQuery({
    flagName: 'wire-rbacv2-enabled',
  });

  return {
    isLoadingFeatureFlags: isLoadingFeatureFlags || isLoadingFeatureFlags2,
    featureFlags: merge(featureFlags ?? [], featureFlags2 ?? []),
    featureFlagsError: featureFlagsError || featureFlagsError2,
  };
};
