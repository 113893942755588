import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  MutableRefObject,
} from 'react';

import { SxProps } from '@mui/system';

import { MainLayoutContext } from './context';
import { SidebarState } from './types';

type Props = PropsWithChildren<{
  sidebarState: SidebarState;
  sidebarRef: MutableRefObject<HTMLDivElement | null>;
  setSidebarState: (
    state: SidebarState | ((current: SidebarState) => SidebarState)
  ) => void;
  isFullWidthContent: boolean;
  setIsFullWidthContent: (isFullWidth: boolean) => void;
  setStyleOverrides: Dispatch<SetStateAction<SxProps | undefined>>;
}>;

export const MainLayoutProvider = ({
  children,
  sidebarState,
  setSidebarState,
  isFullWidthContent,
  setIsFullWidthContent,
  setStyleOverrides,
  sidebarRef,
}: Props) => {
  return (
    <MainLayoutContext.Provider
      value={{
        sidebarState,
        setSidebarState,
        isInsideMainLayout: true,
        setIsFullWidthContent,
        isFullWidthContent,
        setStyleOverrides,
        sidebarRef,
      }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};
