import { ReactNode } from 'react';

import { Stack, Box, Typography } from '@mui/material';

import { Checkbox, Icons } from '@cast/design-system';

import { useThemeColor } from '../../../../../../../theme';

type FeatureEnabledProps = {
  enabled: boolean;
  iconTestId?: string;
};

const FeatureEnabled = ({ enabled, iconTestId }: FeatureEnabledProps) => {
  const color = useThemeColor(enabled ? 'green.500' : 'grey.400');
  const Icon = enabled ? Icons.CheckCircle : Icons.MinusCircle;
  return (
    <Stack direction="row" gap={8} alignItems="center">
      <Icon
        data-testid={iconTestId}
        data-enabled={enabled}
        size={16}
        weight="fill"
        color={color}
      />
      <Typography variant="P12M" color={color}>
        {enabled ? 'Enabled' : 'Not enabled'}
      </Typography>
    </Stack>
  );
};

export type AgentSetupBlockProps = {
  heading: string;
  description: string | ReactNode;
  icon: ReactNode;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  readonly?: boolean;
  noCheckbox?: boolean;
  checkboxTestId?: string;
};

export const AgentSetupBlock = ({
  heading,
  description,
  icon,
  checked,
  onChange,
  readonly,
  checkboxTestId,
}: AgentSetupBlockProps) => {
  return (
    <Stack
      border={checked ? (readonly ? 'green.500' : 'blue.500') : 'grey.200'}
      borderRadius="4px"
      padding="24px 16px 16px 16px"
      flex={1}
      gap={16}
      position="relative"
    >
      <Stack
        direction="row"
        component="label"
        sx={{
          background: 'white',
          px: 4,
          position: 'absolute',
          gap: 8,
          left: 12,
          top: -9,
        }}
      >
        {readonly ? (
          <FeatureEnabled enabled={!!checked} iconTestId={checkboxTestId} />
        ) : (
          <>
            <Checkbox
              size="small"
              checked={checked}
              onChange={(_, checked) => onChange?.(checked)}
              testId={checkboxTestId}
            />
            <Typography
              variant="P12M"
              color={checked ? 'blue.500' : 'textSecondary'}
            >
              Enable
            </Typography>
          </>
        )}
      </Stack>
      <Stack direction="row" gap={24}>
        <Typography variant="A2">{heading}</Typography>
        <Box
          marginLeft="auto"
          sx={{
            svg: {
              width: 28,
              height: 28,
            },
          }}
        >
          {icon}
        </Box>
      </Stack>
      {!readonly && (
        <Typography variant="P10R" color="textSecondary">
          {description}
        </Typography>
      )}
    </Stack>
  );
};
