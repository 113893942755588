import { ThemeOptions } from '@mui/material';

import { cssVars } from '../../utils/cssVars';

const { getters, setValues } = cssVars(
  {
    color: undefined,
    colorHover: undefined,

    background: undefined,
    backgroundHover: undefined,
    backgroundActive: undefined,

    borderColorActive: undefined,
  },
  'ds-avatar'
);

export const avatarTheme: ThemeOptions['components'] = {
  DsAvatar: {
    defaultProps: {
      variant: 'circular',
      size: 'm',
      color: 'aqua',
    },
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
      },
      circularBlue: ({ theme }) =>
        setValues({
          color: theme.palette.blue[500],
          colorHover: theme.palette.blue[600],
          background: theme.palette.blue[100],
          backgroundHover: theme.palette.blue[200],
          borderColorActive: theme.palette.blue[300],
        }),
      circularGreen: ({ theme }) =>
        setValues({
          color: theme.palette.green[500],
          colorHover: theme.palette.green[700],
          background: theme.palette.green[100],
          backgroundHover: theme.palette.green[200],
          borderColorActive: theme.palette.green[300],
        }),
      circularIndigo: ({ theme }) =>
        setValues({
          color: theme.palette.indigo[400],
          colorHover: theme.palette.indigo[600],
          background: theme.palette.indigo[50],
          backgroundHover: theme.palette.indigo[100],
          borderColorActive: theme.palette.indigo[200],
        }),
      circularAqua: ({ theme }) =>
        setValues({
          color: theme.palette.aqua[700],
          colorHover: theme.palette.aqua[800],
          background: theme.palette.aqua[100],
          backgroundHover: theme.palette.aqua[200],
          borderColorActive: theme.palette.aqua[500],
        }),
      circularYellow: ({ theme }) =>
        setValues({
          color: theme.palette.yellow[800],
          colorHover: theme.palette.yellow[900],
          background: theme.palette.yellow[100],
          backgroundHover: theme.palette.yellow[200],
          borderColorActive: theme.palette.yellow[600],
        }),
      circularRed: ({ theme }) =>
        setValues({
          color: theme.palette.red[400],
          colorHover: theme.palette.red[500],
          background: theme.palette.red[50],
          backgroundHover: theme.palette.red[100],
          borderColorActive: theme.palette.red[200],
        }),
      squareIndigo: ({ theme }) =>
        setValues({
          background: theme.palette.indigo[400],
          backgroundHover: theme.palette.indigo[500],
          backgroundActive: theme.palette.indigo[600],
        }),
      squareAqua: ({ theme }) =>
        setValues({
          background: theme.palette.aqua[600],
          backgroundHover: theme.palette.aqua[700],
          backgroundActive: theme.palette.aqua[800],
        }),
      squareYellow: ({ theme }) =>
        setValues({
          background: theme.palette.yellow[600],
          backgroundHover: theme.palette.yellow[700],
          backgroundActive: theme.palette.yellow[800],
        }),
      squareGrey: ({ theme }) =>
        setValues({
          background: theme.palette.grey[500],
          backgroundHover: theme.palette.grey[600],
          backgroundActive: theme.palette.grey[700],
        }),

      circular: {
        borderRadius: '50%',
        background: getters.background(),
        color: getters.color(),
        border: `1px solid ${getters.background()}`,

        '&:hover': {
          background: getters.backgroundHover(),
          color: getters.colorHover(),
          borderColor: getters.backgroundHover(),
        },
        '&:active, &.active': {
          borderColor: getters.borderColorActive(),
        },
      },
      square: {
        borderRadius: '4px',
        color: '#FFFFFF',
        background: getters.background(),

        '&:hover': {
          background: getters.backgroundHover(),
        },
        '&:active, &.active': {
          background: getters.backgroundActive(),
        },
      },
      xxl: ({ theme, variant }) => ({
        minWidth: '100px',
        minHeight: '100px',
        ...(variant === 'circular'
          ? {
              ...theme.typography.P20B,
              fontSize: '40px',
              lineHeight: '52px',
            }
          : theme.typography.h1),
      }),
      xl: ({ theme, variant }) => ({
        minWidth: '48px',
        minHeight: '48px',
        ...(variant === 'circular'
          ? theme.typography.P20B
          : theme.typography.h5),
      }),
      l: ({ theme, variant }) => ({
        minWidth: '40px',
        minHeight: '40px',
        ...(variant === 'circular'
          ? theme.typography.P16B
          : theme.typography.h6),
      }),
      m: ({ theme, variant }) => ({
        minWidth: '32px',
        minHeight: '32px',
        ...(variant === 'circular'
          ? theme.typography.P14B
          : theme.typography.A1),
      }),
      s: ({ theme, variant }) => ({
        minWidth: '28px',
        minHeight: '28px',
        ...(variant === 'circular'
          ? theme.typography.P12B
          : theme.typography.A2),
      }),
      xs: ({ theme, variant }) => ({
        minWidth: '24px',
        minHeight: '24px',
        ...(variant === 'circular'
          ? theme.typography.P10B
          : theme.typography.A2),
      }),
      disabled: ({ theme }) => ({
        '&:disabled': {
          color: theme.palette.grey[300],
          background: theme.palette.grey[200],
          borderColor: theme.palette.grey[200],
        },
      }),
    },
  },
};
