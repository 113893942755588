import { ThemeOptions } from '@mui/material';

import { cssVars } from '../../utils/cssVars';

export const snackbarVars = cssVars(
  {
    accentColor: undefined,
    accentTextColor: undefined,
  },
  'ds-snackbar'
);

export const snackbarTheme: ThemeOptions['components'] = {
  DsSnackbar: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
        minHeight: '56px',
        background: theme.palette.grey[700],
        color: theme.palette.common.white,
        borderRadius: '4px',
        borderLeft: `8px solid ${snackbarVars.getters.accentColor()}`,
        paddingLeft: '12px',

        ...theme.typography.P14R,

        ...(ownerState?.icon ? { paddingLeft: '0', border: 'none' } : {}),

        '& .DsSnackbar-accent-text': {
          color: snackbarVars.getters.accentTextColor(),
        },

        '& .DsSnackbar-close, & .DsSnackbar-action': {
          marginLeft: 'auto',
          marginRight: '12px',
        },
        '& .DsSnackbar-close': {
          opacity: 0.4,

          '&:hover': {
            opacity: 1,
          },
        },
      }),
      icon: {
        display: 'flex',
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        width: '56px',
        borderRadius: '4px 0 0 4px',
        background: snackbarVars.getters.accentColor(),
      },
      advisory: ({ theme }) =>
        snackbarVars.setValues({
          accentColor: theme.palette.aqua[500],
          accentTextColor: theme.palette.aqua[300],
        }),
      info: ({ theme }) =>
        snackbarVars.setValues({
          accentColor: theme.palette.blue[300],
          accentTextColor: theme.palette.blue[300],
        }),
      success: ({ theme }) =>
        snackbarVars.setValues({
          accentColor: theme.palette.green[400],
          accentTextColor: theme.palette.green[200],
        }),
      warning: ({ theme }) =>
        snackbarVars.setValues({
          accentColor: theme.palette.yellow[700],
          accentTextColor: theme.palette.yellow[300],
        }),
      error: ({ theme }) =>
        snackbarVars.setValues({
          accentColor: theme.palette.red[400],
          accentTextColor: theme.palette.red[200],
        }),
      exceptional: ({ theme }) =>
        snackbarVars.setValues({
          accentColor: theme.palette.indigo[300],
          accentTextColor: theme.palette.indigo[100],
        }),
      disabled: ({ theme }) =>
        snackbarVars.setValues({
          accentColor: theme.palette.grey[400],
          accentTextColor: theme.palette.grey[300],
        }),
    },
  },
};
