import { NodeRoles, Node, NodeResponse } from '@cast/types';

const nodeTypeMap: Record<NodeRoles, string> = {
  [NodeRoles.master]: 'Control plane',
  [NodeRoles.worker]: 'Node',
  [NodeRoles.NODE_TYPE_INVALID]: '',
  [NodeRoles.NODE_TYPE_MASTER]: '',
  [NodeRoles.NODE_TYPE_WORKER]: '',
};

export const transformNodeRole = (value: string) => {
  if (value in nodeTypeMap) {
    return nodeTypeMap[value as NodeRoles];
  }
  return value;
};

export const isRemovalDisabled = (node: Node) => {
  return node.labels?.['autoscaling.cast.ai/removal-disabled'] === 'true';
};

export const isManagedByCASTAI = (node: NodeResponse) => {
  return node.labels?.['provisioner.cast.ai/managed-by'] === 'cast.ai';
};

export const getNodeSelectionTemplateName = (node: Node) =>
  node.labels?.['scheduling.cast.ai/node-template'];

export const getNodeSelectionTemplateVersion = (node: Node) =>
  node.labels?.['scheduling.cast.ai/node-template-version'];

export const getNodeConfigurationName = (node: Node) =>
  node.labels?.['provisioner.cast.ai/node-configuration-name'];

export const getNodeConfigurationVersion = (node: Node) =>
  node.labels?.['provisioner.cast.ai/node-configuration-version'];

export const getResourceUsagePercentage = (
  request?: number,
  allocatable?: number
) => {
  let value;
  if (allocatable && request !== undefined) {
    value = (request * 100) / allocatable;
  }
  return value;
};

export const getMasterNodes = (nodes: Node[]) =>
  nodes.filter((node: Node) => node?.role === NodeRoles.master);
