import { Typography } from '@mui/material';

import { List } from '../../../lists/List';
import { ListItem } from '../../../lists/ListItem';
import { ListItemText } from '../../../lists/ListItemText';
import { ListSubheader } from '../../../lists/ListSubheader';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import { RangePickerProviderProps } from '../../types';
import { buildPresetRange, isSelectedFilterPreset } from '../../utils';

export const FilterPresetsList = () => {
  const ctx = useDatePickerContext<RangePickerProviderProps>();

  return (
    <List size="small" disablePadding testId="range-picker-filter-presets">
      <ListSubheader
        sx={{ alignItems: 'center', padding: 8, color: 'grey.900' }}
      >
        <Typography variant="P12M">Date Range:</Typography>
      </ListSubheader>

      {ctx.filterPresets?.map((preset) => {
        return (
          <ListItem
            key={preset.label}
            sx={{
              borderRadius: '4px',
              padding: '7px 8px',
            }}
            selected={
              ctx.selected
                ? isSelectedFilterPreset(ctx.selected, preset, ctx.timezone)
                : false
            }
            onClick={() => {
              const dateRange = buildPresetRange(preset, ctx.timezone);
              ctx.setSelected(dateRange);
              if (dateRange.from) {
                ctx.pickerRef?.scrollToMonth(dateRange.from);
              }
            }}
            component="li"
            testId={'range-preset-' + preset.label}
          >
            <ListItemText
              primary={preset.label}
              primaryTypographyProps={{ variant: 'P12R' }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};
