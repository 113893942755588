import { useCallback } from 'react';

import { Stack } from '@mui/material';
import { Dayjs } from 'dayjs';

import { DateTimeInput } from '../../components/DateTimeInput';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import { RangePickerProviderProps } from '../../types';
import { dayjsToTZDate, rangeToUtc } from '../../utils';

export const RangePickerInput = () => {
  const ctx = useDatePickerContext<RangePickerProviderProps>();

  const validateFromDate = useCallback(
    (date: Dayjs) => {
      const newDate = !ctx.allowTimeInput ? date.startOf('day') : date;

      if (ctx.selected?.to && newDate.isAfter(ctx.selected.to)) {
        return 'From date is after to date';
      }

      const minDate =
        typeof ctx.minDate === 'function'
          ? ctx.minDate({
              range: rangeToUtc(ctx.selected),
              timezone: ctx.timezone,
            })
          : ctx.minDate;

      if (minDate && newDate.isBefore(minDate)) {
        return 'From date is before min date';
      }

      const maxDate =
        typeof ctx.maxDate === 'function'
          ? ctx.maxDate({
              range: rangeToUtc(ctx.selected),
              timezone: ctx.timezone,
            })
          : ctx.maxDate;

      if (maxDate && newDate.isAfter(maxDate)) {
        return 'From date is after max date';
      }

      if (
        ctx.selected.to &&
        ctx.maxNumberOfDays &&
        Math.abs(newDate.diff(ctx.selected.to, 'day')) > ctx.maxNumberOfDays - 1
      ) {
        return `Date range is above max days limit - (${ctx.maxNumberOfDays})`;
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ctx.selected,
      ctx.maxNumberOfDays,
      ctx.timezone,
      ctx.maxDate,
      ctx.minDate,
      ctx.allowTimeInput,
    ]
  );

  const validateToDate = useCallback(
    (date: Dayjs) => {
      const newDate = !ctx.allowTimeInput ? date.endOf('day') : date;

      if (ctx.selected?.from && newDate.isBefore(ctx.selected.from)) {
        return 'To date is before from date';
      }

      const minDate =
        typeof ctx.minDate === 'function'
          ? ctx.minDate({
              range: rangeToUtc(ctx.selected),
              timezone: ctx.timezone,
            })
          : ctx.minDate;

      if (minDate && newDate.isBefore(minDate)) {
        return 'To date is before min date';
      }

      const maxDate =
        typeof ctx.maxDate === 'function'
          ? ctx.maxDate({
              range: rangeToUtc(ctx.selected),
              timezone: ctx.timezone,
            })
          : ctx.maxDate;

      if (maxDate && newDate.isAfter(maxDate)) {
        return 'To date is after max date';
      }

      if (
        ctx.selected.from &&
        ctx.maxNumberOfDays &&
        Math.abs(newDate.diff(ctx.selected.from, 'day')) >
          ctx.maxNumberOfDays - 1
      ) {
        return `To range is above max days limit - (${ctx.maxNumberOfDays})`;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ctx.selected,
      ctx.maxNumberOfDays,
      ctx.timezone,
      ctx.maxDate,
      ctx.minDate,
      ctx.allowTimeInput,
    ]
  );

  const onFromDateChange = (date?: Dayjs) => {
    const newDate = date
      ? dayjsToTZDate(
          !ctx.allowTimeInput ? date.startOf('day') : date,
          ctx.timezone
        )
      : date;
    ctx.setSelected({
      from: newDate,
      to: ctx.selected?.to,
    });
    if (newDate) {
      ctx.pickerRef?.scrollToMonth(newDate);
    }
  };

  const onToDateChange = (date?: Dayjs) => {
    const newDate = date
      ? dayjsToTZDate(
          !ctx.allowTimeInput ? date.endOf('day') : date,
          ctx.timezone
        )
      : date;
    ctx.setSelected({
      from: ctx.selected?.from,
      to: newDate,
    });
    if (newDate) {
      ctx.pickerRef?.scrollToMonth(newDate);
    }
  };

  return (
    <Stack gap={8}>
      <DateTimeInput
        sx={{
          maxWidth: 224,
          '&& ::placeholder': {
            color: 'grey.400',
          },
        }}
        testId="range-from-input"
        startAdornment="From:"
        placeholder={ctx.startPlaceholder}
        dateToSyncWith={ctx.selected.from}
        dateValidator={validateFromDate}
        onDateChange={onFromDateChange}
      />
      <DateTimeInput
        sx={{
          maxWidth: 224,
          '&& ::placeholder': {
            color: 'grey.400',
          },
          '.DS-Input-startAdornment-root': { pr: 26 },
        }}
        testId="range-to-input"
        startAdornment="To:"
        placeholder={ctx.endPlaceholder}
        dateToSyncWith={ctx.selected.to}
        dateValidator={validateToDate}
        onDateChange={onToDateChange}
      />
    </Stack>
  );
};
