import { useTheme, PopperProps } from '@mui/material';
import { SxProps } from '@mui/system';

import { Icons } from '@cast/design-system';

import { EntityAction, EntityActions } from 'components/common';
import { useAbility } from 'core/ability';
import { FormMode } from 'core/forms/rff';
import { getThemeColor } from 'utils/theme';

type Props = {
  formMode?: FormMode;
  onEdit?: () => void;
  onDelete?: () => void;
  sx?: SxProps;
  container?: PopperProps['container'];
};

export const WebhookFormSubdrawerActions = ({
  formMode,
  onEdit,
  onDelete,
  sx,
  container,
}: Props) => {
  const theme = useTheme();

  const showEditButton = formMode === 'view';

  const [cantEdit, cantDelete] = useAbility().cannotMany([
    ['update', 'OrganizationWebHooks'],
    ['delete', 'OrganizationWebHooks'],
  ]);

  return (
    <EntityActions
      triggerButtonProps={{
        disabled: cantEdit && cantDelete,
        sx,
      }}
      container={container}
      testId="webhook-form-subdrawer-actions"
    >
      {showEditButton && (
        <EntityAction
          onClick={(e) => {
            e.stopPropagation();
            onEdit?.();
          }}
          disabled={cantEdit}
          startAdornment={
            <Icons.PencilSimpleLine color={getThemeColor(theme, 'grey.400')} />
          }
        >
          Edit
        </EntityAction>
      )}
      <EntityAction
        onClick={(e) => {
          e.stopPropagation();
          onDelete?.();
        }}
        disabled={cantDelete}
        startAdornment={<Icons.TrashSimple />}
        disruptive
      >
        Delete
      </EntityAction>
    </EntityActions>
  );
};
