import { useTableContext } from '../hooks';

export const getDraggableContainer = (el: HTMLElement) =>
  el.querySelector('.DS-Table-DraggableCloneContainer') as HTMLDivElement;

export const DraggableContainer = () => {
  const ctx = useTableContext();
  if (!ctx.tableProps.reorderableRows) {
    return null;
  }
  return <div className="DS-Table-DraggableCloneContainer" />;
};
