import { ThemeOptions } from '@mui/material';

import { AbsolutePseudo, focusProps } from '../../../theme/utils';

export const breadcrumbsTheme: ThemeOptions['components'] = {
  MuiBreadcrumbs: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .DS-Breadcrumb-root': {
          position: 'relative',
          ...theme.typography.P12R,
          color: theme.palette.grey[400],
          '&:hover:not(.Mui-disabled)': {
            textDecoration: 'underline',
            cursor: 'pointer',
            color: theme.palette.grey[600],
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[300],
          },
          '&:focus-visible': {
            outline: 'none',
            '&:after': {
              ...AbsolutePseudo,
              ...focusProps(theme),
              inset: -4,
              borderRadius: '4px',
            },
          },
        },
      }),
      separator: ({ theme }) => ({
        color: theme.palette.grey[400],
      }),
    },
  },
};
