import { Box } from '@mui/material';

export const ScheduleMeetingOverlay = () => {
  return (
    <Box
      className="booking-overlay"
      sx={{
        position: 'fixed',
        inset: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        backdropFilter: 'blur(2px)',
        zIndex: 9999998,
      }}
    >
      <Box
        className="booking-loader"
        sx={{
          '@keyframes animateRotate': {
            '0%': {
              transform: 'rotate(0deg)',
            },
            '100%': {
              transform: 'rotate(360deg)',
            },
          },
          display: 'inline-block',
          width: 80,
          height: 80,
          '&:after': {
            content: "''",
            display: 'block',
            width: 64,
            height: 64,
            margin: 8,
            borderRadius: '50%',
            border: '6px solid #fff',
            borderColor: '#fff transparent #fff transparent',
            animation: 'animateRotate 1.2s linear infinite',
          },
        }}
      />
    </Box>
  );
};
