import { useMemo } from 'react';

import { TZDate } from 'react-day-picker';

import { InfiniteDayPicker } from '../../components/InfiniteDayPicker';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import { SingleDayPickerProviderProps } from '../../types';
import { buildDisabledMatcher } from '../../utils';

export const SingleDayInfinitePicker = () => {
  const ctx = useDatePickerContext<SingleDayPickerProviderProps>();

  const disabled = useMemo(() => {
    const minDate =
      typeof ctx.minDate === 'function'
        ? ctx.minDate({ timezone: ctx.timezone })
        : ctx.minDate;

    const maxDate =
      typeof ctx.maxDate === 'function'
        ? ctx.maxDate({ timezone: ctx.timezone })
        : ctx.maxDate;

    return buildDisabledMatcher(minDate, maxDate, ctx.timezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.timezone]);

  const startMonth = useMemo(() => {
    const startMonth =
      typeof ctx.startMonth === 'function'
        ? ctx.startMonth({ timezone: ctx.timezone })
        : ctx.startMonth;

    if (startMonth) {
      return new TZDate(startMonth, ctx.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.timezone]);

  const endMonth = useMemo(() => {
    const endMonth =
      typeof ctx.endMonth === 'function'
        ? ctx.endMonth({ timezone: ctx.timezone })
        : ctx.endMonth;

    if (endMonth) {
      return new TZDate(endMonth, ctx.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.timezone]);

  return (
    <InfiniteDayPicker
      dayPickerProps={{
        mode: ctx.mode,
        selected: ctx.selected,
        onSelect: (date) => ctx.setSelected(date as TZDate | undefined),
        disabled,
      }}
      initialMonth={ctx.initialMonth}
      startMonth={startMonth}
      endMonth={endMonth}
      ref={ctx.setPickerRef}
    />
  );
};
