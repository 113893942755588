import { CreateWebhook, UpdateWebhook, Webhook } from '@cast/types';

import { apiObjectToKeyVal, reduceKeyVal } from 'core/forms';

import { FormModel } from './types';

export const makeFormModel = (webhook?: Webhook): FormModel => {
  if (!webhook) {
    return {
      name: '',
      callbackUrl: '',
      selectedSeverities: [],
      requestTemplate: '',
      authKeys: [{ key: '', value: '' }],
    };
  }
  return {
    id: webhook.id,
    name: webhook.name || '',
    callbackUrl: webhook.callbackUrl || '',
    category: webhook.category,
    subcategory: webhook.subcategory,
    selectedSeverities: webhook.severityTriggers || [],
    requestTemplate: webhook.requestTemplate || '',
    authKeys: apiObjectToKeyVal(webhook.authKeys),
  };
};

export const makeApiModel = (
  formModel: FormModel
): CreateWebhook | UpdateWebhook => {
  return {
    authKeys: reduceKeyVal(formModel.authKeys),
    callbackUrl: formModel.callbackUrl,
    name: formModel.name,
    requestTemplate: formModel.requestTemplate,
    severityTriggers: formModel.selectedSeverities,
    category: formModel.category,
    subcategory: formModel.category ? formModel.subcategory : undefined,
  };
};
