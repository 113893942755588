import { ReactNode } from 'react';

import { Box, BoxProps, Stack, StackProps } from '@mui/material';

import { mergeSx } from '../../../../utils';
import { useDrawerInstance } from '../../../drawer';
import { SettingsButton } from '../../data-table/settings/SettingsButton';

type Props = Omit<StackProps, 'children'> & {
  recordsCount?: ReactNode;
  bulkActions?: ReactNode;
  actions?: ReactNode;
  paginator?: ReactNode;
  showSettings?: boolean;
  noButtonStyling?: boolean;
  recordsCountWrapperProps?: BoxProps;
  bulkActionsWrapperProps?: BoxProps;
  actionsWrapperProps?: StackProps;
  paginatorWrapperProps?: BoxProps;
};

export const TableHeader = ({
  recordsCount,
  bulkActions,
  actions,
  showSettings,
  paginator,
  sx,
  noButtonStyling,
  recordsCountWrapperProps,
  bulkActionsWrapperProps,
  actionsWrapperProps,
  paginatorWrapperProps,
  ...props
}: Props) => {
  const { isInDrawer } = useDrawerInstance();
  showSettings ??= !isInDrawer;

  return (
    <Stack
      direction="row"
      display="grid"
      gridTemplateAreas={`"recordsCount bulkActions paginator actions"`}
      gridTemplateColumns="auto 1fr auto auto"
      alignItems="flex-end"
      {...props}
      sx={mergeSx(
        !noButtonStyling && {
          '& .DsIconButton-root': {
            color: 'grey.500',
            backgroundColor: 'var(--backgroundColor)',
          },
        },
        sx
      )}
    >
      {recordsCount !== undefined && (
        <Box
          gridArea="recordsCount"
          mb={8}
          typography="A1"
          color="grey.500"
          {...recordsCountWrapperProps}
        >
          {recordsCount}
        </Box>
      )}
      {!!bulkActions && (
        <Box gridArea="bulkActions" mb={5} {...bulkActionsWrapperProps}>
          {bulkActions}
        </Box>
      )}
      {!!paginator && (
        <Box gridArea="paginator" pl={8} mb={5} {...paginatorWrapperProps}>
          {paginator}
        </Box>
      )}
      {(!!actions || showSettings) && (
        <Stack
          gridArea="actions"
          direction="row"
          gap={8}
          mb={5}
          {...actionsWrapperProps}
        >
          {actions}
          {showSettings && <SettingsButton />}
        </Stack>
      )}
    </Stack>
  );
};
