import { Stack, Typography } from '@mui/material';

import { isDemoCluster } from '@cast/utils';

import { ClusterDemoStatusChip, ClusterStatusChip } from 'components/chips';
import { K8sProviderIcons } from 'components/icons';
import { ClusterStatusTooltip } from 'components/tooltip';
import { useCluster } from 'hooks/useCluster';

export const ClusterNameRow = () => {
  const { cluster, isManaged } = useCluster();

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      px={20}
      py={12}
    >
      <K8sProviderIcons
        provider={cluster.providerType}
        size={18}
        castEnabled={isManaged}
      />
      <Typography variant="A1" ml={4}>
        {cluster.name}
      </Typography>
      {isDemoCluster(cluster.id) ? (
        <ClusterDemoStatusChip sx={{ ml: 16 }} />
      ) : (
        <ClusterStatusTooltip cluster={cluster}>
          <ClusterStatusChip
            sx={{ ml: 16 }}
            status={cluster.displayStatus}
            managed={isManaged}
          />
        </ClusterStatusTooltip>
      )}
    </Stack>
  );
};
