import dayjs from 'dayjs';

import { TimeSeries } from 'types/metrics';

export const getFutureDatapointIndex = (data: TimeSeries<any>) => {
  const estimatedStartIndex = data.findIndex(({ timestamp }) => {
    return dayjs().isBefore(timestamp);
  });

  return estimatedStartIndex >= 0 ? estimatedStartIndex : undefined;
};
