import { PropsWithChildren } from 'react';

import { Field, FieldProps } from 'react-final-form';

import { FlagsContext } from './FlagsContext';
import { showErrorOnChange } from '../../utils';

type Props = PropsWithChildren<{
  onChange?: (values: string[]) => void;
  name: string;
  fieldProps?: Partial<FieldProps<Array<any>, any>>;
}>;

export const RffFlagsGroup = ({
  name,
  fieldProps,
  onChange: _onChange,
  children,
}: Props) => {
  return (
    <Field
      {...fieldProps}
      name={name}
      render={(props: any) => {
        const {
          input: { value, onChange },
          meta,
        } = props;
        const isFlagChecked = (flag: string) => value.includes(flag);
        const showError = showErrorOnChange(meta);
        return (
          <FlagsContext.Provider
            value={{
              toggleFlag: (flag: string) => {
                const newVal = isFlagChecked(flag)
                  ? value.filter((val: string) => val !== flag)
                  : [...value, flag];
                onChange(newVal);
                _onChange?.(newVal);
              },
              isFlagChecked,
              errors: showError ? meta.error : undefined,
            }}
          >
            {children}
          </FlagsContext.Provider>
        );
      }}
    />
  );
};
