import { useContext, useRef, useState } from 'react';

import {
  DraggableProvided,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import { Collapse, Stack, StackProps, Typography } from '@mui/material';

import { ExpandIcon, LockIcon } from './icons';
import { mergeSx } from '../../../../../../../utils';
import { Checkbox } from '../../../../../../controls';
import { Tooltip } from '../../../../../../tooltip';
import { DragIcon } from '../../../../../components/icons';
import { useRowBaseModel } from '../_hooks/useRowBaseModel';
import { useRowLockingWithAnimation } from '../_hooks/useRowLockingWithAnimation';
import { SettingsDrawerContext } from '../context';
import {
  isColumnsGroup,
  SettingsColumn,
  SettingsGroupedColumn,
  SettingsRowModel,
} from '../types';

type DndProps = {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  dragContainerProps?: DraggableProvidedDraggableProps;
  dragRef?: DraggableProvided['innerRef'];
};

type RowBaseProps = Omit<StackProps, 'title'> & {
  column: SettingsColumn;
  childColumn?: boolean;
  groupHeaderColumn?: boolean;
  switchLocking?: (locked: boolean) => void;
} & DndProps;
const RowBase = ({
  children,
  dragHandleProps,
  dragContainerProps,
  dragRef,
  column,
  childColumn,
  switchLocking: parentSwitchLocking,
  ...stackProps
}: RowBaseProps) => {
  const { switchColumnVisibility } = useContext(SettingsDrawerContext);
  const ref = useRef<HTMLElement | null>(null);
  const isGroup = isColumnsGroup(column);
  const { switchLocking, lockingSx } = useRowLockingWithAnimation({
    id: column.id,
    row: ref.current,
  });

  const {
    title,
    frozen,
    visibilitySettingTooltip,
    lockSettingDisabled,
    lockSettingTooltip,
    reorderSettingDisabled,
    reorderSettingTooltip,
    group,
  } = useRowBaseModel(column);

  return (
    <Stack
      direction="row"
      py={8}
      px={32}
      pl={childColumn ? 64 : undefined}
      borderBottom="grey.100"
      alignItems="center"
      {...stackProps}
      sx={mergeSx(
        stackProps.sx,
        {
          '&:hover': { backgroundColor: 'grey.100' },
        },
        lockingSx
      )}
      {...(dragContainerProps || {})}
      ref={(r) => {
        dragRef?.(r);
        ref.current = r;
      }}
    >
      <Tooltip
        title={visibilitySettingTooltip}
        disableHoverListener={!visibilitySettingTooltip}
      >
        <div>
          <Checkbox
            checked={typeof column.visible === 'string' ? true : column.visible}
            indeterminate={column.visible === 'indeterminate'}
            onChange={(_, checked) =>
              switchColumnVisibility?.({
                id: column.id,
                visible: checked,
                group,
              })
            }
            size="small"
            disabled={frozen}
            sx={{ mr: 16 }}
          />
        </div>
      </Tooltip>
      <Typography
        component="div"
        variant="L10B"
        sx={
          !!isGroup
            ? {
                border: 'grey.200',
                borderRadius: '4px',
                px: 4,
                py: 2,
                mr: 8,
              }
            : undefined
        }
      >
        {title}
      </Typography>
      {children}
      {!childColumn && (
        <Stack direction="row" gap={8} ml="auto" alignItems="center">
          <Tooltip
            title={lockSettingTooltip}
            disableHoverListener={!lockSettingTooltip}
          >
            <LockIcon
              isDisabled={lockSettingDisabled}
              isLocked={column.locked}
              onClick={
                lockSettingDisabled
                  ? undefined
                  : () => (parentSwitchLocking || switchLocking)(!column.locked)
              }
            />
          </Tooltip>
          <Tooltip
            title={reorderSettingTooltip}
            disableHoverListener={!reorderSettingTooltip}
          >
            <DragIcon
              {...dragHandleProps}
              css={({ palette }) => ({
                backgroundColor: 'inherit',
                display: 'flex',
                color: reorderSettingDisabled
                  ? palette.grey[300]
                  : palette.grey[500],
                '&:hover': {
                  color: !reorderSettingDisabled
                    ? palette.grey[900]
                    : undefined,
                },
              })}
            />
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

type SettingGroupRowProps = { group: SettingsGroupedColumn } & DndProps;
const SettingsGroupRow = ({
  group,
  dragHandleProps,
  dragRef,
  dragContainerProps,
}: SettingGroupRowProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { switchLocking, lockingSx } = useRowLockingWithAnimation({
    id: group.id,
    row: ref.current,
  });

  return (
    <Stack
      ref={(r) => {
        dragRef?.(r);
        ref.current = r;
      }}
      sx={mergeSx(
        {
          borderBottom: 'grey.100',
          '&:hover': { backgroundColor: 'grey.100' },
        },
        lockingSx
      )}
      {...(dragContainerProps || {})}
    >
      <RowBase
        column={group}
        dragHandleProps={dragHandleProps}
        switchLocking={switchLocking}
        borderBottom="unset"
      >
        <ExpandIcon
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          css={{ cursor: 'pointer' }}
        />
      </RowBase>
      <Collapse in={isExpanded}>
        <Stack>
          {group.columns!.map((col) => (
            <RowBase
              key={col.id}
              column={col}
              borderBottom="unset"
              childColumn
            />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

type SettingsRowProps = { column: SettingsRowModel } & DndProps;
export const SettingsRow = ({ column, ...rest }: SettingsRowProps) => {
  if (isColumnsGroup(column)) {
    return <SettingsGroupRow group={column} {...rest} />;
  } else {
    return <RowBase column={column} {...rest} />;
  }
};
