import {
  AgentStatus,
  Cloud,
  ClusterDisplayStatus,
  ClusterRegion,
  Cluster,
  ClusterStatus,
  K8sProvider,
} from '@cast/types';

export type { Cluster, ClusterStatus } from '@cast/types';

export type ClusterBase = {
  id: string;
  name: string;
  organizationId: string;
  credentialsId: string;
  createdAt: string;
  region: ClusterRegion;
  status: ClusterStatus;
  agentStatus: AgentStatus;
  agentSnapshotReceivedAt: string;
  providerType: K8sProvider;
  providerNamespaceId?: string;
  displayStatus: ClusterDisplayStatus;
  reconcileError?: string;
};

export type EKSClusterDetails = {
  clusterName: string;
  region: string;
  accountId: string;
  securityGroups: string[];
};

export type EKSCluster = ClusterBase & {
  eks: EKSClusterDetails;
};

export type GKEClusterDetails = {
  clusterName: string;
  region: string;
  projectId: string;
};

export type GKECluster = ClusterBase & {
  gke: GKEClusterDetails;
};

export type AKSClusterDetails = {
  nodeResourceGroup: string;
  region: string;
  subscriptionId: string;
};

export type AKSCluster = ClusterBase & {
  aks: AKSClusterDetails;
};

export type KOPSClusterDetails = {
  clusterName: string;
  region: string;
  cloud: Cloud;
  stateStore: string;
};

export type KopsCluster = ClusterBase & {
  kops: KOPSClusterDetails;
};

export type ROSAClusterDetails = {
  cloud: Cloud;
  clusterName: string;
  internalId: string;
  region: string;
};

export type RosaCluster = ClusterBase & {
  openshift: ROSAClusterDetails;
};

export type RosaClusterOnboardingParameters = {
  runAsUser?: string;
  runAsGroup?: string;
  fsGroup?: string;
};

export const isEKSCluster = (cluster: Cluster): cluster is EKSCluster => {
  return (cluster as EKSCluster).eks !== undefined;
};

export const isGKECluster = (cluster: Cluster): cluster is GKECluster => {
  return (cluster as GKECluster).gke !== undefined;
};

export const isAKSCluster = (cluster: Cluster): cluster is AKSCluster => {
  return (cluster as AKSCluster).aks !== undefined;
};

export const isKOPSCluster = (cluster: Cluster): cluster is KopsCluster => {
  return (cluster as KopsCluster).kops !== undefined;
};

export const isROSACluster = (cluster: Cluster): cluster is RosaCluster => {
  return (cluster as RosaCluster).openshift !== undefined;
};
