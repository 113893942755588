import { Divider, styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

import { PreviewMarkdownBaseElement as Element } from './PreviewMarkdownBaseElement';
import { PreviewMarkdownCodeElement } from './PreviewMarkdownCodeElement';
import { PreviewMarkdownLinkElement } from './PreviewMarkdownLinkElement';

export type PreviewMarkdownProps = {
  markdown: string;
  components?: ReactMarkdownOptions['components'];
};

export const PreviewMarkdown = styled(
  ({ markdown, components, ...props }: PreviewMarkdownProps) => {
    return (
      <ReactMarkdown
        {...props}
        components={{
          h1: ({ children }) => (
            <Element type="h1" as="h1">
              {children}
            </Element>
          ),
          h2: ({ children }) => (
            <Element type="h2" as="h2">
              {children}
            </Element>
          ),
          h3: ({ children }) => (
            <Element type="h3" as="h3">
              {children}
            </Element>
          ),
          h4: ({ children }) => (
            <Element type="h4" as="h4">
              {children}
            </Element>
          ),
          h5: ({ children }) => (
            <Element type="h5" as="h5">
              {children}
            </Element>
          ),
          strong: ({ children }) => (
            <Element type="strong" as="strong">
              {children}
            </Element>
          ),
          p: ({ children }) => (
            <Element type="p" as="p">
              {children}
            </Element>
          ),
          ol: ({ children }) => (
            <Element type="ol" as="ol">
              {children}
            </Element>
          ),
          ul: ({ children }) => (
            <Element type="ul" as="ul">
              {children}
            </Element>
          ),
          code: ({ children, inline, node }) => {
            return (
              <PreviewMarkdownCodeElement inline={inline} node={node}>
                {children}
              </PreviewMarkdownCodeElement>
            );
          },
          hr: () => <Divider className="DsPreviewMarkdown-Divider" />,
          a: ({ href, children }) => {
            return (
              <PreviewMarkdownLinkElement
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {children}
              </PreviewMarkdownLinkElement>
            );
          },
          ...components,
        }}
      >
        {markdown}
      </ReactMarkdown>
    );
  },
  {
    name: 'DsPreviewMarkdown',
    slot: 'Root',
    overridesResolver: (_, theme) => {
      return [theme.root];
    },
  }
)``;
