import {
  useContext,
  ReactNode,
  ElementType,
  forwardRef,
  isValidElement,
} from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MUIListItemButton, {
  ListItemButtonProps as MUIListItemButtonProps,
} from '@mui/material/ListItemButton';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import { ListContext, ListProps } from './List';
import { ListItemAvatar } from './ListItemAvatar';
import { ListItemIcon } from './ListItemIcon';
import { AvatarContext, AvatarProps } from '../avatar';
import { ButtonContext } from '../buttons';
import { ChipContext } from '../chip/Chip';
import { CheckboxContext } from '../controls/checkbox';
import { Radio, RadioContext } from '../controls/radio';

const listSizeToAvatar: Record<
  NonNullable<ListProps['size']>,
  AvatarProps['size']
> = {
  large: 'l',
  medium: 'm',
  small: 's',
};

type OwnerState = {
  size: ListProps['size'];
  disableHover: boolean;
  disableSelect: boolean;
  nested: boolean;
  multiline: boolean;
};

type ListItemRootProps<C extends ElementType = 'li'> = MUIListItemButtonProps<
  C,
  {
    ownerState: OwnerState;
  }
>;

const ListItemRoot = styled(
  (props) => <MUIListItemButton {...props} disableRipple />,
  {
    name: 'DsListItem',
    slot: 'Root',
    target: 'DsListItem-root',
    overridesResolver: ({ ownerState, selected, disabled }, theme) => {
      return [
        theme.root,
        theme[ownerState.size],
        (!ownerState.disableHover || ownerState.nested) && theme.hover,
        !ownerState.disableSelect && !disabled && selected && theme.selected,
        disabled && theme.disabled,
      ];
    },
  }
)<ListItemRootProps>({});

export type ListItemProps<C extends ElementType = 'li'> =
  MUIListItemButtonProps<
    C,
    {
      component?: C;
      startAdornment?: ReactNode;
      startAdornmentSx?: SxProps;
      endAdornment?: ReactNode;
      endAdornmentSx?: SxProps;
      nested?: boolean;
      multiline?: boolean;
      disableHover?: boolean;
      testId?: string;
    }
  >;

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      startAdornment,
      startAdornmentSx,
      endAdornment,
      endAdornmentSx,
      nested,
      multiline,
      disableHover,
      testId,
      children,
      ...props
    },
    ref
  ) => {
    const buttonContext = useContext(ButtonContext);
    const chipContext = useContext(ChipContext);
    const checkboxContext = useContext(CheckboxContext);
    const radioContext = useContext(RadioContext);
    const listContext = useContext(ListContext);

    const ownerState = {
      size: listContext?.size ?? 'medium',
      nested: Boolean(nested),
      multiline: Boolean(multiline),
      disableHover:
        Boolean(disableHover) ||
        Boolean(listContext?.disableHover) ||
        Boolean(listContext?.disableSelect),
      disableSelect: Boolean(listContext?.disableSelect),
    };

    const startAdornmentClassName: string[] = [];
    if (isValidElement(startAdornment)) {
      if (startAdornment.type === ListItemAvatar) {
        startAdornmentClassName.push('DsListItem-adornment-avatar');
      }

      if (startAdornment.type === ListItemIcon) {
        startAdornmentClassName.push('DsListItem-adornment-icon');
      }

      if (startAdornment.type === Radio) {
        startAdornmentClassName.push('DsListItem-adornment-radio');
      }
    }

    const endAdornmentClassName: string[] = [];
    if (isValidElement(endAdornment)) {
      if (endAdornment.type === ListItemAvatar) {
        endAdornmentClassName.push('DsListItem-adornment-avatar');
      }

      if (endAdornment.type === ListItemIcon) {
        endAdornmentClassName.push('DsListItem-adornment-icon');
      }

      if (endAdornment.type === Radio) {
        endAdornmentClassName.push('DsListItem-adornment-radio');
      }
    }

    return (
      <ButtonContext.Provider
        value={{
          ...buttonContext,
          size: listContext?.size,
          disabled: props.disabled,
        }}
      >
        <ChipContext.Provider
          value={{
            ...chipContext,
            size: listContext?.size,
            disabled: props.disabled,
          }}
        >
          <AvatarContext.Provider
            value={{
              size: listContext?.size
                ? listSizeToAvatar[listContext.size]
                : 'm',
              disabled: props.disabled,
            }}
          >
            <CheckboxContext.Provider
              value={{
                ...checkboxContext,
                size:
                  listContext?.size && listContext.size === 'small'
                    ? 'small'
                    : 'medium',
                disabled: props.disabled,
              }}
            >
              <RadioContext.Provider
                value={{
                  ...radioContext,
                  size:
                    listContext?.size &&
                    ['large', 'medium'].includes(listContext.size)
                      ? 'medium'
                      : 'small',
                  disabled: props.disabled,
                }}
              >
                <ListItemRoot
                  ref={ref}
                  {...props}
                  ownerState={ownerState}
                  data-testid={testId}
                >
                  {startAdornment && (
                    <Box
                      className={clsx(
                        'DsListItem-adornment-start',
                        startAdornmentClassName
                      )}
                      sx={startAdornmentSx}
                      component="div"
                    >
                      {startAdornment}
                    </Box>
                  )}

                  <div className="DsListItem-content">{children}</div>

                  {endAdornment && (
                    <Box
                      className={clsx(
                        'DsListItem-adornment-end',
                        endAdornmentClassName
                      )}
                      sx={endAdornmentSx}
                      component="div"
                    >
                      {endAdornment}
                    </Box>
                  )}
                </ListItemRoot>
              </RadioContext.Provider>
            </CheckboxContext.Provider>
          </AvatarContext.Provider>
        </ChipContext.Provider>
      </ButtonContext.Provider>
    );
  }
);

ListItem.displayName = 'ListItem';
