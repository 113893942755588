import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { SecurityInsightsImageExceptionChangesRequest } from '@cast/types';

import { apiClient } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useChangeImageExceptionsMutation = (
  onSuccess: () => void
): UseMutationResult<
  unknown,
  unknown,
  SecurityInsightsImageExceptionChangesRequest,
  unknown
> => {
  return useMutation({
    mutationFn: (request) => {
      return apiClient.securityInsights.changeImageExceptions({
        body: request,
      });
    },
    onSuccess,
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
