import { SecurityInsightsCheckExceptionsResponse } from '@cast/types';

export const makeFormModel = (
  exceptions?: SecurityInsightsCheckExceptionsResponse
) => {
  if (!exceptions?.groups?.length) {
    return {
      groups: [{ clusters: [], namespaces: [], kinds: [], names: [] }],
    };
  }
  return {
    groups: exceptions.groups.map((group) => ({
      clusters: group?.clusters || [],
      namespaces: group?.namespaces || [],
      kinds: group?.kinds || [],
      names: group?.names || [],
    })),
  };
};
