import { ComponentType, lazy } from 'react';

const retry = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): Promise<{ default: T }> => {
  const REFRESH_STORAGE_KEY = 'retry-lazy-refreshed';

  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(REFRESH_STORAGE_KEY) || 'false'
    );

    // try to import the component
    factory()
      .then((component) => {
        window.sessionStorage.setItem(REFRESH_STORAGE_KEY, 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error: unknown) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(REFRESH_STORAGE_KEY, 'true'); // we are now going to refresh

          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export const lazyRetry = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
) => {
  if (window.location.href.includes('http://localhost:3000')) {
    return lazy(factory);
  }

  return lazy(() => retry(factory));
};
