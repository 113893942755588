import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';

import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import {
  mergeSx,
  Autocomplete,
  ListItem,
  Tooltip,
  ListItemText,
  ListItemProps,
} from '@cast/design-system';

import { LinkBehaviour } from 'components/common';
import { Link } from 'components/router';
import { useSearchCriterion } from 'components/search';
import { useActiveClusters } from 'features/organization/_hooks';
import { useSecurityInsightsAgents } from 'hooks/queries/security-insights';

import { useHasNoAgentsEnabled } from '../../hooks/useHasNoAgentsEnabled';

type Option = {
  clusterId: string;
  clusterName: string;
  agentEnabled: boolean;
  providerNamespaceId?: string;
};

type Props = {
  sx?: SxProps;
  listSx?: SxProps;
  criterion?: string;
  analyticsHandler?: (values: string[]) => void;
  initialValue?: string[];
  startAdornment?: ReactNode;
  multiple?: boolean;
};

export const ClusterSelect = ({
  sx,
  listSx,
  criterion = 'clusterIds',
  analyticsHandler,
  initialValue,
  startAdornment,
  multiple = true,
}: Props) => {
  const { setValue, value, clear } = useSearchCriterion(criterion);
  const { clusters } = useActiveClusters();
  const clusterIds = useMemo(
    () => clusters?.map((cluster) => cluster.id) || [],
    [clusters]
  );
  const { agentsData, isLoading: isLoadingAgents } = useSecurityInsightsAgents({
    clusterIds,
  });
  const { hasNoAgentsEnabled } = useHasNoAgentsEnabled();

  // Selected options will be placed at the top of filter values
  const options: Option[] = useMemo(() => {
    if (isLoadingAgents) {
      return [];
    }
    return orderBy(
      clusters.map((cluster) => ({
        clusterId: cluster.id,
        clusterName: cluster.name,
        agentEnabled: !!agentsData?.agentStatuses?.[cluster.id],
        providerNamespaceId: cluster.providerNamespaceId,
      })),
      ['agentEnabled', 'clusterName'],
      ['desc', 'asc']
    );
  }, [isLoadingAgents, clusters, agentsData?.agentStatuses]);

  const handleChange = useCallback(
    (_newOption: Option[] | Option, value: string[] | string | undefined) => {
      if (typeof value === 'string') {
        setValue?.([value]);
        analyticsHandler?.([value]);
      } else if (Array.isArray(value) && value.length > 0) {
        setValue?.(value);
        analyticsHandler?.(value);
      } else {
        clear?.();
      }
    },
    [analyticsHandler, clear, setValue]
  );

  const wasInitialized = useRef(false);
  useEffect(() => {
    if (!initialValue || wasInitialized.current) {
      return;
    }
    setValue?.(initialValue);
    wasInitialized.current = true;
  }, [initialValue, setValue]);

  return (
    <Autocomplete
      testId="cluster-select"
      size="medium"
      sx={mergeSx({ width: '100%' }, sx)}
      listSx={mergeSx(
        { 'li > .DsListItem-root': { alignItems: 'flex-start', py: 4 } },
        listSx
      )}
      inputText="Clusters"
      startAdornment={startAdornment}
      checkable={multiple}
      multiple={multiple}
      value={multiple ? value : value?.[0]}
      onChange={handleChange}
      options={options}
      applySortingWhileSearching={false}
      optionDisabled={(option: Option) =>
        !option.agentEnabled && !hasNoAgentsEnabled
      }
      optionKey={(option: Option) => option.clusterId}
      optionLabel={(option: Option) => option.clusterName}
      optionValue={(option: Option) => option.clusterId}
      renderOption={(
        { agentEnabled, providerNamespaceId, clusterId, clusterName }: Option,
        listItemProps: ListItemProps
      ) => {
        const content = (
          <Stack
            data-testid={
              !agentEnabled && !hasNoAgentsEnabled
                ? 'enable-security-agent'
                : undefined
            }
          >
            <ListItemText primary={clusterName} />
            <ListItemText secondary={providerNamespaceId} />
          </Stack>
        );
        return (
          <ListItem
            {...listItemProps}
            sx={{ py: multiple ? 0 : 4 }}
            disabled={!agentEnabled && !hasNoAgentsEnabled}
            testId={`cluster-option-${clusterId}`}
          >
            {!agentEnabled && !hasNoAgentsEnabled ? (
              <Tooltip
                title={
                  <div>
                    <LinkBehaviour
                      component={Link}
                      to="/organization/security/settings"
                      sx={{ textDecoration: 'none' }}
                    >
                      Enable
                    </LinkBehaviour>{' '}
                    security for this cluster to see it in the report
                  </div>
                }
                placement="right"
                arrow
              >
                {content}
              </Tooltip>
            ) : (
              content
            )}
          </ListItem>
        );
      }}
      isOptionEqualToValue={(option: Option | string, value: string) => {
        if (typeof option === 'string') {
          return option === value;
        }

        return isEqual(option?.clusterId, value);
      }}
      showSelectedOptionsFirst
    />
  );
};
