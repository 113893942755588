import { Tooltip } from '@cast/design-system';
import { hasLongFloat } from '@cast/utils';

import { Money, MoneyProps } from './Money';

type Props = {
  moneyProps: MoneyProps;
  compact?: boolean;
  className?: string;
};

export const MoneyWithTooltip = ({
  compact = false,
  moneyProps,
  className,
}: Props) => {
  const showTooltip =
    compact && moneyProps.amount && hasLongFloat(moneyProps.amount);

  const money = <Money {...moneyProps} />;

  if (showTooltip) {
    return (
      <Tooltip
        title={<Money {...moneyProps} precision={10} raw />}
        placement="top"
        arrow
      >
        <span className={className}>{money}</span>
      </Tooltip>
    );
  }

  return money;
};
