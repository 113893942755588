import { DATE_SIMPLE } from '@cast/constants';
import { DateRange, TimeUnit } from '@cast/types';
import { dateRangeIterator } from '@cast/utils';

import { TimeSeries } from 'types/metrics';

type GenerateEmptyTimeSeriesArgs = {
  range: DateRange;
  format?: string;
  timeUnit?: TimeUnit;
};
export const generateEmptyTimeSeries = ({
  range,
  format = DATE_SIMPLE,
  timeUnit,
}: GenerateEmptyTimeSeriesArgs): TimeSeries<any> => {
  return [...dateRangeIterator(...range, timeUnit)].map((date) => {
    const timestamp = date.format(format);
    return { timestamp };
  });
};
