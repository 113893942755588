import { useEffect, useRef } from 'react';

import { useAuth } from 'core/auth';
import { loadWidget } from 'utils/supportChat';

export const useInitSupportChat = () => {
  const { user } = useAuth();
  const isInitialized = useRef(false);
  useEffect(() => {
    if (!user || isInitialized.current) {
      return;
    }

    loadWidget(user.name, user.email);
    isInitialized.current = true;
  }, [user]);
};
