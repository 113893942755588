/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CastaiInventoryV1beta1AddReservationResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GenericReservation } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GenericReservationsList } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetOrganizationReservationsBalanceResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetOrganizationResourceUsageResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetReservationsBalanceResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetReservationsResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1ListInstanceTypeNamesResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1ListRegionsResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1ListZonesResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1OverwriteReservationsResponse } from '../models';
/**
 * InventoryAPIApi - axios parameter creator
 * @export
 */
export const InventoryAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/import/gcp/cud or /v1/savings/commitments/import/azure/reservation instead.
         * @param {string} organizationId The organization ID.
         * @param {CastaiInventoryV1beta1GenericReservation} reservation Reservation
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIAddReservation: async (organizationId: string, reservation: CastaiInventoryV1beta1GenericReservation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryAPIAddReservation', 'organizationId', organizationId)
            // verify required parameter 'reservation' is not null or undefined
            assertParamExists('inventoryAPIAddReservation', 'reservation', reservation)
            const localVarPath = `/v1/organizations/{organizationId}/reservations`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/{commitment_id} instead.
         * @param {string} organizationId The organization ID.
         * @param {string} reservationId Reservation ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIDeleteReservation: async (organizationId: string, reservationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryAPIDeleteReservation', 'organizationId', organizationId)
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('inventoryAPIDeleteReservation', 'reservationId', reservationId)
            const localVarPath = `/v1/organizations/{organizationId}/reservations/{reservationId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"reservationId"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIGetOrganizationReservationsBalance: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations/reservations/balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get used resources for the currently authenticated organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPIGetOrganizationResourceUsage: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations/resource-usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {string} organizationId The organization ID.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIGetReservations: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryAPIGetReservations', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/reservations`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {string} organizationId The organization ID.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIGetReservationsBalance: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryAPIGetReservationsBalance', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/reservations/balance`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all unique instance type names and families
         * @param {Array<string>} [cloudServiceProviders] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPIListInstanceTypeNames: async (cloudServiceProviders?: Array<string>, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/instances/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cloudServiceProviders) {
                localVarQueryParameter['cloudServiceProviders'] = cloudServiceProviders;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all regions
         * @param {number} [pageSize] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPIListRegions: async (pageSize?: number, pageToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all zones
         * @param {number} [pageSize] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPIListZones: async (pageSize?: number, pageToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/import/gcp/cud or /v1/savings/commitments/import/azure/reservation instead.
         * @param {string} organizationId The organization ID.
         * @param {CastaiInventoryV1beta1GenericReservationsList} reservations Reservations
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIOverwriteReservations: async (organizationId: string, reservations: CastaiInventoryV1beta1GenericReservationsList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryAPIOverwriteReservations', 'organizationId', organizationId)
            // verify required parameter 'reservations' is not null or undefined
            assertParamExists('inventoryAPIOverwriteReservations', 'reservations', reservations)
            const localVarPath = `/v1/organizations/{organizationId}/reservations/overwrite`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync cluster resources
         * @param {string} organizationId The organization ID.
         * @param {string} clusterId The cluster ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPISyncClusterResources: async (organizationId: string, clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('inventoryAPISyncClusterResources', 'organizationId', organizationId)
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('inventoryAPISyncClusterResources', 'clusterId', clusterId)
            const localVarPath = `/v1/organizations/{organizationId}/clusters/{clusterId}/sync-resource-usage`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryAPIApi - functional programming interface
 * @export
 */
export const InventoryAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/import/gcp/cud or /v1/savings/commitments/import/azure/reservation instead.
         * @param {string} organizationId The organization ID.
         * @param {CastaiInventoryV1beta1GenericReservation} reservation Reservation
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async inventoryAPIAddReservation(organizationId: string, reservation: CastaiInventoryV1beta1GenericReservation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1AddReservationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIAddReservation(organizationId, reservation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIAddReservation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/{commitment_id} instead.
         * @param {string} organizationId The organization ID.
         * @param {string} reservationId Reservation ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async inventoryAPIDeleteReservation(organizationId: string, reservationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIDeleteReservation(organizationId, reservationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIDeleteReservation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async inventoryAPIGetOrganizationReservationsBalance(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetOrganizationReservationsBalanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIGetOrganizationReservationsBalance(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIGetOrganizationReservationsBalance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get used resources for the currently authenticated organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryAPIGetOrganizationResourceUsage(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetOrganizationResourceUsageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIGetOrganizationResourceUsage(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIGetOrganizationResourceUsage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {string} organizationId The organization ID.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async inventoryAPIGetReservations(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetReservationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIGetReservations(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIGetReservations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {string} organizationId The organization ID.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async inventoryAPIGetReservationsBalance(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetReservationsBalanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIGetReservationsBalance(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIGetReservationsBalance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all unique instance type names and families
         * @param {Array<string>} [cloudServiceProviders] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryAPIListInstanceTypeNames(cloudServiceProviders?: Array<string>, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ListInstanceTypeNamesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIListInstanceTypeNames(cloudServiceProviders, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIListInstanceTypeNames']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all regions
         * @param {number} [pageSize] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryAPIListRegions(pageSize?: number, pageToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ListRegionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIListRegions(pageSize, pageToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIListRegions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all zones
         * @param {number} [pageSize] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryAPIListZones(pageSize?: number, pageToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ListZonesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIListZones(pageSize, pageToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIListZones']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/import/gcp/cud or /v1/savings/commitments/import/azure/reservation instead.
         * @param {string} organizationId The organization ID.
         * @param {CastaiInventoryV1beta1GenericReservationsList} reservations Reservations
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async inventoryAPIOverwriteReservations(organizationId: string, reservations: CastaiInventoryV1beta1GenericReservationsList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1OverwriteReservationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPIOverwriteReservations(organizationId, reservations, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPIOverwriteReservations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync cluster resources
         * @param {string} organizationId The organization ID.
         * @param {string} clusterId The cluster ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryAPISyncClusterResources(organizationId: string, clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryAPISyncClusterResources(organizationId, clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryAPIApi.inventoryAPISyncClusterResources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InventoryAPIApi - factory interface
 * @export
 */
export const InventoryAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/import/gcp/cud or /v1/savings/commitments/import/azure/reservation instead.
         * @param {InventoryAPIApiInventoryAPIAddReservationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIAddReservation(requestParameters: InventoryAPIApiInventoryAPIAddReservationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1AddReservationResponse> {
            return localVarFp.inventoryAPIAddReservation(requestParameters.organizationId, requestParameters.reservation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/{commitment_id} instead.
         * @param {InventoryAPIApiInventoryAPIDeleteReservationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIDeleteReservation(requestParameters: InventoryAPIApiInventoryAPIDeleteReservationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.inventoryAPIDeleteReservation(requestParameters.organizationId, requestParameters.reservationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIGetOrganizationReservationsBalance(options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetOrganizationReservationsBalanceResponse> {
            return localVarFp.inventoryAPIGetOrganizationReservationsBalance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get used resources for the currently authenticated organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPIGetOrganizationResourceUsage(options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetOrganizationResourceUsageResponse> {
            return localVarFp.inventoryAPIGetOrganizationResourceUsage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {InventoryAPIApiInventoryAPIGetReservationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIGetReservations(requestParameters: InventoryAPIApiInventoryAPIGetReservationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetReservationsResponse> {
            return localVarFp.inventoryAPIGetReservations(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments instead.
         * @param {InventoryAPIApiInventoryAPIGetReservationsBalanceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIGetReservationsBalance(requestParameters: InventoryAPIApiInventoryAPIGetReservationsBalanceRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetReservationsBalanceResponse> {
            return localVarFp.inventoryAPIGetReservationsBalance(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all unique instance type names and families
         * @param {InventoryAPIApiInventoryAPIListInstanceTypeNamesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPIListInstanceTypeNames(requestParameters: InventoryAPIApiInventoryAPIListInstanceTypeNamesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ListInstanceTypeNamesResponse> {
            return localVarFp.inventoryAPIListInstanceTypeNames(requestParameters.cloudServiceProviders, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all regions
         * @param {InventoryAPIApiInventoryAPIListRegionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPIListRegions(requestParameters: InventoryAPIApiInventoryAPIListRegionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ListRegionsResponse> {
            return localVarFp.inventoryAPIListRegions(requestParameters.pageSize, requestParameters.pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all zones
         * @param {InventoryAPIApiInventoryAPIListZonesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPIListZones(requestParameters: InventoryAPIApiInventoryAPIListZonesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ListZonesResponse> {
            return localVarFp.inventoryAPIListZones(requestParameters.pageSize, requestParameters.pageToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/savings/commitments/import/gcp/cud or /v1/savings/commitments/import/azure/reservation instead.
         * @param {InventoryAPIApiInventoryAPIOverwriteReservationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        inventoryAPIOverwriteReservations(requestParameters: InventoryAPIApiInventoryAPIOverwriteReservationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1OverwriteReservationsResponse> {
            return localVarFp.inventoryAPIOverwriteReservations(requestParameters.organizationId, requestParameters.reservations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync cluster resources
         * @param {InventoryAPIApiInventoryAPISyncClusterResourcesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryAPISyncClusterResources(requestParameters: InventoryAPIApiInventoryAPISyncClusterResourcesRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.inventoryAPISyncClusterResources(requestParameters.organizationId, requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for inventoryAPIAddReservation operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPIAddReservationRequest
 */
export interface InventoryAPIApiInventoryAPIAddReservationRequest {
    /**
     * The organization ID.
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIAddReservation
     */
    readonly organizationId: string

    /**
     * Reservation
     * @type {CastaiInventoryV1beta1GenericReservation}
     * @memberof InventoryAPIApiInventoryAPIAddReservation
     */
    readonly reservation: CastaiInventoryV1beta1GenericReservation
}

/**
 * Request parameters for inventoryAPIDeleteReservation operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPIDeleteReservationRequest
 */
export interface InventoryAPIApiInventoryAPIDeleteReservationRequest {
    /**
     * The organization ID.
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIDeleteReservation
     */
    readonly organizationId: string

    /**
     * Reservation ID
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIDeleteReservation
     */
    readonly reservationId: string
}

/**
 * Request parameters for inventoryAPIGetReservations operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPIGetReservationsRequest
 */
export interface InventoryAPIApiInventoryAPIGetReservationsRequest {
    /**
     * The organization ID.
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIGetReservations
     */
    readonly organizationId: string
}

/**
 * Request parameters for inventoryAPIGetReservationsBalance operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPIGetReservationsBalanceRequest
 */
export interface InventoryAPIApiInventoryAPIGetReservationsBalanceRequest {
    /**
     * The organization ID.
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIGetReservationsBalance
     */
    readonly organizationId: string
}

/**
 * Request parameters for inventoryAPIListInstanceTypeNames operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPIListInstanceTypeNamesRequest
 */
export interface InventoryAPIApiInventoryAPIListInstanceTypeNamesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InventoryAPIApiInventoryAPIListInstanceTypeNames
     */
    readonly cloudServiceProviders?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIListInstanceTypeNames
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIListInstanceTypeNames
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for inventoryAPIListRegions operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPIListRegionsRequest
 */
export interface InventoryAPIApiInventoryAPIListRegionsRequest {
    /**
     * 
     * @type {number}
     * @memberof InventoryAPIApiInventoryAPIListRegions
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIListRegions
     */
    readonly pageToken?: string
}

/**
 * Request parameters for inventoryAPIListZones operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPIListZonesRequest
 */
export interface InventoryAPIApiInventoryAPIListZonesRequest {
    /**
     * 
     * @type {number}
     * @memberof InventoryAPIApiInventoryAPIListZones
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIListZones
     */
    readonly pageToken?: string
}

/**
 * Request parameters for inventoryAPIOverwriteReservations operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPIOverwriteReservationsRequest
 */
export interface InventoryAPIApiInventoryAPIOverwriteReservationsRequest {
    /**
     * The organization ID.
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPIOverwriteReservations
     */
    readonly organizationId: string

    /**
     * Reservations
     * @type {CastaiInventoryV1beta1GenericReservationsList}
     * @memberof InventoryAPIApiInventoryAPIOverwriteReservations
     */
    readonly reservations: CastaiInventoryV1beta1GenericReservationsList
}

/**
 * Request parameters for inventoryAPISyncClusterResources operation in InventoryAPIApi.
 * @export
 * @interface InventoryAPIApiInventoryAPISyncClusterResourcesRequest
 */
export interface InventoryAPIApiInventoryAPISyncClusterResourcesRequest {
    /**
     * The organization ID.
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPISyncClusterResources
     */
    readonly organizationId: string

    /**
     * The cluster ID.
     * @type {string}
     * @memberof InventoryAPIApiInventoryAPISyncClusterResources
     */
    readonly clusterId: string
}

/**
 * InventoryAPIApi - object-oriented interface
 * @export
 * @class InventoryAPIApi
 * @extends {BaseAPI}
 */
export class InventoryAPIApi extends BaseAPI {
    /**
     * 
     * @summary [Deprecated] Use /v1/savings/commitments/import/gcp/cud or /v1/savings/commitments/import/azure/reservation instead.
     * @param {InventoryAPIApiInventoryAPIAddReservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIAddReservation(requestParameters: InventoryAPIApiInventoryAPIAddReservationRequest, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIAddReservation(requestParameters.organizationId, requestParameters.reservation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use /v1/savings/commitments/{commitment_id} instead.
     * @param {InventoryAPIApiInventoryAPIDeleteReservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIDeleteReservation(requestParameters: InventoryAPIApiInventoryAPIDeleteReservationRequest, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIDeleteReservation(requestParameters.organizationId, requestParameters.reservationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use /v1/savings/commitments instead.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIGetOrganizationReservationsBalance(options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIGetOrganizationReservationsBalance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get used resources for the currently authenticated organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIGetOrganizationResourceUsage(options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIGetOrganizationResourceUsage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use /v1/savings/commitments instead.
     * @param {InventoryAPIApiInventoryAPIGetReservationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIGetReservations(requestParameters: InventoryAPIApiInventoryAPIGetReservationsRequest, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIGetReservations(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use /v1/savings/commitments instead.
     * @param {InventoryAPIApiInventoryAPIGetReservationsBalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIGetReservationsBalance(requestParameters: InventoryAPIApiInventoryAPIGetReservationsBalanceRequest, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIGetReservationsBalance(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all unique instance type names and families
     * @param {InventoryAPIApiInventoryAPIListInstanceTypeNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIListInstanceTypeNames(requestParameters: InventoryAPIApiInventoryAPIListInstanceTypeNamesRequest = {}, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIListInstanceTypeNames(requestParameters.cloudServiceProviders, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all regions
     * @param {InventoryAPIApiInventoryAPIListRegionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIListRegions(requestParameters: InventoryAPIApiInventoryAPIListRegionsRequest = {}, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIListRegions(requestParameters.pageSize, requestParameters.pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all zones
     * @param {InventoryAPIApiInventoryAPIListZonesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIListZones(requestParameters: InventoryAPIApiInventoryAPIListZonesRequest = {}, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIListZones(requestParameters.pageSize, requestParameters.pageToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use /v1/savings/commitments/import/gcp/cud or /v1/savings/commitments/import/azure/reservation instead.
     * @param {InventoryAPIApiInventoryAPIOverwriteReservationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPIOverwriteReservations(requestParameters: InventoryAPIApiInventoryAPIOverwriteReservationsRequest, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPIOverwriteReservations(requestParameters.organizationId, requestParameters.reservations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync cluster resources
     * @param {InventoryAPIApiInventoryAPISyncClusterResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryAPIApi
     */
    public inventoryAPISyncClusterResources(requestParameters: InventoryAPIApiInventoryAPISyncClusterResourcesRequest, options?: RawAxiosRequestConfig) {
        return InventoryAPIApiFp(this.configuration).inventoryAPISyncClusterResources(requestParameters.organizationId, requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }
}

