import { Box, Stack, Typography } from '@mui/material';
import pluralize from 'pluralize';

import { FailedToLoad } from 'components/messages';
import { Search, SearchProvider, useSearchContext } from 'components/search';

import { ResourceTableItem, ResourcesTable } from './ResourcesTable';

type ResourceContentProps = {
  isError?: boolean;
  refetch?: () => void;
};

const ResourceContent = ({ isError, refetch }: ResourceContentProps) => {
  const searchCtx = useSearchContext();
  return (
    <Stack>
      <Stack
        width="100%"
        sx={{
          backgroundColor: 'grey.100',
          border: '1px solid',
          borderColor: 'grey.200',
          borderRadius: '8px 8px 0px 0px',
          padding: '12px 20px',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={16}
        >
          <Typography variant="A2">
            {pluralize('resource', searchCtx?.data?.length ?? 0, true)}
          </Typography>
        </Box>
        {!isError && <Search />}
      </Stack>
      {isError ? (
        <Stack padding={16}>
          <FailedToLoad title="Failed to load resources" refresh={refetch} />
        </Stack>
      ) : (
        <ResourcesTable />
      )}
    </Stack>
  );
};

type Props = {
  resources?: ResourceTableItem[];
  isLoading: boolean;
  isError?: boolean;
  refetch: () => void;
};

export const Resources = ({
  resources,
  isLoading,
  isError,
  refetch,
}: Props) => {
  return (
    <SearchProvider data={resources} isLoading={isLoading}>
      <ResourceContent isError={isError} refetch={refetch} />
    </SearchProvider>
  );
};
