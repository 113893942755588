import { useDrawer } from '@cast/design-system';
import { SecurityInsightsContainerImage } from '@cast/types';

import { CancelImageExceptionDrawer } from './CancelImageExceptionDrawer';

export const useCancelImageExceptionDrawer = () => {
  const { open } = useDrawer();

  const openCancelImageExceptionDrawer = (
    images: SecurityInsightsContainerImage[],
    onCancelled?: () => void
  ) => {
    open(CancelImageExceptionDrawer, {
      images,
      onCancelled,
    });
  };

  return { openCancelImageExceptionDrawer };
};
