import { createContext } from 'react';

import { NoDataReason } from '@cast/types';

import { TimeSeries } from 'types/metrics';

import { DataTransferTimeSeries } from '../../../cost-report/types/networkCostOverTime';

export type NetworkCostDynamicCopiesMap = {
  summary: {
    totalSpendTooltip: string;
    averageTotalDailyCost: string;
    averageDailyCost: string;
  };
  dailyDetails: {
    totalCost: string;
  };
};

type NetworkCostReportState = {
  isLoading: boolean;
  data: {
    items: DataTransferTimeSeries;
    noDataReason?: NoDataReason;
  };
  chartData: DataTransferTimeSeries | TimeSeries<unknown>;
  error?: unknown;
};

type NetworkCostOverTimeContextValue = {
  key: 'cluster' | 'workload';
  error?: unknown;
  refetch?: () => void;
  current: NetworkCostReportState;
  previous: NetworkCostReportState;
  dynamicCopiesMap: NetworkCostDynamicCopiesMap;
};

export const NetworkCostOverTimeContext =
  createContext<NetworkCostOverTimeContextValue>({
    key: '' as never,
    current: {
      isLoading: true,
      data: {
        items: [],
      },
      chartData: [],
    },
    previous: {
      isLoading: true,
      data: {
        items: [],
      },
      chartData: [],
    },
    dynamicCopiesMap: {} as never,
  });
