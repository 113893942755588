import { HTMLAttributes } from 'react';

import clsx from 'clsx';

export const Comma = ({
  className,
  ...props
}: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span {...props} className={clsx('DsPreviewJson-Comma', className)}>
      ,
    </span>
  );
};
