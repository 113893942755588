import { useRef } from 'react';

import { captureMessage } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { client } from '@cast/core';

import { QueryKeys } from 'core/react-query';

type UseCountryCodeQuery = {
  country?: string;
  isLoading: boolean;
  error?: any;
};

export const useCountryCodeQuery = (enabled: boolean): UseCountryCodeQuery => {
  const requestStart = useRef<number>(0);
  const { data, isLoading, error } = useQuery({
    queryKey: [QueryKeys.COUNTRY_CODE],
    queryFn: async () => {
      requestStart.current = new Date().getTime();
      const startTime = new Date().getTime();
      const { data } = await client.get('https://api.country.is');
      const endTime = new Date().getTime();
      const duration = endTime - startTime;
      if (duration > 1000) {
        captureMessage(
          `Country code: fetching took over one second (${duration} milliseconds)`
        );
      }
      return data.country || null;
    },
    enabled,
    gcTime: Number.MAX_SAFE_INTEGER,
  });
  return { country: data || undefined, isLoading, error };
};
