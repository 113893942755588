import { ThemeOptions } from '@mui/material';

export const globalStyles: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: {
      '*': { boxSizing: 'border-box' },
      body: { margin: 0, backgroundColor: '#F4F6F9' },
    },
  },
};
