import { progressBarTheme } from './progress-bar/progressBarTheme';
import { ratioProgressBarTheme } from './ratio-progress-bar/ratioProgressBarTheme';
import { skeletonTheme } from './skeleton/skeletonTheme';
import { spinnerTheme } from './spinner/spinnerTheme';

export const feedbackThemes = {
  ...skeletonTheme,
  ...spinnerTheme,
  ...ratioProgressBarTheme,
  ...progressBarTheme,
};
