import { cloneElement, ReactElement, UIEvent } from 'react';

import { Button, ButtonProps } from '@cast/design-system';

import { useSearchContext } from './hooks';

type ClearAllButtonProps = {
  button?: ReactElement;
} & ButtonProps;

export const ClearAllButton = ({ button, ...props }: ClearAllButtonProps) => {
  const searchState = useSearchContext();
  if (button) {
    return cloneElement(button, {
      onClick: (event: UIEvent) => {
        if (searchState) {
          searchState.resetInitial(event);
        }
      },
      disabled: searchState && !searchState.valuesChanged,
      testId: 'clear-all-button',
      ...props,
    });
  }
  return (
    <Button
      variant="tertiary"
      onClick={(event: UIEvent) => {
        if (searchState) {
          searchState.resetInitial(event);
        }
      }}
      disabled={searchState && !searchState.valuesChanged}
      testId="clear-all-button"
      {...props}
    >
      Clear all
    </Button>
  );
};
