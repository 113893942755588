import { forwardRef, MouseEvent, PropsWithChildren } from 'react';

import { styled } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import { Icons } from '@cast/design-system';

type Props = {
  link?: string;
  noIcon?: boolean;
  sx?: SxProps;
  iconProps?: {
    size: string | number;
  };
  onClick?: (event: MouseEvent) => void;
  className?: string;
  testId?: string;
};

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.blue[500],
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  height: 'max-content',
  cursor: 'pointer',
  '& svg': {
    marginLeft: 4,
    flexShrink: 0,
  },
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const ExternalLink = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<Props>
>((props, ref) => {
  const {
    children,
    link,
    iconProps,
    onClick,
    className,
    testId = 'external-link',
  } = props;
  return (
    <StyledLink
      className={clsx(className, 'ExternalLink-root')}
      sx={props.sx}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      onClick={onClick}
      ref={ref}
      data-testid={testId}
    >
      {children}
      {!props.noIcon && (
        <Icons.ArrowSquareOut
          size={iconProps?.size || '1.3em'}
          color="currentColor"
        />
      )}
    </StyledLink>
  );
});
ExternalLink.displayName = 'ExternalLink';
