import { CSSObject } from '@mui/material';
import get from 'lodash/get';

import {
  ColumnGroupProps,
  ColumnModel,
  ColumnProps,
  ColumnsInfo,
  RowState,
} from '../../../types';

export type ProcessColumnArgs = {
  columnProps: ColumnProps;
  groupProps?: ColumnGroupProps;
  info: ColumnsInfo;
};

export const processColumn = ({
  columnProps: col,
  groupProps,
  info,
}: {
  columnProps: ColumnProps;
  groupProps?: ColumnGroupProps;
  info: ColumnsInfo;
}) => {
  if (!col.visible) {
    return;
  }
  const computedWidth = info.columnWidths[col.id];
  const startOffset =
    info.columnModels[info.columnModels.length - 1]?.endOffset || 0;
  const result: Partial<ColumnModel> = {
    ...col,
    renderCell:
      col.renderCell === undefined
        ? (row: any) => get(row, col.id)
        : typeof col.renderCell === 'string'
        ? (row: any) => get(row, col.renderCell as string)
        : col.renderCell,
    groupProps,
    computedWidth,
    startOffset,
    endOffset: startOffset + computedWidth,
  };

  if (groupProps?.locked) {
    result.locked = true;
  }

  if (col.sortable) {
    result.accessor = col.accessor || col.id;
    if (col.defaultSort) {
      info.defaultSortingState = {
        columnId: col.id,
        sortBy: { direction: col.defaultSort, accessor: result.accessor },
      };
    }
  }

  if (result.locked) {
    info.lockedColumnsWidth += computedWidth;
  } else {
    info.scrollableColumnsWidth += computedWidth;
  }

  if (col.renderFooter) {
    info.hasFooter = true;
  }

  if (!col.getColumnSpan && col.childRowColumnSpan) {
    result.getColumnSpan = (_, state: RowState) => {
      if (state.level! > 0) {
        return col.childRowColumnSpan;
      }
      return 1;
    };
  }

  const styles: CSSObject = {
    width: computedWidth,
    minWidth: computedWidth,
    maxWidth: computedWidth,
    '--startOffset': `${startOffset}px`,
  };
  info.columnStyles[`& .DS-Table-CellWrapper-${col.id}`] = styles;
  if (col.cellWrapperSx) {
    Object.assign(styles, col.cellWrapperSx);
  }
  if (col.cellSx) {
    styles['& .DS-Table-Cell'] = col.cellSx;
  }
  if (col.headerCellWrapperSx) {
    styles['&.DS-Table-HeaderCellWrapper'] = col.headerCellWrapperSx;
  }
  if (col.headerCellSx) {
    styles['& .DS-Table-HeaderCell'] = col.headerCellSx;
  }
  if (col.bodyCellWrapperSx) {
    styles['&.DS-Table-BodyCellWrapper'] = col.bodyCellWrapperSx;
  }
  if (col.bodyCellSx) {
    styles['& .DS-Table-BodyCell'] = col.bodyCellSx;
  }
  if (col.footerCellWrapperSx) {
    styles['&.DS-Table-FooterCellWrapper'] = col.footerCellWrapperSx;
  }
  if (col.footerCellSx) {
    styles['& .DS-Table-FooterCell'] = col.footerCellSx;
  }

  info.columnModels.push(result as ColumnModel);

  if (col.floating) {
    info.floatingColumn = result as ColumnModel;
  }
};
