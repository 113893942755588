import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import { useTabsState } from './useTabsState';

export type TabContentProps<Value = any> = PropsWithChildren<{
  value: Value;
  renderInactive?: boolean;
}>;

export const TabContent = ({
  value,
  children,
  renderInactive,
}: TabContentProps) => {
  const context = useTabsState();
  if (!context) {
    console.error('TabContent works only with TabsProvider');
    return null;
  }
  const { currentTab } = context;
  if (currentTab !== value) {
    if (renderInactive) {
      return <Box display="none">{children}</Box>;
    }
    return null;
  }
  return <>{children}</>;
};
