import { AxiosInstance, AxiosPromise } from 'axios';

import {
  NodeTemplatesAPIApiFactory,
  PoliciesAPIApiFactory,
  AutoscalerAPIApiFactory,
  EvictorAPIApiFactory,
  PodMutationsAPIApiFactory,
} from '@cast/openapi';
import { ClusterWorkloads, RebalancingPlan } from '@cast/types';

import { AXIOS_CONFIGURATION } from './constants';

export const AutoscalerApiAdapter = (client: AxiosInstance) => {
  const policiesApi = PoliciesAPIApiFactory(AXIOS_CONFIGURATION, '', client);
  const selectionTemplatesApi = NodeTemplatesAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const autoscalerApi = AutoscalerAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  const evictorApi = EvictorAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  const podMutationsApi = PodMutationsAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  // prettier-ignore
  return {
    getPolicies: policiesApi.policiesAPIGetClusterPolicies,
    upsertPolicies: policiesApi.policiesAPIUpsertClusterPolicies,
    getNodeConstraints: policiesApi.policiesAPIGetClusterNodeConstraints,
    getSelectionTemplates: selectionTemplatesApi.nodeTemplatesAPIListNodeTemplates,
    createSelectionTemplate: selectionTemplatesApi.nodeTemplatesAPICreateNodeTemplate,
    updateSelectionTemplate: selectionTemplatesApi.nodeTemplatesAPIUpdateNodeTemplate,
    deleteSelectionTemplate: selectionTemplatesApi.nodeTemplatesAPIDeleteNodeTemplate,
    getInstanceTypes: selectionTemplatesApi.nodeTemplatesAPIFilterInstanceTypes,
    getAgentInstallScript: autoscalerApi.autoscalerAPIGetAgentScript,
    getAutoscalerFeatures: autoscalerApi.autoscalerAPIGetClusterSettings,
    listRebalancingPlans: autoscalerApi.autoscalerAPIListRebalancingPlans,
    executeRebalancingPlan: (...args: Parameters<typeof autoscalerApi.autoscalerAPIExecuteRebalancingPlan>): AxiosPromise<RebalancingPlan> => autoscalerApi.autoscalerAPIExecuteRebalancingPlan(...args) as AxiosPromise<RebalancingPlan>,
    getProblematicWorkloads: autoscalerApi.autoscalerAPIGetProblematicWorkloads,
    getRebalancedWorkloads: autoscalerApi.autoscalerAPIGetRebalancedWorkloads,
    getRebalancingPlan: (...args: Parameters<typeof autoscalerApi.autoscalerAPIGetRebalancingPlan>): AxiosPromise<RebalancingPlan> => autoscalerApi.autoscalerAPIGetRebalancingPlan(...args) as AxiosPromise<RebalancingPlan>,
    getWorkloads: (...args: Parameters<typeof autoscalerApi.autoscalerAPIGetClusterWorkloads>): AxiosPromise<ClusterWorkloads> => autoscalerApi.autoscalerAPIGetClusterWorkloads(...args) as AxiosPromise<ClusterWorkloads>,
    generateRebalancingPlan: autoscalerApi.autoscalerAPIGenerateRebalancingPlan,
    getAdvancedEvictorConfig: evictorApi.evictorAPIGetAdvancedConfig,
    updateAdvancedEvictorConfig: evictorApi.evictorAPIUpsertAdvancedConfig,
    getKarpenterMigrationIntents: autoscalerApi.autoscalerAPIGetKarpenterDefinitionsMigrationIntent,
    runKarpenterMigration: autoscalerApi.autoscalerAPIMigrateKarpenterDefinitions,
    getProblematicNodes: autoscalerApi.autoscalerAPIGetProblematicNodes,
    getPodMutations: podMutationsApi.podMutationsAPIListPodMutations,
    getPodMutation: podMutationsApi.podMutationsAPIGetPodMutation,
    createPodMutation: podMutationsApi.podMutationsAPICreatePodMutation,
    updatePodMutation: podMutationsApi.podMutationsAPIUpdatePodMutation,
    deletePodMutation: podMutationsApi.podMutationsAPIDeletePodMutation,
    getMutatorScript: podMutationsApi.podMutationsAPIGetInstallCmdForMutator,
    getMutatorStatus: podMutationsApi.podMutationsAPIGetMutatorStatus,

  };
};
