import { Box } from '@mui/material';

import { useDatePickerContext } from './useDatePickerContext';
import Button from '../../buttons/Button';

export const DatePickerFooterButtons = () => {
  const { handleApply, handleClose } = useDatePickerContext();

  return (
    <Box display="flex" gap={8}>
      <Button
        size="small"
        variant="ghost"
        onClick={handleClose}
        testId="date-picker-cancel-btn"
      >
        Cancel
      </Button>
      <Button
        size="small"
        variant="primary"
        onClick={handleApply}
        testId="date-picker-apply-btn"
      >
        Apply
      </Button>
    </Box>
  );
};
