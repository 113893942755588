import { forwardRef } from 'react';

import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';
import { SvgIcon } from '@mui/material';

export type DragIconProps = Partial<DraggableProvidedDragHandleProps> & {
  className?: string;
};

export const DragIcon = forwardRef<HTMLDivElement, DragIconProps>(
  ({ className, ...props }, ref) => {
    return (
      <div className={`DS-Table-DragIcon ${className}`} ref={ref} {...props}>
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none">
          <rect width="16" height="16" fill="transparent" />
          <rect x="2.5" y="2.5" width="11" height="11" fill="transparent" />
          <path d="M3.5 7L12.5 7" stroke="currentColor" strokeLinecap="round" />
          <path d="M3.5 9L12.5 9" stroke="currentColor" strokeLinecap="round" />
          <path
            d="M7.61114 3.3086C7.81127 3.06101 8.18873 3.06101 8.38886 3.3086L8.85735 3.88821C9.12163 4.21518 8.88891 4.70251 8.46849 4.70251H7.53151C7.11109 4.70251 6.87837 4.21518 7.14265 3.88821L7.61114 3.3086Z"
            fill="currentColor"
          />
          <path
            d="M7.61114 12.6914C7.81127 12.939 8.18873 12.939 8.38886 12.6914L8.85735 12.1118C9.12163 11.7848 8.88891 11.2975 8.46849 11.2975H7.53151C7.11109 11.2975 6.87837 11.7848 7.14265 12.1118L7.61114 12.6914Z"
            fill="currentColor"
          />
        </SvgIcon>
      </div>
    );
  }
);

DragIcon.displayName = 'DragIcon';
