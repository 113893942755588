import { ColumnModel, SortingState, TableApi, TableComponents } from '../types';

type Props = {
  api: TableApi;
  columnModel: ColumnModel;
  SortIcon: Required<TableComponents>['SortIcon'];
};

const getNextSortingState = (
  columnModel: ColumnModel,
  currentSortingState?: SortingState
): SortingState | undefined => {
  if (!currentSortingState || currentSortingState.columnId !== columnModel.id) {
    return {
      columnId: columnModel.id,
      sortBy: { accessor: columnModel.accessor!, direction: 'asc' },
    };
  }
  if (currentSortingState.sortBy.direction === 'asc') {
    return {
      columnId: columnModel.id,
      sortBy: { accessor: columnModel.accessor!, direction: 'desc' },
    };
  }
};

export const Sorter = ({ api, columnModel, SortIcon }: Props) => {
  const { id } = columnModel;
  if (!columnModel.sortable) {
    return null;
  }

  const direction =
    api.state.sortingState?.columnId === id
      ? api.state.sortingState?.sortBy.direction
      : undefined;

  return (
    <SortIcon
      sortDirection={direction}
      onClick={() =>
        api.sortColumn(getNextSortingState(columnModel, api.state.sortingState))
      }
    />
  );
};
