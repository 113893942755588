import { ComponentType, PropsWithChildren, ReactElement } from 'react';

import {
  SnackbarProvider as Provider,
  SnackbarProviderProps,
  VariantType,
} from 'notistack';

import { Icons } from '@cast/design-system';

import { NotificationSnackbar } from './_components';
import { SnackbarUtilsConfigurator } from '../SnackbarUtilsConfigurator';

export const notificationVariants = [
  'advisory',
  'info',
  'success',
  'warning',
  'error',
  'exceptional',
  'disabled',
];

const icons: Record<VariantType, ReactElement> = {
  advisory: <Icons.Download />,
  info: <Icons.Info />,
  success: <Icons.CheckCircle />,
  warning: <Icons.Warning />,
  error: <Icons.WarningCircle />,
  exceptional: <Icons.Jeep />,
  disabled: <Icons.XCircle />,
};

const components = notificationVariants.reduce((acc, curr) => {
  acc[curr as VariantType] = NotificationSnackbar as unknown as ComponentType;
  return acc;
}, {} as Record<VariantType, ComponentType>);

export const SnackbarsProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<SnackbarProviderProps>>) => {
  return (
    <Provider
      iconVariant={icons}
      Components={components}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...props}
    >
      <SnackbarUtilsConfigurator />
      <>{children}</>
    </Provider>
  );
};
