import { forwardRef, useCallback } from 'react';

import { Divider, Stack, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';

import { DATE_TIME } from '@cast/constants';
import {
  Button,
  Chip,
  DrawerProps,
  Icons,
  useDrawer,
} from '@cast/design-system';
import { Notification } from '@cast/types';

import { CopyToClipboard } from 'components/clickable-actions';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  Section,
} from 'components/drawer';
import { ExternalLink, Linkify } from 'components/router';
import { useAbility } from 'core/ability';
import { drawerEvents, notificationsEvents } from 'core/analytics';
import { useAckNotificationsMutation } from 'hooks/mutations/notifications';

import { NotificationDetails } from './_components';
import { SeverityChip } from '../../_components';

const StyledSeverityChip = styled(SeverityChip)(({ theme }) => ({
  ...theme.typography.P12M,
}));

const ObsoleteChip = () => {
  return (
    <Chip
      sx={{
        color: 'grey.600',
        backgroundColor: 'grey.200',
        typography: 'P10M',
      }}
      size="small"
      startIcon={<Icons.Clock fontSize={12} />}
    >
      Obsolete
    </Chip>
  );
};

type Props = DrawerProps & {
  notification?: Notification;
};

type NotificationProps = {
  notification: Notification;
};

const ClusterIdDetails = ({ notification }: NotificationProps) => {
  return (
    <Section
      title="CLUSTER / ID:"
      detail={
        <Stack direction="row" gap="4px">
          <Typography variant="P14R">
            {notification.clusterMetadata?.name}
          </Typography>
          {notification.clusterMetadata?.id && (
            <>
              <Typography color="grey.500">/</Typography>
              <CopyToClipboard
                text={notification.clusterMetadata.id}
                iconSize="20px"
              />
            </>
          )}
        </Stack>
      }
    />
  );
};

const AckedHeader = ({ notification }: NotificationProps) => {
  return (
    <>
      <Typography variant="h5">{notification.name}</Typography>
      <Typography variant="P12R" color="grey.400">
        Resolved by: {notification.ackedBy}
      </Typography>
      <StyledSeverityChip
        severity={notification.severity!}
        size="large"
        sx={{ mt: '12px' }}
      />
    </>
  );
};

const NonAckedHeader = ({ notification }: NotificationProps) => {
  return (
    <>
      <Typography variant="h5">{notification.name}</Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt="12px"
        alignItems="flex-end"
      >
        <StyledSeverityChip severity={notification.severity!} size="large" />
        <Stack direction="row" gap={8} alignItems="center">
          <Typography variant="P12R" color="grey.400">
            {dayjs(notification.timestamp).format(DATE_TIME)}
          </Typography>
          {notification.isExpired && <ObsoleteChip />}
        </Stack>
      </Stack>
    </>
  );
};

const NotificationDrawer = forwardRef<HTMLDivElement, Props>(
  ({ notification, ...drawerProps }, ref) => {
    const ability = useAbility();
    const onClose = () => {
      drawerProps?.onClose?.({}, 'backdropClick');
    };
    const { mutate } = useAckNotificationsMutation(onClose);
    const isAcked = notification && !!notification.ackedBy;

    const handleResolveEvent = useCallback(() => {
      if (notification?.id) {
        mutate([notification.id], {
          onSuccess: () => {
            notificationsEvents.resolvedNotification(notification.name);
          },
        });
      }
    }, [notification, mutate]);

    if (!notification) {
      return null;
    }

    return (
      <Drawer
        {...drawerProps}
        ref={ref}
        testId="notifications-drawer"
        size="medium"
      >
        <DrawerHeader noDivider>
          <Stack mt={21}>
            {isAcked ? (
              <AckedHeader notification={notification} />
            ) : (
              <NonAckedHeader notification={notification} />
            )}
          </Stack>
        </DrawerHeader>
        <DrawerContent px={32}>
          <Divider sx={{ mt: '24px' }} />
          <Stack py={24}>
            {isAcked && (
              <Section
                title="TIMESTAMP:"
                detail={
                  <Stack direction="row" gap={8} alignItems="center">
                    <Typography variant="P14R">
                      {dayjs(notification.timestamp).format(DATE_TIME)}
                    </Typography>
                    {notification.isExpired && <ObsoleteChip />}
                  </Stack>
                }
              />
            )}
            <ClusterIdDetails notification={notification} />
            <Section
              title="OPERATION:"
              detail={notification.operationMetadata?.type}
            />
          </Stack>
          <Divider />
          <Typography
            variant="P14R"
            mt={24}
            sx={{
              '& a': {
                display: 'inline',
                whiteSpace: 'normal',
                '& svg': { fontSize: 12, transform: 'translateY(2px)' },
              },
            }}
          >
            <Linkify
              componentDecorator={
                notification.name === 'Cost anomaly detected'
                  ? (href) => (
                      <ExternalLink link={href}>cluster report</ExternalLink>
                    )
                  : undefined
              }
            >
              {notification.message}
            </Linkify>
          </Typography>
          {(notification.details?.length || 0) > 2 && (
            <NotificationDetails details={notification.details} />
          )}
          <Stack direction="row" justifyContent="flex-end" gap={16} py={24}>
            <Button variant="tertiary" size="medium" onClick={onClose}>
              Close
            </Button>
            <Button
              size="medium"
              disabled={ability.cannot('update', 'OrganizationNotifications')}
              onClick={handleResolveEvent}
              testId="resolve-button"
            >
              Resolve
            </Button>
          </Stack>
        </DrawerContent>
      </Drawer>
    );
  }
);

NotificationDrawer.displayName = 'NotificationDrawer';

export const useNotificationDrawer = () => {
  const { open } = useDrawer();
  return {
    openNotificationDrawer: ({
      notification,
      openedFrom,
    }: {
      notification: Notification;
      openedFrom?: string;
    }) => {
      open(NotificationDrawer, { notification });
      const details = cloneDeep(notification);
      delete details.message;
      drawerEvents.drawerOpened({
        title: 'Notification details',
        openedFrom,
        details,
      });
    },
  };
};
