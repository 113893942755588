/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CastaiInventoryV1beta1AzureReservationImport } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GCPCommitmentImport } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetCommitmentAssignmentsResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetCommitmentResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetCommitmentsAssignmentsResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetCommitmentsResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1GetGCPCommitmentsImportScriptResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1ReplaceCommitmentAssignmentsResponse } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1UpdateCommitmentInput } from '../models';
// @ts-ignore
import { CastaiInventoryV1beta1UpdateCommitmentResponse } from '../models';
/**
 * CommitmentsAPIApi - axios parameter creator
 * @export
 */
export const CommitmentsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign commitment to a cluster
         * @param {string} clusterId Cluster ID
         * @param {string} commitmentId Commitment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPICreateCommitmentAssignment: async (clusterId: string, commitmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('commitmentsAPICreateCommitmentAssignment', 'clusterId', clusterId)
            // verify required parameter 'commitmentId' is not null or undefined
            assertParamExists('commitmentsAPICreateCommitmentAssignment', 'commitmentId', commitmentId)
            const localVarPath = `/v1/savings/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (commitmentId !== undefined) {
                localVarQueryParameter['commitmentId'] = commitmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete commitment
         * @param {string} commitmentId Commitment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIDeleteCommitment: async (commitmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitmentId' is not null or undefined
            assertParamExists('commitmentsAPIDeleteCommitment', 'commitmentId', commitmentId)
            const localVarPath = `/v1/savings/commitments/{commitmentId}`
                .replace(`{${"commitmentId"}}`, encodeURIComponent(String(commitmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove commitment assignment
         * @param {string} assignmentId Assignment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIDeleteCommitmentAssignment: async (assignmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('commitmentsAPIDeleteCommitmentAssignment', 'assignmentId', assignmentId)
            const localVarPath = `/v1/savings/assignments/{assignmentId}`
                .replace(`{${"assignmentId"}}`, encodeURIComponent(String(assignmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get commitment
         * @param {string} commitmentId Commitment ID
         * @param {string} [clusterId] get commitments that are assigned to a cluster
         * @param {boolean} [includeUsage] indicated if commitment usage should be included in a response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetCommitment: async (commitmentId: string, clusterId?: string, includeUsage?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitmentId' is not null or undefined
            assertParamExists('commitmentsAPIGetCommitment', 'commitmentId', commitmentId)
            const localVarPath = `/v1/savings/commitments/{commitmentId}`
                .replace(`{${"commitmentId"}}`, encodeURIComponent(String(commitmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (includeUsage !== undefined) {
                localVarQueryParameter['includeUsage'] = includeUsage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get commitment assignments
         * @param {string} commitmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetCommitmentAssignments: async (commitmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitmentId' is not null or undefined
            assertParamExists('commitmentsAPIGetCommitmentAssignments', 'commitmentId', commitmentId)
            const localVarPath = `/v1/savings/commitments/{commitmentId}/assignments`
                .replace(`{${"commitmentId"}}`, encodeURIComponent(String(commitmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get commitments
         * @param {boolean} [includeUsage] indicated if commitment usage should be included in a response
         * @param {string} [clusterId] get commitments that are assigned to a cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetCommitments: async (includeUsage?: boolean, clusterId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/savings/commitments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeUsage !== undefined) {
                localVarQueryParameter['includeUsage'] = includeUsage;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all commitments assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetCommitmentsAssignments: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/savings/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get GCP commitments (CUDs) import script
         * @param {Array<string>} [projects] GCP Project ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetGCPCommitmentsImportScript: async (projects?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/savings/commitments/import/gcp/cud/script`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projects) {
                localVarQueryParameter['projects'] = projects;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a template containing GCP commitments (CUDs) import script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetGCPCommitmentsScriptTemplate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/scripts/commitments/gcp/cud/import.sh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import Azure commitments (reservations)
         * @param {Array<CastaiInventoryV1beta1AzureReservationImport>} azureReservationImports Import Azure reservation commitments
         * @param {CommitmentsAPIImportAzureReservationsBehaviourEnum} [behaviour] Behaviour   - OVERWRITE: Overwrite all existing commitments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIImportAzureReservations: async (azureReservationImports: Array<CastaiInventoryV1beta1AzureReservationImport>, behaviour?: CommitmentsAPIImportAzureReservationsBehaviourEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'azureReservationImports' is not null or undefined
            assertParamExists('commitmentsAPIImportAzureReservations', 'azureReservationImports', azureReservationImports)
            const localVarPath = `/v1/savings/commitments/import/azure/reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (behaviour !== undefined) {
                localVarQueryParameter['behaviour'] = behaviour;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(azureReservationImports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import GCP commitments (CUDs)
         * @param {Array<CastaiInventoryV1beta1GCPCommitmentImport>} gcpCommitmentImports Import GCPCommitments
         * @param {CommitmentsAPIImportGCPCommitmentsBehaviourEnum} [behaviour] Behaviour   - OVERWRITE: Overwrite all existing commitments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIImportGCPCommitments: async (gcpCommitmentImports: Array<CastaiInventoryV1beta1GCPCommitmentImport>, behaviour?: CommitmentsAPIImportGCPCommitmentsBehaviourEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gcpCommitmentImports' is not null or undefined
            assertParamExists('commitmentsAPIImportGCPCommitments', 'gcpCommitmentImports', gcpCommitmentImports)
            const localVarPath = `/v1/savings/commitments/import/gcp/cud`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (behaviour !== undefined) {
                localVarQueryParameter['behaviour'] = behaviour;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gcpCommitmentImports, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace commitment assignments
         * @param {string} commitmentId 
         * @param {Array<string>} clusterIds Cluster ids. The order of the ids corresponds to priorities if prioritization is enabled for the commitment. The first item in the array is the highest priority one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIReplaceCommitmentAssignments: async (commitmentId: string, clusterIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitmentId' is not null or undefined
            assertParamExists('commitmentsAPIReplaceCommitmentAssignments', 'commitmentId', commitmentId)
            // verify required parameter 'clusterIds' is not null or undefined
            assertParamExists('commitmentsAPIReplaceCommitmentAssignments', 'clusterIds', clusterIds)
            const localVarPath = `/v1/savings/commitments/{commitmentId}/assignments`
                .replace(`{${"commitmentId"}}`, encodeURIComponent(String(commitmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update commitment
         * @param {string} commitmentId 
         * @param {CastaiInventoryV1beta1UpdateCommitmentInput} commitment Commitments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIUpdateCommitment: async (commitmentId: string, commitment: CastaiInventoryV1beta1UpdateCommitmentInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitmentId' is not null or undefined
            assertParamExists('commitmentsAPIUpdateCommitment', 'commitmentId', commitmentId)
            // verify required parameter 'commitment' is not null or undefined
            assertParamExists('commitmentsAPIUpdateCommitment', 'commitment', commitment)
            const localVarPath = `/v1/savings/commitments/{commitmentId}`
                .replace(`{${"commitmentId"}}`, encodeURIComponent(String(commitmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commitment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommitmentsAPIApi - functional programming interface
 * @export
 */
export const CommitmentsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommitmentsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign commitment to a cluster
         * @param {string} clusterId Cluster ID
         * @param {string} commitmentId Commitment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPICreateCommitmentAssignment(clusterId: string, commitmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPICreateCommitmentAssignment(clusterId, commitmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPICreateCommitmentAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete commitment
         * @param {string} commitmentId Commitment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIDeleteCommitment(commitmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIDeleteCommitment(commitmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIDeleteCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove commitment assignment
         * @param {string} assignmentId Assignment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIDeleteCommitmentAssignment(assignmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIDeleteCommitmentAssignment(assignmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIDeleteCommitmentAssignment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get commitment
         * @param {string} commitmentId Commitment ID
         * @param {string} [clusterId] get commitments that are assigned to a cluster
         * @param {boolean} [includeUsage] indicated if commitment usage should be included in a response
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIGetCommitment(commitmentId: string, clusterId?: string, includeUsage?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetCommitmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIGetCommitment(commitmentId, clusterId, includeUsage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIGetCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get commitment assignments
         * @param {string} commitmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIGetCommitmentAssignments(commitmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetCommitmentAssignmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIGetCommitmentAssignments(commitmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIGetCommitmentAssignments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get commitments
         * @param {boolean} [includeUsage] indicated if commitment usage should be included in a response
         * @param {string} [clusterId] get commitments that are assigned to a cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIGetCommitments(includeUsage?: boolean, clusterId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetCommitmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIGetCommitments(includeUsage, clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIGetCommitments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all commitments assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIGetCommitmentsAssignments(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetCommitmentsAssignmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIGetCommitmentsAssignments(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIGetCommitmentsAssignments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get GCP commitments (CUDs) import script
         * @param {Array<string>} [projects] GCP Project ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIGetGCPCommitmentsImportScript(projects?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1GetGCPCommitmentsImportScriptResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIGetGCPCommitmentsImportScript(projects, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIGetGCPCommitmentsImportScript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a template containing GCP commitments (CUDs) import script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIGetGCPCommitmentsScriptTemplate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIGetGCPCommitmentsScriptTemplate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIGetGCPCommitmentsScriptTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import Azure commitments (reservations)
         * @param {Array<CastaiInventoryV1beta1AzureReservationImport>} azureReservationImports Import Azure reservation commitments
         * @param {CommitmentsAPIImportAzureReservationsBehaviourEnum} [behaviour] Behaviour   - OVERWRITE: Overwrite all existing commitments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIImportAzureReservations(azureReservationImports: Array<CastaiInventoryV1beta1AzureReservationImport>, behaviour?: CommitmentsAPIImportAzureReservationsBehaviourEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIImportAzureReservations(azureReservationImports, behaviour, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIImportAzureReservations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import GCP commitments (CUDs)
         * @param {Array<CastaiInventoryV1beta1GCPCommitmentImport>} gcpCommitmentImports Import GCPCommitments
         * @param {CommitmentsAPIImportGCPCommitmentsBehaviourEnum} [behaviour] Behaviour   - OVERWRITE: Overwrite all existing commitments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIImportGCPCommitments(gcpCommitmentImports: Array<CastaiInventoryV1beta1GCPCommitmentImport>, behaviour?: CommitmentsAPIImportGCPCommitmentsBehaviourEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIImportGCPCommitments(gcpCommitmentImports, behaviour, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIImportGCPCommitments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Replace commitment assignments
         * @param {string} commitmentId 
         * @param {Array<string>} clusterIds Cluster ids. The order of the ids corresponds to priorities if prioritization is enabled for the commitment. The first item in the array is the highest priority one.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIReplaceCommitmentAssignments(commitmentId: string, clusterIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ReplaceCommitmentAssignmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIReplaceCommitmentAssignments(commitmentId, clusterIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIReplaceCommitmentAssignments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update commitment
         * @param {string} commitmentId 
         * @param {CastaiInventoryV1beta1UpdateCommitmentInput} commitment Commitments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitmentsAPIUpdateCommitment(commitmentId: string, commitment: CastaiInventoryV1beta1UpdateCommitmentInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1UpdateCommitmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitmentsAPIUpdateCommitment(commitmentId, commitment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentsAPIApi.commitmentsAPIUpdateCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommitmentsAPIApi - factory interface
 * @export
 */
export const CommitmentsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommitmentsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign commitment to a cluster
         * @param {CommitmentsAPIApiCommitmentsAPICreateCommitmentAssignmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPICreateCommitmentAssignment(requestParameters: CommitmentsAPIApiCommitmentsAPICreateCommitmentAssignmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.commitmentsAPICreateCommitmentAssignment(requestParameters.clusterId, requestParameters.commitmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete commitment
         * @param {CommitmentsAPIApiCommitmentsAPIDeleteCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIDeleteCommitment(requestParameters: CommitmentsAPIApiCommitmentsAPIDeleteCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.commitmentsAPIDeleteCommitment(requestParameters.commitmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove commitment assignment
         * @param {CommitmentsAPIApiCommitmentsAPIDeleteCommitmentAssignmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIDeleteCommitmentAssignment(requestParameters: CommitmentsAPIApiCommitmentsAPIDeleteCommitmentAssignmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.commitmentsAPIDeleteCommitmentAssignment(requestParameters.assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get commitment
         * @param {CommitmentsAPIApiCommitmentsAPIGetCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetCommitment(requestParameters: CommitmentsAPIApiCommitmentsAPIGetCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetCommitmentResponse> {
            return localVarFp.commitmentsAPIGetCommitment(requestParameters.commitmentId, requestParameters.clusterId, requestParameters.includeUsage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get commitment assignments
         * @param {CommitmentsAPIApiCommitmentsAPIGetCommitmentAssignmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetCommitmentAssignments(requestParameters: CommitmentsAPIApiCommitmentsAPIGetCommitmentAssignmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetCommitmentAssignmentsResponse> {
            return localVarFp.commitmentsAPIGetCommitmentAssignments(requestParameters.commitmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get commitments
         * @param {CommitmentsAPIApiCommitmentsAPIGetCommitmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetCommitments(requestParameters: CommitmentsAPIApiCommitmentsAPIGetCommitmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetCommitmentsResponse> {
            return localVarFp.commitmentsAPIGetCommitments(requestParameters.includeUsage, requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all commitments assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetCommitmentsAssignments(options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetCommitmentsAssignmentsResponse> {
            return localVarFp.commitmentsAPIGetCommitmentsAssignments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get GCP commitments (CUDs) import script
         * @param {CommitmentsAPIApiCommitmentsAPIGetGCPCommitmentsImportScriptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetGCPCommitmentsImportScript(requestParameters: CommitmentsAPIApiCommitmentsAPIGetGCPCommitmentsImportScriptRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1GetGCPCommitmentsImportScriptResponse> {
            return localVarFp.commitmentsAPIGetGCPCommitmentsImportScript(requestParameters.projects, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a template containing GCP commitments (CUDs) import script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIGetGCPCommitmentsScriptTemplate(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.commitmentsAPIGetGCPCommitmentsScriptTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import Azure commitments (reservations)
         * @param {CommitmentsAPIApiCommitmentsAPIImportAzureReservationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIImportAzureReservations(requestParameters: CommitmentsAPIApiCommitmentsAPIImportAzureReservationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.commitmentsAPIImportAzureReservations(requestParameters.azureReservationImports, requestParameters.behaviour, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import GCP commitments (CUDs)
         * @param {CommitmentsAPIApiCommitmentsAPIImportGCPCommitmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIImportGCPCommitments(requestParameters: CommitmentsAPIApiCommitmentsAPIImportGCPCommitmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.commitmentsAPIImportGCPCommitments(requestParameters.gcpCommitmentImports, requestParameters.behaviour, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace commitment assignments
         * @param {CommitmentsAPIApiCommitmentsAPIReplaceCommitmentAssignmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIReplaceCommitmentAssignments(requestParameters: CommitmentsAPIApiCommitmentsAPIReplaceCommitmentAssignmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ReplaceCommitmentAssignmentsResponse> {
            return localVarFp.commitmentsAPIReplaceCommitmentAssignments(requestParameters.commitmentId, requestParameters.clusterIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update commitment
         * @param {CommitmentsAPIApiCommitmentsAPIUpdateCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitmentsAPIUpdateCommitment(requestParameters: CommitmentsAPIApiCommitmentsAPIUpdateCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1UpdateCommitmentResponse> {
            return localVarFp.commitmentsAPIUpdateCommitment(requestParameters.commitmentId, requestParameters.commitment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for commitmentsAPICreateCommitmentAssignment operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPICreateCommitmentAssignmentRequest
 */
export interface CommitmentsAPIApiCommitmentsAPICreateCommitmentAssignmentRequest {
    /**
     * Cluster ID
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPICreateCommitmentAssignment
     */
    readonly clusterId: string

    /**
     * Commitment ID
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPICreateCommitmentAssignment
     */
    readonly commitmentId: string
}

/**
 * Request parameters for commitmentsAPIDeleteCommitment operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIDeleteCommitmentRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIDeleteCommitmentRequest {
    /**
     * Commitment ID
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPIDeleteCommitment
     */
    readonly commitmentId: string
}

/**
 * Request parameters for commitmentsAPIDeleteCommitmentAssignment operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIDeleteCommitmentAssignmentRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIDeleteCommitmentAssignmentRequest {
    /**
     * Assignment ID
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPIDeleteCommitmentAssignment
     */
    readonly assignmentId: string
}

/**
 * Request parameters for commitmentsAPIGetCommitment operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIGetCommitmentRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIGetCommitmentRequest {
    /**
     * Commitment ID
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPIGetCommitment
     */
    readonly commitmentId: string

    /**
     * get commitments that are assigned to a cluster
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPIGetCommitment
     */
    readonly clusterId?: string

    /**
     * indicated if commitment usage should be included in a response
     * @type {boolean}
     * @memberof CommitmentsAPIApiCommitmentsAPIGetCommitment
     */
    readonly includeUsage?: boolean
}

/**
 * Request parameters for commitmentsAPIGetCommitmentAssignments operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIGetCommitmentAssignmentsRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIGetCommitmentAssignmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPIGetCommitmentAssignments
     */
    readonly commitmentId: string
}

/**
 * Request parameters for commitmentsAPIGetCommitments operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIGetCommitmentsRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIGetCommitmentsRequest {
    /**
     * indicated if commitment usage should be included in a response
     * @type {boolean}
     * @memberof CommitmentsAPIApiCommitmentsAPIGetCommitments
     */
    readonly includeUsage?: boolean

    /**
     * get commitments that are assigned to a cluster
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPIGetCommitments
     */
    readonly clusterId?: string
}

/**
 * Request parameters for commitmentsAPIGetGCPCommitmentsImportScript operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIGetGCPCommitmentsImportScriptRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIGetGCPCommitmentsImportScriptRequest {
    /**
     * GCP Project ids
     * @type {Array<string>}
     * @memberof CommitmentsAPIApiCommitmentsAPIGetGCPCommitmentsImportScript
     */
    readonly projects?: Array<string>
}

/**
 * Request parameters for commitmentsAPIImportAzureReservations operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIImportAzureReservationsRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIImportAzureReservationsRequest {
    /**
     * Import Azure reservation commitments
     * @type {Array<CastaiInventoryV1beta1AzureReservationImport>}
     * @memberof CommitmentsAPIApiCommitmentsAPIImportAzureReservations
     */
    readonly azureReservationImports: Array<CastaiInventoryV1beta1AzureReservationImport>

    /**
     * Behaviour   - OVERWRITE: Overwrite all existing commitments
     * @type {'OVERWRITE'}
     * @memberof CommitmentsAPIApiCommitmentsAPIImportAzureReservations
     */
    readonly behaviour?: CommitmentsAPIImportAzureReservationsBehaviourEnum
}

/**
 * Request parameters for commitmentsAPIImportGCPCommitments operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIImportGCPCommitmentsRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIImportGCPCommitmentsRequest {
    /**
     * Import GCPCommitments
     * @type {Array<CastaiInventoryV1beta1GCPCommitmentImport>}
     * @memberof CommitmentsAPIApiCommitmentsAPIImportGCPCommitments
     */
    readonly gcpCommitmentImports: Array<CastaiInventoryV1beta1GCPCommitmentImport>

    /**
     * Behaviour   - OVERWRITE: Overwrite all existing commitments
     * @type {'OVERWRITE'}
     * @memberof CommitmentsAPIApiCommitmentsAPIImportGCPCommitments
     */
    readonly behaviour?: CommitmentsAPIImportGCPCommitmentsBehaviourEnum
}

/**
 * Request parameters for commitmentsAPIReplaceCommitmentAssignments operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIReplaceCommitmentAssignmentsRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIReplaceCommitmentAssignmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPIReplaceCommitmentAssignments
     */
    readonly commitmentId: string

    /**
     * Cluster ids. The order of the ids corresponds to priorities if prioritization is enabled for the commitment. The first item in the array is the highest priority one.
     * @type {Array<string>}
     * @memberof CommitmentsAPIApiCommitmentsAPIReplaceCommitmentAssignments
     */
    readonly clusterIds: Array<string>
}

/**
 * Request parameters for commitmentsAPIUpdateCommitment operation in CommitmentsAPIApi.
 * @export
 * @interface CommitmentsAPIApiCommitmentsAPIUpdateCommitmentRequest
 */
export interface CommitmentsAPIApiCommitmentsAPIUpdateCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentsAPIApiCommitmentsAPIUpdateCommitment
     */
    readonly commitmentId: string

    /**
     * Commitments
     * @type {CastaiInventoryV1beta1UpdateCommitmentInput}
     * @memberof CommitmentsAPIApiCommitmentsAPIUpdateCommitment
     */
    readonly commitment: CastaiInventoryV1beta1UpdateCommitmentInput
}

/**
 * CommitmentsAPIApi - object-oriented interface
 * @export
 * @class CommitmentsAPIApi
 * @extends {BaseAPI}
 */
export class CommitmentsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Assign commitment to a cluster
     * @param {CommitmentsAPIApiCommitmentsAPICreateCommitmentAssignmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPICreateCommitmentAssignment(requestParameters: CommitmentsAPIApiCommitmentsAPICreateCommitmentAssignmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPICreateCommitmentAssignment(requestParameters.clusterId, requestParameters.commitmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete commitment
     * @param {CommitmentsAPIApiCommitmentsAPIDeleteCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIDeleteCommitment(requestParameters: CommitmentsAPIApiCommitmentsAPIDeleteCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIDeleteCommitment(requestParameters.commitmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove commitment assignment
     * @param {CommitmentsAPIApiCommitmentsAPIDeleteCommitmentAssignmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIDeleteCommitmentAssignment(requestParameters: CommitmentsAPIApiCommitmentsAPIDeleteCommitmentAssignmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIDeleteCommitmentAssignment(requestParameters.assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get commitment
     * @param {CommitmentsAPIApiCommitmentsAPIGetCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIGetCommitment(requestParameters: CommitmentsAPIApiCommitmentsAPIGetCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIGetCommitment(requestParameters.commitmentId, requestParameters.clusterId, requestParameters.includeUsage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get commitment assignments
     * @param {CommitmentsAPIApiCommitmentsAPIGetCommitmentAssignmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIGetCommitmentAssignments(requestParameters: CommitmentsAPIApiCommitmentsAPIGetCommitmentAssignmentsRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIGetCommitmentAssignments(requestParameters.commitmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get commitments
     * @param {CommitmentsAPIApiCommitmentsAPIGetCommitmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIGetCommitments(requestParameters: CommitmentsAPIApiCommitmentsAPIGetCommitmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIGetCommitments(requestParameters.includeUsage, requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all commitments assignments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIGetCommitmentsAssignments(options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIGetCommitmentsAssignments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get GCP commitments (CUDs) import script
     * @param {CommitmentsAPIApiCommitmentsAPIGetGCPCommitmentsImportScriptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIGetGCPCommitmentsImportScript(requestParameters: CommitmentsAPIApiCommitmentsAPIGetGCPCommitmentsImportScriptRequest = {}, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIGetGCPCommitmentsImportScript(requestParameters.projects, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a template containing GCP commitments (CUDs) import script
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIGetGCPCommitmentsScriptTemplate(options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIGetGCPCommitmentsScriptTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import Azure commitments (reservations)
     * @param {CommitmentsAPIApiCommitmentsAPIImportAzureReservationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIImportAzureReservations(requestParameters: CommitmentsAPIApiCommitmentsAPIImportAzureReservationsRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIImportAzureReservations(requestParameters.azureReservationImports, requestParameters.behaviour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import GCP commitments (CUDs)
     * @param {CommitmentsAPIApiCommitmentsAPIImportGCPCommitmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIImportGCPCommitments(requestParameters: CommitmentsAPIApiCommitmentsAPIImportGCPCommitmentsRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIImportGCPCommitments(requestParameters.gcpCommitmentImports, requestParameters.behaviour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace commitment assignments
     * @param {CommitmentsAPIApiCommitmentsAPIReplaceCommitmentAssignmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIReplaceCommitmentAssignments(requestParameters: CommitmentsAPIApiCommitmentsAPIReplaceCommitmentAssignmentsRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIReplaceCommitmentAssignments(requestParameters.commitmentId, requestParameters.clusterIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update commitment
     * @param {CommitmentsAPIApiCommitmentsAPIUpdateCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentsAPIApi
     */
    public commitmentsAPIUpdateCommitment(requestParameters: CommitmentsAPIApiCommitmentsAPIUpdateCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentsAPIApiFp(this.configuration).commitmentsAPIUpdateCommitment(requestParameters.commitmentId, requestParameters.commitment, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CommitmentsAPIImportAzureReservationsBehaviourEnum = {
    OVERWRITE: 'OVERWRITE'
} as const;
export type CommitmentsAPIImportAzureReservationsBehaviourEnum = typeof CommitmentsAPIImportAzureReservationsBehaviourEnum[keyof typeof CommitmentsAPIImportAzureReservationsBehaviourEnum];
/**
 * @export
 */
export const CommitmentsAPIImportGCPCommitmentsBehaviourEnum = {
    OVERWRITE: 'OVERWRITE'
} as const;
export type CommitmentsAPIImportGCPCommitmentsBehaviourEnum = typeof CommitmentsAPIImportGCPCommitmentsBehaviourEnum[keyof typeof CommitmentsAPIImportGCPCommitmentsBehaviourEnum];
