import {
  FC,
  forwardRef,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  UIEvent,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { SortFn, SortingState } from '@cast/design-system';
import { pseudoUnique } from '@cast/utils';

import { analyticsEvents } from 'core/analytics';

import {
  useCriteria,
  useFreeText,
  useSearchByCriteria,
  useSearchByFreeText,
} from './_hooks';
import { SearchContext } from './context';
import { UseServerData } from './server-side';
import { Criterion, FreeTextPredicate, SearchState } from './types';
import { clearUrlState } from './utils';

export type SearchProviderProps<T extends unknown = any> = {
  criteria?: Criterion[];
  serverSideSort?: boolean;
  urlKey?: string;
  disableFreeText?: boolean;
  freeTextServerSearch?: boolean;
  onDataFiltered?: (data: any[]) => void;
  // Server side
  onServerValuesChanged?: (values?: Record<string, any>) => void;
  onServerSortingChanged?: (sortingState?: SortingState) => void;
  useData?: UseServerData;
  errorMessage?: ReactNode;
  // Client side
  data?: T[];
  error?: unknown;
  isLoading?: boolean;
  noResults?: ReactNode;
  dataAggregator?: (data: T[]) => any[];
  freeTextPredicate?: FreeTextPredicate<T>;
  filterByFreeText?: (
    entries: T[],
    freeText: string,
    freeTextPredicate?: FreeTextPredicate<T>
  ) => T[];
  analyticsId?: string;
  children?: ReactNode | ((searchState: SearchState) => ReactNode);
};

const emptyCriteria: Criterion[] = [];

const InnerSearchProvider = <T extends unknown>(
  {
    criteria = emptyCriteria,
    serverSideSort,
    urlKey,
    disableFreeText,
    freeTextServerSearch,
    onServerValuesChanged,
    onServerSortingChanged,
    onDataFiltered,
    useData,
    data,
    isLoading,
    noResults,
    errorMessage,
    error,
    children,
    freeTextPredicate,
    dataAggregator,
    filterByFreeText,
    analyticsId,
  }: SearchProviderProps<T>,
  ref: Ref<SearchState>
) => {
  const {
    values,
    valuesChanged,
    setValue: _setValue,
    setValues,
    clearValue,
    resetInitial,
    clientValues,
    clientCriteria,
    serverCriteria,
    serverValues,
  } = useCriteria(criteria, urlKey);

  const setValue = !!analyticsId
    ? (key: string, value: any) => {
        _setValue(key, value);
        analyticsEvents.searchHasBeenUsed({
          analyticsId,
          key,
          value,
        });
      }
    : _setValue;

  // Client
  const [sortFn, setSortFn] = useState<SortFn<T> | undefined>();

  // Server
  const [sortingState, setSortingState] = useState<SortingState | undefined>(
    null as never
  );

  const { freeText, setFreeText } = useFreeText(urlKey);
  const clientSideFreeTextSearchEnabled =
    !disableFreeText && !freeTextServerSearch;

  const resultLoadingEnabled = !serverSideSort || sortingState !== null;
  const result = useData?.({
    values: serverValues,
    sortingState,
    freeText: !disableFreeText && freeTextServerSearch ? freeText : undefined,
    enabled: resultLoadingEnabled,
  });

  const hasDataAggregator = !!dataAggregator;
  const sortedData = useMemo(() => {
    const _data = result?.data || data;
    if (!hasDataAggregator) {
      return sortFn && _data ? sortFn(_data) : _data;
    }
    // If aggregator is provided, don't sort data until it is not filtered and aggregated
    return _data;
  }, [sortFn, data, result?.data, hasDataAggregator]);

  const dataFilteredByCriteria = useSearchByCriteria(
    clientCriteria,
    clientValues,
    sortedData
  );

  const filteredData = useSearchByFreeText(
    dataFilteredByCriteria,
    freeText,
    clientSideFreeTextSearchEnabled,
    freeTextPredicate,
    filterByFreeText
  );

  const aggregatedData = useMemo(() => {
    if (!dataAggregator || !filteredData) {
      return filteredData;
    }
    const aggregatedData = dataAggregator(filteredData);
    // If aggregator is provided, sort data after filtering and aggregating
    if (sortFn) {
      return sortFn(aggregatedData);
    }
    return aggregatedData;
  }, [filteredData, dataAggregator, sortFn]);

  const [initialResetTrigger, setInitialResetTrigger] = useState<
    number | UIEvent | undefined
  >();

  useEffect(() => {
    onDataFiltered?.(filteredData || []);
  }, [filteredData, onDataFiltered]);

  useEffect(() => {
    onServerValuesChanged?.(serverValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverValues]);

  useEffect(() => {
    onServerSortingChanged?.(sortingState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverValues]);

  const _resetInitial = (event?: UIEvent) => {
    resetInitial();
    setFreeText('');
    setInitialResetTrigger(event || pseudoUnique());
  };

  const hasLoadedResult = result && !result.isLoading && resultLoadingEnabled;
  const hasResultWithoutData = hasLoadedResult && !result?.data?.length;
  const hasResultWithError = hasLoadedResult && !!result?.error;
  const noResultWithAppliedFilters = Boolean(
    hasResultWithoutData && result?.hasFiltersApplied
  );

  let content: ReactNode =
    typeof children === 'function' ? (
      <SearchContext.Consumer>
        {children as (state?: SearchState) => ReactNode}
      </SearchContext.Consumer>
    ) : (
      <>{children}</>
    );
  if (hasResultWithError && errorMessage) {
    // Clear url state if server search is on, because backend error could be affected by search values
    if (urlKey && Object.keys(serverValues).length) {
      clearUrlState(urlKey);
    }
    content = errorMessage;
  } else if (hasResultWithoutData && !noResultWithAppliedFilters && noResults) {
    content = noResults;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchState: SearchState = {
    data: data || result?.data,
    sortedData,
    filteredData: aggregatedData,
    isLoading: isLoading ?? result?.isLoading,
    isFetching: result?.isFetching,
    values,
    setValue,
    setValues,
    clearValue,
    freeText,
    initialText: freeText,
    setFreeText,
    resetInitial: _resetInitial,
    valuesChanged: valuesChanged || !!freeText,
    initialResetTrigger,
    criteria,
    noResultWithAppliedFilters,
    // client
    clientCriteria,
    setSortFn: (fn) => setSortFn(() => fn),
    freeTextPredicate,
    filterByFreeText,
    dataAggregator,
    // server
    serverCriteria,
    serverSideSort,
    freeTextServerSearch,
    sortingState,
    setSortingState: (sortingState?: SortingState) => {
      if (serverSideSort) {
        setSortingState(sortingState);
      }
    },
    urlKey,
    disableFreeText,
    hasPreviousPage: result?.hasPreviousPage,
    fetchPreviousPage: result?.fetchPreviousPage,
    hasNextPage: result?.hasNextPage,
    fetchNextPage: result?.fetchNextPage,
    refetch: result?.refetch,
    error: error || result?.error,
    analyticsId,
    // TODO: remove when removing useData
    metadata: result?.metadata,
  };

  useImperativeHandle(ref, () => searchState, [searchState]);

  return (
    <SearchContext.Provider value={searchState}>
      {content}
    </SearchContext.Provider>
  );
};

export const SearchProvider = forwardRef(InnerSearchProvider) as (<
  T extends unknown
>(
  props: SearchProviderProps<T> & {
    ref?: Ref<SearchState> | MutableRefObject<SearchState>;
  }
) => ReactElement) & { displayName: string };
SearchProvider.displayName = 'SearchProvider';

export const withSearchProvider = <
  T extends { search?: SearchProviderProps } = any
>(
  Component: FC<any>
) => {
  const SearchProviderWrapper = ({ search = {}, ...props }: T) => (
    <SearchProvider {...search}>
      <Component {...props} />
    </SearchProvider>
  );

  return SearchProviderWrapper;
};
