import { HTMLAttributes, ReactElement, ReactNode } from 'react';

import { PopoverProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Meta } from '@storybook/react';
import type { PartialDeep } from 'type-fest';

import { ListItemProps } from '../../lists';
import { InputProps } from '../input';

type Callbacks = {
  onOpen?: () => void;
  onClose?: () => void;
  onInputChange?: (value: string, reason: 'change' | 'reset') => void;
};

type VirtualizationProps = {
  minWidth?: number;
  maxWidth?: number;
  maxHeight?: number;
};

type WithVirtualization = {
  virtualization?: boolean;
  virtualizationProps?: VirtualizationProps;
};

type WithOptionIcon<OptionItem> =
  | {
      optionIcon?: keyof OptionItem | ((option: OptionItem) => ReactNode);
    }
  | {
      checkable?: boolean; // If options are checkable, then optionIcon is not rendered at all
      /**
       * Whether to show `Select All` option in the dropdown list
       * @default true
       */
      showSelectAll?: boolean;
    };

type BaseProps<OptionItem = any, OptionValue = any> = {
  // Global props
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps;
  rootAttributes?: HTMLAttributes<HTMLDivElement>;
  testId?: string;

  // Trigger input props
  inputText?: string;
  inputProps?: Partial<InputProps>;
  endAdornment?: ReactNode | ((option: OptionItem | null) => ReactNode);
  startAdornment?: ReactNode | ((option: OptionItem | null) => ReactNode);
  startOutsideAdornment?: ReactNode;
  endOutsideAdornment?: ReactNode;
  disabled?: boolean;

  // List & options props
  open?: boolean;
  prefix?: ReactNode;
  className?: string;
  options?: OptionItem[] | readonly OptionItem[];
  optionLabel?: keyof OptionItem | ((option: OptionItem) => string);
  optionValue?: keyof OptionItem | ((option: OptionItem) => OptionValue); // default value: option (as OptionValue)
  optionKey?: keyof OptionItem | ((option: OptionItem) => string); // default value: optionLabel
  optionDisabled?: (option: OptionItem) => boolean;
  groupBy?: keyof OptionItem | ((option: OptionItem) => string);
  isOptionEqualToValue?: (option: OptionItem, value: OptionValue) => boolean;
  listHeight?: number;
  listSx?: SxProps;
  listAttributes?: HTMLAttributes<HTMLDivElement>;
  popoverProps?: PartialDeep<
    Pick<PopoverProps, 'transformOrigin' | 'anchorOrigin'>
  >;
  searchFilter?: (item: OptionItem, value: string) => boolean;
  applySortingWhileSearching?: boolean;
  showSelectedOptionsFirst?: boolean;
  renderOption?: (
    option: OptionItem,
    props: ListItemProps,
    selected?: boolean
  ) => ReactElement;
} & WithVirtualization &
  WithOptionIcon<OptionItem> &
  Callbacks;

type BasePropsWithMultipleSelection<OptionItem, OptionValue> = BaseProps<
  OptionItem,
  OptionValue
> & {
  multiple: true;
  value?: OptionValue[];
  hideMultipleChip?: boolean;
  onChange?: (options: OptionItem[], value: OptionValue[]) => void;
};

type BasePropsWithSingleSelection<OptionItem, OptionValue> = BaseProps<
  OptionItem,
  OptionValue
> & {
  multiple?: false | undefined;
  value?: OptionValue;
  renderValue?: keyof OptionItem | ((option: OptionItem) => ReactNode);
  onChange?: (option: OptionItem, value: OptionValue) => void;
};

export type DropdownProps<
  OptionItem = any,
  OptionValue = any,
  SelectionType extends 'single' | 'multiple' | unknown = unknown
> = SelectionType extends 'single'
  ? BasePropsWithSingleSelection<OptionItem, OptionValue>
  : SelectionType extends 'multiple'
  ? BasePropsWithMultipleSelection<OptionItem, OptionValue>
  :
      | BasePropsWithSingleSelection<OptionItem, OptionValue>
      | BasePropsWithMultipleSelection<OptionItem, OptionValue>;

export const isSingleSelection = <OptionItem, OptionValue>(
  props: DropdownProps<OptionItem, OptionValue>
): props is BasePropsWithSingleSelection<OptionItem, OptionValue> => {
  return !props.multiple;
};

export const isMultipleSelection = <OptionItem, OptionValue>(
  props: DropdownProps<OptionItem, OptionValue>
): props is BasePropsWithMultipleSelection<OptionItem, OptionValue> => {
  return !!props.multiple;
};

export const dropdownArgTypes: Meta<DropdownProps<any>>['argTypes'] = {
  inputText: {
    description: 'Trigger input text',
  },
  open: {
    description: "If it's set to true, the dropdown menu will be opened",
    defaultValue: false,
    table: {
      defaultValue: { summary: false },
    },
  },
  prefix: {
    description: 'Start adornment for DropdownInput',
  },
  className: {
    description: 'Custom class name that is passed down to DropdownRoot',
  },
  optionLabel: {
    description: 'Determines option label',
    table: {
      defaultValue: { summary: 'option (itself)' },
    },
  },
  optionValue: {
    description: 'Determines option value',
    table: {
      defaultValue: { summary: 'option (itself)' },
    },
  },
  optionKey: {
    description: 'Determines option key',
    table: {
      defaultValue: { summary: 'optionLabel' },
    },
  },
  optionDisabled: {
    description: 'Determines whether option should be disabled',
    table: {
      defaultValue: { summary: false },
    },
  },
  groupBy: {
    description:
      'If provided, the options will be grouped under the returned string. The groupBy value is also used as the text for group headings.',
  },
  isOptionEqualToValue: {
    description:
      'Custom getter used to determine equality between option values',
  },
  virtualization: {
    description:
      "If it's set to true, list items virtualization behaviour will be enabled",
    defaultValue: false,
    table: {
      defaultValue: { summary: false },
    },
  },
  multiple: {
    description:
      "(multiple) If it's set to true, the menu will support multiple selections",
    defaultValue: false,
    table: {
      defaultValue: { summary: false },
    },
  },
  hideMultipleChip: {
    description:
      '(multiple) Hides the chip that represents the selected quantity of options',
    defaultValue: false,
    table: {
      defaultValue: { summary: false },
    },
  },
  showSelectAll: {
    description: '(checkable)',
    defaultValue: true,
    table: {
      defaultValue: { summary: false },
    },
  },
};

type BaseAutocompleteProps<OptionItem> = {
  options: OptionItem[];
  open?: boolean;
  applySortingWhileSearching?: boolean;
  searchFilter?: (item: OptionItem, value: string) => boolean;
  resetInputOnClose?: boolean;
  searchInputProps?: InputProps;
  inputValue?: string;
  hideSearch?: boolean;
};

type AutocompleteOverridesWithSingleSelection<OptionItem, OptionValue> =
  BaseAutocompleteProps<OptionItem> & {
    multiple?: false | undefined;
    value?: OptionValue | null;
    onChange: (value: OptionItem | null) => void;
  };

type AutocompleteOverridesWithMultipleSelection<OptionItem, OptionValue> =
  BaseAutocompleteProps<OptionItem> & {
    multiple: true;
    value?: OptionValue[];
    onChange: (value: OptionItem[]) => void;
  };

export type AutocompleteOverrides<OptionItem, OptionValue> =
  | AutocompleteOverridesWithSingleSelection<OptionItem, OptionValue>
  | AutocompleteOverridesWithMultipleSelection<OptionItem, OptionValue>;

export type UseDropdownWithAutocomplete<OptionItem, OptionValue> = {
  dropdownProps: DropdownProps<OptionItem, OptionValue>;
  autocompleteOverrides?: Partial<
    AutocompleteOverrides<OptionItem, OptionValue>
  >;
};
