import { ability } from 'core/ability';

export const CAN_VIEW_SECURITY = () =>
  ability?.canOneOf?.('view', [
    'OrganizationSecurityAttackPaths',
    'OrganizationSecurityCompliance',
    'OrganizationSecurityIntegrations',
    'OrganizationSecurityRuntime',
    'OrganizationSecurityNodeRotation',
    'OrganizationSecurityVulnerabilities',
    'OrganizationSecuritySettings',
    'OrganizationSecurityWorkloads',
  ]);
