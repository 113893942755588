import { forwardRef, useEffect, useRef } from 'react';

import { useOverlayScrollbars } from 'overlayscrollbars-react';
import { ScrollerProps } from 'react-virtuoso';

import { setRef } from '@cast/utils';

export const VirtuosoScroller = forwardRef<HTMLDivElement, ScrollerProps>(
  ({ style, children, ...rest }, ref) => {
    // Ref of timeout handle to 'unhide' vertical scroller after horizontal scrolling has ended
    const [initialize] = useOverlayScrollbars({
      options: {
        showNativeOverlaidScrollbars: false,
        overflow: {
          x: 'scroll',
          y: 'scroll',
        },
        scrollbars: {
          autoHide: 'move',
          theme: 'os-theme-dark',
        },
      },
    });

    const rootRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      initialize({
        target: rootRef.current!,
        elements: {
          viewport: contentRef.current,
          content: contentRef.current,
        },
      });
    }, [initialize, ref, rootRef]);

    return (
      <div
        ref={(el) => {
          if (el) {
            rootRef.current = el;
          }
        }}
        data-overlayscrollbars-initialize=""
      >
        <div
          data-overlayscrollbars-contents=""
          ref={(el) => {
            if (el) {
              contentRef.current = el;
              setRef(ref, el);
            }
          }}
          style={style}
          {...rest}
        >
          {children}
        </div>
      </div>
    );
  }
);

VirtuosoScroller.displayName = 'VirtuosoScroller';
