import { demoScheduledRebalancingListSchedules } from '@cast/fixtures';
import { ScheduledrebalancingV1ListRebalancingSchedulesResponse } from '@cast/openapi';

import { ScheduledRebalancingApiAdapter } from '../api-client/scheduled-rebalancing-api-adapter';
import { isDemoClusterRequest, resolveDemoClusterRequest } from '../utils';

export const ScheduledRebalancingApiProxy = (
  origin: ReturnType<typeof ScheduledRebalancingApiAdapter>
): ReturnType<typeof ScheduledRebalancingApiAdapter> => {
  return {
    ...origin,
    listSchedules: async (...args: Parameters<typeof origin.listSchedules>) => {
      if (isDemoClusterRequest(args)) {
        const fixture = await demoScheduledRebalancingListSchedules();
        return resolveDemoClusterRequest(
          fixture as ScheduledrebalancingV1ListRebalancingSchedulesResponse
        );
      }

      return origin.listSchedules(...args);
    },
  };
};
