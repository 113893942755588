import { Stack, Typography } from '@mui/material';

import { Button, DialogActions, DialogContent } from '@cast/design-system';

import { HexIconSuccess } from 'components/hex-icons';

import { AssignCredentialsDialogHeader } from '../../../_components/AssignCredentialsDialogHeader';

type Props = {
  onClose: () => void;
};

export const SuccessSlide = ({ onClose }: Props) => {
  return (
    <Stack position="relative">
      <AssignCredentialsDialogHeader dismiss={onClose} />
      <DialogContent>
        <Stack direction="row" alignItems="center" gap={8}>
          <HexIconSuccess size={40} />
          <Typography variant="h6">
            Kubernetes & cloud security was successfully enabled!
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 16, borderTop: 'grey.100' }}>
        <Button onClick={onClose} variant="tertiary" testId="close-button">
          Close
        </Button>
      </DialogActions>
    </Stack>
  );
};
