import { useMemo } from 'react';

import { Popover, PopoverProps, Stack } from '@mui/material';

import { Icons, Tooltip, Skeleton } from '@cast/design-system';

import { FailedToLoad } from 'components/messages';
import { RffSelect } from 'core/forms/rff';
import { useGetWebhookCategoriesQuery } from 'hooks/queries/notifications';

export const CategorySelect = () => {
  const { categories, isLoading, error, refetch } =
    useGetWebhookCategoriesQuery();

  const options = useMemo(() => {
    if (error) {
      return [];
    }

    return [
      {
        title: 'All',
        value: undefined,
      },
      ...(categories?.map((category) => ({
        title: category.name,
        value: category.value,
      })) || []),
    ];
  }, [categories, error]);

  if (isLoading) {
    return <Skeleton width="100%" height={54} />;
  }

  return (
    <RffSelect
      testId="webhook-category-select"
      label={
        <Stack direction="row" alignItems="center" gap={4}>
          Category
          <Tooltip
            title="Customize notifications by choosing specific categories and receive alerts that matter the most."
            arrow
            placement="top"
          >
            <Icons.Info />
          </Tooltip>
        </Stack>
      }
      name="category"
      options={options}
      optionLabel="title"
      optionValue="value"
      renderValue="title"
      PopoverComponent={
        error
          ? (props: PopoverProps) => (
              <Popover {...props}>
                <FailedToLoad
                  title="Failed to load categories"
                  sx={{
                    width: (props.anchorEl as HTMLElement)?.offsetWidth,
                    minWidth: 200,
                    padding: 16,
                  }}
                  refresh={refetch}
                />
              </Popover>
            )
          : undefined
      }
    />
  );
};
