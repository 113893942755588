import { AxiosInstance } from 'axios';

import { ScheduledRebalancingAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const ScheduledRebalancingApiAdapter = (client: AxiosInstance) => {
  const generatedApi = ScheduledRebalancingAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  return {
    createSchedule:
      generatedApi.scheduledRebalancingAPICreateRebalancingSchedule,
    updateSchedule:
      generatedApi.scheduledRebalancingAPIUpdateRebalancingSchedule,
    deleteSchedule:
      generatedApi.scheduledRebalancingAPIDeleteRebalancingSchedule,
    listSchedules: generatedApi.scheduledRebalancingAPIListRebalancingSchedules,
    listJobs: generatedApi.scheduledRebalancingAPIListRebalancingJobs,
    createJob: generatedApi.scheduledRebalancingAPICreateRebalancingJob,
    updateJob: generatedApi.scheduledRebalancingAPIUpdateRebalancingJob,
    deleteJob: generatedApi.scheduledRebalancingAPIDeleteRebalancingJob,
    listTimeZones:
      generatedApi.scheduledRebalancingAPIListAvailableRebalancingTZ,
  };
};
