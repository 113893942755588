import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useMatch } from 'react-router-dom';

import { Icons, Scroller } from '@cast/design-system';

import { useOrganizations } from 'hooks/useOrganizations';

import { SidebarItem } from './SidebarItem';
import { useMainLayout } from '../../hooks';

export const OrganizationManagementSidebar = () => {
  const theme = useTheme();
  const { currentOrganization: organization } = useOrganizations();
  const { sidebarState } = useMainLayout();
  const match = useMatch('/organization/management/*');

  if (!sidebarState) {
    return null;
  }

  const isMinimized = sidebarState === 'minimized';

  return (
    <>
      <Box mx={8}>
        <SidebarItem
          title="Back to Cluster list"
          icon={<Icons.ArrowLeft height={18} />}
          url="/dashboard"
        />

        <Divider
          color={theme.palette.grey[50]}
          sx={{
            margin: '8px 0px 10px',
          }}
        />
      </Box>

      <Typography
        variant="L10M"
        color="textSecondary"
        pl={isMinimized ? 0 : 20}
        pr={isMinimized ? 0 : 8}
        mb={10}
        textAlign={isMinimized ? 'center' : 'left'}
      >
        {isMinimized ? 'mng.' : 'manage organization'}
      </Typography>

      <Scroller
        sx={{
          px: isMinimized ? '0px' : '8px',
          '& .os-scrollbar-vertical .os-scrollbar-track': {
            pl: '2px !important',
          },
        }}
      >
        <Stack gap={8}>
          <SidebarItem
            title="General"
            icon={<Icons.SlidersHorizontal height={18} />}
            url={`${match?.pathnameBase}/dashboard`}
          />

          <SidebarItem
            title="Access control"
            icon={<Icons.UsersThree height={18} />}
            url={`${match?.pathnameBase}/access-control`}
            rules={[['view', 'OrganizationAccessManagement']]}
            supportsDemo={false}
          >
            <SidebarItem
              title="Users"
              url={`${match?.pathnameBase}/access-control/users`}
              rules={[['view', 'FeatureOrganizationRBACV2']]}
            />

            <SidebarItem
              title="User groups"
              url={`${match?.pathnameBase}/access-control/user-groups`}
              rules={[['view', 'FeatureOrganizationRBACV2']]}
            />

            <SidebarItem
              title="Resources"
              url={`${match?.pathnameBase}/access-control/resources`}
              rules={[['view', 'FeatureOrganizationRBACV2']]}
            />
          </SidebarItem>

          <SidebarItem
            title="SSO"
            icon={<Icons.Key height={18} />}
            url={`${match?.pathnameBase}/sso-connections`}
            rules={[['view', 'OrganizationSsoConnections']]}
          />

          <SidebarItem
            title="Billing report"
            icon={<Icons.CreditCard height={18} />}
            url={`${match?.pathnameBase}/billing-report`}
            rules={[['view', 'OrganizationBillingReport']]}
            rulesConditions={{ organizationId: organization!.id }}
          />
        </Stack>
      </Scroller>
    </>
  );
};
