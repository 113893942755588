import {
  YamlNode,
  Token,
  TokenType,
  ArrayMarkToken,
  ObjectKeyToken,
} from './types';
import { PrimitiveVal } from '../../_types';
import { isPrimitive, makeSpaces } from '../../utils';

class YamlNodeImpl implements YamlNode {
  path = '';
  tokens: Token[] = [];
  index = 0;
  isExpanded = true;
  isVisible = true;
  private _collapsablePath: string | null | undefined = null;

  constructor(readonly allNodes: YamlNodeImpl[]) {}

  private _childNodes?: YamlNode[] = undefined;
  get childNodes(): YamlNode[] {
    if (!this._childNodes) {
      this._childNodes = [];
      const collapsiblePath = this.collapsiblePath;
      if (!collapsiblePath) {
        return this._childNodes;
      }
      for (let i = this.index + 1; i < this.allNodes.length; i++) {
        const node = this.allNodes[i];
        if (node.path.startsWith(collapsiblePath)) {
          this._childNodes.push(node);
        }
      }
    }
    return this._childNodes;
  }

  get lastToken() {
    return this.tokens[this.tokens.length - 1];
  }

  get level() {
    const firstToken = this.tokens[0];
    if (firstToken?.type === TokenType.INDENTATION) {
      return firstToken.value;
    }
    return 0;
  }

  private get collapsiblePath() {
    if (this._collapsablePath === null) {
      const tokenWithPath = this.tokens.find(
        (t) => !!(t as ArrayMarkToken | ObjectKeyToken).collapsePath
      ) as ArrayMarkToken | ObjectKeyToken | undefined;
      this._collapsablePath = tokenWithPath?.collapsePath;
    }
    return this._collapsablePath;
  }

  toggle() {
    if (this.childNodes.length) {
      this.isExpanded = !this.isExpanded;
      this.childNodes.forEach((line) => {
        line.isVisible = this.isExpanded;
      });
    }
  }

  addIndentation(indentBy: number) {
    if (indentBy) {
      this.tokens.push({ type: TokenType.INDENTATION, value: indentBy });
    }
  }

  addObjectKey(key: string, index: number, collapsePath?: string) {
    this.tokens.push({ type: TokenType.OBJECT_KEY, value: key, collapsePath });
  }

  addArrayMark(array: any[], index: number, collapsePath?: string) {
    this.tokens.push({
      type: TokenType.ARRAY_MARK,
      collapsePath,
      index,
    });
  }

  addPrimitive(value: PrimitiveVal, indexInParent: number) {
    this.tokens.push({ type: TokenType.PRIMITIVE_VALUE, indexInParent, value });
  }

  endLine(path: string) {
    this.path = path;
    this.index = this.allNodes.length;
    this.allNodes.push(this);
    return new YamlNodeImpl(this.allNodes);
  }

  nodeAsString(indentation = 2) {
    let str = '';
    for (let i = 0; i < this.tokens.length; i++) {
      const token = this.tokens[i];
      switch (token.type) {
        case TokenType.INDENTATION:
          str += makeSpaces(token.value * indentation);
          break;
        case TokenType.ARRAY_MARK:
          str += `-${makeSpaces(indentation - 1)}`;
          break;
        case TokenType.OBJECT_KEY:
          str += `${token.value}: `;
          break;
        case TokenType.PRIMITIVE_VALUE:
          str += token.value;
          break;
        default:
          throw new Error('Unknown token');
      }
    }
    return str;
  }

  yamlAsString(indentation = 2) {
    return this.allNodes
      .map((node) => node.nodeAsString(indentation))
      .join('\n');
  }
}

type Args = {
  level: number;
  parent: any;
  data: any;
  index: number;
  key?: string;
};

class Visitor {
  private lines: YamlNodeImpl[] = [];
  private currentLine = new YamlNodeImpl(this.lines);
  private prevLevel = -1;
  private pathStack: Array<string | number> = [];

  private get path(): string {
    let parts = '$';
    for (const part of this.pathStack) {
      if (typeof part === 'string') {
        parts += `.${part}`;
      } else {
        parts += `[${part}]`;
      }
    }
    return parts;
  }

  private get isAfterArrayMark(): boolean {
    return this.currentLine.lastToken?.type === TokenType.ARRAY_MARK;
  }

  accept({ data, parent, level, key, index }: Args) {
    this.updateStack(level, index, key);
    if (Array.isArray(parent)) {
      if (!this.isAfterArrayMark) {
        this.currentLine.addIndentation(level);
      }
      this.currentLine.addArrayMark(parent, index, this.path);
      if (isPrimitive(data)) {
        this.currentLine.addPrimitive(data, index);
        this.endLine();
      }
    } else if (typeof parent === 'object') {
      if (!this.isAfterArrayMark) {
        this.currentLine.addIndentation(level);
      }
      if (isPrimitive(data)) {
        this.currentLine.addObjectKey(
          key!,
          index,
          index === 0 ? this.path : undefined
        );
        this.currentLine.addPrimitive(data, index);
        this.endLine();
      } else {
        this.currentLine.addObjectKey(key!, index, this.path);
        this.endLine();
      }
    }
    this.prevLevel = level;
  }

  done(): YamlNode {
    const lines = this.lines;
    this.reset();
    return lines[0];
  }

  private reset() {
    this.lines = [];
    this.currentLine = new YamlNodeImpl(this.lines);
    this.prevLevel = -1;
    this.pathStack = [];
  }

  private endLine() {
    this.currentLine = this.currentLine.endLine(this.path);
  }

  private updateStack(level: number, index: number, key?: string) {
    if (this.prevLevel < level) {
      this.pathStack.push(key ?? index);
    } else if (this.prevLevel === level) {
      this.pathStack.pop();
      this.pathStack.push(key ?? index);
    } else if (this.prevLevel > level) {
      const diff = this.prevLevel - level;
      for (let i = 0; i <= diff; i++) {
        this.pathStack.pop();
      }
      this.pathStack.push(key ?? index);
    }
  }
}

const traverse = (data: any, level = 0, visitor: Visitor): Visitor => {
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      visitor.accept({ level, parent: data, data: entry, index: i });
      if (!isPrimitive(data)) {
        traverse(entry, level + 1, visitor);
      }
    }
  } else if (typeof data === 'object') {
    const entries = Object.entries(data);
    for (let i = 0; i < entries.length; i++) {
      const [key, value] = entries[i];
      visitor.accept({ level, parent: data, key, index: i, data: value });
      if (!isPrimitive(value)) {
        traverse(value, level + 1, visitor);
      }
    }
  }
  return visitor;
};

export const getYaml = (data: object): YamlNode => {
  const visitor = new Visitor();
  traverse(data, 0, visitor);
  return visitor.done();
};
