import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { Cluster, ClusterEditParams, ClusterResponse } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';
import { getClustersCacheUpdaterFn, enhanceCluster } from 'utils/api-utils';

export const useUpdateClusterMutation = (
  id: string
): UseMutationResult<Cluster, unknown, ClusterEditParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload) => {
      return await apiClient.clusters
        .updateCluster(
          { clusterId: id, update: payload },
          {
            timeout: 60_000,
          }
        )
        .then(({ data }) => enhanceCluster(data as ClusterResponse));
    },
    onSuccess: (cluster) => {
      queryClient.setQueryData(
        [QueryKeys.EXTERNAL_CLUSTER, cluster.id],
        cluster
      );

      queryClient.setQueryData<Cluster[]>(
        [QueryKeys.EXTERNAL_CLUSTERS],
        getClustersCacheUpdaterFn(cluster)
      );
    },
  });
};
