import { AxiosInstance } from 'axios';

import {
  AIEnablerAPIApiFactory,
  AIEnablerProvidersAPIApiFactory,
  AIEnablerSettingsAPIApiFactory,
} from '@cast/openapi';
import {
  ComponentsAPIApiFactory,
  HostedModelSpecsAPIApiFactory,
  HostedModelsAPIApiFactory,
  PlaygroundChatCompletionsAPIApiFactory,
} from '@cast/openapi-ai-optimizer';
import {
  LLMOptimizationTestRunRequestParams,
  LLMOptimizationTestRunResponse,
} from '@cast/types-ai-optimizer';

import { AXIOS_CONFIGURATION } from './constants';

export const LLMOptimizationApiFactory = (client: AxiosInstance) => {
  const optimizerGeneratedApi = AIEnablerAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const settingsGeneratedApi = AIEnablerSettingsAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const optimizerProvidersGeneratedApi = AIEnablerProvidersAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const hostedModelsSpecGeneratedApi = HostedModelSpecsAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const hostedModelsGeneratedApi = HostedModelsAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const componentsGeneratedApi = ComponentsAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );
  const playgroundChatCompletionsGeneratedApi =
    PlaygroundChatCompletionsAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    getTestChatCompletion: (
      {
        apiKey,
        model = 'gpt-3.5-turbo-0125',
      }: LLMOptimizationTestRunRequestParams,
      baseURL: string
    ) => {
      return client.post<LLMOptimizationTestRunResponse>(
        '/v1/chat/completions',
        {
          model,
          messages: [
            {
              role: 'user',
              content:
                'What kind of instance types to use in GCP for running training AI model?',
            },
          ],
        },
        {
          baseURL,
          headers: {
            'X-API-Key': apiKey,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
    },
    createPlaygroundCompletion:
      playgroundChatCompletionsGeneratedApi.playgroundChatCompletionsAPIStreamPlaygroundChatCompletion,
    getApiKeyChatCompletionApiKeysSavings:
      optimizerGeneratedApi.aIEnablerAPIGetChatCompletionsAPIKeysSavingsReport,
    getApiKeyChatCompletionRecommendations:
      optimizerGeneratedApi.aIEnablerAPIGetChatCompletionsAPIKeysRecommendationsReport,
    getApiKeyChatCompletionUsage:
      optimizerGeneratedApi.aIEnablerAPIGetChatCompletionsAPIKeyUsageReport,
    getCategoryChatCompletionRecommendations:
      optimizerGeneratedApi.aIEnablerAPIGetChatCompletionsCategoryRecommendationsReport,
    getCategoryChatCompletionUsage:
      optimizerGeneratedApi.aIEnablerAPIGetChatCompletionsCategoryUsageReport,
    getChatCompletionRecommendations:
      optimizerGeneratedApi.aIEnablerAPIGetChatCompletionsRecommendationsReport,
    getChatCompletionUsage:
      optimizerGeneratedApi.aIEnablerAPIGetChatCompletionsUsageReport,
    getSupportedProviders:
      optimizerGeneratedApi.aIEnablerAPIGetSupportedProviders,
    getModels: optimizerGeneratedApi.aIEnablerAPIGetModels,
    getRoutingEvents: optimizerGeneratedApi.aIEnablerAPIGetRoutingEvents,
    getSettings: settingsGeneratedApi.aIEnablerSettingsAPIGetSettings,
    upsertSettings: settingsGeneratedApi.aIEnablerSettingsAPIUpsertSettings,
    registerProvider:
      optimizerProvidersGeneratedApi.aIEnablerProvidersAPIRegisterProviders,
    getProviders:
      optimizerProvidersGeneratedApi.aIEnablerProvidersAPIGetProviders,
    updateProvider:
      optimizerProvidersGeneratedApi.aIEnablerProvidersAPIUpdateProvider,
    deleteProvider:
      optimizerProvidersGeneratedApi.aIEnablerProvidersAPIDeleteProvider,
    prioritizeProviders:
      optimizerProvidersGeneratedApi.aIEnablerProvidersAPIPrioritizeProviders,
    getHostedModelsSpecs:
      hostedModelsSpecGeneratedApi.hostedModelSpecsAPIListHostedModelSpecs,
    createHostedModel:
      hostedModelsGeneratedApi.hostedModelsAPICreateHostedModel,
    getHostedModels: hostedModelsGeneratedApi.hostedModelsAPIListHostedModels,
    scaleHostedModel: hostedModelsGeneratedApi.hostedModelsAPIScaleHostedModel,
    deleteHostedModel:
      hostedModelsGeneratedApi.hostedModelsAPIDeleteHostedModel,
    getCastwareComponents: componentsGeneratedApi.componentsAPIListComponents,
  };
};
