import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { Cluster, ClusterDisconnectParams, ClusterResponse } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';
import { getClustersCacheUpdaterFn, enhanceCluster } from 'utils/api-utils';

export const useDisconnectClusterMutation = (
  clusterId: string
): UseMutationResult<Cluster, unknown, ClusterDisconnectParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (config) => {
      return await apiClient.clusters
        .disconnectCluster({ clusterId, config })
        .then(({ data }) => enhanceCluster(data as ClusterResponse));
    },
    onSuccess: (cluster) => {
      queryClient.setQueryData(
        [QueryKeys.EXTERNAL_CLUSTER, cluster.id],
        cluster
      );

      queryClient.setQueryData<Cluster[]>(
        [QueryKeys.EXTERNAL_CLUSTERS],
        getClustersCacheUpdaterFn(cluster)
      );
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
