import { useEffect } from 'react';

import { ClusterStatus } from '@cast/types';
import { usePrevious } from '@cast/utils';

import { useClusterQuery } from 'hooks/queries/cluster';

export const useDidClusterBecameOperable = (
  clusterId: string,
  onSuccess?: () => void,
  enabled?: boolean
) => {
  const { cluster: _cluster } = useClusterQuery(
    clusterId,
    enabled ? undefined : false
  );

  const newStatus = _cluster?.status;
  const previousStatus = usePrevious(newStatus);

  useEffect(() => {
    if (
      enabled &&
      newStatus &&
      previousStatus &&
      [ClusterStatus.READY, ClusterStatus.WARNING].includes(newStatus) &&
      ![ClusterStatus.READY, ClusterStatus.WARNING].includes(previousStatus)
    ) {
      onSuccess?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStatus, previousStatus, enabled]);

  return newStatus;
};
