import { PropsWithChildren } from 'react';

import {
  PureAbility,
  AbilityClass,
  subject as subjectHelper,
  ConditionsMatcher,
} from '@casl/ability';

import { useAuth } from 'core/auth';
import { useOrganizations } from 'hooks/useOrganizations';
import { useResolveFeatureFlags } from 'hooks/useResolveFeatureFlags';

import { AbilityContext } from './AbilityContext';
import { useRulesV1 } from './hooks/useRules.v1';
import { useRulesV2 } from './hooks/useRules.v2';
import {
  Ability,
  AbilityConditions,
  Actions,
  ConsoleAbility,
  ConsoleRules,
  Subjects,
} from './types';

const ConsoleAbilityClass = PureAbility as AbilityClass<ConsoleAbility>;

type Conditions = any;

const conditionsMatcher = {
  subjectId: (condition: Conditions, context: AbilityConditions) => {
    return condition.subjectId === context.subjectId;
  },
  organizationId: (condition: Conditions, context: AbilityConditions) => {
    return condition.organizationId === context.organizationId;
  },
  clusterId: (condition: Conditions, context: AbilityConditions) => {
    return condition.clusterId === context.clusterId;
  },
  default: (conditions: Conditions) => (entity: any) => {
    return Object.keys(conditions).every(
      (key) => entity[key] === conditions[key]
    );
  },
};

const conditionsMatcherFn: ConditionsMatcher<Conditions> = (ruleConditions) => {
  if (ruleConditions) {
    return (context) => {
      // every rule has subjectId which is userId or groupId
      const matchSubject = conditionsMatcher.subjectId(ruleConditions, context);

      // if rule has clusterId, it means that rules scope is cluster
      if (ruleConditions?.clusterId && conditionsMatcher.clusterId) {
        const matchCluster = conditionsMatcher.clusterId(
          ruleConditions,
          context
        );
        return matchSubject && matchCluster;
      }

      // if rule has organizationId, it means that rules scope is organization
      if (ruleConditions?.organizationId && conditionsMatcher.organizationId) {
        const matchOrganization = conditionsMatcher.organizationId(
          ruleConditions,
          context
        );
        return matchSubject && matchOrganization;
      }

      return false;
    };
  }

  return conditionsMatcher.default(ruleConditions);
};

export let ability: Ability = {} as never;

export const defineAbilityFor = (
  rules: ConsoleRules[],
  context: AbilityConditions
): Omit<Ability, 'isLoading'> => {
  const ability = new ConsoleAbilityClass(rules, {
    conditionsMatcher: conditionsMatcherFn,
  });

  const can = (
    action: Actions,
    subject: Subjects,
    conditions: AbilityConditions = {}
  ) => {
    const { subjectId, organizationId, clusterId } = conditions;
    return ability.can(
      action,
      subjectHelper<Subjects, any>(subject, {
        subjectId: subjectId ?? context.subjectId,
        organizationId: organizationId ?? context.organizationId,
        clusterId: clusterId ?? context.clusterId,
      })
    );
  };

  const canMany = (
    rules: Array<[Actions, Subjects]>,
    conditions: AbilityConditions = {}
  ) => {
    return rules.map(([action, subject]) => can(action, subject, conditions));
  };

  const cannot = (
    action: Actions,
    subject: Subjects,
    conditions: AbilityConditions = {}
  ) => {
    const { subjectId, organizationId, clusterId } = conditions;
    return ability.cannot(
      action,
      subjectHelper<Subjects, any>(subject, {
        subjectId: subjectId ?? context.subjectId,
        organizationId: organizationId ?? context.organizationId,
        clusterId: clusterId ?? context.clusterId,
      })
    );
  };

  const cannotMany = (
    rules: Array<[Actions, Subjects]>,
    conditions: AbilityConditions = {}
  ) => {
    return rules.map(([action, subject]) =>
      cannot(action, subject, conditions)
    );
  };

  const canOneOf = (
    action: Actions,
    subjects: Subjects[],
    conditions: AbilityConditions = {}
  ) => {
    return subjects.some((subject) => can(action, subject, conditions));
  };

  const cannotOneOf = (
    action: Actions,
    subjects: Subjects[],
    conditions: AbilityConditions = {}
  ) => {
    return subjects.some((subject) => cannot(action, subject, conditions));
  };

  const canAllOf = (
    action: Actions,
    subjects: Subjects[],
    conditions: AbilityConditions = {}
  ) => {
    return subjects.every((subject) => can(action, subject, conditions));
  };

  const cannotAllOf = (
    action: Actions,
    subjects: Subjects[],
    conditions: AbilityConditions = {}
  ) => {
    return subjects.every((subject) => cannot(action, subject, conditions));
  };

  const relevantRuleFor = (
    action: Actions,
    subject: Subjects,
    conditions: AbilityConditions = {}
  ) => {
    const { subjectId, organizationId, clusterId } = conditions;
    return ability.relevantRuleFor(
      action,
      subjectHelper<Subjects, any>(subject, {
        subjectId: subjectId ?? context.subjectId,
        organizationId: organizationId ?? context.organizationId,
        clusterId: clusterId ?? context.clusterId,
      })
    );
  };

  return {
    can,
    canMany,
    cannot,
    cannotMany,
    canOneOf,
    canAllOf,
    cannotOneOf,
    cannotAllOf,
    relevantRuleFor,
  };
};

export const AbilityProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { isLoadingFeatureFlags, featureFlags } = useResolveFeatureFlags();
  const { user } = useAuth();
  const { currentOrganization } = useOrganizations();

  const rulesV1 = useRulesV1(featureFlags, user?.guid); // todo(adomas): Stop supporting v1 rules after migration
  const rulesV2 = useRulesV2(featureFlags, user?.guid);

  const isRBACV2Enabled = Boolean(
    !isLoadingFeatureFlags &&
      featureFlags?.find((f) => f.flagName === 'wire-rbacv2-enabled')?.boolean
  );

  const { isLoading, rules } = !isRBACV2Enabled ? rulesV1 : rulesV2;

  // todo(adomas): Remove this after migration https://www.escoffier.edu/wp-content/uploads/2021/07/Smiling-male-chef-with-white-coat-and-hat-768.jpg
  if (isRBACV2Enabled) {
    rules.push({
      action: 'view',
      subject: 'FeatureOrganizationRBACV2',
      conditions: {
        subjectId: user?.guid,
        organizationId: currentOrganization?.id,
      },
    });
  }

  // expose ability to global scope for utilities and other non hook functions
  ability = {
    isLoading: isLoading || isLoadingFeatureFlags,
    ...defineAbilityFor(rules, {
      subjectId: user?.guid,
      organizationId: currentOrganization?.id,
    }),
  };

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
