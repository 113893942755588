import { useMemo } from 'react';

import { groupUniqueNodesWithCount } from '@cast/utils';

import { useAvailableSavingsQuery } from 'hooks/queries/available-savings';
import { useCluster } from 'hooks/useCluster';

import { getConfigurationSummary } from '../utils';

export const useAvailableSavingsCurrentConfiguration = () => {
  const { cluster } = useCluster();
  const { data } = useAvailableSavingsQuery(cluster.id);

  const nodes = useMemo(
    () => groupUniqueNodesWithCount(data?.currentConfiguration?.nodes || []),
    [data]
  );
  const summary = useMemo(() => getConfigurationSummary(nodes), [nodes]);

  return {
    nodes,
    summary,
  };
};
