import { useCallback, useState } from 'react';

import { Dialog } from '@cast/design-system';
import { Cluster } from '@cast/types';

import { SwipeableViews } from 'components/boxes';
import { ClusterProvider } from 'core/providers';
import { isAKSCluster } from 'types/cluster';
import { isE2E } from 'utils/isE2E';

import { EnablingSlide } from './_components/EnablingSlide';
import { RunTheScriptSlide } from './_components/RunTheScriptSlide';
import { SuccessSlide } from './_components/SuccessSlide';
import { useDidClusterBecameOperable } from '../../_hooks/useDidClusterBecameOperable';
import { useWasCredentialsAssigned } from '../../_hooks/useWasCredentialsAssigned';

type Props = {
  cluster: Cluster;
  destroy: () => void;
  onEnabled?: () => void;
};

export const AssignAutoscalerCredentialsDialog = ({
  destroy,
  cluster,
  onEnabled: _onEnabled,
}: Props) => {
  const [activeSlide, setActiveSlide] = useState<
    'runTheScript' | 'enabling' | 'success'
  >('runTheScript');

  const onEnabled = useCallback(() => {
    setActiveSlide('success');
    _onEnabled?.();
  }, [_onEnabled]);
  const wasCredentialsAssigned = useWasCredentialsAssigned(
    cluster.id,
    () => {
      if (isAKSCluster(cluster)) {
        onEnabled();
      }
    },
    activeSlide === 'enabling'
  );
  useDidClusterBecameOperable(
    cluster.id,
    onEnabled,
    !isAKSCluster(cluster) &&
      activeSlide === 'enabling' &&
      wasCredentialsAssigned
  );

  return (
    <Dialog
      open
      onClose={destroy}
      disableEscapeKeyDown
      disableCloseOnBackdropClick
      testId="assign-autoscaler-credentials-dialog"
    >
      <ClusterProvider cluster={cluster} isLoading={false}>
        <SwipeableViews
          slides={{
            runTheScript: (
              <RunTheScriptSlide onClose={destroy} clusterId={cluster.id} />
            ),
            enabling: <EnablingSlide onClose={destroy} />,
            success: <SuccessSlide onClose={destroy} />,
          }}
          disableAnimations={isE2E()}
          activeSlide={activeSlide}
          onSlideChange={setActiveSlide}
        />
      </ClusterProvider>
    </Dialog>
  );
};
