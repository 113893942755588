import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

type Request = {
  enforcementId: string;
};

export const useDeleteCheckPolicyEnforcementMutation = (
  successMessage = 'Check enforcement policy was successfully deleted.'
): UseMutationResult<unknown, unknown, Request, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ enforcementId }) => {
      return apiClient.securityInsights.deleteCheckPolicyEnforcement({
        enforcementId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.SECURITY_BEST_PRACTICE_CHECK],
      });
      notify.success(successMessage);
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
