import { GetRowKey, ReorderRow, RowKey } from '../types';

export const reorderRows = (
  { rowKey, newIndex }: ReorderRow,
  data: any[],
  key: GetRowKey | RowKey
) => {
  const getRowKey = typeof key === 'function' ? key : (row: any) => row[key];
  const arr = [...data];
  const [removedElement] = arr.splice(
    data.findIndex((row) => getRowKey(row) === rowKey),
    1
  );
  arr.splice(newIndex, 0, removedElement);
  return arr;
};
