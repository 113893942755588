/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Each invariant is defined in camel case in order to avoid breaking changes when replacing string fields in existing responses with this enum. Camel case was chosen because message field names in generated code are always in camel case and there is no way to change that. On the other hand, enum field names are carried over to the generated code without any modifications.
 * @export
 * @enum {string}
 */

export const InsightsV1VulnerabilitySeverity = {
    any: 'any',
    none: 'none',
    low: 'low',
    medium: 'medium',
    high: 'high',
    critical: 'critical',
    notAvailable: 'notAvailable'
} as const;

export type InsightsV1VulnerabilitySeverity = typeof InsightsV1VulnerabilitySeverity[keyof typeof InsightsV1VulnerabilitySeverity];



