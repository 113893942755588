import isEmpty from 'lodash/isEmpty';
import { matchPath, generatePath } from 'react-router-dom';

import { organizationQueryKey } from '@cast/constants';

export type OrgCostMonitoringRouteKeys = 'allocationGroups' | 'allocationGroup';

const BASE_URL = '/organization/cost-report';
const PERSISTENT_QUERY_PARAMS = [
  organizationQueryKey,
  'cost_report_date_preset',
  'cost_report_from',
  'cost_report_to',
];

const allocationGroupsBasePath = 'allocation-groups';
const allocationGroupBasePath = 'allocation-groups/:allocationGroup';

const orgCostMonitoringPathMap: Record<OrgCostMonitoringRouteKeys, string> = {
  allocationGroups: allocationGroupsBasePath,
  allocationGroup: allocationGroupBasePath,
};

const routeSegmentsByObject = {
  allocationGroups: 'allocation-groups',
  allocationGroup: 'allocation-groups/:allocationGroup',
} as const;

type ObjectSegmentKeys = keyof typeof routeSegmentsByObject;
type NavigationParams = {
  object?: ObjectSegmentKeys;
};

export const getOrgCostMonitoringRoutePath = (
  key: OrgCostMonitoringRouteKeys
) => {
  return orgCostMonitoringPathMap[key];
};

type RouteMatch = {
  object: ObjectSegmentKeys;
  path: string;
  params: Record<string, string>;
};

const ORG_COST_MONITORING_PATHS = ((): Record<
  OrgCostMonitoringRouteKeys,
  string
> => {
  const r = {} as Record<OrgCostMonitoringRouteKeys, string>;

  // prettier-ignore
  (Object.keys(routeSegmentsByObject) as ObjectSegmentKeys[]).forEach(
    (object) => {
      const objectSegment = routeSegmentsByObject[object];
      if (!objectSegment.includes('__report_type__')) {
        r[object] = `${BASE_URL}/${routeSegmentsByObject[object]}`;
      }
    }
  );

  return r;
})();

const retrieveCurrentRouteContext = (): RouteMatch | undefined => {
  return (
    Object.keys(ORG_COST_MONITORING_PATHS) as OrgCostMonitoringRouteKeys[]
  ).reduce((acc, k) => {
    const match = matchPath(
      ORG_COST_MONITORING_PATHS[k],
      window.location.pathname
    );

    if (match) {
      const object = k;

      return {
        object,
        path: ORG_COST_MONITORING_PATHS[k],
        params: match.params,
      } as RouteMatch;
    }

    return acc;
  }, undefined as RouteMatch | undefined);
};

const appendQueryParams = (path: string, queryParameters: URLSearchParams) => {
  const mergerParams = new URLSearchParams();
  queryParameters.forEach((value, key) => {
    mergerParams.append(key, value);
  });

  const searchParams = new URLSearchParams(window.location.search);
  // Copy query parameters from the current URL
  searchParams.forEach((value, key) => {
    if (PERSISTENT_QUERY_PARAMS.includes(key)) {
      mergerParams.append(key, value);
    }
  });

  return `${path}?${mergerParams.toString()}`;
};

export const buildLinkToReport = (
  destinationPath: string,
  params: Record<string, string> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  // retrieve more data from the current URL
  const match = retrieveCurrentRouteContext();
  const mergedParams: Record<string, string | undefined> = {
    ...match?.params,
    ...params,
  };

  if (isEmpty(mergedParams)) {
    throw new Error(`Missing params for route ${destinationPath}`);
  }

  const path = generatePath(destinationPath, mergedParams);

  // Validate whether all params are provided
  const missingParams = path.match(/\/:\w+/g);
  if (missingParams?.length) {
    throw new Error(
      `Missing params ${missingParams
        .join(', ')
        .replaceAll('/:', '')} for route ${path}`
    );
  }

  return appendQueryParams(path, queryParams);
};

export const getLinkToReport = (
  { object }: NavigationParams,
  params: Record<string, string> = {},
  queryParams: URLSearchParams = new URLSearchParams()
) => {
  // retrieve even more data from the current URL
  const match = retrieveCurrentRouteContext();

  const _object: ObjectSegmentKeys =
    object ?? match?.object ?? 'allocationGroups';
  const key: OrgCostMonitoringRouteKeys = _object;

  const destinationPath = ORG_COST_MONITORING_PATHS[key];
  return buildLinkToReport(destinationPath, params, queryParams);
};

export type LinkParams<T extends string> = Partial<Record<T, string>>;

export type AllocationGroup = 'allocationGroup';
