import { ClusterAnomaly, CpuType, PartialNullableProps } from '@cast/types';

import { computeResourceKeyMap } from 'common/maps';
import { ComposedChartWithEstimateProps } from 'components/chart';
import { ChartType } from 'types/charts';
import { ComputeSpendType } from 'types/cost-report';
import { NodeType } from 'types/nodes';
import { TopMostExpensiveGroupDatapoint } from 'types/top-items';

import { DailyChartEntry } from './charts';
// TODO: fix internal type
import { NormalizedClusterEfficiencyItem } from '../../cluster/cost-report/cluster/efficiency/_types';

type Resource =
  (typeof computeResourceKeyMap)[keyof typeof computeResourceKeyMap];

type NodeTypeWithUptime = `${keyof Record<NodeType, string>}Uptime`;
type NodeTypeWithCost = `${keyof Record<NodeType, string>}Cost`;
type NodeTypeWithResourceCost = `${keyof Record<
  NodeType,
  string
>}${keyof Record<Resource, string>}Cost`;
type NodeTypeWithCostPerCpu = `${keyof Record<NodeType, string>}CostPerCpu`;
type NodeTypeWithCostPerGpu = `${keyof Record<NodeType, string>}CostPerGpu`;
type NodeTypeWithCostPerGib = `${keyof Record<NodeType, string>}CostPerGib`;
type NodeTypeWithCpuCount = `${keyof Record<NodeType, string>}CpuCount`;
type NodeTypeWithGpuCount = `${keyof Record<NodeType, string>}GpuCount`;
type NodeTypeWithRamGib = `${keyof Record<NodeType, string>}RamGib`;

export type CostReportDataBase<T = number> = {
  timestamp: string;

  totalCpu: T;
  totalGpu: T;
  totalRamGib: T;
  totalStorage: T;

  totalCost: T;
  totalCpuCost: T;
  totalGpuCost: T;
  totalRamCost: T;
  totalStorageCost: T;

  normalizedCostPerCpu: T;
  normalizedCostPerGib: T;
  normalizedCostPerGpu: T;
  normalizedCostPerStorage: T;
  costPerStorage: T;

  forecast?: boolean; // true when calculated from moving average
  generated?: boolean; // true when datapoint is added to by us
} & Record<NodeTypeWithCost, T> &
  Record<NodeTypeWithResourceCost, T> &
  Record<NodeTypeWithCostPerCpu, T> &
  Record<NodeTypeWithCostPerGib, T> &
  Record<NodeTypeWithCostPerGpu, T> &
  Record<NodeTypeWithCpuCount, T> &
  Record<NodeTypeWithGpuCount, T> &
  Record<NodeTypeWithRamGib, T> &
  Record<NodeTypeWithUptime, T>;

export type HourlyClusterCostReportData<T = number> = CostReportDataBase<T>;

type NodeTypeWithPodCount = `${keyof Record<NodeType, string>}PodCount`;
type NodeTypeWithCostPerPod = `${keyof Record<NodeType, string>}CostPerPod`;
type ComputeSpendTypeWithForecast = `${keyof Record<
  ComputeSpendType,
  string
>}Forecast`;

export type UptimeWorkloadCostReportData<T = number> = CostReportDataBase<T> & {
  totalPods: T;
  normalizedCostPerPod: T;
} & Record<NodeTypeWithPodCount, T> &
  Record<NodeTypeWithCostPerPod, T>;

export type CostReportDataByPeriod =
  | HourlyClusterCostReportData
  | UptimeWorkloadCostReportData;

type HourlyClusterCostReportDataNullableProps = Exclude<
  keyof HourlyClusterCostReportData,
  'timestamp' | 'forecast'
>;

type UptimeWorkloadCostReportDataNullableProps = Exclude<
  keyof HourlyClusterCostReportData,
  'timestamp' | 'forecast'
>;

export type CostReportChartDataByPeriod<T = number> =
  | PartialNullableProps<
      HourlyClusterCostReportData<T>,
      HourlyClusterCostReportDataNullableProps
    >
  | PartialNullableProps<
      UptimeWorkloadCostReportData<T>,
      UptimeWorkloadCostReportDataNullableProps
    >;

export type ComputeSpendChartData = {
  timestamp: string;
  forecast?: boolean;
  anomalies: ClusterAnomaly[];
} & Partial<Record<ComputeSpendTypeWithForecast, number | null>> &
  Record<ComputeSpendType, number | null> &
  Record<NodeTypeWithCpuCount, number | null> &
  Record<NodeTypeWithRamGib, number | null>;

export type NormalizedCostChartData = Record<
  NormalizedCostMode,
  number | null
> & {
  forecast?: boolean;
  timestamp: string;
};

export type PodCountCostChartData = Record<PodCountMode, number | null> & {
  forecast?: boolean;
  timestamp: string;
};

export type PodCostCostChartData = Record<PodCostMode, number | null> & {
  forecast?: boolean;
  timestamp: string;
};

export type TopMostExpensiveGroupsChartData =
  TopMostExpensiveGroupDatapoint[number];

export type CostReportChartData =
  | ComputeSpendChartData
  | NormalizedCostChartData
  | PodCountCostChartData
  | PodCostCostChartData
  | DailyChartEntry
  | NormalizedClusterEfficiencyItem
  | TopMostExpensiveGroupsChartData;

export const CostReportChartType = {
  AREA: ChartType.AREA,
  BAR: ChartType.BAR,
  LINE: ChartType.LINE,
} as const;
export type CostReportChartType =
  (typeof CostReportChartType)[keyof typeof CostReportChartType];

export enum ComputeSpendMode {
  DAILY_COST = 'DAILY_COST',
  CUMULATIVE = 'CUMULATIVE',
}

export enum NormalizedCostMode {
  ON_DEMAND = CpuType.ON_DEMAND,
  FALLBACK = CpuType.FALLBACK,
  SPOT = CpuType.SPOT,
  NORMALIZED = CpuType.NORMALIZED,
}

export type DailyMetrics = {
  cost?: number;
  prevCost?: number;
};

export type HourlyCostPerResource = {
  normalizedSoFar: number;
  normalizedAverage: number;

  onDemandSoFar: number;
  onDemandAverage: number;

  fallbackSoFar: number;
  fallbackAverage: number;

  spotSoFar: number;
  spotAverage: number;
};

export type AggregatedMetrics = {
  computeCost: {
    hourly: number;
    daily: number;
    total: number;
    todayProjected: number;
    rangeSoFar: number;
    onDemandProjected: number;
    fallbackProjected: number;
    storageProjected: number;
    spotProjected: number;
    onDemandRangeSoFar: number;
    fallbackRangeSoFar: number;
    spotRangeSoFar: number;
    storageRangeSoFar: number;
  };
  hourlyCostPerCpu?: HourlyCostPerResource;
  hourlyCostPerRamGib?: HourlyCostPerResource;
  hourlyCostPerGpu?: HourlyCostPerResource;
  hourlyCostPerStorage?: HourlyCostPerResource;
  cpuCount?: {
    total: number;
    onDemand: number;
    fallback: number;
    spot: number;
  };
  podsCount?: {
    total: number;
    onDemand: number;
    fallback: number;
    spot: number;
  };
  podsCost?: {
    normalized: number;
    onDemand: number;
    fallback: number;
    spot: number;
  };
  monthlyForecast?: DailyMetrics;
  avgDailyCost?: DailyMetrics;
  avgMonthlyCost?: DailyMetrics;
  avgCostPerCpu?: DailyMetrics;
  avgCostPerGpu?: DailyMetrics;
  avgCostPerGib?: DailyMetrics;
  avgCostPerStorage?: DailyMetrics;

  workloadsCount?: number;
};

export type ResourceOfferingMultiplier = {
  [ComputeSpendType.ON_DEMAND]: number;
  [ComputeSpendType.FALLBACK]: number;
  [ComputeSpendType.SPOT]: number;
  [ComputeSpendType.STORAGE]: number;
};

export enum CostReportPodTab {
  COUNT = 'COUNT',
  COST = 'COST',
}

export enum PodCostMode {
  ON_DEMAND = CpuType.ON_DEMAND,
  FALLBACK = CpuType.FALLBACK,
  SPOT = CpuType.SPOT,
  NORMALIZED = CpuType.NORMALIZED,
}

export enum PodCountMode {
  ON_DEMAND = 'onDemand',
  FALLBACK = 'fallback',
  SPOT = 'spot',
  TOTAL = 'total',
}

export const isWorkloadReport = (
  data: CostReportChartDataByPeriod
): data is UptimeWorkloadCostReportData => {
  return (data as UptimeWorkloadCostReportData).onDemandUptime !== undefined;
};

export type ComposedChartWithCostReportProps =
  ComposedChartWithEstimateProps<CostReportChartData> & {
    cursorWidth?: number;
  };
