export const stringToHash = (v: string): number => {
  let hash = 0;
  let i;
  let chr;

  if (v.length === 0) {
    return hash;
  }

  for (i = 0; i < v.length; i++) {
    chr = v.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return Math.abs(hash);
};
