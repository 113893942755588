export type {
  CastaiNotificationsV1beta1Notification as Notification,
  CastaiNotificationsV1beta1WebhookConfig as Webhook,
  CastaiNotificationsV1beta1AddWebhookConfig as CreateWebhook,
  CastaiNotificationsV1beta1UpdateWebhookConfig as UpdateWebhook,
  CastaiNotificationsV1beta1ListNotificationsResponse as NotificationsListResponse,
  CastaiNotificationsV1beta1ListWebhookConfigsResponse as WebhooksListResponse,
  NotificationAPIApiNotificationAPIListNotificationsRequest as ListNotificationsRequest,
} from '@cast/openapi';

export { CastaiNotificationsV1beta1Severity as NotificationSeverity } from '@cast/openapi';

export enum WebhookStatus {
  CONNECTED = 'CanConnect',
  FAILED = 'FailedToConnect',
}
