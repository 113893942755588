import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

export const useDeleteImagesMutation = (
  onSuccess?: () => void
): UseMutationResult<unknown, unknown, string[], unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (tagNames) => {
      return apiClient.securityInsights.deleteImages({ body: { tagNames } });
    },
    onSuccess: (_, tagNames) => {
      notify.success(
        `${
          tagNames.length === 1 ? 'Repository was' : 'Repositories were'
        } removed!`
      );
      queryClient.resetQueries({
        queryKey: [QueryKeys.SECURITY_CONTAINER_IMAGES],
      });
      onSuccess?.();
    },
    onError: (error) => {
      notify.error(parseApiError(error));
    },
  });
};
