import Typography from '@mui/material/Typography';

import { Dialog } from '@cast/design-system';
import { Organization } from '@cast/types';

import { useCreateOrganizationMutation } from 'hooks/mutations/organization/useCreateOrganizationMutation';
import { useOrganizations } from 'hooks/useOrganizations';
import { CreateOrganization } from 'types/organization';
import { parseApiError } from 'utils/api';

import { OrganizationForm } from '../../_components';

type Props = {
  destroy: (organization?: Organization) => void;
};

export const CreateOrganizationDialog = ({ destroy }: Props) => {
  const { setCurrentOrganization } = useOrganizations();
  const { mutate, isPending, error } = useCreateOrganizationMutation(
    (organization) => {
      setCurrentOrganization(organization?.id);
      destroy(organization);
    }
  );

  const handleSubmit = (payload: CreateOrganization) => {
    mutate(payload as Organization);
  };

  return (
    <Dialog
      aria-label="Setup organization dialog"
      open={true}
      fullWidth
      maxWidth="sm"
      onClose={() => destroy()}
      testId="create-organization-dialog"
    >
      <OrganizationForm
        onSubmit={handleSubmit}
        onCancel={() => destroy()}
        submitting={isPending}
        errors={error ? parseApiError(error) : undefined}
      >
        <Typography variant="h4">Set up organization</Typography>
        <Typography variant="P14R" color="grey.500" mt={4}>
          Create a new organization and invite your team. Use and identifiable
          name for your organization.
        </Typography>
      </OrganizationForm>
    </Dialog>
  );
};
