import { ThemeOptions } from '@mui/material';

import { getThemeColor } from '../../utils/theme';

export const searchTheme: ThemeOptions['components'] = {
  DsSearch: {
    styleOverrides: {
      root: {
        width: 500,
      },
      notFound: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '8px 10px',
      },
      criteriaSearch: {},
      criteriaSearchLabel: ({ theme }) => ({
        padding: '11px 8px 5px 8px',
        color: getThemeColor(theme, 'grey.400'),
        ...theme.typography.L10B,
      }),
      criteriaSearchList: ({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        padding: '8px',
        gap: '8px',

        '.DsChip-root': {
          backgroundColor: getThemeColor(theme, 'indigo.300'),
          color: theme.palette.common.white,
          ...theme.typography.P12M,
        },
      }),
      recentSearch: {},
      recentSearchLabel: ({ theme }) => ({
        padding: '11px 8px 5px 8px',
        color: getThemeColor(theme, 'grey.400'),
        ...theme.typography.L10B,
      }),
      options: {
        position: 'relative',
      },
      optionsControls: ({ theme }) => ({
        borderTop: `1px solid ${getThemeColor(theme, 'grey.100')}`,
      }),
      optionsListItem: ({ ownerState }) => ({
        ...(ownerState?.allowMultiple
          ? { '.DsListItem-content': { display: 'flex', gap: '8px' } }
          : {}),
      }),
    },
  },
};
