import { useEffect, useRef, useState } from 'react';

import { Experiments } from '../../constants';
import { ExperimentProps } from '../../types';

export const useExperiment = ({ name, timeout = 2000 }: ExperimentProps) => {
  const id = Experiments[name];
  const [variant, setVariant] = useState<string>('0'); // always default to first variant
  const updateVariantTimeout = useRef<number | null>(null);

  const updateVariant = (newVariant: string) => {
    if (typeof updateVariantTimeout.current === 'number') {
      clearTimeout(updateVariantTimeout.current);
    }
    setVariant(newVariant);
  };

  const updateVariantFromGlobalState = () => {
    const googleOptimizeExperimentValue = window.google_optimize
      ? window.google_optimize.get(id)
      : null;
    updateVariant(googleOptimizeExperimentValue ?? '0');
  };

  const setupOptimizeCallback = () => {
    updateVariantTimeout.current = window.setTimeout(
      updateVariantFromGlobalState,
      timeout
    );

    window.gtag &&
      window.gtag('event', 'optimize.callback', {
        name: id,
        callback: updateVariant,
      });
  };

  useEffect(() => {
    // Delayed init
    if (!window.google_optimize) {
      if (!window.dataLayer) {
        window.dataLayer = [];
      }
      if (!window.dataLayer.hide) {
        window.dataLayer.hide = { start: Date.now() };
      }
      setupOptimizeCallback();
    } else {
      updateVariantFromGlobalState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return variant;
};
