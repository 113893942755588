import * as Sentry from '@sentry/react';

import { excludeEmail } from 'core/analytics/utils';
import { user } from 'core/auth/user';

type Event =
  | 'demo-booked-console'
  | 'clicked-optimize'
  | 'savings-report'
  | 'installed-agent'
  | 'product-signup'
  | 'ab-phone-completed'
  | 'signup_completed';

export const gtmPushEvent = (
  event: Event,
  params?: Record<string, string>,
  email = user().email
) => {
  try {
    if (email && !excludeEmail(email)) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event,
        ...params,
      });
    }
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
};
