import { styled } from '@mui/material';
import isEqual from 'lodash/isEqual';

import { ListItem, Select } from '@cast/design-system';

import { useSearchCriterion } from 'components/search/';

import { SeverityChip } from '../../_components';
import { KnownSeverities } from '../../constants';
import { severityCriterion } from '../constants';

const StyledStatusChip = styled(SeverityChip)({
  width: '100%',
  height: 24,
  flex: 1,
  justifyContent: 'flex-start',
});

export const SeveritySelect = () => {
  const criterion = useSearchCriterion(severityCriterion);
  const value = criterion.value;

  return (
    <Select
      multiple
      checkable
      inputText="Severity"
      value={value}
      options={KnownSeverities}
      onChange={(newValue) => {
        criterion.setValue?.(newValue);
      }}
      listSx={{
        '.Mui-selected': {
          background: 'none',

          '.DsListItem-root': {
            '&:hover': { background: 'none' },
          },
        },
      }}
      renderOption={(option, props) => (
        <ListItem {...props}>
          <StyledStatusChip severity={option} />
        </ListItem>
      )}
      isOptionEqualToValue={isEqual}
    />
  );
};
