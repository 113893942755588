import { Box, Divider } from '@mui/material';

import {
  Button,
  DialogActions,
  DialogContent,
  Icons,
} from '@cast/design-system';
import { Cluster } from '@cast/types';

import { CloseDialogButton } from 'components/dialogs';

import { ConnectedState } from '../../../_components/ConnectedState';
import { ConnectingState } from '../../../_components/ConnectingState';

type ReconnectFooterProps = {
  connecting: boolean;
  onBackClick: () => void;
  onClose: () => void;
  cluster: Cluster;
};

export const ReconnectStep = ({
  connecting,
  onBackClick,
  onClose,
  cluster,
}: ReconnectFooterProps) => {
  return (
    <Box data-testid="reconnect-step">
      <DialogContent sx={{ position: 'relative', p: '24px 32px' }}>
        {connecting ? (
          <ConnectingState
            title="Waiting to reconnect"
            subtitle="Waiting for the connection to be established..."
          />
        ) : (
          <ConnectedState cluster={cluster} reconnect />
        )}
        <CloseDialogButton onClose={onClose} />
      </DialogContent>

      <Divider />
      <DialogActions
        sx={{
          p: '16px 32px',
          direction: 'row',
          justifyContent: connecting ? 'space-between' : 'flex-end',
          alignItems: 'center',
          width: '100%',
          gap: 16,
        }}
      >
        {connecting && (
          <Button
            variant="ghost"
            size="small"
            startIcon={<Icons.ArrowLeft />}
            onClick={onBackClick}
            testId="back-to-setup-button"
          >
            Back
          </Button>
        )}

        <Button
          variant="primary"
          size="small"
          loading={connecting}
          onClick={onClose}
          testId="reconnect-button"
        >
          {connecting ? 'Reconnecting' : 'Close'}
        </Button>
      </DialogActions>
    </Box>
  );
};
