import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type UseBestPracticesFilters = {
  clusterIds?: string[];
  filtersv2?: boolean;
};

export const useBestPracticesFilters = ({
  clusterIds,
  filtersv2 = true,
}: UseBestPracticesFilters) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      QueryKeys.SECURITY_BEST_PRACTICE_FILTERS,
      clusterIds?.length,
      filtersv2,
    ],
    queryFn: async () => {
      const { data } = await apiClient.securityInsights.getBestPracticeFilters({
        clusterIds,
        filtersv2,
      });
      return data;
    },
    enabled: !!clusterIds,
  });
  return { filters: data, isLoading, error, refetch };
};
