import {
  DialogActionsProps as MuiDialogActionsProps,
  styled,
  DialogActions as MuiDialogActions,
} from '@mui/material';

import { mergeSx } from '../../utils';

export type DialogActionsProps = MuiDialogActionsProps;

export const DialogActions = styled(
  ({ sx, ...props }: DialogActionsProps) => {
    return (
      <MuiDialogActions
        sx={mergeSx({ gap: 16, py: 20, px: 24 }, sx)}
        {...props}
      />
    );
  },
  {
    name: 'DsDialogActions',
    slot: 'Root',
    target: 'DsDialogActions-Root',
    overridesResolver: (props, styles) => {
      return [styles.root];
    },
  }
)<DialogActionsProps>({});
