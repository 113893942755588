import {
  CheckResourceStatus,
  SecurityInsightsCheckResource,
} from '@cast/types';

import {
  ClientSideSearchCriterion,
  makeSearchBoxCriteria,
} from 'components/search';

export const affectedResourcesTableFilterKeys = {
  cluster: 'clusterId',
  namespace: 'namespace',
  kind: 'kind',
};

export const resourceSearchBoxCriteria: ClientSideSearchCriterion<SecurityInsightsCheckResource>[] =
  [
    ...makeSearchBoxCriteria<SecurityInsightsCheckResource>(
      {
        title: 'Resource name:',
        key: 'name',
      },
      {
        title: 'Kind:',
        key: 'kind',
        allowMultiple: true,
        options: [
          'Deployment',
          'DaemonSet',
          'StatefulSet',
          'CronJob',
          'Job',
          'Pod',
          'Node',
        ],
      }
    ),
    { key: 'namespace' },
    { key: 'clusterId' },
    { key: 'labels' },
    { key: 'type', initialValue: CheckResourceStatus.affected },
  ];
