import {
  AvailableSavingsNode,
  AvailableSavingsReplicatedWorkload,
  PriceType,
} from '@cast/types';

export type WorkloadRow = Omit<
  AvailableSavingsReplicatedWorkload,
  'replicas'
> & {
  orderKey?: number;
  spotFriendly?: boolean;
  replicas: number;
};

export type PartitionedWorkloads = {
  workloads: WorkloadRow[];
  spotWorkloads: WorkloadRow[];
  spotOnlyWorkloads: WorkloadRow[];
};

export type ModifiedAvailableSavingsNode = AvailableSavingsNode & {
  quantity?: number;
  ramGiBiBytes?: number;
};

export enum Period {
  DAY = 'days',
  WEEK = 'weeks',
  MONTH = 'months',
}

export enum Instance {
  ALL = 'all instances',
  SPOT = 'spot',
  ON_DEMAND = 'on-demand',
}

export type AvailableSavingsHistoryCostRate =
  | PriceType.HOURLY
  | PriceType.DAILY
  | PriceType.MONTHLY;

export enum SpotAnalysisMode {
  SPOT_FRIENDLY_WORKLOADS,
  ALL_WORKLOADS,
}
