import { useCallback, useReducer } from 'react';

export const useTriggerRepaint = () => {
  const [trigger, dispatch] = useReducer((i) => {
    i++;
    if (i === Number.MAX_SAFE_INTEGER) {
      return 0;
    }
    return i;
  }, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(() => dispatch(), [trigger]);
};
