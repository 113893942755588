import { useAvailableSavingsQuery } from 'hooks/queries/available-savings';
import { useCluster } from 'hooks/useCluster';

import { useCurrentPrice } from '../_hooks/useCurrentPrice';
import { useAvailableSavingsArmSupport } from '../_providers/ArmSupportProvider';
import { useAvailableSavingsPauseSchedule } from '../_providers/PauseScheduleProvider';
import { useAvailableSavingsSpotAnalysis } from '../_providers/SpotAnalysisProvider';

/**
 * @returns calculations made by combining Spot analysis, and Pause schedule view settings
 */
export const useCombinedAvailableSavings = () => {
  const { cluster } = useCluster();
  const { data: availableSavingsResponse } = useAvailableSavingsQuery(
    cluster.id
  );

  const { currentMonthlyPrice } = useCurrentPrice();
  const spotCtx = useAvailableSavingsSpotAnalysis();
  const clusterScheduleSavingsState = useAvailableSavingsPauseSchedule();
  const armCtx = useAvailableSavingsArmSupport();

  const price = armCtx?.enabled
    ? armCtx.monthlyPrice
    : spotCtx?.priceAfterWithSpotSavings;

  const availableSavingsPercentage = armCtx?.enabled
    ? armCtx.savingsPercentage
    : spotCtx?.availableSpotSavingsPercentage;

  const monthlyPrice =
    (price * (100 - clusterScheduleSavingsState.clusterScheduleSavings)) / 100;

  const monthlySavings = currentMonthlyPrice - monthlyPrice;
  const savingsProgressPercentage = currentMonthlyPrice
    ? Math.min((monthlyPrice * 100) / currentMonthlyPrice, 100)
    : 100;

  const percentage = availableSavingsPercentage;
  const percentageWithClusterSchedule =
    (100 - percentage) *
    (clusterScheduleSavingsState.clusterScheduleSavings / 100);
  const savingsPercentage = Math.min(
    100,
    percentage + percentageWithClusterSchedule
  );

  const isClusterOptimal =
    !!availableSavingsResponse &&
    !availableSavingsPercentage &&
    !clusterScheduleSavingsState.clusterScheduleSavings;

  const isRebalancingRecommended =
    !!availableSavingsResponse?.isRebalancingRecommended && !isClusterOptimal;

  return {
    monthlyPrice,
    monthlySavings,
    savingsProgressPercentage,
    savingsPercentage,
    isClusterOptimal,
    isRebalancingRecommended,
  };
};
