/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const PodmutationsV1GetMutatorStatusResponseMutatorStatus = {
    UNKNOWN: 'MUTATOR_STATUS_UNKNOWN',
    INVALID: 'MUTATOR_STATUS_INVALID',
    RUNNING: 'MUTATOR_STATUS_RUNNING',
    DISABLED: 'MUTATOR_STATUS_DISABLED'
} as const;

export type PodmutationsV1GetMutatorStatusResponseMutatorStatus = typeof PodmutationsV1GetMutatorStatusResponseMutatorStatus[keyof typeof PodmutationsV1GetMutatorStatusResponseMutatorStatus];



