import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Cluster, ClusterResponse } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';
import { enhanceCluster } from 'utils/api-utils';

export const useClusterQuery = (
  id?: string,
  refetchInterval: number | false = 20_000
) => {
  const {
    data: cluster,
    error,
    isLoading,
  } = useQuery<Cluster, AxiosError>({
    queryKey: [QueryKeys.EXTERNAL_CLUSTER, id],
    queryFn: async () => {
      return await apiClient.clusters
        .getCluster({ clusterId: id! })
        .then(({ data }) => enhanceCluster(data as ClusterResponse));
    },
    refetchInterval,
    enabled: !!id,
  });
  return { cluster, error, isLoading };
};
