import { SecurityVisualSvg } from 'assets/svg';
import { ExternalLink } from 'components/router';

import {
  AgentSetupBlock,
  AgentSetupBlockProps,
} from './_components/AgentSetupBlock';

type Props = Partial<AgentSetupBlockProps>;

export const AgentSecuritySetup = (props: Props) => {
  return (
    <AgentSetupBlock
      heading="Kubernetes & cloud security"
      description={
        <>
          All the key Kubernetes security insights in one place. For CAST AI to
          assess private images please read{' '}
          <ExternalLink link="https://docs.cast.ai/docs/kvisor#enable-private-image-scan-v0370">
            documentation
          </ExternalLink>
        </>
      }
      icon={<SecurityVisualSvg />}
      checkboxTestId="agent-security-checkbox"
      {...props}
    />
  );
};
