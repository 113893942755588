import { Stack, SxProps, Typography, TypographyProps } from '@mui/material';
import dayjs from 'dayjs';

import { getTimeAgo, toDateTimePrecise } from '@cast/utils';

import { CopyToClipboard } from 'components/clickable-actions';
import { EllipsisWithTooltip } from 'components/tooltip';

type Props = {
  time?: string;
  formattedDate?: string;
  distance?: string;
  noRounding?: boolean;
  typographyProps?: TypographyProps;
  sx?: SxProps;
};

export const TimeAgo = ({
  time,
  formattedDate,
  distance,
  noRounding,
  typographyProps,
  sx,
}: Props) => {
  if (!time && (!formattedDate || !distance)) {
    return null;
  }

  const date = time ? toDateTimePrecise(time) : formattedDate;
  let ago = time ? getTimeAgo(time, true) : distance;

  if (!date && !ago) {
    return null;
  }

  ago = noRounding ? ago?.replace('about ', '') : ago;

  return (
    <EllipsisWithTooltip
      TypographyProps={
        {
          variant: 'inherit',
          'data-sortweight': date ? dayjs(date).unix() : undefined,
          ...typographyProps,
        } as any
      }
      TooltipProps={{
        placement: 'bottom-start',
        arrow: true,
        size: 'small',
        title: (
          <Stack direction="row" alignItems="center" gap={4} color="white">
            <Typography variant="inherit" color="inherit">
              {date}
            </Typography>

            <CopyToClipboard color="white" iconSize={14} text={date!} />
          </Stack>
        ),
      }}
      sx={sx}
    >
      {ago}
    </EllipsisWithTooltip>
  );
};
