import { ScreenHint } from 'types/auth';

/**
 * Used for passing screen hints to auth Api which redirects it to IaC.
 * This lets users with a screen hint in url go to specific page, like Signup
 * console.cast.ai -> console-ui -> api -> IaC
 */

export const getScreenHintParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const screenHintQuery = urlParams.get('screen_hint');
  let screenHint = '';
  if (
    screenHintQuery &&
    Object.values(ScreenHint).includes(screenHintQuery as ScreenHint)
  ) {
    screenHint = screenHintQuery;
  }
  return screenHint;
};
