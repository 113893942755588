import { useMemo } from 'react';

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import isEqual from 'lodash/isEqual';
import { useField } from 'react-final-form';

import { mergeSx, ListItem, ListItemText } from '@cast/design-system';

import { RffAutocomplete } from 'core/forms/rff';
import { useActiveClusters } from 'features/organization/_hooks';
import { nonNamespacedLabel } from 'features/organization/security-insights/constants';
import { useBestPracticesFilters } from 'hooks/queries/security-insights';

import { OptionalTag } from './OptionalTag';
import { useGetSelectChipSx } from '../_hooks/useGetSelectChipSx';

type Props = {
  index: number;
  sx?: SxProps;
  listSx?: SxProps;
};

export type Option = {
  namespace: string;
  agentEnabled: boolean;
};

export const RffNamespaceSelect = ({ index, sx, listSx }: Props) => {
  const { input } = useField(`groups[${index}].namespaces`);
  const value: string[] = input.value || [];
  const {
    input: { value: selectedClusterIds },
  } = useField(`groups[${index}].clusters`);
  const { clusters } = useActiveClusters();
  const clusterIds = useMemo(
    () => clusters?.map((cluster) => cluster.id) || [],
    [clusters]
  );
  const { filters } = useBestPracticesFilters({
    clusterIds,
  });

  const options = filters?.namespaces ?? [];

  const chipSx = useGetSelectChipSx();

  return (
    <RffAutocomplete
      testId={`namespace-select-${index}`}
      name={`groups[${index}].namespaces`}
      inputProps={{
        label: (
          <Box display="flex">
            Namespaces
            <OptionalTag />
          </Box>
        ),
      }}
      size="medium"
      sx={mergeSx(sx, chipSx)}
      listSx={listSx}
      inputText={!value.length ? 'Select' : 'Selected'}
      disabled={selectedClusterIds.length === 0}
      checkable
      multiple
      options={options}
      renderOption={(namespace, listItemProps) => {
        return (
          <ListItem
            {...listItemProps}
            sx={{
              background: 'none!important',
              pointerEvents: 'auto!important',
            }}
            testId={
              'ns-option-' + (namespace !== '' ? namespace : nonNamespacedLabel)
            }
          >
            <ListItemText primary={namespace} />
          </ListItem>
        );
      }}
      isOptionEqualToValue={(namespace: string, value: string) => {
        return isEqual(namespace, value);
      }}
      showSelectedOptionsFirst
    />
  );
};
