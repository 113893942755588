import { useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';

import { AgentStatus, Cluster, ClusterStatus } from '@cast/types';

import { QueryKeys } from 'core/react-query';
import { ShowModalOutput } from 'types/modal';

import { ReconnectClusterDialog } from './ReconnectClusterDialog';

export const useReconnectClusterDialog = () => {
  const queryClient = useQueryClient();
  const { showModal } = useModal();
  const modalInstance = useRef<ShowModalOutput | undefined>();

  return {
    openReconnectDialog: (cluster: Cluster, isManaged: boolean) => {
      const intervalId = setInterval(() => {
        if (!modalInstance) {
          return;
        }

        const clusterListQueries: Array<Cluster[]> = queryClient
          .getQueriesData({
            predicate: (query) => {
              return (
                query.queryHash ===
                JSON.stringify([QueryKeys.EXTERNAL_CLUSTERS])
              );
            },
          })
          .map(([, data]) => data as Cluster[]);

        if (clusterListQueries.length) {
          const _cluster = clusterListQueries[0].find(
            (_cluster) => _cluster.id === cluster.id
          );

          if (
            _cluster &&
            ((cluster.agentStatus === AgentStatus.DISCONNECTED &&
              _cluster.agentStatus !== AgentStatus.DISCONNECTED) ||
              (cluster.status === ClusterStatus.FAILED &&
                _cluster.status !== ClusterStatus.FAILED))
          ) {
            modalInstance.current?.update({ cluster: _cluster });
          }
        }
      }, 1000);

      // if managed cluster is disconnected it should be onboarded through phase 1 flow
      const phase: 1 | 2 =
        !isManaged || cluster.agentStatus === 'disconnected' ? 1 : 2;

      modalInstance.current = showModal(
        ReconnectClusterDialog,
        {
          cluster,
          phase,
          onClose: () => {
            modalInstance.current?.destroy();
            clearInterval(intervalId);
          },
        },
        { rootId: 'root' }
      );
    },
  };
};
