import { forwardRef, useEffect, useRef } from 'react';

import { Stack, Typography } from '@mui/material';

import { DraggableRows } from './_components/DraggableRows';
import { SettingsRow } from './_components/SettingsRow';
import { useSettingsDrawerState } from './_hooks/useSettingsDrawerState';
import { SettingsDrawerContext } from './context';
import { Button } from '../../../../../buttons';
import {
  CloseDrawerButton,
  Drawer,
  DrawerProps,
  useDrawer,
} from '../../../../../drawer';
import { ArrowCounterClockwise } from '../../../../../icons';
import { useTableContext } from '../../../../hooks';

const SettingsDrawer = forwardRef<HTMLDivElement, DrawerProps>(
  (props: DrawerProps, ref) => {
    const {
      api: {
        current: {
          state: { columnSettings },
          restoreDefaults,
          columnsInfo: { groupedColumnsProps },
        },
      },
    } = useTableContext();

    const lockedGroupRef = useRef<HTMLDivElement | null>(null);
    const unlockedGroupRef = useRef<HTMLDivElement | null>(null);

    const settingsDrawerState = useSettingsDrawerState(!!props.open);
    const { frozenColumns, lockedColumns, unlockedColumns } =
      settingsDrawerState;

    useEffect(() => {
      if (!settingsDrawerState.initialized) {
        settingsDrawerState.initialize({
          groupedColumnsProps,
          settings: columnSettings,
        });
      }
    }, [groupedColumnsProps, settingsDrawerState, columnSettings]);

    return (
      <Drawer ref={ref} size="small" {...props}>
        <SettingsDrawerContext.Provider
          value={{
            ...settingsDrawerState,
            lockedGroupRef,
            unlockedGroupRef,
          }}
        >
          <CloseDrawerButton />
          <Button
            size="small"
            variant="ghost"
            startIcon={<ArrowCounterClockwise />}
            onClick={() => {
              restoreDefaults();
              settingsDrawerState.restoreDefaults();
            }}
            sx={{ position: 'absolute', top: 24, right: 32 }}
          >
            Restore default
          </Button>
          <Stack pt={68}>
            <Typography variant="h5" mb={24} pl={32}>
              Table management
            </Typography>

            {!!frozenColumns.length &&
              frozenColumns
                .filter(({ excludeFromSettings }) => !excludeFromSettings)
                .map((col) => <SettingsRow key={col.id} column={col} />)}
            <DraggableRows
              rows={lockedColumns}
              group="lockedColumns"
              sx={{
                borderBottom: !!lockedColumns.length ? 'grey.300' : undefined,
              }}
              ref={lockedGroupRef}
            />
            <DraggableRows
              rows={unlockedColumns}
              group="unlockedColumns"
              ref={unlockedGroupRef}
            />
          </Stack>
        </SettingsDrawerContext.Provider>
      </Drawer>
    );
  }
);

SettingsDrawer.displayName = 'SettingsDrawer';

export const useSettingsDrawer = () => {
  const { open } = useDrawer();
  return {
    openSettingsDrawer: () => open(SettingsDrawer, {}),
  };
};
