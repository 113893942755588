import { useEffect } from 'react';

import { Box, Stack } from '@mui/material';

import { CheckResourceStatus, SortOrder } from '@cast/types';

import {
  ClearAllButton,
  clearUrlState,
  Search,
  SearchProvider,
  UseServerData,
  UseServerDataArgs,
} from 'components/search';
import { useBestPracticeCheckResourcesQuery } from 'hooks/queries/security-insights';

import { ResourcesSelect } from './_components/ResourcesSelect';
import { resourceSearchBoxCriteria } from './constants';
import { ClusterSelect } from '../../../../../_components';
import { nonNamespacedLabel } from '../../../../../constants';
import { LabelsSelect } from '../../../../_components/LabelsSelect';
import { NamespaceSelect } from '../../../../_components/NamespaceSelect';
import { ResourcesTable } from '../../../../_components/ResourcesTable';
import { resourcesTableId } from '../../../../constants';

const useData = (
  ruleId: string,
  { values, sortingState, enabled }: UseServerDataArgs
): ReturnType<UseServerData> => {
  const filters = {
    clusterIds: values.clusterId,
    namespaces: values.namespace?.map((ns: string) =>
      ns === nonNamespacedLabel ? '' : ns
    ),
    labels: values.labels?.map((l: string) => l.replace('=', ':')),
    status: values.type,
    resourceKinds: values.kind,
    resourceName: values.name,
  };
  const {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useBestPracticeCheckResourcesQuery({
    enabled,
    filters: {
      ruleId,
      body: {
        ...filters,
        sort: sortingState
          ? {
              field: `resource.${sortingState.columnId}`,
              order: sortingState.sortBy.direction as SortOrder,
            }
          : undefined,
      },
    },
  });

  return {
    data: data.resources,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasFiltersApplied: Object.values(filters).some((v) => v?.length > 0),
    hasNextPage,
    isFetching,
    metadata: { totalCount: data.totalCount },
  };
};

type Props = {
  ruleId: string;
  selectedClusters?: string[];
  selectedNamespaces?: string[];
  selectedType?: CheckResourceStatus;
  selectedLabels?: string[];
};

export const Resources = ({
  ruleId,
  selectedClusters,
  selectedNamespaces,
  selectedType,
  selectedLabels,
}: Props) => {
  useEffect(() => {
    return () => {
      clearUrlState(resourcesTableId);
    };
  }, []);

  return (
    <SearchProvider
      useData={useData.bind(null, ruleId)}
      criteria={resourceSearchBoxCriteria}
      serverSideSort
    >
      <Stack flexGrow={1} minHeight={0} data-testid="resources-tab-content">
        <Stack gap={8} mb={16}>
          <Search searchWhileActive={false} />
          <Stack direction="row" gap={8}>
            <ResourcesSelect initialValue={selectedType} />
            <ClusterSelect
              initialValue={selectedClusters}
              criterion="clusterId"
            />
            <NamespaceSelect
              initialValue={selectedNamespaces}
              criterion="namespace"
            />
            <LabelsSelect initialValue={selectedLabels} />
            <ClearAllButton
              sx={{
                flexBasis: '84px',
                minWidth: 'auto',
                whiteSpace: 'nowrap',
              }}
            />
          </Stack>
        </Stack>

        <Box flexGrow={1} flexShrink={1} mx={-32} overflow="hidden">
          <ResourcesTable
            entity="resource"
            analyticsId="Resources"
            testId="resources-table"
          />
        </Box>
      </Stack>
    </SearchProvider>
  );
};
