import * as Yup from 'yup';

export const contactsFormSchema = Yup.object({
  firstName: Yup.string().required('Required field').label('First name'),
  lastName: Yup.string().required('Required field').label('Last name'),
  company: Yup.string().required('Required field').label('Company name'),
  countryCode: Yup.object()
    .nullable()
    .test('code selected', 'Required field', (value) => !!value)
    .shape({
      code: Yup.string(),
      label: Yup.string(),
      phone: Yup.string(),
    }),
  phone: Yup.lazy((value, context) => {
    const countryCode = context.parent.countryCode?.code;
    return Yup.string()
      .phone(countryCode, 'Invalid phone number')
      .required('Required field');
  }),
});
