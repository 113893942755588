import { CSSObject } from '@mui/material';
import capitalize from 'lodash/capitalize';

import { Chip, ChipProps, mergeSx } from '@cast/design-system';
import { NotificationSeverity } from '@cast/types';

const colors: Record<string, CSSObject> = {
  [NotificationSeverity.CRITICAL]: {
    color: 'red.900',
    backgroundColor: 'red.200',
  },
  [NotificationSeverity.ERROR]: {
    color: 'red.500',
    backgroundColor: 'red.50',
  },
  [NotificationSeverity.WARNING]: {
    color: 'yellow.700',
    backgroundColor: 'yellow.50',
  },
  [NotificationSeverity.SUCCESS]: {
    color: 'green.700',
    backgroundColor: 'green.50',
  },
  [NotificationSeverity.INFO]: {
    color: 'blue.600',
    backgroundColor: 'blue.50',
  },
};

type Props = ChipProps & {
  severity: `${NotificationSeverity}` | string;
};

export const SeverityChip = ({ severity, sx, ...rest }: Props) => {
  return (
    <Chip
      sx={mergeSx(
        {
          ...(colors[severity] || {}),
        },
        sx
      )}
      size="small"
      {...rest}
    >
      {capitalize(severity)}
    </Chip>
  );
};
