import { ReactNode } from 'react';

import { Cluster, K8sProvider } from '@cast/types';

import { useCluster } from 'hooks/useCluster';
import { AKSCluster, EKSCluster, GKECluster, KopsCluster } from 'types/cluster';

type BaseProps = {
  noCluster?: ReactNode;
  fallback?: ReactNode;
};

type ProviderToCluster<T extends `${K8sProvider}` | `${K8sProvider}`[]> =
  T extends `${K8sProvider.EKS}`
    ? EKSCluster
    : T extends `${K8sProvider.AKS}`
    ? AKSCluster
    : T extends `${K8sProvider.GKE}`
    ? GKECluster
    : T extends `${K8sProvider.KOPS}`
    ? KopsCluster
    : T extends Array<`${K8sProvider}`>
    ? Cluster
    : never;

type RootProps<T extends `${K8sProvider}` | Array<`${K8sProvider}`>> = {
  provider: T;
  children: ((cluster: ProviderToCluster<T>) => ReactNode) | ReactNode;
};

type Props<T extends `${K8sProvider}` | Array<`${K8sProvider}`>> = BaseProps &
  RootProps<T>;

const renderContent = (
  provider: `${K8sProvider}`,
  cluster: Cluster,
  fallback: ReactNode,
  children: ReactNode | ((cluster: Cluster) => ReactNode)
) => {
  if (provider === cluster.providerType) {
    if (typeof children === 'function') {
      return <>{children(cluster)}</>;
    }
    return <>{children}</>;
  }
  return <>{fallback}</>;
};

export const WhenProvider = <
  T extends `${K8sProvider}` | Array<`${K8sProvider}`>
>({
  provider,
  children,
  noCluster = null,
  fallback = null,
}: Props<T>) => {
  const { cluster } = useCluster();
  if (!cluster) {
    return <>{noCluster || fallback}</>;
  }

  if (Array.isArray(provider)) {
    const clusterProvider = (cluster as Cluster).providerType;
    if (provider.includes(clusterProvider)) {
      if (typeof children === 'function') {
        return <>{children(cluster as never)}</>;
      }
      return <>{children}</>;
    }
    return <>{fallback}</>;
  }

  return renderContent(provider, cluster as Cluster, fallback, children as any);
};
