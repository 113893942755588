import {
  forwardRef,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useContext,
} from 'react';

import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import capitalize from 'lodash/capitalize';
import { bindToggle } from 'material-ui-popup-state/core';

import { DropdownContext } from '../DropdownContext';

const StyledDropdownRoot = styled(Box, {
  name: 'DsDropdown',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root],
})<BoxProps>({});
StyledDropdownRoot.displayName = 'StyledDropdownRoot';

export type DropdownRootProps = PropsWithChildren<BoxProps>;

export const DropdownRoot = forwardRef<HTMLDivElement, DropdownRootProps>(
  ({ children }, ref) => {
    const { dropdownProps, popupState } = useContext(DropdownContext);
    const prefix = dropdownProps.prefix;
    const disabled =
      dropdownProps.disabled || dropdownProps?.inputProps?.disabled;
    const readOnly = dropdownProps?.inputProps?.readOnly;

    const handleClick = useCallback(
      (e: SyntheticEvent<any>) => {
        if (!disabled && !readOnly) {
          bindToggle(popupState).onClick(e);
        }
      },
      [disabled, readOnly, popupState]
    );

    return (
      <StyledDropdownRoot
        ref={ref}
        {...dropdownProps.rootAttributes}
        className={clsx(
          'DS-Dropdown-root',
          `DS-Dropdown-size${capitalize(dropdownProps.size || 'medium')}`,
          prefix && 'DS-Dropdown-withPrefix',
          dropdownProps.rootAttributes?.className,
          dropdownProps.className
        )}
        sx={dropdownProps.sx}
        data-testid={dropdownProps.testId || 'dropdown'}
        onClick={handleClick}
      >
        {children}
      </StyledDropdownRoot>
    );
  }
);

DropdownRoot.displayName = 'DropdownRoot';
