import { toString } from 'cronstrue';
import * as yup from 'yup';

yup.addMethod(yup.string, 'json', function (message = 'Must be valid json') {
  return this.test('is-json', message, function (value) {
    if (!value) {
      return true;
    }
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  });
});

yup.addMethod(
  yup.string,
  'cron',
  function (message = 'Must be valid cron string') {
    return this.test('is-cron', message, function (value) {
      if (!value) {
        return true;
      }
      try {
        toString(value);
        return true;
      } catch (e) {
        return false;
      }
    });
  }
);
