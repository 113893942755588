import { useContext } from 'react';

import { Stack, Typography } from '@mui/material';

import {
  Button,
  DialogActions,
  DialogContent,
  Icons,
  Spinner,
} from '@cast/design-system';

import { AssignCredentialsDialogContext } from '../context';

export const EnablingCastAiSlide = () => {
  const { dismiss, setActiveSlide } = useContext(
    AssignCredentialsDialogContext
  );
  return (
    <Stack data-testid="in-progress-step">
      <DialogContent>
        <Stack direction="row" gap={12}>
          <Spinner size={32} color="secondary" />
          <Stack gap={4}>
            <Typography variant="h6">Enabling CAST AI features</Typography>
            <Typography variant="P12R" color="textSecondary">
              Closing this dialog will not affect the process
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: 'grey.100', justifyContent: 'space-between' }}
      >
        <Button
          variant="ghost"
          startIcon={<Icons.ArrowLeft />}
          onClick={() => setActiveSlide('setup')}
          testId="back-to-setup-button"
        >
          Back
        </Button>
        <Button onClick={dismiss} testId="close-phase2-dialog-button">
          Close
        </Button>
      </DialogActions>
    </Stack>
  );
};
