import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react';

import { ComputeSpendType } from 'types/cost-report';
import { readStorage, writeStorage } from 'utils/storage';

import { ComputeSpendMode } from '../../../cost-report/types/costOverTime';

const STORE_KEY_BASE = 'cost-report.cluster.compute-spend';

type ReducerState = {
  chartNodeType: Record<ComputeSpendType, boolean>;
  chartMode: ComputeSpendMode;
};

type ReducerAction =
  | { type: 'toggleNodeType'; chartNodeType: ComputeSpendType }
  | { type: 'changeMode'; chartMode: ComputeSpendMode };

type Props = PropsWithChildren<unknown>;

const defaultChartNodeTypes = {
  [ComputeSpendType.ON_DEMAND]: true,
  [ComputeSpendType.FALLBACK]: true,
  [ComputeSpendType.SPOT]: true,
  [ComputeSpendType.STORAGE]: true,
};

const chartNodeTypeFromStorage = readStorage(
  `${STORE_KEY_BASE}.chart-type`,
  defaultChartNodeTypes
);

const initialState: ReducerState = {
  chartNodeType:
    // If all checkboxes were unchecked and you open console again, select all checkboxes instead of showing empty charts
    chartNodeTypeFromStorage &&
    Object.values(chartNodeTypeFromStorage).every((val) => !val)
      ? defaultChartNodeTypes
      : chartNodeTypeFromStorage,
  chartMode: readStorage(
    `${STORE_KEY_BASE}.chart-mode`,
    ComputeSpendMode.DAILY_COST
  ),
};

type ComputeSpendContextValue = [ReducerState, Dispatch<ReducerAction>];

export const ComputeSpendContext = createContext<ComputeSpendContextValue>(
  [] as never
);

function reducer(state: ReducerState, action: ReducerAction) {
  switch (action.type) {
    case 'toggleNodeType': {
      const chartNodeType = {
        ...state.chartNodeType,
        [action.chartNodeType]: !state.chartNodeType[action.chartNodeType],
      };
      writeStorage(`${STORE_KEY_BASE}.chart-type`, chartNodeType);
      return {
        ...state,
        chartNodeType,
      };
    }
    case 'changeMode': {
      writeStorage(`${STORE_KEY_BASE}.chart-mode`, action.chartMode);
      return {
        ...state,
        chartMode: action.chartMode,
      };
    }
  }
}

export const ComputeSpendProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ComputeSpendContext.Provider value={[state, dispatch]}>
      {children}
    </ComputeSpendContext.Provider>
  );
};
