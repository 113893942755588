import { Stack, styled, Typography } from '@mui/material';

const CertificateImage = styled('img')`
  vertical-align: middle;
`;

export const Certificates = () => {
  return (
    <Stack direction="row" columnGap="8px" alignItems="center">
      <CertificateImage
        src="/assets/img/certificates/SOC.png"
        width={28}
        height={28}
      />
      <CertificateImage
        src="/assets/img/certificates/ISO.png"
        width={28}
        height={28}
      />
      <CertificateImage
        src="/assets/img/certificates/CIS.png"
        width={26}
        height={28}
      />
      <Typography variant="P12M" style={{ verticalAlign: 'middle' }}>
        SOC2, ISO27001 & CIS Benchmarks certified
      </Typography>
    </Stack>
  );
};
