import Big from 'big.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import {
  SavingsProgressResponse,
  ClusterEfficiencyResponse,
  WorkloadEfficiencyDetailsResponseRaw,
} from '@cast/types';
import { DEMO_CLUSTER_ID } from '@cast/utils';

import { savingsProgressModifier } from '../modifiers';
import { replaceClusterIdTokens, replaceTimestampTokens } from '../utils';
import { getSummary } from '../utils/achieved-savings';

dayjs.extend(isBetween);

export const demoWorkloadEfficiencyDetails = async (
  start: string,
  end: string
): Promise<WorkloadEfficiencyDetailsResponseRaw> => {
  const json = await import(
    '../generated/demo/workload-efficiency-details.json'
  );

  const response = JSON.parse(
    replaceTimestampTokens(JSON.stringify(json), 'iso', (time) =>
      time.startOf('hour')
    )
  ) as WorkloadEfficiencyDetailsResponseRaw;

  const isValidTimestamp = (timestamp: string) =>
    dayjs(timestamp).isBetween(dayjs(start), dayjs(end));

  const containers = response.containers?.map((container) => ({
    ...container,
    items: container.items.filter(({ timestamp }) =>
      isValidTimestamp(timestamp)
    ),
  }));

  const efficiency = response.efficiency?.filter(({ timestamp }) =>
    isValidTimestamp(timestamp)
  );

  const { waste, costImpact } = (containers || []).reduce(
    (acc, container) => {
      const containerWaste = container.items.reduce(
        (acc, item) => {
          const cpuWaste =
            Big(item.info.requests.cpu).toNumber() > 0
              ? Big(item.info.requests.cpu).sub(item.info.usage.cpu)
              : 0;
          const memoryWaste =
            Big(item.info.requests.memoryGib).toNumber() > 0
              ? Big(item.info.requests.memoryGib).sub(item.info.usage.memoryGib)
              : 0;
          return {
            cpu: acc.cpu.add(cpuWaste),
            memoryGib: acc.memoryGib.add(memoryWaste),
          };
        },
        { cpu: Big(0), memoryGib: Big(0) }
      );

      const containerCostImpact = container.items.reduce(
        (acc, item) => ({
          onDemand: acc.onDemand.add(item.info.costImpact.onDemand),
          spot: acc.spot.add(item.info.costImpact.spot),
          spotFallback: acc.spotFallback.add(item.info.costImpact.spotFallback),
        }),
        {
          onDemand: Big(0),
          spot: Big(0),
          spotFallback: Big(0),
        }
      );

      return {
        waste: {
          cpu: acc.waste.cpu.add(containerWaste.cpu),
          memoryGib: acc.waste.memoryGib.add(containerWaste.memoryGib),
        },
        costImpact: {
          onDemand: acc.costImpact.onDemand.add(containerCostImpact?.onDemand),
          spot: acc.costImpact.onDemand.add(containerCostImpact?.spot),
          spotFallback: acc.costImpact.onDemand.add(
            containerCostImpact?.spotFallback
          ),
        },
      };
    },
    {
      waste: { cpu: Big(0), memoryGib: Big(0) },
      costImpact: { onDemand: Big(0), spot: Big(0), spotFallback: Big(0) },
    }
  );

  return {
    ...response,
    containers,
    efficiency,
    waste: {
      cpu: waste.cpu.toFixed(6),
      memoryGib: waste.cpu.toFixed(6),
    },
    costImpact: {
      onDemand: costImpact.onDemand.toFixed(6),
      spot: costImpact.spot.toFixed(6),
      spotFallback: costImpact.spotFallback.toFixed(6),
    },
  };
};

export const demoClusterEfficiencyDetails = async (
  from: string,
  to: string
) => {
  const json = await import('../generated/demo/cluster-efficiency.json');
  const result = JSON.parse(
    replaceClusterIdTokens(
      replaceTimestampTokens(
        JSON.stringify(json),
        'iso',
        (time) => time.endOf('day').add(1, 'ms'),
        dayjs(to)
      )
    )
  ) as ClusterEfficiencyResponse;
  const _from = dayjs(from);
  let _to = dayjs(to);
  const now = dayjs().endOf('day').add(1, 'ms');
  if (now.isBefore(_to)) {
    _to = now;
  }
  return {
    ...result,
    items: result.items?.filter((item) => {
      const timestamp = dayjs(item.timestamp);
      return timestamp.isBetween(_from, _to.endOf('day').add(1, 'ms'));
    }),
  };
};

export const demoSavingsProgress = async (
  from: string,
  to: string
): Promise<SavingsProgressResponse> => {
  const json = await import('../generated/demo/savings-progress.json');
  const result = savingsProgressModifier(
    JSON.stringify(json),
    {},
    DEMO_CLUSTER_ID,
    dayjs(from)
  );

  const _from = dayjs(from);
  let _to = dayjs(to);
  const now = dayjs().endOf('day').add(1, 'ms');
  if (now.isBefore(_to)) {
    _to = now;
  }

  const items = result.items?.filter((item) => {
    const timestamp = dayjs(item.timestamp);
    return timestamp.isBetween(_from, _to, null, '[]');
  });

  const summary = !!items ? getSummary(items) : undefined;

  return {
    ...result,
    items,
    summary,
  };
};
