import { useMemo } from 'react';

import { capitalize, styled } from '@mui/material';

import { useTriggerRepaint, yamlToJson } from '@cast/utils';

import { YamlLine } from './YamlLine';
import { shouldForwardProp } from '../../../../utils/shouldForwardProp';
import { Scroller } from '../../../scroller';
import { PreviewStateProvider } from '../../_providers/PreviewStateProvider';
import { PreviewYamlProps } from '../types';
import { useYaml } from '../useYaml';

const StyledScroller = styled(Scroller, {
  name: 'DsPreviewYaml',
  slot: 'Scroller',
  target: 'DsPreviewYaml-Scroller os-theme-light',
  shouldForwardProp,
  overridesResolver: (_, styles) => styles.scroller,
})<{ ownerState: PreviewYamlProps }>({});

const SyntaxHighlight = styled('div', {
  name: 'DsPreviewYaml',
  slot: 'SyntaxHighlight',
  target: 'DsPreviewYaml-SyntaxHighlight',
  shouldForwardProp,
  overridesResolver: ({ ownerState }, styles) => [
    styles.syntaxHighlight,
    styles[`size${capitalize(ownerState.size)}`],
  ],
})<{ ownerState: PreviewYamlProps & { errorMarker?: string } }>({});

export const YamlContainer = (props: PreviewYamlProps) => {
  const { containerSx, renderFallback, children } = props;
  let { data } = props;
  if (typeof data === 'string') {
    try {
      if (data === 'null' && renderFallback) {
        return <>{renderFallback(data)}</>;
      }
      data = yamlToJson(data);
    } catch (e) {
      if (!renderFallback) {
        throw e;
      }
      return <>{renderFallback(data)}</>;
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const yaml = useYaml(data);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const yamlText = useMemo(() => yaml?.yamlAsString(), [yaml]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const repaint = useTriggerRepaint();

  return (
    <PreviewStateProvider formattedCode={yamlText}>
      {children ? (
        typeof children === 'function' ? (
          <>{children({ formattedCode: yamlText })}</>
        ) : (
          <>{children}</>
        )
      ) : null}

      <StyledScroller ownerState={props}>
        <SyntaxHighlight ownerState={props} sx={containerSx}>
          {yaml?.allNodes.map((line, index) => (
            <YamlLine
              key={index}
              line={line}
              onToggle={repaint}
              linesCount={yaml.allNodes.length}
            />
          ))}
        </SyntaxHighlight>
      </StyledScroller>
    </PreviewStateProvider>
  );
};
