import dayjs from 'dayjs';
import range from 'lodash/range';
import slice from 'lodash/slice';
import { XAxisProps } from 'recharts';

import { DateRange } from '@cast/types';

import { TimeSeries } from 'types/metrics';

import { anyTimestampToUnix, convertToDateType, getDateType } from './date';

/**
 * @DEPRECATED
 * */
export const getXAxisAreaFormat = (length: number) => {
  if (length <= 72) {
    return 'hh A';
  }

  if (length <= 192) {
    return 'MMM DD';
  }

  if (length <= 744) {
    return 'DD';
  }

  return 'MMM DD';
};

export const dateAxisFormatter = (format: string, tick: number) => {
  return dayjs.unix(tick).format(format);
};

// TODO: support custom ranges like 2 months or so
/**
 * Return interval in which ticks should be displayed
 * */
export const getTickInterval = (
  [from, to]: DateRange,
  datapointsCount: number
) => {
  const diffInDays = to.diff(from, 'days');

  // when displaying 2 days, both labels should be visible
  if (diffInDays === 1 && datapointsCount <= 2) {
    return 1;
  }

  // when displaying 1 day, tick datapoint step size is hour or less, displaying every second datapoint is enough
  // when displaying 1 month, and tick size is days, we want to display every 2nd day
  if (diffInDays <= 1 || datapointsCount <= 31) {
    return 2; // TODO: could be adjusted to viewport/container size
  }

  // in all other cases we assume, that datapoint step size was one hour
  return 24;
};

export const mapTimeTicks = (
  data: TimeSeries<any>,
  tickInterval: number,
  fakeDatapointOffset = 0,
  timestampOffset = 0
): number[] => {
  let _data = data;

  if (fakeDatapointOffset) {
    _data = slice(data, fakeDatapointOffset, data.length);
    _data = slice(_data, 0, _data.length - 1);
  }

  return _data.reduce((acc, current, currentIndex) => {
    // when displaying high density data with fake datapoints we need to offset the ticks
    if (currentIndex < Math.ceil(timestampOffset)) {
      return acc;
    }

    const _timestamp = anyTimestampToUnix(current.timestamp);
    if (!acc.length) {
      acc.push(_timestamp);
      return acc;
    }

    const lastTimestamp: number = acc[acc.length - 1];
    if (_timestamp - lastTimestamp >= 3600 * tickInterval) {
      acc.push(_timestamp);
    }

    return acc;
  }, [] as number[]);
};

export const tickFormatter = (tick: number, [from, to]: DateRange): string => {
  const diff = Math.max(to.diff(from, 'days'), 1);

  if (diff <= 1) {
    return dateAxisFormatter('hh A', tick);
  }

  if (diff <= 14) {
    return dateAxisFormatter('MMM DD', tick);
  }

  if (diff >= 90) {
    const date = dayjs.unix(tick);
    // Only show months of the first day of the month
    return date.date() === 1 ? date.format('MMM') : '';
  }

  return dateAxisFormatter('DD', tick);
};

/**
 * Generates key which is used as x-axis dataKey
 * */
export const attachTimeTicks = <T extends TimeSeries<any>>(
  entries: T,
  key: keyof T[number] = 'timestamp'
) => {
  return entries.map((entry) => ({
    ...entry,
    chartTimestampUnix: anyTimestampToUnix(entry[key]),
  }));
};

export const extractRangeFromData = <T extends TimeSeries<any>>(
  data: T
): DateRange => {
  return data.length > 1
    ? [
        dayjs.unix(anyTimestampToUnix(data[0].timestamp)),
        dayjs.unix(anyTimestampToUnix(data[data.length - 1].timestamp)),
      ]
    : [dayjs(), dayjs()];
};

/**
 * Calculates all ticks which will be used in chart
 * */
export const makeXAxisProps = <T extends TimeSeries<any>>(
  data: T,
  fakeDatapointOffset = 0,
  timestampOffset = 0
): Partial<XAxisProps> => {
  const scale: XAxisProps['scale'] = 'time';
  const type: XAxisProps['type'] = 'number';
  const domain: XAxisProps['domain'] = ['dataMin', 'dataMax'];
  const range = extractRangeFromData(data);

  const tickInterval = getTickInterval(range, data.length);
  const ticks = mapTimeTicks(
    data,
    tickInterval,
    fakeDatapointOffset,
    timestampOffset
  );

  const tickFormatterFn = (tick: number) => tickFormatter(tick, range);

  return {
    fontSize: 10,
    dataKey: 'chartTimestampUnix',
    scale,
    type,
    domain,
    ticks,
    tickFormatter: tickFormatterFn,
    interval: tickInterval === 1 ? 'preserveStartEnd' : 'preserveStart',
  };
};

export const makeFakeDatapointsMapFn =
  <T extends TimeSeries<any>>(
    tickSizeInHours: number,
    method: 'add' | 'subtract',
    timestampKey: keyof T[number] = 'timestamp'
  ) =>
  (d: TimeSeries<any>[number], index: number, array: TimeSeries<any>) => {
    const _dateType = getDateType(d[timestampKey]);
    const _timestamp = dayjs
      .unix(anyTimestampToUnix(d[timestampKey]))
      [method](tickSizeInHours * array.length, 'h');
    return {
      ...d,
      timestamp: convertToDateType(_timestamp.unix(), _dateType),
    };
  };

/**
 * Tries to calculate how many fake datapoints should be added to the beginning and end of the data
 * */
export const getFakeDatapointsLength = <T extends TimeSeries<any>>(
  data: T,
  timestampKey: keyof T[number] = 'timestamp'
) => {
  if (data.length > 1) {
    const [from, to] = extractRangeFromData(data);
    const diffInDays = to.diff(from, 'days');
    const stepSize = dayjs
      .unix(anyTimestampToUnix(data[1][timestampKey]))
      .diff(from, 'minutes');

    if (stepSize < 60) {
      return 1;
    }

    // range: one day, density: every hour
    if (diffInDays <= 1 && stepSize === 60) {
      return 1;
    }

    // range: one week, density: every hour
    if (diffInDays <= 14 && stepSize === 60) {
      return 4;
    }

    // range: one month, density: every hour
    if (diffInDays <= 31 && stepSize === 60) {
      return 24;
    }

    // range: one day, density: every day
    if (diffInDays <= 1 && stepSize <= 1_440) {
      return 0;
    }

    // range: one week, density: every day
    if (diffInDays <= 7 && stepSize <= 1_440) {
      return 1;
    }

    // range: one month, density: every day
    if (diffInDays <= 31 && stepSize <= 1_440) {
      return 1;
    }
  }

  return 0;
};

export const attachFakeDataPoints = <T extends TimeSeries<any>>(
  data: T,
  amount: number,
  timestampKey: keyof T[number] = 'timestamp'
): T => {
  if (data.length <= 1 || amount >= data.length) {
    return data;
  }

  const t0 = anyTimestampToUnix(data[0].timestamp);
  const t1 = anyTimestampToUnix(data[1].timestamp);
  const tickSizeInHours = (t1 - t0) / 3600;

  return [
    ...range(0, amount)
      .fill(data[0])
      .map(makeFakeDatapointsMapFn(tickSizeInHours, 'subtract', timestampKey)),
    ...data,
    ...range(0, amount)
      .fill(data[data.length - 1])
      .map(makeFakeDatapointsMapFn(tickSizeInHours, 'add', timestampKey)),
  ] as T;
};

export const makeChartProps = <T extends TimeSeries<any>>(
  data: T,
  withFake?: boolean,
  timestampKey: keyof T[number] = 'timestamp'
) => {
  if (withFake) {
    const tickInterval = getTickInterval(
      extractRangeFromData(data),
      data.length
    );
    const fakeDatapointsLength = getFakeDatapointsLength(data, timestampKey);
    const fakeData = attachFakeDataPoints(
      data,
      fakeDatapointsLength,
      timestampKey
    );

    const _data = attachTimeTicks(fakeData, timestampKey);

    // extra tick offset when displaying in high density
    const extraTickOffset =
      tickInterval >= 24 && fakeDatapointsLength === 24
        ? Math.trunc(tickInterval / 2)
        : 0;

    const _xAxisProps = makeXAxisProps(
      _data,
      // fake datapoint will never be shown, so we need to offset the ticks by the amount of fake datapoints
      fakeDatapointsLength,
      // we also might want offset datapoints by arbitrary amount from the beginning
      extraTickOffset
    );

    return {
      data: _data,
      xAxisProps: _xAxisProps,
    };
  }

  return {
    data: attachTimeTicks(data, timestampKey),
    xAxisProps: makeXAxisProps(data),
  };
};
